import { PercentageOutlined } from "@ant-design/icons";
import { notification, Space } from "antd";
import moment from "moment";
import MilanoThumbnail from "../../assets/MilanoThumbnail.png";
import {
  NOTIFICATIONS,
  ORDER_DETAILS,
  PRODUCT_DETAILS_PAGE,
  PRODUCT_MAIN,
} from "shared/constants/RouteConstants";

import "./NotificationCardModal.scss";
import { Link, useHistory } from "react-router-dom";
import { useContext } from "react";
import NotificationContext from "context/Notification";
import API_SERVICE from "services/api-service";

interface Notification {
  title: string;
  createdAt: Date;
  data: string;
  isRead: boolean;
  id: number;
  sourceModel: string;
  notification: {
    id: number;
    data: string;
    isRead: boolean;
    sourceModel: string;
    title: string;
    sourceId: number;
    createdAt: Date;
  };
}

const NotificationCardModal = () => {
  const { _fetchUnreadCount, fetchNotifications } =
    useContext(NotificationContext);
  const history = useHistory();

  const _createUrl = (type: string, obj: any) => {
    if (type === "CATEGORY") {
      history.push(`${PRODUCT_MAIN}/${obj.type_id}/${obj.type_details.name}`);
    }
    if (type === "PRODUCT") {
      history.push(
        `${PRODUCT_DETAILS_PAGE}/${obj.type_id}/${obj.type_details.category.name}`
      );
    }
    if (type === "CUSTOM") {
      window.open(obj.customUrl, "_blank");
    }
  };

  const _statusChange = async (id: number) => {
    try {
      await API_SERVICE.statusChangeNotification(id);
      _fetchUnreadCount();
      fetchNotifications();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };

  const _textTruncate = (title: string) => {
    return title?.substring(0, 120) + "...";
  };

  const { notifications } = useContext(NotificationContext);
  return (
    <div className="Notification-dropdown-content">
      {notifications.length !== 0 ? (
        notifications
          .slice(0, 4)
          .filter((notification: Notification) => notification.isRead === false)
          .map((notification: Notification) => {
            const data = JSON.parse(notification.data);
            return (
              <div
                onClick={() => {
                  if (notification.sourceModel === "Order") {
                    history.push(`${ORDER_DETAILS}/${data.id}`);
                  } else if (notification.sourceModel === "Product") {
                    history.push(
                      `${PRODUCT_DETAILS_PAGE}/${data.id}/${data.category.name}`
                    );
                  } else {
                    _createUrl(data.notification_type, data);
                  }
                  _statusChange(notification.id);
                }}
                className="notification-detail-card notification-detail-card-modal"
                key={notification.id}
              >
                <div className="image">
                  <img
                    src={
                      (data.hasOwnProperty("file") && data.file.url) ||
                      MilanoThumbnail
                    }
                    alt={notification.title}
                  />
                </div>
                <div className="details">
                  <p className="notification-card-modal-title text-truncate">
                    {notification.title}
                  </p>
                  <small className="notification-card-modal-info d-block">
                    {data.hasOwnProperty("description") &&
                      _textTruncate(data.description)}
                  </small>
                  <span className="notification-card-modal-time-tag">
                    {moment
                      .utc(notification.createdAt)
                      .local()
                      .startOf("seconds")
                      .fromNow()}
                  </span>
                </div>
              </div>
            );
          })
      ) : (
        <p className="noUnreadNotifications"> No Unread Notification</p>
      )}

      <Link to={NOTIFICATIONS}>
        <div className="view-notification-modal p-1">
          View All Notifications
        </div>
      </Link>
    </div>
  );
};

export default NotificationCardModal;
