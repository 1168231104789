import { useEffect, useState } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import { Row, Col, Spin, Typography, notification } from "antd";
import LoginForm from "./LoginForm";

import "./Login.scss";
import { useTranslation } from "react-i18next";

import loginProp from "shared/assets/images/loginPageImg.png";

import { AuthConsumer } from "shared/contexts/AuthContext";
import API_SERVICE from "client/src/services/api-service";
import AccessControl from "services/AccessControl";
import logo from "../../assets/logo.jpg";
import { HOME } from "../../constants/RouteConstants";

const { Title } = Typography;

interface Props {
  location: any;
}

// const contentStyle = {

//     // color: '#fff',
//     // lineHeight: '373px',
//     // textAlign: 'center',
//     // background: '#364d79',
// };

export default function Login({ location }: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [loginImage, setLoginImage] = useState();
  // const { from } = location.state || { from: { pathname: "/" } };
  const getLoginBanner = async (type: string) => {
    try {
      setImageLoading(true);
      const params = {
        bannerType: type,
      };
      const {
        data: { data },
      } = await API_SERVICE.getLoginBanner(params);
      // if (data) {
      setLoginImage(data?.file?.url || "");
      // }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    } finally {
      setImageLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Login Page";
    getLoginBanner("LOGIN_BANNER");
  }, []);

  return (
    <AuthConsumer>
      {({ isAuth, user, updateAuthToken }) => {
        return isAuth ? (
          user?.roles[0]?.pivot.roleId === 3 ? (
            <Redirect to={"/dealer"} />
          ) : (
            <Redirect to="/admin" />
          )
        ) : (
          <Row className="login-form-row  ">
            <Col
              sm={24}
              md={10}
              lg={12}
              className={"login-form-wrapper-column "}
            >
              <div className="login-form-wrapper" style={{ width: "100%" }}>
                {/* <Row>
                      <Col> */}
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                <div className="login-title">Login</div>
                <p className="login-text">
                  Already have an account? Please Login!
                </p>
                {/* </Col>
                    </Row> */}

                <LoginForm
                  onLogin={async (email: string, password: string) => {
                    setLoading(true);
                    try {
                      const {
                        data: { data },
                      } = await API_SERVICE.login(email, password);
                      updateAuthToken(data);
                    } catch (e) {
                      notification.error({
                        message: API_SERVICE.handleErrors(e),
                        placement: "bottomRight",
                      });
                      setLoading(false);
                    }
                  }}
                  loading={loading}
                />
              </div>
            </Col>
            <Col sm={24} md={10} lg={12} className="login-text-column">
              {imageLoading ? (
                <div className="loaderClass">
                  <Spin tip="Loading" size="large"></Spin>
                </div>
              ) : (
                <div className="login-text-wrapper">
                  <img
                    src={loginImage}
                    alt="login"
                    className="img-fluid login-prop-image"
                  />
                </div>
              )}
            </Col>
          </Row>
        );
      }}
    </AuthConsumer>
  );
}
