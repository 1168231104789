import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import AddToCartBtn from "components/AddToCartBtn";
import { useHistory } from "react-router-dom";
import API_SERVICE from "services/api-service";
import { consverPriceToInternationalFormat } from "services/util";
import { PRODUCT_DETAILS_PAGE } from "shared/constants/RouteConstants";
import MilanoThumbnail from "../../assets/MilanoThumbnail.png";
import "./index.scss";

const SimilarProductCard = (props: any) => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user")!);
  const currency = user?.user.dealerDetails[0]?.currencyType;
  const truncate = (title: string) => {
    return title.length > 60 ? title.substr(0, 60) + "..." : title;
  };

  const addToWIshlistHandle = async (
    productId: number,
    isWishlisted: boolean,
    isHandleMoveCart?: boolean
  ) => {
    if (isWishlisted) {
      try {
        await API_SERVICE.WishListDelete(productId);

        if (isHandleMoveCart) {
          notification.success({
            message: "Removed From Wishlist",
            placement: "bottomLeft",
          });
          props.callBack();
        }
        debugger;
      } catch (error) {
        notification.error({
          message: "Something Went Wrong",
          placement: "bottomLeft",
        });
      }
    } else {
      try {
        await API_SERVICE.addToWishList(productId);
        notification.success({
          message: "Added to Wishlist",
          placement: "bottomLeft",
        });
        props.callBack();
      } catch (err) {
        notification.error({
          message: API_SERVICE.handleErrors(err),
          placement: "bottomLeft",
        });
      }
    }
  };

  return (
    <div style={{ cursor: "pointer" }} className="productSimilarCard">
      <div
        onClick={() => {
          // props.setProductId(props.product.id);
          history.push(
            PRODUCT_DETAILS_PAGE +
              "/" +
              props.product.id +
              "/" +
              props.categoryName
          );
        }}
      >
        <div className="product-image">
          <img
            src={
              props?.product?.productImages.length !== 0
                ? props?.product?.productImages[0].file?.url
                : MilanoThumbnail
            }
            alt="product"
          />
        </div>
        <div className="product-details">
          <h3 className="title "> {truncate(props.product.name)}</h3>
          <p className="price">
            {currency === "USD" ? "$" : "AED"}{" "}
            {consverPriceToInternationalFormat(props.product.price)}
          </p>
          <div className="inventory-info">
            <div>
              <p>SKU</p>
              <p>{props.product.skuId}</p>
            </div>
            <div>
              <p>In stock</p>
            </div>
          </div>
        </div>
      </div>
      <div className="AddToCartBtnContainer">
        <AddToCartBtn
          name=""
          quantity={1}
          orderCoupon={null}
          productId={props.product.id}
        />
        <Button
          className="wishlistBtn"
          onClick={() => {
            addToWIshlistHandle(
              props.product.id,
              props.product.isWishlisted,
              true
            );
          }}
        >
          {props.product.isWishlisted ? <HeartFilled /> : <HeartOutlined />}
        </Button>
      </div>
    </div>
  );
};

export default SimilarProductCard;
