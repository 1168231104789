import "./index.scss";
import { Breadcrumb, Col, Layout, notification, Row, Spin, Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import { HOME } from "shared/constants/RouteConstants";
import { Link } from "react-router-dom";
import NotificationCard from "components/NotificationCard/NotificationCard";
import { useEffect, useState } from "react";
import API_SERVICE from "services/api-service";
import InfiniteScroll from "react-infinite-scroll-component";

const types = {
  TODAY: "today",
  WEEK: "week",
  ALL: "allNotification",
};
export default function Notifications(props: any) {
  const [readUnread, setReadUnread] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [type, setType] = useState(types.TODAY);
  const [notifications, setNotifications] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: 2,
  });

  //fetching
  const fetchNotifications = async (params: any) => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.GetNotifcations(params);

      // data.items.forEach((element) => {
      //   console.log(JSON.parse(element.data));
      // });
      data.items.length !== 0 ? setHasMore(true) : setHasMore(false);
      data.items.length && setNotifications([...notifications, ...data.items]);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  useEffect(() => {
    fetchNotifications({
      type,
      isRead: readUnread,
      page: pagination.page,
      perpage: pagination.perpage,
    });
  }, [type, readUnread]);

  return (
    <Layout className="products-bg-color">
      <Content className="contentPadding">
        <Breadcrumb className="products-breadcrumb" separator=">">
          <Breadcrumb.Item>
            <Link to={HOME}>Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="products-breadcrumb-active">
            Notifications
          </Breadcrumb.Item>
        </Breadcrumb>

        <Layout className="product-main-content-layout">
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ justifyContent: "center", gap: "20px" }}
          >
            <Col xs={24} className="dealer-profile-container">
              <h1 className="page-title text-center">Notifications</h1>
            </Col>
            <Col xs={24} sm={24} md={24} lg={18} xl={12}>
              <Tabs
                destroyInactiveTabPane
                onChange={(e) => {
                  setNotifications([]);
                  setPagination({
                    ...pagination,
                    page: 1,
                  });
                  setType(types.TODAY);
                  setReadUnread(parseInt(e));
                }}
                className="notification-container"
              >
                <Tabs.TabPane
                  tab={
                    <p className="tab-unread">
                      Unread{" "}
                      {/* {notifications.length > 0 && (
                        <span>
                          {
                            notifications.filter(
                              (v: { isRead: boolean }) => v?.isRead === false
                            ).length
                          }
                        </span>
                      )} */}
                    </p>
                  }
                  key="0"
                >
                  <Row>
                    <Col xs={24} sm={24}>
                      <Tabs
                        destroyInactiveTabPane
                        onChange={(e) => {
                          setNotifications([]);
                          setPagination({
                            ...pagination,
                            page: 1,
                          });
                          setType(e);
                        }}
                        defaultActiveKey={types.TODAY}
                        // className="notification-container"
                        size="small"
                      >
                        <Tabs.TabPane
                          id="todaytab1"
                          tab={<p className="tab-unread">Today</p>}
                          key={types.TODAY}
                        >
                          <InfiniteScroll
                            dataLength={notifications.length}
                            next={() => {
                              setPagination({
                                ...pagination,
                                page: pagination.page + 1,
                              });
                              fetchNotifications({
                                type: types.TODAY,
                                isRead: 0,
                                page: pagination.page + 1,
                                perpage: pagination.perpage,
                              });
                            }}
                            hasMore={hasMore}
                            loader={
                              <div className="flex align-items-center justify-content-center">
                                <Spin />
                              </div>
                            }
                            endMessage={
                              <p
                                className="no-prod"
                                style={{ textAlign: "center" }}
                              >
                                <b>Yay! You have seen it all</b>
                              </p>
                            }
                          >
                            {notifications.map((notification) => {
                              return (
                                <NotificationCard
                                  key={notification?.id}
                                  notification={notification}
                                />
                              );
                            })}
                          </InfiniteScroll>
                        </Tabs.TabPane>

                        <Tabs.TabPane
                          tab={<p className="tab-unread">This Week </p>}
                          key={types.WEEK}
                        >
                          <InfiniteScroll
                            dataLength={notifications.length}
                            next={() => {
                              setPagination({
                                ...pagination,
                                page: pagination.page + 1,
                              });
                              fetchNotifications({
                                type: types.WEEK,
                                isRead: 0,
                                page: pagination.page + 1,
                                perpage: pagination.perpage,
                              });
                            }}
                            hasMore={hasMore}
                            loader={
                              <div className="flex align-items-center justify-content-center">
                                <Spin />
                              </div>
                            }
                            endMessage={
                              <p
                                className="no-prod"
                                style={{ textAlign: "center" }}
                              >
                                <b>Yay! You have seen it all</b>
                              </p>
                            }
                          >
                            {notifications.map((notification) => {
                              return (
                                <NotificationCard
                                  key={notification?.id}
                                  notification={notification}
                                />
                              );
                            })}
                          </InfiniteScroll>
                        </Tabs.TabPane>

                        <Tabs.TabPane
                          tab={<p className="tab-unread">All </p>}
                          key={types.ALL}
                        >
                          <InfiniteScroll
                            dataLength={notifications.length}
                            next={() => {
                              setPagination({
                                ...pagination,
                                page: pagination.page + 1,
                              });
                              fetchNotifications({
                                type: types.ALL,
                                isRead: 0,
                                page: pagination.page + 1,
                                perpage: pagination.perpage,
                              });
                            }}
                            hasMore={hasMore}
                            loader={
                              <div className="flex align-items-center justify-content-center">
                                <Spin />
                              </div>
                            }
                            endMessage={
                              <p
                                className="no-prod"
                                style={{ textAlign: "center" }}
                              >
                                <b>Yay! You have seen it all</b>
                              </p>
                            }
                          >
                            {notifications.map((notification) => {
                              return (
                                <NotificationCard
                                  key={notification?.id}
                                  notification={notification}
                                />
                              );
                            })}
                          </InfiniteScroll>
                        </Tabs.TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<p className="tab-unread">Read </p>} key="1">
                  <Row>
                    <Col xs={24} sm={24}>
                      <Tabs
                        destroyInactiveTabPane
                        onChange={(e) => {
                          setNotifications([]);
                          setPagination({
                            ...pagination,
                            page: 1,
                          });
                          setType(e);
                        }}
                        defaultActiveKey={types.TODAY}
                        // className="notification-container"
                        size="small"
                      >
                        <Tabs.TabPane
                          id="todaytab2"
                          tab={<p className="tab-unread">Today </p>}
                          key={types.TODAY}
                        >
                          <InfiniteScroll
                            dataLength={notifications.length}
                            next={() => {
                              setPagination({
                                ...pagination,
                                page: pagination.page + 1,
                              });
                              fetchNotifications({
                                type: types.TODAY,
                                isRead: 1,
                                page: pagination.page + 1,
                                perpage: pagination.perpage,
                              });
                            }}
                            hasMore={hasMore}
                            loader={
                              <div className="flex align-items-center justify-content-center">
                                <Spin />
                              </div>
                            }
                            endMessage={
                              <p
                                className="no-prod"
                                style={{ textAlign: "center" }}
                              >
                                <b>Yay! You have seen it all</b>
                              </p>
                            }
                          >
                            {notifications.map((notification) => {
                              return (
                                <NotificationCard
                                  key={notification?.id}
                                  notification={notification}
                                />
                              );
                            })}
                          </InfiniteScroll>
                        </Tabs.TabPane>

                        <Tabs.TabPane
                          tab={<p className="tab-unread">This Week </p>}
                          key={types.WEEK}
                        >
                          <InfiniteScroll
                            dataLength={notifications.length}
                            next={() => {
                              setPagination({
                                ...pagination,
                                page: pagination.page + 1,
                              });
                              fetchNotifications({
                                type: types.WEEK,
                                isRead: 1,
                                page: pagination.page + 1,
                                perpage: pagination.perpage,
                              });
                            }}
                            hasMore={hasMore}
                            loader={
                              <div className="flex align-items-center justify-content-center">
                                <Spin />
                              </div>
                            }
                            endMessage={
                              <p
                                className="no-prod"
                                style={{ textAlign: "center" }}
                              >
                                <b>Yay! You have seen it all</b>
                              </p>
                            }
                          >
                            {notifications.map((notification) => {
                              return (
                                <NotificationCard
                                  key={notification?.id}
                                  notification={notification}
                                />
                              );
                            })}
                          </InfiniteScroll>
                        </Tabs.TabPane>

                        <Tabs.TabPane
                          tab={<p className="tab-unread">All </p>}
                          key={types.ALL}
                        >
                          <InfiniteScroll
                            dataLength={notifications.length}
                            next={() => {
                              setPagination({
                                ...pagination,
                                page: pagination.page + 1,
                              });
                              fetchNotifications({
                                type: types.ALL,
                                isRead: 1,
                                page: pagination.page + 1,
                                perpage: pagination.perpage,
                              });
                            }}
                            hasMore={hasMore}
                            loader={
                              <div className="flex align-items-center justify-content-center">
                                <Spin />
                              </div>
                            }
                            endMessage={
                              <p
                                className="no-prod"
                                style={{ textAlign: "center" }}
                              >
                                <b>Yay! You have seen it all</b>
                              </p>
                            }
                          >
                            {notifications.map((notification) => {
                              return (
                                <NotificationCard
                                  key={notification?.id}
                                  notification={notification}
                                />
                              );
                            })}
                          </InfiniteScroll>
                        </Tabs.TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </Layout>
      </Content>
    </Layout>
  );
}
