import React from "react";

import { Form, Input, Button, Row, Col, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import lockIcon from "../../assets/icons/lockIcon.png";
interface Props {
  onOTP: (email: string, password: string) => void;
  loading: boolean;
}

export default function OTPForm({ onOTP, loading }: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  function handleSubmit(values: any) {
    const email = localStorage.getItem("otp-email");
    if (onOTP) {
      const { otp } = values;
      //@ts-ignore
      onOTP(email, otp);
    }
  }

  return (
    <Form
      className={"otp-form"}
      form={form}
      layout="vertical"
      fields={[]}
      // initialValues={{
      //   email: "admin",
      //   password: "asdf@1234",
      // }}
      onFinish={handleSubmit}
      // labelCol={{ span: 8 }}
      // wrapperCol={{ span: 20 }}
      requiredMark={false}
    >
      <Form.Item
        className={"mb-3"}
        label={"OTP"}
        name="otp"
        rules={[{ required: true, message: "Otp required" }]}
      >
        <Input
          autoComplete={"off"}
          size="large"
          type="password"
          placeholder="otp"
          className="otp-input"
          prefix={<img src={lockIcon} />}
        />
      </Form.Item>

      <br />

      <Form.Item style={{ justifyContent: "flex-end" }} className="mb-3">
        <Button
          type="primary"
          htmlType="submit"
          block
          loading={loading}
          className="theme-button primary reset-btn"
        >
          Reset Password
        </Button>
      </Form.Item>
    </Form>
  );
}
