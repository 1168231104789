import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  DatePicker,
  Form,
  MenuProps,
  notification,
  Select,
  Table,
} from "antd";
// import "./index.scss";

import { Layout, Row, Col, Input } from "antd";
import { FileUpload } from "components";
import globe from "../../../assets/icons/globe.png";
import API_SERVICE from "services/api-service";
import { CloudDownload, Edit } from "@material-ui/icons";
import AdminListLayout from "shared/components/layout/AdminListLayout/AdminListLayout";
import { BANNER_LIST } from "shared/constants/RouteConstants";
import moment from "moment";
import AccessControl from "services/AccessControl";

const { Header, Sider, Content } = Layout;

const LoginPageManagement = () => {
  const { name, id } = useParams();
  // const id;
  const [form] = Form.useForm();
  const [selectType, setSelectType] = useState("");
  const [id2, setId2] = useState(null);

  const [formTitle, setFormTitle] = useState();
  const [loginImage, setLoginImage] = useState();

  const [imageId, setImageId] = useState(null);
  const [imageFile, setimageFile] = useState({
    name: "",
    url: "",
  });

  let history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleUpdate = async (data: any, type?: string) => {
    try {
      const id = data.id;
      const params = {
        bannerType: "LOGIN_BANNER",
        type: "CUSTOM",
        imageId: imageId,
        displayOrder: 1,
        customUrl: "#",
        status: "ACTIVE",
      };

      await API_SERVICE.updateHomeBanner(id, params);
      notification.success({
        message: `LOGIN BANNER Updated Successfully`,
      });
      setImageId(null);
      setimageFile({
        name: "",
        url: "",
      });
      getHomeOtherBanner("LOGIN_BANNER");
      form.resetFields();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
      });
    }
  };

  const featuredColumns = [
    {
      title: "Image ",

      render: (d: any) => (
        <>
          <a href={d?.file?.url} target="_blank" rel="noreferrer">
            <img
              className="bannerTableImg"
              src={d?.file?.url}
              alt="featured-image"
            />
          </a>
        </>
      ),
    },
    {
      title: "ACTION: ",
      render: (d: any) => (
        <div className="bannerActionButtons">
          <Button
            shape="circle"
            onClick={() => {
              setimageFile({
                name: d.file.name,
                url: d.file.url,
              });
              setImageId(d.imageId);
              form.setFieldsValue({
                ["id"]: d.id,
              });
            }}
          >
            <Edit />
          </Button>
        </div>
      ),
    },
  ];

  const getHomeOtherBanner = async (type: string) => {
    try {
      const params = {
        bannerType: type,
      };
      const {
        data: { data },
      } = await API_SERVICE.getAdminBannersList(params);

      if (data) {
        setLoginImage(data.items);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    getHomeOtherBanner("LOGIN_BANNER");
  }, []);

  return (
    <>
      <Row
        className="align-items-center  stock-summary-container mb-2 mt-2"
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}
      >
        <Col xs={24} lg={21}>
          <div className="manage-products-title">Login Banner</div>
        </Col>
      </Row>
      <Row className="admin-banner-form" style={{ minHeight: "fit-content" }}>
        <Col
          xs={24}
          sm={24}
          lg={12}
          className="p-2 "
          style={{ position: "relative" }}
        >
          <h1 className="manage-products-title"></h1>

          <Form
            form={form}
            labelCol={{
              xs: { span: 4 },
              sm: { span: 4 },
              md: { span: 8 },
              lg: { span: 6 },
              xl: { span: 6 },
            }}
            labelAlign="left"
            wrapperCol={{
              xs: { span: 2 },
              sm: { span: 2 },
              md: { span: 16 },
              lg: { span: 18 },
              xl: { span: 18 },
            }}
            layout="horizontal"
            onFinish={handleUpdate}
          >
            <Form.Item name="id" hidden></Form.Item>

            <Form.Item label="Select Image" name="file1">
              <FileUpload
                type={"BANNER"}
                onChange={(id: any) => {
                  setImageId(id);
                }}
                Long
                Image
              >
                {imageFile.url !== "" ? (
                  <div className="uploadedImage">
                    <img src={imageFile?.url} alt={imageFile?.name} />
                    <Button>Edit</Button>
                  </div>
                ) : (
                  <>
                    <div className="fileUploadDesign">
                      <div>
                        <img src={globe} alt="icon" />
                        Upload Image
                      </div>
                      <p className="text">Upload</p>
                    </div>
                    <p
                      style={{
                        color: "#A7A7A7",
                        fontSize: "14px",
                      }}
                    >
                      Upload file format should be in JPG or PNG and the maximum
                      size of file must be less then 3 MB.
                    </p>
                  </>
                )}
              </FileUpload>
            </Form.Item>

            <Form.Item label=""></Form.Item>
            <Form.Item>
              <Button htmlType="submit" className="mr-2">
                Update
              </Button>
              <Button
                htmlType="button"
                onClick={() => {
                  form.resetFields();
                  setimageFile({
                    url: "",
                    name: "",
                  });
                }}
              >
                Clear
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} lg={12}>
          <Table
            loading={loading}
            pagination={false}
            columns={featuredColumns}
            dataSource={loginImage}
            size="middle"
          />
        </Col>
      </Row>
    </>
  );
};

export default LoginPageManagement;
