import {
  Row,
  Col,
  Button,
  Layout,
  Breadcrumb,
  Form,
  Input,
  Table,
  Select,
} from "antd";

import { notification } from "antd";
import { Content } from "antd/lib/layout/layout";
import "./stockSummary.scss";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { CloudDownload, SearchOutlined } from "@material-ui/icons";
import { DownOutlined } from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import { DASHBOARD } from "shared/constants/RouteConstants";
import API_SERVICE from "services/api-service";

export default function StockSummary(props: any) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [filterAttributes, setFilterAttributes] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: "15",
  } as any);
  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);
  const [fetchingXls, setFetchingXls] = useState(false);
  const [fetchingPdf, setFetchingPdf] = useState(false);

  const columns = [
    {
      title: "CATEGORY : ",
      dataIndex: "category",
      filters:
        filterAttributes &&
        filterAttributes.categories &&
        filterAttributes.categories.length
          ? createFilterTree(filterAttributes.categories)
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterMode: "tree",
      filterSearch: false,
      render: (data) => {
        return data?.name;
      },
    },
    {
      title: "ITEM NAME",
      dataIndex: "name",
    },
    {
      title: "SKU",
      dataIndex: "skuId",
      filters:
        filterAttributes && filterAttributes.skuId
          ? filterAttributes?.skuId.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
    },
    {
      title: "CURRENT STOCK",
      dataIndex: "stock",
      width: "250px",
    },
    {
      title: "STOCK LABEL",
      dataIndex: "productStockLabels",
      render: (d: any) => {
        return <>{d[0]?.labels}</>;
      },
    },
  ];

  function createFilterTree(data: any) {
    return data.map((item: any) => {
      return {
        text: item.name,
        value: item.id,
        children: item.children.length ? createFilterTree(item.children) : [],
      };
    });
  }

  const _handleSearch = (event) => {
    if (event.which === 13) {
      setSearch({ q: event.target.value });
      setPagination((prev) => {
        return { page: 1, perpage: prev.perpage };
      });
      fetchSummary({ page: 1, perpage: pagination.perpage }, sort, filters, {
        q: event.target.value,
      });
    }
  };

  const getAllAttributes = async () => {
    const {
      data: { data },
    } = await API_SERVICE.fetchAllAttributes();
    setFilterAttributes(data);
  };

  const fetchSummary = async (
    pagination = {},
    sort = {},
    filters = {},
    search = {}
  ) => {
    try {
      const params = {
        ...pagination,
        ...sort,
        ...filters,
        ...search,
      };
      params.categoryIds = params.category;
      delete params.category;
      const data = await API_SERVICE.getStockSummary(params);
      setLoading(false);
      if (data) {
        setData(data?.data?.data?.items);
        setTotal(data?.data?.data?.total);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
      setLoading(false);
    }
  };

  const _handleTableChanges = async (
    pagination,
    filters,
    sorter,
    extra: { currentDataSource; action }
  ) => {
    if (extra.action === "paginate") {
      setPagination({
        page: pagination.current,
        perpage: pagination.pageSize,
      });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };

  const onSelectCategory = (value: any) => {
    const filteredEvents = data.filter(({ category }) => {
      category = category.toLowerCase();
      return category === value.toLowerCase();
    });
    Number(value) === 0 ? setData(data) : setData(filteredEvents);
  };

  useEffect(() => {
    fetchSummary(pagination, sort, filters, search);
  }, [sort, filters, pagination]);

  useEffect(() => {
    getAllAttributes();
  }, []);

  useEffect(() => {
    document.title = "Milano Admin |  Stock Summary";
  }, []);

  const fetchDealerStockSummaryExcel = async () => {
    try {
      setFetchingXls(true);
      const {
        data: { data },
      } = await API_SERVICE.GetDealerStockSummaryExcel();
      window.open(data.url);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    } finally {
      setFetchingXls(false);
    }
  };

  const fetchDealerStockSummaryPdf = async () => {
    try {
      setFetchingPdf(true);
      const {
        data: { data },
      } = await API_SERVICE.GetDealerStockSummaryPdf();
      window.open(data.url);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    } finally {
      setFetchingPdf(false);
    }
  };

  return (
    <Layout className="cart-bg-color">
      <Content className="contentPadding">
        <Breadcrumb className="products-breadcrumb mt-2 mb-2" separator=">">
          <Breadcrumb.Item>
            <Link to={DASHBOARD}>Dashboard</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="products-breadcrumb-active">
            Stock Summary
          </Breadcrumb.Item>
        </Breadcrumb>

        <Layout className="cart-main-content-layout">
          <Row
            className="align-items-center stock-summary-container mb-2 mt-2"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
            }}
          >
            <Col xs={24} sm={24} md={12} lg={6} xl={8} xxl={10}>
              <h4 className="admin-page-title">Stock Summary</h4>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              xxl={8}
              className=" align-items-center justify-content-end "
            >
              <Form onFinish={onSelectCategory}>
                <Form.Item
                  label="Search"
                  name="searchItem"
                  className="dashboard-label"
                >
                  <Input
                    className="dashboard-input"
                    placeholder="Search Item"
                    suffix={<SearchOutlined />}
                    onKeyPress={_handleSearch}
                  />
                </Form.Item>
              </Form>
            </Col>
            {/* <Col
              xs={12}
              sm={12}
              md={8}
              lg={4}
              className="flex align-items-center justify-content-end"
            >
              <Form>
                <Form.Item
                  label="Category"
                  name="categoryName"
                  className="dashboard-label"
                >
                  <Select
                    className="dashboard-select"
                    defaultValue="Select Category"
                    // onChange={onSelectCategory}
                  >
                    <Option value="0">Select Category</Option>
                    <Option value="Faucets">Faucets</Option>
                    <Option value="Bathroom Accessories">
                      Bathroom Accessories
                    </Option>
                    <Option value="Shower">Shower</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col> */}
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={5}
              xl={4}
              xxl={3}
              className="flex justify-content-end"
            >
              <Button
                loading={fetchingXls}
                className="dashboard-btn"
                onClick={fetchDealerStockSummaryExcel}
              >
                <CloudDownload className="icon" />
                Download XLS
              </Button>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={5}
              xl={4}
              xxl={3}
              className="flex justify-content-end"
            >
              <Button
                loading={fetchingPdf}
                className="dashboard-btn"
                onClick={fetchDealerStockSummaryPdf}
              >
                <CloudDownload className="icon" />
                Download PDF
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={24} className="mb-3">
              <Table
                loading={loading}
                columns={columns}
                dataSource={data}
                size="middle"
                scroll={{ x: true }}
                pagination={{
                  size: "default",
                  current: pagination.page,
                  pageSize: pagination.perpage,
                  total: total,
                }}
                onChange={_handleTableChanges}
              />
            </Col>
          </Row>
        </Layout>
      </Content>
    </Layout>
  );
}
