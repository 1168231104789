import {
  ChevronRight,
  Close,
  Delete,
  InboxOutlined,
  PartyMode,
  SearchOutlined,
} from '@material-ui/icons';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import discountCoupon from '../../../assets/discount.svg';
import activeCouponIcon from '../../../assets/coupon.svg';

import {
  Row,
  Col,
  Button,
  Layout,
  Breadcrumb,
  Divider,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
  notification,
  Select,
  Checkbox,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import CartProductCard from 'components/CartProductCard/CartProductCard';
import CheckoutProductCard from 'components/CheckoutProductCard/CheckoutProductCard';
import { useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import globe from '../../../assets/icons/globe.png';
import home from '../../../assets/icons/home.png';
import mapPin from '../../../assets/icons/map-pin.png';
import map from '../../../assets/icons/map.png';
import smartphone from '../../../assets/icons/smartphone.png';
import userPic from '../../../assets/icons/username.png';
import mailIcon from '../../../assets/icons/mail.png';

import { Link, useHistory } from 'react-router-dom';

import {
  CHECKOUT_REDIRECT,
  HOME,
  THANKYOU,
} from 'shared/constants/RouteConstants';
import API_SERVICE from 'services/api-service';
import CartContext from 'context/Cart';
import { consverPriceToInternationalFormat } from 'services/util';
import { countryList } from 'shared/constants/Constants';
import Loader from 'components/Loader/Loader';
import { City, ICity } from 'country-state-city';

const { Step } = Steps;
const steps = [
  {
    title: 'My Cart',
  },
  {
    title: 'Checkout',
  },
];

interface Values {
  id: number;
  name: string;
  phone: number;
  address: string;
  building: string;
  street: string;
  area: string;
  zipcode: number;
  city: string;
  country: string;
}
const { Option } = Select;

export default function CartPage(props: any) {
  const pageTopRefCart = useRef(null);
  const pageTopRefCheckout = useRef(null);

  const history = useHistory();
  const {
    cartData,
    cartProducts,
    pagination,
    total,
    cartValue,
    setTotal,
    setPagination,
    fetchCartDetails,
    createOrder,
  } = useContext(CartContext);

  const [searchInput, setSearchInput] = useState('');

  //Steps
  const [current, setCurrent] = useState(0);

  const [edit, setEdit] = useState(false);

  const [form] = Form.useForm();
  const [address, setAddress] = useState([]);
  const [activeCoupon, setActiveCoupon] = useState([]);
  const [checkOutState, setCheckOutState] = useState({
    deliverySlot: '',
    billingAddressId: null,
    shippingAddressId: null,
    sameAsShipping: false,
    cartId: cartProducts[0]?.cartId,
    status: 'NEW',
    placed: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [currency, setCurrency] = useState();
  const [couponCode, setCouponCode] = useState(null);
  const [appliedCoupon, setAppliedCoupon] = useState(false);
  const [disable, setDisable] = useState(false);
  const [countrySelected, setCountry] = useState('');
  const [discountDetails, setDiscountDetails] = useState({
    totalAmount: 0,
    discountAmount: 0,
    totalAmountAfterDiscount: 0,
  });
  const [cityList, setCityList] = useState<ICity[]>([]);

  //Coupon Modal
  const [isCouponModalOPen, setIsCouponModalOPen] = useState(false);

  const showModal = (value: string) => {
    setIsModalOpen(true);
    setModalTitle(value);
    form.setFieldsValue({ country: countrySelected });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handlePlaceOrder = async (props: any) => {
    console.log(cartData);
    const { billingAddressId, shippingAddressId, deliverySlot, placed } =
      checkOutState;
    if (!shippingAddressId) {
      notification.error({
        message: 'Please Select Shipping Address',
        placement: 'bottomLeft',
      });
    } else if (!billingAddressId) {
      notification.error({
        message: 'Please Select Billing Address',
        placement: 'bottomLeft',
      });
    } else if (!deliverySlot) {
      notification.error({
        message: 'Please Select Delivery Slot',
        placement: 'bottomLeft',
      });
    } else {
      // new changes

      try {
        setDisable(true);

        const payload = {
          ...checkOutState,
        };

        if (couponCode) {
          //@ts-ignore
          payload['couponCode'] = couponCode;
        }

        const {
          data: { data },
        } = await createOrder(payload);
        setCheckOutState({ ...checkOutState, placed: !placed });
        localStorage.setItem('orderdata', JSON.stringify(data));
        setDisable(false);
        notification.success({
          message: `${
            cartData.availableCreditLimit < 0
              ? 'Your order is placed and is subjected to credit approval.'
              : 'Order Placed Successfully'
          }`,
          placement: 'bottomLeft',
        });
        {
          cartData.availableCreditLimit < 0
            ? history.push(THANKYOU, 'fail')
            : history.push(THANKYOU, 'success');
        }

        fetchCartDetails();
        setDiscountDetails({
          totalAmount: 0,
          discountAmount: 0,
          totalAmountAfterDiscount: 0,
        });
      } catch (error) {
        notification.error({
          message: API_SERVICE.handleErrors(error),
          placement: 'bottomLeft',
        });
      }
    }
  };

  const getCurrency = () => {
    const user = JSON.parse(localStorage.getItem('user')!);
    setCurrency(user?.user.dealerDetails[0]?.currencyType);
  };

  const getCountry = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.self();

      setCountry(data.dealerDetails[0].country);
    } catch (error) {
      notification.error({
        message: 'Something Went Wrong',
        placement: 'bottomLeft',
      });
    }
  };

  const _getValidCoupon = async () => {
    try {
      const data = await API_SERVICE.getValidCouponDealer();
      setActiveCoupon(data.data.data);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: 'bottomLeft',
      });
    }
  };

  //AddingAddress
  const addAddress = async (values: Values) => {
    const user = JSON.parse(localStorage.getItem('user')!);
    const userId = user.user.id;

    const params = {
      userId: userId,
      name: values.name,
      phone: values.phone,
      address: values.address,
      building: values.building,
      street: values.street,
      area: values.area,
      zipcode: values.zipcode,
      city: values.city,
      country: values.country,
    };

    try {
      await API_SERVICE.addAddress({ ...params });
      notification.success({
        message: 'Added Address Successfully',
        placement: 'bottomLeft',
      });
      form.resetFields();
      fetchAddress();
      setIsModalOpen(false);
      fetchAddress();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: 'bottomLeft',
      });
    }
  };

  //fetchAddress
  const fetchAddress = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.GetAddress();

      setAddress(data.items);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: 'bottomLeft',
      });
    }
  };

  //editAddress
  const editAddress = async (addr: Values) => {
    const editParams: {} = {
      id: addr.id,
      name: addr.name,
      phone: addr.phone,
      address: addr.address,
      building: addr.building,
      street: addr.street,
      area: addr.area,
      zipcode: addr.zipcode,
      city: addr.city,
      country: addr.country,
    };
    setEdit(true);
    form.setFieldsValue({ ...editParams });
  };

  //updateAddress
  const updateAddress = async (values: Values) => {
    try {
      await API_SERVICE.editAddress({ ...values });
      notification.success({
        message: 'Updated Address Successfully',
        placement: 'bottomLeft',
      });
      fetchAddress();

      setIsModalOpen(false);
      form.resetFields();
      setEdit(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: 'bottomLeft',
      });
    }
  };

  const _applyCoupon = async (coupon: string) => {
    try {
      const payload = {
        couponCode: coupon,
      };
      const {
        data: { data },
      } = await API_SERVICE.applyCouponDealer(payload);
      notification.success({
        message: 'Coupon Applied Successfully',
        placement: 'bottomLeft',
      });
      setDiscountDetails({ ...data });
      setCouponCode(coupon);
      setAppliedCoupon(true);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: 'bottomLeft',
      });
      setAppliedCoupon(false);
    } finally {
      setIsCouponModalOPen(false);
    }
  };

  let finalList: any[] = [];

  cartProducts.forEach((product) => {
    finalList.push(product);
    if (product.freeQuantity) {
      let currentProduct = { ...product };
      currentProduct.quantity = product.freeQuantity;
      currentProduct.price = 0;
      currentProduct['free'] = true;
      finalList.push(currentProduct);
    }
  });

  useEffect(() => {
    getCurrency();
    getCountry();
    const user = JSON.parse(localStorage.getItem('user')!);
    user.user.dealerDetails.length !== 0 &&
      setCheckOutState({
        ...checkOutState,
        deliverySlot: user.user.dealerDetails[0].deliverySlot,
      });

    fetchCartDetails();
    fetchAddress();

    //Get UAE cities
    const cities: ICity[] = City.getCitiesOfCountry('AE') as ICity[];

    setCityList(cities);
  }, []);

  useEffect(() => {
    if (cartProducts.length !== 0) {
      _getValidCoupon();
    }
  }, [cartProducts.length]);

  return (
    <>
      {disable ? <Loader /> : ''}

      <Layout className="cart-bg-color">
        <Content className="contentPadding">
          <Breadcrumb className="products-breadcrumb" separator=">">
            <Breadcrumb.Item>
              <Link to={HOME}>Home</Link>
            </Breadcrumb.Item>

            {current === 1 ? (
              <>
                <Breadcrumb.Item className="products-breadcrumb">
                  My Cart
                </Breadcrumb.Item>
                <Breadcrumb.Item className="products-breadcrumb-active">
                  Checkout
                </Breadcrumb.Item>
              </>
            ) : (
              <>
                <Breadcrumb.Item className="products-breadcrumb-active">
                  My Cart
                </Breadcrumb.Item>
              </>
            )}
          </Breadcrumb>

          <Layout className="cart-main-content-layout">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {steps[current].title === 'My Cart' ? (
                <Col xs={24}>
                  <h1
                    className="page-title text-center"
                    onClick={() => (finalList = [])}
                  >
                    My Cart
                  </h1>
                </Col>
              ) : (
                <Col xs={24}>
                  <h1
                    className="page-title text-center"
                    onClick={() => (finalList = [])}
                  >
                    Checkout
                  </h1>
                </Col>
              )}

              <Col xs={24} className="steps">
                <Steps
                  current={current}
                  direction="horizontal"
                  onChange={(c) => {
                    setCurrent(c);
                    fetchCartDetails(1);
                  }}
                  responsive={true}
                >
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
              </Col>
              {steps[current].title === 'My Cart' ? (
                <>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={16}
                    ref={pageTopRefCart}
                  >
                    <div className="cart-left">
                      <div className="search-container">
                        {/* <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            // fetchCartDetails({"q": searchInput});
                            fetchCartDetails(
                              1,
                              pagination.perpage,
                              searchInput
                            );
                          }}
                        >
                          <SearchOutlined className="icon" />
                          <input
                            type="text"
                            placeholder="search all products"
                            onChange={(e) => {
                              e.target.value === ""
                                ? fetchCartDetails()
                                : setSearchInput(e.target.value);
                            }}
                          />
                          <Button htmlType="submit" hidden={true}>
                            Search
                          </Button>
                        </form> */}
                      </div>
                      <div className="cart-item-container">
                        {finalList.length !== 0 ? (
                          finalList.map((cart: any) => {
                            return (
                              <CartProductCard
                                key={
                                  cart.free
                                    ? `${cart.productId}Cart`
                                    : cart.productId
                                }
                                //@ts-ignore
                                appliedCoupon={appliedCoupon}
                                setAppliedCoupon={setAppliedCoupon}
                                setCouponCode={setCouponCode}
                                setDiscountDetails={setDiscountDetails}
                                cart={cart}
                              />
                            );
                          })
                        ) : (
                          <p className="no-prod m-0">No Products Added Yet</p>
                        )}
                      </div>

                      <Pagination
                        responsive
                        className="pagination"
                        current={pagination.page}
                        defaultCurrent={pagination.page || 1}
                        total={total}
                        pageSize={pagination.perpage}
                        onChange={(page, size) => {
                          setPagination({ page: page, perpage: size });
                          fetchCartDetails(page, size);
                          //@ts-ignore
                          pageTopRefCart.current.scrollIntoView();
                        }}
                      />
                      {/* <Pagination
                      defaultCurrent={pagination.page}
                      total={pagination.total}
                    /> */}
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <div className="cart-right" style={{ border: 'none' }}>
                      {/* {!appliedCoupon && (
                      <div
                        className={`coupon-container`}
                        onClick={() => setIsCouponModalOPen(true)}
                      >
                        <span className="icon1">
                          <InboxOutlined />
                        </span>
                        <span>Have a coupon code ?</span>
                        <span className="icon2">
                          <ChevronRight />
                        </span>
                      </div>
                    )}
                    <Modal
                      title="Coupon Code"
                      footer={null}
                      className="add-address-modal"
                      open={isCouponModalOPen}
                      onCancel={() => setIsCouponModalOPen(false)}
                    >
                      <Form
                        form={form}
                        layout="vertical"
                        // onFinish={({ couponCode: couponId }: any) => {
                        //   setCheckOutState({ ...checkOutState, couponId });
                        //   setIsCouponModalOPen(!isCouponModalOPen);
                        // }}

                        onFinish={({ couponCode }) => _applyCoupon(couponCode)}
                      >
                        <Row
                          gutter={{
                            xs: 8,
                            sm: 16,
                            md: 24,
                            lg: 24,
                            xl: 24,
                          }}
                        >
                          <Col xs={24} md={16}>
                            <Form.Item
                              className="modalLabels"
                              name="couponCode"
                            >
                              <Input
                                className="modalInputs couponInput"
                                prefix={<CardGiftcardIcon />}
                                placeholder="Enter Coupon Code Here...."
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={8}>
                            <Form.Item>
                              <Button
                                htmlType="submit"
                                className="theme-btn addAddressBtn couponBtn"
                              >
                                Apply Coupon
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Modal>

                    {!appliedCoupon ? (
                      <div className="coupon-container summary-container shipping-container">
                        <div className="top mb-2">
                          <h6 className="title flex align-items-center ">
                            Active Coupons
                            <img
                              src={activeCouponIcon}
                              alt="active-coupon-icon"
                              className="ml-1"
                            />
                          </h6>
                        </div>

                        <div className="couponList">
                          {activeCoupon.length === 0
                            ? "No Valid Coupons"
                            : activeCoupon.map(
                                (coupon: {
                                  code: string;
                                  description: string;
                                }) => {
                                  return (
                                    <div className="couponRow">
                                      <div className="couponRowLeft">
                                        <p className="name">{coupon.code}</p>
                                        <p className="desc">
                                          {coupon.description}
                                        </p>
                                      </div>

                                      <Button
                                        className="couponBtn"
                                        onClick={() => {
                                          _applyCoupon(coupon.code);
                                        }}
                                      >
                                        Apply
                                      </Button>
                                    </div>
                                  );
                                }
                              )}
                        </div>
                      </div>
                    ) : (
                      <div className="coupon-container summary-container shipping-container">
                        <div className="top ">
                          <h6 className="title flex align-items-center ">
                            <img
                              src={discountCoupon}
                              className="mr-2 icon"
                              alt="discount-icon"
                            />
                            {couponCode} Activated Successfully..!!
                            <Close
                              className="closeIcon"
                              onClick={() => {
                                setAppliedCoupon(false);
                                setDiscountDetails({
                                  discountAmount: 0,
                                  totalAmountAfterDiscount: 0,
                                  totalAmount: 0,
                                });
                              }}
                            />
                          </h6>
                        </div>
                      </div>
                    )} */}
                      <div className="coupon-container summary-container">
                        <h6 className="title">Summary</h6>
                        <div className="details details1 details2">
                          <p>
                            <b>Total Price Excl. of VAT </b>
                          </p>
                          <p className="totalPrice">
                            {currency === 'USD' ? '$' : 'AED'}{' '}
                            {consverPriceToInternationalFormat(
                              appliedCoupon
                                ? discountDetails?.totalAmount
                                : cartData?.totalAmount
                            )}
                          </p>
                        </div>
                        <div className="details details1 details2">
                          <p>
                            <b>Total VAT Amount </b>
                          </p>
                          <p className="totalPrice">
                            {currency === 'USD' ? '$' : 'AED'}{' '}
                            {consverPriceToInternationalFormat(
                              appliedCoupon
                                ? 0.05 * discountDetails?.totalAmount
                                : 0.05 * cartData?.totalAmount
                            )}
                          </p>
                        </div>
                        <div className="details details1 details2">
                          <p>
                            <b>Total Price Inc. of VAT </b>
                          </p>
                          <p className="totalPrice">
                            {currency === 'USD' ? '$' : 'AED'}{' '}
                            {consverPriceToInternationalFormat(
                              appliedCoupon
                                ? 0.05 * discountDetails?.totalAmount +
                                    discountDetails?.totalAmount
                                : 0.05 * cartData?.totalAmount +
                                    cartData?.totalAmount
                            )}
                          </p>
                        </div>

                        {appliedCoupon && (
                          <>
                            <div className="details details1">
                              <p>Discount Amount</p>
                              <p>
                                {currency === 'USD' ? '$' : 'AED'}{' '}
                                {consverPriceToInternationalFormat(
                                  discountDetails.discountAmount
                                )}
                              </p>
                            </div>
                            <Divider className="divider" />
                            <div className="details details1 details2">
                              <p>
                                <b>Price After Discount</b>
                              </p>
                              <p className="totalPrice">
                                {currency === 'USD' ? '$' : 'AED'}{' '}
                                {consverPriceToInternationalFormat(
                                  discountDetails.totalAmountAfterDiscount.toFixed(
                                    2
                                  )
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        <Divider dashed={true} className="divider" />
                        <div className="details details1 details2">
                          <p>
                            <b>Available Credit Limit </b>
                          </p>
                          <p>
                            {currency === 'USD' ? '$' : 'AED'}{' '}
                            {consverPriceToInternationalFormat(
                              cartData?.availableCreditLimit?.toFixed(2)
                            )}
                          </p>
                        </div>
                        <Divider dashed={true} className="divider" />
                        {/* <div className="details details1">
                          <p>Total CBM</p>
                          <p>{cartData?.totalCbm}</p>
                        </div> */}

                        {current < steps.length - 1 && (
                          <Button
                            className="theme-btn"
                            onClick={() => setCurrent(1)}
                          >
                            Checkout
                          </Button>
                        )}

                        <Button
                          className="theme-btn1"
                          onClick={() => history.push(HOME)}
                        >
                          Continue Shopping
                        </Button>
                      </div>
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={12}
                    ref={pageTopRefCheckout}
                  >
                    <div className="cart-left">
                      <div className="search-container">
                        {/* <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            // fetchCartDetails(searchInput);
                            fetchCartDetails(
                              1,
                              pagination.perpage,
                              searchInput
                            );
                          }}
                        >
                          <SearchOutlined className="icon" />
                          <input
                            placeholder="Search"
                            style={{ width: "100%" }}
                            // onKeyDown={handleKeyDown}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                          <Button htmlType="submit" hidden={true}>
                            Search
                          </Button>
                        </form> */}
                      </div>
                      <div className="cart-item-container">
                        {finalList.length !== 0 ? (
                          finalList.map((cart: any, index: number) => (
                            <CheckoutProductCard
                              key={
                                cart.free
                                  ? `${cart.productId}Chk`
                                  : cart.productId
                              }
                              appliedCoupon={appliedCoupon}
                              setAppliedCoupon={setAppliedCoupon}
                              setCouponCode={setCouponCode}
                              setDiscountDetails={setDiscountDetails}
                              cart={cart}
                            />
                          ))
                        ) : (
                          <p className="no-prod m-0">No Products Added Yet </p>
                        )}
                      </div>
                      <Pagination
                        responsive
                        className="pagination"
                        current={pagination.page}
                        defaultCurrent={pagination.page || 1}
                        total={total}
                        pageSize={pagination.perpage}
                        onChange={(page, size) => {
                          setPagination({ page: page, perpage: size });
                          fetchCartDetails(page, size);
                          //@ts-ignore
                          pageTopRefCheckout.current.scrollIntoView();
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <div className="cart-right">
                      <div className="coupon-container summary-container">
                        <h6 className="title">Summary</h6>
                        <div className="details details1 details2">
                          <p>
                            <b>Total Price Excl. of VAT </b>
                          </p>
                          <p className="totalPrice">
                            {currency === 'USD' ? '$' : 'AED'}{' '}
                            {consverPriceToInternationalFormat(
                              appliedCoupon
                                ? discountDetails?.totalAmount
                                : cartData?.totalAmount
                            )}
                          </p>
                        </div>
                        <div className="details details1 details2">
                          <p>
                            <b>Total VAT Amount </b>
                          </p>
                          <p className="totalPrice">
                            {currency === 'USD' ? '$' : 'AED'}{' '}
                            {consverPriceToInternationalFormat(
                              appliedCoupon
                                ? 0.05 * discountDetails?.totalAmount
                                : 0.05 * cartData?.totalAmount
                            )}
                          </p>
                        </div>
                        <div className="details details1 details2">
                          <p>
                            <b>Total Price Inc. of VAT </b>
                          </p>
                          <p className="totalPrice">
                            {currency === 'USD' ? '$' : 'AED'}{' '}
                            {consverPriceToInternationalFormat(
                              appliedCoupon
                                ? 0.05 * discountDetails?.totalAmount +
                                    discountDetails?.totalAmount
                                : 0.05 * cartData?.totalAmount +
                                    cartData?.totalAmount
                            )}
                          </p>
                        </div>
                        {appliedCoupon && (
                          <>
                            <div className="details details1">
                              <p>Discount Amount</p>
                              <p>
                                {currency === 'USD' ? '$' : 'AED'}{' '}
                                {consverPriceToInternationalFormat(
                                  discountDetails.discountAmount
                                )}
                              </p>
                            </div>
                            <Divider className="divider" />
                            <div className="details details1 details2">
                              <p>
                                <b>Price After Discount</b>
                              </p>
                              <p className="totalPrice">
                                {currency === 'USD' ? '$' : 'AED'}{' '}
                                {consverPriceToInternationalFormat(
                                  discountDetails.totalAmountAfterDiscount.toFixed(
                                    2
                                  )
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        <Divider dashed={true} className="divider" />
                        {/* <div className="details details1">
                          <p>Total CBM</p>
                          <p>{cartData?.totalCbm}</p>
                        </div> */}
                      </div>

                      <div className="coupon-container summary-container shipping-container">
                        <div className="top">
                          <h6 className="title">Shipping Address</h6>
                          <Button
                            className="theme-btn1"
                            onClick={() => showModal('Shipping Address')}
                            style={{ background: '#EBEBEB' }}
                          >
                            Add Address
                          </Button>

                          <Modal
                            title={modalTitle}
                            open={isModalOpen}
                            onCancel={() => {
                              handleCancel();
                              setEdit(false);
                            }}
                            footer={null}
                            className="add-address-modal"
                          >
                            <Form
                              form={form}
                              layout="vertical"
                              onFinish={edit ? updateAddress : addAddress}
                            >
                              <Row
                                gutter={{
                                  xs: 8,
                                  sm: 16,
                                  md: 24,
                                  lg: 24,
                                  xl: 24,
                                }}
                              >
                                {edit ? (
                                  <Col xs={24} md={12} hidden={true}>
                                    <Form.Item name="id">
                                      <Input
                                        placeholder="Name"
                                        required={true}
                                        hidden={true}
                                      />
                                    </Form.Item>
                                  </Col>
                                ) : (
                                  ''
                                )}
                                <Col xs={24} md={12}>
                                  <Form.Item
                                    className="modalLabels"
                                    label="Contact Person Name"
                                    name="name"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Enter Contact Person Name',
                                      },
                                      {
                                        pattern: /^[A-Za-z ]+$/,
                                        message:
                                          'Contact Person Name Cannot Be a number',
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="modalInputs"
                                      prefix={<img src={userPic} />}
                                      placeholder="Name"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                  <Form.Item
                                    className="modalLabels"
                                    label="Mobile Number"
                                    name="phone"
                                    tooltip="Enter Valid Phone Number"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          'Please Enter Valid Phone Number',
                                      },
                                      {
                                        min: 8,
                                        message:
                                          'Phone Number must between 8-13 Digits.',
                                      },
                                      {
                                        max: 13,
                                        message:
                                          'Phone Number must between 8-13 Digits.',
                                      },
                                    ]}
                                  >
                                    <Input
                                      type="number"
                                      className="modalInputs"
                                      prefix={<img src={smartphone} />}
                                      placeholder="Mobile Number"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24}>
                                  <Form.Item
                                    className="modalLabels"
                                    label="Line Address"
                                    name="address"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Enter Address',
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="modalInputs"
                                      prefix={<img src={mapPin} />}
                                      placeholder="Line Address"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={24} md={12}>
                                  <Form.Item
                                    className="modalLabels"
                                    label="Building"
                                    name="building"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Building Details Required',
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="modalInputs"
                                      prefix={<img src={home} />}
                                      placeholder="Building"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                  <Form.Item
                                    label="Street"
                                    name="street"
                                    className="modalLabels"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Street Required',
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="modalInputs"
                                      prefix={<img src={map} />}
                                      placeholder="Street"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                  <Form.Item
                                    label="Area"
                                    name="area"
                                    className="modalLabels"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Area Details Required',
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="modalInputs"
                                      prefix={<img src={mapPin} />}
                                      placeholder="Area"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                  <Form.Item
                                    className="modalLabels"
                                    label="PO Box/Zip Code"
                                    name="zipcode"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Zip Code / PO Box Required',
                                      },
                                    ]}
                                  >
                                    <Input
                                      type="number"
                                      className="modalInputs"
                                      prefix={<img src={mailIcon} />}
                                      placeholder="PO Box/Zip Code"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                  <Form.Item
                                    label="City"
                                    name="city"
                                    className="modalLabels"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'City Name Required ',
                                      },
                                    ]}
                                  >
                                    {/* <Input
                        className="modalInputs"
                        prefix={<img src={mailIcon} />}
                        placeholder="City"
                      /> */}
                                    <span className="select-with-prefix">
                                      <img
                                        src={mailIcon}
                                        className="select-prefix-icon"
                                      />
                                      <Select
                                        className="select-with-prefix-input"
                                        placeholder={'City'}
                                        showSearch
                                        onChange={(v: any) => {
                                          // const newValue = JSON.parse(v);
                                          // setOrderBy({
                                          //   order: newValue.order,
                                          //   orderBy: newValue.orderBy,
                                          // });
                                        }}
                                      >
                                        {cityList.length !== 0
                                          ? cityList.map((city: ICity) => {
                                              return (
                                                <Option
                                                  key={city.name}
                                                  value={city.name}
                                                >
                                                  {city.name}
                                                </Option>
                                              );
                                            })
                                          : ''}
                                      </Select>
                                    </span>
                                  </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                  <Form.Item
                                    label="Country"
                                    name="country"
                                    className="modalLabels modalLabelsSelect"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Country Name Required ',
                                      },
                                    ]}
                                  >
                                    <Input
                                      disabled
                                      prefix={<img src={globe} />}
                                      placeholder="Country"
                                      className="modalInputs"
                                    />
                                  </Form.Item>
                                  {/* <Form.Item
                                    label="Country"
                                    name="country"
                                    className="modalLabels modalLabelsSelect"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Country Name Required ",
                                      },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        //@ts-ignore
                                        option?.children
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                      suffixIcon={
                                        <img
                                          src={globe}
                                          // className="banner-form-select-icon"
                                          // alt="icon"
                                          // style={{
                                          //   height: "21px",
                                          //   width: '21px',
                                          //   objectFit: 'contain',

                                          // }}
                                        />
                                      }
                                      placeholder="Select Country"
                                      className="banner-form-select"
                                    >
                                      {countryList.map((country) => {
                                        return (
                                          <Select.Option
                                            value={country}
                                            className="modalInputs"
                                          >
                                            <img
                                            src={globe}
                                            className="banner-form-select-icon"
                                            alt="icon"
                                            style={{
                                              height: "21px",
                                              width: "21px",
                                              objectFit: "contain",
                                            }}
                                          />
                                            {country}
                                          </Select.Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item> */}
                                </Col>
                                <Col
                                  xs={24}
                                  style={{
                                    textAlign: 'center',
                                    marginTop: '40px',
                                  }}
                                >
                                  <Form.Item>
                                    {edit ? (
                                      <Button
                                        htmlType="submit"
                                        className="theme-btn addAddressBtn"
                                      >
                                        Update Address
                                      </Button>
                                    ) : (
                                      <Button
                                        htmlType="submit"
                                        className="theme-btn addAddressBtn"
                                      >
                                        Add Address
                                      </Button>
                                    )}
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </Modal>
                        </div>

                        <div
                          style={{
                            maxHeight: '270px',
                            overflowY: 'auto',
                            width: '100%',
                          }}
                        >
                          {address.map((addr: any) => {
                            return (
                              <div className="address-row" key={`${addr.id}SA`}>
                                <input
                                  type="radio"
                                  // value="CSS"
                                  name="shipping-address"
                                  id={`${addr.id}SA`}
                                  onChange={() =>
                                    setCheckOutState({
                                      ...checkOutState,
                                      shippingAddressId: addr.id,
                                    })
                                  }
                                />

                                <label htmlFor={`${addr.id}SA`}>
                                  <p className="name">
                                    {addr.name}
                                    <span>
                                      <Button
                                        className="theme-btn1"
                                        onClick={() => {
                                          showModal('Shipping Address');
                                          editAddress(addr);
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </span>
                                  </p>
                                  <p className="address">
                                    {addr.building},{addr.address},{addr.street}
                                    , {addr.area},{addr.city}, {addr.country}
                                  </p>
                                  <p className="phone">
                                    Phone Number: {addr.phone}
                                  </p>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="coupon-container summary-container billing-container">
                        <div className="top">
                          <h6 className="title">Billing Address</h6>
                          <Button
                            className="theme-btn1"
                            onClick={() => showModal('Billing Address')}
                          >
                            Add Address
                          </Button>
                        </div>
                        <div
                          style={{
                            maxHeight: '270px',
                            overflowY: 'auto',
                            width: '100%',
                          }}
                        >
                          <div className="address-row">
                            <input
                              type="radio"
                              // value="address1"
                              disabled={
                                checkOutState.shippingAddressId ? false : true
                              }
                              name="billing-address"
                              id="sameasshippingadddr"
                              onChange={() =>
                                setCheckOutState({
                                  ...checkOutState,
                                  sameAsShipping: !checkOutState.sameAsShipping,
                                  billingAddressId:
                                    checkOutState.shippingAddressId,
                                })
                              }
                            />
                            <label htmlFor="sameasshippingadddr">
                              <p className="name">Same as Shipping Address</p>
                            </label>
                          </div>

                          {address.map((addr: any) => {
                            return (
                              <div className="address-row" key={`${addr.id}BA`}>
                                <input
                                  type="radio"
                                  value="CSS"
                                  id={`${addr.id}BA`}
                                  name="billing-address"
                                  onChange={() =>
                                    setCheckOutState({
                                      ...checkOutState,
                                      billingAddressId: addr.id,
                                    })
                                  }
                                />
                                <label htmlFor={`${addr.id}BA`}>
                                  <p className="name">
                                    {addr.name}
                                    <span>
                                      <Button
                                        className="theme-btn1"
                                        onClick={() => {
                                          showModal('Billing Address');
                                          editAddress(addr);
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </span>
                                  </p>
                                  <p className="address">
                                    {addr.building},{addr.address},{addr.street}
                                    , {addr.area},{addr.city}, {addr.country}
                                  </p>
                                  <p className="phone">
                                    Phone Number: {addr.phone}
                                  </p>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="coupon-container summary-container">
                        <h6 className="title">Preffered Time Slot</h6>
                        <div className="address-row">
                          <input
                            type="radio"
                            value="morning"
                            id="morning"
                            name="timeslot"
                            checked={checkOutState.deliverySlot === 'Morning'}
                            onChange={(e) => {
                              setCheckOutState({
                                ...checkOutState,
                                deliverySlot: 'Morning',
                              });
                            }}
                          />
                          <label htmlFor="morning">
                            <p className="name">Morning (8AM TO 12 PM)</p>
                          </label>
                        </div>
                        <div className="address-row">
                          <input
                            type="radio"
                            value="afternoon"
                            id="afternoon"
                            checked={checkOutState.deliverySlot === 'Afternoon'}
                            onChange={(e) =>
                              setCheckOutState({
                                ...checkOutState,
                                deliverySlot: 'Afternoon',
                              })
                            }
                            name="timeslot"
                          />
                          <label htmlFor="afternoon">
                            <p className="name">Afternoon (12 PM TO 4PM)</p>
                          </label>
                        </div>
                        <div className="address-row">
                          <input
                            type="radio"
                            id="evening"
                            checked={checkOutState.deliverySlot === 'Evening'}
                            onChange={(e) =>
                              setCheckOutState({
                                ...checkOutState,
                                deliverySlot: 'Evening',
                              })
                            }
                            value="evening"
                            name="timeslot"
                          />
                          <label htmlFor="evening">
                            <p className="name">Evening ( 4PM TO 8PM)</p>
                          </label>
                        </div>
                      </div>

                      {cartProducts?.length !== 0 ? (
                        <Button
                          onClick={handlePlaceOrder}
                          className="theme-btn"
                        >
                          Place Order
                        </Button>
                      ) : (
                        <Button
                          onClick={handlePlaceOrder}
                          className="theme-btn"
                          disabled
                        >
                          Place Order
                        </Button>
                      )}
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Layout>
        </Content>
      </Layout>
    </>
  );
}
