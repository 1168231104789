import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import voucherIcon from "shared/assets/images/voucher.png";

import { Button, notification } from "antd";

import AddToCartBtn from "components/AddToCartBtn";
import API_SERVICE from "services/api-service";
import "./index.scss";
import { PRODUCT_DETAILS_PAGE } from "shared/constants/RouteConstants";

import MilanoThumbnail from "../../assets/MilanoThumbnail.png";

import { consverPriceToInternationalFormat } from "services/util";

const ProductCard = (props: any) => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user")!);
  const currency = user?.user.dealerDetails[0]?.currencyType;

  const truncate = (title: string) => {
    return title.length > 50 ? title.substring(0, 50) + "..." : title;
  };

  const handleClick = () => {
    history.push(
      `${PRODUCT_DETAILS_PAGE}/${props.product.id}/${props.product.category.name}`
    );
  };

  const addToWIshlistHandle = async (
    productId: number,
    isWishlisted: boolean,
    isHandleMoveCart?: boolean
  ) => {
    if (isWishlisted) {
      try {
        await API_SERVICE.WishListDelete(productId);

        if (isHandleMoveCart) {
          notification.success({
            message: "Removed From Wishlist",
            placement: "bottomLeft",
          });
          props.callback();
        }
      } catch (error) {
        notification.error({
          message: "Something Went Wrong",
          placement: "bottomLeft",
        });
      }
    } else {
      try {
        await API_SERVICE.addToWishList(productId);
        notification.success({
          message: "Added to Wishlist",
          placement: "bottomLeft",
        });
        props.callback();
      } catch (err) {
        notification.error({
          message: API_SERVICE.handleErrors(err),
          placement: "bottomLeft",
        });
      }
    }
  };

  const fileUrl = props.product.productImages[0]?.file?.url;

  return (
    <div style={{ cursor: "pointer" }} className="productCardMain">
      <div className="offer-line">
        {/* {props.product?.buyGetOffers &&
          props.product?.buyGetOffers.map((item: any) => {
            return (
              <p key={item.id} className="product-detail-offer-text">
                Buy {item.buyQty} Get {item.getQty} free
              </p>
            );
          })} */}
        {props.product?.buyGetOffers && props.product?.buyGetOffers[0] && (
          <p className="product-detail-offer-text">
            Buy {props.product?.buyGetOffers[0]?.buyQty} Get{" "}
            {props.product?.buyGetOffers[0]?.getQty} free
          </p>
        )}
      </div>
      <div className="product-image">
        <img
          src={
            props.product.thumbnail !== null
              ? props.product.thumbnail.url
              : MilanoThumbnail
          }
          onClick={handleClick}
          alt="product"
        />
      </div>
      <div className="product-details">
        <h3 className="title" onClick={handleClick}>
          {" "}
          {truncate(props.product?.name)}
        </h3>
        <p className="price">
          {currency === "USD" ? "$" : "AED"}{" "}
          {consverPriceToInternationalFormat(props.product?.price)}
          {parseInt(props.product?.cartoonQuantity) > 1 && (
            <span> per piece</span>
          )}
        </p>
        <div className="inventory-info">
          <div>
            <p>SKU</p>
            <p>{props.product?.skuId}</p>
          </div>
          <div>
            {parseInt(props.product?.cartoonQuantity) > 1 && (
              <p className="theme-p">
                (Set Of {props.product?.cartoonQuantity})
              </p>
            )}
            <p>{props.product?.label?.labels}</p>
          </div>
        </div>
        <div className="AddToCartBtnContainer">
          <AddToCartBtn
            name=""
            quantity={parseInt(props.product.cartoonQuantity)}
            orderCoupon={null}
            productId={props.product.id}
          />
          <Button
            className="wishlistBtn"
            onClick={() => {
              console.log(props);
              addToWIshlistHandle(
                props.product.id,
                props.product.isWishlisted,
                true
              );
            }}
          >
            {props.product.isWishlisted ? <HeartFilled /> : <HeartOutlined />}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
