import {
  STATUS_CONFIRMED,
  STATUS_DELIVERED,
  STATUS_LPO_SIGNED,
  STATUS_LPO_SIGN_OFF,
  STATUS_NEW,
  STATUS_PROCESSING,
  STATUS_SHIPPED,
  STATUS_REJECTED,
  OFFER_TYPE,
} from "../constants/Constants";

export const filteredList = (searchKey: string, items: any[]) => {
  if (!searchKey) {
    return items;
  }
  return items.filter((c: any) => {
    let passed = false;
    for (const key in c) {
      const value = c[key];
      if (
        value &&
        typeof value === "string" &&
        value.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
      ) {
        passed = true;
      }
    }
    return passed;
  });
};
export const getScopeTableData = (questionId: any) => {
  const scopeTableData = localStorage.getItem("scopeTableData")
    ? JSON.parse(localStorage.getItem("scopeTableData") as string)
    : {};
  return scopeTableData[questionId] ? [...scopeTableData[questionId]] : [{}];
};

function parseDotNotation(str: string, val: any, obj: any) {
  let currentObj = obj,
    keys = str.split("."),
    i,
    l = Math.max(1, keys.length - 1),
    key;

  for (i = 0; i < l; ++i) {
    key = keys[i];
    currentObj[key] = currentObj[key] || {};
    currentObj = currentObj[key];
  }

  currentObj[keys[i]] = val;
  delete obj[str];
}

export function dotToObject(obj: any) {
  for (let key in obj) {
    if (key.indexOf(".") !== -1) {
      parseDotNotation(key, obj[key], obj);
    }
  }
  return obj;
}

export function objectToDot(obj: any) {
  let res: any = {};
  (function recurse(obj, current) {
    for (let key in obj) {
      let value = obj[key];
      let newKey = current ? current + "." + key : key;
      if (value && typeof value === "object") {
        // @ts-ignore
        recurse(value, newKey);
      } else {
        res[newKey] = value;
      }
    }
  })(obj);
  return res;
}

export function roundOfValue(
  value: any,
  decimal = 2,
  forceReturnNumber = true
) {
  value = parseFloat(value);
  if (isNaN(value)) {
    return forceReturnNumber ? 0 : value;
  }
  return Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

export function removeSpecialCharacters(value: string) {
  return value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, " ");
}

export function adminOrderStatusText(status: string) {
  switch (status) {
    case STATUS_NEW:
      return "LPO generation pending";
      break;
    case STATUS_LPO_SIGN_OFF:
      return "LPO Sign Off Pending";
      break;
    case STATUS_LPO_SIGNED:
      return "Approval Pending";
      break;
    case STATUS_PROCESSING:
      return "Processing";
      break;
    case STATUS_REJECTED:
      return "Rejected";
      break;
    case STATUS_CONFIRMED:
      return "Confirmed";
      break;
    case STATUS_SHIPPED:
      return "Shipped";
      break;
    case STATUS_DELIVERED:
      return "Delivered";
      break;
    default:
      return "-";
  }
}

export function salesOrderStatusText(status: string) {
  switch (status) {
    case STATUS_NEW:
      return "LPO Generation Pending";
    case STATUS_LPO_SIGN_OFF:
      return "LPO Sign Off Pending";
    case STATUS_LPO_SIGNED:
      return "Approval Pending";
    case STATUS_PROCESSING:
      return "Processing";
    case STATUS_REJECTED:
      return "Rejected";
    case STATUS_CONFIRMED:
      return "Confirmed";
    case STATUS_SHIPPED:
      return "Shipped";
    case STATUS_DELIVERED:
      return "Delivered";
    default:
      return "-";
  }
}

export function dealerOrderStatusText(status: string) {
  switch (status) {
    case STATUS_NEW:
      return "New";
    case STATUS_LPO_SIGN_OFF:
      return "LPO Sign Off Required";
    case STATUS_LPO_SIGNED:
      return "Approval Pending";
    case STATUS_PROCESSING:
      return "Processing";
    case STATUS_REJECTED:
      return "Rejected";
    case STATUS_CONFIRMED:
      return "Confirmed";
    case STATUS_SHIPPED:
      return "Shipped";
    case STATUS_DELIVERED:
      return "Delivered";
    case "PENDING":
      return "Pending";
    case "APPROVED":
      return "Approved";
    default:
      return "-";
  }
}
export function CouponTypeText(type: string) {
  switch (type) {
    case OFFER_TYPE.COUPON:
      return "COUPON TYPE";
    case OFFER_TYPE.BUY_X_GET_Y:
      return "BUY X GET Y";
    default:
      return "-";
  }
}

export function DownloadLink(url: string) {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.click();
}
