import { PercentageOutlined } from "@ant-design/icons";
import { notification, Space } from "antd";
import NotificationContext from "context/Notification";
import moment from "moment";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import API_SERVICE from "services/api-service";
import {
  ORDER_DETAILS,
  PRODUCT_DETAILS_PAGE,
  PRODUCT_MAIN,
  SALES_ORDER_DETAILS,
} from "shared/constants/RouteConstants";
import MilanoThumbnail from "../../assets/MilanoThumbnail.png";

import "./index.scss";

interface Notification {
  notification: {
    id: number;
    data: string;
    isRead: boolean;
    sourceModel: string;
    title: string;
    sourceId: number;
    sourceModal: string;
    createdAt: Date;
  };
}

const NotificationCard = (props: Notification) => {
  const { _fetchUnreadCount } = useContext(NotificationContext);
  const history = useHistory();
  const data = JSON.parse(props?.notification.data);
  const user = JSON.parse(localStorage.getItem("user")!);
  const roleId = user?.user?.roles[0]?.pivot?.roleId;

  const _createUrl = (obj: any) => {
    if (obj.notification_type === "CATEGORY") {
      history.push(`${PRODUCT_MAIN}/${obj.type_id}/${obj.type_details.name}`);
    }
    if (obj.notification_type === "PRODUCT") {
      history.push(
        `${PRODUCT_DETAILS_PAGE}/${obj.type_id}/${obj.type_details.category.name}`
      );
    }
    if (obj.notification_type === "CUSTOM") {
      window.open(obj.customUrl, "_blank");
    }
  };

  const _statusChange = async (id: number) => {
    try {
      await API_SERVICE.statusChangeNotification(id);
      _fetchUnreadCount();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };

  const _handleClick = (data: any) => {
    if (props.notification.sourceModel === "Order") {
      history.push(
        `${roleId === 3 ? ORDER_DETAILS : SALES_ORDER_DETAILS}/${data.id}`
      );
    } else if (props.notification.sourceModel === "Product") {
      history.push(`${PRODUCT_DETAILS_PAGE}/${data.id}/${data.category.name}`);
    } else {
      _createUrl(data);
    }

    _statusChange(props.notification.id);
  };

  return (
    <div className="notification-detail-card">
      <div className="image">
        <img
          onClick={() => _handleClick(data)}
          src={
            (data.hasOwnProperty("file") && data.file.url) || MilanoThumbnail
          }
          alt={props.notification.title}
        />
        {data.hasOwnProperty("description") && (
          <span className="icon">
            <PercentageOutlined />
          </span>
        )}
      </div>
      <div className="details">
        <h2 className="title" onClick={() => _handleClick(data)}>
          {props.notification.title}
        </h2>
        <p className="info">
          {data.hasOwnProperty("description") && data.description}
          {/* We recommend a <b>10%</b> discount for 100 units of Sanitaryware
          products on <b>brands era, hindware and Winder</b> */}
        </p>
        <span className="time-tag">
          {moment
            .utc(props.notification.createdAt)
            .local()
            .startOf("seconds")
            .fromNow()}
        </span>
      </div>
    </div>
  );
};

export default NotificationCard;
