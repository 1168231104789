import {
  PlusOutlined,
  DeleteOutlined,
  DownloadOutlined,
  UploadOutlined,
  EditOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./index.scss";

import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Table,
  Tree,
  TreeSelect,
} from "antd";

import API_SERVICE from "services/api-service";
import { CheckBox } from "@material-ui/icons";
import { error } from "console";
import CreateBuyGetOffer from "./CreateBuyGetOffer";
import AccessControl from "services/AccessControl";

const { SHOW_PARENT } = TreeSelect;
const BuyXGetYList = (props: any) => {
  let history = useHistory();
  const [form] = Form.useForm();
  const [offerList, setOfferList] = useState([]);
  const [filterAttributes, setFilterAttributes] = useState({});
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: 15,
  } as any);

  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);
  const [categorySelectAll, setCategorySelectAll] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [downloadSampleUrl, setDownloadSampleUrl] = useState("");
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [editId, setEditId] = useState();
  const [productId, setProductId] = useState();
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [bulkFile, setBulkFile] = useState({
    name: " ",
    size: 0,
  });
  const offerId = props?.match?.params?.id;

  const columns = [
    {
      title: "PRODUCT NAME",
      dataIndex: "product",
      width: "25%",
      render: (product: any) => product.name,
    },
    {
      title: "PRODUCT SKU",
      dataIndex: "product",
      key: "sku",
      width: "15%",
      render: (product: any) => product.skuId,
    },
    {
      title: "CATEGORY",
      dataIndex: "product",
      width: "15%",
      render: (product: { category: { name: string } }) =>
        product.category.name,
    },
    {
      title: "VALUE X",
      dataIndex: "buyQty",
      width: "10%",
    },
    {
      title: "VALUE Y",
      dataIndex: "getQty",
      width: "10%",
    },
    {
      title: "ACTION: ",
      key: "action",
      render: (a: any) => (
        <AccessControl id={47}>
          <Button
            shape="circle"
            onClick={() => {
              fillForm(a?.productId);
              setEditId(a?.id);
              setProductId(a?.productId);
              setIsModalEditOpen(true);
            }}
            className="mr-2"
          >
            <EditOutlined />
          </Button>
          <Button
            shape="circle"
            onClick={() => deleteProductOfGetBuyOffers(a?.id)}
          >
            <DeleteOutlined />
          </Button>
        </AccessControl>
      ),
    },
  ];
  const fillForm = async (productId: any) => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.getSingleBuyOffer(offerId, productId);
      console.log("ddddddddddd", data);
      form.setFieldsValue({
        valueX: data.buyQty,
        valueY: data.getQty,
      });
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    } finally {
      setIsModalOpen(false);
      getAllOffers();
    }
  };
  const editOffer = async (values: any) => {
    const reqObj = {
      offerId,
      productId: productId,
      buyQty: values.valueX,
      getQty: values.valueY,
    };
    try {
      await API_SERVICE.editGetBuyOffers(reqObj, editId);
      notification.success({
        message: "Offer Edited successfully",
        placement: "bottomRight",
      });
      setIsModalEditOpen(false);
      form.resetFields();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    } finally {
      setIsModalOpen(false);
      getAllOffers();
    }
  };

  const _handleTableChanges = async (
    pagination,
    filters,
    sorter,
    extra: { currentDataSource; action }
  ) => {
    if (extra.action === "paginate") {
      setPagination({
        page: pagination.current,
        perpage: pagination.pageSize,
      });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };

  //handleBulkUpload
  const handleBulkUpload = async (e: any) => {
    e.preventDefault();
    try {
      await API_SERVICE.handleBulkUpload(bulkFile);
      notification.success({
        message: "Uploaded successfully",
        placement: "bottomRight",
      });
      setBulkFile({ name: " ", size: 0 });
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  const getBuyOfferSample = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.getBuyOfferSample();
      if (data) {
        setDownloadSampleUrl(data.url);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  /*=================== listing offer data ===================*/

  const deleteProductOfGetBuyOffers = async (id: number) => {
    try {
      setLoading(true);
      await API_SERVICE.deleteProductOfGetBuyOffers(id);
      notification.success({
        message: `Offer Deleted`,
        placement: "bottomRight",
      });
      getAllOffers();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    } finally {
      setLoading(false);
    }
  };

  const getAllOffers = async (pagination = {}) => {
    const params = {
      ...pagination,
      offerId,
    };

    try {
      setLoading(true);
      const {
        data: { data },
      } = await API_SERVICE.getAllGetBuyOffers(params);
      if (data.items) {
        setOfferList(data.items);
        setTotal(data.total);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOffers(pagination);
  }, [pagination, sort, filters]);

  useEffect(() => {
    getBuyOfferSample();
  }, []);

  return (
    <>
      <CreateBuyGetOffer
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        offerId={offerId}
        getAllOffers={getAllOffers}
      />
      <Modal
        title={<p className="stockLabelModalTitle">Edit Items</p>}
        footer={null}
        className=" admin-buyxgety-modal"
        open={isModalEditOpen}
        onCancel={() => {
          setIsModalEditOpen(false);
          form.resetFields();
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => editOffer(values)}
        >
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 24,
              xl: 24,
            }}
          >
            <Col xs={24}>
              <div className="stockLabelActionContainer buyXGetYContainer">
                <Row className="header">
                  <Col xs={12}>Value X</Col>
                  <Col xs={12}>Value Y</Col>
                </Row>

                <div className="labelRowCss">
                  <Row className="row">
                    <Col xs={12} className="p-1">
                      <Form.Item
                        name={"valueX"}
                        className="m-0"
                        rules={[
                          {
                            required: true,
                            message: "Enter Value X",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          className="inputFields text-center"
                          placeholder="Enter Value"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} className="p-1">
                      <Form.Item
                        name={"valueY"}
                        className="m-0"
                        rules={[
                          {
                            required: true,
                            message: "Enter Value Y",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          className="inputFields text-center"
                          placeholder="Enter Value"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col xs={24} className="flex justify-content-center mt-3">
              <Form.Item className="stockLabelBtnContainer">
                <Button htmlType="submit" className="theme-btn   ">
                  Edit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Bulk Upload"
        footer={null}
        className="add-address-modal "
        open={bulkUploadModal}
        onCancel={() => setBulkUploadModal(false)}
      >
        <form onSubmit={handleBulkUpload}>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 24,
              xl: 24,
            }}
          >
            <Col xs={24} sm={24} md={16} lg={24} xl={16}>
              <a href={downloadSampleUrl} download>
                <div className="fileUploadDesign" style={{ height: "45px" }}>
                  <div className="text-truncate">
                    <DownloadOutlined className="icon" />
                    Download Sample
                  </div>
                  <p className="text">Download</p>
                </div>
              </a>
            </Col>
            <Col xs={24} sm={24} md={16} lg={24} xl={16}>
              <input
                type="file"
                id="uploadFile"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: "none" }}
                onChange={(e: any) => setBulkFile(e?.target?.files[0])}
              ></input>
              <label htmlFor="uploadFile">
                <p>Upload File</p>
                <div className="fileUploadDesign">
                  <div>
                    <UploadOutlined className="icon" />

                    {bulkFile?.name !== " " ? (
                      <span className="text">{bulkFile?.name}</span>
                    ) : (
                      "Select File"
                    )}
                  </div>
                  <p className="text">Upload</p>
                </div>
              </label>
            </Col>
            <Col xs={24} className="flex justify-content-center mt-3">
              <div className="stockLabelBtnContainer">
                <Button htmlType="submit" className="theme-btn">
                  Upload
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </Modal>

      <Row
        className="align-items-center stock-summary-container mb-2 mt-2"
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}
        style={{ padding: "10px" }}
      >
        <Col xs={24} md={12}>
          <div className="manage-products-title">BUY X GET Y</div>
        </Col>
        <Col
          xs={24}
          md={12}
          className="flex justify-content-end buyXGetYHeader"
        >
          <Button
            type="primary"
            className="theme-btn sales-table-button ml-2 product-btn "
            onClick={() => {
              setBulkUploadModal(true);
            }}
          >
            <PlusOutlined />
            Bulk Upload
          </Button>
          <Button
            type="primary"
            className="theme-btn sales-table-button ml-2 product-btn "
            onClick={() => setIsModalOpen(true)}
          >
            <PlusOutlined />
            Add More Items
          </Button>
        </Col>
      </Row>
      <Row className="manage-order-main-content">
        <Col xs={24} className="mb-3">
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={offerList}
            size="middle"
            pagination={{
              size: "default",
              current: pagination.page,
              pageSize: pagination.perpage,
              total: total,
            }}
            onChange={_handleTableChanges}
          />
        </Col>
      </Row>
    </>
  );
};

export default BuyXGetYList;
