import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./ManageOrder.scss";

import {
  DownOutlined,
  EyeOutlined,
  SearchOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Layout,
  notification,
  Row,
  Table,
  Modal,
} from "antd";
import moment from "moment";
import { useEffect } from "react";

import API_SERVICE from "services/api-service";
import {
  consverPriceToInternationalFormat,
  textTransform,
} from "services/util";
import {
  NEW_ORDER,
  SALES_DASHBOARD,
  SALES_ORDER_DETAILS,
  SALES_PENDING_CART,
} from "shared/constants/RouteConstants";
import modal from "antd/lib/modal";

const { Content } = Layout;

const ManageOrder = () => {
  const user = JSON.parse(localStorage.getItem("user")!);
  const roleId = user?.user?.roles[0]?.pivot?.roleId;
  let history = useHistory();
  const [filterAttributes, setFilterAttributes] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: "15",
  } as any);
  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const viewOrderClick = (id?: number) => {
    history.push(`${SALES_ORDER_DETAILS}/${id}`, id);
  };

  const _handleSearch = (event: any) => {
    if (event.which === 13) {
      setSearch({ q: event.target.value });
      setPagination((prev: { perpage: number }) => {
        return { page: 1, perpage: prev.perpage };
      });
      fetchOrders({ page: 1, perpage: pagination.perpage }, sort, filters, {
        q: event.target.value,
      });
    }
  };

  const fetchOrders = async (
    pagination = {},
    sort = {},
    filters = {},
    search = {}
  ) => {
    try {
      const params = {
        ...pagination,
        ...sort,
        ...filters,
        ...search,
      };
      const {
        data: { data },
      } = await API_SERVICE.fetchOrders(params);
      data.items.forEach((item: { status: string }) => {
        item.status = item.status.toLowerCase();
      });
      setLoading(false);
      if (data) {
        setFilteredData(data.items);
        setTotal(data?.total);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  const _handleTableChanges = async (
    pagination: { current: any; pageSize: any },
    filters: any,
    sorter: { order: string; columnKey: any },
    extra: { currentDataSource: any; action: string }
  ) => {
    if (extra.action === "paginate") {
      setPagination({
        page: pagination.current,
        perpage: pagination.pageSize,
      });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };

  const getAllAttributes = async () => {
    const {
      data: { data },
    } = await API_SERVICE.fetchSalesManageOrderAttributes();
    setFilterAttributes(data);
  };

  useEffect(() => {
    fetchOrders(pagination, sort, filters, search);
  }, [sort, filters, pagination]);

  useEffect(() => {
    getAllAttributes();
  }, []);
  function DataModal(reason: any) {
    Modal.info({
      title: "Reason for Cancellation",
      content: <div className="modal_data_wrapper">{reason[0].reason}</div>,
      onOk() {},
    });
  }

  const columns = [
    {
      title: "S.N.",
      dataIndex: "serialNumber",
      render: (a: any, v: any, index: number) => (
        <div>{(pagination.page - 1) * pagination.perpage + index + 1}</div>
      ),
    },
    {
      title: "ORDER ID ",
      key: "orderNumber",
      className: "orderid",
      width: 120,
      filters:
        filterAttributes && filterAttributes.orderNumber
          ? filterAttributes?.orderNumber.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,

      render: (d: any) => {
        return (
          <div
            onClick={() => {
              viewOrderClick(d?.id);
            }}
          >
            {d?.orderNumber}
          </div>
        );
      },
    },
    {
      title: "PLACED ON  ",
      className: "placedon",
      key: "date",
      filters:
        filterAttributes && filterAttributes.date
          ? filterAttributes?.date.map((item: any) => {
              return {
                text: moment(item.date).format("DD/MM/YYYY"),
                value: item.date,
              };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (d: any) => {
        return moment(d?.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      title: "TOTAL CBM  ",
      dataIndex: "totalCbm",
      key: "totalCbm",
      filters:
        filterAttributes && filterAttributes.totalCbm
          ? filterAttributes?.totalCbm.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
    },
    {
      title: "DEALER NAME & CODE",
      dataIndex: "user",
      key: "userId",
      filters:
        filterAttributes && filterAttributes.userId
          ? filterAttributes?.userId.map((item: any) => {
              return {
                text: item.name + " | " + item.dealerCode,
                value: item.id,
              };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (d: any) => {
        return (
          <>
            {d.name}
            <br />
            {d.dealerCode}
          </>
        );
      },
    },
    {
      title: "TOTAL AMOUNT ",
      // dataIndex: "totalAmount",
      render: (d: any) => {
        return (
          <>
            AED {consverPriceToInternationalFormat(d.totalAmount)}
            {/* {d.totalAmount} */}
          </>
        );
      },
    },

    {
      title: "PLACED BY ",
      dataIndex: "user",
      key: "placedById",
      filters:
        filterAttributes && filterAttributes.placedById
          ? filterAttributes?.placedById.map((item: any) => {
              return {
                text: item.name + " | " + item.email,
                value: item.id,
              };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (d: any) => {
        return (
          <>
            {d.name}
            <br />
            {d.email}
          </>
        );
      },
    },
    {
      title: "STATUS ",
      key: "status",
      // dataIndex: "status",
      filters:
        filterAttributes && filterAttributes.status
          ? filterAttributes?.status.map((item: any) => {
              return { text: textTransform(item), value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (d: any) => {
        return (
          <>
            <div className="text-capitalize">
              {d.status === "lpo_sign_off"
                ? "LPO Signed Off required"
                : textTransform(d.status)}
              {d.status === "rejected" && (
                <>
                  {" "}
                  <InfoCircleOutlined
                    onClick={() => DataModal(d?.orderCancellationReason)}
                  />
                </>
              )}
              <Modal
                maskStyle={{
                  backgroundColor: "rgba(0,0,0,0.1)",
                }}
                title="Reason for cancellation"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
              ></Modal>
            </div>
          </>
        );
      },
    },
    {
      title: "ACTION ",
      // dataIndex: "action",
      render: (a: any) => (
        <Button
          shape="circle"
          onClick={() => {
            viewOrderClick(a.id);
          }}
        >
          <EyeOutlined />
        </Button>
      ),
    },
  ];

  return (
    <>
      <Layout className="products-bg-color">
        <Content className="contentPadding">
          <Row
            className="align-items-center  stock-summary-container mb-2 mt-2"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
            }}
          >
            <Col xs={24}>
              <Breadcrumb
                className="products-breadcrumb"
                style={{
                  margin: "16px 0",
                }}
                separator=">"
              >
                <Breadcrumb.Item>
                  <Link to={SALES_DASHBOARD}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item className="products-breadcrumb-active">
                  Manage Orders
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col xs={24} sm={24} md={5} lg={6} xl={6}>
              <h1 className="page-title sales-page-title">Manage Orders</h1>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={19}
              lg={18}
              xl={18}
              className="flex justify-content-end"
            >
              <Row>
                <Col
                  xs={24}
                  md={roleId === 4 ? 24 : 12}
                  lg={roleId === 4 ? 24 : 12}
                  className="sales-table-search "
                >
                  Search
                  <Input
                    onKeyPress={_handleSearch}
                    className="sales-search"
                    placeholder="Search..."
                    prefix={<SearchOutlined />}
                  />
                </Col>
                {roleId === 6 && (
                  <Col
                    xs={24}
                    md={12}
                    lg={12}
                    className="sales-table-button-container pl-5 "
                  >
                    <Button
                      type="primary"
                      className="theme-btn sales-table-button"
                      onClick={() => history.push(SALES_PENDING_CART)}
                    >
                      <PlusOutlined />
                      Pending Cart
                    </Button>
                    {/* New Order commented for my milano */}
                    {/* <Button
                      type="primary"
                      className="theme-btn sales-table-button"
                      onClick={() => history.push(NEW_ORDER)}
                    >
                      <PlusOutlined />
                      New Order
                    </Button> */}
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <Row className="sales-manage-order-table">
            <Col xs={24} className="mb-3">
              <Table
                scroll={{ x: true }}
                loading={loading}
                columns={columns}
                dataSource={filteredData}
                size="middle"
                pagination={{
                  size: "default",
                  current: pagination.page,
                  pageSize: pagination.perpage,
                  total: total,
                  /*onChange:(page, perpage) => {
                            setPagination({ page, perpage });
                            getProductList({page, perpage}, sort, filters, search);
                        }*/
                }}
                onChange={_handleTableChanges}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default ManageOrder;
