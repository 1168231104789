import { notification } from "antd";
import { createContext, useEffect, useState } from "react";
import API_SERVICE from "services/api-service";
import parameters from "shared/parameters";
import openSocket from "socket.io-client";

const NotificationContext = createContext();
const socket = openSocket(parameters.SocketAddr, {
  "force new connection": true,
});

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [totalNoti, setTotalNoti] = useState(0);

  socket.onAny((eventName, ...args) => {
    fetchNotifications();
    _fetchUnreadCount();
  });

  const _fetchUnreadCount = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.GetNotifcationsCount();
      setTotalNoti(data.unreadNotifications || 0);
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };

  //fetching
  const fetchNotifications = async (params) => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.GetNotifcations({
        notificationType: "UNREAD",
      });
      setNotifications(data.items);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        totalNoti,
        notifications,
        fetchNotifications,
        _fetchUnreadCount,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
