import "./SalesProfile.scss";
import { Button, Col, Form, Input, Row, notification, Layout } from "antd";
import API_SERVICE from "services/api-service";
import { useHistory } from "react-router-dom";
import { SALES_PROFILE } from "shared/constants/RouteConstants";
import lockIcon from "shared/assets/icons/lockIcon.png";
import { useTranslation } from "react-i18next";
import { Content } from "antd/lib/layout/layout";

export default function SalesResetPassword(props: any) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory();

  const handleSubmit = async (values: any) => {
    try {
      const params = {
        oldPassword: values.oldPassword,
        password: values.confirm,
      };
      await API_SERVICE.changeAdminPassword(params);
      notification.success({
        message: "Password changed",
        placement: "bottomRight",
      });
    } catch (e) {
      notification.error({
        message: t(API_SERVICE.handleErrors(e)),
        placement: "bottomRight",
      });
    }
  };

  return (
    <>
      <Layout className="products-bg-color">
        <Content className="contentPadding">
          <Row
            className="sales-profile-row"
            align="top"
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col xs={0} sm={0} md={8} lg={8}></Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <h1 className="page-title text-center sales-profile-main-title ">
                Reset Password
              </h1>
            </Col>
            <Col className="btn-col" xs={24} sm={24} md={8} lg={8}>
              <Button
                className="sales-profile-btn"
                onClick={() => {
                  history.push(`${SALES_PROFILE}/true`);
                }}
              >
                <img src={lockIcon} alt="" />
                Edit Profile
              </Button>
              <Button className="sales-profile-btn">
                <img src={lockIcon} alt="" />
                Change Password
              </Button>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} className="sales-profile-container">
              <Form
                form={form}
                layout="vertical"
                fields={[]}
                onFinish={handleSubmit}
              >
                <Row
                  className="sales-reset-form"
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 24,
                    xl: 24,
                  }}
                >
                  <Col xs={24}>
                    <Form.Item
                      className="modalLabels"
                      label="Old Password"
                      name="oldPassword"
                      rules={[
                        {
                          required: true,
                          message: "Enter Old Password",
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={<img src={lockIcon} alt=""/>}
                        autoComplete={"off"}
                        className="modalInputs"
                        placeholder="Enter Old Password"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      className="modalLabels"
                      label="New Password"
                      name="password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Enter new Password",
                        },
                      ]}
                    >
                      <Input.Password
                        className="modalInputs"
                        prefix={<img src={lockIcon} alt="" />}
                        placeholder="Enter New Password"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      className="modalLabels"
                      name="confirm"
                      label="Confirm Password"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        className="modalInputs"
                        prefix={<img src={lockIcon} alt="" />}
                        placeholder="Confirm New Password"
                        type="password"
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    style={{
                      marginTop: "40px",
                    }}
                  >
                    <Form.Item>
                      <div className="btn-center">
                        <Button
                          htmlType="submit"
                          className="theme-btn resetBtn addAddressBtn"
                        >
                          Reset Password
                        </Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}
