import {
  Badge,
  Breadcrumb,
  Button,
  Col,
  Divider,
  Layout,
  notification,
  Pagination,
  Row,
  Spin,
} from "antd";
import { Content } from "antd/lib/layout/layout";

import { SearchOutlined } from "@material-ui/icons";
import { FileUpload } from "components";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  consverPriceToInternationalFormat,
  textTransform,
} from "services/util";
import {
  STATUS_CONFIRMED,
  STATUS_DELIVERED,
  STATUS_LPO_SIGNED,
  STATUS_LPO_SIGN_OFF,
  STATUS_NEW,
  STATUS_PROCESSING,
  STATUS_SHIPPED,
} from "shared/constants/Constants";
import {
  HOME,
  ORDER_DETAILS,
  PRODUCT_DETAILS_PAGE,
} from "shared/constants/RouteConstants";
import DownloadIconImg from "../../../assets/icons/download.png";
import UploadIconImg from "../../../assets/icons/upload.png";
import DetailIconImg from "../../../assets/icons/view-details.png";
import MilanoThumbnail from "../../../assets/MilanoThumbnail.png";
import API_SERVICE from "../../../services/api-service";
import "./index.scss";
import { dealerOrderStatusText } from "shared/services/Utility";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";

const DetailIcon = () => {
  return (
    <div className="detail-img-container">
      <img className="detail-img" src={DetailIconImg} alt="Details" />
    </div>
  );
};

const DownloadIcon = () => {
  return (
    <div className="detail-img-container">
      <img className="detail-img" src={DownloadIconImg} alt="Details" />
    </div>
  );
};

const UploadIcon = () => {
  return (
    <div className="detail-img-container">
      <img className="detail-img" src={UploadIconImg} alt="Details" />
    </div>
  );
};

interface CartItem {
  id: string;
  status: string;
  createdAt: string;
  totalAmount: string;
  totalCbm: string;
  orderNumber: string;
  orderDetails: [];
  deliverySlot: string;
  discountAmount: string;
  orderTotalAmount: string;
  shippingAddressId: number;
  statusColor: {
    hex: string;
  };
  user: {
    name: string;
    phone: string;
  };
  shippingAddress: {
    street: any;
    address: any;
    area: any;
    building: any;
    city: any;
    country: any;
    zipcode: any;
    phone: string;
    name: string;
  };
}

interface Product {
  quantity: number;

  product: {
    name: string;
    skuId: number;
    id: number;

    thumbnail: {
      url: string;
    };
    boxQuantity: string;
    fullDescription: string;
    family: string;
  };
}

export default function MyOrders(props: any) {
  const pageTopRef = useRef(null);
  const history = useHistory();
  const [searchInput, setSearchInput] = useState("");
  const [myOrders, setMyOrders] = useState([]);
  const [currency, setCurrency] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    perpage: 10,
    total: 1,
  });

  const [loading, setLoading] = useState(false);

  const fetchOrders = async (
    page?: number,
    perpage?: number,
    searchInput?: string
  ) => {
    try {
      setLoading(true);
      const params = {
        page: pagination.page,
        perpage: pagination.perpage,
      };
      if (searchInput) {
        //@ts-ignore
        params["q"] = searchInput;
      }
      if (page) {
        params["page"] = page;
      }
      if (perpage) {
        params["perpage"] = perpage;
      }
      const {
        data: { data },
      } = await API_SERVICE.getMyOrder(params);
      if (data.items) {
        setMyOrders(data.items);
        setPagination({
          page: data.page,
          perpage: data.perpage,
          total: data.total,
          pages: data.pagesadmin,
        });
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };
  const getCurrency = () => {
    const user = JSON.parse(localStorage.getItem("user")!);
    setCurrency(user?.user.dealerDetails[0]?.currencyType);
  };
  const downloadTableData = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.downloadOrderStatement();
      if (data) {
        window.open(data.url, "_blank");
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    fetchOrders();
    getCurrency();
  }, []);

  const prepareDeliverySlot = (slot: string) => {
    switch (slot) {
      case "Morning":
        return `${slot} (8AM TO 12 PM)`;
      case "Evening":
        return `${slot} (4PM TO 8PM)`;
      case "Afternoon":
        return `${slot} (12PM TO 4PM)`;
      default:
        break;
    }
  };
  const downloadGeneratedLPO = async (id: number) => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.downloadGeneratedLPO(id);
      window.open(data.url, "_blank");
      fetchOrders();
    } catch (e) {
      notification.error({
        message: API_SERVICE.handleErrors(e),
        placement: "bottomLeft",
      });
    }
  };

  const handleUploadLPO = async (id: number, lpoId: number) => {
    try {
      const params = {
        lpoId,
      };
      await API_SERVICE.uploadSignedLPO(id, params);
      notification.success({
        message: `Uploaded Successfully`,
        placement: "bottomLeft",
      });
      fetchOrders();
    } catch (e) {
      notification.error({
        message: API_SERVICE.handleErrors(e),
        placement: "bottomLeft",
      });
    }
  };

  const deliveryStatusButtons = (status: string, item: any) => {
    return (
      <Button
        className="theme-btn1"
        onClick={() => history.push(ORDER_DETAILS + "/" + item?.id)}
      >
        <span>
          <DetailIcon />
        </span>
        View Order Details
      </Button>
    );
    // switch (status) {
    //   case STATUS_NEW:
    //     return (
    //       <Button
    //         className="theme-btn1"
    //         onClick={() => history.push(ORDER_DETAILS + "/" + item?.id)}
    //       >
    //         <span>
    //           <DetailIcon />
    //         </span>
    //         View Order Details
    //       </Button>
    //     );
    //   case STATUS_LPO_SIGN_OFF:
    //     return (
    //       <>
    //         <Button
    //           className="theme-btn1"
    //           onClick={() => {
    //             downloadGeneratedLPO(item.id);
    //           }}
    //         >
    //           <span>
    //             <DownloadOutlined />
    //           </span>
    //           Download LPO
    //         </Button>
    //         <FileUpload
    //           type={"DOCUMENT"}
    //           showList="false"
    //           style={{ display: "none" }}
    //           onChange={(id: any) => {
    //             handleUploadLPO(item.id, id);
    //           }}
    //         >
    //           <Button className="theme-btn1">
    //             <span>
    //               <UploadOutlined />
    //             </span>
    //             Upload Signed LPO
    //           </Button>
    //         </FileUpload>
    //         <Button
    //           className="theme-btn1"
    //           onClick={() => history.push(ORDER_DETAILS + "/" + item?.id)}
    //         >
    //           <span>
    //             <DetailIcon />
    //           </span>
    //           View Order Details
    //         </Button>
    //         ;
    //       </>
    //     );

    //   case STATUS_LPO_SIGNED:
    //   case STATUS_PROCESSING:
    //   case STATUS_CONFIRMED:
    //   case STATUS_SHIPPED:
    //     return (
    //       <>
    //         <Button
    //           className="theme-btn1"
    //           onClick={() => {
    //             window.open(item?.lpo?.url);
    //           }}
    //         >
    //           <span>
    //             <DownloadOutlined />
    //           </span>
    //           Download Signed LPO
    //         </Button>
    //         <Button
    //           className="theme-btn1"
    //           onClick={() => history.push(ORDER_DETAILS + "/" + item?.id)}
    //         >
    //           <span>
    //             <DetailIcon />
    //           </span>
    //           View Order Details
    //         </Button>
    //         ;
    //       </>
    //     );
    //   case STATUS_DELIVERED:
    //     return (
    //       <>
    //         <Button
    //           className="theme-btn1"
    //           onClick={() => {
    //             window.open(item.lpo.url);
    //           }}
    //         >
    //           <span>
    //             <DownloadOutlined />
    //           </span>
    //           Download Signed LPO
    //         </Button>
    //         <Button className="theme-btn1">
    //           <span>
    //             <DownloadOutlined />
    //           </span>
    //           Download Tax Invoice
    //         </Button>
    //         <Button
    //           className="theme-btn1"
    //           onClick={() => history.push(ORDER_DETAILS + "/" + item?.id)}
    //         >
    //           <span>
    //             <DetailIcon />
    //           </span>
    //           View Order Details
    //         </Button>
    //         ;
    //       </>
    //     );
    //   default:
    //     return;
    // }
  };

  return (
    <Layout className="my-order-bgcolor">
      <Content className="contentPadding">
        <Breadcrumb className="products-breadcrumb" separator=">">
          <Breadcrumb.Item>
            <Link to={HOME}>Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="products-breadcrumb-active">
            My Orders
          </Breadcrumb.Item>
        </Breadcrumb>
        <Layout className="my-order-main-content-layout" ref={pageTopRef}>
          <Row className="mb-3">
            <Col xs={24} className="dealer-profile-container">
              <h1 className="page-title text-center">My Orders</h1>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  fetchOrders(1, pagination.perpage, searchInput);
                }}
              >
                <span className="wishlist-search">
                  <SearchOutlined className="icon" />
                  <input
                    placeholder="Search Order Number"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      e.target.value === ""
                        ? fetchOrders()
                        : setSearchInput(e.target.value);
                    }}
                  />
                </span>
              </form>
            </Col>
            <Row className="download-row">
              <Col className="justify-content-end">
                <Button
                  className="download-btn mt-2"
                  onClick={downloadTableData}
                >
                  <span>
                    <DownloadOutlined />
                  </span>
                  Download Order Statement
                </Button>
              </Col>
            </Row>
          </Row>

          {loading ? (
            <Spin size="large" />
          ) : myOrders.length !== 0 ? (
            myOrders.map((item: CartItem, idx: number) => (
              <div className="mb-2">
                <Row key={`MO${idx}`}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    className="order-details-topbar "
                  >
                    <Row>
                      <Col
                        xs={10}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={4}
                        className="order-details-title order-details-top"
                      >
                        {moment(item.createdAt).format("ll")}
                      </Col>{" "}
                      <Col
                        xs={14}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={4}
                        className="order-details-title order-details-top"
                      >
                        Total{" "}
                        <span>
                          {currency === "USD" ? "$" : "AED"}{" "}
                          {consverPriceToInternationalFormat(item?.totalAmount)}
                        </span>
                      </Col>
                      <Col
                        xs={15}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={5}
                        className="order-details-title order-details-top"
                      >
                        Total CBM{" "}
                        <span>
                          {" "}
                          {consverPriceToInternationalFormat(item.totalCbm)}
                        </span>
                      </Col>
                      <Col
                        xs={24}
                        sm={14}
                        md={14}
                        lg={14}
                        xl={7}
                        className="order-details-title order-details-top p-0"
                      >
                        Order #
                        <span className="details-amount">
                          {item.orderNumber}
                        </span>
                      </Col>
                      <Col
                        xs={24}
                        sm={24}
                        md={10}
                        lg={10}
                        xl={4}
                        className="order-details-title myOrdersBatch  p-0"
                      >
                        <span>{textTransform(item?.status)}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col xs={24} sm={24} md={16} lg={16} xl={18}>
                    <Row>
                      {item.orderDetails
                        .slice(0, 2)
                        .map((item: Product, idxx: number) => {
                          return (
                            <OrderMDetailsCard
                              categoryName={item?.product?.category?.name}
                              name={item?.product?.name}
                              sku={item?.product?.skuId}
                              url={
                                item?.product?.thumbnail !== null
                                  ? item?.product?.thumbnail?.url
                                  : MilanoThumbnail
                              }
                              quantity={item?.quantity}
                              id={item.product.id}
                              key={`MOP${idxx}`}
                            />
                          );
                        })}
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={6}
                    className="myOrderActionBtns"
                  >
                    {deliveryStatusButtons(item?.status, item)}
                  </Col>
                </Row>
                <Divider className="divide" />

                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    className="order-addres-bar my-order-addres-bar"
                  >
                    <div className="left">
                      <p className="deliver-to">Deliver To</p>
                    </div>
                    <div className="right">
                      <p className="name"> {item?.shippingAddress?.name}</p>
                      <p className="address">
                        {`${item?.shippingAddress?.street},${item?.shippingAddress?.address}, ${item?.shippingAddress?.area}, ${item?.shippingAddress?.building} ${item?.shippingAddress?.city} ${item?.shippingAddress?.country} Pincode - ${item?.shippingAddress?.zipcode}`}
                      </p>
                      <p className="phone-number">
                        Phone Number : {item?.shippingAddress?.phone}
                      </p>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    className="order-addres-bar"
                  >
                    <div className="deliver-title">
                      {prepareDeliverySlot(item?.deliverySlot)}
                    </div>
                  </Col>
                </Row>
              </div>
            ))
          ) : (
            <p className="no-prod">Sorry, No Such Orders :( </p>
          )}

          <Col xs={24} style={{ display: "grid", placeItems: "center" }}>
            <Pagination
              className="pagination"
              defaultCurrent={pagination.page || 1}
              total={pagination.total}
              showSizeChanger={false}
              responsive
              pageSize={pagination.perpage}
              onChange={(page, size) => {
                //@ts-ignore
                setPagination({ page: page, perpage: size });
                fetchOrders(page, size);
                //@ts-ignore
                pageTopRef.current.scrollIntoView();
              }}
            />
          </Col>
        </Layout>
      </Content>
    </Layout>
  );
}

// const AddressField = (props: { id: number }) => {
//   const [addr, setAddr] = useState("");
//   const { id } = props;

//   const getAddressBasedOnId = async () => {
//     const { data } = await API_SERVICE.GetAddressBasedOnId(id).then(
//       (res) => res.data
//     );
//     if (data) {
//       const { street, area, city, building, country, zipcode } = data;
//       setAddr(
//         `${street}, ${area}, ${building} ${city} ${country} Pincode - ${zipcode}`
//       );
//     }
//   };
//   useEffect(() => {
//     getAddressBasedOnId();
//   }, []);

//   return <>{addr}</>;
// };

function OrderMDetailsCard({
  categoryName = "",
  name = "Galina Jacuzzi Bathtub",
  quantity = 1,
  sku = 117171717,
  url = "https://random.imagecdn.app/214/",
  id = 1,
}) {
  const history = useHistory();
  const handleClick = () => {
    history.push(`${PRODUCT_DETAILS_PAGE}/${id}/${categoryName}`);
  };

  return (
    <Col xs={24}>
      <div className="orderCardContainer myOrderMainCardContainer">
        <div
          className="imgContainer"
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <img src={url} alt={name} />
        </div>
        <div className="infoContainer">
          <p
            className="title"
            onClick={handleClick}
            style={{ cursor: "pointer" }}
          >
            {name} X {quantity}
          </p>
          <p className="sku">SKU: {sku}</p>
        </div>
      </div>
    </Col>
  );
}
