import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Divider,
  Layout,
  Pagination,
  Row,
  Spin,
  notification,
} from "antd";
import dayjs from "dayjs";
import Chart from "react-apexcharts";
import MilanoThumbnail from "../../../assets/MilanoThumbnail.png";
import dollar from "../../../assets/icons/dollar-sign.png";
import arrow from "../../../assets/icons/insight-box-arrow.png";

import {
  CalendarOutlined,
  RightOutlined,
  PieChartOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import API_SERVICE from "services/api-service";
import { consverPriceToInternationalFormat } from "services/util";
import { STATUS_LPO_SIGN_OFF } from "shared/constants/Constants";
import {
  HOME,
  MY_ORDERS,
  REVENUE_SUMMARY,
  STOCK_SUMMARY,
} from "shared/constants/RouteConstants";
import { removeSpecialCharacters } from "shared/services/Utility";
import "./index.scss";

export default function Dashboard(props: any) {
  const user = JSON.parse(localStorage.getItem("user")!);
  const currency = user?.user.dealerDetails[0]?.currencyType;
  const history = useHistory();
  const [totalRevenue, setTotalRevenue] = useState();
  const [totalOrders, setTotalOrders] = useState();
  const [totalProducts, setTotalProucts] = useState();
  const [avgOrderValue, setAvgOrderValue] = useState();
  const [favouriteProducts, setFavouriteProducts] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    perpage: 5,
  });
  const [total, setTotal] = useState(10);

  const [purchaseSummaryDetails, setPurchaseSummaryDetails] = useState([]);
  const [chartOrderDetails, setChartOrderDetails] = useState([]);
  const [arr1, setArr1] = useState([]);
  const [arr2, setArr2] = useState([]);
  const [colors, setColors] = useState([]);

  const [loading, setLoading] = useState(false);
  const [favouriteProductRange, setFavouriteProductRange] = useState({
    month: moment(new Date()).format("MMMM"),
    year: moment(new Date()).format("YYYY"),
  });
  const [purchaseSummaryRange, setPurchaseSummaryRange] = useState({
    year: moment(new Date()).format("YYYY"),
  }); //

  const GetTotalRevenue = async () => {
    try {
      setLoading(true);
      const { data: data } = await API_SERVICE.getTotalRevenueDealer();
      if (data) {
        setTotalRevenue(data);
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    } finally {
      GetTotalOrder();
    }
  };
  const GetTotalOrder = async () => {
    try {
      setLoading(true);
      const { data: data } = await API_SERVICE.getTotalOrdersDealer();
      if (data) {
        setTotalOrders(data);
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    } finally {
      GetTotalProducts();
    }
  };

  const GetTotalProducts = async () => {
    try {
      setLoading(true);
      const { data: data } = await API_SERVICE.getTotalProductsDealer();
      if (data) {
        setTotalProucts(data);
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    } finally {
      GetAvgOrderValue();
    }
  };
  const GetAvgOrderValue = async () => {
    try {
      setLoading(true);
      const { data: data } = await API_SERVICE.getAvgOrderValueDealer();
      if (data) {
        setAvgOrderValue(data);
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  const GetFavouriteProducts = async (page?: number, perpage?: number) => {
    try {
      const params = {
        ...pagination,
        month: favouriteProductRange.month,
        year: favouriteProductRange.year,
      };
      if (page) {
        params["page"] = page;
      }
      if (perpage) {
        params["perpage"] = perpage;
      }
      const {
        data: { data },
      } = await API_SERVICE.GetFavouriteProducts(params);
      if (data) {
        setFavouriteProducts(data.items);
        setPagination({
          page: data.page,
          perpage: data.perpage,
          pages: data.pages,
        });
        setTotal(data.total);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  const GetPurchaseSummaryDetails = async () => {
    try {
      const params = {
        year: purchaseSummaryRange.year,
      };
      const { data: data } = await API_SERVICE.GetPurchaseSummaryDetails(
        params
      );
      if (data) {
        setPurchaseSummaryDetails(data.data);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  const GetDealerChartDetails = async () => {
    try {
      //@ts-ignore
      const { data: data } = await API_SERVICE.GetDealerChartDetails();
      if (data) {
        data?.data?.forEach(
          (item: { total: any; statusColor: { hex: any } }) => {
            //@ts-ignore
            if (item.total) arr2.push(item.total);
            //@ts-ignore
            if (item?.statusColor) colors.push(item.statusColor.hex);
          }
        );
        data?.data?.forEach((item: { status: string }) => {
          //@ts-ignore
          if (item.status) arr1.push(item?.status.replace(/_/g, " "));
        });
        setArr1(arr1);
        setArr2(arr2);
        setColors(colors);

        setChartOrderDetails(data.data);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  useEffect(() => {
    GetPurchaseSummaryDetails();
  }, [purchaseSummaryRange]);

  useEffect(() => {
    GetFavouriteProducts();
  }, [favouriteProductRange]);

  useEffect(() => {
    GetDealerChartDetails();
    GetTotalRevenue();

    return () => setArr2([]);
  }, []);

  return (
    <Layout className="dashboard-bg-color">
      <Breadcrumb className="products-breadcrumb" separator=">">
        <Breadcrumb.Item>
          <Link to={HOME}>Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="products-breadcrumb-active">
          Dashboard
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        style={{ justifyContent: "center" }}
      >
        <Col xs={24} className="dealer-profile-container">
          <h1 className="page-title text-center">Dashboard</h1>
        </Col>
      </Row>
      <Row className="dealer-dashboard-inner-padding">
        <Col xs={24} sm={24} md={24} lg={15} xl={18}>
          <div className="dashboard-admin-row mb-2">
            <div className="dashboard-box dealer-box">
              <div className="dashboard-box-title">Total Order Value</div>
              <div className="dashboard-box-content">
                {totalRevenue?.data !== 0
                  ? currency === "USD"
                    ? "$"
                    : "AED"
                  : ""}{" "}
                {loading ? (
                  <Spin />
                ) : (
                  consverPriceToInternationalFormat(
                    parseFloat(totalRevenue?.data).toFixed(2)
                  )
                )}
              </div>
            </div>
            <div
              className="dashboard-box dealer-box dashboard-order"
              onClick={() => history.push(MY_ORDERS)}
            >
              <div className="dashboard-box-title">Total Orders</div>
              <div className="dashboard-box-content">
                {" "}
                {loading ? <Spin /> : totalOrders?.data}
              </div>
            </div>
            {/* <div className="dashboard-box dealer-box">
              <div className="dashboard-box-title">Total Products</div>
              <div className="dashboard-box-content">
                {" "}
                {loading ? (
                  <Spin />
                ) : (
                  consverPriceToInternationalFormat(totalProducts?.data)
                )}
              </div>
            </div> */}
            <div className="dashboard-box dealer-box">
              <div className="dashboard-box-title">Average Order Value</div>
              <div className="dashboard-box-content">
                {avgOrderValue?.data !== 0
                  ? currency === "USD"
                    ? "$"
                    : "AED"
                  : ""}{" "}
                {loading ? (
                  <Spin />
                ) : (
                  consverPriceToInternationalFormat(
                    parseFloat(avgOrderValue?.data).toFixed(2)
                  )
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={9}
          xl={6}
          className="dealer-dashboard-btns"
        >
          {/* <Button
            className="theme-btn dealer-dashboard-btn"
            onClick={() => history.push(STOCK_SUMMARY)}
          >
            Stock Summary
          </Button> */}
          <Button
            className="theme-btn dealer-dashboard-btn"
            onClick={() => history.push(REVENUE_SUMMARY)}
          >
            Order Summary
          </Button>
        </Col>
      </Row>

      <Row
        style={{ margin: 0 }}
        gutter={{ xs: 8, sm: 16, md: 24 }}
        // justify="space-between"
        className="dealer-dashboard-inner-padding pt-0"
      >
        <Col
          className="gutter-row  fav-products-box"
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={9}
          xxl={7}
        >
          <div className="insight-box">
            <div className="insight-box-header">
              <div className="insight-box-title">
                <img className="insight-img" src={arrow} alt="" />
                Favourite Products
                <DatePicker
                  disabledDate={(current) => {
                    return (
                      current &&
                      current > dayjs().startOf("month").add(1, "month")
                    );
                  }}
                  allowClear={false}
                  defaultValue={moment(new Date(), "MMM YYYY")}
                  picker="month"
                  onChange={(value) => {
                    setFavouriteProductRange({
                      month: moment(value).format("MMMM"),
                      year: moment(value).format("YYYY"),
                    });
                  }}
                  className="admin-datepicker"
                  suffixIcon={<CalendarOutlined />}
                />
              </div>
            </div>
            <Divider className="divide" />
            <div className="insight-content-box">
              {favouriteProducts.length !== 0
                ? favouriteProducts.map((prod: any, index: number) => {
                    return <DetailCard product={prod} key={index} />;
                  })
                : " No Data Available"}
            </div>

            <Divider className="divide admin-divide" />
            <div className="flex justify-content-center pl-2 pb-3 pr-2">
              <Pagination
                showLessItems={true}
                showSizeChanger={false}
                current={pagination.page}
                defaultCurrent={pagination.page || 1}
                total={10}
                pageSize={pagination.perpage}
                onChange={(page, size) => {
                  //@ts-ignore
                  setPagination({ page: page, perpage: size });
                  GetFavouriteProducts(page, size);
                }}
              />
            </div>
            {/* <Button
              type="text"
              className="insight-button"
              onClick={() => history.push(HOME)}
            >
              More Insights <RightOutlined />
            </Button> */}
          </div>
        </Col>

        <Col
          className="gutter-row month-box"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={9}
          xxl={10}
        >
          <div className="insight-box">
            <div className="insight-box-header">
              <div className="insight-box-title">
                <ShoppingCartOutlined className="insight-img" />
                {/* <img className="insight-img" src={dollar} alt="" /> */}
                Month Wise Purchase Summary
                <DatePicker
                  // disabledDate={(current) => {
                  //   return (
                  //     current &&
                  //     current > dayjs().startOf("month").add(1, "month")
                  //   );
                  // }}
                  allowClear={false}
                  defaultValue={moment(new Date(), "MMM YYYY")}
                  picker="year"
                  onChange={(value) => {
                    setPurchaseSummaryRange({
                      year: moment(value).format("YYYY"),
                    });
                  }}
                  className="admin-datepicker"
                  suffixIcon={<CalendarOutlined />}
                />
              </div>
            </div>
            <Divider className="divide" />
            <Row
              // align="middle"
              justify="space-between"
              className="pb-4 month-wise-wrapper"
              gutter={{ xs: 8, sm: 16, md: 24 }}
            >
              <Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
                <Row align="middle" justify="space-between">
                  <Col xs={8} className="month-wise-title text-center ">
                    Month
                  </Col>
                  <Col xs={8} className="month-wise-title text-center">
                    Purchase
                  </Col>
                  <Col xs={8} className="month-wise-title text-center">
                    Orders
                  </Col>
                  <Divider className="divide" />

                  {purchaseSummaryDetails.length !== 0 ? (
                    purchaseSummaryDetails
                      .slice(0, 6)
                      .map((rev: any, index: number) => {
                        return (
                          <>
                            <Col
                              key={index}
                              style={{ padding: 0 }}
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                            >
                              <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                                <Col
                                  className="gutter-row"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >
                                  <p
                                    className="insight-box-month"
                                    style={{ textAlign: "center" }}
                                  >
                                    {rev.months}
                                  </p>
                                </Col>
                                <Col
                                  className="gutter-row rev-col"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >
                                  <p className="insight-box-span ">
                                    {rev.revenue !== 0
                                      ? currency === "USD"
                                        ? "$"
                                        : "AED"
                                      : ""}{" "}
                                    {consverPriceToInternationalFormat(
                                      parseFloat(rev.revenue).toFixed(2)
                                    )}
                                  </p>
                                </Col>
                                <Col
                                  className="gutter-row"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >
                                  <p className="insight-box-span">
                                    {rev.orders}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                            <Divider />
                          </>
                        );
                      })
                  ) : (
                    <p className="text-center " style={{ width: "100%" }}>
                      {" "}
                      No Data Available
                    </p>
                  )}
                </Row>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <Row
                  align="middle"
                  justify="space-between"
                  gutter={{ xs: 8, sm: 16, md: 24 }}
                >
                  <Col xs={8} className="month-wise-title text-center ">
                    Month
                  </Col>
                  <Col xs={8} className="month-wise-title text-center">
                    Purchase
                  </Col>
                  <Col xs={8} className="month-wise-title text-center">
                    Orders
                  </Col>

                  <Divider className="divide" />
                  {purchaseSummaryDetails.length !== 0 &&
                  purchaseSummaryDetails.length > 6 ? (
                    purchaseSummaryDetails
                      .slice(6)
                      .map((rev: any, index: number) => {
                        return (
                          <>
                            <Col
                              key={index}
                              style={{ padding: 0 }}
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                            >
                              <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                                <Col
                                  className="gutter-row "
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >
                                  <p
                                    className="insight-box-month "
                                    style={{ textAlign: "center" }}
                                  >
                                    {rev.months}
                                  </p>
                                </Col>
                                <Col
                                  className="gutter-row"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >
                                  <p className="insight-box-span">
                                    {rev.revenue !== 0
                                      ? currency === "USD"
                                        ? "$"
                                        : "AED"
                                      : ""}{" "}
                                    {consverPriceToInternationalFormat(
                                      parseFloat(rev.revenue).toFixed(2)
                                    )}
                                  </p>
                                </Col>
                                <Col
                                  className="gutter-row"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >
                                  <p className="insight-box-span">
                                    {rev.orders}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                            <Divider />
                          </>
                        );
                      })
                  ) : (
                    <p className="text-center " style={{ width: "100%" }}>
                      {" "}
                      No Data Available
                    </p>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          className="gutter-row order-box"
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={8}
          xxl={7}
        >
          <div className="insight-box">
            <div className="insight-box-header">
              <div className="insight-box-title">
                <PieChartOutlined className="insight-img" />
                Order Summary
              </div>
            </div>
            <Divider className="divide" />
            <div className="insight-content-box">
              {arr2.length !== 0 && (
                //@ts-ignore
                <Chart
                  className="dealer-donut"
                  type="donut"
                  // height={270}
                  width={250}
                  series={arr2}
                  options={{
                    legend: {
                      // position:absolute,
                      // top: 100,
                    },
                    labels: arr1,
                    colors: colors,

                    plotOptions: {
                      pie: {
                        donut: {
                          labels: {
                            show: true,
                            total: {
                              show: true,
                              fontSize: 16,
                              label: "No of Orders",
                              color: "#808080",
                            },
                            value: {
                              fontSize: 26,
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                  }}
                />
              )}
              <div className="dealer-chart-info">
                {chartOrderDetails.length !== 0
                  ? chartOrderDetails.map((chart: any, index: number) => {
                      return (
                        <div className="row" key={index}>
                          <p>
                            <span
                              className="color"
                              style={{
                                backgroundColor: `${chart?.statusColor?.hex}`,
                              }}
                            />
                            {chart?.status === STATUS_LPO_SIGN_OFF
                              ? "LPO SIGNED OFF REQUIRED"
                              : removeSpecialCharacters(chart?.status)}
                          </p>
                          <p className="percent"> {chart?.total} %</p>
                        </div>
                      );
                    })
                  : "No Details "}
              </div>
            </div>

            <Divider className="divide admin-divide" />
            <Button
              type="text"
              className="insight-button"
              onClick={() => history.push(MY_ORDERS)}
            >
              More Insights <RightOutlined />
            </Button>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

function DetailCard(props: any) {
  const truncate = (title: string) => {
    return title.length > 36 ? title.substring(0, 36) + "..." : title;
  };

  return (
    <Row
      className="detail-card"
      style={{ margin: 0 }}
      align="middle"
      justify="space-between"
    >
      <Col xs={17}>
        <Row align="middle">
          <Col xs={7} className="flex justify-content-center">
            <img
              className="photo insight-photo"
              src={props.product?.url || MilanoThumbnail}
            />
          </Col>
          <Col xs={17}>
            <div className="detailCard-title">
              {truncate(props.product?.name || " ")}
              <p>SKU {props.product?.skuId}</p>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={7}>
        <div className="detailCard-number">
          {consverPriceToInternationalFormat(props.product?.count)} Nos
        </div>
      </Col>
    </Row>
  );
}
