import { Route, Redirect } from "react-router-dom";
import { AuthConsumer } from "shared/contexts/AuthContext";
import {
  ADMIN_DASHBOARD,
  HOME,
  SALES_DASHBOARD,
} from "../constants/RouteConstants";
import { NotificationProvider } from "context/Notification";

export default function ProtectedRoute({ component: Component, ...rest }: any) {
  if (rest.path) {
    // console.log(rest.path);
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthConsumer>
          {({ isAuth, user }) =>
            isAuth ? (
              user.roles[0].id === 1 || user.roles[0].id === 2 ? (
                rest.path.startsWith("/admin") ? (
                  <NotificationProvider>
                    <Component {...props} />
                  </NotificationProvider>
                ) : (
                  <Redirect
                    to={{
                      pathname: ADMIN_DASHBOARD,
                    }}
                  />
                )
              ) : user.roles[0].id === 3 ? (
                rest.path.startsWith("/dealer") ? (
                  <NotificationProvider>
                    <Component {...props} />
                  </NotificationProvider>
                ) : (
                  <Redirect
                    to={{
                      pathname: HOME,
                    }}
                  />
                )
              ) : user.roles[0].id === 4 || user.roles[0].id === 6 ? (
                rest.path.startsWith("/sales") ? (
                  <NotificationProvider>
                    <Component {...props} />
                  </NotificationProvider>
                ) : (
                  <Redirect
                    to={{
                      pathname: SALES_DASHBOARD,
                    }}
                  />
                )
              ) : rest.path.startsWith("/admin") ? (
                <NotificationProvider>
                  <Component {...props} />
                </NotificationProvider>
              ) : (
                <Redirect
                  to={{
                    pathname: HOME,
                  }}
                />
              )
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                  // state: { from: rest.location },
                }}
              />
            )
          }
        </AuthConsumer>
      )}
    />
  );
}

// (user.roleId === 1 || user.roleId === 2 || user.roleId === 3)
//                                     ?
//                                         rest.path.startsWith('/admin') ? <Component {...props} /> : <Redirect
//                                             to={{
//                                                 pathname: GROUPS_LIST,
//                                             }}
//                                     />

//                                     :

//                                     (
//                                         (user.roleId === 4 || user.roleId === 5 || user.roleId === 6 || user.roleId === 7)
//                                         ?
//                                         rest.path.startsWith('/admin') ? <Component {...props} /> : <Redirect
//                                             to={{
//                                                 pathname: COMPANY_SURVEY_LIST,
//                                             }}
//                                         />
//                                         :
//                                         rest.path.startsWith('/admin') ? <Component {...props} /> : <Redirect
//                                             to={{
//                                                 pathname: HOME,
//                                             }}
//                                         />
//                                     )
//                             )

//                             :

//                             (
//                                 <Redirect
//                                     to={{
//                                         pathname: '/login',
//                                         // state: { from: rest.location },
//                                     }}
//                                 />
//                             )
