import { Add, CloseOutlined, Remove } from '@material-ui/icons';
import { Button, Input, notification, Popconfirm, Spin } from 'antd';
import CartContext from 'context/Cart';
import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API_SERVICE from 'services/api-service';
import { consverPriceToInternationalFormat } from 'services/util';
import { PRODUCT_DETAILS_PAGE } from 'shared/constants/RouteConstants';
import './index.scss';
import MilanoThumbnail from '../../assets/MilanoThumbnail.png';
import voucherIcon from 'shared/assets/images/voucher.png';
import { LoadingOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
const { Panel } = Collapse;
interface Props {
  cart: {
    free: boolean;
    quantity: number;
    productId: number;
    freeQuantity: number;
    product: {
      name: string;
      skuId: string;
      cbmValue: number;
      price: number;
      cartoonQuantity: string;
      uomCode: string;
      buyGetOffers: [
        {
          id: number;
          buyQty: number;
          getWty: number;
        }
      ];
      category: {
        name: string;
      };
      thumbnail: {
        url: string | null;
        name: string;
      };
    };
  };
  appliedCoupon: boolean;
  setDiscountDetails: Function;
  setCouponCode: Function;
  setAppliedCoupon: Function;
}

const CartProductCard = (props: Props) => {
  const [disable, setDisable] = useState(false);
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user')!);
  const currency = user?.user.dealerDetails[0]?.currencyType;
  const { fetchCartDetails, cartProducts, pagination, setPagination } =
    useContext(CartContext);

  const [quantity, setQuantity] = useState(props.cart.quantity);
  const quantityValue = useRef<HTMLInputElement>(null);

  const handleDelete = async (productId: number) => {
    try {
      setDisable(true);
      await API_SERVICE.deleteCartProduct(productId);

      notification.success({
        message: `Product Deleted Successfully`,
        placement: 'bottomLeft',
      });
      setDisable(false);
      if (cartProducts.length === 1) {
        let page = pagination.page - 1;
        fetchCartDetails(page);
        setPagination((prev: { page: number }) => {
          return prev.page - 1;
        });
      } else {
        fetchCartDetails(pagination.page);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: 'bottomLeft',
      });
    } finally {
      props.setAppliedCoupon(false);
      props.setCouponCode(null);
      props.setDiscountDetails({
        totalAmount: 0,
        discountAmount: 0,
        totalAmountAfterDiscount: 0,
      });
    }
  };

  const handleQuantityValue = async (quantity: number, productId: number) => {
    try {
      setDisable(true);
      await API_SERVICE.addToCart({
        productId,
        orderCoupon: null,
        quantity,
      });
      notification.success({
        message: `Updated quantity`,
        placement: 'bottomLeft',
      });
      setDisable(false);
      fetchCartDetails();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: 'bottomLeft',
      });
    }
  };

  // const handleQuantity = (action: string, productId: number) => {
  //   if (action === 'minus') {
  //     if (quantity > parseInt(props.cart.product.cartoonQuantity)) {
  //       setQuantity(quantity - parseInt(props.cart.product.cartoonQuantity));
  //       handleQuantityValue(
  //         quantity - parseInt(props.cart.product.cartoonQuantity),
  //         productId
  //       );
  //     }
  //     if (quantity === parseInt(props.cart.product.cartoonQuantity)) {
  //       handleDelete(productId);
  //     }
  //   } else {
  //     setQuantity(
  //       (prev) => prev + parseInt(props.cart.product.cartoonQuantity)
  //     );
  //     handleQuantityValue(
  //       quantity + parseInt(props.cart.product.cartoonQuantity),
  //       productId
  //     );
  //   }

  //   if (props.appliedCoupon) {
  //     props.setAppliedCoupon(false);
  //     props.setCouponCode(null);
  //     props.setDiscountDetails({
  //       totalAmount: 0,
  //       discountAmount: 0,
  //       totalAmountAfterDiscount: 0,
  //     });
  //   }
  // };
  const handleQuantity = (action: string, productId: number) => {
    if (action === 'minus') {
      if (quantity > 1) {
        setQuantity(quantity - 1);
        handleQuantityValue(quantity - 1, productId);
      }
      if (quantity === 1) {
        handleDelete(productId);
      }
    } else {
      setQuantity((prev) => prev + 1);
      handleQuantityValue(quantity + 1, productId);
    }

    if (props.appliedCoupon) {
      props.setAppliedCoupon(false);
      props.setCouponCode(null);
      props.setDiscountDetails({
        totalAmount: 0,
        discountAmount: 0,
        totalAmountAfterDiscount: 0,
      });
    }
  };

  const handleClick = () => {
    history.push(
      `${PRODUCT_DETAILS_PAGE}/${props.cart.productId}/${props.cart.product.category.name}`
    );
  };
  const handleApplyOffer = async (quantity: any) => {
    try {
      await API_SERVICE.addToCart({
        productId: props.cart.productId,
        orderCoupon: null,
        quantity: quantity,
      });

      notification.success({
        message: 'Offer Applied and added to cart!',
        placement: 'bottomLeft',
      });
      fetchCartDetails();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: 'bottomLeft',
      });
    }
  };

  function calculateCartonsAndProducts(
    totalQuantity: number,
    productsPerCarton: number
  ) {
    if (productsPerCarton > 1) {
      const cartons = Math.floor(totalQuantity / productsPerCarton);
      const remainingProducts = totalQuantity % productsPerCarton;

      return `${cartons} Carton${
        cartons !== 1 ? 's' : ''
      } and ${remainingProducts} ${props.cart.product.uomCode}`;
    }
    return null;
  }

  function getCartons(totalQuantity: number, productsPerCarton: number) {
    const cartons = Math.floor(totalQuantity / productsPerCarton);

    return cartons;
  }

  return (
    <>
      {disable ? <Loader /> : ''}
      <div className="cart-product-card">
        <div className="image">
          <img
            onClick={handleClick}
            src={
              props.cart?.product?.thumbnail !== null
                ? props.cart?.product?.thumbnail?.url
                : MilanoThumbnail
            }
            alt={props.cart.product?.thumbnail?.name}
          />
        </div>
        <div className="cart-detail-right">
          <div className="cart-detail-right-top">
            <div className="details">
              <p className="title" onClick={handleClick}>
                {props.cart.product?.name}
              </p>
              <div className="sku">
                <p> SKU: {props.cart.product?.skuId}</p>
                {/* <p>CBM:{props.cart.product?.cbmValue}</p> */}
              </div>
              <div className="flex justify-content-between">
                <p className="price">
                  {currency === 'USD' ? '$' : 'AED'}{' '}
                  {props.cart.free
                    ? 0
                    : consverPriceToInternationalFormat(
                        props.cart.product?.price
                      )}
                </p>
                <div className="sku">
                  <p>
                    SUB TOTAL : {currency === 'USD' ? '$' : 'AED'}{' '}
                    {consverPriceToInternationalFormat(
                      props.cart.product?.price * quantity
                    )}
                  </p>
                </div>
              </div>
            </div>

            {props.appliedCoupon ? (
              <div
                className={`${
                  props.cart.free && 'pointer-events-none'
                } quantity`}
              >
                <Popconfirm
                  title="Applied Coupon will be removed. Are you sure you want to continue ?"
                  onConfirm={() =>
                    handleQuantity('minus', props.cart.productId)
                  }
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="minus">
                    <Remove />
                  </Button>
                </Popconfirm>
                <input className="value" value={quantity} ref={quantityValue} />
                <Popconfirm
                  title="Applied Coupon will be removed. Are you sure you want to continue ?"
                  onConfirm={() => handleQuantity('add', props.cart?.productId)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="plus">
                    <Add />
                  </Button>
                </Popconfirm>
              </div>
            ) : (
              <div className="quantity-div">
                <div
                  className={`${
                    props.cart.free && 'pointer-events-none'
                  } quantity ${props.cart.free && 'free-input'}`}
                >
                  {!props.cart.free ? (
                    <Button
                      className="minus"
                      onClick={() => {
                        handleQuantity('minus', props.cart.productId);
                      }}
                    >
                      <Remove />
                    </Button>
                  ) : (
                    ''
                  )}
                  <input
                    className={`value
                `}
                    value={props.cart.free ? props.cart.freeQuantity : quantity}
                    // ref={quantityValue}
                    onChange={(e) => {
                      setQuantity(Number(e.target.value));
                    }}
                    onBlur={(e) => {
                      // if (
                      //   Number(e.target.value) %
                      //     Number(props.cart.product.cartoonQuantity) !=
                      //   0
                      // ) {
                      //   notification.error({
                      //     message: `Quantity must be a multiple of ${props.cart.product.cartoonQuantity}`,
                      //     placement: 'bottomLeft',
                      //   });
                      //   setQuantity(props.cart.quantity);
                      // } else {
                      //   handleQuantityValue(quantity, props.cart.productId);
                      // }
                      handleQuantityValue(quantity, props.cart.productId);
                    }}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        if (
                          Number(e.target.value) %
                            Number(props.cart.product.cartoonQuantity) !=
                          0
                        ) {
                          notification.error({
                            message: `Quantity must be a multiple of ${props.cart.product.cartoonQuantity}`,
                            placement: 'bottomLeft',
                          });
                          setQuantity(props.cart.quantity);
                        } else {
                          handleQuantityValue(quantity, props.cart.productId);
                        }
                      }
                    }}
                  />

                  {!props.cart.free ? (
                    <Button
                      className="plus"
                      onClick={() => {
                        handleQuantity('add', props.cart?.productId);
                      }}
                    >
                      <Add />
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
                <h5 className="carton-product-text text-center">
                  {calculateCartonsAndProducts(
                    quantity,
                    Number(props.cart.product.cartoonQuantity)
                  )}
                </h5>
              </div>
            )}
            <div className="cancel">
              {!props.cart.free &&
                (props.appliedCoupon ? (
                  <Popconfirm
                    title="Applied Coupon
will be removed. Are you sure you want to continue ?"
                    onConfirm={() => handleDelete(props.cart?.productId)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <CloseOutlined className=" icon" />
                  </Popconfirm>
                ) : (
                  <CloseOutlined
                    className=" icon"
                    onClick={() => handleDelete(props.cart?.productId)}
                  />
                ))}
            </div>
          </div>

          <div className="cart-detail-right-bottom">
            {parseInt(props.cart.product.cartoonQuantity) > 1 && (
              <p className="product-detail-offer-text">
                Carton size: {props.cart.product.cartoonQuantity}
              </p>
            )}
            {props.cart.product?.buyGetOffers &&
              props.cart.product?.buyGetOffers.slice(0, 2).map((item: any) => {
                return (
                  <p key={item.id} className="product-detail-offer-text">
                    {props.cart.free ? (
                      props.cart.freeQuantity === item.getQty ? (
                        <>
                          <img src={voucherIcon} className="icon" alt="icon" />{' '}
                          &nbsp; Offer Applied ( {item.buyQty} Get {item.getQty}{' '}
                          Free)
                        </>
                      ) : (
                        <></>
                      )
                    ) : (
                      <>
                        <img src={voucherIcon} className="icon" alt="icon" />{' '}
                        &nbsp; Buy {item.buyQty} {props.cart.product.uomCode} of
                        this product and get {item.getQty}{' '}
                        {props.cart.product.uomCode} absolutely free <br />
                        {Number(props.cart.product?.cartoonQuantity) > 1 && (
                          <span className="ml-md-4">
                            (Buy{' '}
                            {getCartons(
                              item.buyQty,
                              Number(props.cart.product.cartoonQuantity)
                            )}{' '}
                            carton
                            {getCartons(
                              item.buyQty,
                              Number(props.cart.product.cartoonQuantity)
                            ) > 1
                              ? 's'
                              : ''}{' '}
                            of this product and get{' '}
                            {getCartons(
                              item.getQty,
                              Number(props.cart.product.cartoonQuantity)
                            )}{' '}
                            carton
                            {getCartons(
                              item.getQty,
                              Number(props.cart.product.cartoonQuantity)
                            ) > 1
                              ? 's'
                              : ''}{' '}
                            absolutely free)
                          </span>
                        )}
                      </>
                    )}
                  </p>
                );
              })}
            {props.cart.product?.buyGetOffers &&
              props.cart.product?.buyGetOffers.length > 2 && (
                <Collapse className="cart-collapse">
                  <Panel header="View All Offers" key="1">
                    {props.cart.product?.buyGetOffers
                      .slice(2)
                      .map((item: any) => {
                        return (
                          <p
                            key={item.id}
                            className="product-detail-offer-text"
                          >
                            {props.cart.free ? (
                              <>
                                <img
                                  src={voucherIcon}
                                  className="icon"
                                  alt="icon"
                                />{' '}
                                &nbsp; Offer Applied ( {item.buyQty} Get{' '}
                                {item.getQty} Free)
                              </>
                            ) : (
                              <>
                                <img
                                  src={voucherIcon}
                                  className="icon"
                                  alt="icon"
                                />{' '}
                                &nbsp; Buy {item.buyQty} of this product and get{' '}
                                {item.getQty} absolutely free
                                <br />
                                {Number(props.cart.product?.cartoonQuantity) >
                                  1 && (
                                  <span className="product-detail-offer-text">
                                    &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&emsp;(Buy{' '}
                                    {getCartons(
                                      item.buyQty,
                                      Number(props.cart.product.cartoonQuantity)
                                    )}{' '}
                                    carton
                                    {getCartons(
                                      item.buyQty,
                                      Number(props.cart.product.cartoonQuantity)
                                    ) > 1
                                      ? 's'
                                      : ''}{' '}
                                    of this product and get{' '}
                                    {getCartons(
                                      item.getQty,
                                      Number(props.cart.product.cartoonQuantity)
                                    )}{' '}
                                    carton
                                    {getCartons(
                                      item.getQty,
                                      Number(props.cart.product.cartoonQuantity)
                                    ) > 1
                                      ? 's'
                                      : ''}{' '}
                                    absolutely free)
                                  </span>
                                )}
                              </>
                            )}
                          </p>
                        );
                      })}
                  </Panel>
                </Collapse>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
const Loader = () => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 50, color: 'white' }} spin />
  );
  return (
    <div className="loader-main">
      <Spin indicator={antIcon} />
    </div>
  );
};
export default CartProductCard;
