import { Add, CloseOutlined, Remove } from "@material-ui/icons";
import { Button, Input } from "antd";
import "./index.scss";

const ModalCartProductCard = () => {
  return (
    <div className="cart-product-card  modal-product-card">
      <div className="image modal-cart-image">
        <img src="https://random.imagecdn.app/214/151" alt="product-image" />
      </div>
      <div className="details modal-cart-details">
        <p className="title">Galina Jacuzzi Bathtub</p>

        <p className="price">$100.89</p>
        <div className="quantity modal-cart-quantity">
          <Button className="minus">
            <Remove />
          </Button>
          <Input className="value" value="0" />
          <Button className="plus">
            <Add />
          </Button>
        </div>
      </div>

      <div className="cancel modal-cart-cancel">
        <CloseOutlined className="icon" />
      </div>
    </div>
  );
};

export default ModalCartProductCard;
