import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./AddUser.scss";
import { AppstoreOutlined, MailOutlined } from "@ant-design/icons";
import { MenuProps, notification, Select } from "antd";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  SearchOutlined,
  BellOutlined,
  DownOutlined,
  ShoppingCartOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import logoTransparent from "shared/assets/logoTransparent.png";
import userPic from "../../../assets/icons/username.png";
import smartphone from "../../../assets/icons/smartphone.png";

import {
  Layout,
  Menu,
  Row,
  Col,
  Input,
  Badge,
  Dropdown,
  Space,
  Button,
  Form,
} from "antd";
import { Option } from "antd/lib/mentions";
import API_SERVICE from "services/api-service";

import TextArea from "antd/lib/input/TextArea";
import { useEffect } from "react";
import AdminListLayout from "shared/components/layout/AdminListLayout/AdminListLayout";
import AccessControl from "services/AccessControl";
const { Header, Sider, Content } = Layout;

// const { RangePicker } = DatePicker;
const menu = (
  <Menu
    items={[
      {
        label: <a href="https://www.antgroup.com">1st menu item</a>,
        key: "0",
      },
      {
        label: <a href="https://www.aliyun.com">2nd menu item</a>,
        key: "1",
      },
      {
        type: "divider",
      },
      {
        label: "3rd menu item",
        key: "3",
      },
    ]}
  />
);
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps["items"] = [
  getItem("Dashboard", "1", <AppstoreOutlined />),
  getItem("Catalogue", "sub1", <MailOutlined />, [
    getItem("Products", "g1", <ShoppingCartOutlined />),
    getItem("Categories", "g2", <ShoppingCartOutlined />),
  ]),
  getItem("Orders", "3", <ShoppingCartOutlined />),
  getItem("Dealers", "4", <AppstoreOutlined />),
  getItem("Stock Labels", "5", <AppstoreOutlined />),
  getItem("Users & Roles", "6", <AppstoreOutlined />),
];

const AddUser = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [rolesForSelect, setRolesForSlect] = useState([]);

  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
  };
  let history = useHistory();

  const { id }: any = useParams();
  const [form] = Form.useForm();

  const [selectedRole, setSelectedRole] = useState(1);

  const fetchRoles = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await API_SERVICE.getAdminAllRoles({ page: -1 });
      setRolesForSlect(items);
    } catch (error) {
      notification.error({
        message: "Something Went Wrong",
        placement: "bottomRight",
      });
    }
  };
  const onSuccess = (actionType: string) => {
    if (actionType === "Updated") history.goBack();
    if (actionType === "Created") form.resetFields();
    notification.success({
      message: `User ${actionType} Successfully`,
      placement: "bottomRight",
    });
  };

  const createOrEditUserAdmin = async () => {
    try {
      const val = await form.getFieldsValue();
      const createPayload = () => ({
        // dealerCode: null,
        name: val.name,
        password: val.password,
        phone: val.phone,
        roleId: selectedRole,
        email: val.email,
        address: val.address,
        // username: null
      });
      if (id) {
        await API_SERVICE.editAdminUserById(id, createPayload());
        onSuccess("Updated");
      } else {
        await API_SERVICE.createUserFromAdmin(createPayload());
        onSuccess("Created");
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };
  const initialRunIfEditForm = async (i: number) => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.getAdminUserById(i);
      form.setFieldsValue({
        ["address"]: data?.address,
        ["email"]: data?.email,
        ["name"]: data?.name,
        ["password"]: data.password,
        ["phone"]: data.phone,
        ["role"]: data.roleName,
      });
    } catch (error) {
      notification.error({
        message: "Something Went Wrong",
        placement: "bottomRight",
      });
    }
  };
  useEffect(() => {
    fetchRoles();
    if (id) initialRunIfEditForm(id);
  }, []);
  return (
    <AccessControl id={52}>
      <>
        <Content className="add-user-main-content">
          <div className="add-user-title">{id ? "EDIT" : "ADD"} USERs</div>
          <div className="add-user-icon">
            <ArrowLeftOutlined onClick={() => history.goBack()} />
          </div>
          <div className="add-user-form coupon-form">
            <Form
              form={form}
              onFinish={createOrEditUserAdmin}
              layout="vertical"
            >
              <Row
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 24,
                  xl: 24,
                }}
              >
                <Col xs={24} md={12}>
                  <Form.Item
                    label=" Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Name",
                      },
                    ]}
                  >
                    <Input
                      prefix={<img src={userPic} />}
                      placeholder="Name"
                      className="banner-form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Valid Email",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      prefix={<img src={smartphone} />}
                      placeholder="email"
                      className="banner-form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Role" name="role">
                    <Select
                      placeholder="Role"
                      id="selectRoles"
                      onChange={(e) => setSelectedRole(e)}
                    >
                      {rolesForSelect?.map((item: any) => (
                        <Option value={item.id}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item
                    label="Mobile Number"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Enter Phone Number",
                      },
                      {
                        min: 8,
                        message: "Phone Number must be 8 Digits.",
                      },
                      {
                        max: 13,
                        message: "Phone Number must be 13 Digits.",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      prefix={<img src={smartphone} />}
                      placeholder="Mobile Number"
                      className="banner-form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label="Password" name="password">
                    <Input
                      type="password"
                      prefix={<img src={smartphone} />}
                      placeholder="Password"
                      className="banner-form-input"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Enter Address",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Address"
                      className="address-text"
                      style={{ resize: "none" }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} className="mt-2 mb-2 ">
                  <Button htmlType="submit" className="theme-btn addUserbtn ">
                    {id ? "Update" : "Add"} User
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Content>
      </>
    </AccessControl>
  );
};

export default AddUser;
