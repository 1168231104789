import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./OfferList.scss";
import { AppstoreOutlined, MailOutlined } from "@ant-design/icons";
import { DatePicker, MenuProps, notification, Select } from "antd";

import { ShoppingCartOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import globe from "../../../assets/icons/globe.png";

import {
  Layout,
  Menu,
  Row,
  Col,
  Input,
  Badge,
  Dropdown,
  Space,
  Button,
  Form,
} from "antd";
import { Option } from "antd/lib/mentions";
import API_SERVICE from "services/api-service";

import TextArea from "antd/lib/input/TextArea";
import { useEffect } from "react";
import FileUpload from "components/FileUpload";
import moment from "moment";
import { OFFER_TYPE } from "shared/constants/Constants";
import { OFFER_LIST } from "shared/constants/RouteConstants";
const { Header, Sider, Content } = Layout;
const { RangePicker } = DatePicker;

const NewOffer = () => {
  let history = useHistory();

  //   const { id }: any = useParams();
  const [form] = Form.useForm();

  const _handleSubmit = async (data: any) => {
    try {
      const params = {
        title: data.title,
        description: data.description,
        status: data.status,
        startDate: moment(data.date[0]).format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(data.date[1]).format("YYYY-MM-DD HH:mm:ss"),
        type: data.type,
      };

      await API_SERVICE.addNewOffer(params);
      notification.success({
        message: "Offer Added Successfully",
        placement: "bottomRight",
      });
      form.resetFields();
      history.push(OFFER_LIST);
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  return (
    <>
      <Content className="add-user-main-content">
        <div className="add-user-title">CREATE NEW OFFER</div>
        <div className="add-user-icon">
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </div>
        <div className="add-user-form coupon-form">
          <Form
            form={form}
            labelCol={{
              xs: { span: 4 },
              sm: { span: 4 },
              md: { span: 8 },
              lg: { span: 6 },
              xl: { span: 4 },
            }}
            labelAlign="left"
            wrapperCol={{
              xs: { span: 2 },
              sm: { span: 2 },
              md: { span: 16 },
              lg: { span: 18 },
              xl: { span: 12 },
            }}
            layout="horizontal"
            onFinish={_handleSubmit}
          >
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Enter Title",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Title"
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Description",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Enter Description"
                    className="address-text"
                    style={{ resize: "none" }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                  label="Select Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "Select Status",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Status"
                    className="banner-form-select"
                  >
                    <Select.Option value={1}>ACTIVE</Select.Option>
                    <Select.Option value={0}>INACTIVE</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item label="Select Type" name="type">
                  <Select
                    placeholder="Select Type"
                    className="banner-form-select"
                  >
                    <Option value={OFFER_TYPE.COUPON}>Coupon Based</Option>
                    <Option value={OFFER_TYPE.BUY_X_GET_Y}>Buy X Get Y</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24}>
                <Form.Item
                  label="Start Date and Time"
                  name="date"
                  rules={[
                    {
                      required: true,
                      message: "Start Date and Time",
                    },
                  ]}
                >
                  <RangePicker
                    showTime
                    style={{ borderRadius: "8px" }}
                    className="offer-date"
                    disabledDate={(current) => {
                      return (
                        moment().add(-1, "days") >= current ||
                        moment().add(1, "month") <= current
                      );
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item
                  wrapperCol={
                    ({ sm: { offset: 4 } },
                    { md: { offset: 8 } },
                    { lg: { offset: 6 } },
                    { xl: { offset: 4 } })
                  }
                >
                  <Button
                    htmlType="submit"
                    className="send-notification-btn new-offer-btn"
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </>
  );
};

export default NewOffer;
