/* eslint-disable jsx-a11y/img-redundant-alt */
import { Add, Remove } from '@material-ui/icons';
import {
  Row,
  Col,
  Button,
  Input,
  Layout,
  Breadcrumb,
  Divider,
  notification,
} from 'antd';
import { useState, useEffect } from 'react';
import { Content } from 'antd/lib/layout/layout';
import AddToCartBtnSales from 'components/AddToCartBtnSales';
import { Link, useParams } from 'react-router-dom';
import API_SERVICE from 'services/api-service';
import './SalesProductDetail.scss';
import fastIcon from 'shared/assets/images/FastDelivery.png';
import voucherIcon from 'shared/assets/images/voucher.png';
import packageIcon from 'shared/assets/images/Package.png';
import { consverPriceToInternationalFormat } from 'services/util';
import MilanoThumbnail from '../../../assets/MilanoThumbnail.png';
import SalesCartContext from 'context/SaleCart';
import { useContext } from 'react';
import SalesSimilarProductCard from 'components/SalesSimilarProductCard/SalesSimilarProductCard';
import { DownloadOutlined } from '@ant-design/icons';

export default function SalesProductDetailsPage(props: any) {
  const { dealerId, cartProducts } = useContext(SalesCartContext);
  const [quantity, setQuantity] = useState(1);
  const [productsList, setProductsList] = useState([]);
  const params: any = useParams();
  const [productDetails, setProductDetails] = useState({
    id: 0,
    name: '',
    price: 0,
    description: '',
    fullDescription: '',
    skuId: 0,
    cbmValue: 0,
    categoryId: 0,
    label: {
      labels: '',
    },
  });

  // const { id, categoryName } = params;
  // const [productId, setProductId] = useState((id && id) || null);
  const [selectedImage, setSelectedImage] = useState();
  const [thumbnailImage, setThumbnailImage] = useState();
  const [productImages, setProductImages] = useState([
    {
      id: 1,
      file: {
        name: 'random.png',
        url: '',
      },
    },
  ]);

  const fetchProductDetails = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchProductDetails(params.id);
      setProductDetails({ ...data });
      const { productImages } = data;
      setProductImages(productImages);
      setThumbnailImage(data.thumbnail);
      setSelectedImage(productImages[0]?.file?.url);
      setProductsList(data?.similar);
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: 'bottomRight',
      });
    }
  };

  const fetchQuantity = async () => {
    cartProducts.map((item) =>
      item.productId == props.match.params.id ? setQuantity(item.quantity) : ''
    );
  };
  const downloadProductDetails = async () => {
    try {
      const params = {
        url: 'https://milano.valuescale.co.in' + props.location.pathname,
      };
      const {
        data: { data },
      } = await API_SERVICE.downloadProductDetails(productDetails.id, params);
      if (data) {
        window.open(data.url, '_blank');
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    fetchProductDetails();
    fetchQuantity();
  }, []);

  return (
    <Layout className="products-bg-color">
      <Content className="contentPadding">
        <Layout className="product-main-content-layout">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={10} lg={11} xl={12}>
              <div className="product-details-left">
                <div className="selectedImage">
                  <img
                    src={selectedImage || thumbnailImage?.url}
                    alt="Product Image"
                  />
                </div>
                <div className="allImages">
                  {productImages.map((image) => (
                    <img
                      style={{ cursor: 'pointer' }}
                      onClick={() => setSelectedImage(image.file.url)}
                      key={image.id}
                      src={image.file.url}
                      alt={image.file.name}
                    />
                  ))}
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={14} lg={13} xl={12}>
              <div className="product-details-right">
                <div className="products-details-right__top">
                  <h1 className="products-details-right__top__title">
                    {productDetails.name}
                  </h1>
                  <p className="products-details-right__top__dprice">
                    AED{' '}
                    {consverPriceToInternationalFormat(productDetails.price)}
                    {productDetails?.discount && (
                      <>
                        <span
                          className="products-details-right__top__dprice__mprice"
                          style={{ textDecoration: 'line-through' }}
                        >
                          $
                          {consverPriceToInternationalFormat(
                            productDetails.base_price
                          )}
                        </span>
                        <span className="products-details-right__top__dprice__save">
                          Save{' '}
                          {(productDetails.discount /
                            productDetails.base_price) *
                            100}
                        </span>
                      </>
                    )}
                  </p>
                </div>
                <p style={{ fontSize: '18px' }}>
                  SKU : {productDetails.skuId} | CBM : {productDetails.cbmValue}
                </p>
                <Row style={{ paddingTop: '10px' }} gutter={{ xs: 20 }}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    className="products-details-right__middle"
                  >
                    <img src={fastIcon} className="icon" />
                    Fast Delivery
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    className="products-details-right__middle"
                  >
                    <img src={voucherIcon} className="icon" />
                    Available Voucher
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    className="products-details-right__middle"
                  >
                    <img src={packageIcon} className="icon" />
                    {productDetails?.label?.labels}
                  </Col>
                </Row>
                <Divider style={{ backgroundColor: '#a7a7a7' }} />
                <Row>
                  <Col xs={24}>
                    {/*<h4 className="descrpTitle">Description</h4>*/}
                    <p className="descrp">{productDetails?.description}</p>
                  </Col>
                </Row>
                <Row style={{ padding: '20px 0' }}>
                  <Col xs={24} className="productDetailsRightBottom">
                    <p>Quantity</p>
                    <div
                      style={{
                        display: 'flex',
                        gap: '15px',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        disabled={!dealerId ? true : false}
                        className="quantityBtn minus"
                        onClick={() => {
                          if (quantity > 1) {
                            setQuantity(quantity - 1);
                          }
                        }}
                      >
                        <Remove />
                      </Button>
                      <Input
                        className="quantityBtn value"
                        value={quantity}
                        disabled={!dealerId ? true : false}
                      />
                      <Button
                        disabled={!dealerId ? true : false}
                        className="quantityBtn plus"
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <Add />
                      </Button>
                    </div>

                    <AddToCartBtnSales
                      name={'prodDetails'}
                      quantity={quantity}
                      productId={productDetails.id}
                    />
                  </Col>
                </Row>
                <Divider style={{ backgroundColor: '#a7a7a7' }} />
                <Row className="download-row">
                  <Button
                    className="download-details-btn"
                    onClick={downloadProductDetails}
                  >
                    <DownloadOutlined />
                    Download Product Details
                  </Button>
                </Row>
              </div>
            </Col>
            <Col className="description-row" xs={24}>
              <p className="main-description">Description</p>
              <Divider
                style={{ width: '192px !important', backgroundColor: 'red' }}
              />
              {/* <hr  style={{ width:'182px',backgroundColor:'red !important' }} /> */}
              <div />
              <p
                dangerouslySetInnerHTML={{
                  __html: productDetails.fullDescription,
                }}
                className="main-description-details"
              ></p>
              {/* <p className="features-title">Features :</p>
              <ul className="features-list">
                <li>
                  Preinstalled cable drive pop up and waste-overflow fitting
                  included.
                </li>
                <li>Striking upscale modern design. </li>
                <li>Extra deep, full-body soak</li>
                <li>
                  Ergonomic design forms to the body’s shape for ultimate
                  comfort.
                </li>
                <li>Quick and easy installation.</li>
                <li>Chromatherapy led light for relaxation.</li>
                <li>
                  Premium acrylic and tub thickness provides for excellent heat
                  retention
                </li>
                <li>High gloss white surface</li>
                <li>Standard Corner shape design.</li>
                <li>Non-porous surface for easy cleaning and sanitizing</li>
                <li>12 Air jets for bubble massage</li>
                <li>6 Hydro-jets for hydro massage</li>
                <li>2 Small Spine jets for Back massage</li>
                <li>
                  Solid, one-piece construction for safety and durability{" "}
                </li>
                <li>
                  Constructed of thick 100% heavy gauge sanitary grade precision
                  acrylic.
                </li>
                <li>
                  Color is consistent throughout its thickness not painted on.
                </li>
                <li>Color will not fade or lose its brilliance overtime.</li>{" "}
                <li>Wellsonic collection.</li>
                <li>Quick connect In-Line heater system(optional).</li>
                <li>
                  Soft gel Imported headrest pillow · Electronic touch pad with
                  FM system(optional).
                </li>
                <li>5 Year Manufacturing Warranty </li>
              </ul> */}
            </Col>

            {/* <Col xs={24}>
              <p className="similar-products">
                Similar Products
                <span>View All</span>
              </p>
              <div className="similar-products-content">
                {productsList.slice(0, 4).map((product: any) => {
                  return (
                    <SalesSimilarProductCard
                      key={product?.id}
                      callBack={fetchProductDetails}
                      product={product}
                      categoryName={params.categoryName}
                    />
                  );
                })}
              </div>
            </Col> */}
          </Row>
        </Layout>
      </Content>
    </Layout>
  );
}
