import React, { useEffect, useState } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import { Row, Col, Spin, Typography, notification } from "antd";
import ResetPasswordForm from "./resetPasswordForm";

import "./resetPassword.scss";
import { useTranslation } from "react-i18next";
import resetPasswordProp from "shared/assets/images/loginPageImg.png";
import logo from "shared/assets/logo.jpg";
import title from "./assets/title.png";

import { AuthConsumer } from "shared/contexts/AuthContext";
import API_SERVICE from "client/src/services/api-service";
import { COMPANY_LIST } from "../../constants/RouteConstants";

const { Title } = Typography;

interface Props {
  location: any;
}

// const contentStyle = {
//     height: '373px',
//     color: '#fff',
//     lineHeight: '373px',
//     textAlign: 'center',
//     background: '#364d79',
// };

export default function ResetPassword({ location }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const { from } = location.state || { from: { pathname: "/dealer" } };
  const [loginImage, setLoginImage] = useState();
  const getLoginBanner = async (type: string) => {
    try {
      setImageLoading(true);
      const params = {
        bannerType: type,
      };
      const {
        data: { data },
      } = await API_SERVICE.getLoginBanner(params);
      // if (data) {
      setLoginImage(data?.file?.url);
      // }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    } finally {
      setImageLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Reset Password Page";
    getLoginBanner("LOGIN_BANNER");
  }, []);

  return (
    <AuthConsumer>
      {({ isAuth, updateAuthToken }) => {
        console.log(isAuth, "========");
        return isAuth ? (
          <Redirect to={from} />
        ) : (
          <Row className="resetPassword-form-row">
            <Col
              sm={10}
              md={12}
              lg={12}
              className={"resetPassword-form-wrapper-column"}
            >
              <div className="resetPassword-form-wrapper">
                <Row>
                  <Col>
                    <div className="logo">
                      <img src={logo} alt="logo" />
                    </div>
                    <div className="resetPassword-title">Set new password</div>
                    <p className="resetPassword-text">
                      Enter new password which you have not used earlier
                    </p>
                  </Col>
                </Row>
                <ResetPasswordForm
                  onresetPassword={async (password: any) => {
                    setLoading(true);
                    try {
                      const uuid = JSON.parse(localStorage.getItem("uuid")!);
                      await API_SERVICE.changePassword(uuid, password);
                      console.log(uuid, password);
                      notification.success({
                        message: "Password changed",
                        placement: "bottomRight",
                      });
                      setLoading(false);
                      history.push("/");
                      localStorage.clear();
                    } catch (e) {
                      notification.error({
                        message: t(API_SERVICE.handleErrors(e)),
                        placement: "bottomRight",
                      });
                      setLoading(false);
                    }
                  }}
                  loading={loading}
                />
              </div>
            </Col>
            <Col sm={10} md={12} lg={12} className="resetPassword-text-column">
              {imageLoading ? (
                <div className="loaderClass">
                  <Spin tip="Loading" size="large"></Spin>
                </div>
              ) : (
                <div className="resetPassword-text-wrapper">
                  <img
                    src={loginImage}
                    alt="forgotPassword"
                    className="img-fluid resetPassword-prop-image"
                  />
                </div>
              )}
            </Col>
          </Row>
        );
      }}
    </AuthConsumer>
  );
}
