import React, { CSSProperties, useEffect, useRef, useState } from "react";
import voucherIcon from "shared/assets/images/voucher.png";
import useWindowDimensions from "shared/hooks/WindowsDimension";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  CATEGORIES,
  PRODUCT_DETAILS_PAGE,
} from "shared/constants/RouteConstants";
import {
  Row,
  Col,
  Button,
  Space,
  Input,
  Carousel,
  Card,
  Layout,
  Checkbox,
  Divider,
  Slider,
  Breadcrumb,
  Menu,
  Dropdown,
  notification,
  Pagination,
  Select,
} from "antd";
import API_SERVICE from "services/api-service";
import AddToCartBtn from "components/AddToCartBtn";
import { InboxOutlined } from "@material-ui/icons";
// import "./ProductPage.scss";
import PropTypes from "prop-types";
import {
  ArrowRightOutlined,
  HeartOutlined,
  HeartFilled,
  DownSquareOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { consverPriceToInternationalFormat } from "services/util";
import Loader from "components/Loader/Loader";
import MilanoThumbnail from "../../../assets/MilanoThumbnail.png";

const { Search } = Input;
const { Content } = Layout;

export default function SearchPage(props: any) {
  const pageTopRef = useRef();
  const [disable, setDisable] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [querySearch, setQuery] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    perpage: 12,
    total: 1,
  });

  useEffect(() => {
    fetchFilteredProducts(props?.match?.params?.query);
    setQuery(props?.match?.params?.query);
  }, [props?.match?.params?.query]);
  const fetchFilteredProducts = async (
    query?: any,
    page?: number,
    perpage?: number
  ) => {
    if (query) {
      setDisable(true);
      const params = {
        q: query || querySearch,
        page: page || 1,
        perpage: 16,
      };
      const {
        data: { data },
      } = await API_SERVICE.getAllProducts(params);
      setFilteredProducts(data.items);
      setPagination({
        page: data.page,
        perpage: data.perpage,
        total: data.total,
        pages: data.pages,
      });
      setDisable(false);
    } else {
      setFilteredProducts([]);
    }
  };

  return (
    <>
      {disable ? <Loader /> : ""}
      <Layout className="product-main-content contentPadding pb-7">
        <Row ref={pageTopRef}>
          <Col xs={16} sm={16} md={16} lg={24}>
            <Breadcrumb className="products-breadcrumb" separator=">">
              <Breadcrumb.Item>
                <Link to={CATEGORIES}>All Categories</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="products-breadcrumb-active">
                {pagination?.total} Results for {props?.match?.params?.query}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row className="m-0" gutter={{ xs: 8, sm: 16, md: 24 }}>
          <Col style={{ padding: 0 }} className="gutter-row" xs={24}>
            <Content className="content product-main-content">
              <Row
                className="view"
                style={{ margin: 0 }}
                align="middle"
                justify="space-between"
              >
                <Col xs={24} sm={24} md={12}>
                  <p className="view-page">
                    Viewing{" "}
                    <span className="view-page-number">
                      {" "}
                      {filteredProducts.length}{" "}
                    </span>
                    of
                    <span className="view-page-number">
                      {" "}
                      {pagination.total}{" "}
                    </span>
                    Products
                  </p>
                </Col>
              </Row>
              <Row
                className="flex products-display m-0"
                justify={"space-between"}
              >
                {filteredProducts.length !== 0 ? (
                  filteredProducts.map((product: any) => {
                    return (
                      <ProductCard
                        key={product.id}
                        //   categoryName={categoryName}
                        product={product}
                        //   callback={fetchCategoryProducts}
                      />
                    );
                  })
                ) : (
                  <Col xs={24} className="flex justify-content-center">
                    <h2>"No Products Available"</h2>
                  </Col>
                )}

                <Col
                  xs={24}
                  sm={24}
                  md={23}
                  lg={23}
                  style={{ display: "grid", placeItems: "flex-end" }}
                >
                  <Pagination
                    responsive
                    className="pagination"
                    showSizeChanger={false}
                    current={pagination.page}
                    defaultCurrent={pagination.page || 1}
                    total={pagination.total}
                    pageSize={pagination.perpage}
                    onChange={(page, size) => {
                      //@ts-ignore
                      setPagination({ page: page, perpage: size });
                      fetchFilteredProducts(querySearch, page, size);

                      //@ts-ignore
                      pageTopRef.current.scrollIntoView();
                    }}
                  />
                </Col>
              </Row>
            </Content>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

function ProductCard(props: any) {
  const history = useHistory();
  const catName = props.product?.category?.parentCategory?.name;
  const user = JSON.parse(localStorage.getItem("user")!);
  const currency = user?.user.dealerDetails[0]?.currencyType;
  const truncate = (title: string) => {
    return title.length > 40 ? title.substr(0, 40) + "..." : title;
  };

  const addToWIshlistHandle = async (
    productId: number,
    isWishlisted: boolean,
    isHandleMoveCart?: boolean
  ) => {
    if (isWishlisted) {
      try {
        await API_SERVICE.WishListDelete(productId);

        if (isHandleMoveCart) {
          notification.success({
            message: "Removed From Wishlist",
            placement: "bottomLeft",
          });
          props.callback();
        }
      } catch (error) {
        notification.error({
          message: "Something Went Wrong",
          placement: "bottomLeft",
        });
      }
    } else {
      try {
        await API_SERVICE.addToWishList(productId);
        notification.success({
          message: "Added to Wishlist",
          placement: "bottomLeft",
        });
        props.callback();
      } catch (err) {
        notification.error({
          message: API_SERVICE.handleErrors(err),
          placement: "bottomLeft",
        });
      }
    }
  };
  return (
    <div className="product-card-search" style={{ cursor: "pointer" }}>
      <div className="offer-line">
        {props.product?.buyGetOffers && props.product?.buyGetOffers[0] && (
          <p className="product-detail-offer-text">
            Buy {props.product?.buyGetOffers[0]?.buyQty} Get{" "}
            {props.product?.buyGetOffers[0]?.getQty} free
          </p>
        )}
      </div>
      <div
        onClick={() =>
          history.push(
            PRODUCT_DETAILS_PAGE + "/" + props?.product?.id + "/" + catName
          )
        }
      >
        <div className="product-card-image">
          <img
            src={
              props?.product?.thumbnail !== null
                ? props?.product?.thumbnail?.url
                : MilanoThumbnail
            }
            alt={props?.product?.thumbnail?.name}
          />
        </div>
        <div className="product-details">
          <h3 className="title ">{truncate(props?.product?.name)} </h3>
          <p className="price">
            {currency === "USD" ? "$" : "AED"}{" "}
            {consverPriceToInternationalFormat(props?.product?.price)}{" "}
            {parseInt(props?.product?.cartoonQuantity) > 1 && (
              <span> per piece</span>
            )}
            <span className="product-stock">
              {props?.product?.label?.labels}
            </span>
          </p>

          <div className="inventory-info">
            <div>
              <p className="product-sku">SKU {props?.product?.skuId}</p>
            </div>
            <div>
              {parseInt(props?.product?.cartoonQuantity) > 1 && (
                <p className="theme-p">
                  (Set Of {props?.product?.cartoonQuantity})
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="AddToCartBtnContainer">
        <AddToCartBtn
          name=""
          productId={props?.product.id}
          quantity={parseInt(props.product.cartoonQuantity)}
          orderCoupon={null}
        />
        <Button
          className="wishlistBtn"
          onClick={() => {
            addToWIshlistHandle(
              props.product.id,
              props.product.isWishlisted,
              true
            );
          }}
        >
          {props.product.isWishlisted ? <HeartFilled /> : <HeartOutlined />}
        </Button>
      </div>
    </div>
  );
}
