import "./AdminProfile.scss";
import { Button, Col, Form, Input, Row, notification } from "antd";
import API_SERVICE from "services/api-service";
import { Redirect, useHistory, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import lockIcon from "shared/assets/icons/lockIcon.png";
import { useTranslation } from "react-i18next";
import { ADMIN_PROFILE } from "shared/constants/RouteConstants";
import { ArrowLeftOutlined } from "@ant-design/icons";

export default function ResetAdminPassword(props: any) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory();

  const handleSubmit = async (values: any) => {
    try {
      const params = {
        oldPassword: values.oldPassword,
        password: values.confirm,
      };
      await API_SERVICE.changeAdminPassword(params);
      notification.success({
        message: "Password changed",
        placement: "bottomRight",
      });
      history.push(ADMIN_PROFILE);
    } catch (e) {
      notification.error({
        message: t(API_SERVICE.handleErrors(e)),
        placement: "bottomRight",
      });
    }
  };

  return (
    <>
      <Row className="admin-profile-main-title-row-2">
        <div className="add-dealer-icon">
          {" "}
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </div>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} className="admin-profile-container">
          <h2 className="page-title text-center" style={{ margin: "20px 0" }}>
            Reset Password
          </h2>
          <Form
            form={form}
            layout="vertical"
            fields={[]}
            onFinish={handleSubmit}
          >
            <Row
              className="admin-reset-form"
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <Col xs={24} md={24} lg={24} xl={24}>
                <Form.Item
                  className="modalLabels reset-form-admin-input"
                  label="Old Password"
                  name="oldPassword"
                  rules={[
                    {
                      required: true,
                      message: "Enter Old Password",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<img src={lockIcon} />}
                    autoComplete={"off"}
                    className="modalInputs"
                    placeholder="Enter Old Password"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24} xl={24}>
                <Form.Item
                  className="modalLabels reset-form-admin-input"
                  label="New Password"
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Enter new Password",
                    },
                  ]}
                >
                  <Input.Password
                    className="modalInputs reset-form-admin-input"
                    prefix={<img src={lockIcon} />}
                    placeholder="Enter New Password"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24} xl={24}>
                <Form.Item
                  className="modalLabels reset-form-admin-input"
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="modalInputs"
                    prefix={<img src={lockIcon} />}
                    placeholder="Confirm New Password"
                    type="password"
                  />
                </Form.Item>
              </Col>

              <Col
                xs={24}
                md={24}
                lg={24}
                xl={24}
                style={{
                  marginTop: "40px",
                }}
              >
                <Form.Item>
                  <div>
                    <Button htmlType="submit" className="reset-btn-admin">
                      Reset Password
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}
