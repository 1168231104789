import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./PendingCart.scss";
import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import SalesCartContext from "context/SaleCart";
import { SearchOutlined, DownOutlined, EyeOutlined } from "@ant-design/icons";
import { Layout, Row, Col, Input, Button, Table } from "antd";
import { notification } from "antd";
import { useEffect, useContext } from "react";
import moment from "moment";
import API_SERVICE from "services/api-service";
import { consverPriceToInternationalFormat } from "services/util";
import {
  NEW_ORDER,
  SALES_CHECKOUT,
  SALES_DASHBOARD,
  SALES_ORDER_DETAILS,
} from "shared/constants/RouteConstants";

const { Content } = Layout;
interface Props {
  location: any;
}

const PendingCart = () => {
  let history = useHistory();
  const {
    fetchCartDetails,
    cartProducts,
    setCartProducts,
    cartValues,
    setCartValues,
    dealerId,
    setDealerId,
  } = useContext(SalesCartContext);
  const [filterAttributes, setFilterAttributes] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: "15",
  } as any);
  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);

  const fetchPendingCart = async (
    pagination = {},
    sort = {},
    filters = {},
    search = {}
  ) => {
    try {
      const params = {
        ...pagination,
        ...sort,
        ...filters,
        ...search,
      };
      const {
        data: { data },
      } = await API_SERVICE.getPendingCart(params);

      setLoading(false);
      if (data) {
        setFilteredData(data.items);
        setTotal(data?.total);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  const _handleSearch = (event) => {
    if (event.which === 13) {
      setSearch({ q: event.target.value });
      setPagination((prev) => {
        return { page: 1, perpage: prev.perpage };
      });
      fetchPendingCart(
        { page: 1, perpage: pagination.perpage },
        sort,
        filters,
        {
          q: event.target.value,
        }
      );
    }
  };

  const _handleTableChanges = async (
    pagination,
    filters,
    sorter,
    extra: { currentDataSource; action }
  ) => {
    if (extra.action === "paginate") {
      setPagination({
        page: pagination.current,
        perpage: pagination.pageSize,
      });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };

  const getAllAttributes = async () => {
    const {
      data: { data },
    } = await API_SERVICE.getPendingCartAttributes();
    setFilterAttributes(data);
  };

  const viewOrderClick = async (id: number) => {
    console.log(id);
    setDealerId(id);
    history.push(SALES_CHECKOUT);
  };

  useEffect(() => {
    fetchPendingCart(pagination, sort, filters, search);
  }, [sort, filters, pagination]);

  useEffect(() => {
    getAllAttributes();
  }, []);

  const columns = [
    {
      title: "S.N.",
      dataIndex: "serialNumber",
      // sorter: (a: any, b: any) => a.serialNumber - b.serialNumber,
      render: (a: any, v: any, index: number) => (
        <div>{(pagination.page - 1) * pagination.perpage + index + 1}</div>
      ),
    },
    {
      title: "CART ID : ",
      key: "cartId",
      // dataIndex: "orderDetails",
      className: "orderid",
      width: 120,
      filters:
        filterAttributes && filterAttributes.cartId
          ? filterAttributes?.cartId.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,

      render: (d: any) => {
        return (
          <div
            onClick={() => {
              viewOrderClick(d?.dealerId);
            }}
          >
            {d?.id}
          </div>
        );
      },
    },
    {
      title: "CREATED ON  ",
      className: "placedon",
      key: "date",
      filters:
        filterAttributes && filterAttributes.date
          ? filterAttributes?.date.map((item: any) => {
              return { text: moment(item).format("DD/MM/YYYY"), value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (d: any) => {
        return moment(d?.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      title: "TOTAL CBM  ",
      dataIndex: "totalCbm",
      key: "cbm",
      filters:
        filterAttributes && filterAttributes.cbm
          ? filterAttributes?.cbm.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
    },
    {
      title: "DEALER NAME & CODE",
      key: "dealerName",
      filters:
        filterAttributes && filterAttributes.dealerName
          ? filterAttributes?.dealerName.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (d: any) => {
        return (
          <>
            {d?.name}
            <br />
            {d?.dealerCode}
          </>
        );
      },
    },
    {
      title: "TOTAL AMOUNT ",
      // dataIndex: "totalAmount",
      render: (d: any) => {
        return (
          <>
            AED {consverPriceToInternationalFormat(d.totalAmount)}
            {/* {d.totalAmount} */}
          </>
        );
      },
    },
    {
      title: "PLACED BY ",
      key: "userName",
      filters:
        filterAttributes && filterAttributes.userName
          ? filterAttributes?.userName.map((item: any) => {
              return {
                text: item,
                value: item,
              };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (d: any) => {
        return (
          <>
            {d?.placedByName}
            <br />
            {d?.placedByEmail}
          </>
        );
      },
    },
    {
      title: "ACTION ",
      render: (d: any) => (
        <Button
          shape="circle"
          onClick={() => {
            viewOrderClick(d.dealerId);
          }}
        >
          <EyeOutlined />
        </Button>
      ),
    },
  ];

  return (
    <>
      <Layout className="products-bg-color">
        <Content className="contentPadding">
          <Row
            className="align-items-center  stock-summary-container mb-2 mt-2"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
            }}
          >
            <Col xs={24}>
              <Breadcrumb
                className="products-breadcrumb"
                style={{
                  margin: "16px 0",
                }}
                separator=">"
              >
                <Breadcrumb.Item>
                  <Link to={SALES_DASHBOARD}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Manage Orders</Breadcrumb.Item>
                <Breadcrumb.Item className="products-breadcrumb-active">
                  Pending Carts
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col xs={24} lg={14}>
              <h1 className="page-title sales-page-title">Pending Carts</h1>
            </Col>

            <Col xs={24} lg={10} className="flex justify-content-end">
              <Row>
                <Col xs={24} md={15} lg={24} className="sales-table-search ">
                  Search
                  <Input
                    onKeyPress={_handleSearch}
                    className="sales-search"
                    placeholder="Search..."
                    prefix={<SearchOutlined />}
                  />
                </Col>
                {/* <Col
                  xs={24}
                  md={9}
                  lg={9}
                  className="sales-table-button-container "
                >
                  <Button
                    type="primary"
                    className="theme-btn sales-table-button"
                    onClick={() => history.push(NEW_ORDER)}
                  >
                    <PlusOutlined />
                    New Order
                  </Button>
                </Col> */}
              </Row>
            </Col>
          </Row>
          <Row className="sales-manage-order-table">
            <Col xs={24} className="mb-3">
              <Table
                scroll={{ x: true }}
                loading={loading}
                columns={columns}
                dataSource={filteredData}
                size="middle"
                pagination={{
                  size: "default",
                  current: pagination.page,
                  pageSize: pagination.perpage,
                  total: total,
                }}
                onChange={_handleTableChanges}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default PendingCart;
