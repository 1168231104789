import React, { useEffect, useState } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import { Row, Col, Spin, Typography, notification } from "antd";
import ForgotPasswordForm from "./forgotPasswordForm";
import { useTranslation } from "react-i18next";

import forgotPasswordProp from "shared/assets/images/loginPageImg.png";

import { AuthConsumer } from "shared/contexts/AuthContext";
import API_SERVICE from "client/src/services/api-service";
import AccessControl from "services/AccessControl";
import logo from "../../assets/logo.jpg";
import "./forgotPassword.scss";
const { Title } = Typography;

interface Props {
  location: any;
}

// const contentStyle = {

//     // color: '#fff',
//     // lineHeight: '373px',
//     // textAlign: 'center',
//     // background: '#364d79',
// };

export default function ForgotPassword({ location }: Props) {
  const history = useHistory();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const { from } = location.state || { from: { pathname: "/dealer" } };
  const [loginImage, setLoginImage] = useState();
  const getLoginBanner = async (type: string) => {
    try {
      setImageLoading(true);
      const params = {
        bannerType: type,
      };
      const {
        data: { data },
      } = await API_SERVICE.getLoginBanner(params);
      // if (data) {
      setLoginImage(data?.file?.url);
      // }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    } finally {
      setImageLoading(false);
    }
  };
  useEffect(() => {
    document.title = "Forgot Password";
    getLoginBanner("LOGIN_BANNER");
  }, []);

  return (
    <AuthConsumer>
      {({ isAuth, updateAuthToken }) => {
        return isAuth ? (
          <Redirect to={from} />
        ) : (
          // <div className='forgotPassword'>
          //     <div className='forgotPassword-item'>
          <Row className="forgotPassword-form-row  ">
            <Col
              sm={24}
              md={12}
              lg={12}
              className={"forgotPassword-form-wrapper-column "}
            >
              <div className="forgotPassword-form-wrapper">
                {/* <Row>
                                            <Col > */}
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                <div className="forgotPassword-title">Forgot Password?</div>
                <p className="forgotPassword-text">Reset your password</p>
                {/* </Col>
                                        </Row> */}

                <ForgotPasswordForm
                  OnforgotPassword={async (email: string) => {
                    setLoading(true);
                    try {
                      const {
                        data: { data },
                      } = await API_SERVICE.forgotPassword(email);
                      setLoading(false);
                      localStorage.setItem("otp-email", email);
                      notification.success({
                        message: data.successMessage,
                        placement: "bottomRight",
                      });
                      history.push("/otp");
                    } catch (e) {
                      notification.error({
                        // message: "Something went wrong . Please Try Again",
                        message: t(API_SERVICE.handleErrors(e)),
                        placement: "bottomRight",
                      });
                      setLoading(false);
                    }
                  }}
                  loading={loading}
                />
              </div>
            </Col>
            <Col sm={24} md={12} lg={12} className="forgotPassword-text-column">
              {imageLoading ? (
                <div className="loaderClass">
                  <Spin tip="Loading" size="large"></Spin>
                </div>
              ) : (
                <div className="forgotPassword-text-wrapper">
                  <img
                    src={loginImage}
                    alt="forgotPassword"
                    className="img-fluid forgotPassword-prop-image"
                  />
                </div>
              )}
            </Col>
          </Row>
          //     </div>
          // </div>));
        );
      }}
    </AuthConsumer>
  );
}
