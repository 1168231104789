import { ConfigProvider } from "antd";
import "./i18n";

import enUS from "antd/es/locale/en_US";

// Router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from "shared/components/ProtectedRoute";
import { AuthProvider } from "shared/contexts/AuthContext";

// Views
import "antd/dist/antd.css";
import { SalesCartProvider } from "context/SaleCart";
import { CartProvider } from "context/Cart";
import { NotificationProvider } from "context/Notification";
import ScrollToTop from "ScrollToTop";
import {
  ADMIN_DASHBOARD,
  HOME,
  SALES_DASHBOARD,
} from "shared/constants/RouteConstants";
import ForgotPassword from "views/Dealer/forgotPassword/ForgotPassword";
import Login from "views/Dealer/login/Login";
import OTP from "views/Dealer/otp/OTP";
import Register from "views/Dealer/register/Register";
import ResetPassword from "views/Dealer/resetPassword/ResetPassword";
import "./App.scss";

import AdminLayoutMilano from "views/AdminLayoutMilano";
import DealerLayout from "views/DealerLayout";
import Page404 from "views/Page404/Page404";
import SalesRoutes from "./views/SalesRoutes";

function App() {
  return (
    <ConfigProvider locale={enUS}>
      <AuthProvider>
        <SalesCartProvider>
          <CartProvider>
            <Router
              getUserConfirmation={() => {
                /* Empty callback to block the default browser prompt */
              }}
            >
              <ScrollToTop>
                <Switch>
                  <Route exact path="/" component={Login} />
                  <Route exact path="/register" component={Register} />
                  <Route exact path="/otp" component={OTP} />

                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />

                  <Route
                    exact
                    path="/reset-password"
                    component={ResetPassword}
                  />

                  <ProtectedRoute path={HOME} component={DealerLayout} />
                  <ProtectedRoute
                    path={ADMIN_DASHBOARD}
                    component={AdminLayoutMilano}
                  />
                  <ProtectedRoute
                    path={SALES_DASHBOARD}
                    component={SalesRoutes}
                  />

                  {/* <ProtectedRoute path={HOME} component={ClosedLayout} /> */}
                  {/* <ProtectedRoute path="/" component={Layout} /> */}
                  <Route path="*" component={Page404} />
                </Switch>
              </ScrollToTop>
            </Router>
          </CartProvider>
        </SalesCartProvider>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
