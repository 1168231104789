import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  AppstoreOutlined,
  MailOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Form, MenuProps, notification, Select } from "antd";
import "./index.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import logoTransparent from "shared/assets/logoTransparent.png";
import { Layout, Menu, Row, Col, Input, Badge, Dropdown, Space } from "antd";
import { FileUpload } from "components";
import globe from "../../../assets/icons/globe.png";
import API_SERVICE from "services/api-service";
import { CloudDownload } from "@material-ui/icons";
import AdminListLayout from "shared/components/layout/AdminListLayout/AdminListLayout";
import { BANNER_LIST } from "shared/constants/RouteConstants";

const { Header, Sider, Content } = Layout;

const ManageOrder = () => {
  const { id } = useParams();

  const [form] = Form.useForm();
  const [selectType, setSelectType] = useState("");
  const [id2, setId2] = useState(null);

  const [imageId, setImageId] = useState(null);
  const [bannerImageFile, setBannerImageFile] = useState({
    name: "",
    url: "",
  });

  const [categoriesData, setCategoriesData] = useState([]);
  const [productsList, setProductsList] = useState([]);

  let history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [loading, setLoading] = useState(false);

  const typeSelectData = [
    {
      key: "0",
      name: "Category",
      value: "CATEGORY",
    },
    {
      key: "1",
      name: "Product",
      value: "PRODUCT",
    },
    {
      key: "2",
      name: "Custom",
      value: "CUSTOM",
    },
  ];

  const fetchCategoriesData = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchCategories({ page: -1 });
      if (data.items !== 0) {
        setCategoriesData(data.items);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
      });
    }
  };

  const fetchAllProducts = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchCategoriesProducts({ page: -1 });
      if (data.items !== 0) {
        setProductsList(data.items);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
      });
    }
  };

  const onSelectTypeChange = (value: string) => {
    switch (value) {
      case "CATEGORY":
        setSelectType(value);
        fetchCategoriesData();
        form.setFieldsValue({
          ["typeId"]: null,
        });
        return;
      case "PRODUCT":
        setSelectType(value);
        fetchAllProducts();
        form.setFieldsValue({
          ["typeId"]: null,
        });
        return;
      case "CUSTOM":
        setSelectType(value);
        return;
    }
  };

  const handleSubmit = async (data: any) => {
    try {
      const params = {
        bannerType: "HOME_BANNER",
        type: data.type,
        imageId: imageId,
        displayOrder: Number(data.displayOrder),
        status: data.status,
      };
      if (data.typeId) {
        //@ts-ignore
        params["typeId"] = data.typeId;
      }
      if (data.customUrl) {
        //@ts-ignore
        params["customUrl"] = data.customUrl;
      }
      await API_SERVICE.addHomeBanner(params);
      notification.success({
        message: "Banner Added Successfully",
        placement: "bottomRight",
      });
      setImageId(null);
      form.resetFields();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  const handleUpdate = async (data: any) => {
    try {
      const params = {
        bannerType: "HOME_BANNER",
        type: data.type,
        imageId: imageId,
        displayOrder: Number(data.displayOrder),
        status: data.status,
      };
      if (data.typeId) {
        //@ts-ignore
        params["typeId"] = data.typeId;
      }
      if (data.customUrl) {
        //@ts-ignore
        params["customUrl"] = data.customUrl;
      }

      await API_SERVICE.updateHomeBanner(id, params);
      notification.success({
        message: "Banner Updated Successfully",
        placement: "bottomRight",
      });
      setImageId(null);
      history.push(BANNER_LIST);
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  const fetchSingleBanner = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchSingleBannerDetails(id);
      if (data) {
        setSelectType(data.type);
        onSelectTypeChange(data.type);
        if (data.customUrl) {
          form.setFieldsValue({
            ["customUrl"]: data.customUrl,
          });
        }
        if (data.typeId) {
          setId2(data.typeId);
        }
        setBannerImageFile({
          name: data.file.name,
          url: data.file.url,
        });
        setImageId(data.imageId);
        form.setFieldsValue({
          ["type"]: data.type,
          ["displayOrder"]: data.displayOrder,
          ["status"]: data.status,
          ["typeId"]: data.typeId,
        });
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchSingleBanner();
    }
  }, []);

  return (
    <>
      <div className="add-dealer-icon">
        {" "}
        <ArrowLeftOutlined onClick={() => history.goBack()} />
      </div>
      <Row
        className="align-items-center  stock-summary-container mb-2 mt-2"
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}
      >
        <Col xs={12} md={12}>
          <div className="manage-products-title">
            {id ? "Edit Banner" : "Add Banner"}
          </div>
        </Col>
        <Col xs={12} md={12} className="flex justify-content-end">
          <Button
            className="product-btn"
            onClick={() => history.push(BANNER_LIST)}
          >
            Go to Banner List
          </Button>
        </Col>
      </Row>
      <Row className="admin-banner-form">
        <Col xs={24} sm={24} className="p-2 " style={{ position: "relative" }}>
          <Form
            form={form}
            labelCol={{
              xs: { span: 4 },
              sm: { span: 4 },
              md: { span: 8 },
              lg: { span: 6 },
              xl: { span: 4 },
            }}
            labelAlign="left"
            wrapperCol={{
              xs: { span: 2 },
              sm: { span: 2 },
              md: { span: 16 },
              lg: { span: 18 },
              xl: { span: 12 },
            }}
            layout="horizontal"
            onFinish={id ? handleUpdate : handleSubmit}
          >
            <Form.Item
              label="Select Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Select Type",
                },
              ]}
            >
              <Select
                placeholder="Select Type"
                className="banner-form-select"
                onChange={onSelectTypeChange}
              >
                {typeSelectData.map((t) => (
                  <Select.Option key={t.key} value={t.value}>
                    {t.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {selectType === "CUSTOM" ? (
              <Form.Item
                label="Custom Url"
                name="customUrl"
                rules={[
                  {
                    required: true,
                    message: "Enter Url Please",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Custom Url"
                  className="banner-form-input"
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="Select Id"
                name="typeId"
                rules={[
                  {
                    required: true,
                    message: "Select Id",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option?.children[1]
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Search to Select Id"
                  className="banner-form-select"
                  onChange={(d) => console.log(d)}
                >
                  {selectType === "CATEGORY"
                    ? categoriesData.map((cat: any) => {
                        return (
                          <Select.Option value={cat.id}>
                            <img
                              src={cat?.file?.url}
                              className="banner-form-select-icon"
                              alt="icon"
                            />
                            {cat.name}
                          </Select.Option>
                        );
                      })
                    : productsList.map((prod: any) => {
                        return (
                          <Select.Option value={prod.id}>
                            <img
                              src={prod?.thumbnail?.url}
                              className="banner-form-select-icon"
                              alt="icon"
                            />
                            {prod.name}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
            )}

            <Form.Item label="Select Image / Video" name="file1">
              <FileUpload
                type={"BANNER"}
                onChange={(id: any) => {
                  setImageId(id);
                }}
              >
                <div className="fileUploadDesign m-0">
                  <div>
                    <img src={globe} alt="icon" />
                    Upload Image
                  </div>
                  <p className="text">Upload</p>
                </div>
              </FileUpload>
              {id ? (
                <p className="LastUploadedFileCss mt-2">
                  {bannerImageFile?.name}

                  <a
                    target="_blank"
                    onClick={() => window.open(bannerImageFile?.url)}
                  >
                    <CloudDownload />
                    Download
                  </a>
                </p>
              ) : (
                <></>
              )}
            </Form.Item>
            <Form.Item
              label="Display Order"
              name="displayOrder"
              rules={[
                {
                  required: true,
                  message: "Enter Display Order",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Enter Display Order"
                className="banner-form-input"
              />
            </Form.Item>

            <Form.Item
              label="Select Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: "Select Status",
                },
              ]}
            >
              <Select
                placeholder="Select Status"
                className="banner-form-select"
              >
                <Select.Option value="ACTIVE">ACTIVE</Select.Option>
                <Select.Option value="INACTIVE">INACTIVE</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label=" ">
              {id ? (
                <Button htmlType="submit">Update</Button>
              ) : (
                <Button htmlType="submit">Save</Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ManageOrder;
