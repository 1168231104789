import {
  Row,
  Col,
  Button,
  Input,
  Layout,
  Breadcrumb,
  Divider,
  Form,
  Modal,
  notification,
  InputNumber,
  Select,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import './index.scss';
import plusCircle from 'shared/assets/images/plus-circle.png';
import globe from '../../../assets/icons/globe.png';
import home from '../../../assets/icons/home.png';
import mapPin from '../../../assets/icons/map-pin.png';
import map from '../../../assets/icons/map.png';
import smartphone from '../../../assets/icons/smartphone.png';
import userPic from '../../../assets/icons/username.png';
import mailIcon from '../../../assets/icons/mail.png';
import API_SERVICE from 'services/api-service';
// import { countryList } from "shared/constants/Constants";
import { City, ICity } from 'country-state-city';

const { Search } = Input;

const { Option } = Select;

interface Values {
  id: number;
  name: string;
  phone: number;
  address: string;
  building: string;
  street: string;
  area: string;
  zipcode: number;
  city: string;
  country: string;
}

export default function ManageAddress(props: any) {
  const [form] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [addresses, SetAddresses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState<ICity[]>([]);

  const [countrySelected, setCountry] = useState('');
  const showModal = (value: string) => {
    setIsModalOpen(true);
    setModalTitle(value);
    form.setFieldsValue({ country: countrySelected });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  //AddingAddress
  const addAddress = async (values: Values) => {
    const user = JSON.parse(localStorage.getItem('user')!);
    const userId = user.user.id;
    console.log('values', values);

    if (values.phone.length === 10) {
      const params = {
        userId: userId,
        name: values.name,
        phone: values.phone,
        address: values.address,
        building: values.building,
        street: values.street,
        area: values.area,
        zipcode: values.zipcode,
        city: values.city,
        country: values.country,
      };

      try {
        await API_SERVICE.addAddress({ ...params });
        notification.success({
          message: 'Added Address Successfully',
          placement: 'bottomLeft',
        });
        form.resetFields();
        fetchAddress();
        setIsModalOpen(false);
      } catch (error) {
        notification.error({
          message: 'Something Went Wrong !  Please try again !',
          placement: 'bottomLeft',
        });
      }
    } else {
      notification.error({
        message: 'Please Enter Valid Mobile Number ',
        placement: 'bottomLeft',
      });
    }
    // a commnent
  };

  //fetchAddress
  const fetchAddress = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.GetAddress();

      SetAddresses(data.items);
    } catch (error) {
      notification.error({
        message: 'Something Went Wrong',
        placement: 'bottomLeft',
      });
    }
  };
  const getCountry = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.self();

      setCountry(data.dealerDetails[0].country);
    } catch (error) {
      notification.error({
        message: 'Something Went Wrong',
        placement: 'bottomLeft',
      });
    }
  };

  //editAddress
  const editAddress = async (addr: Values) => {
    const editParams: {} = {
      id: addr.id,
      name: addr.name,
      phone: addr.phone,
      address: addr.address,
      building: addr.building,
      street: addr.street,
      area: addr.area,
      zipcode: addr.zipcode,
      city: addr.city,
      country: addr.country,
    };
    setEdit(true);
    form.setFieldsValue({ ...editParams });
  };
  //updateAddress
  const updateAddress = async (values: Values) => {
    try {
      await API_SERVICE.editAddress({ ...values });
      notification.success({
        message: 'Updated Address Successfully',
        placement: 'bottomLeft',
      });
      fetchAddress();

      setIsModalOpen(false);
      form.resetFields();
      setEdit(false);
    } catch (error) {
      notification.error({
        message: 'Something Went Wrong',
        placement: 'bottomLeft',
      });
    }
  };
  //updateAddress
  const deleteAddress = async (id: number) => {
    try {
      await API_SERVICE.deleteAddress(id);
      notification.success({
        message: 'Delete Address Successfully',
        placement: 'bottomLeft',
      });
      fetchAddress();

      setIsModalOpen(false);
    } catch (e) {
      notification.error({
        message: API_SERVICE.handleErrors(e),
        placement: 'bottomLeft',
      });
    }
  };

  const confirm = (id: number) => {
    Modal.confirm({
      title: 'Are You Sure ?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      onOk: () => deleteAddress(id),
      cancelText: 'Cancel',
    });
  };
  const getCountryList = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await API_SERVICE.countryList({ page: -1 });

      if (items) setCountryList(items);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: 'bottomLeft',
      });
    }
  };

  useEffect(() => {
    document.title = 'Manage Address';
    getCountry();
    fetchAddress();
    getCountryList();

    //Get UAE cities
    const cities: ICity[] = City.getCitiesOfCountry('AE') as ICity[];

    setCityList(cities);
  }, []);

  return (
    <Layout className="address-bg-color">
      <Content className="contentPadding">
        <Breadcrumb className="products-breadcrumb " separator=">">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item className="products-breadcrumb-active">
            Manage Address
          </Breadcrumb.Item>
        </Breadcrumb>

        <Layout className="address-main-content-layout">
          <div className="title">Manage Addresses</div>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col
              className="gutter-row"
              xs={24}
              sm={24}
              md={24}
              lg={12}
              lg={12}
              xl={8}
            >
              <div className="add-address-box">
                <div className="add-address">
                  <img
                    className="img-add-address"
                    onClick={() => showModal('Add Address')}
                    src={plusCircle}
                    alt=""
                  />
                  <p>Add New Address</p>
                </div>
              </div>
            </Col>

            <Modal
              title={modalTitle}
              open={isModalOpen}
              onCancel={() => {
                handleCancel();
                setEdit(false);
              }}
              footer={null}
              className="add-address-modal"
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={edit ? updateAddress : addAddress}
              >
                <Row
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 24,
                    xl: 24,
                  }}
                >
                  {edit ? (
                    <Col xs={24} sm={24} md={12} lg={12} lg={12} hidden={true}>
                      <Form.Item name="id">
                        <Input
                          placeholder="Name"
                          required={true}
                          hidden={true}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    ''
                  )}
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="modalLabels"
                      label="Contact Person Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Contact Person Name',
                        },
                        // {
                        //   pattern: /^[A-Za-z ]+$/,
                        //   message: "Contact Person Name Cannot Be a number",
                        // },
                      ]}
                    >
                      <Input
                        className="modalInputs"
                        prefix={<img src={userPic} />}
                        placeholder="Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="modalLabels"
                      label="Mobile Number"
                      name="phone"
                      tooltip="Enter Valid Phone Number"
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Valid Phone Number',
                        },
                        {
                          min: 8,
                          message: 'Phone Number must between 8-13 Digits.',
                        },
                        {
                          max: 13,
                          message: 'Phone Number must between 8-13 Digits.',
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        className="modalInputs"
                        prefix={<img src={smartphone} />}
                        placeholder="Mobile Number"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      className="modalLabels"
                      label="Line Address"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Address',
                        },
                      ]}
                    >
                      <Input
                        className="modalInputs"
                        prefix={<img src={mapPin} />}
                        placeholder="Line Address"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="modalLabels"
                      label="Building"
                      name="building"
                      rules={[
                        {
                          required: true,
                          message: 'Building Details Required',
                        },
                      ]}
                    >
                      <Input
                        className="modalInputs"
                        prefix={<img src={home} />}
                        includes
                        placeholder="Building"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      label="Street"
                      name="street"
                      className="modalLabels"
                      rules={[
                        {
                          required: true,
                          message: 'Street Required',
                        },
                      ]}
                    >
                      <Input
                        className="modalInputs"
                        prefix={<img src={map} />}
                        placeholder="Street"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      label="Area"
                      name="area"
                      className="modalLabels"
                      rules={[
                        {
                          required: true,
                          message: 'Area Details Required',
                        },
                      ]}
                    >
                      <Input
                        className="modalInputs"
                        prefix={<img src={mapPin} />}
                        placeholder="Area"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      className="modalLabels"
                      label="PO Box/Zip Code"
                      name="zipcode"
                      rules={[
                        {
                          required: true,
                          message: 'Zip Code / PO Box Required',
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        className="modalInputs"
                        prefix={<img src={mailIcon} />}
                        placeholder="PO Box/Zip Code"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      label="City"
                      name="city"
                      className="modalLabels"
                      rules={[
                        {
                          required: true,
                          message: 'City Name Required ',
                        },
                      ]}
                    >
                      {/* <Input
                        className="modalInputs"
                        prefix={<img src={mailIcon} />}
                        placeholder="City"
                      /> */}
                      <span className="select-with-prefix">
                        <img src={mailIcon} className="select-prefix-icon" />
                        <Select
                          className="select-with-prefix-input"
                          placeholder={'City'}
                          showSearch
                          onChange={(v: any) => {
                            // const newValue = JSON.parse(v);
                            // setOrderBy({
                            //   order: newValue.order,
                            //   orderBy: newValue.orderBy,
                            // });
                          }}
                        >
                          {cityList.length !== 0
                            ? cityList.map((city: ICity) => {
                                return (
                                  <Option key={city.name} value={city.name}>
                                    {city.name}
                                  </Option>
                                );
                              })
                            : ''}
                        </Select>
                      </span>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                      label="Country"
                      name="country"
                      className="modalLabels modalLabelsSelect"
                      rules={[
                        {
                          required: true,
                          message: 'Country Name Required ',
                        },
                      ]}
                    >
                      <Input
                        disabled
                        prefix={<img src={globe} />}
                        placeholder="Country"
                        className="modalInputs"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    style={{
                      textAlign: 'center',
                      marginTop: '40px',
                    }}
                  >
                    <Form.Item>
                      {edit ? (
                        <Button
                          htmlType="submit"
                          className="theme-btn addAddressBtn "
                        >
                          Update Address
                        </Button>
                      ) : (
                        <Button htmlType="submit" className="theme-btn ">
                          Add Address
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Modal>

            {addresses.length !== 0 ? (
              addresses.map((addr: any) => {
                return (
                  <Col
                    key={addr.id}
                    className="gutter-row"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    lg={12}
                    xl={8}
                  >
                    <div className="address-box">
                      <p className="address-box-title">{addr.name}</p>
                      <div className="address-box-main-details">
                        <p>
                          {addr.building},{addr.address},{addr.street},{' '}
                          {addr.area},{addr.city}, {addr.country}
                        </p>
                        <p>Phone Number: {addr.phone}</p>
                      </div>
                      <Divider className="divide" dashed={true} />
                      <div>
                        <Button
                          className="address-action"
                          onClick={() => {
                            showModal('Edit Address');
                            editAddress(addr);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          className="address-action"
                          onClick={() => confirm(addr.id)}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <p className="no-prod">No Address Added Yet</p>
            )}
          </Row>
        </Layout>
      </Content>
    </Layout>
  );
}
