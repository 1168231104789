import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "./index.scss";

function Donut(props: any) {
  const [arr1] = useState([]);
  const [arr2] = useState([]);
  const [colors] = useState([]);

  const calculation = () => {
    props.orderChartStatus?.data?.forEach((item) => {
      if (item.status) arr1.push(item?.status.replace(/_/g, " "));
    });
    props.orderChartStatus?.data?.forEach((item) => {
      if (item.total) arr2.push((item?.total / 100) * props?.totalOrders?.data);
    });
    props.orderChartStatus?.data?.forEach((item: any) => {
      if (item.statusColor) colors.push(item?.statusColor?.hex);
    });
  };

  console.log(colors);
  useEffect(() => {
    calculation();
  }, []);

  return (
    <>
      <Chart
        className="donut-big dealer-donut"
        type="donut"
        // height={600}
        // width={500}
        series={arr2}
        options={{
          chart: {
            width: 100,
            height: 100,
          },
          legend: {
            // position:absolute,
            top: 100,
          },
          colors: colors.length > 0 ? colors : undefined,
          labels: arr1,
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    // label: "Total",
                    fontSize: 28,
                    showAlways: true,
                  },
                  // name: {
                  //   show: false,
                  //   label: "Total",
                  // },
                  value: { show: true, fontSize: 16, offsetY: 20 },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
            // formatter: function (val) {
            //   return val + "%";
            // },
          },
        }}
      />
      <div className="dealer-chart-info">
        {props?.orderChartStatus?.data?.length !== 0
          ? props?.orderChartStatus?.data?.map((chart: any, index: number) => {
              return (
                <div className="row" key={index}>
                  <p>
                    <span
                      className="color"
                      style={{
                        backgroundColor: `${chart?.statusColor?.hex}`,
                      }}
                    ></span>
                    {chart?.status}
                  </p>
                  <p className="percent">
                    {" "}
                    {(chart?.total / 100) * props?.totalOrders?.data}{" "}
                  </p>
                </div>
              );
            })
          : "No Details "}
      </div>
    </>
  );
}

export default Donut;
