import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@material-ui/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Input,
  Layout,
  notification,
  Row,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import API_SERVICE from "client/src/services/api-service";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { SALES_DASHBOARD } from "shared/constants/RouteConstants";
import "./index.scss";

function DealerList() {
  let history = useHistory();
  const [fetchingXls, setFetchingXls] = useState(false);
  const [fetchingPdf, setFetchingPdf] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filterAttributes, setFilterAttributes] = useState({});
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: "15",
  } as any);
  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);

  const _handleSearch = (event) => {
    if (event.which === 13) {
      setSearch({ q: event.target.value });
      setPagination((prev) => {
        return { page: 1, perpage: prev.perpage };
      });
      GetAllDealerListSales(
        { page: 1, perpage: pagination.perpage },
        sort,
        filters,
        {
          q: event.target.value,
        }
      );
    }
  };

  const getAllAttributes = async () => {
    const {
      data: { data },
    } = await API_SERVICE.GetSalesDealerListAttribute();
    setFilterAttributes(data);
  };

  const GetAllDealerListSales = async (
    pagination = {},
    sort = {},
    filters = {},
    search = {}
  ) => {
    try {
      const params = {
        ...pagination,
        ...sort,
        ...filters,
        ...search,
      };
      setLoading(false);
      const data = await API_SERVICE.GetAllDealerListSalesManager(params);
      if (data) {
        setFilteredData(data.data.data.items);
        setTotal(data?.total);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  const _handleTableChanges = async (
    pagination,
    filters,
    sorter,
    extra: { currentDataSource; action }
  ) => {
    if (extra.action === "paginate") {
      setPagination({
        page: pagination.current,
        perpage: pagination.pageSize,
      });
    }
    if (extra.action === "sort") {
      if (sorter.dealer) {
        setSort({
          dealerName: sorter.columnKey,
          dealer: sorter.dealer === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.dealer && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };

  useEffect(() => {
    GetAllDealerListSales(pagination, sort, filters, search);
  }, [sort, filters, pagination]);

  const columns = [
    {
      title: "S.N.",
      dataIndex: "key",
      render: (a: any, v: any, index: number) => (
        <div>{(pagination.page - 1) * pagination.perpage + index + 1}</div>
      ),
    },
    {
      title: "DEALER CODE",
      dataIndex: "dealerCode",
      key: "dealerCode",
      className: "user-name",
      filterSearch: true,
      filters:
        filterAttributes && filterAttributes.dealerCode
          ? filterAttributes?.dealerCode.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "DEALER NAME",
      dataIndex: "dealerName",
      filterSearch: true,
      filters:
        filterAttributes && filterAttributes.dealerName
          ? filterAttributes?.dealerName.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      filterSearch: true,
      filters:
        filterAttributes && filterAttributes.email
          ? filterAttributes?.email.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },

    {
      title: "ADDRESS",
      dataIndex: "address",
      key: "address",
      filterSearch: true,
      filters:
        filterAttributes && filterAttributes.address
          ? filterAttributes?.address.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      filterSearch: true,
      filters:
        filterAttributes && filterAttributes.phone
          ? filterAttributes?.phone.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "SALES MANAGER",
      filterSearch: true,
      key: "salesManager",
      filters:
        filterAttributes && filterAttributes.salesManager
          ? filterAttributes?.salesManager.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      render: (d: any) => (
        <>
          {d.salesManager} <br /> {d.salesManagerEmail}
        </>
      ),
    },
    {
      title: "STATUS",
      render: (d: any) => <>{d.status === 1 ? "ACTIVE" : "INACTIVE"}</>,
    },
  ];

  const GetAllDealerListSalesManagerPDF = async () => {
    try {
      setFetchingPdf(true);
      const {
        data: { data },
      } = await API_SERVICE.GetAllDealerListSalesManagerPDF();
      window.open(data.url, "_blank");
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      setFetchingPdf(false);
    }
  };
  const GetAllDealerListSalesManagerXLS = async () => {
    try {
      setFetchingXls(true);
      const {
        data: { data },
      } = await API_SERVICE.GetAllDealerListSalesManagerXLS();
      window.open(data.url, "_blank");
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      setFetchingXls(false);
    }
  };
  useEffect(() => {
    getAllAttributes();
  }, []);

  return (
    <>
      <Layout className="products-bg-color">
        <Content className="contentPadding">
          <Row
            className="align-items-center  stock-summary-container mb-2 mt-2"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
            }}
          >
            <Col xs={24}>
              <Breadcrumb
                className="products-breadcrumb"
                style={{
                  margin: "16px 0",
                }}
                separator=">"
              >
                <Breadcrumb.Item>
                  <Link to={SALES_DASHBOARD}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item className="products-breadcrumb-active">
                  Delers List
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5}>
              <h1 className="page-title sales-page-title">Dealer List</h1>
            </Col>

            <Col xs={24} md={19} lg={19} className="flex justify-content-end">
              <Row>
                <Col xs={24} md={10} lg={10} className="sales-table-search ">
                  Search
                  <Input
                    onKeyPress={_handleSearch}
                    className="sales-search ml-2"
                    placeholder="Search..."
                    prefix={<SearchOutlined />}
                  />
                </Col>
                <Col
                  xs={24}
                  md={14}
                  lg={14}
                  className="sales-table-button-container "
                >
                  <Button
                    loading={fetchingXls}
                    type="primary"
                    className="theme-btn sales-table-button sales-table-download"
                    onClick={() => GetAllDealerListSalesManagerXLS()}
                  >
                    <DownloadOutlined className="icon" />
                    Download XLS
                  </Button>
                  <Button
                    loading={fetchingPdf}
                    type="primary"
                    className="theme-btn sales-table-button sales-table-download"
                    onClick={() => GetAllDealerListSalesManagerPDF()}
                  >
                    <DownloadOutlined className="icon" />
                    Download PDF
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="sales-dealer-list-table">
            <Col xs={24} className="mb-3 mt-2">
              <Table
                scroll={{ x: true }}
                loading={loading}
                columns={columns}
                dataSource={filteredData}
                size="middle"
                pagination={{
                  size: "default",
                  current: pagination.page,
                  pageSize: pagination.perpage,
                  total: total,
                }}
                onChange={_handleTableChanges}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}

export default DealerList;
