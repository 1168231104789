import { Add, Delete, Remove } from "@material-ui/icons";
import { Button, Row, Col, notification, Input } from "antd";
import "./index.scss";

import MilanoThumbnail from "../../assets/MilanoThumbnail.png";
import { consverPriceToInternationalFormat } from "services/util";
import { useHistory } from "react-router-dom";
import API_SERVICE from "services/api-service";
import { useContext, useRef, useState } from "react";
import SalesCartContext from "context/SaleCart";

const SalesCheckoutCard = ({ prod }) => {
  const history = useHistory();
  const {
    fetchCartDetails,
    cartProducts,
    pagination,
    setPagination,
    dealerId,
    setCurrentDiscount,
  } = useContext(SalesCartContext);
  const quantityValue = useRef<HTMLInputElement>(null);

  const [quantity, setQuantity] = useState(prod.quantity);
  let searchTimer: any = undefined;

  const handleQuantityValue = async (quantity: number, productId: number) => {
    try {
      const params = {
        productId,
        quantity,
        dealerId,
      };
      await API_SERVICE.addToCartSales(params);
      notification.success({
        message: `Updated quantity`,
        placement: "bottomRight",
      });

      fetchCartDetails();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  const handleQuantity = (action: string, productId: number) => {
    if (action === "minus") {
      if (quantity > 1) {
        setQuantity(quantity - 1);
        handleQuantityValue(quantity - 1, productId);
      }
      if (quantity === 1) {
        handleDelete(productId);
      }
    } else {
      setQuantity((prev) => prev + 1);
      handleQuantityValue(quantity + 1, productId);
    }
  };

  const handleDelete = async (productId: number) => {
    try {
      await API_SERVICE.deleteSalesCartProduct(dealerId, productId);
      notification.success({
        message: `Product Deleted Successfully`,
        placement: "bottomRight",
      });
      if (cartProducts.length === 1) {
        let page = pagination.page - 1;
        setPagination((prev: { page: number }) => {
          return prev.page - 1;
        });
        fetchCartDetails(page);
      } else {
        fetchCartDetails(pagination.page);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };
  const handlePriceChangeAPI = async (price: any) => {
    try {
      const params = {
        dealerId: dealerId,
        productId: prod.productId,
        quantity: quantity,
        price: price,
      };
      await API_SERVICE.updatePriceCart(params);

      notification.success({
        message: `Successfully Updated`,
        placement: "topRight",
      });
      fetchCartDetails();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "topRight",
      });
    }
  };
  const handlePriceChange = (price: any) => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      handlePriceChangeAPI(price);
    }, 1000);
  };
  return (
    <div className="cart-product-card sales-checkout-product-card">
      <div className="image sales-checkout-card-image">
        <img
          src={
            prod.product.thumbnail !== null
              ? prod.product.thumbnail.url
              : MilanoThumbnail
          }
          alt=""
          style={{ objectFit: "cover" }}
        />
      </div>
      <div className="details sales-checkout-card-details">
        <p className="title">{prod.product.name}</p>
        {/* <div className="sku" > */}
        <Row
          gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}
          className="sku"
          justify="space-between"
        >
          <Col xs={4} sm={4} md={4} lg={4}>
            <p> SKU:{prod.product.skuId} </p>
          </Col>
          <Col>
            <p>CBM:{prod.product.cbmValue}</p>
          </Col>
        </Row>

        {/* </div> */}
        {/* <div className="sku">

        </div> */}

        <div className="quantity sales-checkout-page-card-quantity ">
          <Button
            className="minus"
            disabled
            onClick={() => handleQuantity("minus", prod.productId)}
          >
            <Remove />
          </Button>
          <input
            disabled
            className="value"
            value={quantity}
            ref={quantityValue}
          />
          <Button
            disabled
            className="plus"
            onClick={() => handleQuantity("add", prod.productId)}
          >
            <Add />
          </Button>
        </div>
      </div>

      <div className="cancel sales-checkout-card-cancel">
        <div className="price">
          <div>AED </div>
          <div>
            <Input
              disabled
              type="number"
              defaultValue={prod.product.price}
              onChange={(e) => handlePriceChange(e.target.value)}
              className="sales-checkout-input"
            />
          </div>
        </div>
        <Button disabled className="theme-btn1 sales-checkout-delete">
          <Delete
            className="icon"
            onClick={() => handleDelete(prod.productId)}
          />
        </Button>
      </div>
    </div>
  );
};

export default SalesCheckoutCard;
