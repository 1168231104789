import { CSSProperties, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Space,
  Input,
  Card,
  notification,
  Layout,
  Breadcrumb,
} from "antd";
import "./categories.scss";
import API_SERVICE from "services/api-service";
import { Content } from "antd/lib/layout/layout";
import { Link, useHistory } from "react-router-dom";
import {
  CATEGORIES,
  HOME,
  PRODUCT_MAIN,
} from "shared/constants/RouteConstants";
import MilanoThumbnail from "../../../assets/MilanoThumbnail.png";
import Loader from "components/Loader/Loader";

export default function Categories(props: any) {
  const history = useHistory();
  const [categoriesData, setCategoriesData] = useState([]);
  const [disable, setDisable] = useState(false);
  const fetchCategoriesData = async () => {
    try {
      setDisable(true);
      const {
        data: { data },
      } = await API_SERVICE.fetchCategories({ page: -1 });
      if (data.items !== 0) {
        setCategoriesData(
          data.items
            .filter((item: any) => item?.isActive === true)
            .sort((a: any, b: any) => {
              return a.order - b.order;
            })
        );
      }
      setDisable(false);
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };

  useEffect(() => {
    fetchCategoriesData();
  }, []);

  return (
    <>
      {disable ? <Loader /> : ""}
      <Layout className="cart-bg-color">
        <Content className="contentPadding">
          <Breadcrumb className="products-breadcrumb mb-2 mt-4" separator=">">
            <Breadcrumb.Item>
              <Link to={HOME}>Home</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item className="products-breadcrumb-active">
              All Categories
            </Breadcrumb.Item>
          </Breadcrumb>

          <Row align="middle" justify="center">
            <Col xs={24}>
              <h1 className="category-page-title">All Categories</h1>
            </Col>
          </Row>

          <Row className=" categories-container mt-2 mb-7">
            {categoriesData.length !== 0 ? (
              categoriesData.map((c: any) => {
                return (
                  <Card
                    onClick={() =>
                      history.push(
                        PRODUCT_MAIN + "/" + c["id"] + "/" + c["name"]
                      )
                    }
                    key={c.id}
                    className="card-box category-card"
                    cover={
                      <img
                        className="photo"
                        src={c.file?.url ? c.file?.url : MilanoThumbnail}
                        alt=""
                      />
                    }
                  >
                    <p className="img-text text-truncate">{c["name"]}</p>
                  </Card>
                );
              })
            ) : (
              <Col xs={24} className="flex justify-content-center">
                <h2>"No Categories Available"</h2>
              </Col>
            )}

            {/* <Col>
          <a href="">
            <Card
              className="card-box"
              cover={
                <img
                  className="photo"
                  src="https://via.placeholder.com/300"
                  alt=""
                />
              }
            >
              <p className="img-text">Rain Shower</p>
            </Card>
          </a>
        </Col>
        <Col>
          <a href="">
            <Card
              className="card-box"
              cover={
                <img
                  className="photo"
                  src="https://via.placeholder.com/300"
                  alt=""
                />
              }
            >
              <p className="img-text">Bath tub & Shower Room</p>
            </Card>
          </a>
        </Col>
        <Col>
          <a href="">
            <Card
              className="card-box"
              cover={
                <img
                  className="photo"
                  src="https://via.placeholder.com/300"
                  alt=""
                />
              }
            >
              <p className="img-text">Vanity</p>
            </Card>
          </a>
        </Col> */}
          </Row>
        </Content>
      </Layout>
    </>
  );
}
