import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import {
  Add,
  LocationOnOutlined,
  Remove,
  SearchOutlined,
} from "@material-ui/icons";
import { Badge, Form, Pagination, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./OrderDetails.scss";
import MilanoThumbnail from "../../../assets/MilanoThumbnail.png";
import {
  ArrowLeftOutlined,
  CalendarOutlined,
  CodeSandboxOutlined,
  DollarCircleOutlined,
  DownOutlined,
  UserOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Table,
} from "antd";
import moment from "moment";
import API_SERVICE from "services/api-service";
import {
  consverPriceToInternationalFormat,
  textTransform,
} from "services/util";
import dollarIcon from "shared/assets/images/dollar-sign.png";
import uploadIcon from "shared/assets/images/upload.png";
import SearchCard from "../../../components/SearchCard/SearchCard";
import { FileUpload } from "components";
import {
  STATUS_CONFIRMED,
  STATUS_DELIVERED,
  STATUS_LPO_SIGN_OFF,
  STATUS_LPO_SIGNED,
  STATUS_NEW,
  STATUS_PROCESSING,
  STATUS_SHIPPED,
} from "shared/constants/Constants";
import { adminOrderStatusText } from "shared/services/Utility";
import AccessControl from "services/AccessControl";

const { Content } = Layout;

let searchTimer: any = undefined;
export default function OrderDetails(props: any) {
  const [form] = Form.useForm();
  const [assignForm] = Form.useForm();
  let history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const orderId = props?.match?.params?.id;

  const [alreadyAssigned, setAlreadyAssigned] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [orderData, setOrderData] = useState();
  const quantityValue = useRef<HTMLInputElement>(null);
  const [addNewModal, setAddNewModal] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");
  const [salesManagerList, setSalesManagerList] = useState([]);

  const [salesManList, setSalesManList] = useState([]);
  const [filterAttributes, setFilterAttributes] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: "15",
  } as any);
  const [total, setTotal] = useState(10);
  const [productListPagination, setProductListPagination] = useState({
    page: 1,
    pages: 1,
    perpage: 5,
  });
  const [productListTotal, setProductListTotal] = useState(12);
  const [filters, setFilters] = useState({} as any);

  const handlePriceChange = (price: any, d: any) => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      handleQuantityValue(
        d?.quantity,
        d?.productId,
        d?.orderId,
        d?.id,
        Number(price)
      );
    }, 800);
  };

  const deleteOrderDetails = async (orderDetailId: number) => {
    try {
      const id = orderData?.id;
      await API_SERVICE.deleteOrderDetails({ id, orderDetailId });
      notification.success({
        message: `Product Deleted`,
        placement: "topRight",
      });
      fillForm(id);
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "topRight",
      });
    }
  };

  const handleQuantityValue = async (
    quantity: number,
    productId: number,
    orderId: number,
    id: number,
    price: number
  ) => {
    try {
      // console.log(id);
      const params = {
        productId,
        quantity,
        price,
      };
      await API_SERVICE.updateOrderDetails(id, orderId, params);
      notification.success({
        message: `Successfully Updated`,
        placement: "topRight",
      });
      fillForm(props.match.params.id);
      //  fillForm();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "topRight",
      });
    }
  };

  const handleQuantity = (
    action: string,
    productId: number,
    quantity: number,
    orderId: number,
    id: number,
    price: number
  ) => {
    if (action === "minus") {
      if (quantity > 1) {
        handleQuantityValue(quantity - 1, productId, orderId, id, price);
      }
      if (quantity === 1) {
        deleteOrderDetails(id);
      }
    } else {
      handleQuantityValue(quantity + 1, productId, orderId, id, price);
    }
  };

  const fillForm = async (id: number, pagination = {}, filters = {}) => {
    try {
      const params = {
        ...pagination,
        ...filters,
      };
      const {
        data: { data },
      } = await API_SERVICE.fetchOrderById(id, params);

      setOrderData(data.order);
      setFilteredData(data.products.items);
      setTotal(data.products.total);
      specificSalesManagerList(data.order.user.id);
      specificSalesPersonList(data.order.user.id);

      // assignForm.setFieldsValue({
      //   ["salesManager"]: data.order.salesManager.name,
      //   ["salesMan"]: data.order.salesPerson.name,
      // });

      // if (
      //   data.order.salesManagerId !== null &&
      //   data.order.salesPersonId !== null
      // ) {
      //   setAlreadyAssigned(true);
      // }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const columns = [
    {
      title: "S.N.",
      dataIndex: "serialNumber",
      // sorter: (a: any, b: any) => a.serialNumber - b.serialNumber,
      render: (a: any, v: any, index: number) => (
        <div>{(pagination.page - 1) * pagination.perpage + index + 1}</div>
      ),
    },
    {
      title: "PRODUCT NAME : ",
      key: "productIds",
      filters:
        filterAttributes && filterAttributes.productId
          ? filterAttributes?.productId.map((item: any) => {
              return { text: item.name, value: item.id };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (d: any) => (
        <Row
          className="detail-card"
          style={{ margin: 0 }}
          align="middle"
          justify="space-between"
        >
          <Col>
            <Row align="middle">
              <Col>
                <img
                  className="photo product-photo"
                  src={d?.product?.thumbnail?.url || MilanoThumbnail}
                />
              </Col>
              <Col>
                <div className="detailCard-title">
                  {d.product?.name}
                  <p>SKU {d.product?.skuId}</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    },

    {
      title: " CBM : ",
      dataIndex: "product",
      key: "cbm",
      render: (d: any) => {
        return <>{d?.cbmValue}</>;
      },
    },
    {
      title: "PRICE PER UNIT",
      key: "price",
      render: (d: any) => (
        <div className="price-div grid-container" key={d.id}>
          <div className="dollar-price grid-child">AED </div>
          <div className="price-number grid-child">
            <Input
              type="number"
              disabled={
                orderData.status === STATUS_NEW ||
                orderData.status === STATUS_LPO_SIGN_OFF ||
                orderData.status === STATUS_LPO_SIGNED
                  ? false
                  : true
              }
              defaultValue={d?.price}
              onChange={(e) => handlePriceChange(e.target.value, d)}
            />
          </div>
        </div>
      ),
    },
    {
      title: "TOTAL QUANTITY : ",
      key: "totalQuantity",
      render: (d: any) => {
        return (
          <>
            <div className="quantityOrderDetail">
              <AccessControl id={35}>
                <Button
                  disabled={
                    orderData.status === STATUS_NEW ||
                    orderData.status === STATUS_LPO_SIGN_OFF ||
                    orderData.status === STATUS_LPO_SIGNED
                      ? false
                      : true
                  }
                  className="minusOrderDetail btn-small"
                  onClick={() =>
                    handleQuantity(
                      "minus",
                      d?.productId,
                      Number(d?.quantity),
                      d?.orderId,
                      d?.id,
                      d?.price
                    )
                  }
                >
                  <Remove />
                </Button>
              </AccessControl>
              <input
                disabled={
                  orderData.status === STATUS_NEW ||
                  orderData.status === STATUS_LPO_SIGN_OFF ||
                  orderData.status === STATUS_LPO_SIGNED
                    ? false
                    : true
                }
                className="valueOrderDetail btn-small"
                type="number"
                value={Number(d?.quantity)}
              />
              <AccessControl id={35}>
                <Button
                  className="plusOrderDetail btn-small"
                  disabled={
                    orderData.status === STATUS_NEW ||
                    orderData.status === STATUS_LPO_SIGNED ||
                    orderData.status === STATUS_LPO_SIGN_OFF
                      ? false
                      : true
                  }
                  onClick={() =>
                    handleQuantity(
                      "add",
                      d?.productId,
                      Number(d?.quantity),
                      d?.orderId,
                      d?.id,
                      d?.price
                    )
                  }
                >
                  <Add />
                </Button>
              </AccessControl>
            </div>
          </>
        );
      },
    },
    {
      title: "TOTAL AMOUNT : ",
      key: "totalAmount",
      render: (d: any) => {
        return (
          <>
            AED{" "}
            {consverPriceToInternationalFormat(
              (d?.price * d?.quantity).toFixed(2)
            )}
          </>
        );
      },
    },
    {
      title: "ACTION: ",
      key: "actions",
      render: (d: any) => {
        if (
          orderData.status === STATUS_NEW ||
          orderData.status === STATUS_LPO_SIGN_OFF ||
          orderData.status === STATUS_LPO_SIGNED
        ) {
          return (
            <AccessControl id={35}>
              <Button shape="circle" onClick={() => deleteOrderDetails(d?.id)}>
                <DeleteOutlined />
              </Button>
            </AccessControl>
          );
        } else {
          return (
            <AccessControl id={35}>
              <Button
                shape="circle"
                disabled
                onClick={() => deleteOrderDetails(d?.id)}
              >
                <DeleteOutlined />
              </Button>
            </AccessControl>
          );
        }
      },
    },
  ];

  const getAllAttributes = async () => {
    const {
      data: { data },
    } = await API_SERVICE.fetchManageOrderAttributes(orderId);
    setFilterAttributes(data);
  };

  const _handleTableChanges = async (
    pagination,
    filters,
    sorter,
    extra: { currentDataSource; action }
  ) => {
    if (extra.action === "paginate") {
      setPagination({
        page: pagination.current,
        perpage: pagination.pageSize,
      });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };

  const specificSalesManagerList = async (dealerId: number) => {
    try {
      const params = {
        id: dealerId,
      };
      const {
        data: { data },
      } = await API_SERVICE.specificSalesManagerList(params);
      setSalesManagerList(data);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };
  const specificSalesPersonList = async (dealerId: number) => {
    try {
      const params = {
        id: dealerId,
      };
      const {
        data: { data },
      } = await API_SERVICE.specificSalesPersonList(params);
      setSalesManList(data);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const downloadGeneratedLPO = async (id: number) => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.downloadGeneratedLPO(id);
      window.open(data.url, "_blank");
      fillForm(orderId);
    } catch (e) {
      notification.error({
        message: API_SERVICE.handleErrors(e),
        placement: "bottomRight",
      });
    }
  };

  // const deliveryStatusButtons = (status: string, item: any) => {
  //   switch (status) {
  //     case STATUS_NEW:
  //       return (
  //         orderData?.salesManagerId &&
  //         orderData?.salesPersonId && (
  //           <Button
  //             type="primary"
  //             className="order-generate-btn"
  //             onClick={() => showModal()}
  //           >
  //             <FileTextOutlined />
  //             Generate LPO
  //           </Button>
  //         )
  //       );
  //     case STATUS_LPO_SIGN_OFF:
  //       return (
  //         <Button
  //           type="primary"
  //           className="order-generate-btn"
  //           onClick={() => {
  //             downloadGeneratedLPO(item?.id);
  //           }}
  //         >
  //           <DownloadOutlined />
  //           Download Generated LPO
  //         </Button>
  //       );

  //     case STATUS_LPO_SIGNED:
  //     case STATUS_PROCESSING:
  //     case STATUS_CONFIRMED:
  //     case STATUS_SHIPPED:
  //       return (
  //         <Button
  //           type="primary"
  //           className="order-generate-btn"
  //           onClick={() => {
  //             window.open(item.lpo?.url);
  //           }}
  //         >
  //           <DownloadOutlined />
  //           Download Signed LPO
  //         </Button>
  //       );

  //     case STATUS_DELIVERED:
  //       return (
  //         <>
  //           <Button
  //             type="primary"
  //             className="order-generate-btn"
  //             onClick={() => {
  //               window.open(item.lpo?.url);
  //             }}
  //           >
  //             <DownloadOutlined />
  //             Download Signed LPO
  //           </Button>
  //           <Button
  //             type="primary"
  //             className="order-generate-btn"
  //             // onClick={() => {
  //             //   downloadGeneratedLPO(item?.id);
  //             // }}
  //           >
  //             <DownloadOutlined />
  //             Download Tax Invoice
  //           </Button>
  //         </>
  //       );
  //     default:
  //       return;
  //   }
  // };

  // const handleAssigning = async (values: any) => {
  //   try {
  //     const params = {
  //       salesManagerId: values.salesManager,
  //       salesPersonId: values.salesMan,
  //     };
  //     await API_SERVICE.orderAssigning(orderId, params).then(() =>
  //       fillForm(orderId)
  //     );
  //     notification.success({
  //       message: `Order Assigned`,
  //       placement: "bottomRight",
  //     });
  //   } catch (err) {
  //     notification.error({
  //       message: API_SERVICE.handleErrors(err),
  //       placement: "bottomRight",
  //     });
  //   }
  // };

  const _handleSearch = (values: any) => {
    setSearchProduct(values?.productSearch);
    getAllProducts(1, productListPagination.perpage, values?.productSearch);
  };

  const getAllProducts = async (
    page?: number,
    perpage?: number,
    search?: string
  ) => {
    const params = {
      page: page,
      perpage: perpage,
    };
    if (search) {
      //@ts-ignore
      params["q"] = search;
    }

    try {
      const {
        data: { data },
      } = await API_SERVICE.getAllProducts(params);
      if (data.items !== 0) {
        setProductsList(data.items);
        setProductListPagination({
          page: data.page,
          perpage: data.perpage,
          pages: data.pages,
        });
        setProductListTotal(data.total);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "topRight",
      });
    }
  };

  useEffect(() => {
    fillForm(orderId, pagination, filters);
  }, [filters, pagination]);

  useEffect(() => {
    getAllAttributes();
    getAllProducts(1, productListPagination.perpage, searchProduct);
  }, []);

  return (
    <>
      {/* <AccessControl id={35}>
        <Button
          type="primary"
          className="theme-btn  floatingBtn"
          onClick={() => setAddNewModal(true)}
        >
          <Add />
          Add New
        </Button>
      </AccessControl> */}
      <Modal
        title="Search Products"
        open={addNewModal}
        footer={null}
        onCancel={() => {
          setAddNewModal(false);
          fillForm(orderId);
        }}
      >
        <Col xs={24}>
          <Form onFinish={_handleSearch}>
            <Form.Item name="productSearch">
              <Input
                className="new-order-search"
                placeholder="Search Products"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24}>
          {productsList.length !== 0 ? (
            productsList.map((product) => {
              return (
                <SearchCard
                  product={product}
                  add={true}
                  key={product.id}
                  orderId={orderId}
                />
              );
            })
          ) : (
            <>
              <div className="search-no">No result found</div>
            </>
          )}
        </Col>
        <Col xs={24}>
          <Pagination
            responsive
            className="pagination"
            current={productListPagination.page}
            defaultCurrent={productListPagination.page || 1}
            total={productListTotal}
            pageSize={productListPagination.perpage}
            onChange={(page, size) => {
              //@ts-ignore
              setProductListPagination({ page: page, perpage: size });
              getAllProducts(page, size);
            }}
          />
        </Col>
      </Modal>
      <Modal
        title="LPO Generation"
        open={isModalOpen}
        onCancel={() => {
          handleCancel();
        }}
        footer={null}
        className="generate-lpo-modal"
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(value) => {
            API_SERVICE.adminLpoGeneration(orderId, value)
              .then((d) => {
                notification.success({ message: "Success" });
                handleCancel();
              })
              .catch((e) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
              });
            fillForm(orderId, pagination, filters);
          }}
        >
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 24,
              xl: 24,
            }}
          >
            <Col xs={24} sm={24} className="lpoModalHeader">
              <div className="lpoModalHeaderDiv">
                <p>DATE</p>
                <p>{moment(orderData?.createdAt).format("DD/MM/YYYY")}</p>
              </div>
              <div className="lpoModalHeaderDiv">
                <p>TOTAL AMOUNT</p>
                <p>
                  AED{" "}
                  {consverPriceToInternationalFormat(orderData?.totalAmount)}
                </p>
              </div>
              <div className="lpoModalHeaderDiv">
                <p>TOTAL CBM</p>
                <p>{orderData?.totalCbm}</p>
              </div>
              <div className="lpoModalHeaderDiv">
                <p>ORDER NUMBER</p>
                <p>#{orderData?.orderNumber || "-"}</p>
              </div>
              <div className="lpoModalHeaderDiv">
                <p>DEALER NAME & CODE</p>
                <p>
                  {orderData?.user?.name} ({orderData?.user?.dealerCode})
                </p>
              </div>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Shipping Charge"
                name="shippingCharge"
                className="modalLabels"
                rules={[
                  {
                    required: true,
                    message: "Shipping Charge Required",
                  },
                ]}
              >
                <Input
                  className="modalInputs"
                  prefix={<img src={dollarIcon} />}
                  placeholder="Shipping Charge"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                className="modalLabels"
                label="VAT"
                name="vat"
                rules={[
                  {
                    required: true,
                    message: "VAT Required",
                  },
                ]}
              >
                <Input
                  type="number"
                  className="modalInputs"
                  prefix={<img src={dollarIcon} />}
                  placeholder="VAT"
                />
              </Form.Item>
            </Col>

            <Col xs={0} md={8}></Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Other Charges"
                name="otherCharge"
                className="modalLabels"
                rules={[
                  {
                    required: true,
                    message: "Other Charges Required ",
                  },
                ]}
              >
                <Input
                  className="modalInputs"
                  prefix={<img src={dollarIcon} />}
                  placeholder="Other Charges"
                />
              </Form.Item>
            </Col>
            <Col xs={0} md={16}></Col>
            <Col xs={24} md={14}>
              <Form.Item
                className="modalLabels"
                label="Upload Supporting Document"
                name="supportingDocId"
              >
                <FileUpload
                  type={"DOCUMENT"}
                  // callback={stampUrlUpdate}
                  style={{ display: "block" }}
                  // onChange={(id: any) => setStampId(id)}
                >
                  <>
                    <div className="fileUploadDesign">
                      <div>
                        <img src={uploadIcon} alt="icon" />
                        Upload Scanned Copy
                      </div>
                      <p className="text">Upload</p>
                    </div>
                    <p
                      style={{
                        color: "#A7A7A7",
                        fontSize: "14px",
                      }}
                    >
                      Upload file format should be in JPG or PNG and the maximum
                      size of file must be less then 3 MB.
                    </p>
                  </>
                </FileUpload>
              </Form.Item>
            </Col>

            <Col
              xs={24}
              style={{
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              <Form.Item>
                <Button htmlType="submit" className="theme-btn addAddressBtn">
                  Generate LPO
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <div className="order-details-main-layout">
        <Layout className="site-layout">
          <Content className="order-details-main-layout">
            <div className="order-details-title">MANAGE ORDERS</div>
            <div className="order-details-icon">
              <ArrowLeftOutlined onClick={() => history.goBack()} />
            </div>
            <Row
              className="order-details-title-row flex align-items-center  "
              gutter={{ xs: 8, sm: 16, md: 24 }}
              align="bottom"
            >
              <Col
                className="gutter-row  order-detail-col"
                xs={24}
                sm={24}
                md={16}
                lg={11}
                xl={11}
                xxl={11}
              >
                <div className="order-details-inner-title ">
                  Order Details | #{orderData?.orderNumber || "-"}
                </div>
              </Col>
              <Col
                className="gutter-row order-detail-col myOrdersBatch "
                xs={24}
                sm={24}
                md={6}
                lg={6}
                xl={6}
              >
                <span>{orderData?.status}</span>
                {/* <Badge
                  size="default"
                  className="orderBadge"
                  status={"default"}
                  color={orderData?.statusColor?.hex}
                  text={textTransform(adminOrderStatusText(orderData?.status))}
                  text={textTransform(
                    orderData?.statusColor?.name
                      ? orderData?.statusColor?.name === STATUS_LPO_SIGN_OFF
                        ? "Lpo Sign Off Pending"
                        : orderData?.statusColor?.name
                      : ""
                  )}
                /> */}
              </Col>
              {/* <AccessControl id={36}>
                <Col
                  className="gutter-row flex justify-content-end order-detail-col "
                  xs={24}
                  sm={24}
                  md={24}
                  lg={9}
                  xl={9}
                >
                  {deliveryStatusButtons(orderData?.status, orderData)}
                </Col>
              </AccessControl> */}
            </Row>

            <Row
              className="order-details-content-row mt-2  "
              gutter={[
                { xs: 10, sm: 10, md: 10 },
                { xs: 10, sm: 10, md: 10 },
              ]}
            >
              <Col
                className="gutter-row "
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
              >
                <Row
                  className="order-details-div m-0"
                  gutter={{ xs: 8, sm: 16, md: 24 }}
                >
                  <Col className="gutter-row" xs={24} sm={24} md={10} lg={9}>
                    <div className="order-detail-div-title">
                      <CalendarOutlined /> ORDER DATE
                    </div>
                    <div className="order-detail-div-content">
                      {moment(orderData?.createdAt).format("DD/MM/YYYY")}
                    </div>
                  </Col>

                  <Col className="gutter-row" xs={24} sm={24} md={8} lg={7}>
                    <div className="order-detail-div-title">
                      <DollarCircleOutlined /> PAYABLE AMOUNT
                    </div>
                    <div className="order-detail-div-content">
                      AED{" "}
                      {consverPriceToInternationalFormat(
                        orderData?.totalAmount
                      )}
                    </div>
                  </Col>
                  <Col className="gutter-row" xs={24} sm={24} md={6} lg={8}>
                    <div className="order-detail-div-title">
                      <CodeSandboxOutlined /> TOTAL CBM
                    </div>
                    <div className="order-detail-div-content">
                      {orderData?.totalCbm}
                    </div>
                  </Col>
                  <Col className="gutter-row" xs={24} sm={24} md={10} lg={9}>
                    <div className="order-detail-div-title">
                      <UserOutlined /> DEALER NAME & CODE
                    </div>
                    <div className="order-detail-div-content">
                      {orderData?.user?.name}
                      <br />
                      {orderData?.user?.dealerCode}
                    </div>
                  </Col>
                  <Col className="gutter-row" xs={24} sm={24} md={8} lg={7}>
                    <div className="order-detail-div-title">
                      <UserOutlined /> ORDER BY
                    </div>
                    <div className="order-detail-div-content">
                      {orderData?.user?.name}
                      <br />
                      {orderData?.placedById?.email}
                    </div>
                  </Col>
                  <Col className="gutter-row" xs={24} sm={24} md={8} lg={8}>
                    <div className="order-detail-div-title">
                      <UserOutlined /> LICENSE NUMBER
                    </div>
                    <div className="order-detail-div-content">
                      {orderData?.user?.dealerDetails[0]?.licenceNumber}
                    </div>
                  </Col>
                  {orderData?.orderTotalAmount ? (
                    <>
                      <Col className="gutter-row" xs={24} sm={24} md={9}>
                        <div className="order-detail-div-title">
                          <DollarCircleOutlined />
                          TOTAL AMOUNT
                        </div>
                        <div className="order-detail-div-content">
                          AED{" "}
                          {consverPriceToInternationalFormat(
                            orderData?.orderTotalAmount
                          )}
                        </div>
                      </Col>
                      <Col className="gutter-row" xs={24} sm={24} md={12}>
                        <div className="order-detail-div-title">
                          <DollarCircleOutlined />
                          DISCOUNT AMOUNT
                        </div>
                        <div className="order-detail-div-content">
                          AED{" "}
                          {consverPriceToInternationalFormat(
                            orderData.discountAmount
                          )}
                        </div>
                      </Col>
                    </>
                  ) : null}
                </Row>
              </Col>
              <Col
                className="gutter-row  "
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
              >
                <Row
                  className="order-details-div m-0"
                  gutter={{ xs: 8, sm: 16, md: 24 }}
                >
                  <Col className="gutter-row " xs={24} sm={24} md={12} lg={12}>
                    <div className="order-detail-div-title">
                      <LocationOnOutlined />
                      SHIPPING ADDRESS
                    </div>
                    <div className="order-detail-name">
                      {orderData?.shippingAddress?.name}
                    </div>
                    <div className="order-detail-div-address">
                      {orderData?.shippingAddress?.address},
                      {orderData?.shippingAddress?.building},
                      {orderData?.shippingAddress?.street},
                      {orderData?.shippingAddress?.area},
                      {orderData?.shippingAddress?.city},
                      {orderData?.shippingAddress?.country}
                    </div>
                    <div className="order-detail-phone">
                      Phone Number: {orderData?.shippingAddress?.phone}
                    </div>
                  </Col>
                  <Col className="gutter-row " xs={24} sm={24} md={12} lg={12}>
                    <div className="order-detail-div-title">
                      <LocationOnOutlined /> BILLING ADDRESS
                    </div>
                    <div className="order-detail-name">
                      {orderData?.billingAddress?.name}
                    </div>
                    <div className="order-detail-div-address">
                      {orderData?.billingAddress?.address},
                      {orderData?.billingAddress?.building},
                      {orderData?.billingAddress?.street},
                      {orderData?.billingAddress?.area},
                      {orderData?.billingAddress?.city},
                      {orderData?.billingAddress?.country}
                    </div>
                    <div className="order-detail-phone">
                      Phone Number: {orderData?.billingAddress?.phone}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <AccessControl id={36}>
              <Form
                form={assignForm}
                onFinish={handleAssigning}
                disabled={alreadyAssigned}
              >
                <Row className="pb-3">
                  <Col xs={24} sm={24} md={8} lg={8} xl={4} className="pr-2">
                    <Form.Item name="salesManager">
                      <Select
                        allowClear
                        className="mr-2 adminOrderDetailsSelect"
                        placeholder="Select Sales Manager"
                      >
                        {salesManagerList.map((item: any) => {
                          return (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={6} xl={4} className="pr-2">
                    <Form.Item name="salesMan">
                      <Select
                        allowClear
                        className="mr-2 adminOrderDetailsSelect"
                        placeholder="Select Sales Man"
                      >
                        {salesManList.map((item: any) => {
                          return (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={4} className="pr-2">
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="order-generate-btn"
                      >
                        Assign
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </AccessControl> */}
            <Row>
              <Col xs={24} className="mb-3">
                <Table
                  scroll={{ x: true }}
                  loading={loading}
                  columns={columns}
                  dataSource={filteredData}
                  size="middle"
                  pagination={{
                    size: "default",
                    current: pagination.page,
                    pageSize: pagination.perpage,
                    total: total,
                    /*onChange:(page, perpage) => {
                            setPagination({ page, perpage });
                            getProductList({page, perpage}, sort, filters, search);
                        }*/
                  }}
                  onChange={_handleTableChanges}
                />
              </Col>
            </Row>
          </Content>
        </Layout>
      </div>
    </>
  );
}
