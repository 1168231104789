import { ArrowLeftOutlined } from "@ant-design/icons";
import TocIcon from "@material-ui/icons/Toc";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Select,
  notification,
} from "antd";
import { Option } from "antd/lib/mentions";
import { FileUpload } from "components";
import { useEffect, useState } from "react";
import AccessControl from "services/AccessControl";
import API_SERVICE from "services/api-service";
import MilanoThumnail from "../../../assets/MilanoThumbnail.png";
import globe from "../../../assets/icons/globe.png";
import "./index.scss";

function ManageCategory() {
  const { Panel } = Collapse;

  const [catForm] = Form.useForm();
  const [subCatForm] = Form.useForm();
  const [subSubCatForm] = Form.useForm();
  const [selectedCat, setSelectedCat] = useState({
    id: null,
    name: "",
    fileName: null,
    fileUrl: null,
  });
  const [catMenu, setCatMenu] = useState([]);
  const [subCatMenu, setSubCatMenu] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);

  const [categoryImageId, setCategoryImageId] = useState(null);
  const [showSubCatForm, setShowSubCatForm] = useState(false);
  const [showSubCatForm1, setShowSubCatForm1] = useState(false);

  const categoryUrlUpdate = ({ url }: any) => {
    setSelectedCat({
      ...selectedCat,
      fileUrl: url,
    });
  };

  //API CALL FUNCTION
  async function apiCallUpdateCategory(id: number, params: any) {
    try {
      await API_SERVICE.updateCategory(id, params);
      if (params?.isActive || params.isActive === false) {
        notification.success({
          message: params.isActive ? "Activated" : "Deactivated",
          placement: "bottomRight",
        });
      } else {
        notification.success({
          message: "Category Updated Successfully",
          placement: "bottomRight",
        });
      }
      fetchCategoriesData();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  }

  const handleUpdateCategory = async (values: any) => {
    const id = values.id;
    const params = {
      name: values.categoryName,
      parentId: values.parentId,
      order: values.displayOrder,
      fileId: categoryImageId,
    };
    apiCallUpdateCategory(id, params);
  };

  const handleUpdateSubCategory = async (values: any) => {
    const id = values.id;
    const params = {
      name: values.subCategoryName,
      parentId: values.parentId,
      order: values.displayOrder,
    };
    apiCallUpdateCategory(id, params);
  };

  const handleUpdateSubSubCategory = async (values: any) => {
    const id = values.id;
    const params = {
      name: values.subSubCategoryName,
      parentId: values.subCategoryName,
      order: values.displayOrder,
    };
    apiCallUpdateCategory(id, params);
  };

  const onMainCatChange = (key: any) => {
    setShowSubCatForm(false);
    setShowSubCatForm1(false);
    if (key) {
      const myValues = JSON.parse(key);
      console.log(myValues);
      catForm.setFieldsValue({
        ["id"]: myValues.id,
        ["categoryName"]: myValues?.name,
        ["displayOrder"]: myValues?.order,
        ["uploadImage"]: myValues?.file?.id,
        ["parentId"]: myValues?.parentId,
        ["fileId"]: myValues?.fileId,
      });
      setCategoryImageId(myValues?.fileId);
      setSelectedCat({
        id: myValues.id,
        name: myValues.name,
        fileName: myValues?.file?.name,
        fileUrl: myValues?.file?.url,
      });
      setCatMenu(categoriesData);
      subCatForm.resetFields();
      subSubCatForm.resetFields();
    } else {
      setSelectedCat({
        id: null,
        name: "",
        fileUrl: null,
        fileName: null,
      });
      setCatMenu([]);
      catForm.resetFields();
      subCatForm.resetFields();
      subSubCatForm.resetFields();
    }
  };
  const onSubCatChange = (key: any) => {
    setShowSubCatForm(true);
    setShowSubCatForm1(false);
    if (key) {
      const myValues = JSON.parse(key);
      const filteredData: any = categoriesData.filter(
        (c: any) => c?.id === selectedCat.id
      );
      setSubCatMenu(filteredData[0]?.children);
      setSelectedCat({
        ...selectedCat,
        //@ts-ignore
        selectedSubCatName: myValues?.name,
      });
      subCatForm.setFieldsValue({
        ["id"]: myValues.id,
        ["categoryName"]: selectedCat.name,
        ["subCategoryName"]: myValues.name,
        ["displayOrder"]: myValues.order,
        ["parentId"]: selectedCat.id,
      });
    } else {
      setSubCatMenu([]);
      subCatForm.resetFields();
      subSubCatForm.resetFields();
    }
  };
  const onSubSubCatChange = (values: any) => {
    setShowSubCatForm1(true);
    if (values) {
      subSubCatForm.setFieldsValue({
        ["id"]: values.id,
        ["categoryName"]: selectedCat.name,
        ["subCategoryName"]: values.parentId,
        ["subSubCategoryName"]: values.name,
        ["displayOrder"]: values.order,
      });
    } else {
      subSubCatForm.resetFields();
    }
  };

  const fetchCategoriesData = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchCategories({ page: -1 });
      if (data.items !== 0) {
        setCategoriesData(data.items);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    fetchCategoriesData();
  }, []);

  const genExtra = (cat: any) => (
    <AccessControl id={30}>
      <Checkbox
        defaultChecked={cat?.isActive}
        onChange={(e) => {
          if (e.target.checked) {
            const params = {
              isActive: true,
            };
            console.log(params, cat.id);
            apiCallUpdateCategory(cat.id, params);
          } else {
            const params = {
              isActive: false,
            };
            apiCallUpdateCategory(cat.id, params);
          }
        }}
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
        }}
      />
    </AccessControl>
  );

  const _myCountFunction = (cat: any) => {
    function calculateCount(arr: any) {
      let count = 0;
      arr.forEach((item: any) => {
        count = count + item.productsCount;
        if (item.children.length) {
          calculateCount(item.children);
        }
      });
      return count;
    }
    if (cat.children.length) {
      let res = calculateCount(cat.children);
      res = res + cat.productsCount;
      return res;
    } else {
      return cat.productsCount;
    }
  };

  return (
    <AccessControl id={27}>
      <>
        <Row>
          <Col xs={24} className="mt-2 mb-2">
            <ArrowLeftOutlined className="adminLeftArrowIcon" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={16} className="addRoleLeft">
            <Row>
              <Col xs={24} sm={23}>
                <Row>
                  <Col xs={24}>
                    <Form
                      form={catForm}
                      onFinish={handleUpdateCategory}
                      layout="vertical"
                    >
                      <Row
                        gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 24,
                          xl: 24,
                        }}
                      >
                        <Col xs={24}>
                          <h1 className="categoryManagementSubtitle">
                            Update Category
                          </h1>
                        </Col>
                        <Col xs={24} md={12} hidden={true}>
                          <Form.Item
                            className="modalLabels"
                            name="id"
                            hidden={true}
                          ></Form.Item>
                        </Col>
                        <Col xs={24} md={12} hidden={true}>
                          <Form.Item
                            className="modalLabels"
                            name="parentId"
                            hidden={true}
                          ></Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                          <Form.Item
                            className="modalLabels m-0 mb-2"
                            label="Category Name"
                            name="categoryName"
                            rules={[
                              {
                                required: true,
                                message: "Enter Category Name",
                              },
                            ]}
                          >
                            <Input
                              className="banner-form-input"
                              prefix={<TocIcon className="categoryIcon" />}
                              placeholder="Category Name"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                          <Form.Item
                            className="modalLabels m-0 mb-2"
                            label="Display Order"
                            name="displayOrder"
                            rules={[
                              {
                                required: true,
                                message: "Enter Display Order",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              className="banner-form-input"
                              prefix={<TocIcon className="categoryIcon" />}
                              placeholder="Display Order"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                          <Form.Item
                            className="modalLabels m-0 mb-2 fileUploadDesign1 text-truncate"
                            label="Upload Image"
                            name="uploadImage"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: 'Please Select Image',
                            //   },
                            // ]}
                          >
                            <FileUpload
                              type={"CATEGORY"}
                              callback={categoryUrlUpdate}
                              style={{ display: "block" }}
                              onChange={(id: any) => setCategoryImageId(id)}
                            >
                              {selectedCat?.fileUrl !== null ? (
                                <div className="uploadedImage">
                                  <img
                                    src={selectedCat?.fileUrl || MilanoThumnail}
                                    alt={selectedCat?.name}
                                  />
                                  <Button>Edit</Button>
                                </div>
                              ) : (
                                <>
                                  <div className="fileUploadDesign">
                                    <div>
                                      <img src={globe} alt="icon" />
                                      Upload Image
                                    </div>
                                    <p className="text">Upload</p>
                                  </div>
                                  <p
                                    style={{
                                      color: "#A7A7A7",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Upload file format should be in JPG or PNG
                                    and the maximum size of file must be less
                                    then 3 MB.
                                  </p>
                                </>
                              )}
                            </FileUpload>
                          </Form.Item>
                        </Col>
                        <AccessControl id={30}>
                          <Col xs={24} sm={24} md={10} className="mt-3">
                            <Button
                              htmlType="submit"
                              className="theme-btn addUserbtn addAddressBtn addCategoryBtn"
                            >
                              Update Category
                            </Button>
                          </Col>
                        </AccessControl>
                      </Row>
                    </Form>
                  </Col>

                  {showSubCatForm ? (
                    <>
                      <Col xs={24} className="mt-3">
                        <Form
                          form={subCatForm}
                          layout="vertical"
                          onFinish={handleUpdateSubCategory}
                        >
                          <Row
                            gutter={{
                              xs: 8,
                              sm: 16,
                              md: 24,
                              lg: 24,
                              xl: 24,
                            }}
                          >
                            <Col xs={24}>
                              <h1 className="categoryManagementSubtitle">
                                Update Sub-Category
                              </h1>
                            </Col>
                            <Col xs={24} md={12} hidden={true}>
                              <Form.Item
                                className="modalLabels"
                                name="id"
                                hidden={true}
                              ></Form.Item>
                            </Col>

                            <Col xs={24} md={8}>
                              <Form.Item
                                className="modalLabels m-0 mb-2"
                                label="Select Category"
                                name="parentId"
                                rules={[
                                  {
                                    required: true,
                                    message: "Selct Category",
                                  },
                                ]}
                              >
                                <Select
                                  className="banner-form-select"
                                  placeholder="Select Category"
                                >
                                  {catMenu.length !== 0
                                    ? catMenu.map((cat: any) => {
                                        return (
                                          <Option key={cat?.id} value={cat?.id}>
                                            {cat?.name}
                                          </Option>
                                        );
                                      })
                                    : ""}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.Item
                                className="modalLabels m-0 mb-2"
                                label="Sub-category Name"
                                name="subCategoryName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter Sub-category Name",
                                  },
                                ]}
                              >
                                <Input
                                  className="banner-form-input"
                                  prefix={<TocIcon className="categoryIcon" />}
                                  placeholder="Sub-category Name"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.Item
                                className="modalLabels m-0 mb-2"
                                label="Display Order"
                                name="displayOrder"
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter Display Order",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  className="banner-form-input"
                                  prefix={<TocIcon className="categoryIcon" />}
                                  placeholder="Display Order"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={10} className="mt-3">
                              <Button
                                htmlType="submit"
                                className="theme-btn addUserbtn addAddressBtn addCategoryBtn"
                              >
                                Update Sub Category
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  {showSubCatForm1 ? (
                    <>
                      <Col xs={24} className="mt-3">
                        <Form
                          form={subSubCatForm}
                          layout="vertical"
                          onFinish={handleUpdateSubSubCategory}
                        >
                          <Row
                            gutter={{
                              xs: 8,
                              sm: 16,
                              md: 24,
                              lg: 24,
                              xl: 24,
                            }}
                          >
                            <Col xs={24}>
                              <h1 className="categoryManagementSubtitle">
                                Update Sub-subcategory
                              </h1>
                            </Col>
                            <Col xs={24} md={12} hidden={true}>
                              <Form.Item
                                className="modalLabels"
                                name="id"
                                hidden={true}
                              ></Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.Item
                                className="modalLabels m-0 mb-2"
                                label="Select Category"
                                name="categoryName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Select Category",
                                  },
                                ]}
                              >
                                <Select
                                  disabled
                                  className="banner-form-select"
                                  placeholder="Select Category"
                                >
                                  {catMenu.length !== 0
                                    ? catMenu.map((cat: any) => {
                                        return (
                                          <Option key={cat.id} value={cat.id}>
                                            {cat.name}
                                          </Option>
                                        );
                                      })
                                    : ""}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.Item
                                className="modalLabels m-0 mb-2"
                                label="Select Sub-category"
                                name="subCategoryName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Select Sub-Category",
                                  },
                                ]}
                              >
                                <Select
                                  className="banner-form-input"
                                  placeholder="Select Sub-category"
                                >
                                  {subCatMenu.length !== 0
                                    ? subCatMenu.map((cat: any) => {
                                        return (
                                          <Option key={cat.id} value={cat?.id}>
                                            {cat?.name}
                                          </Option>
                                        );
                                      })
                                    : ""}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.Item
                                className="modalLabels m-0 mb-2"
                                label="Sub-subcategory Name"
                                name="subSubCategoryName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter Sub-subcategory Name",
                                  },
                                ]}
                              >
                                <Input
                                  className="banner-form-input"
                                  prefix={<TocIcon className="categoryIcon" />}
                                  placeholder="Sub-category Name"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                              <Form.Item
                                className="modalLabels m-0 mb-2"
                                label="Display Order"
                                name="displayOrder"
                                rules={[
                                  {
                                    required: true,
                                    message: "Enter Display Order",
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  className="banner-form-input"
                                  prefix={<TocIcon className="categoryIcon" />}
                                  placeholder="Display Order"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={0} sm={0} md={16}></Col>

                            <Col xs={24} sm={24} md={10} className="mt-3">
                              <Button
                                htmlType="submit"
                                className="theme-btn addUserbtn addAddressBtn addCategoryBtn"
                              >
                                Update Sub-subcategory
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xs={24} sm={24} md={24} lg={8} className="addRoleRight">
            <div className="addRoleRightHeading mb-2">Categories</div>

            <Collapse
              accordion
              onChange={onMainCatChange}
              className="site-collapse-custom-collapse outerCollapseCategory"
              bordered={false}
            >
              {categoriesData.length !== 0 ? (
                categoriesData.map((cat: any, i: number) => {
                  return (
                    <Panel
                      header={
                        <>
                          {cat?.name}
                          <b>
                            {" "}
                            ( {cat?.totalActiveProduct}/{cat?.totalProduct} )
                          </b>
                        </>
                      }
                      key={JSON.stringify(cat)}
                      extra={genExtra(cat)}
                    >
                      <Collapse
                        accordion
                        onChange={onSubCatChange}
                        className="innerCollapseCategory"
                        bordered={false}
                      >
                        {cat.children.length !== 0 ? (
                          <>
                            {cat.children.map((subCat: any) => {
                              return (
                                <>
                                  <Panel
                                    header={
                                      <>
                                        {subCat?.name}{" "}
                                        <b>
                                          ( {subCat?.activeProductCount}/
                                          {subCat?.productsCount} )
                                        </b>
                                      </>
                                    }
                                    key={JSON.stringify(subCat)}
                                    extra={genExtra(subCat)}
                                  >
                                    <div style={{ cursor: "pointer" }}>
                                      {subCat.children.length !== 0 ? (
                                        <>
                                          {subCat.children.map(
                                            (subSubCat: any) => {
                                              return (
                                                <div
                                                  key={subSubCat.id}
                                                  onClick={() =>
                                                    onSubSubCatChange(subSubCat)
                                                  }
                                                  className="flex justify-content-between"
                                                >
                                                  <p className="m-0">
                                                    <span className="rightArrowCategory">
                                                      &gt;
                                                    </span>
                                                    {subSubCat.name} (
                                                    {subSubCat?.productsCount})
                                                  </p>
                                                  <AccessControl id={30}>
                                                    <Checkbox
                                                      defaultChecked={
                                                        subSubCat?.isActive
                                                      }
                                                      onChange={(e) => {
                                                        if (e.target.checked) {
                                                          const params = {
                                                            isActive: true,
                                                          };
                                                          console.log(
                                                            params,
                                                            subSubCat.id
                                                          );
                                                          apiCallUpdateCategory(
                                                            subSubCat.id,
                                                            params
                                                          );
                                                        } else {
                                                          const params = {
                                                            isActive: false,
                                                          };
                                                          apiCallUpdateCategory(
                                                            subSubCat.id,
                                                            params
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </AccessControl>
                                                </div>
                                              );
                                            }
                                          )}
                                        </>
                                      ) : (
                                        <p key="disabled">No Sub Categories </p>
                                      )}
                                    </div>
                                  </Panel>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <Panel
                            key={1}
                            header="No Sub Categories"
                            collapsible="disabled"
                          ></Panel>
                        )}
                      </Collapse>
                    </Panel>
                  );
                })
              ) : (
                <Panel header="This is panel header 2" key="1">
                  <p>No Categories Available</p>
                </Panel>
              )}
            </Collapse>
          </Col>
        </Row>
      </>
    </AccessControl>
  );
}

export default ManageCategory;
