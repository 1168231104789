import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./OfferList.scss";
import { PlusOutlined } from "@ant-design/icons";
import { Switch, Modal, Form, Input, DatePicker } from "antd";

import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { Layout, Row, Col, Button, Table } from "antd";
import { notification } from "antd";
import { useEffect } from "react";
import moment from "moment";

import API_SERVICE from "services/api-service";
import {
  BUYXGETYLIST,
  COUPON,
  NEW_OFFER,
} from "shared/constants/RouteConstants";
import { CouponTypeText } from "shared/services/Utility";
import { OFFER_TYPE } from "shared/constants/Constants";
import AccessControl from "services/AccessControl";

const OfferList = () => {
  let history = useHistory();
  const [filteredData, setFilteredData] = useState([]);
  const [filterAttributes, setFilterAttributes] = useState({});

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: 15,
  } as any);
  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);
  const [form] = Form.useForm();
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [editId, setEditId] = useState();
  const getOfferList = async (pagination = {}) => {
    try {
      setLoading(true);
      const params = {
        ...pagination,
      };
      const {
        data: { data },
      } = await API_SERVICE.getOfferList(params);
      setLoading(false);
      if (data) {
        setFilteredData(data.items);
        setTotal(data?.total);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      setLoading(false);
    }
  };

  const toogleActiveInactive = async (id: number, isActive: boolean) => {
    try {
      setLoading(true);
      const params = {
        status: isActive ? true : false,
      };
      await API_SERVICE.offerActiveInactiveToggleCall(id, params);
      notification.success({
        message: isActive ? "Activated" : "Deactivated",
        placement: "bottomRight",
      });
      getOfferList();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      setLoading(false);
    }
  };

  const _handleTableChanges = async (
    pagination,
    filters,
    sorter,
    extra: { currentDataSource; action }
  ) => {
    if (extra.action === "paginate") {
      setPagination({
        page: pagination.current,
        perpage: pagination.pageSize,
      });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };

  const columns = [
    {
      title: "OFFER TITLE",
      key: "title",
      dataIndex: "title",
    },
    {
      title: " OFFER DESCRIPTION",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "TYPE",
      key: "type",
      dataIndex: "type",
      render: (type: string) => {
        return CouponTypeText(type);
      },
    },
    {
      title: "STATUS",
      key: "status",
      render: (d: any) => (
        <div>
          <Switch
            onChange={(e: any) => {
              toogleActiveInactive(d.id, e);
            }}
            checked={d.status}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
          />
        </div>
      ),
    },
    {
      title: "START TIMESTAMP",
      key: "sentTime",
      render: (d: any) => {
        return moment(d?.startDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "END TIMESTAMP",
      render: (d: any) => {
        return moment(d?.endDate).format("DD/MM/YYYY");
      },
    },
    {
      title: "ACTION",
      render: (a: any) => (
        <>
          <Button
            shape="circle"
            onClick={() => {
              // fillForm(a?.productId);
              setEditId(a?.id);
              // setProductId(a?.productId);
              setIsModalEditOpen(true);
            }}
            className="mr-2"
          >
            <EditOutlined />
          </Button>
          <Button
            shape="circle"
            onClick={() => {
              if (a.type === OFFER_TYPE.COUPON) history.push(`COUPON/${a.id}`);
              else history.push(`${BUYXGETYLIST}/${a.id}`);
            }}
          >
            <EyeOutlined />
          </Button>
        </>
      ),
    },
  ];
  const editOffer = async (values: any) => {
    console.log();
    const reqObj = {
      endDate: moment(values.valueX).format("YYYY-MM-DD HH:mm:ss"),
    };
    try {
      await API_SERVICE.editOffer(reqObj, editId);
      notification.success({
        message: "Offer Edited successfully",
        placement: "bottomRight",
      });
      setIsModalEditOpen(false);
      form.resetFields();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    } finally {
      setIsModalEditOpen(false);
      getOfferList();
    }
  };

  useEffect(() => {
    getOfferList(pagination);
  }, [pagination, sort, filters]);

  return (
    <AccessControl id={38}>
      <>
        <Modal
          title={<p className="stockLabelModalTitle">Edit Offer</p>}
          footer={null}
          className=" admin-buyxgety-modal "
          open={isModalEditOpen}
          onCancel={() => {
            setIsModalEditOpen(false);
            form.resetFields();
          }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => editOffer(values)}
          >
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <Col xs={24}>
                <div className="stockLabelActionContainer buyXGetYContainer">
                  <Row className="header">
                    <Col xs={24}>End Date</Col>
                  </Row>

                  <div className="labelRowCss">
                    <Row className="row">
                      <Col xs={24} className="p-1">
                        <Form.Item
                          name={"valueX"}
                          className="m-0"
                          rules={[
                            {
                              required: true,
                              message: "Enter End Date",
                            },
                          ]}
                        >
                          <DatePicker
                            showTime
                            // format="YYYY-MM-DD HH:mm:ss"
                            disabledDate={(current) => {
                              return (
                                moment().add(-1, "days") >= current ||
                                moment().add(1, "month") <= current
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col xs={24} className="flex justify-content-center mt-3">
                <Form.Item className="stockLabelBtnContainer">
                  <Button htmlType="submit" className="theme-btn   ">
                    Edit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Row
          className="align-items-center  stock-summary-container mb-2 mt-2"
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
          }}
          style={{ padding: "10px" }}
        >
          <Col xs={12}>
            <div className="manage-products-title">Offers</div>
          </Col>
          <AccessControl id={42}>
            <Col xs={12} className="flex justify-content-end">
              <Button
                type="primary"
                className="theme-btn sales-table-button ml-2 product-btn"
                onClick={() => history.push(NEW_OFFER)}
              >
                <PlusOutlined />
                New Offer
              </Button>
            </Col>
          </AccessControl>
        </Row>
        <Row className="manage-order-main-content">
          <Col xs={24} className="mb-3">
            <Table
              scroll={{ x: true }}
              // loading={loading}
              columns={columns}
              dataSource={filteredData}
              size="middle"
              pagination={{
                size: "default",
                current: pagination.page,
                pageSize: pagination.perpage,
                total: total,
              }}
              onChange={_handleTableChanges}
            />
          </Col>
        </Row>
      </>
    </AccessControl>
  );
};

export default OfferList;
