import DealerList from "./DealerList";
import DealerListSalesMan from "./DealerListSalesMan";

const SalesDealer = () => {
  const user = JSON.parse(localStorage.getItem("user")!);
  return user?.user?.roles[0]?.pivot?.roleId === 4 ? (
    <DealerList />
  ) : (
    <DealerListSalesMan />
  );
};

export default SalesDealer;
