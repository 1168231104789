import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Layout,
  Radio,
  Row,
  Select,
  notification,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import "./index.scss";

import { FileUpload } from "components";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import API_SERVICE from "services/api-service";
import calenderIcon from "shared/assets/images/calendar.png";
import hashIcon from "shared/assets/images/hash.png";
import uploadIcon from "shared/assets/images/upload.png";
import { HOME, RESET_PASSWORD_DEALER } from "shared/constants/RouteConstants";
import { AuthContext } from "shared/contexts/AuthContext";
import MilanoThumbnail from "../../../assets/MilanoThumbnail.png";
import globe from "../../../assets/icons/globe.png";
import mailIcon from "../../../assets/icons/mail.png";
import userPic from "../../../assets/icons/username.png";
import { getUser } from "services/local-storage";
import { FileTextOutlined } from "@ant-design/icons";
const { Option } = Select;

export default function DealerProfile(props: any) {
  let history = useHistory();

  const user = getUser();
  const { authToken } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [signatureId, setSignatureId] = useState();
  const [licenceId, setLicenceId] = useState();
  const [stampId, setStampId] = useState();
  const [stampFile1, setStampFile1] = useState({
    id: null,
    name: "",
    fileUrl: null,
  });
  const [signatureFile1, setSignatureFile1] = useState({
    id: null,
    name: "",
    fileUrl: null,
  });
  const [licenceFile1, setLicenceFile1] = useState({
    id: null,
    name: "",
    fileUrl: null,
  });

  const [profilePic, setProfilePic] = useState("");
  const [profilePicId, setProfilePicId] = useState();
  const [countryList, setCountryList] = useState([]);

  const profileUpdate = ({ id, url }) => {
    setProfilePicId(id);
    setProfilePic(url);
  };

  const stampUrlUpdate = ({ url }: any) => {
    setStampFile1({
      ...stampFile1,
      fileUrl: url,
    });
  };
  const signatureUrlUpdate = ({ url }: any) => {
    setSignatureFile1({
      ...signatureFile1,
      fileUrl: url,
    });
  };

  const licenceUrlUpdate = ({ url }: any) => {
    setLicenceFile1({
      ...licenceFile1,
      fileUrl: url,
    });
  };

  const getUserDetails = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.self();

      const u = { ...data };
      const localData = authToken || {};
      //@ts-ignore
      localData["user"] = u;
      localStorage.setItem("user", JSON.stringify(localData));

      form.resetFields();
      form.setFieldsValue({
        ["dealerId"]: data?.id,
        ["name"]: data?.name,
        ["dealerCode"]: data?.dealerCode,
        ["email"]: data?.email,
        ["phone"]: data?.phone,
        ["stampId"]: null,
        ["signature"]: null,
        ["License-Scanned-Copy"]: null,
        ["countryId"]: data.countryId && Number(data.countryId),
        ["country"]: data?.dealerDetails[0]?.country,
        ["currency"]: data?.dealerDetails[0]?.currencyType,
        ["licenceNumber"]: data?.dealerDetails[0]?.licenceNumber,
        ["licenceExpireDate"]: moment(
          data?.dealerDetails[0]?.licenceExpireDate
        ).format("YYYY-MM-DD"),
        ["deliverySlot"]: data?.dealerDetails[0]?.deliverySlot,
      });

      setProfilePic(data?.dealerDetails[0]?.file?.url);
      setProfilePicId(data?.dealerDetails[0]?.logoId);
      setStampId(data?.dealerDetails[0]?.stampId || null);
      setStampFile1({
        id: data?.dealerDetails[0]?.stamp?.id,
        name: data?.dealerDetails[0]?.stamp?.name,
        fileUrl: data?.dealerDetails[0]?.stamp?.url,
      });
      setSignatureFile1({
        id: data?.dealerDetails[0]?.signature?.id,
        name: data?.dealerDetails[0]?.signature?.name,
        fileUrl: data?.dealerDetails[0]?.signature?.url,
      });
      setLicenceFile1({
        id: data?.dealerDetails[0]?.licence?.id,
        name: data?.dealerDetails[0]?.licence?.name,
        fileUrl: data?.dealerDetails[0]?.licence?.url,
      });

      setSignatureId(data?.dealerDetails[0]?.signatureId || null);
      setLicenceId(data?.dealerDetails[0]?.licenceId || null);

      // setStampFile(data?.dealerDetails[0]?.stamp);
      // setSignatureFile(data?.dealerDetails[0]?.signature);
      // setLicenceFile(data?.dealerDetails[0]?.licence);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  const updateDealerDetails = async (values: {
    dealerId: number;
    name: string;
    dealerCode: any;
    email: string;
    phone: number;
    countryId: number;
    country: string;
    currency: string;
    licenceExpireDate: string;
    deliverySlot: string;
  }) => {
    const dealerId = values.dealerId;
    const params = {
      name: values.name,
      dealerCode: values.dealerCode,
      email: values.email,
      phone: values.phone,
      countryId: Number(values.countryId),
      dealerDetails: {
        country: values.country,
        logoId: profilePicId,
        currency: values.currency,
        licenceExpireDate: values.licenceExpireDate,
        deliverySlot: values.deliverySlot,
        stampId: stampId,
        signatureId: signatureId,
        licenceId: licenceId,
      },
    };
    try {
      await API_SERVICE.dealerProfileUpdate(dealerId, params);
      notification.success({
        message: "Updated Successfully",
        placement: "bottomLeft",
      });

      getUserDetails();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  const prefixSelector = (
    <Form.Item name="countryId" noStyle>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          //@ts-ignore
          (option?.searchText ?? "").toLowerCase().includes(input.toLowerCase())
        }
        style={{ minWidth: "110px" }}
        options={countryList.map(
          (item: {
            countryCode: string;
            phoneCode: string;
            id: number;
            countryName: string;
          }) => {
            return {
              key: item.id,
              label: (
                <>
                  {item.countryCode} (+ {item.phoneCode})
                </>
              ),
              searchText: item.countryName,
              value: item.id,
            };
          }
        )}
      ></Select>
    </Form.Item>
  );

  const _getCountryList = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await API_SERVICE.countryList({ page: -1 });

      if (items) setCountryList(items);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  useEffect(() => {
    getUserDetails();
    _getCountryList();
  }, []);

  return (
    <Layout className="products-bg-color">
      <Content className="contentPadding">
        <Breadcrumb className="products-breadcrumb" separator=">">
          <Breadcrumb.Item>
            <Link to={HOME}>Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="products-breadcrumb-active">
            Dealer Profile
          </Breadcrumb.Item>
        </Breadcrumb>

        <Layout className="product-main-content-layout">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} className="dealer-profile-container">
              <h1 className="page-title text-center">
                Dealer Profile {user.user.name && `- (${user.user.name})`}
              </h1>

              <Form
                form={form}
                layout="vertical"
                onFinish={updateDealerDetails}
              >
                <Row
                  className="dealer-profile-form"
                  gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 24,
                    xl: 24,
                  }}
                >
                  <Col xs={24}>
                    <Form.Item
                      className="modalLabels"
                      name="profilePic"
                      style={{ textAlign: "center", margin: "20px 0px" }}
                    >
                      <FileUpload
                        showList="false"
                        type={"PROFILE_PIC"}
                        callback={profileUpdate}
                        style={{ display: "block" }}
                        onChange={(id: any) => {
                          setProfilePicId(id);
                        }}
                      >
                        {" "}
                        <img
                          src={profilePic || MilanoThumbnail}
                          alt="dealer-pic"
                          className="dealer-pic"
                        />
                        <p className="dealer-pic-text">Edit</p>
                      </FileUpload>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} hidden={true}>
                    <Form.Item
                      className="modalLabels"
                      label="Dealer Id"
                      name="dealerId"
                      hidden={true}
                    ></Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      className="modalLabels"
                      label="Dealer Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Enter Dealer Name",
                        },
                      ]}
                    >
                      <Input
                        className="modalInputs"
                        prefix={<img src={userPic} />}
                        placeholder="Dealer Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      className="modalLabels"
                      label="Dealer Code"
                      name="dealerCode"
                      rules={[
                        {
                          required: true,
                          message: "Enter Dealer Code",
                        },
                      ]}
                    >
                      <Input
                        className="modalInputs"
                        prefix={<img src={hashIcon} />}
                        placeholder="Dealer Code"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      className="modalLabels"
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Enter Dealer Email",
                        },
                      ]}
                    >
                      <Input
                        type="email"
                        className="modalInputs"
                        prefix={<img src={mailIcon} alt="emailIcon" />}
                        placeholder="Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      className="modalLabels"
                      label="Mobile Number"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Enter Mobile Number",
                        },
                        {
                          min: 8,
                          message: "Mobile Number must be 8 Digits.",
                        },
                        {
                          max: 13,
                          message: "Mobile Number must be 13 Digits.",
                        },
                      ]}
                    >
                      <Input
                        addonBefore={prefixSelector}
                        className="modalInputsPrefixSelector"
                        // prefix={<img src={smartphone} />}
                        // className="modalInputs"
                        placeholder="Mobile Number"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      className="modalLabels dealerProfileLabelsSelect"
                      label="Country"
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Enter Country Name",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        disabled={true}
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        suffixIcon={
                          <img
                            src={globe}
                            // className="banner-form-select-icon"
                            // alt="icon"
                            // style={{
                            //   height: "21px",
                            //   width: '21px',
                            //   objectFit: 'contain',

                            // }}
                          />
                        }
                        placeholder="Select Country"
                        className="banner-form-select"
                      >
                        {countryList.map((country) => {
                          return (
                            <Select.Option
                              value={country}
                              className="modalInputs"
                            >
                              {/* <img
                                src={globe}
                                className="banner-form-select-icon"
                                alt="icon"
                                style={{
                                  height: "21px",
                                  width: "21px",
                                  objectFit: "contain",
                                }}
                              /> */}
                              {country}
                            </Select.Option>
                          );
                        })}
                      </Select>
                      {/* <Input
                        className="modalInputs"
                        prefix={<img src={globe} />}
                        placeholder="Country"
                      /> */}
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      className="modalLabels"
                      label="Currency"
                      name="currency"
                      rules={[
                        {
                          required: true,
                          message: "Enter Currency",
                        },
                      ]}
                    >
                      <Input
                        className="modalInputs"
                        placeholder="Current"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} md={12}>
                    <Form.Item
                      className="modalLabels"
                      label="Stamp"
                      name="stampId"
                    >
                      <FileUpload
                        type={"DOCUMENT"}
                        callback={stampUrlUpdate}
                        style={{ display: "block" }}
                        onChange={(id: any) => setStampId(id)}
                      >
                        {stampId !== null ? (
                          <>
                            <div className="uploadedImage">
                              <img
                                //@ts-ignore
                                src={stampFile1?.fileUrl}
                                alt={stampFile1?.name}
                              />
                              <Button>Edit</Button>
                            </div>
                            <p
                              className="mt-2"
                              style={{
                                color: "#A7A7A7",
                                fontSize: "14px",
                              }}
                            >
                              Upload file format should be in JPG or PNG and the
                              maximum size of file must be less then 3 MB.
                            </p>
                          </>
                        ) : (
                          <>
                            <div className="fileUploadDesign">
                              <div>
                                <img src={uploadIcon} alt="icon" />
                                Upload Image
                              </div>
                              <p className="text">Upload</p>
                            </div>
                            <p
                              style={{
                                color: "#A7A7A7",
                                fontSize: "14px",
                              }}
                            >
                              Upload file format should be in JPG or PNG and the
                              maximum size of file must be less then 3 MB.
                            </p>
                          </>
                        )}
                      </FileUpload>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      className="modalLabels"
                      label="Signature of Signing Authority "
                      name="signature"
                    >
                      <FileUpload
                        type={"DOCUMENT"}
                        callback={signatureUrlUpdate}
                        style={{ display: "block" }}
                        onChange={(id: any) => setSignatureId(id)}
                      >
                        {signatureId !== null ? (
                          <>
                            <div className="uploadedImage">
                              <img
                                src={signatureFile1?.fileUrl}
                                alt={signatureFile1?.name}
                              />
                              <Button>Edit</Button>
                            </div>
                            <p
                              className="mt-2"
                              style={{
                                color: "#A7A7A7",
                                fontSize: "14px",
                              }}
                            >
                              Upload file format should be in JPG or PNG and the
                              maximum size of file must be less then 3 MB.
                            </p>
                          </>
                        ) : (
                          <>
                            <div className="fileUploadDesign">
                              <div>
                                <img src={uploadIcon} alt="icon" />
                                Upload Image
                              </div>
                              <p className="text">Upload</p>
                            </div>
                            <p
                              style={{
                                color: "#A7A7A7",
                                fontSize: "14px",
                              }}
                            >
                              Upload file format should be in JPG or PNG and the
                              maximum size of file must be less then 3 MB.
                            </p>
                          </>
                        )}
                      </FileUpload>
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} md={12}>
                    <Form.Item
                      className="modalLabels"
                      label=" Trade License Number"
                      name="licenceNumber"
                    >
                      <Input
                        className="modalInputs"
                        //  min={disableDates()}
                        prefix={<img src={hashIcon} />}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      className="modalLabels"
                      label="Trade License Expiration Date "
                      name="licenceExpireDate"
                    >
                      <Input
                        className="modalInputs"
                        type="date"
                        //  min={disableDates()}
                        prefix={<img src={calenderIcon} />}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      className="modalLabels"
                      label="Trade License Scanned Copy "
                      name="License-Scanned-Copy"
                    >
                      <FileUpload
                        type={"DOCUMENT"}
                        callback={licenceUrlUpdate}
                        style={{ display: "block" }}
                        onChange={(id: any) => setLicenceId(id)}
                      >
                        {licenceId !== null ? (
                          <>
                            {console.log(licenceFile1?.fileUrl)}
                            {console.log(
                              "pppppp",
                              licenceFile1?.fileUrl?.split(".")[4]
                            )}
                            <div className="uploadedImage">
                              {licenceFile1?.fileUrl?.split(".")[4] ===
                              "pdf" ? (
                                <FileTextOutlined className="icon-profile" />
                              ) : (
                                <img
                                  src={licenceFile1?.fileUrl}
                                  alt={licenceFile1?.name}
                                />
                              )}
                              <Button>Edit</Button>
                            </div>
                            <p
                              className="mt-2"
                              style={{
                                color: "#A7A7A7",
                                fontSize: "14px",
                              }}
                            >
                              Upload file format should be in JPG , PNG or pdf
                              and the maximum size of file must be less then 3
                              MB.
                            </p>
                          </>
                        ) : (
                          <>
                            <div className="fileUploadDesign">
                              <div>
                                <img src={uploadIcon} alt="icon" />
                                Upload
                              </div>
                              <p className="text">Upload</p>
                            </div>
                            <p
                              style={{
                                color: "#A7A7A7",
                                fontSize: "14px",
                              }}
                            >
                              Upload file format should be in JPG , PNG or pdf
                              and the maximum size of file must be less then 3
                              MB.
                            </p>
                          </>
                        )}
                      </FileUpload>
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      className="modalLabels"
                      label="Preferred Delivery Timing Slot"
                      name="deliverySlot"
                      rules={[
                        {
                          required: true,
                          message: "Enter Delievery Slot",
                        },
                      ]}
                    >
                      <Radio.Group className="dealer-radio-button">
                        <Radio value="Morning">Morning (8AM TO 12 PM)</Radio>
                        <Radio value="Afternoon">
                          Afternoon (12 PM TO 4PM)
                        </Radio>
                        <Radio value="Evening">Evening ( 4PM TO 8PM)</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    style={{
                      textAlign: "center",
                      marginTop: "40px",
                    }}
                  >
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        className="theme-btn addAddressBtn"
                      >
                        SAVE
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    style={{
                      textAlign: "center",
                      marginTop: "40px",
                    }}
                  >
                    <Form.Item>
                      <Link
                        to={RESET_PASSWORD_DEALER}
                        className="resetPasswordLink"
                      >
                        Change Password
                      </Link>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Layout>
      </Content>
    </Layout>
  );
}
