export const OTP = "/otp";
export const REGISTER = "/register";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";

//DEALER ROUTES
export const HOME = "/dealer";
export const CATEGORIES = "/dealer/categories";
export const PRODUCT_MAIN = "/dealer/product-main";
export const PRODUCT_DETAILS_PAGE = "/dealer/product-details-page";
export const MANAGE_ADDRESS = "/dealer/manage-address";
export const CART = "/dealer/cart";
export const THANKYOU = "/dealer/thankyou";
export const DEALER_PROFILE = "/dealer/dealer-profile";
export const WISHLIST = "/dealer/wishlist";
export const NOTIFICATIONS = "/dealer/notifications";
export const MY_ORDERS = "/dealer/my-orders";
export const CHECKOUT_REDIRECT = "/dealer/cart";
export const ORDER_DETAILS = "/dealer/order-details";
export const DASHBOARD = "/dealer/dashboard";
export const STOCK_SUMMARY = "/dealer/stock-summary";
export const REVENUE_SUMMARY = "/dealer/revenue-summary";
export const PRIVACY_POLICY = "/dealer/privacy-policy";
export const RESET_PASSWORD_DEALER = "/dealer/revenue-password";
export const DEALER_TERMS_AND_CONDITIONS = "/dealer/terms-and-conditions";
export const SEARCH_RESULT_PAGE = "/dealer/search";

//ADMIN ROUTES
export const ADMIN_DASHBOARD = "/admin";
export const ADD_USER = "/admin/add-user";
export const EDIT_USER = "/admin/add-user/:id";
export const UPDATE_CATEGORY = "/admin/update-category";
export const STOCK_LABEL = "/admin/stock-label";
export const DEALER_LIST = "/admin/dealer-list";
export const ADD_DEALER = "/admin/add-dealer";
export const ADD_ROLE = "/admin/add-role";
export const EDIT_ROLE = "/admin/add-role/:id";
export const BANNER_LIST = "/admin/banner-list";
export const BANNER_ADD = "/admin/banner-add";
export const BANNER_MANAGEMENT = "/admin/banner-management";
export const USER_MANAGEMENT = "/admin/user-management";
export const MANAGE_ORDER = "/admin/manage-order";
export const EDIT_PRODUCT = "/admin/edit-product";
export const ADMIN_ORDER_DETAILS = "/admin/order-details";
export const MANAGE_PRODUCTS = "/admin/manage-products";
export const ADMIN_PROFILE = "/admin/admin-profile";
export const RESET_ADMIN_PASSWORD = "/admin/reset-admin-profile";
export const CUSTOM_NOTIFICATION = "/admin/custom-notification";
export const NEW_NOTIFICATION = "/admin/new-notification";
export const OFFER_LIST = "/admin/offer-list";
export const COUPON = "/admin/coupon";
export const NEW_COUPON = "/admin/new-coupon";
export const NEW_OFFER = "/admin/new-offer";
export const BUYXGETYLIST = "/admin/buyxgetylist";
export const ADMIN_NOTIFICATION = "/admin/notification";
export const LOGIN_PAGE_MANAGEMENT = "/admin/login-page-management";
export const ADMIN_CONFIGURATION = "/admin/configuration";
export const ADMIN_TERMS_AND_CONDITIONS = "/admin/terms-and-conditions";
export const ADMIN_PRIVACY_POLICY = "/admin/privacy-policy";

//Sales layout
export const SALES_DASHBOARD = "/sales";
export const SALES_CHECKOUT = "/sales/checkout";
export const SALES_MANAGE_ORDERS = "/sales/manage-orders";
export const SALES_ORDER_DETAILS = "/sales/order-details";
export const SALES_DEALER_LIST = "/sales/dealer-list";
export const SALES_MAN_DEALER_LIST = "/sales/sales-man-dealer-list";
export const SALES_PROFILE = "/sales/sales-profile";
export const SALES_RESET_PASSWORD = "/sales/sales-reset-password";
export const NEW_ORDER = "/sales/new-order";
export const SALES_ORDER_APPROVAL = "/sales/order-approval";
export const SALES_THANKYOU = "/sales/thankyou";
export const SALES_PENDING_CART = "/sales/pending-cart";
export const SALES_PRODUCT_DETAIL = "/sales/product-detail";
export const SALES_NOTIFICATION = "/sales/notification";
