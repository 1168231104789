import { notification } from "antd";
import { createContext, useEffect, useState } from "react";
import API_SERVICE from "services/api-service";
import {
  DISCOUNT_TYPE_FIX,
  DISCOUNT_TYPE_PERCENTAGE,
} from "shared/constants/Constants";
import { roundOfValue } from "shared/services/Utility";

const SalesCartContext = createContext();

export const SalesCartProvider = ({ children }) => {
  const [discountDiv, setDiscountDiv] = useState(false);
  const [discountedAmount, setDiscountedAmount] = useState(null);
  const [currentDiscount, setCurrentDiscount] = useState({
    amount: 0,
    type: "",
  });
  const [cartValues, setCartValues] = useState({
    cartId: null,
    totalAmount: 0,
    totalCbm: 0,
    totalItems: 0,
  });
  const [cartProducts, setCartProducts] = useState([]);
  const [dealerId, setDealerId] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    perpage: 10,
  });
  const [total, setTotal] = useState(10);

  const createOrder = async (obj) => {
    return await API_SERVICE.createOrder({
      ...obj,
      cartId: cartValues.cartId,
      discountAmount: currentDiscount.amount,
      discountType: currentDiscount.type,
    });
  };

  const fetchCartDetails = async (
    page?: number,
    perpage?: number,
    search?: string
  ) => {
    const params = { ...pagination, dealerId };

    if (!dealerId) {
      return;
    }
    if (search) {
      params["q"] = search;
      params["page"] = 1;
    }
    if (page) {
      params["page"] = page;
    }
    if (perpage) {
      params["perpage"] = perpage;
    }
    try {
      const {
        data: { data },
      } = await API_SERVICE.getSalesCartDetails(params);

      if (data.cart) {
        let total = data.cart.totalAmount
          ? parseFloat(data.cart.totalAmount)
          : null;
        let newValue;
        if (total && currentDiscount.type === DISCOUNT_TYPE_FIX) {
          newValue = total - parseFloat(currentDiscount.amount);
          if (newValue > 0) {
            total = newValue;
          } else {
            total = total;
            setDiscountDiv(false);
            setCurrentDiscount({
              amount: null,
              type: null,
            });
          }
        } else if (currentDiscount.type === DISCOUNT_TYPE_PERCENTAGE)
          total = total - Number((currentDiscount.amount / 100) * total);
        // else if (currentDiscount.type === " ")
        //   total = parseFloat(data.cart.totalAmount);

        setCartValues({
          cartId: data.cart.id,
          totalAmount: total.toFixed(2),
          totalCbm: data.cart.totalCbm,
          totalItems: data.products.total,
        });
        setCartProducts(data.products.items);
        setPagination({
          page: data.products.page,
          perpage: data.products.perpage,
          pages: data.products.pages,
        });
        setTotal(data.products.total);
      } else {
        setCurrentDiscount({
          amount: null,
          type: null,
        });
        setCartValues({
          cartId: null,
          totalAmount: null,
          totalCbm: null,
          totalItems: null,
        });
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  useEffect(() => {
    !cartValues.cartId && fetchCartDetails();
  }, [currentDiscount, setCurrentDiscount]);

  return (
    <SalesCartContext.Provider
      value={{
        dealerId,
        setDealerId,
        total,
        setTotal,
        cartValues,
        setCartValues,
        pagination,
        setPagination,
        cartProducts,
        currentDiscount,
        setCurrentDiscount,
        setDiscountDiv,
        discountDiv,
        createOrder,
        setCartProducts,
        fetchCartDetails,
      }}
    >
      {children}
    </SalesCartContext.Provider>
  );
};

export default SalesCartContext;
