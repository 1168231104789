import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  DatePicker,
  Form,
  MenuProps,
  notification,
  Select,
  Table,
} from "antd";
import "./index.scss";

import { Layout, Row, Col, Input } from "antd";
import { FileUpload } from "components";
import globe from "../../../assets/icons/globe.png";
import API_SERVICE from "services/api-service";
import { CloudDownload, Edit } from "@material-ui/icons";
import AdminListLayout from "shared/components/layout/AdminListLayout/AdminListLayout";
import { BANNER_LIST } from "shared/constants/RouteConstants";
import moment from "moment";
import AccessControl from "services/AccessControl";

const { Header, Sider, Content } = Layout;

const HomeBanners = () => {
  const { name, id } = useParams();
  // const id;
  const [form] = Form.useForm();
  const [selectType, setSelectType] = useState("");
  const [id2, setId2] = useState(null);

  const [formTitle, setFormTitle] = useState();
  const [featuredImage, setFeaturedImage] = useState();

  const [threeBannerData, setThreeBannerData] = useState([]);
  const [bottomBanner, setBottomBanner] = useState([]);

  const [imageId, setImageId] = useState(null);
  const [imageFile, setimageFile] = useState({
    name: "",
    url: "",
  });

  const [categoriesData, setCategoriesData] = useState([]);
  const [productsList, setProductsList] = useState([]);

  let history = useHistory();

  const [loading, setLoading] = useState(false);

  const typeSelectData = [
    {
      key: "0",
      name: "Category",
      value: "CATEGORY",
    },
    {
      key: "1",
      name: "Product",
      value: "PRODUCT",
    },
    {
      key: "2",
      name: "Custom",
      value: "CUSTOM",
    },
  ];

  const fetchCategoriesData = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchCategories({});
      if (data.items !== 0) {
        setCategoriesData(data.items);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
      });
    }
  };

  const fetchAllProducts = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchCategoriesProducts("");
      if (data.items !== 0) {
        setProductsList(data.items);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
      });
    }
  };

  const onSelectTypeChange = (value: string) => {
    switch (value) {
      case "CATEGORY":
        setSelectType(value);
        fetchCategoriesData();
        form.setFieldsValue({
          typeId: null,
        });
        return;
      case "PRODUCT":
        setSelectType(value);
        fetchAllProducts();
        form.setFieldsValue({
          typeId: null,
        });
        return;
      case "CUSTOM":
        setSelectType(value);
        return;
    }
  };

  const handleUpdate = async (data: any, type?: string) => {
    try {
      const id = data.id;
      const params = {
        bannerType: type || data.bannerType,
        type: data.type,
        imageId: imageId,
        displayOrder: Number(data.displayOrder),
        status: data.status,
      };
      if (data.validUpto) {
        //@ts-ignore
        params["featuredProductValidity"] = moment(data.validUpto).format(
          "YYYY-MM-DD hh:mm:ss"
        );
      }

      if (data.typeId) {
        //@ts-ignore
        params["typeId"] = data.typeId;
      }
      if (data.customUrl) {
        //@ts-ignore
        params["customUrl"] = data.customUrl;
      }

      await API_SERVICE.updateHomeBanner(id, params);
      notification.success({
        message: `${type || data.bannerType} Updated Successfully`,
      });
      setImageId(null);
      setimageFile({
        name: "",
        url: "",
      });
      getHomeOtherBanner(type || data.bannerType);
      form.resetFields();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
      });
    }
  };

  const bannerType = (name: string) => {
    switch (name) {
      case "featured-banner":
        return (
          <>
            Featured Products
            <span className="form-sub-title">
              ( Recommended Size : height: 435 px width: 290 px )
            </span>
          </>
        );
      case "three-banner-section":
        return "Three Banner Sections";
      case "bottom-banner":
        return (
          <>
            Bottom Banner
            <span className="form-sub-title">
              ( Recommended Size : height: 509 px width: 1000 px )
            </span>
          </>
        );
      default:
        return;
    }
  };

  const threeBannerColumns = [
    {
      title: "Image ",
      render: (d: any) => (
        <>
          <a href={d.file?.url} target="_blank" rel="noreferrer">
            <img
              className="bannerTableImg"
              src={d.file?.url}
              alt="featured-image"
            />
          </a>
        </>
      ),
    },
    {
      title: "Url Type",
      dataIndex: "type",
    },
    {
      title: "Banner Type",
      dataIndex: "bannerType",
    },

    {
      title: "ACTION: ",
      //   dataIndex: "",
      render: (d: any) => (
        <div className="bannerActionButtons">
          <Button
            shape="circle"
            onClick={() => {
              setFormTitle(d.bannerType);
              setSelectType(d.type);
              onSelectTypeChange(d.type);
              if (d.customUrl) {
                form.setFieldsValue({
                  customUrl: d.customUrl,
                });
              }
              if (d.typeId) {
                setId2(d.typeId);
              }
              setimageFile({
                name: d.file.name,
                url: d.file.url,
              });
              setImageId(d.imageId);
              form.setFieldsValue({
                id: d.id,
                type: d.type,
                displayOrder: d.displayOrder,
                status: d.status,
                typeId: d.typeId,
                bannerType: d.bannerType,
              });
            }}
          >
            <Edit />
          </Button>
        </div>
      ),
    },
  ];

  const featuredColumns = [
    {
      title: "Image ",

      render: (d: any) => (
        <>
          <a href={d?.file?.url} target="_blank" rel="noreferrer">
            <img
              className="bannerTableImg"
              src={d?.file?.url}
              alt="featured-image"
            />
          </a>
        </>
      ),
    },
    {
      title: name === "bottom-banner" ? "Url Type" : "Valid Upto",
      render: (d: any) =>
        name === "bottom-banner"
          ? d.type
          : moment(d.featuredProductValidity).format("Do MMM YYYY  HH:mm:ss"),
    },

    {
      title: "ACTION: ",
      //   dataIndex: "",
      render: (d: any) =>
        name === "bottom-banner" ? (
          <div className="bannerActionButtons">
            <Button
              shape="circle"
              onClick={() => {
                setSelectType(d.type);
                onSelectTypeChange(d.type);
                if (d.customUrl) {
                  form.setFieldsValue({
                    customUrl: d.customUrl,
                  });
                }
                if (d.featuredProductValidity) {
                  form.setFieldsValue({
                    validUpto: " ",
                  });
                }
                if (d.typeId) {
                  setId2(d.typeId);
                }
                setimageFile({
                  name: d.file.name,
                  url: d.file.url,
                });
                setImageId(d.imageId);
                form.setFieldsValue({
                  id: d.id,
                  type: d.type,
                  displayOrder: d.displayOrder,
                  status: d.status,
                  typeId: d.typeId,
                });
              }}
            >
              <Edit />
            </Button>
          </div>
        ) : (
          <div className="bannerActionButtons">
            <Button
              shape="circle"
              onClick={() => {
                setimageFile({
                  name: d.file.name,
                  url: d.file.url,
                });
                setImageId(d.imageId);
                form.setFieldsValue({
                  id: d.id,
                  type: d.type,
                  displayOrder: d.displayOrder,
                  status: d.status,
                  typeId: d.typeId,
                  validUpto: moment(d.featuredProductValidity),
                });
              }}
            >
              <Edit />
            </Button>
          </div>
        ),
    },
  ];

  const getHomeOtherBanner = async (type: string) => {
    try {
      const params = {
        bannerType: type,
      };
      const {
        data: { data },
      } = await API_SERVICE.getAdminBannersList(params);

      if (data.length !== 0) {
        if (type === "FEATURED_PRODUCT") {
          setFeaturedImage(data);
        }
        if (type === "THREE_BANNER_SECTION") {
          setThreeBannerData(data);
        }

        if (type === "BOTTOM_BANNER") {
          setBottomBanner(data);
        }
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    form.resetFields();
    setimageFile({
      url: "",
      name: "",
    });
    if (name === "bottom-banner") getHomeOtherBanner("BOTTOM_BANNER");
    if (name === "featured-banner") getHomeOtherBanner("FEATURED_PRODUCT");
    if (name === "three-banner-section")
      getHomeOtherBanner("THREE_BANNER_SECTION");
  }, [name]);

  return name === "three-banner-section" ? (
    <AccessControl id={14}>
      <>
        <Row
          className="align-items-center  stock-summary-container mb-2 mt-2"
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
          }}
        >
          <Col xs={24} lg={21}>
            <div className="manage-products-title">{bannerType(name)}</div>
          </Col>
        </Row>
        <Row className="admin-banner-form" style={{ minHeight: "fit-content" }}>
          <Col
            xs={24}
            sm={24}
            lg={12}
            className="p-2 "
            style={{ position: "relative" }}
          >
            <h1 className="manage-products-title">
              {formTitle}
              {formTitle === "LONG_IMAGE" && (
                <span className="form-sub-title">
                  ( Recommended Size : height: 438 px width: 1280 px )
                </span>
              )}
              {formTitle === "LEFT_IMAGE" && (
                <span className="form-sub-title">
                  ( Recommended Size : height: 453 px width: 886 px )
                </span>
              )}
              {formTitle === "RIGHT_IMAGE" && (
                <span className="form-sub-title">
                  ( Recommended Size : height: 453 px width: 886 px )
                </span>
              )}
            </h1>

            <Form
              form={form}
              labelCol={{
                xs: { span: 4 },
                sm: { span: 4 },
                md: { span: 8 },
                lg: { span: 6 },
                xl: { span: 6 },
              }}
              labelAlign="left"
              wrapperCol={{
                xs: { span: 2 },
                sm: { span: 2 },
                md: { span: 16 },
                lg: { span: 18 },
                xl: { span: 18 },
              }}
              layout="horizontal"
              onFinish={handleUpdate}
            >
              <Form.Item name="displayOrder" hidden></Form.Item>
              <Form.Item name="id" hidden></Form.Item>
              <Form.Item name="bannerType" hidden></Form.Item>
              <Form.Item
                label="Select Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Select Type",
                  },
                ]}
              >
                <Select
                  placeholder="Select Type"
                  className="banner-form-select"
                  onChange={onSelectTypeChange}
                >
                  {typeSelectData.map((t) => (
                    <Select.Option key={t.key} value={t.value}>
                      {t.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {selectType === "CUSTOM" ? (
                <Form.Item
                  label="Custom Url"
                  name="customUrl"
                  rules={[
                    {
                      required: true,
                      message: "Enter Url Please",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Custom Url"
                    className="banner-form-input"
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Select Id"
                  name="typeId"
                  rules={[
                    {
                      required: true,
                      message: "Select Id",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option?.children[1]
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    placeholder="Search to Select Id"
                    className="banner-form-select"
                    onChange={(d) => console.log(d)}
                  >
                    {selectType === "CATEGORY"
                      ? categoriesData.map((cat: any) => {
                          return (
                            <Select.Option value={cat.id}>
                              <img
                                src={cat?.file?.url}
                                className="banner-form-select-icon"
                                alt="icon"
                              />
                              {cat.name}
                            </Select.Option>
                          );
                        })
                      : productsList.map((prod: any) => {
                          return (
                            <Select.Option value={prod.id}>
                              <img
                                src={prod?.thumbnail?.url}
                                className="banner-form-select-icon"
                                alt="icon"
                              />
                              {prod.name}
                            </Select.Option>
                          );
                        })}
                  </Select>
                </Form.Item>
              )}

              <Form.Item label="Select Image" name="file1">
                <FileUpload
                  type={"BANNER"}
                  onChange={(id: any) => {
                    setImageId(id);
                  }}
                  Long
                  Image
                >
                  {imageFile.url !== "" ? (
                    <div className="uploadedImage">
                      <img src={imageFile?.url} alt={imageFile?.name} />
                      <Button>Edit</Button>
                    </div>
                  ) : (
                    <>
                      <div className="fileUploadDesign">
                        <div>
                          <img src={globe} alt="icon" />
                          Upload Image
                        </div>
                        <p className="text">Upload</p>
                      </div>
                      <p
                        style={{
                          color: "#A7A7A7",
                          fontSize: "14px",
                        }}
                      >
                        Upload file format should be in JPG or PNG and the
                        maximum size of file must be less then 3 MB.
                      </p>
                    </>
                  )}
                </FileUpload>
                {/* {imageFile.url !== "" && (
                <p className="LastUploadedFileCss mt-2">
                  {imageFile?.name}

                  <a
                    target="_blank"
                    onClick={() => window.open(imageFile?.url)}
                  >
                    <CloudDownload />
                    Download
                  </a>
                </p>
              )} */}
              </Form.Item>

              <Form.Item label=""></Form.Item>
              <AccessControl id={21}>
                <Form.Item>
                  <Button htmlType="submit" className="mr-2">
                    Update
                  </Button>
                  <Button
                    htmlType="button"
                    onClick={() => {
                      form.resetFields();
                      setimageFile({
                        url: "",
                        name: "",
                      });
                      setFormTitle("");
                    }}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </AccessControl>
            </Form>
          </Col>
          <Col xs={24} lg={12}>
            <Table
              loading={loading}
              pagination={false}
              columns={threeBannerColumns}
              dataSource={threeBannerData}
              size="middle"
            />
          </Col>
        </Row>
      </>
    </AccessControl>
  ) : (
    <AccessControl id={name === "bottom-banner" ? 15 : 13}>
      <>
        <Row
          className="align-items-center  stock-summary-container mb-2 mt-2"
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
          }}
        >
          <Col xs={24} lg={21}>
            <div className="manage-products-title">{bannerType(name)} </div>
          </Col>
        </Row>
        <Row className="admin-banner-form">
          <Col
            xs={24}
            sm={24}
            lg={24}
            xl={12}
            className="p-2 "
            style={{ position: "relative" }}
          >
            <Form
              form={form}
              labelCol={{
                xs: { span: 4 },
                sm: { span: 4 },
                md: { span: 8 },
                lg: { span: 6 },
                xl: { span: 6 },
              }}
              labelAlign="left"
              wrapperCol={{
                xs: { span: 2 },
                sm: { span: 2 },
                md: { span: 16 },
                lg: { span: 18 },
                xl: { span: 18 },
              }}
              layout="horizontal"
              onFinish={(values) => {
                name === "bottom-banner"
                  ? handleUpdate(values, "BOTTOM_BANNER")
                  : handleUpdate(values, "FEATURED_PRODUCT");
              }}
            >
              <Form.Item name="displayOrder" hidden></Form.Item>
              <Form.Item name="id" hidden></Form.Item>

              {name === "bottom-banner" && (
                <>
                  <Form.Item
                    label="Select Type"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Select Type",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Type"
                      className="banner-form-select"
                      onChange={onSelectTypeChange}
                    >
                      {typeSelectData.map((t) => (
                        <Select.Option key={t.key} value={t.value}>
                          {t.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {selectType === "CUSTOM" ? (
                    <Form.Item
                      label="Custom Url"
                      name="customUrl"
                      rules={[
                        {
                          required: true,
                          message: "Enter Url Please",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Custom Url"
                        className="banner-form-input"
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label="Select Id"
                      name="typeId"
                      rules={[
                        {
                          required: true,
                          message: "Select Id",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                          option?.children[1]
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        placeholder="Search to Select Id"
                        className="banner-form-select"
                        onChange={(d) => console.log(d)}
                      >
                        {selectType === "CATEGORY"
                          ? categoriesData.map((cat: any) => {
                              return (
                                <Select.Option value={cat.id}>
                                  <img
                                    src={cat?.file?.url}
                                    className="banner-form-select-icon"
                                    alt="icon"
                                  />
                                  {cat.name}
                                </Select.Option>
                              );
                            })
                          : productsList.map((prod: any) => {
                              return (
                                <Select.Option value={prod.id}>
                                  <img
                                    src={prod?.thumbnail?.url}
                                    className="banner-form-select-icon"
                                    alt="icon"
                                  />
                                  {prod.name}
                                </Select.Option>
                              );
                            })}
                      </Select>
                    </Form.Item>
                  )}
                </>
              )}

              <Form.Item label="Select Image" name="file1">
                <FileUpload
                  type={"BANNER"}
                  onChange={(id: any) => {
                    setImageId(id);
                  }}
                >
                  {imageFile.url !== "" ? (
                    <div className="uploadedImage">
                      <img src={imageFile?.url} alt={imageFile?.name} />
                      <Button>Edit</Button>
                    </div>
                  ) : (
                    <>
                      <div className="fileUploadDesign">
                        <div>
                          <img src={globe} alt="icon" />
                          Upload Image
                        </div>
                        <p className="text">Upload</p>
                      </div>
                      <p
                        style={{
                          color: "#A7A7A7",
                          fontSize: "14px",
                        }}
                      >
                        Upload file format should be in JPG or PNG and the
                        maximum size of file must be less then 3 MB.
                      </p>
                    </>
                  )}
                </FileUpload>
              </Form.Item>

              {name === "featured-banner" && (
                <Form.Item
                  label="Valid Upto"
                  name="validUpto"
                  rules={[
                    {
                      required: true,
                      message: "Select Date",
                    },
                  ]}
                >
                  <DatePicker
                    showTime
                    disabledDate={(current) => {
                      return (
                        moment().add(-1, "days") >= current ||
                        moment().add(1, "month") <= current
                      );
                    }}
                    placeholder="Valid Upto Date"
                    className="banner-form-input"
                  />
                </Form.Item>
              )}

              <Form.Item label=""></Form.Item>
              <AccessControl id={name === "featured-banner" ? 20 : 25}>
                <Form.Item>
                  <Button htmlType="submit" className="mr-2">
                    Update
                  </Button>
                  <Button
                    htmlType="button"
                    onClick={() => {
                      form.resetFields();
                      setimageFile({
                        url: "",
                        name: "",
                      });
                      setFormTitle("");
                    }}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </AccessControl>
            </Form>
          </Col>
          <Col xs={24} xl={12}>
            <Table
              loading={loading}
              pagination={false}
              columns={featuredColumns}
              dataSource={
                name !== "bottom-banner" ? featuredImage : bottomBanner
              }
              size="middle"
            />
          </Col>
        </Row>
      </>
    </AccessControl>
  );
};

export default HomeBanners;
