import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { DollarCircleOutlined, MailOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import API_SERVICE from "services/api-service";
import "./AddDealer.scss";

import {
  ArrowLeftOutlined,
  FileTextOutlined,
  HomeOutlined,
  MobileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { notification } from "antd";
import globe from "../../../assets/icons/globe.png";
import mapPin from "../../../assets/icons/map-pin.png";
import map from "../../../assets/icons/map.png";

import { Button, Col, Form, Input, Row, Select } from "antd";

const { Option } = Select;

export default function AddDealer(props: any) {
  const [form] = Form.useForm();
  let history = useHistory();
  const [salesManagerList, setSalesManagerList] = useState([]);
  const [currentSalesManagerList, setCurrentSalesManagerList] = useState([]);
  const [salesManList, setSalesManList] = useState([]);
  const [currentSalesManList, setCurrentSalesManList] = useState([]);
  const dealerId = props?.match?.params?.id;
  const [countryList, setCountryList] = useState([]);

  const fetchSalesManager = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await API_SERVICE.getAdminAllUsers({ roleId: 4 });
      setSalesManagerList(items);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };
  const fetchSalesMan = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await API_SERVICE.getAdminAllUsers({ roleId: 6, page: -1 });
      setSalesManList(items);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const updateDealer = async (values: any) => {
    try {
      const val = await form.getFieldsValue();
      const outerObj = [];
      for (let i = 0; i < addrList.length; i++) {
        outerObj.push({
          id: values[`id${i}`],
          address: values[`address${i}`],
          building: values[`building${i}`],
          area: values[`area${i}`],
          city: values[`city${i}`],
          zipcode: values[`zipcode${i}`],
          street: values[`street${i}`],
          country: values[`country${i}`],
        });
      }

      const params = {
        name: val?.dealerName,
        email: val?.email,
        dealerCode: val?.dealerCode,
        phone: val?.phone,
        countryId: val.prefixCountry,
        currencyType: val.currencyType ? val.currencyType : null,
        userAddress: outerObj,
        salesManagerIds: val.salesManager,
        salesPersonIds: val.salesPerson,
      };

      await API_SERVICE.updateDealer(dealerId, params);
      notification.success({
        message: "Updated Successfully",
        placement: "bottomRight",
      });
      fillForm();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };
  const addr = {
    id: "",
    address: "",
    building: "",
    city: "",
    zipcode: "",
    area: "",
    street: "",
    country: "",
  };
  const [addrList, setAddrList] = useState([]);
  const [dataAddress, setDataAddress] = useState([]);

  const fillForm = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchDealerById(dealerId);
      setAddrList(data?.address);
      setDataAddress(data?.address);
      const newArr = data.salesManager.map((item: any) => item.salesManagerId);
      setCurrentSalesManagerList(newArr);
      const newArr1 = data.salesPerson.map((item: any) => item.salesPersonId);
      setCurrentSalesManList(newArr1);

      let currency;
      let orionEmail;
      let orionPhone;
      if (data.dealerDetails && data.dealerDetails[0]) {
        currency = data.dealerDetails[0].currencyType;
        orionEmail = data.dealerDetails[0].orionEmail;
        orionPhone = data.dealerDetails[0].orionPhone;
      }

      form.setFieldsValue({
        dealerCode: data?.dealerCode,
        dealerName: data?.name,
        email: data?.email,
        phone: data?.phone,
        prefixCountry: data.country ? Number(data.country.id) : null,
        currencyType: currency,
        salesManager: newArr,
        salesPerson: newArr1,
        orionEmail: orionEmail,
        orionPhone: orionPhone,
      });
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const prefixSelector = (
    <Form.Item name="prefixCountry" noStyle>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          //@ts-ignore
          (option?.searchText ?? "").toLowerCase().includes(input.toLowerCase())
        }
        style={{ minWidth: "110px" }}
        // style={{ width: "fit-content", minWidth: "180px" }}
        options={countryList.map(
          (item: {
            countryCode: string;
            phoneCode: string;
            id: number;
            countryName: string;
          }) => {
            return {
              key: item.id,
              label: (
                <>
                  {item.countryCode} (+ {item.phoneCode})
                </>
              ),
              searchText: item.countryName,
              value: item.id,
            };
          }
        )}
      ></Select>
    </Form.Item>
  );

  const _getCountryList = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await API_SERVICE.countryList({ page: -1 });

      if (items) setCountryList(items);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  useEffect(() => {
    fetchSalesManager();
    fetchSalesMan();
    fillForm();
    _getCountryList();
  }, []);

  const deleteTableRows = (index: any) => {
    const rows = [...addrList];
    rows.splice(index, 1);
    setAddrList(rows);
  };

  return (
    <>
      <div className="add-dealer-main-content">
        <div className="main-title">DEALERS</div>
        <div className="add-dealer-icon">
          {" "}
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </div>
        <div className="add-dealer-title">Update Dealer</div>
        <div className="dealer-detail-title add-dealer-title">
          Basic Details
        </div>
        <div className="add-dealer-form">
          <Form form={form} layout="vertical" onFinish={updateDealer}>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <Form.Item
                  label="Dealer Code"
                  name="dealerCode"
                  rules={[
                    {
                      required: true,
                      message: "Enter dealer code",
                    },
                  ]}
                >
                  <Input
                    prefix={<FileTextOutlined />}
                    placeholder="Alphanumeric"
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <Form.Item
                  label="Dealer Name"
                  name="dealerName"
                  rules={[
                    {
                      required: true,
                      message: "Enter dealer name",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Dealer Name"
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Enter Email",
                    },
                  ]}
                >
                  <Input
                    type="email"
                    prefix={<MailOutlined />}
                    placeholder="Email"
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <Form.Item
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Enter Phone Number",
                    },
                    {
                      min: 8,
                      message: "Phone Number must be 8 Digits.",
                    },
                    {
                      max: 13,
                      message: "Phone Number must be 13 Digits.",
                    },
                  ]}
                >
                  <Input
                    addonBefore={prefixSelector}
                    className="modalInputsPrefixSelector"
                    // prefix={<MobileOutlined />}
                    placeholder="Phone"
                    // className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <Form.Item label="Currency" name="currencyType">
                  <Input
                    disabled
                    prefix={<DollarCircleOutlined />}
                    placeholder="Currency"
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <Form.Item label="Orion Email" name="orionEmail">
                  <Input
                    disabled
                    prefix={<MailOutlined />}
                    placeholder="OrionEmail"
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                <Form.Item label="Orion Phone Number" name="orionPhone">
                  <Input
                    disabled
                    prefix={<MobileOutlined />}
                    placeholder="Orion Phone Number"
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className=" add-dealer-title">Address Details</div>
            {addrList.map((item, index) => {
              form.setFieldsValue({
                ["id" + index]: dataAddress[index]?.id,
                ["address" + index]: dataAddress[index]?.address,
                ["building" + index]: dataAddress[index]?.building,
                ["city" + index]: dataAddress[index]?.city,
                ["zipcode" + index]: dataAddress[index]?.zipcode,
                ["city" + index]: dataAddress[index]?.city,
                ["area" + index]: dataAddress[index]?.area,
                ["street" + index]: dataAddress[index]?.street,
                ["country" + index]: dataAddress[index]?.country,
              });
              return (
                <Row
                  align="middle"
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 24, xl: 24 }}
                >
                  <div className="address-row-title">Address {index + 1}</div>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 24,
                        xl: 24,
                      }}
                    >
                      <Form.Item hidden={true} name={"id" + index}>
                        <Input
                          prefix={<HomeOutlined />}
                          placeholder="Building"
                        />
                      </Form.Item>
                      <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                        <Form.Item
                          label="Address"
                          name={"address" + index}
                          rules={[
                            {
                              required: true,
                              message: "Enter Address",
                            },
                          ]}
                        >
                          <Input
                            prefix={<HomeOutlined />}
                            placeholder="Enter Address"
                            className="banner-form-input"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                        <Form.Item
                          label="Building"
                          name={"building" + index}
                          rules={[
                            {
                              required: true,
                              message: "Enter building",
                            },
                          ]}
                        >
                          <Input
                            prefix={<HomeOutlined />}
                            placeholder="Building"
                            className="banner-form-input"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                        <Form.Item
                          label="City"
                          name={"city" + index}
                          rules={[
                            {
                              required: true,
                              message: "Enter city",
                            },
                          ]}
                        >
                          <Input
                            prefix={<img src={mapPin} />}
                            placeholder="City"
                            className="banner-form-input"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={3}
                        className="flex justify-content-center align-items-center"
                      >
                        {addrList.length > 1 && (
                          <Button
                            className="labelRowCssDeleteBtn"
                            onClick={() => deleteTableRows(index)}
                          >
                            <CloseIcon />
                          </Button>
                        )}
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                        <Form.Item
                          label="PO Box/Zip Code"
                          name={"zipcode" + index}
                          rules={[
                            {
                              required: true,
                              message: "Enter Zip Code ",
                            },
                          ]}
                        >
                          <Input
                            prefix={<MailOutlined />}
                            placeholder="PO Box/Zip Code"
                            className="banner-form-input"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                        <Form.Item
                          label="Area"
                          name={"area" + index}
                          rules={[
                            {
                              required: true,
                              message: "Enter Area",
                            },
                          ]}
                        >
                          <Input
                            prefix={<img src={mapPin} />}
                            placeholder="Area"
                            className="banner-form-input"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                        <Form.Item
                          label="Street"
                          name={"street" + index}
                          rules={[
                            {
                              required: true,
                              message: "Enter street",
                            },
                          ]}
                        >
                          <Input
                            prefix={<img src={map} />}
                            placeholder="Street"
                            className="banner-form-input"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 24,
                        xl: 24,
                      }}
                    >
                      <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                        <Form.Item
                          label="Country"
                          name={"country" + index}
                          rules={[
                            {
                              required: true,
                              message: "Enter country ",
                            },
                          ]}
                        >
                          <Input
                            prefix={<img src={globe} />}
                            placeholder="Country"
                            className="banner-form-input"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
            <div>
              <Button
                className="add-dealer-address-btn"
                //@ts-ignore
                onClick={() => setAddrList([...addrList, addr])}
              >
                Add More Address
              </Button>
            </div>
            <div className=" add-dealer-title">Sales Team Assignment</div>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <Col xs={24} sm={24} md={8} lg={12} xl={6}>
                <Form.Item label="Sales Manager" name="salesManager">
                  <Select
                    maxTagCount={"responsive"}
                    placeholder="Select Sales Manager"
                    mode="multiple"
                    options={salesManagerList.map((item: any) => {
                      return {
                        value: item.id,
                        label: item.name,
                      };
                    })}
                  ></Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={8} lg={12} xl={6}>
                <Form.Item label="Sales Man" name="salesPerson">
                  <Select
                    maxTagCount={"responsive"}
                    placeholder="Select Sales Man"
                    mode="multiple"
                    options={salesManList.map((item: any) => {
                      return {
                        value: item.id,
                        label: item.name,
                      };
                    })}
                  >
                    {}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <div>
              <Button className="add-dealer-btn" htmlType="submit">
                Update Dealer
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
