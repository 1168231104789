import {
  DownOutlined,
  PlusOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Button, Col, Divider, Input, Modal, Row, Switch, Table } from "antd";
import "./index.scss";
import { EditOutlined, SearchOutlined } from "@material-ui/icons";
import {
  JSXElementConstructor,
  ReactElement,
  ReactNodeArray,
  ReactPortal,
  useState,
} from "react";
import API_SERVICE from "client/src/services/api-service";
import { useEffect } from "react";
import { notification } from "antd";
import { ADD_DEALER } from "shared/constants/RouteConstants";
import AccessControl from "services/AccessControl";
import { ReactI18NextChild } from "react-i18next";

function DealerList() {
  let history = useHistory();
  const { Search } = Input;
  const [loading, setLoading] = useState(false);
  const [dealerList, setDealerList] = useState([]);
  const [filterAttributes, setFilterAttributes] = useState({});
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: "15",
  } as any);
  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);

  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [downloadSampleUrl, setDownloadSampleUrl] = useState("");

  const [bulkFile, setBulkFile] = useState({
    name: " ",
    size: 0,
  });

  const addDealerClick = (dealerId: number) => {
    history.push(`${ADD_DEALER}/${dealerId}`, dealerId);
  };

  const getDealerList = async (
    pagination = {},
    sort = {},
    filters = {},
    search = {}
  ) => {
    const params = {
      ...pagination,
      ...sort,
      ...filters,
      ...search,
    };
    try {
      setLoading(true);
      const {
        data: { data },
      } = await API_SERVICE.fetchDealer(params);
      if (data) {
        setDealerList(data?.items);
        setTotal(data?.total);
      }
      setLoading(false);
    } catch (e) {
      notification.error({
        message: API_SERVICE.handleErrors(e),
        placement: "bottomRight",
      });
    }
  };

  const getAllAttributes = async () => {
    const {
      data: { data },
    } = await API_SERVICE.fetchAllAttributesDealer();
    setFilterAttributes(data);
  };

  const _handleSearch = (event: { which: number; target: { value: any } }) => {
    if (event.which === 13) {
      setSearch({ q: event.target.value });
      setPagination((prev: { perpage: any }) => {
        return { page: 1, perpage: prev.perpage };
      });
      getDealerList({ page: 1, perpage: pagination.perpage }, sort, filters, {
        q: event.target.value,
      });
    }
  };

  const _handleTableChanges = async (
    pagination: { current: any; pageSize: any },
    filters: any,
    sorter: { order: string; columnKey: any },
    extra: { currentDataSource: any; action: string }
  ) => {
    if (extra.action === "paginate") {
      setPagination({ page: pagination.current, perpage: pagination.pageSize });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };

  const toogleActiveInactive = async (id: number, isActive: boolean) => {
    try {
      await API_SERVICE.dealerActiveInactiveToogleCall(id, {
        isActive,
      });

      notification.success({
        message: isActive ? "Activated" : "Deactivated",
        placement: "bottomRight",
      });
      getDealerList(pagination.page);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const downloadTableData = async () => {
    try {
      setDownloadLoader(true);
      const {
        data: { data },
      } = await API_SERVICE.downloadDealersData();
      if (data) {
        window.open(data.url, "_blank");
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    } finally {
      setDownloadLoader(false);
    }
  };
  useEffect(() => {
    getDealerList(pagination, sort, filters, search);
  }, [sort, filters, pagination]);

  useEffect(() => {
    getAllAttributes();
  }, []);

  const columns = [
    {
      title: "S.N.",
      dataIndex: "key",
      // sorter: (a: any, b: any) => a.serialNumber - b.serialNumber,
      render: (a: any, v: any, index: number) => (
        <div>{(pagination.page - 1) * pagination.perpage + index + 1}</div>
      ),
    },
    {
      title: "DEALER CODE",
      dataIndex: "dealerCode",
      className: "user-name",
    },
    {
      title: "DEALER NAME",
      dataIndex: "name",
      filterSearch: true,
      filters:
        filterAttributes && filterAttributes.dealerName
          ? filterAttributes?.dealerName.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      filterSearch: true,
      filters:
        filterAttributes && filterAttributes.email
          ? filterAttributes?.email.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      // onFilter: (value: string, record: any) =>
      // record.email.indexOf(value) === 0,
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },

    {
      title: "COUNTRY",
      dataIndex: "address",
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      filterSearch: true,
      filters:
        filterAttributes && filterAttributes.phone
          ? filterAttributes?.phone.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      // onFilter: (value: string, record: any) =>
      // record.phone.indexOf(value) === 0,
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
    },
    {
      title: "SALES MANAGER",
      dataIndex: "salesManager",
      filterSearch: true,
      filters:
        filterAttributes && filterAttributes.salesManager
          ? filterAttributes?.salesManager.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      // onFilter: (value: string, record: any) =>
      // record.salesManager.indexOf(value) === 0,
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      render: (d: any) => {
        return d.map((item: { name: any }) => (
          <p className="m-0"> {item.name}</p>
        ));
      },
    },
    {
      title: "STATUS",
      key: "isActive",
      // dataIndex: "isActive",
      filters: [
        {
          text: "Active",
          value: 1,
        },
        {
          text: "Inactive",
          value: 0,
        },
      ],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,

      render: (d: any) => (
        <div>
          <Switch
            onChange={(e: any) => {
              toogleActiveInactive(d.id, e);
            }}
            checked={d.isActive ? true : false}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
          />
        </div>
      ),
    },
    {
      title: "ACTION: ",
      key: "action",
      render: (a: any) => (
        <AccessControl id={45}>
          <div className="actionBtns">
            <Button
              shape="circle"
              onClick={() => {
                addDealerClick(a.id);
              }}
            >
              <EditOutlined />
            </Button>
            {/* <Button shape="circle" className="action-btn">
                        <DeleteOutlined />
                    </Button> */}
          </div>
        </AccessControl>
      ),
    },
  ];

  return (
    <AccessControl id={39}>
      <>
        <BulkUpload
          bulkUploadModal={bulkUploadModal}
          setBulkUploadModal={setBulkUploadModal}
          downloadSampleUrl={downloadSampleUrl}
          setDownloadSampleUrl={setDownloadSampleUrl}
          bulkFile={bulkFile}
          setBulkFile={setBulkFile}
        />
        <Row
          className=" stock-summary-container mb-2 mt-2"
          align="middle"
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
          }}
        >
          <Col xs={10} sm={10} lg={7} xl={12} xxl={11}>
            <div className="manage-products-title">Dealer List</div>
          </Col>
          <Col
            xs={14}
            sm={14}
            lg={7}
            xl={4}
            xxl={6}
            className="flex justify-content-end "
          >
            <Input
              onKeyPress={_handleSearch}
              className="admin-search mr-2 "
              placeholder="Search..."
              prefix={<SearchOutlined />}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={10}
            lg={4}
            xl={3}
            xxl={3}
            className="flex justify-content-end"
          >
            <Button
              type="primary"
              className="product-btn"
              onClick={() => {
                setBulkUploadModal(true);
              }}
            >
              <PlusOutlined />
              Bulk Update
            </Button>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={4}
            xxl={4}
            className="flex justify-content-center"
          >
            <Button
              loading={downloadLoader}
              type="primary"
              onClick={() => downloadTableData()}
              className="product-btn"
            >
              <PlusOutlined />
              Download Dealer List
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={24} className="mb-3 mt-2">
            <Table
              scroll={{ x: true }}
              loading={loading}
              columns={columns}
              dataSource={dealerList}
              size="middle"
              pagination={{
                size: "default",
                current: pagination.page,
                pageSize: pagination.perpage,
                total: total,
              }}
              onChange={_handleTableChanges}
            />
          </Col>
        </Row>
      </>
    </AccessControl>
  );
}

export default DealerList;

function BulkUpload(props: {
  bulkUploadModal: boolean;
  setBulkUploadModal: Function;
  downloadSampleUrl: string;
  setDownloadSampleUrl: Function;
  bulkFile: any;
  setBulkFile: Function;
}) {
  const downloadSample = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.dealerListSampleDownload();
      if (data) {
        props.setDownloadSampleUrl(data.url);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  //handleBulkUpload
  const handleBulkUpload = async (e: any) => {
    e.preventDefault();
    try {
      await API_SERVICE.dealerListBulkUpload(props.bulkFile);
      notification.success({
        message: "Uploaded successfully",
        placement: "bottomRight",
      });
      props.setBulkFile({ name: " ", size: 0 });
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    downloadSample();
  }, []);

  return (
    <Modal
      title="Bulk update dealer contact details"
      footer={null}
      className="add-address-modal "
      open={props.bulkUploadModal}
      onCancel={() => props.setBulkUploadModal(false)}
    >
      <form onSubmit={handleBulkUpload}>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 24,
            xl: 24,
          }}
        >
          <Col xs={24} sm={24} md={16} lg={24} xl={16}>
            <a href={props.downloadSampleUrl} download>
              <div className="fileUploadDesign" style={{ height: "45px" }}>
                <div className="text-truncate">
                  <DownloadOutlined className="icon" />
                  Download Sample
                </div>
                <p className="text">Download</p>
              </div>
            </a>
          </Col>
          <Col xs={24} sm={24} md={16} lg={24} xl={16}>
            <input
              type="file"
              id="uploadFile"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={{ display: "none" }}
              onChange={(e: any) => props.setBulkFile(e?.target?.files[0])}
            ></input>
            <label htmlFor="uploadFile">
              <p>Upload File</p>
              <div className="fileUploadDesign">
                <div>
                  <UploadOutlined className="icon" />

                  {props.bulkFile?.name !== " " ? (
                    <span className="text">{props.bulkFile?.name}</span>
                  ) : (
                    "Select File"
                  )}
                </div>
                <p className="text">Upload</p>
              </div>
            </label>
          </Col>
          <Col xs={24} className="flex justify-content-center mt-3">
            <div className="stockLabelBtnContainer">
              <Button htmlType="submit" className="theme-btn">
                Upload
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </Modal>
  );
}
