import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  List,
  Menu,
  Row,
  Switch,
  Table,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import API_SERVICE from "../../../services/api-service";
import "./index.scss";

import AccessControl, {
  accessIncludes,
} from "client/src/services/AccessControl";
import { DEFAULT_USER_ROLES } from "shared/constants/Constants";
import userPic from "../../../assets/icons/username.png";

const { SubMenu } = Menu;
const { Panel } = Collapse;
export default function AddRole(props: any) {
  const [selectedRoleIndex, setSelectedRoleIndex] = useState(-1);
  const [roles, setRoles] = useState([] as any[]);
  const [all_permissions, set_all_permissions] = useState([]);
  const [role_permissions, set_role_permissions] = useState([]);
  const [form] = Form.useForm();
  const history = useHistory();
  const topScrollRef: any = useRef(null);
  const [view, setView] = useState<string>("Add");
  const [roleId, setRoleId] = useState<number>(Number.MIN_VALUE);

  const refresh = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await API_SERVICE.getAdminAllRoles({ page: -1 });
      setRoles(items);
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  const get_all_permissions = async () => {
    try {
      const data = await API_SERVICE.getAdminPermissions({});
      set_all_permissions(data.data.data[0]);
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  async function toggle_access(id) {
    const roleId = roles[selectedRoleIndex].id;
    let arr = role_permissions;
    const index = role_permissions.indexOf(id);
    if (index > -1) arr.splice(index, 1);
    else arr.push(id);
    await API_SERVICE.update_permissions(roleId, arr);
    refresh();
  }
  function access_control(obj) {
    if (obj)
      return accessIncludes([56]) ? (
        <Checkbox
          onChange={() => toggle_access(obj.id)}
          checked={role_permissions.includes(obj.id)}
        />
      ) : (
        <Checkbox
          onChange={() => toggle_access(obj.id)}
          checked={role_permissions.includes(obj.id)}
          disabled={true}
        />
      );
    else return <Checkbox disabled />;
  }
  const permission_columns = {
    name: "Permission rights",
    Show: "Enable/Disable",
  };
  function create_column(id) {
    const obj = {
      key: id,
      title: permission_columns[id] || id,
    };
    if (id == "name") obj.render = (a: any) => a[id];
    else {
      obj.align = "center";
      obj.render = (a: any) => access_control(a[id]);
    }
    return obj;
  }

  const manageRoleEditOrAdd = async () => {
    try {
      const val = form.getFieldsValue();
      if (roleId !== Number.MIN_VALUE) {
        await API_SERVICE.editAdminRole(roleId, {
          name: val.roleName,
          description: val.roleDescription,
        });
        notification.success({
          message: "Role Updated Successfully",
          placement: "bottomRight",
        });
      } else {
        await API_SERVICE.addAdminRole({
          name: val.roleName,
          description: val.roleDescription,
        });
        notification.success({
          message: "Role Created Successfully",
          placement: "bottomRight",
        });
      }
      form.resetFields();
      refresh();
      setView("Add");
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const handleEditRole = async (id: number) => {
    console.log("id", id);
    try {
      setRoleId(id);
      const {
        data: { data },
      } = await API_SERVICE.getAdminRoleById(id);
      topScrollRef.current.scrollIntoView();
      setView("Edit");
      form.setFieldsValue({
        roleName: data.name,
        roleDescription: data.description,
      });
      getPermissionById();
    } catch (error) {
      notification.error({
        placement: "bottomRight",
        message: "Something Went Wrong",
      });
    }
  };

  const getPermissionById = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.getPermissionById(roleId);
    } catch (error) {
      notification.error({
        placement: "bottomRight",
        message: "Something Went Wrong",
      });
    }
  };

  useEffect(() => {
    if (selectedRoleIndex > -1)
      set_role_permissions(roles[selectedRoleIndex].permissionIds || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoleIndex]);
  useEffect(() => {
    refresh();
    get_all_permissions();
  }, []);

  return (
    <AccessControl id={53}>
      <>
        <Row ref={topScrollRef}>
          <Col xs={24}>{view} ROLES</Col>
          <Col xs={24} className="mt-2 mb-2">
            <ArrowLeftOutlined
              className="adminLeftArrowIcon"
              onClick={() => history.goBack()}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} className="addRoleLeft">
            <Row>
              <Col xs={24} sm={24} md={22}>
                <Row>
                  <Col xs={24}>
                    <Form
                      onFinish={manageRoleEditOrAdd}
                      form={form}
                      layout="vertical"
                    >
                      <Row
                        gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 24,
                          xl: 24,
                        }}
                      >
                        <Col xs={24} md={12}>
                          <Form.Item
                            label="Role Name"
                            name="roleName"
                            rules={[
                              {
                                required: true,
                                message: "Enter Role Name",
                              },
                            ]}
                          >
                            <Input
                              prefix={<img src={userPic} alt="" />}
                              placeholder="Name"
                              className="banner-form-input"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            label="Role Description"
                            name="roleDescription"
                            rules={[
                              {
                                required: true,
                                message: "Enter Role Description",
                              },
                            ]}
                          >
                            <TextArea
                              rows={4}
                              className="adminTextarea"
                              placeholder="Role Description"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={10} className="mt-2">
                          <Button
                            htmlType="submit"
                            className="theme-btn addUserbtn addAddressBtn"
                          >
                            {view} Role
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col xs={24} className="mt-2 p-1">
                    <h1>Existing Roles</h1>
                    <div className="existingRolesContainer p-2">
                      <RolesList
                        setActiveIndex={setSelectedRoleIndex}
                        refresh={refresh}
                        roles={roles}
                        handleEditRole={handleEditRole}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <AccessControl id={51}>
            <Col xs={24} sm={24} md={24} lg={12} className="addRoleRight">
              {selectedRoleIndex > -1 && all_permissions && (
                <>
                  <div className="addRoleRightHeading mb-2">
                    {roles[selectedRoleIndex].name} Right Manage
                  </div>

                  <Collapse accordion ghost>
                    {Object.keys(all_permissions).map((item, index) => {
                      console.log(all_permissions);
                      const sub_modules: readonly any[] | undefined = [];
                      const operations = [];
                      const table_columns = [create_column("name")];
                      Object.keys(all_permissions[item]).map(
                        (sub_module, module_index) => {
                          const obj = { name: sub_module };
                          all_permissions[item][sub_module].map(
                            (permission, permission_index) => {
                              obj[permission.name] = permission;

                              if (!operations.includes(permission.name)) {
                                operations.push(permission.name);
                                table_columns.push(
                                  create_column(permission.name)
                                );
                              }
                            }
                          );
                          sub_modules.push(obj);
                        }
                      );

                      return (
                        <Panel header={item.toUpperCase()} key={index}>
                          <Table
                            pagination={false}
                            dataSource={sub_modules}
                            columns={table_columns}
                          />
                        </Panel>
                      );
                    })}
                  </Collapse>
                </>
              )}
            </Col>
          </AccessControl>
        </Row>
      </>
    </AccessControl>
  );
}

const RolesList = ({ refresh, roles, setActiveIndex, handleEditRole }: any) => {
  const handleRoleSwitch = async (isActive: boolean, id: number) => {
    try {
      await API_SERVICE.adminRoleActiveInactiveToogleCall(id, {
        isActive,
      });
      notification.success({
        message: `Role ${isActive ? "Activated" : "Deactivated"} Successfully`,
        placement: "bottomRight",
      });
    } catch (error) {
      notification.error({
        message: "Something Went Wrong",
        placement: "bottomRight",
      });
    } finally {
      refresh();
    }
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={roles}
      renderItem={(item: any, index) => (
        <List.Item
          onClick={() => setActiveIndex(index)}
          actions={[
            <Switch
              onChange={(isActive) => handleRoleSwitch(isActive, item.id)}
              checkedChildren="ON"
              unCheckedChildren="OFF"
              checked={item.isActive}
              disabled={DEFAULT_USER_ROLES.includes(item.id)}
            />,
            <AccessControl id={55}>
              <Button
                onClick={() => handleEditRole(item.id)}
                shape="circle"
                className="action-btn"
                disabled={DEFAULT_USER_ROLES.includes(item.id)}
              >
                <EditOutlined />
              </Button>
            </AccessControl>,
          ]}
        >
          {item.name}
        </List.Item>
      )}
    ></List>
  );
};
