import { InboxOutlined } from "@material-ui/icons";
import { Button, notification } from "antd";
import { ShoppingOutlined } from "@ant-design/icons";

import SalesCartContext from "context/SaleCart";
import { useContext } from "react";
import API_SERVICE from "services/api-service";
import "./AddToCartBtn.scss";

interface Props {
  name: string;
  productId: number;
  orderCoupon: null | string;
  quantity: number;
}

const AddToCartBtnSales = (props: Props) => {
  const {
    dealerId,
    cartValues,
    cartProducts,
    pagination,
    total,
    setTotal,
    setPagination,
    createOrder,
    fetchCartDetails,
  } = useContext(SalesCartContext);

  const handleQuantityValue = async (quantity: number, productId: number) => {
    try {
      const params = {
        productId: productId,
        quantity: props.quantity,
        dealerId: dealerId,
      };
      await API_SERVICE.addToCartSales(params);
      notification.success({
        message: `Successfully Added To Cart`,
        placement: "bottomRight",
      });
      fetchCartDetails();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  const addToCart = async () => {
    const filteredArray = cartProducts.filter(
      (item: any) => item.productId === Number(props.productId)
    );
    if (filteredArray.length !== 0) {
      let updatedQuantity = filteredArray[0].quantity + 1;
      if (props?.name === "prodDetails") {
        updatedQuantity = filteredArray[0].quantity + props.quantity;
        handleQuantityValue(updatedQuantity, props.productId);
      } else {
        handleQuantityValue(updatedQuantity, props.productId);
      }
    } else {
      try {
        const params = {
          productId: props.productId,
          quantity: props.quantity,
          dealerId: dealerId,
        };
        console.log(params);
        await API_SERVICE.addToCartSales(params);

        notification.success({
          message: "Successfully Added To Cart",
          placement: "bottomRight",
        });
        fetchCartDetails();
      } catch (err) {
        notification.error({
          message: API_SERVICE.handleErrors(err),
          placement: "bottomRight",
        });
      }
    }
  };
  return (
    <Button
      className="addToCartBtn"
      onClick={() => addToCart()}
      disabled={!dealerId ? true : false}
    >
      <ShoppingOutlined className="icon" />
      Add to Cart
    </Button>
  );
};

export default AddToCartBtnSales;
