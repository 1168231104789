import { LocationOnOutlined } from "@material-ui/icons";
import SearchCard from "components/SearchCard/SearchCard";
import { Suspense, useContext, useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
const { Search } = Input;

import {
  BellOutlined,
  DropboxOutlined,
  HeartOutlined,
  HeartFilled,
  HomeOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuFoldOutlined,
  PhoneOutlined,
  ShoppingOutlined,
  ShoppingFilled,
} from "@ant-design/icons";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import PersonIcon from "@material-ui/icons/Person";
import type { MenuProps } from "antd";
import {
  Badge,
  Button,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
  Form,
} from "antd";
import API_SERVICE from "client/src/services/api-service";
import { getUser } from "client/src/services/local-storage";
import ModalCartProductCard from "components/ModalCartProductCard/ModalCartProductCard";
import NotificationCardModal from "components/NotificationCardModal/NotificationCardModal";
import CartContext from "context/Cart";
import NotificationContext from "context/Notification";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-use";
import logo from "shared/assets/logo.jpg";
import LoaderOverlay from "shared/components/LoaderOverlay";
import {
  CART,
  CATEGORIES,
  DASHBOARD,
  DEALER_PROFILE,
  DEALER_TERMS_AND_CONDITIONS,
  HOME,
  MANAGE_ADDRESS,
  MY_ORDERS,
  NOTIFICATIONS,
  PRIVACY_POLICY,
  SEARCH_RESULT_PAGE,
  WISHLIST,
} from "shared/constants/RouteConstants";
import useWindowDimensions from "shared/hooks/WindowsDimension";
import "shared/style/fonts/icons/style.scss";
import { AuthConsumer, logout } from "../../contexts/AuthContext";
import "./Layout.scss";
const { Header, Footer, Sider, Content } = Layout;
const { Text } = Typography;
const { SubMenu } = Menu;
// import { DirectionsBoatOutlined } from "@material-ui/icons";
// import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

// const onSearch = (value) => console.log(value);

interface LayoutProps {
  selectedMenu?: string | null;
  onMenuClick?: (route: string) => void;
  children: any;
  hasHeader?: boolean;
  logout?: boolean;
  hasLink?: boolean;
  linkText?: string;
  linkUrl?: string;
}
// asdf
export default function DesktopAdminLayout({
  selectedMenu,
  onMenuClick,
  children,
  hasLink,
  linkText,
  linkUrl,
}: LayoutProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [floatBtn, setFloatBtn] = useState(true);
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const windowDimensions = useWindowDimensions();
  const user = getUser();
  let searchTimer: any = undefined;
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const d = new Date();
  let year = d.getFullYear();
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const { cartValue, fetchCartDetails } = useContext(CartContext);
  const [isWishlistEmpty, setIsWishlistEmpty] = useState(true);
  const { totalNoti, fetchNotifications, _fetchUnreadCount } =
    useContext(NotificationContext);
  const items = [
    {
      key: "1",
      label: (
        <Button className="header-btn header-dropdown-btn">
          <PersonIcon />
          <p className="text">Profile Details</p>
        </Button>
      ),
      onClick: () => history.push(DEALER_PROFILE),
    },
    {
      key: "2",
      label: (
        <Button className="header-btn header-dropdown-btn">
          <LocationOnOutlined />
          <p className="text">Manage Address</p>
        </Button>
      ),
      onClick: () => history.push(MANAGE_ADDRESS),
    },
    {
      key: "3",
      label: (
        <Button className="header-btn header-dropdown-btn">
          <DropboxOutlined />
          <p className="text">My Orders</p>
        </Button>
      ),
      onClick: () => history.push(MY_ORDERS),
    },
  ];

  const fetchFilteredProducts = async (query: any) => {
    if (query) {
      const params = {
        q: query,
      };
      const {
        data: {
          data: { items },
        },
      } = await API_SERVICE.getAllProducts(params);
      setFilteredProducts(items);
    } else {
      setFilteredProducts([]);
    }
  };

  const _handleChange = (query: string) => {
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      fetchFilteredProducts(query);
    }, 800);
  };

  const handleSearch = (query: string) => {
    if (query.length == 0) {
      return;
    }
    setSearchVisible(false);
    form.resetFields();
    history.push(SEARCH_RESULT_PAGE + "/" + query);
  };
  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const mobileMenuItems: MenuProps["items"] = [
    getItem("Dashboard", DASHBOARD, <DashboardOutlinedIcon />),
    getItem("Home", HOME, <HomeOutlined />),
    getItem(
      "Notifications",
      NOTIFICATIONS,
      <Badge count={totalNoti}>
        <BellOutlined />
      </Badge>
    ),
    getItem("Wishlist", WISHLIST, <HeartOutlined />),

    getItem("My Account", "myAccount", <PersonIcon />, [
      getItem("Profile Details", DEALER_PROFILE, <PersonIcon />),
      getItem("Manage Address", MANAGE_ADDRESS, <LocationOnOutlined />),
      getItem("My Orders", MY_ORDERS, <DropboxOutlined />),
    ]),
    getItem(
      "My Cart",
      CART,
      <Badge count={cartValue}>
        <ShoppingOutlined />
      </Badge>
    ),
    getItem("Log Out", "logout", <LogoutOutlined />),
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "logout") {
      logout();
    } else {
      history.push(e.key);
      setOpen(false);
    }
  };

  useEffect(() => {
    _fetchUnreadCount();
    fetchNotifications();
    fetchCartDetails();
  }, []);

  useEffect(() => {
    location.pathname === CART ? setFloatBtn(false) : setFloatBtn(true);
  }, [location]);

  return (
    <Layout
      hasSider={false}
      style={{ minHeight: "100vh" }}
      className={`desktop-layout`}
    >
      <Header>
        <Row align="middle" justify="space-between" className="mb-2 mt-3 ">
          <Col xs={16} md={8} lg={8} xl={8} className="header-left">
            <div className="d-flex align-items-center">
              <div className="custom-nav-link header-logo-wrapper">
                <Link to={HOME}>
                  <img src={logo} className="logo" />
                </Link>
              </div>
            </div>
          </Col>
          {windowDimensions.width < 800 && (
            <Col xs={8} className="flex justify-content-end align-items-end">
              <Space>
                <Button className="header-btn" onClick={showDrawer}>
                  <MenuFoldOutlined />
                </Button>
              </Space>
            </Col>
          )}
          {windowDimensions.width >= 800 ? (
            <Col
              className="desktop-menu gutter-row flex justify-content-end align-items-center  "
              xs={24}
              sm={24}
              md={16}
              lg={16}
              xl={16}
            >
              <AuthConsumer>
                {({ isAuth, user }) =>
                  isAuth ? (
                    <>
                      <Link to={HOME}>
                        <Button className="header-btn">
                          <HomeOutlined />
                        </Button>
                      </Link>

                      <div>
                        <div>
                          <Dropdown
                            // trigger={["click"]}
                            dropdownRender={(menu) => <NotificationCardModal />}
                          >
                            <Button className="header-btn">
                              <Badge count={totalNoti}>
                                <BellOutlined />
                              </Badge>
                            </Button>
                          </Dropdown>
                        </div>
                      </div>

                      <Link to={WISHLIST}>
                        <Button className="header-btn">
                          <HeartOutlined />
                        </Button>
                      </Link>
                      <Dropdown menu={{ items }}>
                        <Button className="header-btn">
                          <PersonIcon />
                          {windowDimensions.width > 1150 && (
                            <p className="text">
                              My Account <br /> <span>{user.name}</span>{" "}
                            </p>
                          )}
                        </Button>
                      </Dropdown>

                      <Button
                        onClick={() => history.push(CART)}
                        className="header-btn"
                      >
                        <Badge count={cartValue}>
                          <ShoppingOutlined />
                        </Badge>
                        {windowDimensions.width > 1150 && (
                          <p className="text">
                            My Cart <br /> <span>{cartValue} items</span>
                          </p>
                        )}
                      </Button>
                      <Button
                        onClick={() => logout()}
                        className="header-btn desktop-logout"
                      >
                        <LogoutOutlined />
                        {windowDimensions.width > 1150 && (
                          <p className="text">Logout </p>
                        )}
                      </Button>
                    </>
                  ) : (
                    <Redirect to="/" />
                  )
                }
              </AuthConsumer>
            </Col>
          ) : (
            <>
              <Drawer
                placement={"left"}
                closable={false}
                onClose={onClose}
                width={windowDimensions.width < 500 ? "85%" : "35%"}
                open={open}
              >
                <Menu
                  onClick={onClick}
                  defaultSelectedKeys={["1"]}
                  mode="inline"
                  items={mobileMenuItems}
                />
              </Drawer>
            </>
          )}
        </Row>

        <Row className="mt-1 mb-1 header-bottom-row">
          <Col xs={0} sm={0} md={8} lg={5} xl={3}>
            <Button
              htmlType="submit"
              onClick={() => history.push(DASHBOARD)}
              className="theme-btn flex justify-content-center align-center header-dashboard "
            >
              <DashboardOutlinedIcon className="mr-1" />
              Dashboard
            </Button>
          </Col>
          <Col xs={0} sm={0} md={1} lg={3} xl={3}></Col>
          <Col xs={24} sm={12} md={15} lg={16} xl={9}>
            <Form form={form}>
              <Form.Item name="search">
                <Search
                  size="large"
                  className="header-search"
                  placeholder="What are you looking for?"
                  enterButton
                  onSearch={handleSearch}
                  onChange={(e) => {
                    setSearchVisible(true);
                    _handleChange(e.target.value);
                  }}
                  id="headerSearch"
                />
              </Form.Item>

              <div
                onMouseLeave={() => {
                  setSearchVisible(false);
                  form.resetFields();
                }}
                onClick={() => form.resetFields()}
                className={
                  searchVisible
                    ? "search-suggestions"
                    : "search-suggestions-collapsed"
                }
              >
                {filteredProducts.length !== 0 ? (
                  filteredProducts.map((product) => {
                    return <SearchCard product={product} />;
                  })
                ) : (
                  <>
                    <div className="search-no">No result found</div>
                  </>
                )}
              </div>
            </Form>
          </Col>
        </Row>
        {openCart && (
          <div className="cartModal">
            <div className="cart-modal-header">My Cart ({cartValue} items)</div>
            <ModalCartProductCard />
            <ModalCartProductCard />
            <ModalCartProductCard />

            <div className="coupon-container summary-container">
              <div className="flex justify-content-between mt-3">
                <p className="m-0 pb-2 font-20">
                  <b> Price (1,435)</b>{" "}
                </p>
                <p className="m-0 font-20">
                  <b>$200</b>
                </p>
              </div>
              <Divider className="divider" />
              <div className="flex justify-content-between pb-3">
                <p className="m-0 font-20">Total Price</p>
                <p className="totalPrice m-0 font-20 ">$200.00</p>
              </div>
            </div>

            <Link to={"/cart"}>
              <Button
                className="theme-btn"
                onClick={() => setOpenCart(!openCart)}
              >
                Checkout
              </Button>
            </Link>
          </div>
        )}
      </Header>

      <Content className={"content-layout"}>
        <Suspense fallback={<LoaderOverlay size="large" loading={true} />}>
          {children}
        </Suspense>
      </Content>

      <Footer className="dealerFooter">
        {floatBtn && cartValue ? (
          <button
            onClick={() => history.push(CART)}
            className=" floatingCartBtn"
          >
            <Badge
              count={cartValue}
              className="floatingCartBadge"
              size="default"
            >
              <ShoppingOutlined className="floatingCartIcon" />
            </Badge>
          </button>
        ) : null}
        <Row className="footer-container" justify="space-between">
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <div className="d-flex align-items-center mb-2">
              <div className="custom-nav-link header-logo-wrapper">
                <Link to={HOME}>
                  <img src={logo} className="logo" />
                </Link>
              </div>
            </div>
            <p className="footer-title">Head Office</p>
            <p className="footer-address">Gate-4, Jebel Ali Free Zone, Dubai</p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={4}>
            <p className="footer-title1">Company</p>
            <ul className="footer-links">
              <li>
                <Link to={CATEGORIES}> Categories</Link>
              </li>
              <li>
                <Link to={MY_ORDERS}> My Order</Link>
              </li>
              <li>
                <Link to={CART}> Cart</Link>
              </li>
            </ul>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={4}>
            <p className="footer-title1">Information</p>
            <ul className="footer-links">
              <li>
                <Link to={DEALER_TERMS_AND_CONDITIONS}>
                  Terms And Conditions
                </Link>
              </li>
              <li>
                <Link to={PRIVACY_POLICY}>Privacy Policy</Link>
              </li>
            </ul>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={4}>
            <p className="footer-title1">Contact</p>
            <ul className="footer-links">
              <li>
                {" "}
                <a href="tel:+97148871234">
                  <PhoneOutlined className="icon" /> (+971) 4 8871234
                </a>
              </li>
              <li>
                <a href="mailto:info@milanoitalysrl.com">
                  <MailOutlined className="icon" /> info@milanoitalysrl.com{" "}
                </a>
              </li>
            </ul>
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <p className="footer-title1 footer-title2">
              Download Shop Mobile App
            </p>

            <Button type="primary" size="large">
              Download
            </Button>
          </Col> */}
        </Row>
        <Row>
          <Col xs={24} className="mt-3">
            <p className="m-0">
              Copyright © {year} Milano. All Rights Reserved.
            </p>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
}
