import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Row,
  Table,
  notification,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import API_SERVICE from "services/api-service";
const { Panel } = Collapse;

function AdminConfiguration() {
  const [currencyChangeForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: "15",
  } as any);
  const [total, setTotal] = useState(10);
  const [loading, setLoading] = useState(false);
  const [expiry, setExpiry] = useState([]);
  const _handleCurrencyChange = async (values: {
    id: number;
    from: string;
    to: string;
    fromRate: number;
    toRate: number;
  }) => {
    try {
      const params = {
        from: values.from,
        to: values.to,
        from_rate: values.fromRate,
        to_rate: values.toRate,
      };

      await API_SERVICE.handleEditCurrencyChange(values.id, params);

      notification.success({
        message: "Currency Updated",
        placement: "bottomRight",
      });
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  const _fetchCurrencyValues = async () => {
    try {
      const {
        data: {
          data: { items },
        },
      } = await API_SERVICE.handleCurrencyChange();

      currencyChangeForm.setFieldsValue({
        ["id"]: items[0]?.id,
        ["from"]: items[0]?.from,
        ["to"]: items[0]?.to,
        ["fromRate"]: items[0]?.fromRate,
        ["toRate"]: items[0]?.toRate,
      });
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };
  const handleDaysLeft = async (values: any) => {
    try {
      const params = {
        title: "Licence Expiry",
        notifiedBeforeDays: values.days,
      };

      await API_SERVICE.daysLeft(params);
      getExpiry();
      notification.success({
        message: "Alert Added",
        placement: "bottomRight",
      });
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };
  const columns = [
    {
      title: "S.N.",
      dataIndex: "key",
      // sorter: (a: any, b: any) => a.serialNumber - b.serialNumber,
      render: (a: any, v: any, index: number) => (
        <div>{(pagination.page - 1) * pagination.perpage + index + 1}</div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Days",
      dataIndex: "notifiedBeforeDays",
    },

    {
      title: "ACTION: ",
      key: "action",
      render: (a: any) => (
        <div className="actionBtns">
          <Button
            shape="circle"
            className="action-btn"
            onClick={() => {
              deleteDaysLeft(a?.id);
            }}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];
  const _handleTableChanges = async (
    pagination: { current: any; pageSize: any },
    filters: any,
    sorter: { order: string; columnKey: any },
    extra: { currentDataSource: any; action: string }
  ) => {
    if (extra.action === "paginate") {
      setPagination({ page: pagination.current, perpage: pagination.pageSize });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };
  const getExpiry = async (
    pagination = {},
    sort = {},
    filters = {},
    search = {}
  ) => {
    const params = {
      ...pagination,
      ...sort,
      ...filters,
      ...search,
    };
    const {
      data: { data },
    } = await API_SERVICE.daysLeftGet(params);
    setExpiry(data);
    setTotal(data?.total);
  };
  const deleteDaysLeft = async (id: any) => {
    try {
      await API_SERVICE.deleteDaysLeft(id);
      notification.success({
        message: `Alert Deleted`,
        placement: "bottomRight",
      });
      getExpiry();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    _fetchCurrencyValues();
    getExpiry();
  }, []);

  return (
    <>
      <Row
        className="align-items-center stock-summary-container mb-2 mt-2"
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}
        style={{ padding: "10px" }}
      >
        <Col xs={24}>
          <div className="manage-products-title">Configurations</div>
        </Col>
        <Col xs={24}>
          <Collapse
            accordion
            defaultActiveKey={["1"]}
            className="site-collapse-custom-collapse outerCollapseCategory"
          >
            <Panel header="Set Currency Conversion Rate" key="1">
              <Form
                form={currencyChangeForm}
                layout="inline"
                className="m-2"
                onFinish={_handleCurrencyChange}
              >
                <Form.Item name="id" hidden>
                  <Input disabled />
                </Form.Item>{" "}
                <Form.Item name="from" label="From">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="to" label="To">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="fromRate" label="From Rate">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="toRate" label="To Rate">
                  <InputNumber />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit">Update</Button>
                </Form.Item>
              </Form>
            </Panel>
          </Collapse>
        </Col>
        <Col xs={24}>
          <Collapse
            accordion
            defaultActiveKey={["1"]}
            className="site-collapse-custom-collapse outerCollapseCategory"
          >
            <Panel header="Send Dealer Trade License Alert" key="1">
              <Form
                form={currencyChangeForm}
                layout="inline"
                className="m-2"
                onFinish={handleDaysLeft}
              >
                <Form.Item name="days" label="Enter Days ">
                  <Input />
                </Form.Item>{" "}
                <Form.Item>
                  <Button htmlType="submit">Add</Button>
                </Form.Item>
              </Form>
            </Panel>
          </Collapse>
        </Col>
        <Col xs={24} lg={12} className="mb-3 mt-2">
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={expiry}
            size="middle"
            pagination={{
              size: "default",
              current: pagination.page,
              pageSize: pagination.perpage,
              total: total,
            }}
            onChange={_handleTableChanges}
          />
        </Col>
      </Row>
    </>
  );
}

export default AdminConfiguration;
