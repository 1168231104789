import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./ManageOrder.scss";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  SearchOutlined,
  BellOutlined,
  DownOutlined,
  ShoppingCartOutlined,
  RightOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import logoTransparent from "shared/assets/logoTransparent.png";
import {
  Layout,
  Menu,
  Row,
  Col,
  Input,
  Badge,
  Dropdown,
  Space,
  Progress,
  Divider,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Table,
  Pagination,
} from "antd";
import { notification } from "antd";
import { useEffect } from "react";
import moment from "moment";

import AdminListLayout from "shared/components/layout/AdminListLayout/AdminListLayout";
import API_SERVICE from "services/api-service";
import {
  consverPriceToInternationalFormat,
  textTransform,
} from "services/util";
import AccessControl from "services/AccessControl";

const { Header, Sider, Content } = Layout;

// const { RangePicker } = DatePicker;

interface Props {
  location: any;
}

const ManageOrder = () => {
  let history = useHistory();
  const [filteredData, setFilteredData] = useState([]);
  const [filterAttributes, setFilterAttributes] = useState({});

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: "15",
  } as any);
  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);

  const viewOrderClick = (id?: number) => {
    history.push(`order-details/${id}`, id);
  };

  const _handleSearch = (event) => {
    if (event.which === 13) {
      setSearch({ q: event.target.value });
      setPagination((prev) => {
        return { page: 1, perpage: prev.perpage };
      });
      fetchOrders({ page: 1, perpage: pagination.perpage }, sort, filters, {
        q: event.target.value,
      });
    }
  };

  const getAllAttributes = async () => {
    const {
      data: { data },
    } = await API_SERVICE.fetchSalesManageOrderAttributes();
    setFilterAttributes(data);
  };

  const fetchOrders = async (
    pagination = {},
    sort = {},
    filters = {},
    search = {}
  ) => {
    try {
      const params = {
        ...pagination,
        ...sort,
        ...filters,
        ...search,
      };
      const data = await API_SERVICE.fetchOrders(params);
      setLoading(false);
      if (data) {
        setFilteredData(data.data.data.items);
        setTotal(data?.data?.data?.total);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  const _handleTableChanges = async (
    pagination,
    filters,
    sorter,
    extra: { currentDataSource; action }
  ) => {
    if (extra.action === "paginate") {
      setPagination({
        page: pagination.current,
        perpage: pagination.pageSize,
      });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };

  useEffect(() => {
    fetchOrders(pagination, sort, filters, search);
  }, [sort, filters, pagination]);

  useEffect(() => {
    getAllAttributes();
  }, []);

  const columns = [
    {
      title: "S.N.",
      dataIndex: "serialNumber",
      // sorter: (a: any, b: any) => a.serialNumber - b.serialNumber,
      render: (a: any, v: any, index: number) => (
        <div>{(pagination.page - 1) * pagination.perpage + index + 1}</div>
      ),
    },
    {
      title: "ORDER ID : ",
      key: "orderNumber",
      // dataIndex: "orderDetails",
      className: "orderid",
      width: 120,
      filters:
        filterAttributes && filterAttributes.orderNumber
          ? filterAttributes?.orderNumber.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,

      render: (d: any) => {
        return (
          <div
            onClick={() => {
              viewOrderClick(d?.id);
            }}
          >
            {d?.orderNumber}
          </div>
        );
      },
    },
    {
      title: "PLACED ON : ",
      className: "placedon",
      key: "date",
      filters:
        filterAttributes && filterAttributes.date
          ? filterAttributes?.date.map((item: any) => {
              return {
                text: moment(item.date).format("DD/MM/YYYY"),
                value: item.date,
              };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (d: any) => {
        return moment(d?.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      title: "TOTAL CBM : ",
      dataIndex: "totalCbm",
      key: "totalCbm",
      filters:
        filterAttributes && filterAttributes.totalCbm
          ? filterAttributes?.totalCbm.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
    },
    {
      title: "DEALER NAME & CODE: ",
      dataIndex: "user",
      key: "userId",
      filters:
        filterAttributes && filterAttributes.userId
          ? filterAttributes?.userId.map((item: any) => {
              return {
                text: item.name + " " + item.dealerCode,
                value: item.id,
              };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (d: any) => {
        return (
          <>
            {d.name}
            <br />
            {d.dealerCode}
          </>
        );
      },
    },
    {
      title: "TOTAL AMOUNT : ",
      // dataIndex: "totalAmount",
      render: (d: any) => {
        return (
          <>
            AED {consverPriceToInternationalFormat(d.totalAmount)}
            {/* {d.totalAmount} */}
          </>
        );
      },
    },

    {
      title: "PLACED BY : ",
      dataIndex: "user",
      key: "userId",
      filters:
        filterAttributes && filterAttributes.userId
          ? filterAttributes?.userId.map((item: any) => {
              return {
                text: item.name + " | " + item.email,
                value: item.id,
              };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (d: any) => {
        return (
          <>
            {d.name}
            <br />
            {d.email}
          </>
        );
      },
    },
    {
      title: "STATUS : ",
      key: "status",
      filters:
        filterAttributes && filterAttributes.status
          ? filterAttributes?.status.map((item: any) => {
              return { text: textTransform(item), value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,

      render: (d: any) => {
        return textTransform(d.status);
      },
    },
    {
      title: "ACTION: ",
      // dataIndex: "action",
      render: (a: any) => (
        <Button
          shape="circle"
          onClick={() => {
            viewOrderClick(a.id);
          }}
        >
          <EyeOutlined />
        </Button>
      ),
    },
  ];

  return (
    <AccessControl id={33}>
      <>
        <Row
          className="align-items-center  stock-summary-container mb-2 mt-2"
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
          }}
        >
          <Col xs={12} lg={18}>
            <div className="manage-products-title">Manage Orders</div>
          </Col>
          <Col xs={12} lg={6} className="flex justify-content-end">
            <Input
              onKeyPress={_handleSearch}
              className="admin-search"
              placeholder="Search..."
              prefix={<SearchOutlined />}
            />
          </Col>
        </Row>
        <Row className="manage-order-main-content">
          <Col xs={24} className="mb-3">
            <Table
              scroll={{ x: true }}
              loading={loading}
              columns={columns}
              dataSource={filteredData}
              size="middle"
              pagination={{
                size: "default",
                current: pagination.page,
                pageSize: pagination.perpage,
                total: total,
              }}
              onChange={_handleTableChanges}
            />
          </Col>
        </Row>
      </>
    </AccessControl>
  );
};

export default ManageOrder;
