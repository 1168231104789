import { Add, Delete, Remove } from '@material-ui/icons';
import { Button, notification, Popconfirm } from 'antd';
import CartContext from 'context/Cart';
import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API_SERVICE from 'services/api-service';
import { consverPriceToInternationalFormat } from 'services/util';
import { PRODUCT_DETAILS_PAGE } from 'shared/constants/RouteConstants';
import MilanoThumbnail from '../../assets/MilanoThumbnail.png';
import './index.scss';
import voucherIcon from 'shared/assets/images/voucher.png';
import { Collapse } from 'antd';
const { Panel } = Collapse;
interface Props {
  cart: {
    free: boolean;
    quantity: number;
    productId: number;
    freeQuantity: number;
    product: {
      name: string;
      skuId: string;
      cbmValue: number;
      price: number;
      cartoonQuantity: string;
      uomCode: string;
      buyGetOffers: [
        {
          id: number;
          buyQty: number;
          getWty: number;
        }
      ];
      thumbnail: {
        url: string | null;
        name: string;
      };
    };
  };
  appliedCoupon: boolean;
  setDiscountDetails: Function;
  setCouponCode: Function;
  setAppliedCoupon: Function;
}

const CheckoutProductCard = (props: Props) => {
  const { fetchCartDetails, cartProducts, pagination, setPagination } =
    useContext(CartContext);
  const [quantity, setQuantity] = useState(props.cart.quantity);
  const quantityValue = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user')!);
  const currency = user?.user.dealerDetails[0]?.currencyType;

  const handleQuantityValue = async (quantity: number, productId: number) => {
    try {
      await API_SERVICE.addToCart({
        productId,
        orderCoupon: null,
        quantity,
      });
      notification.success({
        message: `Updated quantity`,
        placement: 'bottomLeft',
      });
      fetchCartDetails();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: 'bottomLeft',
      });
    }
  };

  // const handleQuantity = (action: string, productId: number) => {
  //   if (action === 'minus') {
  //     if (quantity > parseInt(props.cart.product.cartoonQuantity)) {
  //       setQuantity(quantity - parseInt(props.cart.product.cartoonQuantity));
  //       handleQuantityValue(
  //         quantity - parseInt(props.cart.product.cartoonQuantity),
  //         productId
  //       );
  //     }
  //     if (quantity === parseInt(props.cart.product.cartoonQuantity)) {
  //       handleDelete(productId);
  //     }
  //   } else {
  //     setQuantity(
  //       (prev) => prev + parseInt(props.cart.product.cartoonQuantity)
  //     );
  //     handleQuantityValue(
  //       quantity + parseInt(props.cart.product.cartoonQuantity),
  //       productId
  //     );
  //   }
  //   props.setAppliedCoupon(false);
  //   props.setCouponCode(null);
  //   props.setDiscountDetails({
  //     totalAmount: 0,
  //     discountAmount: 0,
  //     totalAmountAfterDiscount: 0,
  //   });
  // };

  const handleQuantity = (action: string, productId: number) => {
    if (action === 'minus') {
      if (quantity > 1) {
        setQuantity(quantity - 1);
        handleQuantityValue(quantity - 1, productId);
      }
      if (quantity === 1) {
        handleDelete(productId);
      }
    } else {
      setQuantity((prev) => prev + 1);
      handleQuantityValue(quantity + 1, productId);
    }
    props.setAppliedCoupon(false);
    props.setCouponCode(null);
    props.setDiscountDetails({
      totalAmount: 0,
      discountAmount: 0,
      totalAmountAfterDiscount: 0,
    });
  };

  const handleDelete = async (productId: number) => {
    try {
      await API_SERVICE.deleteCartProduct(productId);
      notification.success({
        message: `Product Deleted Successfully`,
        placement: 'bottomLeft',
      });
      if (cartProducts.length === 1) {
        let page = pagination.page - 1;
        setPagination((prev: { page: number }) => {
          return prev.page - 1;
        });
        fetchCartDetails(page);
      } else {
        fetchCartDetails(pagination.page);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: 'bottomLeft',
      });
    } finally {
      props.setAppliedCoupon(false);
      props.setCouponCode(null);
      props.setDiscountDetails({
        totalAmount: 0,
        discountAmount: 0,
        totalAmountAfterDiscount: 0,
      });
    }
  };

  const handleClick = () => {
    history.push(
      `${PRODUCT_DETAILS_PAGE}/${props.cart.productId}/${props.cart.product.category.name}`
    );
  };

  function calculateCartonsAndProducts(
    totalQuantity: number,
    productsPerCarton: number
  ) {
    if (productsPerCarton > 1) {
      const cartons = Math.floor(totalQuantity / productsPerCarton);
      const remainingProducts = totalQuantity % productsPerCarton;

      return `${cartons} Carton${
        cartons !== 1 ? 's' : ''
      } and ${remainingProducts} ${props.cart.product.uomCode}`;
    }
    return null;
  }

  function getCartons(totalQuantity: number, productsPerCarton: number) {
    const cartons = Math.floor(totalQuantity / productsPerCarton);

    return cartons;
  }

  return (
    <div className="cart-product-card checkout-product-card">
      <div className="image checkout-card-image">
        <img
          onClick={handleClick}
          src={
            props.cart.product.thumbnail !== null
              ? props.cart.product.thumbnail?.url
              : MilanoThumbnail
          }
          alt={props.cart.product?.thumbnail?.name}
          style={{ objectFit: 'cover' }}
        />
      </div>
      <div className="details checkout-card-details">
        <p onClick={handleClick} className="title">
          {props.cart.product?.name}
        </p>
        <div className="sku">
          <p> SKU: {props.cart.product?.skuId}</p>
          {/* <p>CBM:{props.cart.product?.cbmValue}</p> */}
        </div>
        {props.cart.product.cartoonQuantity > 1 && (
          <p className="product-detail-offer-text">
            This product is sold in a set of{' '}
            {props.cart.product.cartoonQuantity}
          </p>
        )}
        {props.cart.product?.buyGetOffers &&
          props.cart.product?.buyGetOffers.slice(0, 2).map((item: any) => {
            return (
              <p key={item.id} className="product-detail-offer-text">
                <img src={voucherIcon} className="icon" alt="icon" /> &nbsp;
                {quantity === props.cart.freeQuantity ? (
                  <>
                    Offer Applied ( {item.buyQty} Get {item.getQty} Free)
                  </>
                ) : (
                  <>
                    Buy {item.buyQty} of this product and get {item.getQty}{' '}
                    absolutely free
                    <br />
                    {Number(props.cart.product?.cartoonQuantity) > 1 && (
                      <span>
                        &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&emsp;(Buy{' '}
                        {getCartons(
                          item.buyQty,
                          Number(props.cart.product.cartoonQuantity)
                        )}{' '}
                        carton
                        {getCartons(
                          item.buyQty,
                          Number(props.cart.product.cartoonQuantity)
                        ) > 1
                          ? 's'
                          : ''}{' '}
                        of this product and get{' '}
                        {getCartons(
                          item.getQty,
                          Number(props.cart.product.cartoonQuantity)
                        )}{' '}
                        carton
                        {getCartons(
                          item.getQty,
                          Number(props.cart.product.cartoonQuantity)
                        ) > 1
                          ? 's'
                          : ''}{' '}
                        absolutely free)
                      </span>
                    )}
                  </>
                )}
              </p>
            );
          })}
        {props.cart.product?.buyGetOffers &&
          props.cart.product?.buyGetOffers.length > 2 && (
            <Collapse className="cart-collapse mb-2">
              <Panel header="View All Offers" key="1">
                {props.cart.product?.buyGetOffers.slice(2).map((item: any) => {
                  return (
                    <p key={item.id} className="product-detail-offer-text ">
                      {props.cart.free ? (
                        <>
                          <img src={voucherIcon} className="icon" alt="icon" />{' '}
                          &nbsp; Offer Applied ( {item.buyQty} Get {item.getQty}{' '}
                          Free)
                        </>
                      ) : (
                        <>
                          <img src={voucherIcon} className="icon" alt="icon" />{' '}
                          &nbsp; Buy {item.buyQty} of this product and get{' '}
                          {item.getQty} absolutely free
                          <br />
                          {Number(props.cart.product?.cartoonQuantity) > 1 && (
                            <span>
                              &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&emsp;(Buy{' '}
                              {getCartons(
                                item.buyQty,
                                Number(props.cart.product.cartoonQuantity)
                              )}{' '}
                              carton
                              {getCartons(
                                item.buyQty,
                                Number(props.cart.product.cartoonQuantity)
                              ) > 1
                                ? 's'
                                : ''}{' '}
                              of this product and get{' '}
                              {getCartons(
                                item.getQty,
                                Number(props.cart.product.cartoonQuantity)
                              )}{' '}
                              carton
                              {getCartons(
                                item.getQty,
                                Number(props.cart.product.cartoonQuantity)
                              ) > 1
                                ? 's'
                                : ''}{' '}
                              absolutely free)
                            </span>
                          )}
                        </>
                      )}
                    </p>
                  );
                })}
              </Panel>
            </Collapse>
          )}
        {props.appliedCoupon ? (
          <div
            className={`${
              props.cart.free && 'pointer-events-none'
            } quantity checkout-card-quantity  m-0`}
          >
            <Popconfirm
              title="Applied Coupon will be removed. Are you sure you want to continue ?"
              onConfirm={() => handleQuantity('minus', props.cart.productId)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="minus">
                <Remove />
              </Button>
            </Popconfirm>
            <input
              className="value"
              value={quantity}
              onChange={(e) => {
                setQuantity(Number(e.target.value));
              }}
              onBlur={() => {
                handleQuantityValue(quantity, props.cart.productId);
              }}
              // onKeyPress={(event) => {
              //   if (event.which === 13) {
              //     handleQuantityValue(quantity, props.cart.productId);
              //   }
              // }}
            />
            <Popconfirm
              title="Applied Coupon will be removed. Are you sure you want to continue ?"
              onConfirm={() => handleQuantity('minus', props.cart.productId)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="plus">
                <Add />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <div className="quantity-div-checkout">
            <div
              className={`${
                props.cart.free && 'pointer-events-none'
              } quantity checkout-card-quantity  m-0`}
            >
              {!props.cart.free ? (
                <Button
                  className="minus"
                  onClick={() => handleQuantity('minus', props.cart?.productId)}
                >
                  <Remove />
                </Button>
              ) : (
                ''
              )}
              <input
                className="value"
                value={quantity}
                onChange={(e) => {
                  setQuantity(Number(e.target.value));
                }}
                onBlur={(e) => {
                  // if (
                  //   Number(e.target.value) %
                  //     Number(props.cart.product.cartoonQuantity) !=
                  //   0
                  // ) {
                  //   notification.error({
                  //     message: `Quantity must be a multiple of ${props.cart.product.cartoonQuantity}`,
                  //     placement: 'bottomLeft',
                  //   });
                  //   setQuantity(props.cart.quantity);
                  // } else {
                  handleQuantityValue(quantity, props.cart.productId);
                  // }
                }}
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    // if (
                    //   Number(e.target.value) %
                    //     Number(props.cart.product.cartoonQuantity) !=
                    //   0
                    // ) {
                    //   notification.error({
                    //     message: `Quantity must be a multiple of ${props.cart.product.cartoonQuantity}`,
                    //     placement: 'bottomLeft',
                    //   });
                    //   setQuantity(props.cart.quantity);
                    // } else {
                    handleQuantityValue(quantity, props.cart.productId);
                  }
                  // }
                }}
                ref={quantityValue}
              />
              {!props.cart.free ? (
                <Button
                  className="plus"
                  onClick={() => handleQuantity('add', props.cart?.productId)}
                >
                  <Add />
                </Button>
              ) : (
                ''
              )}
            </div>
            <h5 className="carton-product-text text-left ml-5">
              {calculateCartonsAndProducts(
                quantity,
                Number(props.cart.product.cartoonQuantity)
              )}
            </h5>
          </div>
        )}
      </div>

      <div className="cancel checkout-card-cancel">
        <p className="price">
          {currency === 'USD' ? '$' : 'AED'}{' '}
          {consverPriceToInternationalFormat(props.cart.product?.price)}
        </p>
        <div
          className="sku"
          style={{
            flex: 2,
            fontSize: '16px',
            color: '#808080',
          }}
        >
          <p>
            SUB-TOTAL : {currency === 'USD' ? '$' : 'AED'}{' '}
            {props.cart.product?.price * quantity}
          </p>
        </div>
        {!props.cart.free && (
          <>
            {props.appliedCoupon ? (
              <Popconfirm
                title="This action will remove Applied Coupon ? Are you sure ?"
                onConfirm={() => {
                  handleDelete(props.cart?.productId);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button className="theme-btn1 ">
                  <Delete className="icon" />
                </Button>
              </Popconfirm>
            ) : (
              <Button className="theme-btn1 ">
                <Delete
                  className="icon"
                  onClick={() => handleDelete(props.cart?.productId)}
                />
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CheckoutProductCard;
