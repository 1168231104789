import { notification, Row, Col } from "antd";
import MilanoThumbnail from "../../assets/MilanoThumbnail.png";
import { useHistory } from "react-router-dom";
import { ShoppingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import API_SERVICE from "services/api-service";
import { PRODUCT_DETAILS_PAGE } from "shared/constants/RouteConstants";
import "./index.scss";
import { useContext } from "react";
import CartContext from "context/Cart";
import { getUser } from "services/local-storage";

const SearchCard = (props: any) => {
  const { fetchCartDetails } = useContext(CartContext);
  const history = useHistory();
  const user = getUser();
  let currency = "";

  if (!props.add) {
    currency = user?.user?.dealerDetails[0]?.currencyType;
  }

  const addProduct = async () => {
    try {
      const productDetails = [
        {
          productId: props?.product?.id,
          quantity: 1,
        },
      ];
      await API_SERVICE.addProductInOrder(props.orderId, {
        productDetails: productDetails,
      });

      notification.success({
        message: "Successfully Added To Cart",
        placement: "topRight",
      });
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "topRight",
      });
    }
  };
  const addToCart = async () => {
    try {
      await API_SERVICE.addToCart({
        productId: props.product.id,
        orderCoupon: props.orderCoupon,
        quantity: 1,
      });

      notification.success({
        message: "Successfully Added To Cart",
        placement: "bottomLeft",
      });
      fetchCartDetails();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };

  console.log("props.product.category.name", props.product.category.name);
  const handleClick = () => {
    history.push(
      PRODUCT_DETAILS_PAGE +
        "/" +
        props.product.id +
        "/" +
        props.product.category.name.replaceAll("/", "%2F")
    );
  };

  return (
    <div className="searchcard p-2">
      <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
        <Col
          className="gutter-row flex align-items-center"
          xs={5}
          sm={5}
          md={5}
          lg={5}
        >
          <img
            className="photo img-fluid search-card-image-height"
            onClick={() => props.add !== true && handleClick()}
            src={props?.product?.thumbnail?.url || MilanoThumbnail}
          />
        </Col>
        <Col
          className="gutter-row"
          xs={15}
          sm={15}
          md={15}
          lg={15}
          onClick={() => props.add !== true && handleClick()}
        >
          <div className="searchcard-details">{props.product?.name}</div>
          {props.add == true ? (
            <div className="searchcard-price">{props.product?.price}</div>
          ) : (
            <div className="searchcard-price">
              {props.add === true ? "AED" : currency === "USD" ? "$" : "AED"}{" "}
              {props.product?.price}
            </div>
          )}
        </Col>
        <Col
          className="gutter-row  flex align-items-center"
          xs={4}
          sm={4}
          md={4}
          lg={4}
        >
          {props.add === true ? (
            <div>
              <PlusCircleOutlined
                onClick={() => {
                  addProduct();
                }}
              />
            </div>
          ) : (
            <div>
              <ShoppingOutlined onClick={addToCart} />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SearchCard;
