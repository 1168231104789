//@ts-nocheck
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Layout,
  Modal,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import Chart from "react-apexcharts";
import arrow from "../../../assets/icons/insight-box-arrow.png";
import "./index.scss";
import MilanoThumbnail from "../../../assets/MilanoThumbnail.png";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SALES_ORDER_APPROVAL } from "shared/constants/RouteConstants";
import {
  CalendarOutlined,
  DownOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { ArrowRightOutlined } from "@material-ui/icons";
import Donut from "components/Donut/Donut";
import DonutSmall from "components/Donut/DonutSmall";
import moment from "moment";
import dayjs from "dayjs";
import API_SERVICE from "services/api-service";
import { consverPriceToInternationalFormat } from "services/util";

import { DollarCircleOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import {
  STATUS_CONFIRMED,
  STATUS_DELIVERED,
  STATUS_LPO_SIGNED,
  STATUS_PROCESSING,
  STATUS_REJECTED,
  STATUS_SHIPPED,
} from "shared/constants/Constants";

export default function SalesDashboard(props: any) {
  const user = JSON.parse(localStorage.getItem("user")!);
  const roleId = user?.user?.roles[0]?.pivot?.roleId;
  const history = useHistory();
  const [form] = Form.useForm();
  const [salesPersonForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fetchRevenue, setFetchRevenue] = useState(false);
  const [fetchOrder, setFetchOrder] = useState(false);
  const [fetchChart, setFetchChart] = useState(false);
  const [assignedDealerData, setAssignedDealerData] = useState([]);
  const [dealerAssignedValue, setDealerAssignedValue] = useState();
  const [assignedSalesPersonData, setAssignedSalesPersonData] = useState([]);
  const [orderChartStatus, setOrderChartStatus] = useState({});
  const [totalRevenue, setTotalRevenue] = useState();
  const [totalOrders, setTotalOrders] = useState();
  const [totalProducts, setTotalProucts] = useState();
  const [selectedDealerIds, setSelectedDealerIds] = useState([]);
  const [selectedSalesPersonIds, setSelectedSalesPersonIds] = useState([]);

  const [chartOrderDetails, setChartOrderDetails] = useState([]);
  const [arr2, setArr2] = useState([]);

  const [topPerformingProducts, setTopPerformingProducts] = useState([]);
  const [topPerformingDealers, setTopPerformingDealers] = useState([]);
  const [topPerformingRange, setTopPerformingRange] = useState({
    month: moment(new Date()).format("MMMM"),
    year: moment(new Date()).format("YYYY"),
  });
  const [topPerformingDealerRange, setTopPerformingDealerRange] = useState({
    month: moment(new Date()).format("MMMM"),
    year: moment(new Date()).format("YYYY"),
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    GetSalesDashboardOrderChartInfo();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const GetAllAssignedDealersDataSalesManager = async () => {
    try {
      setLoading(true);
      const params = {
        page: -1,
      };

      const {
        data: { data: data },
      } = await (roleId === 4
        ? API_SERVICE.GetAllAssignedDealersDataSalesManager(params)
        : API_SERVICE.GetAllAssignedDealersDataSalesPerson(params));

      if (data) setAssignedDealerData(data.items);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      setLoading(false);
    }
  };

  const GetAllAssignedSalesPerson = async () => {
    try {
      setLoading(true);
      const params = {
        page: -1,
      };
      const {
        data: { data: data },
      } = await API_SERVICE.GetAllAssignedSalesPerson(params);
      if (data) setAssignedSalesPersonData(data.items);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      setLoading(false);
    }
  };

  const GetAllAssignedDealersValue = async () => {
    try {
      setLoading(true);
      const { data: data } = await (roleId === 4
        ? API_SERVICE.GetAllAssignedDealersValue()
        : API_SERVICE.GetAllAssignedDealersValueSalesPerson());

      if (data) {
        setDealerAssignedValue(data);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      setLoading(false);
      GetTotalProducts();
    }
  };

  const GetSalesRevenue = async () => {
    try {
      setFetchRevenue(true);
      const params = {};
      if (selectedDealerIds.length !== 0) {
        params["dealerId"] = selectedDealerIds;
      }
      if (selectedSalesPersonIds.length !== 0) {
        params["salesPersonId"] = selectedSalesPersonIds;
      }
      const { data: data } = await API_SERVICE.GetSalesRevenue(params);
      if (data) {
        setTotalRevenue(data);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      setFetchRevenue(false);
    }
  };
  const GetSalesOrder = async () => {
    try {
      setFetchOrder(true);
      const params = {};
      if (selectedDealerIds.length !== 0) {
        params["dealerId"] = selectedDealerIds;
      }
      if (selectedSalesPersonIds.length !== 0) {
        params["salesPersonId"] = selectedSalesPersonIds;
      }
      const { data: data } = await API_SERVICE.GetSalesOrder(params);
      if (data) {
        setTotalOrders(data);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      setFetchOrder(false);
    }
  };

  const GetSalesDashboardOrderChartInfo = async () => {
    try {
      setFetchChart(true);
      const params = {};
      if (selectedDealerIds.length !== 0) {
        params["dealerId"] = selectedDealerIds;
      }
      if (selectedSalesPersonIds.length !== 0) {
        params["salesPersonId"] = selectedSalesPersonIds;
      }
      const { data: data } = await API_SERVICE.GetSalesDashboardOrderChartInfo(
        params
      );
      if (data) {
        setOrderChartStatus(data);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      setFetchChart(false);
    }
  };

  const GetTotalProducts = async () => {
    try {
      setLoading(true);
      const { data: data } = await API_SERVICE.getTotalProducts();
      if (data) {
        setTotalProucts(data);
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const GetApprovalSummaryChartDetails = async () => {
    try {
      const params = {};
      if (selectedDealerIds.length !== 0) {
        params["dealerId"] = selectedDealerIds;
      }
      if (selectedSalesPersonIds.length !== 0) {
        params["salesPersonId"] = selectedSalesPersonIds;
      }
      const {
        data: { data: data },
      } = await API_SERVICE.GetApprovalSummaryChartDetails(params);

      if (data) {
        console.log("chart data", data);
        setChartOrderDetails([
          data.orderApproved,
          data.orderRejected,
          data.orderPending,
        ]);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const GetTopPerformingProducts = async () => {
    try {
      const params = {
        month: topPerformingRange.month,
        year: topPerformingRange.year,
      };
      const { data: data } = await API_SERVICE.GetTopPerformingProducts(params);
      if (data) {
        setTopPerformingProducts(data.data);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };
  const GetTopPerformingDealers = async () => {
    try {
      const params = {
        month: topPerformingDealerRange.month,
        year: topPerformingDealerRange.year,
      };
      const { data: data } = await API_SERVICE.GetTopPerformingDealers(params);
      if (data) {
        setTopPerformingDealers(data.data);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const setOrderApprovalDefaultFilter = (selectedValue: string) => {
    if (selectedValue === "Approved") {
      return [
        STATUS_PROCESSING,
        STATUS_CONFIRMED,
        STATUS_SHIPPED,
        STATUS_DELIVERED,
      ];
    } else if (selectedValue === "Rejected") {
      return [STATUS_REJECTED];
    } else if (selectedValue === "Pending") {
      return [STATUS_LPO_SIGNED];
    } else {
      return [];
    }
  };

  useEffect(() => {
    GetTopPerformingProducts();
  }, [topPerformingRange]);

  useEffect(() => {
    GetTopPerformingDealers();
  }, [topPerformingDealerRange]);

  useEffect(() => {
    GetSalesRevenue();
    GetSalesOrder();
    GetSalesDashboardOrderChartInfo();
    GetApprovalSummaryChartDetails();
  }, [selectedDealerIds, selectedSalesPersonIds]);

  useEffect(() => {
    GetAllAssignedDealersValue();
    GetAllAssignedDealersDataSalesManager();
    GetAllAssignedSalesPerson();
  }, []);

  const _handleSalesPersonChange = (value: string[]) => {
    form.resetFields();
    setSelectedDealerIds([]);
    setSelectedSalesPersonIds(value);
  };
  const _handleDealerChange = (value: string[]) => {
    salesPersonForm.resetFields();
    setSelectedSalesPersonIds([]);
    setSelectedDealerIds(value);
  };

  return (
    <Layout className="dashboard-bg-color salesDashboard">
      <Modal
        title="Total Order Breakdown by Status"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="total-order-modal"
        destroyOnClose={true}
      >
        <Donut orderChartStatus={orderChartStatus} />
      </Modal>
      <Row>
        <Col xs={24} className="salesDashboardDropdownContainer">
          <Form form={form}>
            <Form.Item name="selectedDealer">
              <Select
                showSearch
                maxTagCount="responsive"
                showArrow
                mode="multiple"
                allowClear
                tokenSeparators={[","]}
                placeholder="All Dealers"
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  ((option?.label).toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                onChange={_handleDealerChange}
                options={assignedDealerData.map((item: any) => {
                  return {
                    value: item.dealerId,
                    label: item.dealerName,
                  };
                })}
                suffixIcon={<DownOutlined />}
                className="salesDashboardDropdown"
              />
            </Form.Item>
          </Form>
          {user?.user?.roles[0]?.pivot?.roleId === 4 && (
            <Form form={salesPersonForm} className="salesDashboardDropdownForm">
              <Form.Item name="selectedSalesPerson">
                <Select
                  showArrow
                  className="salesDashboardDropdown "
                  showSearch
                  tokenSeparators={[","]}
                  maxTagCount="responsive"
                  suffixIcon={<DownOutlined />}
                  mode="multiple"
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    ((option?.label).toLowerCase() ?? "").includes(
                      input.toLowerCase()
                    )
                  }
                  placeholder="All Sales Person"
                  onChange={_handleSalesPersonChange}
                  options={assignedSalesPersonData.map((item: any) => {
                    return {
                      value: item.id,
                      label: item.name,
                    };
                  })}
                />
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>

      <Row className="dealer-dashboard-inner-padding">
        <Col xs={24} sm={24} md={24} lg={24} xl={19}>
          <div className="dashboard-admin-row mb-2">
            <div className="dashboard-box dealer-box">
              <div className="dashboard-box-title">Total Revenue</div>
              <div className="dashboard-box-content">
                {totalRevenue?.data ? "AED" : null}
                {fetchRevenue ? (
                  <Spin />
                ) : (
                  consverPriceToInternationalFormat(totalRevenue?.data)
                )}
              </div>
            </div>
            <div className="dashboard-box dashboard-order dashboard-order-chart dealer-box ">
              <div className="dashboard-box-title">Total Orders</div>
              <div className="dashboard-box-content">
                {fetchOrder ? <Spin /> : totalOrders?.data}
              </div>

              <ArrowRightOutlined className="arrow" onClick={showModal} />
              <div className="donut">
                {fetchChart ? (
                  <Spin />
                ) : (
                  <DonutSmall orderChartStatus={orderChartStatus} />
                )}
              </div>
            </div>

            <div className="dashboard-box dealer-box">
              <div className="dashboard-box-title">Dealers Assigned </div>
              <div className="dashboard-box-content">
                {loading ? (
                  <Spin />
                ) : (
                  consverPriceToInternationalFormat(dealerAssignedValue?.data)
                )}
              </div>
            </div>
            <div className="dashboard-box dealer-box">
              <div className="dashboard-box-title">Total Products</div>
              <div className="dashboard-box-content">
                {" "}
                {loading ? (
                  <Spin />
                ) : (
                  consverPriceToInternationalFormat(totalProducts?.data)
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={5}
          className="salesDashboardBtns mb-2"
        >
          <Button className="salesDasboardBtn">
            <DollarCircleOutlined />
            Revenue Summary <ArrowRightOutlined className="icon" />
          </Button>
          <Button className="salesDasboardBtn">
            <ShoppingCartOutlined />
            Stock Summary <ArrowRightOutlined className="icon" />{" "}
          </Button>
        </Col>
      </Row>

      <Row
        // style={{ margin: 0 }}
        gutter={{ xs: 8, sm: 16 }}
        // justify="space-between"
      >
        <Col
          className="gutter-row"
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={7}
        >
          <div className="insight-box ">
            <div className="insight-box-header">
              <div className="insight-box-title">
                <img className="insight-img" src={arrow} alt="" />
                Top performing Products
                <DatePicker
                  allowClear={false}
                  disabledDate={(current) => {
                    return (
                      current &&
                      current > dayjs().startOf("month").add(1, "month")
                    );
                  }}
                  defaultValue={moment(new Date(), "MMM YYYY")}
                  picker="month"
                  onChange={(value) => {
                    setTopPerformingRange({
                      month: moment(value).format("MMMM"),
                      year: moment(value).format("YYYY"),
                    });
                  }}
                  className="admin-datepicker"
                  suffixIcon={<CalendarOutlined />}
                />
              </div>
            </div>
            <Divider className="divide" />
            <div className="insight-content-box salesInsightBox">
              {topPerformingProducts.length !== 0
                ? topPerformingProducts.slice(0, 5).map((prod: any) => {
                    return <DetailCard product={prod} />;
                  })
                : " No Top Performing Products in this Month :(((("}
            </div>

            <Divider className="divide admin-divide" />
            <Button
              type="text"
              className="insight-button"
              // onClick={() => history.push(MANAGE_PRODUCTS)}
            >
              More Insights <RightOutlined />
            </Button>
          </div>
        </Col>
        <Col
          className="gutter-row"
          xs={24}
          sm={24}
          md={24}
          lg={10}
          xl={9}
          xxl={7}
        >
          <div className="insight-box ">
            <div className="insight-box-header">
              <div className="insight-box-title">
                <img className="insight-img" src={arrow} alt="" />
                Top performing Dealers
                <DatePicker
                  allowClear={false}
                  disabledDate={(current) => {
                    return (
                      current &&
                      current > dayjs().startOf("month").add(1, "month")
                    );
                  }}
                  defaultValue={moment(new Date(), "MMM YYYY")}
                  picker="month"
                  onChange={(value) => {
                    setTopPerformingDealerRange({
                      month: moment(value).format("MMMM"),
                      year: moment(value).format("YYYY"),
                    });
                  }}
                  className="admin-datepicker"
                  suffixIcon={<CalendarOutlined />}
                />
              </div>
            </div>
            <Divider className="divide" />
            <div>
              <div className="insight-content-box salesInsightBox">
                {topPerformingDealers.length !== 0
                  ? topPerformingDealers.slice(0, 5).map((dealer: any) => {
                      return <TopPerformingCard dealer={dealer} />;
                    })
                  : " No Top Performing Dealers in this Month :(((("}
              </div>
            </div>
            <Divider className="divide" />
            <Button
              type="text"
              className="insight-button"
              // onClick={() => history.push(DEALER_LIST)}
            >
              More Insights <RightOutlined />
            </Button>
          </div>
        </Col>
        <Col
          className="gutter-row"
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={16}
          xxl={10}
        >
          {roleId === 4 ? (
            <div className="insight-box salesInsightBoxContainer">
              <div className="insight-box-header">
                <div className="insight-box-title">
                  <img className="insight-img" src={arrow} alt="" />
                  Order Approval Summary
                </div>
              </div>
              <Divider className="divide" />
              <div className="insight-content-box salesInsightBox flex align-items-center justify-content-center">
                <Chart
                  // onClick={() => history.push(SALES_ORDER_APPROVAL)}
                  type="donut"
                  width={500}
                  series={chartOrderDetails}
                  options={{
                    chart: {
                      events: {
                        dataPointSelection: (event, chartContext, config) => {
                          history.push({
                            pathname: SALES_ORDER_APPROVAL,
                            state: setOrderApprovalDefaultFilter(
                              config.w.config.labels[config.dataPointIndex]
                            ),
                          });
                          /*history.push(
                            `AED {" "} {SALES_ORDER_APPROVAL}/AED {" "} {
                              config.w.config.labels[config.dataPointIndex]
                            }`
                          );*/
                        },
                      },
                    },
                    responsive: [
                      {
                        breakpoint: 400,
                        options: {
                          chart: {
                            width: 300,
                          },
                        },
                      },
                    ],
                    colors: ["#6ABC91", "#F9AF11", "#E50035"],
                    labels: ["Approved", "Rejected", "Pending"],
                    plotOptions: {
                      legend: {
                        show: true,
                        showForNullSeries: true,
                        showForZeroSeries: true,
                        horizontalAlign: "center",
                        floating: false,
                        fontSize: "14px",
                        offsetX: 50,
                        offsetY: 0,

                        itemMargin: {
                          horizontal: 5,
                          vertical: 0,
                        },
                        onItemClick: {
                          toggleDataSeries: true,
                        },
                        onItemHover: {
                          highlightDataSeries: true,
                        },
                      },
                      pie: {
                        donut: {
                          labels: {
                            show: true,
                            total: {
                              show: true,
                              fontSize: 16,
                              label: "total",
                              color: "#808080",
                            },
                            value: {
                              fontSize: 26,
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                  }}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Layout>
  );
}

function DetailCard(props: any) {
  return (
    <Row
      className="detail-card"
      style={{ margin: 0 }}
      align="middle"
      justify="space-between"
    >
      <Col xs={17} sm={17} lg={18}>
        <Row align="middle">
          <Col xs={5}>
            <img
              className="photo insight-photo"
              src={props.product?.url || MilanoThumbnail}
              alt="product-image"
            />
          </Col>
          <Col xs={18}>
            <div className="detailCard-title">
              {props.product?.name}
              <p>SKU {props.product?.skuId}</p>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={7} sm={7} lg={6}>
        <div className="detailCard-number">{props.product?.count} Nos</div>
      </Col>
    </Row>
  );
}

function TopPerformingCard(props: any) {
  return (
    <Row
      className="detail-card"
      style={{ margin: 0 }}
      align="middle"
      justify="space-between"
    >
      <Col>
        <Row align="middle">
          <Col>
            <img
              className="photo top-performing"
              src={props.dealer?.url || MilanoThumbnail}
            />
          </Col>
          <Col>
            <div className="detailCard-title">
              {props.dealer?.name}
              <p>{props.dealer?.customerId}</p>
            </div>
          </Col>
        </Row>
      </Col>
      <Col>
        <div className="detailCard-number">
          AED {consverPriceToInternationalFormat(props.dealer?.revenue)}{" "}
        </div>
      </Col>
    </Row>
  );
}
