import * as React from "react";

import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import {
  Badge,
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { useState } from "react";
import logoTransparent from "../../../../client/src/assets/milanoAdminLogo.png";
import "./index.scss";

import {
  AppstoreOutlined,
  BellOutlined,
  DownOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LayoutOutlined,
  CodeSandboxOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserAddOutlined,
  UsergroupDeleteOutlined,
  ClusterOutlined,
  FileDoneOutlined,
  ShoppingCartOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import PersonIcon from "@material-ui/icons/Person";

import {
  ADD_ROLE,
  ADD_USER,
  ADMIN_CONFIGURATION,
  ADMIN_DASHBOARD,
  ADMIN_PRIVACY_POLICY,
  ADMIN_PROFILE,
  ADMIN_TERMS_AND_CONDITIONS,
  BANNER_LIST,
  BANNER_MANAGEMENT,
  CUSTOM_NOTIFICATION,
  DEALER_LIST,
  LOGIN_PAGE_MANAGEMENT,
  MANAGE_ORDER,
  MANAGE_PRODUCTS,
  OFFER_LIST,
  STOCK_LABEL,
  UPDATE_CATEGORY,
  USER_MANAGEMENT,
} from "../../../constants/RouteConstants";
import { logout } from "../../../contexts/AuthContext";
import useWindowDimensions from "../../../hooks/WindowsDimension";
import NotificationCardModal from "components/NotificationCardModal/NotificationCardModal";
import AdminNotificationCard from "components/AdminNotificationCard/AdminNotificationCard";
import AdminNotificationCardModal from "components/AdminNotificationCard/AdminNotificationCard";
import AccessControl, { accessIncludes } from "services/AccessControl";
import NotificationContext from "context/Notification";

const { Title } = Typography;

type MenuItem = Required<MenuProps>["items"][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

interface LayoutProps {
  children: any;
  title?: any;
  titleIcon?: any;
  titleSearch?: any;
  titleAction?: any;
  onMenuClick?: (route: string) => void;
  panel?: any;
  pagination: any;
  filters: any;
}

const { Header, Footer, Sider, Content } = Layout;

export default function AdminListLayout({
  children,
  titleSearch,
  titleIcon,
  titleAction,
  title,
  panel,
  pagination,
  filters,
}: LayoutProps) {
  const items: MenuProps["items"] = [
    accessIncludes([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]) &&
      getItem("Dashboard", `${ADMIN_DASHBOARD}`, <AppstoreOutlined />),

    accessIncludes([12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]) &&
      accessIncludes([
        12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
      ]) &&
      getItem(
        "Banner Management",
        "banner",
        <PhotoLibraryIcon className="admin-sidebar-icon" />,
        [
          accessIncludes([12, 16, 19, 23]) &&
            getItem(
              "Home Slider",
              `${BANNER_LIST}`,
              <PhotoLibraryIcon className="admin-sidebar-icon" />
            ),

          accessIncludes([13, 20]) &&
            getItem(
              "Featured Product",
              `${BANNER_MANAGEMENT}/featured-banner`,
              <PhotoLibraryIcon className="admin-sidebar-icon" />
            ),

          accessIncludes([14, 17, 21, 24]) &&
            getItem(
              "3 Banner Section",
              `${BANNER_MANAGEMENT}/three-banner-section`,
              <PhotoLibraryIcon className="admin-sidebar-icon" />
            ),
          accessIncludes([15, 18, 22, 25]) &&
            getItem(
              "Bottom Banner",
              `${BANNER_MANAGEMENT}/bottom-banner`,
              <PhotoLibraryIcon className="admin-sidebar-icon" />
            ),
          getItem(
            "Login Banner",
            `${LOGIN_PAGE_MANAGEMENT}`,
            <PhotoLibraryIcon className="admin-sidebar-icon" />
          ),
        ]
      ),

    accessIncludes([26, 27, 28, 29, 30, 31, 32]) &&
      getItem("Catalogue", "catalogue", <LayoutOutlined />, [
        accessIncludes([26, 28, 29, 31, 32]) &&
          getItem("Products", `${MANAGE_PRODUCTS}`, <CodeSandboxOutlined />),
        accessIncludes([27, 30]) &&
          getItem(
            "Categories",
            `${UPDATE_CATEGORY}`,
            <UnorderedListOutlined />
          ),
      ]),

    accessIncludes([49, 50, 51, 52, 53, 54, 55, 56, 57]) &&
      getItem("Users & Roles", "users", <UsergroupAddOutlined />, [
        accessIncludes([49, 52, 54, 57]) &&
          getItem("Add User", `${ADD_USER}`, <UserAddOutlined />),
        accessIncludes([50, 53, 55]) &&
          getItem("Add Role", `${ADD_ROLE}`, <UsergroupDeleteOutlined />),
        accessIncludes([49, 50, 51, 52, 53, 54, 55, 56, 57]) &&
          getItem(
            "User Management",
            `${USER_MANAGEMENT}`,
            <UsergroupAddOutlined />
          ),
      ]),

    accessIncludes([39]) &&
      getItem("Dealers", `${DEALER_LIST}`, <ClusterOutlined />),
    accessIncludes([40]) &&
      getItem("Stock Labels", `${STOCK_LABEL}`, <FileDoneOutlined />),
    accessIncludes([33]) &&
      getItem("Orders", `${MANAGE_ORDER}`, <ShoppingCartOutlined />),
    accessIncludes([37]) &&
      getItem(
        "Notifications",
        `${CUSTOM_NOTIFICATION}`,
        <NotificationOutlined />
      ),
    accessIncludes([38]) &&
      getItem("Offers", `${OFFER_LIST}`, <FileDoneOutlined />),
    accessIncludes([1, 2]) &&
      getItem("Configurations", `${ADMIN_CONFIGURATION}`, <FileDoneOutlined />),
  ];

  //@ts-ignore
  const { totalNoti, _fetchUnreadCount, fetchNotifications } =
    React.useContext(NotificationContext);
  let d = new Date();
  let year = d.getFullYear();
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const showVisible = () => {
    if (visible) setVisible(false);
    else setVisible(true);
  };

  const windowDimensions = useWindowDimensions();

  const history = useHistory();

  const menu = (
    <Menu
      items={[
        {
          key: "0",
          label: (
            <Button className="header-btn header-dropdown-btn">
              <PersonIcon />
              <p className="text">Profile Details</p>
            </Button>
          ),
          onClick: () => history.push(ADMIN_PROFILE),
        },
        {
          key: "1",
          label: (
            <Button className="header-btn header-dropdown-btn">
              <LogoutOutlined />
              <p className="text">Logout</p>
            </Button>
          ),
          onClick: () => logout(),
        },
      ]}
    />
  );

  const onClick: MenuProps["onClick"] = (e: any) => {
    history.push(e.key);
    if (windowDimensions.width < 769) {
      setCollapsed(true);
      setVisible(true);
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));

  //@ts-ignore
  const adminProfilePic =
    JSON.parse(localStorage.getItem("user")!)?.user?.file?.url ||
    "https://api-milano.valuescale.co.in/images/profiles/21ef19fd-a54b-4aef-bcd1-e1be4c569f68.jpg";
  const userName = JSON.parse(localStorage.getItem("user")!)?.user?.name;

  React.useEffect(() => {
    if (windowDimensions.width < 769) {
      setVisible(true);
      setCollapsed(true);
    }

    _fetchUnreadCount();
    fetchNotifications();

    // if (accessIncludes([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])) {
    //   history.push(ADMIN_DASHBOARD);
    //   return;
    // }
    // if (
    //   accessIncludes([12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25])
    // ) {
    //   history.push(BANNER_LIST);
    //   return;
    // }
    // if (accessIncludes([26, 27, 28, 29, 30, 31, 32])) {
    //   history.push(MANAGE_PRODUCTS);
    //   return;
    // }
    // if (accessIncludes([39])) {
    //   history.push(DEALER_LIST);
    //   return;
    // }
    // if (accessIncludes([40])) {
    //   history.push(STOCK_LABEL);
    //   return;
    // }
    // if (accessIncludes([33])) {
    //   history.push(MANAGE_ORDER);
    //   return;
    // }

    // if (accessIncludes([37])) {
    //   history.push(CUSTOM_NOTIFICATION);
    //   return;
    // }
    // if (accessIncludes([38])) {
    //   history.push(OFFER_LIST);
    //   return;
    // }
  }, []);

  return (
    <div className={"admin-list-layout"}>
      <div className="admin-dashboard-main">
        <Layout className="admin-layout">
          <Sider
            className={`${visible ? "admin-sider-right" : "admin-sider-main"}`}
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <div className="admin-sider">
              <div
                className={
                  visible ? "ant-layout-sider-collapsed" : "admin-logo"
                }
              >
                <Link to={ADMIN_DASHBOARD}>
                  <img
                    className={visible ? "ant-layout-sider-collapsed" : ""}
                    src={logoTransparent}
                    alt="logo"
                  />
                </Link>
              </div>
              <div
                className={"admin-sider-title"}
                style={{ display: visible ? "none" : "block" }}
              >
                MENU
              </div>
              <Menu
                onClick={onClick}
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["sub1"]}
                mode="inline"
                theme="dark"
                items={items}
                className="admin-sider-menu"
              />
            </div>
          </Sider>

          <Layout className="site-layout">
            <Header className="site-layout-background p-0">
              <Row
                gutter={{ xs: 8, sm: 16, md: 24 }}
                align="middle"
                justify="space-between"
                className="admin-header"
              >
                <Col
                  xs={visible ? 4 : 24}
                  sm={visible ? 4 : 24}
                  md={6}
                  lg={6}
                  className="admin-header-left"
                >
                  {React.createElement(
                    collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: "trigger",
                      onClick: () => {
                        setCollapsed(!collapsed);
                        setVisible(!visible);
                      },
                    }
                  )}
                  {titleSearch}
                </Col>
                <Col className="flex justify-content-center align-items-center">
                  {user && user.user.roles[0] && (
                    <div className="roleNameStyle">
                      <b>{user.user?.roles[0]?.name} View</b>
                    </div>
                  )}
                </Col>
                <Col
                  className={`gutter-row header-col admin-header-right ${
                    visible ? "admin-right-show" : "admin-right-hide"
                  }`}
                  xs={18}
                  sm={18}
                  md={8}
                  lg={8}
                >
                  {user && user.user.roles[0] && (
                    <b className="roleName">{user.user?.roles[0]?.name} View</b>
                  )}

                  <Dropdown
                    // trigger={["click"]}
                    dropdownRender={(menu) => <AdminNotificationCardModal />}
                  >
                    {/* <Button > */}
                    <Badge count={totalNoti}>
                      <BellOutlined />
                    </Badge>
                    {/* </Button> */}
                  </Dropdown>
                  <div className="admin-avatar-container">
                    <div className="avatar">
                      <img
                        src={
                          adminProfilePic !== ""
                            ? adminProfilePic
                            : "https://avatars.dicebear.com/api/open-peeps/stefan.svg"
                        }
                        alt="user"
                      />
                    </div>
                    <div className="dropdown">
                      <Dropdown
                        className="admin-dropdown"
                        overlay={menu}
                        trigger={["click"]}
                        placement="bottomRight"
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          <Space>
                            <p className="header-name">{userName} </p>
                            <DownOutlined
                              className="menu-down"
                              style={{ width: "10px" }}
                            />
                          </Space>
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
              </Row>
            </Header>
            <Content
              className={`manage-products-main-content ${
                visible ? "content-right-show" : "admin-right-hide"
              }`}
            >
              <Row
                className="align-items-center stock-summary-container mb-2 mt-2"
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                }}
              >
                <div className="manage-order-title">{title}</div>
              </Row>
              {children}
            </Content>
            <Footer className="p-2 admin-footer">
              <span>{year} © Milano</span>
              <ul>
                <li>
                  <Link to={ADMIN_TERMS_AND_CONDITIONS}>
                    Terms And Conditions
                  </Link>
                </li>
                <li>
                  <Link to={ADMIN_PRIVACY_POLICY}>Privacy Policy</Link>
                </li>
              </ul>
            </Footer>
          </Layout>
        </Layout>
      </div>
    </div>
  );
}
