import AXIOS from "axios";
import { logout } from "shared/contexts/AuthContext";
import Parameters from "shared/parameters";

function getPublicInstance() {
  return AXIOS.create({
    // @ts-ignore
    accept: "application/json",
    baseURL: Parameters.ApiUrl,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
}

function getProtectedInstance() {
  let user = localStorage.getItem("user") as string;
  let token = "";
  if (user) {
    token = JSON.parse(user).token;
  } else {
    window.location.href = "/login";
  }
  return AXIOS.create({
    // @ts-ignore
    accept: "application/json",
    baseURL: Parameters.ApiUrl,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
}

function getProtectedFileUpload() {
  let user = localStorage.getItem("user") as string;
  let token = "";
  if (user) {
    token = JSON.parse(user).token;
  } else {
    window.location.href = "/login";
  }
  return AXIOS.create({
    // @ts-ignore
    accept: "application/json",
    baseURL: Parameters.ApiUrl,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
}

function handleErrors(error: any) {
  if (error?.response?.status === 440) {
    logout();
  }
  let message = "Something went wrong!!";
  if (error && error.response && error.response.data) {
    const data = error.response.data;
    if (data.error) {
      message = data.error;
    } else if (data.message) {
      const keys = Object.keys(data.message);
      if (keys.length) {
        message = data.message[keys[0]];
      }
    }
  }
  return message;
}

//auth
async function login(username: string, password: string) {
  const instance = getPublicInstance();
  return await instance.post("/api/authenticate", { username, password });
}

async function forgotPassword(email: string) {
  const instance = getPublicInstance();
  return await instance.post("/api/forgot-password", { email });
}

async function resetPassword(email: any, otp: any) {
  const instance = getPublicInstance();
  return await instance.post("/api/reset-password", { email, otp });
}

async function changePassword(uuid: any, password: string) {
  const instance = getPublicInstance();
  return await instance.post("/api/reset-password", { uuid, password });
}

//Change Admin Password
async function changeAdminPassword(params: any) {
  const instance = getProtectedInstance();
  return await instance.post("/api/change-password", params);
}
//Categories

async function fetchCategories(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/admin/categories", { params });
}

async function fetchCategoriesProducts(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/products`, { params });
}
async function popularFamilyProducts(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/popular-family/products`, { params });
}

//Product Page

async function fetchAttributes(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/attributes`, { params });
}
async function fetchPopularAttributes(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/popular-products-attributes`, { params });
}
async function fetchFeaturedAttributes(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/featured-product-attributes`, { params });
}
async function fetchProductDetails(productId: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/product-details?productId=${productId}`);
}

//Wishlist
async function addToWishList(productId: number) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/wishlist`, { productId });
}

async function fetchWishlist(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/wishlist", { params });
}
async function WishListDeleteAll() {
  const instance = getProtectedInstance();
  return await instance.delete("/api/wishlist-delete");
}

async function WishListDelete(id: any) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/wishlist/${id}`);
}

// //Dealer

async function getStockSummary(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/dashboard/stocksummary`, { params });
}

async function getRevenueSummary(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/dashboard-revenue-summary`, { params });
}

async function NotifyMe(params: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/notify-me`, params);
}

async function downloadOrderStatement() {
  const instance = getProtectedInstance();
  return await instance.get("/api/order-statement");
}

//Cart

async function addToCart({ productId, quantity, orderCoupon }: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/cart`, { productId, quantity, orderCoupon });
}
async function getValidCouponDealer() {
  const instance = getProtectedInstance();
  return await instance.get(`/api/valid-coupons`);
}

async function applyCouponDealer(payload: { couponCode: string }) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/apply-coupons`, payload);
}

async function createOrder({
  deliverySlot,
  billingAddressId,
  shippingAddressId,
  cartId,
  status,
  couponCode = null,
  discountType = null,
  discountAmount = null,
}: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/order`, {
    deliverySlot,
    billingAddressId,
    shippingAddressId,
    cartId,
    status,
    couponCode,
    discountType,
    discountAmount,
  });
}

async function getCartDetails(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/cart`, { params });
}

async function getMyOrder(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/order`, { params });
}

async function getFilteredCartDetails(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/cart`, { params });
}

async function deleteCartProduct(productId: number) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/cart/${productId}`);
}

async function updatePriceCart(params: any) {
  const instance = getProtectedInstance();
  return await instance.post(`api/cart-create`, params);
}
// HOME

//Browser category

async function fetchBrowserCategory(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/admin/categories", { params });
}

//Feature Products

async function fetchFeatureProducts(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/featured-product", { params });
}

//Popular Products

async function fetchPopularProducts(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/popular-product", { params });
}
//Popular Family

async function fetchPopularFamily(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/popular-family", { params });
}

//Dealer Profile

async function dealerProfileUpdate(id: number, details: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/dealer-profile/${id}`, details);
}

//Admin Profile
async function adminProfileUpdate(id: number, details: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/admin/user-profile-update/${id}`, details);
}

// Address

//Address
// async function addAddress(userId:any,name:string,phone:number,address:any,building:string,street:string,area:string,zipcode:number,city:string,country:string) {
//     const instance = getProtectedInstance();
//     return await instance.post(`/api/user-address`,{userId,name,phone,address,building,street,area,zipcode,city,country});
// }

async function GetAddress() {
  const instance = getProtectedInstance();
  return await instance.get("/api/user-address");
}

async function GetUserAttributes() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/user-attributes");
}

async function GetAddressBasedOnId(id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/user-address/${id}`);
}

async function addAddress({
  userId,
  name,
  phone,
  address,
  building,
  street,
  area,
  zipcode,
  city,
  country,
}: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/user-address`, {
    userId,
    name,
    phone,
    address,
    building,
    street,
    area,
    zipcode,
    city,
    country,
  });
}

async function editAddress({
  id,
  name,
  phone,
  address,
  building,
  street,
  area,
  zipcode,
  city,
  country,
}: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/user-address/${id}`, {
    name,
    phone,
    address,
    building,
    street,
    area,
    zipcode,
    city,
    country,
  });
}

async function deleteAddress(id: number) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/user-address/${id}`);
}

//Notification
async function GetNotifcationsCount() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/notifications-count");
}

async function statusChangeNotification(id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/notifications/${id}`);
}

async function GetNotifcations(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/admin/notifications", { params });
}

async function self() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/self");
}

//Dealer Dashboard

async function getTotalRevenueDealer() {
  const instance = getProtectedInstance();
  return await instance.get("/api/dashboard/totalrevenue");
}
async function getTotalOrdersDealer() {
  const instance = getProtectedInstance();
  return await instance.get("/api/dashboard/totalorders");
}

async function getTotalProductsDealer() {
  const instance = getProtectedInstance();
  return await instance.get("/api/dashboard/totalproducts");
}

async function getAvgOrderValueDealer() {
  const instance = getProtectedInstance();
  return await instance.get("/api/dashboard/averageordervalue");
}

async function GetFavouriteProducts(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/dashboard/favoriteproducts", {
    params,
  });
}
async function GetPurchaseSummaryDetails(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/dashboard-purchase-summary", {
    params,
  });
}

async function GetDealerChartDetails() {
  const instance = getProtectedInstance();
  return await instance.get("/api/dashboard-order-summary");
}

// async function otpVerifcation(email: string, otp: string) {
//     const instance = getPublicInstance();
//     return await instance.post('/api/otp', {email, otp});
// }
async function getBanners(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/home-banner", { params });
}

async function getHomeBanners(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/banners", { params });
}

async function bannerActiveInactiveToogleCall(id: number, payload: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/admin/banner-status/${id}`, payload);
}

//Admin
async function getAdminAllUsers(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/admin/users", { params });
}

async function getAdminAllRoles(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/roles", { params });
}

async function getAdminUserById(id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/users/${id}`);
}

async function editAdminUserById(id: number, payload: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/admin/users/${id}`, payload);
}

async function addAdminRole(payload: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/roles`, payload);
}

async function editAdminRole(id: number, payload: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/roles/${id}`, payload);
}

async function getAdminRoleById(id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/roles/${id}`);
}

async function adminActiveInactiveToogleCall(id: number, payload: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/admin/user-status-update/${id}`, payload);
}

async function adminRoleActiveInactiveToogleCall(id: number, payload: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/roles/${id}`, payload);
}

async function getAdminPermissions(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/permissions", { params });
}
async function getAdminBannersList(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/admin/banners", { params });
}
async function getLoginBanner(params: any) {
  const instance = getPublicInstance();
  return await instance.get("/api/login-banner", { params });
}
async function fetchSingleBannerDetails(id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/banners/${id}`);
}

async function getHomeOtherBanner(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/non-home-banners", {
    params,
  });
}

//Admin CustomNotification

async function addCustomNotification(params: any) {
  const instance = getProtectedInstance();
  return await instance.post("/admin/custom-notification", params);
}

async function fetchSingleNotification(id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/custom-notification/${id}`);
}

async function addNewOffer(params: any) {
  const instance = getProtectedInstance();
  return await instance.post("/admin/offer", params);
}

// comment

async function addHomeBanner(params: any) {
  const instance = getProtectedInstance();
  return await instance.post("/admin/banners", params);
}

async function updateHomeBanner(id: number, params: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/admin/banners/${id}`, params);
}

async function deleteHomeBanner(id: number) {
  const instance = getProtectedInstance();
  return await instance.delete(`/admin/banners/${id}`);
}

//admin-dealer
async function fetchDealer(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/dealers`, { params });
}

async function dealerActiveInactiveToogleCall(id: number, payload: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/admin/user-status-update/${id}`, payload);
}

async function updateDealer(id: number, params: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/admin/dealers/${id}`, params);
}

async function fetchDealerById(id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/dealers/${id}`);
}

async function fetchSalesManager() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/users/4");
}
async function fetchSalesPerson() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/users/6");
}

//admin-orders

async function fetchOrders(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/order", { params });
}

async function fetchOrderById(id: any, params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/orders/${id}`, { params });
}
async function DealerfetchOrderById(id: any, params?: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/orders/${id}`, { params });
}

async function downloadGeneratedLPO(id: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/orders/${id}/download-lpo`);
}

async function uploadSignedLPO(id: any, params: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/orders/${id}/lpo-upload`, params);
}

async function addStockLabel(productId: number, params: any) {
  const instance = getProtectedInstance();
  return await instance.post(
    `/admin/products/${productId}/stock-label`,
    params
  );
}

async function handleBulkUpload(file: any) {
  const formdata = new FormData();
  formdata.append("file", file);
  const instance = getProtectedInstance();
  return await instance.post("/admin/product-bulk/stocklabelupload", formdata);
}

async function downloadSample() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/product-stock-label/download-sample");
}

async function handleManageProductBulkUpload(file: any) {
  const formdata = new FormData();
  formdata.append("file", file);
  const instance = getProtectedInstance();
  return await instance.post("/admin/product-bulk/upload", formdata);
}
async function handleManageProductBulkUploadImage(file: any, images: any) {
  const formdata = new FormData();
  formdata.append("file", file);
  formdata.append("images", images);
  const instance = getProtectedInstance();
  return await instance.post("/admin/product-bulk/upload-images", formdata);
}

async function downloadManageProductSample() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/product-bulk/download-sample");
}
async function downloadManageProductSampleImage() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/product-bulk/upload-images-sample");
}

async function downloadManageProductTableData() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/product-data-download");
}

async function downloadDealersData() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/dealer-data-download");
}
async function downloadProductDetails(id: any, params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/products-details-download-pdf/${id}`, {
    params,
  });
}

async function updateOrderDetails(id: number, orderId: number, params: any) {
  const instance = getProtectedInstance();
  return await instance.put(
    `/admin/orders/${orderId}/order-details/${id}`,
    params
  );
}

async function orderAssigning(id: number, params: any) {
  const instance = getProtectedInstance();
  return await instance.put(`admin/update-order-sales-person/${id}`, params);
}

async function specificSalesManagerList(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/admin/order-details/sales-manager", { params });
}
async function specificSalesPersonList(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/admin/order-details/sales-person", { params });
}

async function deleteOrderDetails({ id, orderDetailId }: any) {
  const instance = getProtectedInstance();
  return await instance.delete(
    `/admin/orders/${id}/order-details/${orderDetailId}`
  );
}

//Admin Dashboard

async function getTotalRevenue() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/dashboard/totalrevenue");
}
async function getTotalOrders() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/dashboard/totalorders");
}
async function getDealersCount() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/dashboard/totaldealers");
}

async function getTotalProducts() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/dashboard/totalproducts");
}

async function getAvgOrderValue() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/dashboard/averageordervalue");
}

async function GetTopPerformingProducts(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/admin/dashboard/top-performing-products", {
    params,
  });
}

async function GetTopPerformingDealers(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/admin/dashboard/top-performing-dealers", {
    params,
  });
}

async function GetDashboardOrderChartInfo() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/dashboard-order-status");
}

async function GetRevenueDetails(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/admin/dashboard-revenue", {
    params,
  });
}

//Sales

//Dashboard

async function GetAllAssignedDealersDataSalesManager(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/sales-manager/dealers", { params });
}

async function GetAllAssignedDealersDataSalesPerson(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/sales-person/dealers", { params });
}
async function GetAllAssignedSalesPerson(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/sales-manager/sales-person", {
    params,
  });
}

async function GetAllAssignedDealersValue() {
  const instance = getProtectedInstance();
  return await instance.get("/api/sales-dashboard/assigned-dealers");
}
async function GetAllAssignedDealersValueSalesPerson() {
  const instance = getProtectedInstance();
  return await instance.get(
    "/api/sales-dashboard/sales-person/assigned-dealers"
  );
}

async function GetSalesRevenue(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/sales-dashboard/total-revenue", { params });
}
async function GetSalesOrder(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/sales-dashboard/total-orders", { params });
}

async function getPendingCart(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/sales-pending-carts", { params });
}

async function getPendingCartAttributes() {
  const instance = getProtectedInstance();
  return await instance.get("/api/sales-cart-attribute");
}

async function GetSalesDashboardOrderChartInfo(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/sales-dashboard-status", { params });
}

async function GetApprovalSummaryChartDetails(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/order-approval-summary-count", {
    params,
  });
}

async function getAllProducts(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/products", { params });
}

async function dealerforSalesManager(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/sales-manager/dealers", { params });
}

async function dealerforSalesPerson(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/sales-person/dealers", { params });
}

async function addToCartSales(params: any) {
  const instance = getProtectedInstance();
  return await instance.post("/api/cart-create", params);
}
async function getSalesCartDetails(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/sales-cart`, { params });
}

async function deleteSalesCartProduct(dealerId: number, productId: number) {
  const instance = getProtectedInstance();
  return await instance.delete(
    `/api/sales-cart/products/${dealerId}/${productId}`
  );
}

async function GetSalesDealerAddress(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/user-address-dealers`, { params });
}

//Sales Api

async function GetAllDealerListSalesManager(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/dealer-list-sales-manager", {
    params,
  });
}

async function GetAllDealerListSalesMan(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/dealer-list-sales-person", {
    params,
  });
}

async function GetAllDealerListSalesManagerPDF() {
  const instance = getProtectedInstance();
  return await instance.get("/api/pdf-generate-sales-manager");
}

async function GetAllDealerListSalesManagerXLS() {
  const instance = getProtectedInstance();
  return await instance.get("/api/export-sales-manager-data");
}

async function GetAllDealerListSalesManPDF() {
  const instance = getProtectedInstance();
  return await instance.get("/api/pdf-generate-sales-person");
}

async function GetAllDealerListSalesManXLS() {
  const instance = getProtectedInstance();
  return await instance.get("/api/export-sales-person-data");
}

async function fetchSalesManageOrderAttributes() {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/order-attributes`);
}

async function GetSalesDealerListAttribute() {
  const instance = getProtectedInstance();
  return await instance.get("/api/dealer-list-attribute");
}

async function fetchOrderApprovalAttributes() {
  const instance = getProtectedInstance();
  return await instance.get(`/api/order-sales-attributes`);
}
async function approveRejectOrder(orderId, param) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/order-approve-rejected/${orderId}`, param);
}

async function addProductInOrder(orderId: number, productDetails: any) {
  const instance = getProtectedInstance();
  return await instance.post(
    `/api/add-products-in-order/${orderId}`,
    productDetails
  );
}

async function register(
  email: string,
  name: string,
  role: string,
  phoneNumber: string,
  cNumber: string,
  cName: string,
  incorporationDate: string,
  employeesNumber: string,
  isSME: boolean
) {
  const instance = getPublicInstance();
  const data: any = {
    name: name,
    email: email,
    phoneNumber: phoneNumber,
    company: {
      name: cName,
      role: role,
      cin: cNumber,
      incorporationDate: incorporationDate,
      noOfEmployees: employeesNumber,
      isSme: isSME,
    },
  };
  return await instance.post("/api/register", data);
}

async function fileUpload(type: any, file: any) {
  const formdata = new FormData();
  formdata.append("file", file);
  formdata.append("type", type);
  const instance = getProtectedInstance();
  return await instance.post("/api/files", formdata);
}

//groups/pefs
async function getGroups(params: any = { perpage: 1000 }) {
  const instance = getProtectedInstance();
  return await instance.get("/api/pefs", { params });
}

async function groupCreate(data: any) {
  const instance = getProtectedInstance();
  return await instance.post("/api/pefs", data);
}

async function groupUpdate(id: any, data: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/pefs/${id}`, data);
}

async function groupDelete(id: any) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/pefs/${id}`);
}

//users
async function users(params: any = { perpage: 1000 }) {
  const instance = getProtectedInstance();
  return await instance.get("/api/users", { params });
}

async function userCreate(data: any) {
  const instance = getProtectedInstance();
  return await instance.post("/api/users", data);
}

async function userStatus(id: number, details: any) {
  const instance = getProtectedInstance();
  return await instance.patch(`/api/users/${id}/status-update`, details);
}

async function userUpdate(id: number, data: any) {
  const instance = getProtectedInstance();
  return await instance.put("/api/users/" + id, data);
}

async function userDelete(id: number) {
  const instance = getProtectedInstance();
  return await instance.delete("/api/users/" + id);
}

//industries
async function getIndustries(params: any = { perpage: 1000 }) {
  const instance = getProtectedInstance();
  return await instance.get("/api/industries", { params });
}

//companies
async function getCompanies(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/companies", { params });
}

async function companyUpdate(id: number, details: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/companies/${id}`, details);
}

async function companyCreate(details: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/companies`, details);
}

async function companyDelete(id: number) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/companies/${id}`);
}

async function companyStatus(id: number, details: any) {
  const instance = getProtectedInstance();
  return await instance.patch(`/api/companies/${id}/status-update`, details);
}

async function companyApprove(id: number, details: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/admin/companies/${id}/approve`, details);
}

async function companyReject(id: number, details: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/admin/companies/${id}/reject`, details);
}

async function addSICCode(id: number, details: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/admin/companies/${id}/sic-codes`, details);
}

async function getEmployees(id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/admin/companies/${id}/employees`);
}

//roles
async function getRoleSections() {
  const instance = getProtectedInstance();
  return await instance.get("api/admin/roles/sections");
}

async function getRoles(params: any = { perpage: 1000 }) {
  const instance = getProtectedInstance();
  return await instance.get("/api/roles", { params });
}

async function roleUpdate(id: number, details: any) {
  const instance = getProtectedInstance();
  return await instance.put(
    `/api/roles/${id}/module-permission-groups`,
    details
  );
}

async function roleCreate(details: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/roles`, details);
}

async function roleStatus(id: number, details: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/roles/${id}/status`, details);
}

async function roleDelete(id: number) {
  const instance = getProtectedInstance();
  return await instance.delete("/api/roles/" + id);
}

//files
async function getFilesList(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/file-masters", { params });
}

async function fileUpdate(id: number, details: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/file-masters/${id}`, details);
}

async function fileCreate(details: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/file-masters`, details);
}

async function fileDelete(id: number) {
  const instance = getProtectedInstance();
  return await instance.delete("/api/file-masters/" + id);
}

async function fileStatus(id: number, details: any) {
  const instance = getProtectedInstance();
  return await instance.patch(`/api/file-masters/${id}/status-update`, details);
}

async function getFileTypes() {
  const instance = getProtectedInstance();
  return await instance.get("/api/file-types");
}

//departments
async function getDepartments() {
  const instance = getProtectedInstance();
  return await instance.get("/api/departments");
}

async function createDepartment(details: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/departments`, details);
}

//branch locations
async function getLocations() {
  const instance = getProtectedInstance();
  return await instance.get("/api/locations");
}

async function createLocations(details: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/locations`, details);
}

//countries
async function getCountries(params = { page: -1, order: "asc" }) {
  const instance = getProtectedInstance();
  return await instance.get("/api/countries", { params });
}

//Sectors
async function getSectors() {
  const instance = getProtectedInstance();
  return await instance.get("/api/sectors");
}

//Content
async function updateContent(id: number, req: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/company/${id}/contents`, req);
}

//company timelines
async function getCompanyTimelines(companyId: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/company/${companyId}/timelines`);
}

async function updateCompanyTimelines(id: number, req: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/company/${id}/timelines`, req);
  // return await instance.put(`/api/company/${id}/timelines`, req);
}

//profile
async function getProfile() {
  const instance = getProtectedInstance();
  return await instance.get("/api/self");
}

async function profileUpdate(req: any) {
  const instance = getProtectedInstance();
  return await instance.put("/api/users", req);
}

//claims master
async function getClaimStages() {
  const instance = getProtectedInstance();
  return await instance.get("/api/admin/claim-stages");
}

async function getClaimTypes() {
  const instance = getProtectedInstance();
  return await instance.get("/api/admin/claim-types");
}

async function claimTypesUpdate(req: any) {
  const instance = getProtectedInstance();
  return await instance.put("/api/admin/claim-types/bulk-update", req);
}

async function claimStagesUpdate(req: any) {
  const instance = getProtectedInstance();
  return await instance.put("/api/admin/claim-stages/bulk-update", req);
}

//claim
async function getClaims(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/admin/claims", { params });
}

async function claimUpdate(id: number, details: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/admin/claims/${id}`, details);
}

async function claimCreate(details: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/admin/claims`, details);
}

async function claimDelete(id: number) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/admin/claims/${id}`);
}

async function getClaimById(claimId: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/admin/claims/${claimId}`);
}

// financial employee cost
async function financialEmployeesCostList(
  claimId: any,
  params: any = { perpage: 1000 }
) {
  const instance = getProtectedInstance();
  return await instance.get(
    `api/admin/claims/${claimId}/financial/employees-cost`,
    { params }
  );
}

async function financialEmployeeCostCreate(claimId: any, details: any) {
  const instance = getProtectedInstance();
  return await instance.post(
    `/api/admin/claims/${claimId}/financial/employees-cost`,
    details
  );
}

async function financialEmployeeCostDelete(claimId: any, employeesCostId: any) {
  const instance = getProtectedInstance();
  return await instance.delete(
    `/api/admin/claims/${claimId}/financial/employees-cost/${employeesCostId}`
  );
}

async function financialEmployeesCostTotal(claimId: any) {
  const instance = getProtectedInstance();
  return await instance.get(
    `api/admin/claims/${claimId}/financial/employees-cost/totals`
  );
}

async function financialEmployeeCostDescriptionUpdate(
  claimId: any,
  details: any
) {
  const instance = getProtectedInstance();
  return await instance.put(
    `/api/admin/claims/${claimId}/financial/employees-cost/description`,
    details
  );
}

async function uploadFinancialEmployeeCostBulkSheet(
  claimId: any,
  details: any
) {
  const instance = getProtectedFileUpload();
  return await instance.post(
    `/api/admin/claims/${claimId}/financial/employees-cost/bulk-upload/report`,
    details
  );
}

async function uploadConsumablesCostBulkSheet(claimId: any, details: any) {
  const instance = getProtectedFileUpload();
  return await instance.post(
    `/api/admin/claims/${claimId}/financial/employees-cost/bulk-upload/report`,
    details
  );
}

async function uploadSubContractorCostBulkSheet(claimId: any, details: any) {
  const instance = getProtectedFileUpload();
  return await instance.post(
    `/api/admin/claims/${claimId}/financial/employees-cost/bulk-upload/report`,
    details
  );
}

async function uploadExternalWorkerCostBulkSheet(claimId: any, details: any) {
  const instance = getProtectedFileUpload();
  return await instance.post(
    `/api/admin/claims/${claimId}/financial/external-workers-cost/bulk-upload/report`,
    details
  );
}

//financial SubContractor cost
async function subContractorCostList(
  claimId: any,
  params: any = { perpage: 1000 }
) {
  const instance = getProtectedInstance();
  return await instance.get(
    `api/admin/claims/${claimId}/financial/sub-contractors-cost`,
    { params }
  );
}

async function subContractorCostCreate(claimId: any, details: any) {
  const instance = getProtectedInstance();
  return await instance.post(
    `/api/admin/claims/${claimId}/financial/sub-contractors-cost`,
    details
  );
}

async function subContractorCostDelete(claimId: any, employeesCostId: any) {
  const instance = getProtectedInstance();
  return await instance.delete(
    `/api/admin/claims/${claimId}/financial/sub-contractors-cost/${employeesCostId}`
  );
}

async function subContractorCostTotal(claimId: any) {
  const instance = getProtectedInstance();
  return await instance.get(
    `api/admin/claims/${claimId}/financial/sub-contractors-cost/totals`
  );
}

async function financialSubContractorCostDescriptionUpdate(
  claimId: any,
  details: any
) {
  const instance = getProtectedInstance();
  return await instance.put(
    `/api/admin/claims/${claimId}/financial/sub-contractors-cost/description`,
    details
  );
}

//financial external worker cost
async function externalWorkerCostList(
  claimId: any,
  params: any = { perpage: 1000 }
) {
  const instance = getProtectedInstance();
  return await instance.get(
    `api/admin/claims/${claimId}/financial/external-workers-cost`,
    { params }
  );
}

async function externalWorkerCostCreate(claimId: any, details: any) {
  const instance = getProtectedInstance();
  return await instance.post(
    `/api/admin/claims/${claimId}/financial/external-workers-cost`,
    details
  );
}

async function externalWorkerCostDelete(claimId: any, employeesCostId: any) {
  const instance = getProtectedInstance();
  return await instance.delete(
    `/api/admin/claims/${claimId}/financial/external-workers-cost/${employeesCostId}`
  );
}

async function externalWorkerCostTotal(claimId: any) {
  const instance = getProtectedInstance();
  return await instance.get(
    `api/admin/claims/${claimId}/financial/external-workers-cost/totals`
  );
}

async function externalWorkerCostDescriptionUpdate(claimId: any, details: any) {
  const instance = getProtectedInstance();
  return await instance.put(
    `/api/admin/claims/${claimId}/financial/external-workers-cost/description`,
    details
  );
}

//financial consumables cost
async function consumablesCostList(
  claimId: any,
  params: any = { perpage: 1000 }
) {
  const instance = getProtectedInstance();
  return await instance.get(
    `api/admin/claims/${claimId}/financial/consumables-cost`,
    { params }
  );
}

async function consumablesCostCreate(claimId: any, details: any) {
  const instance = getProtectedInstance();
  return await instance.post(
    `/api/admin/claims/${claimId}/financial/consumables-cost`,
    details
  );
}

async function consumablesCostDelete(claimId: any, employeesCostId: any) {
  const instance = getProtectedInstance();
  return await instance.delete(
    `/api/admin/claims/${claimId}/financial/consumables-cost/${employeesCostId}`
  );
}

async function consumablesCostTotal(claimId: any) {
  const instance = getProtectedInstance();
  return await instance.get(
    `api/admin/claims/${claimId}/financial/consumables-cost/totals`
  );
}

async function consumablesTypeList() {
  const instance = getProtectedInstance();
  return await instance.get(`api/admin/claim-consumables`);
}

async function financialConsumablesCostDescriptionUpdate(
  claimId: any,
  details: any
) {
  const instance = getProtectedInstance();
  return await instance.put(
    `/api/admin/claims/${claimId}/financial/consumables-cost/description`,
    details
  );
}

async function createBulkClaimConsumables(details: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/admin/claim-consumables/bulk-save`, details);
}

async function getCompanyHouseDetails(cin: any) {
  const instance = getPublicInstance();
  return await instance.get(`/api/company-house/details?cin=${cin}`);
}

// roles and permissions

async function getAllRoles(params: any = { perpage: 1000 }) {
  const instance = getProtectedInstance();
  return await instance.get("/api/roles", { params });
}

async function getAllPermissions(params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get("/api/permissions", { params });
}

async function updatePermissions(id: number, arr: any = []) {
  const instance = getProtectedInstance();
  const data = {
    permissionIds: arr,
  };
  return await instance.post(`/api/roles/${id}/permissions`, data);
}

async function getFiles(params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get("/api/files", { params });
}

//funds
async function getFunds(params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get("/api/funds", { params });
}

async function createFund(params: any) {
  const instance = getProtectedInstance();
  return await instance.post("/api/funds", params);
}

async function updateFund(id: number, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/funds/${id}`, params);
}

async function deleteFund(id: number, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/funds/${id}`, { params });
}

//surveys
async function getSurveys(params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get("/api/surveys", { params });
}

async function getSurveyDetails(surveyId: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/surveys/${surveyId}`);
}

async function createSurvey(params: any) {
  const instance = getProtectedInstance();
  return await instance.post("/api/surveys", params);
}

async function updateSurvey(id: number, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.put(`/api/surveys/${id}`, params);
}

async function deleteSurvey(id: number, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/surveys/${id}`, { params });
}

async function copySurvey(id: number, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/surveys/${id}/duplicate`, params);
}

async function downloadSurveyResponses(surveyId: number, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/surveys/${surveyId}/download-questions`, {
    params,
  });
}

//KPI
async function getKpi(params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get("/api/kpis", { params });
}

async function createKpi(params: any) {
  const instance = getProtectedInstance();
  return await instance.post("/api/kpis", params);
}

async function deleteKpi(id: number, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/kpis/${id}`, { params });
}

//METRIC
async function getMetric(params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get("/api/metrics", { params });
}

async function createMetric(params: any) {
  const instance = getProtectedInstance();
  return await instance.post("/api/metrics", params);
}

async function deleteMetric(id: number) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/metrics/${id}`);
}

async function updateMetricOrder(id: number, params: any) {
  const instance = getProtectedInstance();
  return await instance.patch(`/api/metrics/${id}`, params);
}

//fund_configurations
async function getFundConfig(fundId: number, params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`api/funds/${fundId}/configurations`, { params });
}

async function createFundConfig(fundId: number = 0, params: any) {
  const instance = getProtectedInstance();
  return await instance.post(`api/funds/${fundId}/configurations`, params);
}

async function updateFundConfig(configId: number, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.patch(`/api/fund-configurations/${configId}`, params);
}

async function deleteFundConfig(configId: number, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/fund-configurations/${configId}`, {
    params,
  });
}

//Company Analyst Data collection survey
async function getSurveyMetrics(id: number, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`api/user/surveys/${id}/metrics`, params);
}

async function getMetricsData(MetricId: any, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(
    `api/user/surveys/metrics/${MetricId}/questions`,
    params
  );
}

async function getUserMetricsData(
  surveyId: any,
  MetricId: any,
  params: any = {}
) {
  const instance = getProtectedInstance();
  return await instance.get(
    `api/user/surveys/${surveyId}/metrics/${MetricId}/questions`,
    params
  );
}

async function getMetricsResponses(MetricId: any, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(
    `api/user/surveys/metrics/${MetricId}/responses`,
    params
  );
}

async function saveMetricResponsesToDraft(
  surveyId: any,
  MetricId: any,
  params: any = {}
) {
  const instance = getProtectedInstance();
  return await instance.put(
    `api/user/surveys/${surveyId}/metrics/${MetricId}/responses`,
    params
  );
}

async function saveSurveyFinalData() {
  const instance = getProtectedInstance();
  return await instance.post(`api/user/surveys/responses/publish`);
}

async function publishSurveyByAdmin(surveyId: number) {
  const instance = getProtectedInstance();
  return await instance.post(`api/survey/${surveyId}/questions/publish`);
}

//units
async function getUnits(params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`api/units`, params);
}

async function createUnit(params: any) {
  const instance = getProtectedInstance();
  return await instance.post("/api/units", params);
}

async function deleteUnit(id: number, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/units/${id}`, { params });
}

// admin survey questionnaire
async function getSurveyMetricsById(surveyId: any, params = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`api/surveys/${surveyId}/metrics`, params);
}

async function getSurveyQuestionsByMetricsId(
  surveyId: any,
  MetricId: any,
  params: any = { page: -1 }
) {
  const instance = getProtectedInstance();
  return await instance.get(
    `api/survey/${surveyId}/metrics/${MetricId}/questions`,
    { params }
  );
}

async function getUnitCategories(params: any = { page: -1 }) {
  const instance = getProtectedInstance();
  return await instance.get(`api/units/categories`, { params });
}

// questions master right panel
async function getMasterQuestions(params: any = { isMaster: 1, page: -1 }) {
  const instance = getProtectedInstance();
  return await instance.get(`api/questions`, { params });
}

//%percentage completion text
async function getPercentageTask(surveyId: any, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`api/surveys/${surveyId}/percent-complete`, {
    params,
  });
}

async function getUserPercentageComplete(surveyId: any, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`api/user/surveys/${surveyId}/percent-complete`, {
    params,
  });
}

//Admin-> data collection-> table data
async function getDataCollection(params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`api/data-collection`, { params });
}

//GHG master management
async function downloadGHGFile() {
  const instance = getProtectedInstance();
  return await instance.get("/api/ghg-file/download");
}

async function uploadGHGFile(details: any) {
  const instance = getProtectedFileUpload();
  return await instance.post(`/api/ghg-file/upload`, details);
}

async function getGHGFileDetails() {
  const instance = getProtectedInstance();
  return await instance.get("/api/ghg-file/download-details");
}

async function getGHGQuestionTableData(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/ghg", { params });
}

async function getTotalCarbonIntensities(
  surveyId: any,
  params = { page: -1, order: "asc" }
) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/user/surveys/${surveyId}/carbon-intensity`, {
    params,
  });
}

async function getPercentageRenewableEnergy(surveyId: any, params: any) {
  const instance = getProtectedInstance();
  return await instance.get(
    `/api/user/surveys/${surveyId}/percentage-renewable-energy`,
    { params }
  );
}

async function getQuestionRemarks(surveyId: any, questionId: any) {
  const instance = getProtectedInstance();
  return await instance.get(
    `/api/surveys/${surveyId}/questions/${questionId}/remarks`
  );
}

async function postComments(surveyId: any, remarkId: any, data: any) {
  const instance = getProtectedInstance();
  return await instance.post(
    `api/surveys/${surveyId}/remarks/${remarkId}/comment`,
    data
  );
}
async function roleApproval(surveyId: any, remarkId: any) {
  const instance = getProtectedInstance();
  return await instance.get(
    `api/surveys/${surveyId}/remarks/${remarkId}/approve`
  );
}
async function getPermissionById(id: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/roles/${id}/permissions`);
}

//auto save form data
/*
async function saveFormData(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/api/........', data);
}/*


/*function downloadEmoployeeCostSampleSheet(claimId: any){
    return `${Parameters.ApiUrl}/api/claims/${claimId}/financial/employees-cost/bulk-upload/sample-download`;
}

function downloadSubContractorCostSampleSheet(claimId: any) {
    return `${Parameters.ApiUrl}/api/claims/${claimId}/financial/employees-cost/bulk-upload/sample-download`;
}

function downloadExternalWorkerCostSampleSheet(claimId: any) {
    return `${Parameters.ApiUrl}/api/claims/${claimId}/financial/external-workers-cost/bulk-upload/sample-download`;
}

function downloadConsumablesCostSampleSheet(claimId: any) {
    return `${Parameters.ApiUrl}/api/claims/${claimId}/financial/employees-cost/bulk-upload/sample-download`;
}*/

async function getAllDelears() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/adeelrs");
}

async function fetchAllAttributesDealer() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/dealer-attribute");
}

async function deleteUserFromAdmin(id: any) {
  const instance = getProtectedInstance();
  return await instance.delete(`/admin/users/${id}`);
}

async function createUserFromAdmin(payload: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/admin/users`, payload);
}

async function createSurveyQuestion(surveyId: number, data: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/api/survey/${surveyId}/questions`, data);
}

async function deleteSurveyPage(pageId: number, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/pages/${pageId}`, { params });
}

async function deleteMasterQuestion(
  surveyId: number,
  questionId: number,
  params: any = {}
) {
  const instance = getProtectedInstance();
  return await instance.delete(
    `/api/surveys/${surveyId}/questions/${questionId}`,
    { params }
  );
}

async function deleteSurveySection(sectionId: number, params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.delete(`/api/sections/${sectionId}`, { params });
}

async function getAllProductsList(params = {}) {
  const instance = getProtectedInstance();
  return await instance.get("/admin/products", { params });
}

async function getOfferList(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/admin/offer", { params });
}
async function getCustomNotification() {
  const instance = getProtectedInstance();
  return await instance.get("/admin/custom-notification");
}

async function getProductById(id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/products/${id}`);
}
async function updateProduct(id: number, params: any) {
  const instance = getProtectedInstance();
  return await instance.patch(`/admin/products/${id}`, params);
}
async function deleteProduct(id: number) {
  const instance = getProtectedInstance();
  return await instance.delete(`/admin/products/${id}`);
}

async function fetchAllAttributes() {
  const instance = getProtectedInstance();
  return await instance.get(`/api/attributes`);
}
async function fetchManageOrderAttributes(id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/orders/${id}/order-details/product`);
}

async function updateCategory(id: number, params: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/admin/categories/${id}`, params);
}

async function update_permissions(id: number, arr: type = []) {
  const instance = getProtectedInstance();
  const data = {
    permissionIds: arr,
  };
  return await instance.post(`/api/roles/${id}/permissions`, data);
}

async function fetchOrderApprovals(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/order-approval`, { params });
}

async function adminLpoGeneration(id: number, params = {}) {
  const instance = getProtectedInstance();
  return await instance.post(`/admin/orders/${id}`, params);
}

async function GetDealerTotalRevenue() {
  const instance = getProtectedInstance();
  return await instance.get("/api/export-revenue-summary");
}

async function getRevenuePdf() {
  const instance = getProtectedInstance();
  return await instance.get("/api/pdf-revenue-summary");
}

async function GetDealerStockSummaryExcel() {
  const instance = getProtectedInstance();
  return await instance.get("/api/export-stock-summary");
}

async function GetDealerStockSummaryPdf() {
  const instance = getProtectedInstance();
  return await instance.get("/api/pdf-generate-stock-summary");
}

async function offerActiveInactiveToggleCall(id: number, payload: any) {
  const instance = getProtectedInstance();
  return await instance.patch(`/admin/offers/status-update/${id}`, payload);
}

async function getAllGetBuyOffers(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/admin/offers/get-buy-offers", { params });
}

async function getSingleBuyOffer(id: any, productId: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/offers/${id}/get-buy-offers/${productId}`);
}
async function getSimilarProducts(params: any) {
  const instance = getProtectedInstance();
  return await instance.get("/api/similar-product", { params });
}

async function createGetBuyOffers(data: any) {
  const instance = getProtectedInstance();
  return await instance.post("/admin/get-buy-offers", data);
}
async function editGetBuyOffers(data: any, id: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/admin/offers/get-buy-offers/${id}`, data);
}
async function editOffer(data: any, id: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/admin/offer/${id}`, data);
}

async function deleteProductOfGetBuyOffers(id: number) {
  const instance = getProtectedInstance();
  return await instance.delete(`/admin/offers/get-buy-offers/${id}`);
}

async function getBuyOffersBulkUpload(file: any) {
  const formdata = new FormData();
  formdata.append("file", file);
  const instance = getProtectedInstance();
  return await instance.post(
    "/admin/offers/get-buy-offers/bulk-upload",
    formdata
  );
}

async function getBuyOfferSample() {
  const instance = getProtectedInstance();
  return await instance.get(
    "/admin/offers/get-buy-offers/bulk-upload/download-sample"
  );
}

async function createCoupon(payload: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/admin/coupons`, payload);
}
async function daysLeft(payload: any) {
  const instance = getProtectedInstance();
  return await instance.post(`/admin/notified-before-days`, payload);
}
async function daysLeftGet(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/notified-before-days`, params);
}
async function deleteDaysLeft(id: any) {
  const instance = getProtectedInstance();
  return await instance.delete(`/admin/notified-before-days/${id}`);
}

async function deleteCoupon(id: number) {
  const instance = getProtectedInstance();
  return await instance.delete(`/admin/offers/coupons/${id}`);
}

async function getCouponList(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/coupons`, { params });
}

async function orderSummaryStatusAttributes(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/dashboard-revenue-summary-attribute`, {
    params,
  });
}

async function couponActiveInactiveToogleCall(id: number, payload: any) {
  const instance = getProtectedInstance();
  return await instance.patch(`/admin/coupons/status-update/${id}`, payload);
}

async function dealerListSampleDownload() {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/dealer-bulk/download-sample`);
}

async function dealerListBulkUpload(file: any) {
  const instance = getProtectedInstance();
  const formdata = new FormData();
  formdata.append("file", file);
  return await instance.post(`/admin/dealer-bulk-update`, formdata);
}

//Admin Configurations

async function handleCurrencyChange() {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/currency-converter`);
}
async function handleEditCurrencyChange(id: number, params: any) {
  const instance = getProtectedInstance();
  return await instance.put(`/admin/currency-converter/${id}`, params);
}

async function countryList(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/country`, { params });
}

const API_SERVICE = {
  GetDealerStockSummaryExcel,
  GetDealerStockSummaryPdf,
  GetDealerTotalRevenue,
  getRevenuePdf,
  update_permissions,

  fetchManageOrderAttributes,
  fetchAllAttributes,
  getAllProductsList,
  getProductById,
  updateProduct,
  deleteProduct,
  login,
  forgotPassword,
  resetPassword,
  changePassword,
  changeAdminPassword,
  self,
  register,
  dealerProfileUpdate,
  adminProfileUpdate,

  fetchCategories,
  popularFamilyProducts,
  fetchCategoriesProducts,
  fetchProductDetails,
  fetchAttributes,
  fetchPopularAttributes,
  fetchFeaturedAttributes,

  fetchPopularProducts,
  fetchPopularFamily,
  fetchBrowserCategory,

  getStockSummary,
  getRevenueSummary,
  NotifyMe,
  downloadOrderStatement,
  addToCart,
  getValidCouponDealer,
  applyCouponDealer,
  getCartDetails,
  getFilteredCartDetails,
  addToWishList,
  deleteCartProduct,
  updatePriceCart,

  fetchWishlist,
  WishListDeleteAll,
  WishListDelete,

  GetUserAttributes,
  addAddress,
  GetAddress,
  editAddress,
  deleteAddress,
  getOfferList,
  getCustomNotification,
  //DealerDashboard
  getTotalOrdersDealer,
  getTotalRevenueDealer,
  getTotalProductsDealer,
  getAvgOrderValueDealer,
  GetFavouriteProducts,
  GetPurchaseSummaryDetails,
  GetDealerChartDetails,
  GetNotifcationsCount,
  statusChangeNotification,
  GetNotifcations,

  fetchFeatureProducts,
  getBanners,
  bannerActiveInactiveToogleCall,
  fileUpload,

  fetchDealer,
  dealerActiveInactiveToogleCall,
  fetchDealerById,
  updateDealer,

  fetchOrders,
  fetchOrderById,
  updateOrderDetails,

  deleteOrderDetails,
  specificSalesManagerList,
  orderAssigning,
  specificSalesPersonList,

  //Admin routes
  addStockLabel,
  downloadSample,
  handleManageProductBulkUpload,
  handleManageProductBulkUploadImage,
  downloadManageProductSample,
  downloadManageProductSampleImage,
  downloadManageProductTableData,
  downloadDealersData,
  downloadProductDetails,
  handleBulkUpload,
  updateCategory,
  DealerfetchOrderById,
  downloadGeneratedLPO,
  uploadSignedLPO,

  //Admin Dashboard
  getTotalOrders,
  getTotalRevenue,
  getDealersCount,
  getTotalProducts,
  getAvgOrderValue,
  GetTopPerformingProducts,
  GetTopPerformingDealers,
  GetDashboardOrderChartInfo,
  GetRevenueDetails,

  //adminroutes
  adminRoleActiveInactiveToogleCall,
  getAdminPermissions,
  adminActiveInactiveToogleCall,
  getAdminRoleById,
  getAdminAllUsers,
  getAdminAllRoles,
  getAdminUserById,
  editAdminUserById,
  addAdminRole,
  editAdminRole,
  getAdminBannersList,
  getLoginBanner,
  addHomeBanner,
  deleteHomeBanner,
  fetchSingleBannerDetails,
  updateHomeBanner,

  getHomeOtherBanner,
  addCustomNotification,
  fetchSingleNotification,
  addNewOffer,

  //Sales Api

  GetAllDealerListSalesManager,
  GetAllDealerListSalesMan,
  GetAllDealerListSalesManagerPDF,
  GetAllDealerListSalesManagerXLS,
  GetAllDealerListSalesManPDF,
  GetAllDealerListSalesManXLS,
  fetchSalesManageOrderAttributes,
  GetSalesDealerListAttribute,

  //Sales
  GetAllAssignedDealersDataSalesManager,
  GetAllAssignedDealersDataSalesPerson,
  GetAllAssignedSalesPerson,
  GetAllAssignedDealersValue,
  GetAllAssignedDealersValueSalesPerson,
  GetSalesRevenue,
  GetSalesOrder,
  GetSalesDashboardOrderChartInfo,
  GetApprovalSummaryChartDetails,

  getPendingCart,
  getPendingCartAttributes,
  getAllProducts,
  dealerforSalesManager,
  dealerforSalesPerson,
  addToCartSales,
  getSalesCartDetails,
  deleteSalesCartProduct,
  GetSalesDealerAddress,

  getGroups,
  groupCreate,
  groupUpdate,
  groupDelete,

  getIndustries,
  getAllDelears,
  fetchAllAttributesDealer,
  deleteUserFromAdmin,
  createUserFromAdmin,
  getCompanies,
  companyCreate,
  companyUpdate,
  companyDelete,
  companyStatus,
  companyApprove,
  companyReject,
  addSICCode,
  getEmployees,

  users,
  userCreate,
  userUpdate,
  userDelete,
  userStatus,

  getRoleSections,
  getRoles,
  roleCreate,
  roleUpdate,
  roleDelete,
  roleStatus,

  getFilesList,
  fileCreate,
  fileUpdate,
  fileDelete,
  fileStatus,
  getFileTypes,

  getDepartments,
  createDepartment,
  getLocations,
  createLocations,
  getCountries,
  getSectors,
  updateContent,

  getCompanyTimelines,
  updateCompanyTimelines,

  getProfile,

  profileUpdate,

  getClaimStages,
  getClaimTypes,
  claimTypesUpdate,
  claimStagesUpdate,

  handleErrors,
  GetAddressBasedOnId,

  getClaims,
  claimUpdate,
  claimCreate,
  claimDelete,
  getClaimById,
  getHomeBanners,

  financialEmployeesCostList,
  financialEmployeeCostCreate,
  financialEmployeeCostDelete,
  financialEmployeesCostTotal,
  financialEmployeeCostDescriptionUpdate,
  uploadFinancialEmployeeCostBulkSheet,
  uploadConsumablesCostBulkSheet,
  uploadSubContractorCostBulkSheet,
  uploadExternalWorkerCostBulkSheet,

  subContractorCostList,
  subContractorCostCreate,
  subContractorCostDelete,
  subContractorCostTotal,
  financialSubContractorCostDescriptionUpdate,

  externalWorkerCostList,
  externalWorkerCostCreate,
  externalWorkerCostDelete,
  externalWorkerCostTotal,
  externalWorkerCostDescriptionUpdate,

  consumablesCostList,
  consumablesCostCreate,
  consumablesCostDelete,
  consumablesCostTotal,
  consumablesTypeList,
  financialConsumablesCostDescriptionUpdate,
  createBulkClaimConsumables,
  getCompanyHouseDetails,

  getAllRoles,
  getAllPermissions,
  updatePermissions,

  getFiles,

  getFunds,
  createFund,
  updateFund,
  deleteFund,

  getSurveys,
  getSurveyDetails,
  createSurvey,
  updateSurvey,
  deleteSurvey,

  getKpi,
  createKpi,
  deleteKpi,

  getMetric,
  createMetric,
  deleteMetric,
  updateMetricOrder,

  getFundConfig,
  createFundConfig,
  updateFundConfig,
  deleteFundConfig,

  getSurveyMetrics,
  getMetricsResponses,
  getMetricsData,
  getUserMetricsData,
  saveMetricResponsesToDraft,
  saveSurveyFinalData,
  publishSurveyByAdmin,
  getUnits,
  createUnit,
  deleteUnit,

  getSurveyMetricsById,
  getSurveyQuestionsByMetricsId,
  getUnitCategories,
  getMasterQuestions,
  getPercentageTask,
  getUserPercentageComplete,
  getDataCollection,
  downloadGHGFile,
  uploadGHGFile,
  getGHGFileDetails,
  getGHGQuestionTableData,
  getTotalCarbonIntensities,
  getPercentageRenewableEnergy,
  copySurvey,
  downloadSurveyResponses,
  getQuestionRemarks,
  postComments,
  roleApproval,
  getPermissionById,
  createSurveyQuestion,
  deleteSurveyPage,
  deleteSurveySection,
  createOrder,
  getMyOrder,
  fetchOrderApprovals,
  adminLpoGeneration,
  fetchOrderApprovalAttributes,
  approveRejectOrder,
  addProductInOrder,
  offerActiveInactiveToggleCall,
  getAllGetBuyOffers,
  getSingleBuyOffer,
  getSimilarProducts,
  createGetBuyOffers,
  editGetBuyOffers,
  editOffer,
  deleteProductOfGetBuyOffers,
  getBuyOffersBulkUpload,
  getBuyOfferSample,
  createCoupon,
  daysLeft,
  daysLeftGet,
  deleteDaysLeft,
  deleteCoupon,
  getCouponList,
  couponActiveInactiveToogleCall,
  handleCurrencyChange,
  handleEditCurrencyChange,
  countryList,
  orderSummaryStatusAttributes,

  dealerListSampleDownload,
  dealerListBulkUpload,
};
export default API_SERVICE;
