import { Add, InboxOutlined, Remove } from "@material-ui/icons";
import {
  HeartOutlined,
  HeartFilled,
  BellOutlined,
  DownloadOutlined,
  CheckOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Button,
  Input,
  Layout,
  Breadcrumb,
  Divider,
  notification,
} from "antd";
import { useState, useEffect, useContext } from "react";
import { Content } from "antd/lib/layout/layout";
import AddToCartBtn from "components/AddToCartBtn";
import { Link, useParams } from "react-router-dom";
import API_SERVICE from "services/api-service";
import "./index.scss";
import { CATEGORIES, PRODUCT_MAIN } from "shared/constants/RouteConstants";
import fastIcon from "shared/assets/images/FastDelivery.png";
import voucherIcon from "shared/assets/images/voucher.png";
import packageIcon from "shared/assets/images/Package.png";
import SimilarProductCard from "components/SimilarProductCard/SimilarProductCard";
import { consverPriceToInternationalFormat } from "services/util";
import HorizontalScroll from "react-scroll-horizontal";
import MilanoThumbnail from "../../../assets/MilanoThumbnail.png";
import CartContext from "context/Cart";
import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import Loader from "components/Loader/Loader";
import { DownloadLink } from "shared/services/Utility";

const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: `element-${ind}` }));
export default function ProductDetailsPage(props: any) {
  const [originalQuantity, setOriginalQuantity] = useState();
  const [items, setItems] = React.useState(getItems);
  const [selected, setSelected] = React.useState([]);
  const [disable, setDisable] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [productsList, setProductsList] = useState([]);
  const params: any = useParams();
  const [productDetails, setProductDetails] = useState({
    id: 0,
    name: "",
    price: 0,
    description: "",
    fullDescription: "",
    skuId: 0,
    cbmValue: 0,
    categoryId: 0,
    origin: "",
    size: "",
    color: "",
    netWeight: 0,
    grossWeight: 0,
    warranty: "",
    installationType: "",
    familyName: "",
    label: {
      labels: "",
    },
    category: {
      parentCategory: {
        id: 0,
      },
    },
  });
  const [selectedImage, setSelectedImage] = useState();
  const [thumbnailImage, setThumbnailImage] = useState();
  const [currency, setCurrency] = useState();
  const [userId, setUserId] = useState();
  const [visible, setVisible] = useState(false);
  const [productImages, setProductImages] = useState([
    {
      id: 1,
      file: {
        name: "random.png",
        url: "",
      },
    },
  ]);
  const { fetchCartDetails } = useContext(CartContext);

  const fetchProductDetails = async () => {
    try {
      setDisable(true);
      const {
        data: { data },
      } = await API_SERVICE.fetchProductDetails(params.id);
      setProductDetails({ ...data });
      const { productImages } = data;
      setProductImages(productImages);
      setThumbnailImage(data.thumbnail);
      setSelectedImage(productImages[0]?.file?.url);
      setQuantity(parseInt(data?.cartoonQuantity));
      setOriginalQuantity(parseInt(data?.cartoonQuantity));
      fetchSimilarProducts(data.categoryId, data?.id);
      setDisable(false);
    } catch (err) {
      console.error(err);
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };
  const fetchSimilarProducts = async (categoryId: any, id: any) => {
    try {
      const params = {
        categoryId: categoryId,
        productId: id,
      };
      console.log("par", params);
      const {
        data: { data },
      } = await API_SERVICE.getSimilarProducts(params);
      setProductsList(data.items);
    } catch (err) {
      console.error(err);
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };
  const handleApplyOffer = async (quantity: any) => {
    try {
      await API_SERVICE.addToCart({
        productId: params.id,
        orderCoupon: null,
        quantity: quantity,
      });

      notification.success({
        message: "Offer Applied and added to cart!",
        placement: "bottomLeft",
      });
      fetchCartDetails();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };

  const addToWIshlistHandle = async (productId: number) => {
    if (productDetails.isWishlisted) {
      try {
        await API_SERVICE.WishListDelete(productId);
        notification.success({
          message: "Deleted from Wishlist",
          placement: "bottomLeft",
        });
        fetchProductDetails();
      } catch (err) {
        notification.error({
          message: API_SERVICE.handleErrors(err),
          placement: "bottomLeft",
        });
      }
    } else {
      try {
        await API_SERVICE.addToWishList(productId);
        notification.success({
          message: "Added to Wishlist",
          placement: "bottomLeft",
        });
        fetchProductDetails();
      } catch (err) {
        notification.error({
          message: API_SERVICE.handleErrors(err),
          placement: "bottomLeft",
        });
      }
    }
  };

  const handleNotificaton = async () => {
    try {
      const parameters = {
        userId: userId,
        productId: params.id,
      };
      await API_SERVICE.NotifyMe(parameters);
      setVisible(true);
      notification.success({
        message: "Successfully subscribed",
        placement: "bottomRight",
      });
      fetchProductDetails();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  const getCurrency = () => {
    const user = JSON.parse(localStorage.getItem("user")!);
    setCurrency(user?.user.dealerDetails[0]?.currencyType);
    setUserId(user?.user.id);
  };
  const downloadProductDetails = async () => {
    try {
      const params = {
        url: "https://milano.valuescale.co.in" + props.location.pathname,
      };
      const {
        data: { data },
      } = await API_SERVICE.downloadProductDetails(productDetails.id, params);
      if (data) {
        window.open(data.url, "_blank");
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };
  const child = { width: `30em`, height: `100%` };

  useEffect(() => {
    fetchProductDetails();
    getCurrency();
  }, [params]);
  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);

    return (
      <Button
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
        className="scroll-btn"
      >
        <LeftOutlined className="arrow-icon" />
      </Button>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } =
      React.useContext(VisibilityContext);

    return (
      <Button
        disabled={isLastItemVisible}
        onClick={() => scrollNext()}
        className="scroll-btn"
      >
        <RightOutlined className="arrow-icon" />
      </Button>
    );
  }

  return (
    <>
      {disable ? <Loader /> : ""}
      <Layout className="products-bg-color">
        <Content className="contentPadding">
          <Breadcrumb className="products-breadcrumb" separator={">"}>
            <Breadcrumb.Item>
              <Link to={CATEGORIES}>All Categories</Link>
            </Breadcrumb.Item>

            {params.categoryName && (
              <Breadcrumb.Item>
                <Link
                  to={
                    PRODUCT_MAIN +
                    "/" +
                    productDetails.category.parentCategory.id +
                    "/" +
                    params.categoryName
                  }
                >
                  {params.categoryName}
                </Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item className="products-breadcrumb-active">
              {productDetails.name}
            </Breadcrumb.Item>
          </Breadcrumb>

          <Layout className="product-main-content-layout">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={24} md={10} lg={11} xl={12}>
                <div className="product-details-left">
                  <div className="selectedImage">
                    <img
                      src={
                        selectedImage || thumbnailImage?.url || MilanoThumbnail
                      }
                      alt="Loading..."
                    />
                  </div>
                  <div className="allImages">
                    {productImages.map((image) => (
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => setSelectedImage(image.file.url)}
                        key={image.id}
                        src={image.file.url}
                        alt={image.file.name}
                      />
                    ))}
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={14} lg={13} xl={12}>
                <div className="product-details-right">
                  <div className="products-details-right__top">
                    <h1 className="products-details-right__top__title">
                      {productDetails.name}
                    </h1>
                    <p className="products-details-right__top__dprice">
                      {productDetails.discount ? (
                        <>
                          <span
                            className="products-details-right__top__dprice__mprice"
                            style={{ textDecoration: "line-through" }}
                          >
                            {currency === "USD" ? "$" : "AED"}{" "}
                            {consverPriceToInternationalFormat(
                              productDetails.basePrice
                            )}
                          </span>
                          <span className="products-details-right__top__dprice__save">
                            Save{" "}
                            {(productDetails.discount /
                              productDetails.basePrice) *
                              100}
                          </span>
                        </>
                      ) : (
                        <>
                          {currency === "USD" ? "$" : "AED"}{" "}
                          {consverPriceToInternationalFormat(
                            productDetails.price
                          )}
                        </>
                      )}
                      <br />
                      <span className="descrp m-0"> (Price Excl. of VAT)</span>
                    </p>
                    <div
                      className="products-details-right__top__favourite"
                      onClick={() => addToWIshlistHandle(params.id)}
                    >
                      {productDetails.isWishlisted ? (
                        <>
                          <HeartFilled className="icon" /> Remove from Favourite
                        </>
                      ) : (
                        <>
                          <HeartOutlined className="icon" /> Add to Favourite
                        </>
                      )}
                    </div>
                  </div>
                  <p style={{ fontSize: "18px" }}>
                    SKU : {productDetails.skuId}
                    {productDetails.cbmValue > 0 && (
                      <> | CBM : {productDetails.cbmValue}</>
                    )}
                    {/* | CBM :{" "}
                    productDetails.cbmValue */}
                  </p>
                  <Row style={{ paddingTop: "10px" }} gutter={{ xs: 20 }}>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      className="products-details-right__middle"
                    >
                      <img src={fastIcon} className="icon" alt="icon" />
                      Fast Delivery
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      className="products-details-right__middle"
                    >
                      <img src={voucherIcon} className="icon" alt="icon" />
                      Available Voucher
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      className="products-details-right__middle"
                    >
                      <img src={packageIcon} className="icon" alt="icon" />
                      {productDetails?.label?.labels}

                      {visible &&
                        productDetails?.stock === 0 &&
                        productDetails.isSubscribed && (
                          <>
                            <Button className="notify-btn ml-2">
                              <CheckOutlined />
                              Subscribed
                            </Button>
                          </>
                        )}
                      {!visible &&
                        productDetails?.stock === 0 &&
                        !productDetails.isSubscribed && (
                          <>
                            <Button
                              className="notify-btn ml-2"
                              onClick={handleNotificaton}
                            >
                              <BellOutlined />
                              Notify Me
                            </Button>
                          </>
                        )}
                    </Col>
                  </Row>
                  {productDetails.buyGetOffers &&
                    productDetails.buyGetOffers.length !== 0 && (
                      <Divider style={{ backgroundColor: "#a7a7a7" }} />
                    )}

                  {productDetails.buyGetOffers &&
                    productDetails.buyGetOffers.map((item: any) => {
                      return (
                        <div className="d-flex mb-1 align-items-center">
                          <p
                            key={item.id}
                            className="product-detail-offer-text m-0"
                          >
                            <img src={voucherIcon} alt="" className="icon" />{" "}
                            &nbsp; Buy {item.buyQty} of this product and get{" "}
                            {item.getQty} absolutely free
                          </p>
                          <Button
                            className="notify-btn ml-2"
                            onClick={() => {
                              handleApplyOffer(item?.buyQty);
                              // if (
                              //   parseInt(productDetails?.cartoonQuantity) === 1
                              // ) {
                              //   handleApplyOffer(item?.buyQty);
                              // } else {
                              //   handleApplyOffer(
                              //     productDetails?.cartoonQuantity
                              //   );
                              // }
                            }}
                          >
                            Apply
                          </Button>
                        </div>
                      );
                    })}
                  <Divider style={{ backgroundColor: "#a7a7a7" }} />
                  <Row>
                    <Col xs={24}>
                      {/*<h4 className="descrpTitle">Description</h4>*/}
                      <p className="descrp">{productDetails?.description}</p>
                    </Col>
                  </Row>
                  <Row style={{ padding: "20px 0" }}>
                    <Col xs={24} className="productDetailsRightBottom">
                      <p>Quantity</p>
                      <div
                        style={{
                          display: "flex",
                          gap: "15px",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          className="quantityBtn minus"
                          onClick={() => {
                            if (
                              quantity >
                              parseInt(productDetails?.cartoonQuantity)
                            ) {
                              setQuantity(
                                quantity -
                                  parseInt(productDetails?.cartoonQuantity)
                              );
                            }
                          }}
                        >
                          <Remove />
                        </Button>
                        <Input
                          className={`quantityBtn value `}
                          value={quantity}
                          onChange={(e) => setQuantity(Number(e.target.value))}
                          onBlur={(e) => {
                            if (
                              Number(e.target.value) %
                                Number(productDetails?.cartoonQuantity) !=
                              0
                            ) {
                              notification.error({
                                message: `Quantity must be a multiple of ${productDetails?.cartoonQuantity}`,
                                placement: "bottomLeft",
                              });
                              setQuantity(originalQuantity);
                            }
                          }}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              if (
                                Number(e.target.value) %
                                  Number(productDetails?.cartoonQuantity) !=
                                0
                              ) {
                                notification.error({
                                  message: `Quantity must be a multiple of ${productDetails?.cartoonQuantity}`,
                                  placement: "bottomLeft",
                                });
                                setQuantity(originalQuantity);
                              }
                            }
                          }}
                        />
                        <Button
                          className="quantityBtn plus"
                          onClick={() => {
                            setQuantity(
                              quantity +
                                parseInt(productDetails?.cartoonQuantity)
                            );
                          }}
                        >
                          <Add />
                        </Button>
                      </div>

                      <AddToCartBtn
                        name={"prodDetails"}
                        quantity={quantity}
                        orderCoupon={null}
                        productId={params.id}
                        canAdd={
                          quantity % Number(productDetails?.cartoonQuantity) ==
                          0
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>

                  {productDetails?.cartoonQuantity > 1 && (
                    <p className="product-detail-offer-text">
                      This product is sold as a set of{" "}
                      {productDetails?.cartoonQuantity} items.
                    </p>
                  )}
                  <Divider style={{ backgroundColor: "#a7a7a7" }} />
                  {/* <Row className="download-row">
                  <Button className="download-details-btn" onClick={downloadProductDetails}>
                  <DownloadOutlined />
                    Download Product Details
                  </Button>

                </Row> */}
                  {productDetails?.productDescription &&
                    productDetails?.productDescription?.url && (
                      <Row>
                        <Button
                          className="download-details-btn download-tech-btn"
                          onClick={() =>
                            DownloadLink(
                              productDetails?.productDescription?.url
                            )
                          }
                        >
                          <DownloadOutlined />
                          Tech Specification
                        </Button>
                      </Row>
                    )}
                </div>
              </Col>
              <Col className="description-row" xs={24}>
                <p className="main-description">Description</p>
                <Divider
                  style={{ width: "192px !important", backgroundColor: "red" }}
                />
                {/* <hr  style={{ width:'182px',backgroundColor:'red !important' }} /> */}
                <div />
                <p
                  dangerouslySetInnerHTML={{
                    __html: productDetails.fullDescription,
                  }}
                  className="main-description-details"
                ></p>
                {/* <p className="features-title">Features :</p>
              <ul className="features-list">
                <li>
                  Preinstalled cable drive pop up and waste-overflow fitting
                  included.
                </li>
                <li>Striking upscale modern design. </li>
                <li>Extra deep, full-body soak</li>
                <li>
                  Ergonomic design forms to the body’s shape for ultimate
                  comfort.
                </li>
                <li>Quick and easy installation.</li>
                <li>Chromatherapy led light for relaxation.</li>
                <li>
                  Premium acrylic and tub thickness provides for excellent heat
                  retention
                </li>
                <li>High gloss white surface</li>
                <li>Standard Corner shape design.</li>
                <li>Non-porous surface for easy cleaning and sanitizing</li>
                <li>12 Air jets for bubble massage</li>
                <li>6 Hydro-jets for hydro massage</li>
                <li>2 Small Spine jets for Back massage</li>
                <li>
                  Solid, one-piece construction for safety and durability{" "}
                </li>
                <li>
                  Constructed of thick 100% heavy gauge sanitary grade precision
                  acrylic.
                </li>
                <li>
                  Color is consistent throughout its thickness not painted on.
                </li>
                <li>Color will not fade or lose its brilliance overtime.</li>{" "}
                <li>Wellsonic collection.</li>
                <li>Quick connect In-Line heater system(optional).</li>
                <li>
                  Soft gel Imported headrest pillow · Electronic touch pad with
                  FM system(optional).
                </li>
                <li>5 Year Manufacturing Warranty </li>
              </ul> */}
              </Col>
              <Col xs={24} className="description-row">
                <p className="main-description ">Additional Details</p>
                <Divider
                  style={{ width: "192px !important", backgroundColor: "red" }}
                />
                <div className="detail-outer-table">
                  <table className="detail-custom-table">
                    {productDetails.origin && (
                      <tr>
                        <th>Country of Origin</th>
                        <td>{productDetails?.origin}</td>
                      </tr>
                    )}
                    {productDetails.installationType && (
                      <tr>
                        <th>Installation Type</th>
                        <td>{productDetails?.installationType}</td>
                      </tr>
                    )}
                    {productDetails?.color && (
                      <tr>
                        <th>Color</th>
                        <td>{productDetails?.color}</td>
                      </tr>
                    )}
                    {productDetails.familyName && (
                      <tr>
                        <th>Family Name</th>
                        <td>{productDetails?.familyName}</td>
                      </tr>
                    )}
                    {productDetails.warranty && (
                      <tr>
                        <th>Warranty</th>
                        <td>{productDetails?.warranty}</td>
                      </tr>
                    )}
                    {productDetails.netWeight > 0 && (
                      <tr>
                        <th>Net Weight(in Kgs)</th>
                        <td>{productDetails?.netWeight}</td>
                      </tr>
                    )}
                    {productDetails.grossWeight > 0 && (
                      <tr>
                        <th>Gross Weight(in Kgs)</th>
                        <td>{productDetails?.grossWeight}</td>
                      </tr>
                    )}
                    {productDetails.size && (
                      <tr>
                        <th>Size</th>
                        <td>{productDetails?.size}</td>
                      </tr>
                    )}
                  </table>
                </div>
              </Col>

              {productsList && productsList.length ? (
                <>
                  <Col xs={24}>
                    <p className="similar-products">
                      Similar Products
                      {/* <span>View All</span> */}
                    </p>

                    <ScrollMenu
                      LeftArrow={LeftArrow}
                      RightArrow={RightArrow}
                      transitionBehavior={"smooth"}
                      transitionDuration={2000}
                    >
                      {productsList.map((product: any) => (
                        <SimilarProductCard
                          key={product?.id}
                          callBack={fetchProductDetails}
                          product={product}
                          categoryName={params.categoryName}
                        />
                      ))}
                    </ScrollMenu>
                  </Col>
                </>
              ) : null}
            </Row>
          </Layout>
        </Content>
      </Layout>
    </>
  );
}
