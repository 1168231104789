import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import voucherIcon from "shared/assets/images/voucher.png";

import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import { Button, Card, Carousel, Col, Layout, notification, Row } from "antd";
import ProductCard from "components/ProductCard/ProductCard";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CATEGORIES } from "shared/constants/RouteConstants";

import { ArrowRightOutlined } from "@material-ui/icons";
import AddToCartBtn from "components/AddToCartBtn";

import API_SERVICE from "services/api-service";
import {
  PRODUCT_DETAILS_PAGE,
  PRODUCT_MAIN,
} from "shared/constants/RouteConstants";
import "./home.scss";

import MilanoThumbnail from "../../../assets/MilanoThumbnail.png";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import {
  consverPriceToInternationalFormat,
  textTransform,
} from "services/util";
import { acceptedVideosTypes } from "shared/constants/Constants";
import { Autoplay, Pagination } from "swiper";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/swiper.min.css";

export default function Home(props: any) {
  const history = useHistory();
  const [mutedState, setMutedState] = useState(false);

  const [time, setTime] = useState({
    Days: 0,
    Hours: 0,
    Minutes: 0,
    Seconds: 0,
  });

  const [featuredProducts, setFeaturedProducts] = useState({
    items: [],
  });
  const [popularProducts, setPopularProducts] = useState([]);
  const [browserCatogory, setBrowserCatogory] = useState([]);
  const [popularFamily, setPopularFamily] = useState([]);
  const [banners, setBanners] = useState([]);

  const [featuredImage, setFeaturedImage] = useState({
    url: "",
    deadline: "22 January 2023",
  });
  const [longImage, setLongImage] = useState();
  const [leftImage, setLeftImage] = useState();
  const [rightImage, setRightImage] = useState();
  const [bottomBanner, setBottomBanner] = useState();

  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1023);
  };

  useEffect(() => {
    if (window.innerWidth > 1023) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const bannerSettings = {
    dots: true,
    infinite: true,
    initialSlide: 0,
    centerMode: true,
    speed: 1000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
        },
      },
    ],
  };

  const settingsFeatured = {
    dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 986,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const settingsCategory = {
    draggable: true,
    className: "center",
    infinite: true,
    dots: false,
    initialSlide: 0,
    slidesToShow: 5,
    slideToScroll: 3,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          initialSlide: 0,
          slidesToShow: 3,
          slideToScroll: 2,

          slidesToScroll: 1,
        },
      },
    ],
  };

  const popularFamilySettings = {
    slidesPerView: 1,
    spaceBetween: 10,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 0,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 50,
      },
    },
  };

  const fetchFeatureProducts = async () => {
    try {
      let {
        data: { data },
      } = await API_SERVICE.fetchFeatureProducts();
      // console.log((data = data.featured.slice(0, 4)));
      if (data.items.length !== 0) {
        if (!isDesktop) {
          if (data.items.length > 5) data.items = data.items.slice(0, 4);
          setFeaturedProducts({ ...data });
        } else setFeaturedProducts({ ...data });
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };
  const fetchBrowserCategory = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchBrowserCategory({ page: -1 });
      if (data.length !== 0) {
        setBrowserCatogory(
          data.items
            .filter((item: any) => item?.isActive === true)
            .sort((a: any, b: any) => {
              return a.order - b.order;
            })
        );
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };
  const fetchPopularProducts = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchPopularProducts({});
      if (data.items.length !== 0) {
        if (isDesktop) {
          setPopularProducts(data.items.slice(0, 4));
        } else {
          setPopularProducts(data.items.slice(0, 2));
        }
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };
  const fetchPopularFamily = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchPopularFamily({});

      if (data.length !== 0) {
        setPopularFamily(data);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };
  const getBanners = () => {
    try {
      const res = API_SERVICE.getHomeBanners({});
      res.then((data) => {
        const { items } = data?.data?.data;
        if (items) {
          const activeSlides = items.filter(
            (item: any) => item.status === "ACTIVE"
          );
          let bannerItems = activeSlides.sort(
            (a: { displayOrder: number }, b: { displayOrder: number }) => {
              const displayOrderA = a.displayOrder;
              const displayOrderB = b.displayOrder;
              return displayOrderA - displayOrderB;
            }
          );
          setBanners(bannerItems);
        }
      });
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };

  const getHomeOtherBanner = async (type: string) => {
    try {
      const params = {
        bannerType: type,
      };
      const {
        data: { data },
      } = await API_SERVICE.getHomeOtherBanner(params);

      if (data.length !== 0) {
        if (type === "FEATURED_PRODUCT") {
          setFeaturedImage({
            url: data.file.url,
            deadline: data.featuredProductValidity,
          });
        }
        if (type === "LONG_IMAGE") {
          setLongImage(data);
        }
        if (type === "LEFT_IMAGE") {
          setLeftImage(data);
        }
        if (type === "RIGHT_IMAGE") {
          setRightImage(data);
        }
        if (type === "BOTTOM_BANNER") {
          setBottomBanner(data);
        }
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };

  const bannersUrl = (type: string, obj: any) => {
    if (type === "CATEGORY") {
      history.push(`${PRODUCT_MAIN}/${obj.typeId}/${obj.typeDetails.name}`);
    }
    if (type === "PRODUCT") {
      history.push(
        `${PRODUCT_DETAILS_PAGE}/${obj.typeId}/${obj.typeDetails.category.name}`
      );
    }
    if (type === "CUSTOM") {
      window.open(obj.customUrl, "_blank");
    }
  };

  const getTime = () => {
    const time = Date.parse(featuredImage.deadline) - Date.now();
    setTime({
      Days: time > 0 ? Math.floor(time / (1000 * 60 * 60 * 24)) : 0,
      Hours: time > 0 ? Math.floor((time / (1000 * 60 * 60)) % 24) : 0,
      Minutes: time > 0 ? Math.floor((time / 1000 / 60) % 60) : 0,
      Seconds: time > 0 ? Math.floor((time / 1000) % 60) : 0,
    });
  };

  useEffect(() => {
    document.title = "Home Page";
    getHomeOtherBanner("FEATURED_PRODUCT");
    getHomeOtherBanner("LONG_IMAGE");
    getHomeOtherBanner("LEFT_IMAGE");
    getHomeOtherBanner("RIGHT_IMAGE");
    getHomeOtherBanner("BOTTOM_BANNER");
    getBanners();
    fetchFeatureProducts();
    fetchBrowserCategory();
    fetchPopularProducts();
    fetchPopularFamily();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);

    return () => clearInterval(interval);
  }, [featuredImage.deadline]);

  const muteHandler = (id: string) => {
    const el = document.getElementById(id);
    if (el.muted === false) {
      el.muted = true;
      return;
    }
    const allVideoTags = document.querySelectorAll("video");
    allVideoTags.forEach((video) => (video.muted = false));
    el.muted = false;
  };
  return (
    <div className="content">
      <div className="carousel">
        <Carousel {...bannerSettings} autoplay>
          {banners.map((item: any) => {
            const ext = item.file?.url.slice(
              ((item.file?.url.lastIndexOf(".") - 1) >>> 0) + 2
            );

            return acceptedVideosTypes.includes(ext) ? (
              <>
                <div className="bannerVideoDiv">
                  <button
                    onClick={() => {
                      setMutedState(!mutedState);
                      muteHandler(item.id);
                    }}
                  >
                    {mutedState ? <VolumeUpIcon /> : <VolumeOffIcon />}
                  </button>
                  <video
                    id={item.id}
                    style={{ cursor: "auto", aspectRatio: "16 / 9" }}
                    width="100%"
                    height="100%"
                    autoPlay
                    loop
                    muted
                    className="photo photo-banner"
                    onClick={() => {
                      if (item.type === "CATEGORY") {
                        history.push(
                          `${PRODUCT_MAIN}/${item.typeId}/${item.typeDetails.name}`
                        );
                      }
                      if (item.type === "PRODUCT") {
                        history.push(
                          `${PRODUCT_DETAILS_PAGE}/${item.typeId}/${item.typeDetails.category.name}`
                        );
                      }
                      if (item.type === "CUSTOM") {
                        window.open(item.customUrl, "_blank");
                      }
                    }}
                  >
                    <source src={item.file?.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </>
            ) : (
              <>
                <div>
                  <img
                    style={{ cursor: "auto" }}
                    className="photo photo-banner"
                    src={item?.file?.url}
                    onClick={() => {
                      if (item.type === "CATEGORY") {
                        history.push(
                          `${PRODUCT_MAIN}/${item.typeId}/${item.typeDetails.name}`
                        );
                      }
                      if (item.type === "PRODUCT") {
                        history.push(
                          `${PRODUCT_DETAILS_PAGE}/${item.typeId}/${item.typeDetails.category.name}`
                        );
                      }
                      if (item.type === "CUSTOM") {
                        window.open("http://" + item.customUrl, "_blank");
                      }
                    }}
                    alt=""
                  />
                </div>
              </>
            );
          })}
        </Carousel>
      </div>
      <Layout className="home-main-content-layout">
        <div className="category">
          <div className="category-title">
            Browse Category <Link to={CATEGORIES}>View All</Link>
          </div>
          <div className="category-photo">
            {isDesktop ? (
              <>
                <Row
                  style={{ margin: 0 }}
                  gutter={{ xs: 8, sm: 16, md: 24 }}
                  align="middle"
                  justify="space-between"
                >
                  {browserCatogory.length != 0 ? (
                    browserCatogory.slice(0, 7).map((product: any) => {
                      return (
                        <>
                          <Col
                            className="gutter-row"
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={2.5}
                          >
                            <Link
                              to={
                                PRODUCT_MAIN +
                                "/" +
                                product.id +
                                "/" +
                                product.name
                              }
                            >
                              <figure>
                                <img
                                  className="photo"
                                  src={
                                    product.file?.url
                                      ? product.file?.url
                                      : MilanoThumbnail
                                  }
                                  alt=""
                                />
                                <figcaption className="img-text text-truncate">
                                  {textTransform(product.name)}
                                </figcaption>
                              </figure>
                            </Link>
                          </Col>
                        </>
                      );
                    })
                  ) : (
                    <> "No Categories Available"</>
                  )}
                  <Col
                    className="gutter-row "
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    xs={3}
                    sm={3}
                    md={3}
                    lg={2}
                  >
                    <Button
                      className="category-btn"
                      shape="circle"
                      icon={<ArrowRightOutlined />}
                      onClick={() => {
                        history.push(CATEGORIES);
                      }}
                    />
                    <p
                      onClick={() => {
                        history.push(CATEGORIES);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      View All
                    </p>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row
                  style={{ margin: 0 }}
                  gutter={{ xs: 8, sm: 16, md: 24 }}
                  align="middle"
                  justify="space-between"
                >
                  <Col sm={24} xs={24} md={24}>
                    <Carousel {...settingsCategory} autoplay>
                      {browserCatogory.length !== 0 ? (
                        browserCatogory.map((product: any) => {
                          return (
                            <div>
                              <Link
                                to={
                                  PRODUCT_MAIN +
                                  "/" +
                                  product.id +
                                  "/" +
                                  product.name
                                }
                              >
                                <figure className="browerCategoryFigure">
                                  <img
                                    className="photo"
                                    src={
                                      product.file?.url
                                        ? product.file?.url
                                        : MilanoThumbnail
                                    }
                                    alt=""
                                  />
                                  <figcaption className="img-text">
                                    {product.name}
                                  </figcaption>
                                </figure>
                              </Link>
                            </div>
                          );
                        })
                      ) : (
                        <>"No Categories Available</>
                      )}{" "}
                    </Carousel>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>

        <div className="featured">
          <Row className="featured-title-row m-0" align="bottom">
            <Col xs={15} md={18} lg={18} xl={23} className="featured-title ">
              Featured Products
            </Col>
            <Col xs={9} md={6} lg={6} xl={1}>
              <div
                className="view-popular "
                onClick={() =>
                  history.push(PRODUCT_MAIN + "/FeaturedProducts", "featured")
                }
              >
                <Link to={""} className="padding-0">
                  View All
                </Link>
              </div>
            </Col>
          </Row>

          <Row
            gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}
            className="featured-row"
            justify="space-between"
          >
            <Col className="gutter-row" xs={10} sm={10} md={12} lg={8} xl={6}>
              <div
                className="featuredCardLeft"
                style={{
                  backgroundImage: `url(${featuredImage?.url})`,
                  backgroundSize: "cover",
                }}
              >
                {/* <div className="discountPercent">
                  Up to <br /> 30% Off!
                </div>
                <img src={tap} alt="" /> */}
                <div className="featured-timer">
                  <div className="featured-hour">
                    <div>
                      <b>{time.Days}</b>
                    </div>
                    <div>days</div>
                  </div>
                  <div className="featured-hour">
                    <div>
                      <b>{time.Hours}</b>
                    </div>
                    <div>Hrs</div>
                  </div>
                  <div className="featured-min">
                    <div>
                      <b>{time.Minutes}</b>
                    </div>
                    <div>Min</div>
                  </div>
                  <div className="featured-sec">
                    <div>
                      <b>{time.Seconds}</b>
                    </div>
                    <div>Sec</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              className="gutter-row "
              xs={14}
              sm={14}
              md={12}
              lg={16}
              xl={18}
            >
              <div className="featured-carousal">
                <Carousel {...settingsFeatured}>
                  {featuredProducts?.items.length !== 0 ? (
                    featuredProducts.items.map((product) => {
                      return <Featured product={product} />;
                    })
                  ) : (
                    <></>
                  )}
                </Carousel>

                {/* <Carousel {...settingsFeatured}>
                  {featuredProducts.featured.length !== 0 ? (
                    featuredProducts.featured.map((product) => {
                      return <Featured product={product} />;
                    })
                  ) : (
                    <></>
                  )}
                </Carousel> */}
              </div>
            </Col>
          </Row>
        </div>

        <div className="popular">
          <Row className="m-0" align="middle" justify="space-between">
            <Col>
              <div className="popular-title">Popular Product</div>
            </Col>
            <Col>
              <div className="view-popular">
                <Link to={PRODUCT_MAIN + "/Popular Products"}>View All</Link>
              </div>
            </Col>
          </Row>
          <Row style={{ margin: 0 }} className="popular-row">
            {popularProducts.length !== 0 ? (
              popularProducts.map((product: any) => {
                return (
                  <>
                    <Col
                      xl={6}
                      lg={8}
                      md={12}
                      sm={12}
                      xs={12}
                      className="flex justify-content-between "
                    >
                      <ProductCard
                        product={product}
                        callback={fetchPopularProducts}
                      />
                    </Col>
                  </>
                );
              })
            ) : (
              <></>
            )}
          </Row>
        </div>

        <div className="long-img">
          <img
            className="home-long-img"
            src={longImage?.file?.url}
            alt={longImage?.file?.name}
            onClick={() => bannersUrl(longImage?.type, longImage)}
          />
        </div>
        <div className="m-0 short-img">
          <div>
            <img
              className="short-img-photo"
              src={leftImage?.file?.url}
              alt={leftImage?.file?.name}
              onClick={() => bannersUrl(leftImage?.type, leftImage)}
            />
          </div>
          <div>
            <img
              className="short-img-photo"
              src={rightImage?.file?.url}
              alt={rightImage?.file?.name}
              onClick={() => bannersUrl(rightImage?.type, rightImage)}
            />
          </div>
        </div>

        <div className="popular-seller">
          <Row className="m-0" align="middle" justify="space-between">
            <Col>
              <div className="popular-seller-title">Popular Family</div>
            </Col>
          </Row>
          <Row className="m-0" align="middle" justify="space-between">
            <Col sm={24} xs={24} md={24}>
              <Swiper
                {...popularFamilySettings}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
              >
                {popularFamily.length != 0 ? (
                  popularFamily.map((product, index) => {
                    return (
                      <SwiperSlide>
                        <PopularSellerCard product={product} />
                      </SwiperSlide>
                    );
                  })
                ) : (
                  <></>
                )}
              </Swiper>
            </Col>
          </Row>
        </div>

        <Row className="bottomBanner">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className="bottomBannerLeft"
            style={{ backgroundImage: `url(${bottomBanner?.file?.url})` }}
            onClick={() => bannersUrl(bottomBanner?.type, bottomBanner)}
          >
            {/* <div className="overlay"></div> */}
            {/* <div className="content"> */}
            {/* <h2 className="title">Lorem ipsem amet</h2>
              <p className="descrip">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              </p> */}
            {/* <Button>
                Explore Ranges <ArrowRightOutlined />
              </Button> */}
            {/* </div> */}
          </Col>
        </Row>
      </Layout>
    </div>
  );
}

function Featured(props: any) {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user")!);
  const currency = user?.user.dealerDetails[0]?.currencyType;
  const handleClick = () => {
    history.push(
      PRODUCT_DETAILS_PAGE +
        "/" +
        props.product.id +
        "/" +
        props.product.category.name
    );
  };
  const truncate = (title: string) => {
    return title.length > 40 ? title.substring(0, 40) + "..." : title;
  };

  return (
    <div className="featured-card" style={{ cursor: "pointer" }}>
      <div className="offer-line">
        {/* {props.product?.buyGetOffers &&
          props.product?.buyGetOffers.map((item: any) => {
            return (
              <p key={item.id} className="product-detail-offer-text">
                Buy {item.buyQty} Get {item.getQty} free
              </p>
            );
          })} */}
        {props.product?.buyGetOffers && props.product?.buyGetOffers[0] && (
          <p className="product-detail-offer-text">
            Buy {props.product?.buyGetOffers[0]?.buyQty} Get{" "}
            {props.product?.buyGetOffers[0]?.getQty} free
          </p>
        )}
      </div>
      <div className="featured-image">
        <img
          src={
            props.product.thumbnail !== null
              ? props.product.thumbnail?.url
              : MilanoThumbnail
          }
          alt="product"
          onClick={handleClick}
        />
      </div>
      <div className="product-details">
        <Row justify="center">
          <h3 className="featured-p" onClick={handleClick}>
            {truncate(props.product?.name)}
          </h3>
        </Row>
        <Row align="middle" justify="center">
          {props.product?.discount > 0 && (
            <Col>
              <p className="cancel-price">
                ${consverPriceToInternationalFormat(props.product?.base_price)}
              </p>
            </Col>
          )}
          <Col>
            <p className="new-price">
              {props.product?.discount > 0 && "- "}{" "}
              {currency === "USD" ? "$" : "AED"}{" "}
              {consverPriceToInternationalFormat(props.product?.price)}
              {parseInt(props.product?.cartoonQuantity) > 1 && (
                <span> per piece</span>
              )}
              {parseInt(props.product?.cartoonQuantity) > 1 && (
                <span className="theme-p ml-1">
                  (Set Of {props.product?.cartoonQuantity})
                </span>
              )}
            </p>
          </Col>
        </Row>
        <div className="inventory-info mb-2">
          <div>
            <p>SKU</p>
            <p className="m-0">{props.product?.skuId}</p>
          </div>
          <div>
            <p>{props?.product?.label?.labels}</p>
          </div>
        </div>

        <div className="AddToCartBtnContainer">
          <AddToCartBtn
            quantity={props.product.cartoonQuantity}
            orderCoupon={null}
            productId={props.product?.id}
            name={""}
          />
        </div>
      </div>
    </div>
  );
}

function PopularSellerCard(props: any) {
  return (
    <Link to={`${PRODUCT_MAIN}/PopularFamily/${props.product.familyName}`}>
      <Card className="card-box">
        <p className="img-text mb-0">{props.product.familyName}</p>
        <p className="quantity-text mb-0">{props.product.count} Products</p>
      </Card>
    </Link>
  );
}
