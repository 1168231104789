import { Close, ShoppingBasket } from "@material-ui/icons";
import { Button, Col, notification } from "antd";
import CartContext from "context/Cart";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import API_SERVICE from "services/api-service";
import { consverPriceToInternationalFormat } from "services/util";
import { PRODUCT_DETAILS_PAGE } from "shared/constants/RouteConstants";
import "./index.scss";
import MilanoThumbnail from "../../assets/MilanoThumbnail.png";

const WishlistCard = (props: any) => {
  const { fetchCartDetails } = useContext(CartContext);

  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user")!);
  const currency = user?.user.dealerDetails[0]?.currencyType;

  const fetchWishlistData = async (
    page?: number,
    perpage?: number,
    search?: string
  ) => {
    try {
      const params = { ...props.pagination };
      if (search) {
        params["q"] = search;
        params["page"] = 1;
      }
      if (page) {
        params["page"] = page;
      }
      if (perpage) {
        params["perpage"] = perpage;
      }
      const {
        data: { data },
      } = await API_SERVICE.fetchWishlist(params);
      props.setWishlistCart({ ...data });
      props.setPagination({
        page: data.productsWishlisted.page,
        perpage: data.productsWishlisted.perpage,
        pages: data.productsWishlisted.pages,
      });
      props.setTotal(data.productsWishlisted.total);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  async function WishListDelete(id: number, moveToCart?: boolean) {
    console.log(props.wishlistCart.productsWishlisted);
    try {
      await API_SERVICE.WishListDelete(id);
      if (moveToCart === false) {
        if (props.wishlistCart.productsWishlisted.items.length === 1) {
          notification.success({
            message: "Deleted Succesfully",
            placement: "bottomLeft",
          });
          let page = props.pagination.page - 1;
          props.setPagination((prev: { page: number }) => {
            return prev.page - 1;
          });
          fetchWishlistData(page);
        } else {
          notification.success({
            message: "Deleted Succesfully",
            placement: "bottomLeft",
          });
          fetchWishlistData(props.pagination.page);
        }
      } else {
        if (props.wishlistCart.productsWishlisted.items.length === 1) {
          let page = props.pagination.page - 1;
          props.setPagination({ page: props.pagination.page - 1 });
          fetchWishlistData(page);
        } else {
          fetchWishlistData(props.pagination.page);
        }
      }

      // if (moveToCart === false) {
      //   notification.success({
      //     message: 'Deleted Succesfully',
      //     placement: 'bottomLeft',
      //   })
      //   fetchWishlistData()
      // } else {
      //   fetchWishlistData()
      // }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  }

  const addToCart = async (productId: number) => {
    try {
      await API_SERVICE.addToCart({
        productId: productId,
        orderCoupon: null,
        quantity: 1,
      });

      notification.success({
        message: "Moved To Cart",
        placement: "bottomLeft",
      });
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };

  async function handleMoveToCart(id: number, isHandleMoveCart: true) {
    await WishListDelete(id)
      .then(() => addToCart(id))
      .catch((error) =>
        notification.error({
          message: "Something Else",
          placement: "bottomLeft",
        })
      );
    fetchCartDetails();
  }

  const handleClick = () => {
    history.push(
      `${PRODUCT_DETAILS_PAGE}/${props.product.id}/${props.product.category.name}`
    );
  };

  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        className="productCardMain wishlistCard"
      >
        <div className="product-image">
          <img
            src={
              props.product.thumbnail !== null
                ? props.product.thumbnail.url
                : MilanoThumbnail
            }
            alt="product-image"
            onClick={handleClick}
          />
        </div>
        <div className="product-details">
          <h3 className="title text-truncate" onClick={handleClick}>
            {" "}
            {props.product?.name}
          </h3>
          <p className="price">
            {currency === "USD" ? "$" : "AED"}{" "}
            {consverPriceToInternationalFormat(props.product?.price)}
          </p>
          <div className="inventory-info wishlist-card-iventory">
            <div>
              <p>SKU</p>
              <p>{props.product?.skuId}</p>
            </div>
            <div>
              <p>{props.product?.label?.labels}</p>
            </div>
          </div>
          <div className="wishlist-card-cancel">
            <Button onClick={() => handleMoveToCart(props.product.id, true)}>
              <ShoppingBasket /> Move to cart
            </Button>

            <Button onClick={() => WishListDelete(props.product.id, false)}>
              <Close />
            </Button>
          </div>
        </div>
      </div>
    </>
  );

  // return (
  //   <div className="cart-product-card checkout-product-card wishlist-card">
  //     <div
  //       className="image checkout-card-image wishlist-card-image"
  //       onClick={handleClick}
  //     >
  //       <img
  //         src={
  //           props.product.thumbnail !== null
  //             ? props.product.thumbnail.url
  //             : MilanoThumbnail
  //         }
  //         style={{ width: "90px", height: "90px", objectFit: "cover" }}
  //         alt="product-image"
  //       />
  //     </div>
  //     <div className="details checkout-card-details wishlist-card-details">
  //       <p className="title" onClick={handleClick}>
  //         {props.product.name}
  //       </p>
  //       <div className="sku">
  //         <p> SKU: {props.product.skuId}</p>
  //         <p>CBM:{props.product.cbmValue}</p>
  //       </div>
  //       <p className="price">
  //         {" "}
  //         {currency === "USD" ? "$" : "AED"}{" "}
  //         {consverPriceToInternationalFormat(props.product.price)}{" "}
  //       </p>
  //     </div>

  //     <div className="cancel checkout-card-cancel wishlist-card-cancel">
  //       <Button
  //         className="theme-btn1 "
  //         onClick={() => handleMoveToCart(props.product.id, true)}
  //       >
  //         <ShoppingBasket className="icon" />
  //         Move to cart
  //       </Button>
  //       <Button
  //         className="theme-btn1"
  //         onClick={() => WishListDelete(props.product.id, false)}
  //       >
  //         <Close className="icon" />
  //       </Button>
  //     </div>
  //   </div>
  // );
};

export default WishlistCard;
