import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
const Loader = () => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 50, color: "white" }} spin />
  );
  return (
    <div className="loader-main">
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Loader;
