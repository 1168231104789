import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./CustomNotification.scss";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";

import {
  SearchOutlined,
  BellOutlined,
  DownOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import logoTransparent from "shared/assets/logoTransparent.png";
import {
  Layout,
  Menu,
  Row,
  Col,
  Input,
  Badge,
  Dropdown,
  Space,
  Progress,
  Divider,
  Modal,
  Button,
  DatePicker,
  Form,
  Select,
  Table,
  Pagination,
} from "antd";
import { notification } from "antd";
import { useEffect } from "react";
import moment from "moment";

import AdminListLayout from "shared/components/layout/AdminListLayout/AdminListLayout";
import API_SERVICE from "services/api-service";
import {
  consverPriceToInternationalFormat,
  textTransform,
} from "services/util";
import { padding } from "@material-ui/system";
import { NEW_NOTIFICATION } from "shared/constants/RouteConstants";
import { imageListClasses } from "@material-ui/core";
import AccessControl from "services/AccessControl";

const { Header, Sider, Content } = Layout;

// const { RangePicker } = DatePicker;

interface Props {
  location: any;
}

const CustomNotification = () => {
  let history = useHistory();
  const [filteredData, setFilteredData] = useState([]);
  const [filterAttributes, setFilterAttributes] = useState({});

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: "15",
  } as any);
  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);

  //   const viewOrderClick = (id?: number) => {
  //     history.push(`order-details/${id}`, id);
  //   };

  const getNotifications = async (
    pagination = {},
    sort = {},
    filters = {},
    search = {}
  ) => {
    try {
      // const params = {
      //   ...pagination,
      //   ...sort,
      //   ...filters,
      //   ...search,
      // };
      const data = await API_SERVICE.getCustomNotification();
      setLoading(false);
      if (data) {
        setFilteredData(data.data.data.items);
        setTotal(data?.data?.data?.total);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const columns = [
    {
      title: "S.N.",
      dataIndex: "serialNumber",
      // sorter: (a: any, b: any) => a.serialNumber - b.serialNumber,
      render: (a: any, v: any, index: number) => (
        <div>{(pagination.page - 1) * pagination.perpage + index + 1}</div>
      ),
    },
    {
      title: "TITLE : ",
      key: "title",
      dataIndex: "title",
    },
    {
      title: "DESCRIPTION : ",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "SEND DATE : ",
      key: "sendDate",
      render: (d: any) => {
        return moment(d?.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      title: "SENT TIME : ",
      key: "sentTime",
      render: (d: any) => {
        return moment(d?.createdAt).format("LT");
      },
    },
    {
      title: "IMAGE ",
      dataIndex: "file",
      render: (_file: any) => {
        return (
          <a href={_file?.url} target="_blank" rel="noreferrer">
            <img
              className="bannerTableImg"
              src={_file?.url}
              alt={_file?.name}
            />
          </a>
        );
      },
    },
    {
      title: "ACTION: ",
      // dataIndex: "action",
      render: (a: any) => (
        <Button
          shape="circle"
          onClick={() => {
            history.push(`${NEW_NOTIFICATION}/${a.id}`);
          }}
        >
          <EyeOutlined />
        </Button>
      ),
    },
  ];

  return (
    <AccessControl id={37}>
    <>
      <Row
        className="align-items-center  stock-summary-container mb-2 mt-2"
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}
        style={{ padding: "10px" }}
      >
        <Col xs={10}>
          <div className="manage-products-title">Custom Notifications</div>
        </Col>
        <AccessControl id={41}>
        <Col xs={14} className="flex justify-content-end">
          <Button
            type="primary"
            className="theme-btn sales-table-button ml-2 product-btn"
            onClick={() => history.push(NEW_NOTIFICATION)}
          >
            <PlusOutlined />
            New Notification
          </Button>
        </Col>
        </AccessControl>
      </Row>
      <Row className="manage-order-main-content">
        <Col xs={24} className="mb-3">
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={filteredData}
            size="middle"
            pagination={{
              size: "default",
              current: pagination.page,
              pageSize: pagination.perpage,
              total: total,
            }}
            // onChange={_handleTableChanges}
          />
        </Col>
      </Row>
    </>
    </AccessControl>
  );
};

export default CustomNotification;
