import {
  Row,
  Col,
  Button,
  Layout,
  Breadcrumb,
  Form,
  Input,
  Table,
  Select,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { CloudDownload, SearchOutlined } from "@material-ui/icons";
import { Option } from "antd/lib/mentions";
import { DASHBOARD, ORDER_DETAILS } from "shared/constants/RouteConstants";
import { DatePicker, Space } from "antd";
import { notification } from "antd";
import moment from "moment";
import API_SERVICE from "services/api-service";
import { textTransform } from "../../../services/util";
const { RangePicker } = DatePicker;
export default function RevenueSummary(props: any) {
  const user = JSON.parse(localStorage.getItem("user")!);
  const currency = user?.user.dealerDetails[0]?.currencyType;
  const [fetching, setFetching] = useState(true);
  const [fetchingXls, setFetchingXls] = useState(false);
  const [fetchingPdf, setFetchingPdf] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [status, setStatus] = useState([]);
  const [data, setData] = useState();

  const onDateSelect = (val: any) => {
    fetchSummary(
      moment(val[0]._d).format("YYYY-MM-DD"),
      moment(val[1]._d).format("YYYY-MM-DD")
    );
  };

  const fetchSummary = async (
    startDate: any,
    endDate: any,
    searchValue?: string
  ) => {
    try {
      const params = {
        startDate,
        endDate,
      };
      if (searchValue) {
        //@ts-ignore
        params["q"] = searchValue;
      }
      if (filterValue) {
        //@ts-ignore
        params["status"] = filterValue;
      }

      const data = await API_SERVICE.getRevenueSummary(params);
      // setLoading(false);
      setData(data.data.data.items);
      // if (data) {
      //   setData(data.data.data.items);
      //   setTotal(data?.total);
      // }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    } finally {
      setFetching(false);
    }
  };

  const history = useHistory();
  //RangePicker Date Calculations
  const today = moment();
  const diff = today.subtract(1, "year");
  const previousYear = diff.format("YYYY-MM-DD");
  const currentYear = moment(new Date()).format("YYYY-MM-DD");

  const columns = [
    {
      title: "ORDER ID",
      render: (d: { id: number; orderNumber: string }) => {
        return (
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              history.push(`${ORDER_DETAILS}/${d.id}`);
            }}
          >
            <b>{d.orderNumber}</b>
          </span>
        );
      },
    },
    {
      title: "DATE",
      render: (d: any) => {
        return <>{moment(d?.createdAt).format("YYYY-MM-DD")}</>;
      },
    },
    // {
    //   title: "DEALER DEATILS:",
    //   dataIndex: "user",
    //   render: (d: any) => {
    //     return (
    //       <>
    //         {d.name}
    //         <br />
    //         {d.dealerCode}
    //       </>
    //     );
    //   },
    // },
    {
      title: "TOTAL AMOUNT",
      dataIndex: "",
      width: "250px",
      render: (_d: any) => {
        return (
          <>
            {currency === "USD" ? "$" : "AED"} {_d?.totalAmount}
          </>
        );
      },
    },
    {
      title: "TOTAL CBM",
      dataIndex: "totalCbm",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (d: string) => {
        const newValue = textTransform(d);
        return newValue;
      },
    },
  ];

  const fetchDealerTotalRevenue = async () => {
    try {
      setFetchingXls(true);
      const {
        data: { data },
      } = await API_SERVICE.GetDealerTotalRevenue();
      window.open(data.url);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    } finally {
      setFetchingXls(false);
    }
  };

  const fetchRevenuePdf = async () => {
    try {
      setFetchingPdf(true);
      const {
        data: { data },
      } = await API_SERVICE.getRevenuePdf();
      window.open(data.url);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    } finally {
      setFetchingPdf(false);
    }
  };

  const orderSummaryStatusAttributes = async (startDate: any, endDate: any) => {
    try {
      const params = {
        startDate,
        endDate,
      };

      const {
        data: { data },
      } = await API_SERVICE.orderSummaryStatusAttributes(params);

      if (data.status) setStatus(data.status);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  const _handleSearch = (values: { searchInput: string }) => {
    fetchSummary(
      moment(diff).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD"),
      values.searchInput
    );
  };

  useEffect(() => {
    fetchSummary(previousYear, currentYear);
    orderSummaryStatusAttributes(previousYear, currentYear);
    document.title = "Milano Admin |  Stock Summary";
  }, []);

  useEffect(() => {
    fetchSummary(previousYear, currentYear);
  }, [filterValue]);

  return (
    <Layout className="cart-bg-color">
      <Content className="contentPadding">
        <Breadcrumb className="products-breadcrumb mt-2 mb-2" separator=">">
          <Breadcrumb.Item>
            <Link to={DASHBOARD}>Dashboard</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="products-breadcrumb-active">
            Order Summary
          </Breadcrumb.Item>
        </Breadcrumb>

        <Layout className="cart-main-content-layout">
          <Row
            className="align-items-center stock-summary-container mb-2 mt-2"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
            }}
          >
            <Col xs={24} sm={24} md={12} lg={10} xl={10} xxl={4}>
              <h4 className="admin-page-title">Order Summary</h4>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={7}
              xl={7}
              xxl={4}
              className="flex align-items-center "
            >
              <Form
                onFinish={_handleSearch}
                className="status-form-order-summary"
              >
                <Form.Item
                  label="Search"
                  name="searchInput"
                  className="dashboard-label"
                >
                  <Input
                    className="dashboard-input"
                    placeholder="Search Item"
                    suffix={<SearchOutlined />}
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={7}
              xl={7}
              xxl={4}
              className="flex align-items-center"
            >
              <Form className="status-form-order-summary">
                <Form.Item
                  label="Status"
                  name="categoryName"
                  className="dashboard-label "
                >
                  <Select
                    allowClear
                    onChange={(value: string) => {
                      setFilterValue(value);
                    }}
                    className="dashboard-select"
                    placeholder="Select_Status"
                    options={status.map((s) => {
                      return { value: s, label: textTransform(s) };
                    })}
                  ></Select>
                </Form.Item>
              </Form>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              xxl={6}
              className="flex align-items-center "
            >
              <Form className="status-form-order-summary">
                <Form.Item
                  label="Order Date"
                  name="categoryName"
                  className="dashboard-label"
                >
                  <RangePicker
                    style={{ flexWrap: "nowrap" }}
                    defaultValue={[
                      moment(diff, "YYYY-MM-DD"),
                      moment(new Date(), "YYYY-MM-DD"),
                    ]}
                    onChange={onDateSelect}
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              xxl={3}
              // className="flex "
            >
              <Button
                loading={fetchingXls}
                className="dashboard-btn"
                onClick={fetchDealerTotalRevenue}
              >
                <CloudDownload className="icon" />
                Download XLS
              </Button>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              xxl={3}
              // className="flex "
            >
              <Button
                loading={fetchingPdf}
                className="dashboard-btn"
                onClick={fetchRevenuePdf}
              >
                <CloudDownload className="icon" />
                Download PDF
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={24} className="mb-3">
              <Table
                loading={fetching}
                columns={columns}
                dataSource={data}
                size="middle"
                scroll={{ x: "auto" }}
              />
            </Col>
          </Row>
        </Layout>
      </Content>
    </Layout>
  );
}
