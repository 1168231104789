import { Breadcrumb, Button, Col, Layout, Row } from "antd";

import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { HOME } from "shared/constants/RouteConstants";
import "./index.scss";

export default function PrivacyPolicy(props: any) {
  const [isArabic, setIsArabic] = useState(false);
  useEffect(() => {
    document.title = "Privacy Policy";
  }, []);

  return (
    <Layout className="dashboard-bg-color">
      <Breadcrumb className="products-breadcrumb" separator=">">
        <Breadcrumb.Item>
          <Link to={HOME}>Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="products-breadcrumb-active">
          Privacy Policy
        </Breadcrumb.Item>
      </Breadcrumb>
      <Row className="pb-5 privacy-policy-container">
        <Col xs={24} className="dealer-profile-container">
          <h1 className="page-title text-center">
            {isArabic ? "سياسة الخصوصية" : "Privacy Policy"}
          </h1>
        </Col>
        <Col xs={24} className="d-flex justify-content-end">
          <Button
            onClick={() => setIsArabic(!isArabic)}
            style={{ maxWidth: "280px" }}
            className="action-button-red mb-3"
          >
            {isArabic ? "Switch to English" : "Switch to Arabic"}
          </Button>
        </Col>
        {isArabic ? (
          <Col xs={24} className="text-align-right">
            <p>
              خصوصيتك أمر مهم بالنسبة لنا. سياسة الخصوصية الماثلة ("سياسة
              الخصوصية") هي طريقتنا لإعلامك بكيفية جمعنا لمعلوماتك، ولماذا نحتاج
              إليها، وكيفية استخدامنا لها.
            </p>
            <h2>نطاق التطبيق</h2>
            <p>
              تنطبق سياسة الخصوصية الماثلة على استخدامك لتطبيق الهاتف المتحرك
              الخاص بموقعنا على أجهزة مختلفة (يشار إليه فيما بعد بـــ
              "التطبيق"). تنطبق سياسة الخصوصية هذه على المعلومات التي تقدمها لنا
              بعد تقديمها مع سياسة الخصوصية هذه أو توجيهها إليها. قد يكون هذا،
              على سبيل المثال، عندما تشارك معلوماتك مع ممثلي الخدمة لدينا (على
              سبيل المثال، وكيل خدمة العملاء، أو متخصص التصميم، أو متخصص في
              التركيب أو التجميع) أو ترسل إلينا خطابًا أو بريدًا إلكترونيًا أو
              دردشة. قبل إرسال المعلومات إلى الموقع، يرجى مراجعة سياسة الخصوصية
              هذه بعناية.
            </p>
            <h2>المعلومات التي نجمعها وكيفية استخدامنا لها</h2>
            <p>
              هناك ثلاث فئات عامة من المعلومات التي نجمعها: (1) المعلومات التي
              تقدمها لنا؛ (2) المعلومات التي نجمعها تلقائيًا عند استخدامك
              للموقع؛ (3) المعلومات التي نجمعها من جهات خارجية. نستخدم معلوماتك
              بعدة طرق مختلفة وكيفية تعاملنا معها يعتمد على وقت جمعها وما نجمعه.
            </p>
            <p className="ml-2 pt-1 pb-1 profile-sub-heading">
              ا(1) لمعلومات التي تقدمها لنا
            </p>
            <p>
              تتضمن المعلومات التي تقدمها لنا تفاصيل مثل معلومات الاتصال الخاصة
              بك مثل عنوان البريد الإلكتروني، والعنوان البريدي، ورقم الهاتف،
              وتفاصيل حسابك مثل كلمة المرور، وتاريخ الميلاد، والجنس، والموقع،
              واللغة المفضلة، ومعلومات الدفع الخاصة بك مثل رقم بطاقة الائتمان /
              الخصم، ورمز الأمان / رمز التحقق من البطاقة (CVV)، وتاريخ انتهاء
              الصلاحية، ومعلومات التمويل الخاصة بك التي تشمل على سبيل المثال لا
              الحصر، رقم هوية الإمارات الخاصة بك، وتاريخ الميلاد والدخل، وسجلات
              الاتصال الخاصة بك معنا، مثل تسجيلات مكالمات الخدمة، والدردشة
              والرسائل الفورية، أو التواصل مع قنواتنا على وسائل التواصل
              الاجتماعي، ومراجعات المنتجات، ومنشورات المنتدى، والردود على
              الاستبيانات، والمشاركة في العروض الترويجية، وغيرها من المحتويات
              التي ينشئها المستخدمون.
            </p>

            <h2>معالجة هذه المعلومات:</h2>
            <p>
              نحتاج إلى اسمك وبيانات الاتصال الخاصة بك لإنشاء حسابك. هذه
              المعلومات ضرورية للأداء المناسب لخدمات وعروض معينة على الموقع
              وللوفاء بعقدنا معك عند تقديمك طلب. نستخدم هذه المعلومات أيضًا
              لتقديم معلومات مهمة حول الحساب لك، مثل تأكيدات الطلبات والإشعارات
              والتغييرات في إعدادات الحساب والإشعارات حول الموقع أو الشروط
              والسياسات التي تنطبق عليها. نستخدم معلومات الدفع الخاصة بك لمعالجة
              الدفعات والوفاء بالطلبات وتقديم المبالغ المستردة والامتثال للقانون
              المعمول به. نقوم بجمع معلومات التمويل الخاصة بك نيابة عن الممول
              الذي تختاره ونرسل المعلومات إلى هذا الممول لتسهيل طلبك للحصول على
              منتجات التمويل. نستخدم سجل اتصالاتك وتواصلك معنا، بالإضافة إلى
              معلومات أخرى مثل معلومات الملف الشخصي الإضافية لتشغيل الموقع
              وحمايته وتحسينه ولإضفاء الطابع الشخصي على تجربتك وتخصيصها
              وتحسينها.
            </p>
            <p className="ml-2 pt-1 pb-1 profile-sub-heading">
              ا(2) لمعلومات التي نجمعها تلقائيًا عند استخدامك للموقع
            </p>
            <p>
              تتضمن المعلومات التي نجمعها تلقائيًا عند استخدامك الموقع تفاصيل
              مثل عنوان بروتوكول الإنترنت (IP) الخاص بك وبيانات الأعطال وبيانات
              ملفات تعريف الارتباط وبيانات الموقع ومعلومات نظام تحديد المواقع
              (GPS) للهاتف المتحرك وطول الوقت الذي تقضيه على الموقع وسجل التصفح
              الخاص بك وزيارة ومشاهدة الصفحة / المنتج أو النقرات وما اشتريته وما
              قمت بوضعه في عربة التسوق أو حفظته وغير ذلك من "المعلومات العامة".
            </p>
            <h2>معالجة هذه المعلومات</h2>
            <p>
              نستخدم بيانات تسجيل الدخول ومعلومات الجهاز الخاصة بك لتشغيل الموقع
              وحمايته وتحسينه ولإضفاء الطابع الشخصي على تجربتك وتخصيصها. نستخدم
              المعلومات التي تم جمعها تلقائيًا لإنشاء بيئة تسوق آمنة عبر
              الإنترنت والحفاظ عليها. نستخدم منتجاتك المحفوظة لمساعدتك في إجراء
              عملية شراء، ونستخدم سجل الشراء الخاص بك لدعم عقدنا معك والتأكد من
              حصولك على تجربة عملاء رائعة. يجوز لنا استخدام المعلومات التي تم
              جمعها تلقائيًا لإجراء تحليلات داخلية وتدريب موظفينا واكتشاف ومنع
              الاحتيال لضمان الجودة وتحسين خدماتنا وعروضنا. يجوز لنا استخدام
              المعلومات التي تم جمعها تلقائيًا مثل ملفات تعريف الارتباط
              والتقنيات المماثلة لتقديم الإعلانات الأكثر ملاءمة لك.
            </p>
            <h2>سياسة ملفات تعريف الارتباط</h2>
            <p>
              نستخدم ملفات تعريف الارتباط عند زيارة موقعنا لتحليل حركة مرور
              البيانات وتحسين الأداء والمحتوى وتوفير تجربة تسوق متكاملة وأكثر
              تخصيصًا للعملاء. نستخدم بشكل عام الأنواع التالية من ملفات تعريف
              الارتباط:
            </p>

            <ul>
              <li style={{ direction: "rtl" }}>
                ملفات تعريف الارتباط الوظيفية التي تساعد في توفير تجربة تسوق
                سلسة عبر الإنترنت.
              </li>
              <li style={{ direction: "rtl" }}>
                ملفات تعريف الارتباط التحليلية التي تساعدنا في قياس وتحليل وفهم
                كيفية استخدام عملائنا للموقع وتحديد طرق تحسين كل من وظائفه
                وتجربة التسوق الخاصة بك.
              </li>
              <li style={{ direction: "rtl" }}>
                ملفات تعريف الارتباط الخاصة بتفضيلات العملاء التي تساعدنا على
                منحك تجربة أكثر تخصيصًا على الموقع.
              </li>
              <li style={{ direction: "rtl" }}>
                ملفات تعريف الارتباط المستهدفة أو الإعلانية التي تساعدنا في
                تقديم الإعلانات الرقمية الأكثر ملاءمة لك. تحد أنواع ملفات تعريف
                الارتباط هذه أيضًا من عدد المرات التي ترى فيها إعلانًا وتساعدنا
                في قياس فعالية حملاتنا التسويقية. نستخدم ملفات تعريف الارتباط
                لمساعدتنا في تحديد وإعادة استهداف المستخدمين الذين قد يكون لديهم
                اهتمام بمنتجاتنا أو خدماتنا أو عروضنا على موقعنا وعلى مواقع
                الجهات الخارجية.
              </li>
            </ul>
            <p>
              يمكنك منع استخدام ملفات تعريف الارتباط عن طريق تغيير الإعدادات في
              متصفح الويب الخاص بك بحيث (1) لا يقبل ملفات تعريف ارتباط جديدة،
              (2) يُعلمك بملفات تعريف الارتباط الجديدة، أو (3) يحذف جميع ملفات
              تعريف الارتباط المستلمة بالفعل. بحذف أو تعطيل ملفات تعريف الارتباط
              المستقبلية، قد تتأثر تجربة المستخدم الخاصة بك وقد لا تتمكن من
              الاستفادة من وظائف معينة في موقعنا (مثل تخزين الأصناف في عربة
              التسوق الخاصة بك بين الزيارات، وتخصيص تجربة موقع الويب الخاص بك،
              وجعل الرسائل التسويقية أكثر ملاءمة). بقبولك الشروط، فإنك تؤكد
              موافقتك على استخدام دانوب لمواد البناء ش.م.ح لملفات تعريف الارتباط
              والتقنيات الأخرى المماثلة لهذه الأغراض
            </p>
            <p className="ml-2 pt-1 pb-1 profile-sub-heading">
              ا(3) لمعلومات التي نجمعها من جهات خارجية
            </p>
            <p>
              تتضمن المعلومات التي نجمعها من جهات خارجية المعلومات التي قدمتها
              إلى خدمات الجهات الخارجية والربط أو الاتصال أو تسجيل الدخول إلى
              الموقع بحساباتك على جوجل أو فيس بوك أو إنستجرام أو تويتر. تتضمن
              هذه المعلومات عنوان بريدك الإلكتروني وعنوانك البريدي ورقم هاتفك
              وكلمة المرور واسم حساب المستخدم على مواقع التواصل الاجتماعي الخاص
              بك وغيرها من المعلومات مثل البيانات الديموغرافية ومعلومات الكشف عن
              الاحتيال ومعلومات عنك وأنشطتك وتجاربك وتفاعلاتك داخل وخارج الموقع.
            </p>
            <h2>معالجة هذه المعلومات</h2>
            <p>
              سنستخدم المعلومات من خدمات الجهات الخارجية إذا قمت بالتسجيل معنا
              باستخدام خدمات أخرى، مثل فيس بوك أو جوجل، بما في ذلك خدمة تسجيل
              الدخول بنقرة واحدة من جوجل. يعد الاتصال بتطبيقات أو خدمات الجهات
              الخارجية اختياريًا. إذا قمت بإنشاء حسابك عن طريق الاتصال من خلال
              خدمة أخرى، فسنجمع معلومات عامة من هذا الحساب المتصل للمساعدة في
              إكمال ملفك الشخصي. يجوز لنا استخدام المعلومات التي نجمعها أو
              نتلقاها من جهات خارجية لتقديم وتخصيص اتصالاتنا ومراسلاتنا معك، أو
              لإدارة المكافآت أو الاستبيانات أو السحوبات أو المسابقات أو الأنشطة
              الترويجية الأخرى. قد يحتوي الموقع على روابط لمواقع ويب أخرى. يُرجى
              العلم أن دانوب لمواد البناء ش.م.ح ليست مسؤولة عن ممارسات الخصوصية
              في مواقع الجهات الخارجية التي لا تتولى دانوب لمواد البناء ش.م.ح
              إدارتها وتشغيلها، ولا يمكنها ضمان أمن أي من معلوماتك الشخصية التي
              تم جمعها في تلك المواقع. تحثك دانوب لمواد البناء ش.م.ح على قراءة
              بيانات الخصوصية لكل موقع ويب تابع لجهة خارجية يقوم بجمع معلوماتك
              الشخصية.
            </p>
            <h2>الإجراءات الأمنية والتغيير في سياسة الخصوصية</h2>
            <p>
              أمن معلوماتك هو أمر مهم بالنسبة لنا، حيث نستخدم الضمانات التقنية
              والتنظيمية المناسبة لحماية معلوماتك من الاستخدام غير المصرح به
              والإفصاح والفقدان. نحثك على اتخاذ تدابير وإجراءات معقولة لحماية
              كلمة المرور وجهاز الكمبيوتر الخاص بك لمنع الوصول غير المصرح به إلى
              حسابك. تذكر تسجيل الخروج من حسابك وإغلاق نافذة المتصفح إذا كنت
              تستخدم جهاز كمبيوتر مشتركًا في مكان عام مثل مكتبة أو مقهى إنترنت.
              تحتفظ دانوب لمواد البناء ش.م.ح بالحق في تغيير سياسة الخصوصية هذه
              من وقت لآخر لبيان الطرق الجديدة التي نعالج بها معلوماتك. نحثك على
              مراجعة هذه الصفحة للاطلاع على أحدث المعلومات حول ممارسات الخصوصية
              لدينا. تخضع أي معلومات نجمعها ونخزنها والتي يمكن التعرف عليها
              شخصيًا للحماية باستخدام الوسائل المناسبة، وعلى الرغم من قيام دانوب
              لمواد البناء ش.م.ح بذلك، إلا أنها لن تتحمل المسؤولية عن أي وصول
              غير مصرح به من قبل جهات خارجية. إذا كان لديك أي أسئلة أو تعليقات
              حول سياسة الخصوصية، فلا تتردد في الاتصال بنا. يرد فيما يلي
              البيانات التي سيتم إضافتها في هذا القسم:
            </p>
            <ul>
              <li style={{ direction: "rtl" }}>
                لن يتم تخزين جميع تفاصيل بطاقات الائتمان/الخصم ومعلومات التعريف
                الشخصية أو بيعها أو مشاركتها أو تأجيرها أو استئجارها لأي جهة
                خارجية.
              </li>
              <li style={{ direction: "rtl" }}>
                يجوز تغيير أو تحديث سياسات الموقع الإلكتروني والشروط والأحكام
                بين الحين والآخر تلبيةً للاشتراطات والمعايير؛ ومن ثم، نهيب
                بالعملاء زيارة هذه الأقسام بانتظام للوقوف على التغييرات التي
                تُجرى على الموقع الإلكتروني. تسري التعديلات في يوم نشرها.
              </li>
            </ul>
            <h2>سياسة الاحتفاظ بالبيانات وإدارة معلوماتك</h2>
            <p>
              سنحتفظ بالبيانات المقدمة من المستخدم طالما أنك تستخدم التطبيق
              ولفترة معقولة بعد ذلك. سنحتفظ بالمعلومات التي تم جمعها تلقائيًا
              لمدة تصل إلى 24 شهرًا وبعد ذلك يجوز لنا تخزينها بشكل إجمالي. إذا
              كنت ترغب في حذف البيانات المقدمة من المستخدم والمقدمة من جانبك عبر
              التطبيق، فيرجى التواصل معنا على customerservice@milanoitalysrl.com
              وسوف نقوم بالرد عليك خلال مدة معقولة. يُرجى العلم أن بعض أو كل
              البيانات المقدمة من المستخدم قد تكون مطلوبة حتى يعمل التطبيق بشكل
              صحيح.
            </p>
            <p>
              <b>آخر تحديث </b> <br />
              <b>OCT 26, 2020</b>
            </p>
          </Col>
        ) : (
          <Col xs={24}>
            <p>
              Your privacy is important to us. This Privacy Policy ("Privacy
              Policy") is our way of letting you know how we collect your
              information, why we need it, and how we use it.
            </p>
            <h2>Scope of Application</h2>
            <p>
              This Privacy Policy applies to your use of our website on
              different devices (hereafter referred to as, the "Site").
            </p>
            <p>
              This Privacy Policy applies to the information you provide to us
              after being presented with or directed to this Privacy Policy.This
              could be, for example, when you share your information with our
              service representatives (e.g. a customer service agent, design
              professional, or an installation or assembly professional) or send
              us a letter, email or chat.
            </p>
            <p>
              Before submitting information to the Site, please review this
              Privacy Policy carefully.
            </p>
            <h2>Information We Collect and How We Use It</h2>
            <p>
              There are three general categories of information we collect: (1)
              Information you give to us; (2) Information we automatically
              collect when you use the Site; (3) Information we collect from
              third parties.
            </p>
            <p>
              We use your information in several different ways and what we do
              with your it depends on when we collect it and what we collect.
            </p>
            <p className="ml-2 pt-1 pb-1 profile-sub-heading">
              1) Information you give to us
            </p>
            <p>
              Information you give to us includes details such as your contact
              information like email address, mailing address, phone number,
              your account details like password, date of birth, gender,
              location, preferred language, your payment information like
              credit/debit card number, security/CVV code, expiration date, your
              financing information that includes but not limited to your
              Emirates id number, date of birth and income, your contact history
              with us such as service call recordings, chat and instant
              messages, or communications with our social media channels,
              product reviews, forum posts, survey responses, participation in
              promotions, and other user-generated content.
            </p>

            <h2>Treatment of such information:</h2>
            <p>
              We need your name and contact details to create your account. This
              information is necessary for the adequate performance of certain
              services and offerings on the Site and to fulfill our contract
              with you when you place an order. We also use this information to
              deliver important account information to you, such as order
              confirmations and notices, changes to account settings, and
              notices about the Site, or the terms and policies that apply to
              them.
            </p>
            <p>
              We use your payment information to process payments, fulfill
              orders, provide refunds and comply with applicable law.
            </p>
            <p>
              We collect your financing information on behalf of the financing
              provider you choose and send the information to that financing
              provider to facilitate your application to financing products.
            </p>
            <p>
              We use your communications and contact history with us, as well as
              other information such as additional profile information, to
              operate, protect, improve and optimize the Site and to
              personalize, customize and improve your experience.
            </p>
            <p className="ml-2 pt-1 pb-1 profile-sub-heading">
              2) Information we automatically collect when you use the Site
            </p>
            <p>
              Information we automatically collect when you use the site
              includes details such as your IP address, crash data, cookie data,
              location data, mobile GPS information, the length of time you
              spend on the site, your browsing history, page/product views or
              clicks, what you bought and what you've placed in your cart or
              saved, and other such 'General Information'.
            </p>
            <h2>Treatment of such information:</h2>
            <p>
              We use your log data and device information to operate, protect,
              improve and optimize the Site and to personalize and customize
              your experience. We use automatically collected information to
              create and maintain a secure online shopping environment. We use
              your saved products to help you make a purchase, and we use your
              purchase history to uphold our contract with you and ensure that
              you've had a great customer experience.
            </p>
            <p>
              We may use automatically collected information to perform internal
              analytics, train our staff, detect and prevent fraud for quality
              assurance and improve our services and offerings.
            </p>
            <p>
              We may use automatically collected information such as cookies and
              similar technologies to provide the most relevant advertising to
              you.
            </p>
            <p>
              We use your communications and contact history with us, as well as
              other information such as additional profile information, to
              operate, protect, improve and optimize the Site and to
              personalize, customize and improve your experience.
            </p>
            <h2>Cookie policy:</h2>
            <p>
              We use cookies when you visit our Site to analyze traffic,
              optimize performance and content and to provide an integrated and
              more personalized shopping experience for customers.
            </p>
            <p>We generally use the following types of cookies:</p>

            <ul>
              <li>
                Functionality cookies that help provide a seamless online
                shopping experience
              </li>
              <li>
                Analytics cookies that help us measure, analyze and understand
                how our customers use the Site and to identify ways to improve
                both its functionality and your shopping experience.
              </li>
              <li>
                Customer preference cookies that help us give you a more tailor
                made experience on the site.
              </li>
              <li>
                Targeting or advertising cookies that help us deliver the most
                relevant digital ads to you. These types of cookies also limit
                the number of times that you see an ad and help us measure the
                effectiveness of our marketing campaigns. We use cookies to help
                us identify and retarget users who may be interested in our
                products, services or offerings both on our Site and on third
                party websites.
              </li>
            </ul>
            <p>
              You can prevent the use of cookies by changing the settings in
              your web browser so that (i) it does not accept new cookies, (ii)
              it informs you about new cookies, or (iii) it deletes all already
              received cookies. By deleting or disabling future cookies, your
              user experience may be affected and you may not be able to take
              advantage of certain functions of our Site (such as storing items
              in your Cart between visits, customizing your website experience,
              and making marketing messages more relevant).
            </p>
            <p>
              By accepting the Terms, you confirm your consent to Danube Home’s
              use of cookies, and other such technologies, for these purposes.
            </p>
            <p className="ml-2 pt-1 pb-1 profile-sub-heading">
              3) Information we collect from third parties
            </p>
            <p>
              Information we collect from third parties, includes information
              provided by you to third party services and linking, connecting,
              or logging into the Site with your Google, Facebook, Instagram or
              Twitter accounts. Such information includes your email address,
              mailing address, phone number and password, your social media
              handles and other information such as demographic data, fraud
              detection information and information about you and your
              activities, experiences and interactions on and off the Site.
            </p>
            <h2>Treatment of such information</h2>
            <p>
              We will use information from third party services if you register
              with us using other services, such as Facebook or Google,
              including Google’s one-tap login service. Connecting to
              third-party applications or services is optional. If you create
              your account by connecting through another service, we will
              collect public information from that connected account to help
              complete your profile.
            </p>
            <p>
              We may use information we collect or receive from third parties to
              deliver and personalize our communications with you, or to
              administer rewards, surveys, sweepstakes, contests or other
              promotional activities.
            </p>
            <p>
              The site may contain links to other websites. Please be aware that
              Danube Home is not responsible for the privacy practices of third
              party websites not operated by Danube Home, and cannot guarantee
              the security of any of your Personal Information collected there.
              Danube Home encourages you to read the privacy statements of each
              and every third party website that collects your Personal
              Information.
            </p>
            <h2>Security Measures and Change in Privacy Policy</h2>
            <p>
              The security of your information is important to us. We use
              appropriate technical and organizational safeguards to protect
              your information from unauthorized use, disclosure, and loss. We
              encourage you to take reasonable measures to protect your password
              and your computer to prevent unauthorized access to your account.
              Remember to sign out of your account and close your browser window
              if you are using a shared computer in a public place such as a
              library or an Internet cafe.
            </p>
            <p>
              Danube Home reserves the right to change this Privacy Policy from
              time to time to reflect new ways in which we process your
              information. We encourage you to review this page for the latest
              information on our privacy practices.
            </p>
            <p>
              Any information that we collect and store that is personally
              identifiable is protected using appropriate means, and although
              Danube Home does so, it will not be held responsible for any
              unauthorized access by third parties.
            </p>
            <p>
              If you have any questions or feedback about the Privacy Policy,
              feel free to contact us.
            </p>
            <p>
              <i>
                The following statements to be added on this section are as
                follows:
              </i>
            </p>
            <ul>
              <li>
                All credit/debit cards details and personally identifiable
                information will NOT be stored, sold, shared, rented or leased
                to any third parties.
              </li>
              <li>
                The Website Policies and Terms &amp; Conditions may be changed
                or updated occasionally to meet the requirements and standards.
                Therefore the Customers’ are encouraged to frequently visit
                these sections in order to be updated about the changes on the
                website. Modifications will be effective on the day they are
                posted.
              </li>
            </ul>
            <h2>Data retention policy, managing your information</h2>
            <p>
              We will retain user-provided data for as long as you use the
              application and for a reasonable time thereafter. We will retain
              automatically collected information for up to 24 months and
              thereafter may store it in aggregate. If you’d like us to delete
              user-provided data that you have provided via the application,
              please contact us at privacy@danubehome.com and we will respond in
              a reasonable time. Please note that some or all of the
              user-provided data may be required in order for the application to
              function properly.
            </p>
            <p>
              <b>Last Updated: </b> <br />
              <b>OCT 26, 2020</b>
            </p>
          </Col>
        )}
      </Row>
    </Layout>
  );
}
