import * as React from "react";
import { ReactElement } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import {
  ADD_DEALER,
  ADD_ROLE,
  ADD_USER,
  ADMIN_CONFIGURATION,
  ADMIN_DASHBOARD,
  ADMIN_NOTIFICATION,
  ADMIN_ORDER_DETAILS,
  ADMIN_PRIVACY_POLICY,
  ADMIN_PROFILE,
  ADMIN_TERMS_AND_CONDITIONS,
  BANNER_ADD,
  BANNER_LIST,
  BANNER_MANAGEMENT,
  BUYXGETYLIST,
  COUPON,
  CUSTOM_NOTIFICATION,
  DEALER_LIST,
  EDIT_PRODUCT,
  EDIT_ROLE,
  EDIT_USER,
  LOGIN_PAGE_MANAGEMENT,
  MANAGE_ORDER,
  MANAGE_PRODUCTS,
  NEW_COUPON,
  NEW_NOTIFICATION,
  NEW_OFFER,
  OFFER_LIST,
  RESET_ADMIN_PASSWORD,
  STOCK_LABEL,
  UPDATE_CATEGORY,
  USER_MANAGEMENT,
} from "shared/constants/RouteConstants";

import Page404 from "./Page404/Page404";

import AdminDashboard from "views/Admin/AdminDashboard/AdminDashboard";
import ManageOrder from "views/Admin/ManageOrder/ManageOrder";
import OrderDetails from "views/Admin/OrderDetails/OrderDetails";
import AdminConfiguration from "views/Admin/AdminConfiguration/AdminConfiguration";

import BannerAddEdit from "views/Admin/Banner/BannerAddEdit";
import BannerList from "views/Admin/Banner/BannerList";

import AddRole from "views/Admin/AddRole/AddRole";
import AddUser from "views/Admin/AddUser/AddUser";
import ManageProducts from "views/Admin/ManageProducts/ManageProducts";
import UserManagement from "views/Admin/UserManagement/UserManagement";

import HomeBanners from "views/Admin/HomeBanners/HomeBanners";
import ManageCategory from "views/Admin/ManageCategory/ManageCategory";
import AddDealer from "views/Admin/ManageDealer/AddDealer";
import DealerList from "views/Admin/ManageDealer/DealerList";
import StockLabelPlanning from "views/Admin/StockLabelPlanning/StockLabelPlanning";

import AdminListLayout from "shared/components/layout/AdminListLayout/AdminListLayout";
import EditProduct from "views/Admin/ManageProducts/EditProducts";
import AdminNotification from "./Admin/AdminNotification/AdminNotification";
import AdminProfile from "./Admin/AdminProfile/AdminProfile";
import ResetAdminPassword from "./Admin/AdminProfile/ResetAdminPassword";
import BuyXGetYList from "./Admin/BuyXGetY/BuyXGetYList";
import Coupon from "./Admin/Coupons/Coupons";
import NewCoupon from "./Admin/Coupons/NewCoupon";
import CustomNotification from "./Admin/CustomNotification/CustomNotification";
import NewNotification from "./Admin/CustomNotification/NewNotification";
import LoginPageManagement from "./Admin/LoginPageManagement/LoginPageManagement";
import NewOffer from "./Admin/OfferList/NewOffer";
import OfferList from "./Admin/OfferList/OfferList";
import PrivacyPolicy from "./Dealer/PrivacyPolicy/PrivacyPolicy";
import { TermsAndCondition } from "./TermsAndCondition/TermsAndCondition";

interface MenuItem {
  route: string;
  icon: ReactElement;
  label: string;
}

type Props = {};

const AdminLayoutMilano: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const history = useHistory();

  const selectedMenu = pathname;

  function handleMenuClick(route: string) {
    if (route !== pathname) {
      history.push(route);
    }
  }

  const AppLayout = AdminListLayout;

  React.useEffect(() => {
    document.title = "Milano Admin";
  }, []);

  return (
    <AppLayout
      onMenuClick={handleMenuClick}
      pagination={undefined}
      filters={undefined}
    >
      <Switch>
        <Route exact path={BANNER_ADD} component={BannerAddEdit} />
        <Route exact path={BANNER_ADD + "/:id"} component={BannerAddEdit} />
        <Route exact path={BANNER_LIST} component={BannerList} />
        <Route exact path={ADMIN_DASHBOARD} component={AdminDashboard} />
        <Route exact path={MANAGE_ORDER} component={ManageOrder} />
        <Route exact path={MANAGE_PRODUCTS} component={ManageProducts} />
        <Route exact path={EDIT_PRODUCT} component={EditProduct} />
        <Route
          exact
          path={`${ADMIN_ORDER_DETAILS}/:id`}
          component={OrderDetails}
        />
        <Route exact path={EDIT_USER} component={AddUser} />
        <Route exact path={`${ADD_DEALER}/:id`} component={AddDealer} />
        <Route exact path={ADD_USER} component={AddUser} />
        <Route exact path={ADD_ROLE} component={AddRole} />

        <Route exact path={EDIT_ROLE} component={AddRole} />
        <Route exact path={USER_MANAGEMENT} component={UserManagement} />

        <Route exact path={ADD_DEALER} component={AddDealer} />
        <Route exact path={DEALER_LIST} component={DealerList} />
        <Route exact path={STOCK_LABEL} component={StockLabelPlanning} />
        <Route exact path={UPDATE_CATEGORY} component={ManageCategory} />
        <Route
          exact
          path={BANNER_MANAGEMENT + "/:name"}
          component={HomeBanners}
        />
        <Route exact path={ADMIN_PROFILE} component={AdminProfile} />
        <Route
          exact
          path={RESET_ADMIN_PASSWORD}
          component={ResetAdminPassword}
        />
        <Route exact path={`${EDIT_PRODUCT}/:id`} component={EditProduct} />
        <Route
          exact
          path={CUSTOM_NOTIFICATION}
          component={CustomNotification}
        />
        <Route exact path={NEW_NOTIFICATION} component={NewNotification} />
        <Route
          exact
          path={NEW_NOTIFICATION + "/:id"}
          component={NewNotification}
        />
        <Route exact path={`${BUYXGETYLIST}/:id`} component={BuyXGetYList} />
        <Route exact path={OFFER_LIST} component={OfferList} />
        <Route exact path={`${COUPON}/:id`} component={Coupon} />
        <Route exact path={`${NEW_COUPON}/:id`} component={NewCoupon} />
        <Route exact path={NEW_OFFER} component={NewOffer} />
        <Route
          exact
          path={ADMIN_CONFIGURATION}
          component={AdminConfiguration}
        />
        <Route exact path={ADMIN_NOTIFICATION} component={AdminNotification} />
        <Route
          exact
          path={LOGIN_PAGE_MANAGEMENT}
          component={LoginPageManagement}
        />

        <Route
          exact
          path={ADMIN_TERMS_AND_CONDITIONS}
          component={TermsAndCondition}
        />
        <Route exact path={ADMIN_PRIVACY_POLICY} component={PrivacyPolicy} />

        <Route path="*" component={Page404} />
      </Switch>
    </AppLayout>
  );
};

export default AdminLayoutMilano;
