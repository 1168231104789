import React, { Component } from "react";
import Chart from "react-apexcharts";
import "./donutsmall.scss";

function DonutSmall(props) {
  const arr1 = [];
  const arr2 = [];
  const colors = [];
  props?.orderChartStatus?.data?.forEach((item) => {
    if (item.status) arr1.push(item?.status?.replace(/_/g, " "));
  });
  props?.orderChartStatus?.data?.forEach((item) => {
    if (item.total) arr2.push((item?.total / 100) * props?.totalOrders?.data);
  });
  props?.orderChartStatus?.data?.forEach((item) => {
    if (item.statusColor) colors.push(item?.statusColor?.hex);
  });
  console.log(props);
  const chartOptions = {
    colors: colors.length > 0 ? colors : undefined,
    labels: arr1,
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              fontSize: 36,
              label: "$",
            },
            name: {
              show: false,
              label: "ABC",
              fontSize: 12,
              offsetY: 11,
              color: "#c71e21",
            },
            value: { show: false },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  if (arr1.length > 0) {
    return (
      <Chart
        className="donut-small"
        type="donut"
        height={150}
        width={150}
        // series={[50, 25, 15, 10, 10]}
        series={arr2}
        options={{ ...chartOptions }}
      />
    );
  }

  return <></>;
}

export default DonutSmall;
