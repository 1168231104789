import React, { useState, useEffect } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import { Row, Col, Spin, Typography, notification } from "antd";
import OTPForm from "./OTPForm";

import "./OTP.scss";
import { useTranslation } from "react-i18next";

import logo from "shared/assets/logo.jpg";
import otpProp from "shared/assets/images/loginPageImg.png";
import title from "./assets/title.png";
import button from "./assets/button.png";
import banner from "./banner.png";
import button_left from "./assets/button_left.png";
import button_right from "./assets/button_right.png";

//STATIC
import product1 from "./assets/product1.png";
import product2 from "./assets/product2.png";
import product3 from "./assets/product3.png";
import product4 from "./assets/product4.png";
import product5 from "./assets/product5.png";

import { AuthConsumer } from "shared/contexts/AuthContext";
import API_SERVICE from "client/src/services/api-service";

const { Title } = Typography;

interface Props {
  location: any;
}

// const contentStyle = {
//     height: '373px',
//     color: '#fff',
//     lineHeight: '373px',
//     textAlign: 'center',
//     background: '#364d79',
// };

export default function OTP({ location }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const { from } = location.state || { from: { pathname: "/dealer" } };
  const [loginImage, setLoginImage] = useState();

  const getLoginBanner = async (type: string) => {
    try {
      setImageLoading(true);

      const params = {
        bannerType: type,
      };
      const {
        data: { data },
      } = await API_SERVICE.getLoginBanner(params);
      // if (data) {
      setLoginImage(data?.file?.url);
      // }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    } finally {
      setImageLoading(false);
    }
  };

  useEffect(() => {
    document.title = "OTP Page";
    getLoginBanner("LOGIN_BANNER");
  }, []);

  return (
    <AuthConsumer>
      {({ isAuth, updateAuthToken }) => {
        return isAuth ? (
          <Redirect to={from} />
        ) : (
          <Row className="otp-form-row">
            <Col sm={10} md={12} lg={12} className={"otp-form-wrapper-column"}>
              <div className="otp-form-wrapper">
                <Row>
                  <Col>
                    <div className="logo">
                      <img src={logo} alt="logo" />
                    </div>
                    <div className="otp-title">Verify your account</div>
                    <p className="otp-text">Enter otp received on email </p>
                  </Col>
                </Row>

                <OTPForm
                  onOTP={async (email: string, otp: string) => {
                    setLoading(true);
                    try {
                      let otpEmail = localStorage.getItem("otp-email");
                      const { data: data } = await API_SERVICE.resetPassword(
                        otpEmail,
                        otp
                      );
                      notification.success({
                        message: "OTP verified",
                        placement: "bottomRight",
                      });
                      setLoading(false);
                      history.push("/reset-password");
                      localStorage.setItem(
                        "uuid",
                        JSON.stringify(data.data.uuid)
                      );
                    } catch (e) {
                      notification.error({
                        message: t(API_SERVICE.handleErrors(e)),
                        placement: "bottomRight",
                      });
                      setLoading(false);
                    }
                  }}
                  loading={loading}
                />
              </div>
            </Col>
            <Col sm={24} md={12} lg={12} className="otp-text-column">
              {imageLoading ? (
                <div className="loaderClass">
                  <Spin tip="Loading" size="large"></Spin>
                </div>
              ) : (
                <div className="otp-text-wrapper">
                  <img
                    src={loginImage}
                    alt="img"
                    className="img-fluid otp-prop-image"
                  />
                </div>
              )}
            </Col>
          </Row>
        );
      }}
    </AuthConsumer>
  );
}
