import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./Coupons.scss";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { MenuProps, Switch } from "antd";

import {
  SearchOutlined,
  BellOutlined,
  DownOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import logoTransparent from "shared/assets/logoTransparent.png";
import { Layout, Row, Col, Button, Table } from "antd";
import { notification } from "antd";
import { useEffect } from "react";
import moment from "moment";

import AdminListLayout from "shared/components/layout/AdminListLayout/AdminListLayout";
import API_SERVICE from "services/api-service";
import { padding } from "@material-ui/system";
import { NEW_COUPON, NEW_NOTIFICATION } from "shared/constants/RouteConstants";
import AccessControl from "services/AccessControl";

const { Header, Sider, Content } = Layout;

// const { RangePicker } = DatePicker;

interface Props {
  location: any;
}

const Coupon = (props: any) => {
  let history = useHistory();
  const parameters = useParams();
  const [filteredData, setFilteredData] = useState([]);
  const [filterAttributes, setFilterAttributes] = useState({});

  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: 15,
  } as any);
  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);
  const offerId = parameters.id;

  const toogleActiveInactive = async (id: number, isActive: boolean) => {
    try {
      const payload = {
        status: isActive ? true : false,
      };
      await API_SERVICE.couponActiveInactiveToogleCall(id, payload);
      notification.success({
        message: isActive ? "Activated" : "Deactivated",
        placement: "bottomRight",
      });
      getAllCoupons();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const getAllCoupons = async (pagination = {}) => {
    try {
      setLoading(true);
      const params = {
        ...pagination,
        offerId: offerId,
      };
      const {
        data: { data },
      } = await API_SERVICE.getCouponList(params);
      if (data.items) {
        setFilteredData(data.items);
        setTotal(data.total);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteCoupon = async (id: number) => {
    try {
      setLoading(true);
      await API_SERVICE.deleteCoupon(id);
      notification.success({
        message: `Coupon Deleted`,
        placement: "bottomRight",
      });
      getAllCoupons();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    } finally {
      setLoading(false);
    }
  };

  const _handleTableChanges = async (
    pagination,
    filters,
    sorter,
    extra: { currentDataSource; action }
  ) => {
    if (extra.action === "paginate") {
      setPagination({
        page: pagination.current,
        perpage: pagination.pageSize,
      });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };

  const columns = [
    {
      title: "COUPON TITLE",
      key: "code",
      dataIndex: "code",
    },
    {
      title: "COUPON  DESCRIPTION",
      dataIndex: "description",
    },
    {
      title: "TYPE",
      dataIndex: "type",
    },
    {
      title: "STATUS",
      render: (d: any) => (
        <div>
          <Switch
            onChange={(e: any) => {
              toogleActiveInactive(d.id, e);
            }}
            checked={d.status === 1 ? true : false}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
          />
        </div>
      ),
    },
    {
      title: "AMOUNT (AED)",
      dataIndex: "amount",
      render: (d: any) => `AED ${d}`,
    },

    {
      title: "ACTION",
      render: (a: any) => (
        <AccessControl id={47}>
          <Button shape="circle" onClick={() => deleteCoupon(a?.id)}>
            <DeleteOutlined />
          </Button>
        </AccessControl>
      ),
    },
  ];

  useEffect(() => {
    getAllCoupons(pagination);
  }, [pagination, sort, filters]);

  return (
    <>
      <Row
        className="align-items-center  stock-summary-container mb-2 mt-2"
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}
        style={{ padding: "10px" }}
      >
        <Col xs={12}>
          <div className="manage-products-title">COUPONS</div>
        </Col>
        <Col xs={12} className="flex justify-content-end">
          <Button
            type="primary"
            className="theme-btn sales-table-button ml-2 product-btn"
            onClick={() => {
              history.push(`${NEW_COUPON}/${offerId}`);
            }}
          >
            <PlusOutlined />
            New Coupon
          </Button>
        </Col>
      </Row>
      <Row className="manage-order-main-content">
        <Col xs={24} className="mb-3">
          <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={filteredData}
            size="middle"
            pagination={{
              size: "default",
              current: pagination.page,
              pageSize: pagination.perpage,
              total: total,
            }}
            onChange={_handleTableChanges}
          />
        </Col>
      </Row>
    </>
  );
};

export default Coupon;
