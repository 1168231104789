import { InboxOutlined } from "@material-ui/icons";
import { Button, notification } from "antd";
import { ShoppingOutlined } from "@ant-design/icons";

import CartContext from "context/Cart";
import { useContext } from "react";
import API_SERVICE from "services/api-service";
import "./AddToCartBtn.scss";

interface Props {
  name: string;
  productId: number;
  orderCoupon: null | string;
  quantity: number;
  canAdd?: boolean;
}

const AddToCartBtn = (props: Props) => {
  // console.log(props.quantity);
  const { fetchCartDetails, cartProducts } = useContext(CartContext);

  const handleQuantityValue = async (quantity: number, productId: number) => {
    try {
      await API_SERVICE.addToCart({
        productId,
        orderCoupon: null,
        quantity,
      });
      notification.success({
        message: `Updated Quanity`,
        placement: "bottomLeft",
      });
      fetchCartDetails();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };

  const addToCart = async () => {
    console.log(props);
    if (props?.canAdd == false) {
      return;
    }
    const filteredArray = cartProducts.filter(
      (item: any) => item.productId === Number(props.productId)
    );
    if (filteredArray.length !== 0) {
      let updatedQuantity = filteredArray[0].quantity + props.quantity;
      if (props?.name === "prodDetails") {
        updatedQuantity = filteredArray[0].quantity + props.quantity;
        handleQuantityValue(updatedQuantity, props.productId);
      } else {
        handleQuantityValue(updatedQuantity, props.productId);
      }
    } else {
      try {
        await API_SERVICE.addToCart({
          productId: props.productId,
          orderCoupon: props.orderCoupon,
          quantity: props.quantity,
        });

        notification.success({
          message: "Successfully Added To Cart",
          placement: "bottomLeft",
        });
        fetchCartDetails();
      } catch (err) {
        notification.error({
          message: API_SERVICE.handleErrors(err),
          placement: "bottomLeft",
        });
      }
    }
  };
  return (
    <Button className="addToCartBtn" onClick={() => addToCart()}>
      <ShoppingOutlined className="icon" />
      Add to Cart
    </Button>
  );
};

export default AddToCartBtn;
