import * as React from "react";
import { ReactElement } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useMedia } from "react-use";
import DesktopLayout from "shared/components/layout/DesktopLayout";
import MobileLayout from "shared/components/layout/MobileLayout";
import { useTranslation } from "react-i18next";
import DesktopAdminLayout from "shared/components/layout/DesktopAdminLayout";
import Categories from "./Dealer/categories/Categories";
import ProductPage from "./Dealer/ProductPage/ProductPage";
import ProductDetailsPage from "./Dealer/ProductDetailsPage/ProductDetailsPage";
import { Home } from "@material-ui/icons";
import Home1 from "./Dealer/home/Home";
import CartPage from "./Dealer/CartPage/CartPage";
import ManageAddress from "./Dealer/ManageAddress/ManageAddress";
import ThankyouPage from "./Dealer/ThankyouPage/ThankyouPage";
import DealerProfile from "./Dealer/DealerProfile/DealerProfile";
import Notifications from "./Dealer/Notifications/Notifications";
import Wishlist from "./Dealer/Wishlist/Wishlist";

/*
import Login from './login/Login';
import HomePage from './home/HomePage';
import Dashboard from './Dashboard'
import ProductListPage from './ProductList/ProductListPage';
import ProductDetailsPage from './ProductDetails/ProductDetailsPage';

import ProductListing from './ProductsAdmin/ProductListing';
import Shipping from './Shipping';
import Account from './Account';
import Orders from './OrdersAdmin/Orders';
import CategoriesPage from './Categories/CategoriesPage';
import PortAndSuppliersPage from './PortAndSuppliers/PortAndSuppliers';
import UsersPage from './UsersAndRolesManagement/UsersPage';
import RolesPage from './UsersAndRolesManagement/RolesPage';
import ContainersPage from './Containers/ContainersPage';
*/

// import CompaniesPage from './Companies/CompaniesPage';
// import GroupPage from './Groups/GroupsPage';
// import RolesPage from './Roles/RolesPage';
//import UsersPage from './Users/UsersPage';
// import UserProfile from './UserProfile';
// import UsersPage from './Users/UsersPage';
// import UsersFile from './Files/FilesPage';
// import ClaimsMaster from './MasterManagement';
// import Permissions from './Permission/Permission';
// import ForgotPassword from './forgotPassword/ForgotPassword'

import {
  HOME,
  CATEGORIES,
  NOTIFICATIONS,
  DEALER_PROFILE,
  PRODUCT_MAIN,
  PRODUCT_DETAILS_PAGE,
  MANAGE_ADDRESS,
  THANKYOU,
  CART,
  WISHLIST,
  DASHBOARD,
  STOCK_SUMMARY,
  REVENUE_SUMMARY,
  MY_ORDERS,
  ORDER_DETAILS,
  PRIVACY_POLICY,
  RESET_PASSWORD_DEALER,
  DEALER_TERMS_AND_CONDITIONS,
  SEARCH_RESULT_PAGE,
} from "shared/constants/RouteConstants";
import StockSummary from "./Dealer/Dashboard/StockSummary";
import RevenueSummary from "./Dealer/Dashboard/RevenueSummary";
import Dashboard from "./Dealer/Dashboard/Dashboard";
import MyOrders from "./Dealer/MyOrders/MyOrders";
import OrderDetails from "./Dealer/OrderDetails/OrderDetails";
import Page404 from "./Page404/Page404";
import PrivacyPolicy from "./Dealer/PrivacyPolicy/PrivacyPolicy";
import ResetPasswordDealer from "./Dealer/DealerProfile/ResetPasswordDealer";
import { TermsAndCondition } from "./TermsAndCondition/TermsAndCondition";
import SearchPage from "./Dealer/SearchResultPage/SearchResultPage";

// import Content from "./Content";
// import Home from './Analyst/Homepage/Homepage';
// import CompanySurveyList from './Analyst/DataCollection/SurveyList';
// import DataCollection from './Analyst/DataCollection/DataCollection';
// import Targets from './Analyst/Targets/Targets';
// import Result from './Analyst/Result/Result';
// import Support from './Analyst/Support/Support';
// import ContactUs from './Analyst/ContactUs/Contactus';
// import Companies from './FundManager/Companies/Companies';
// import Insights from './FundManager/Insights/Insights';
// import Portfolio from './FundManager/Portfolio/Portfolio';
// import Funds from './RAdmin/Funds/Funds';
// import Surveys from './RAdmin/Surveys/Surveys';
// import DCollection from './RAdmin/DataCollection/DataCollection';
// import FundsConfigure from "./RAdmin/Funds/Configure";
// import SurveyQuestionnaire from "./RAdmin/Surveys/SurveyQuestionnaire";

interface MenuItem {
  route: string;
  icon: ReactElement;
  label: string;
}

type Props = {};

const DealerLayout: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const history = useHistory();

  const selectedMenu = pathname;

  function handleMenuClick(route: string) {
    if (route !== pathname) {
      history.push(route);
    }
  }

  const AppLayout = DesktopAdminLayout;

  React.useEffect(() => {
    document.title = "Milano Dealer";
  }, []);

  return (
    <AppLayout selectedMenu={selectedMenu} onMenuClick={handleMenuClick}>
      <Switch>
        <Route exact path={HOME} component={Home1} />
        <Route exact path={CATEGORIES} component={Categories} />
        <Route
          exact
          path={PRODUCT_MAIN + "/FeaturedProducts"}
          component={ProductPage}
        />
        <Route
          exact
          path={PRODUCT_MAIN + "/:popularProducts"}
          component={ProductPage}
        />

        <Route
          exact
          path={PRODUCT_MAIN + "/:categoryId/:categoryName"}
          component={ProductPage}
        />
        <Route
          exact
          path={SEARCH_RESULT_PAGE + "/:query"}
          component={SearchPage}
        />
        <Route
          exact
          path={PRODUCT_DETAILS_PAGE + "/:id/:categoryName"}
          component={ProductDetailsPage}
        />

        <Route exact path={CART} component={CartPage} />
        <Route exact path={MANAGE_ADDRESS} component={ManageAddress} />
        <Route exact path={THANKYOU} component={ThankyouPage} />
        <Route exact path={DEALER_PROFILE} component={DealerProfile} />
        <Route exact path={NOTIFICATIONS} component={Notifications} />
        <Route exact path={MY_ORDERS} component={MyOrders} />
        <Route exact path={WISHLIST} component={Wishlist} />
        <Route exact path={ORDER_DETAILS + "/:id"} component={OrderDetails} />

        <Route exact path={REVENUE_SUMMARY} component={RevenueSummary} />
        <Route exact path={DASHBOARD} component={Dashboard} />
        <Route exact path={STOCK_SUMMARY} component={StockSummary} />
        <Route exact path={REVENUE_SUMMARY} component={RevenueSummary} />
        <Route exact path={PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route
          exact
          path={DEALER_TERMS_AND_CONDITIONS}
          component={TermsAndCondition}
        />

        <Route
          exact
          path={RESET_PASSWORD_DEALER}
          component={ResetPasswordDealer}
        />

        <Route path="*" component={Page404} />
      </Switch>
    </AppLayout>
  );
};

export default DealerLayout;
