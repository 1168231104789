import {
  Row,
  Col,
  Button,
  Layout,
  Divider,
  DatePicker,
  Breadcrumb,
  notification,
  Spin,
  Modal,
  Form,
  Input,
  Select,
  Table,
  Popconfirm,
  message,
} from "antd";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import {
  HOME,
  SALES_DASHBOARD,
  SALES_ORDER_APPROVAL,
  SALES_ORDER_DETAILS,
} from "shared/constants/RouteConstants";
import {
  RightOutlined,
  CalendarOutlined,
  DownOutlined,
  EyeOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import API_SERVICE from "services/api-service";
import "../ManageOrder/ManageOrder.scss";

import {
  consverPriceToInternationalFormat,
  textTransform,
} from "../../../services/util";
import {
  STATUS_LPO_SIGNED,
  STATUS_NEW,
  STATUS_PROCESSING,
  STATUS_REJECTED,
} from "shared/constants/Constants";
import { salesOrderStatusText } from "shared/services/Utility";
const { Header, Sider, Content } = Layout;

export default function OrderApproval(props: any) {
  const [form] = Form.useForm();
  const { filterValue } = useParams();
  const [loadingListData, setLoadingListData] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [orderApprovalsList, setOrderApprovalsList] = useState([]);
  const [filterAttributes, setFilterAttributes] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const[modalTitle,setModalTitle] = useState("")
  
  
    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    const onFinish = async (id:any ) => { 
      const value=form.getFieldsValue();
      console.log(value);
      const param = {
        status: STATUS_REJECTED,
        reason:value.cancelText
      };
      try {
        await API_SERVICE.approveRejectOrder(id, param);
        notification.success({
          message:  "Order Rejected",
          placement: "bottomRight",
        });
        handleCancel();
        GetOrderApprovalList(pagination, sort, filters, search);
      } catch (error) {
        notification.error({
          message: API_SERVICE.handleErrors(error),
          placement: "bottomRight",
        });
      } finally {
        setLoadingListData(false);
      }
    }

  const [pagination, setPagination] = useState({
    page: 1,
    perpage: "15",
  } as any);
  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);
  let history = useHistory();
  const { state: defaultFilter } = props.location;
  const { TextArea } = Input;

  const columns = [
    {
      title: "S.N.",
      dataIndex: "serialNumber",
      // sorter: (a: any, b: any) => a.serialNumber - b.serialNumber,
      render: (a: any, v: any, index: number) => (
        <div>{(pagination.page - 1) * pagination.perpage + index + 1}</div>
      ),
    },
    {
      title: "ORDER ID ",
      key: "orderNumber",
      // dataIndex: "orderDetails",
      className: "orderid",
      width: 120,
      filters:
        filterAttributes && filterAttributes.orderNumber
          ? filterAttributes?.orderNumber.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,

      render: (d: any) => {
        return (
          <div
            onClick={() => {
              viewOrderClick(d?.id);
            }}
          >
            {d?.orderNumber}
          </div>
        );
      },
    },
    {
      title: "PLACED ON : ",
      className: "placedon",
      key: "date",
      filters:
        filterAttributes && filterAttributes.date
          ? filterAttributes?.date.map((item: any) => {
              return {
                text: moment(item.date).format("DD/MM/YYYY"),
                value: item.date,
              };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (d: any) => {
        return moment(d?.placedOn).format("DD/MM/YYYY");
      },
    },
    {
      title: "TOTAL CBM  ",
      dataIndex: "totalCbm",
      filters:
        filterAttributes && filterAttributes.totalCbm
          ? filterAttributes?.totalCbm.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
    },
    {
      title: "DEALER NAME & CODE",
      // dataIndex: "user",
      key: "userId",
      filters:
        filterAttributes && filterAttributes.userId
          ? filterAttributes?.userId.map((item: any) => {
              return {
                text: item.name + " | " + item.dealerCode,
                value: item.id,
              };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (d: any) => {
        return (
          <>
            {d?.dealerName}
            <br />
            {d?.dealerCode}
          </>
        );
      },
    },
    {
      title: "TOTAL AMOUNT : ",
      dataIndex: "totalAmount",
      render: (amount: any) => (
        <>AED {consverPriceToInternationalFormat(amount)}</>
      ),
    },
    {
      title: "PLACED BY : ",
      key: "placedBy",
      filters:
        filterAttributes && filterAttributes.placedBy
          ? filterAttributes?.placedBy.map((item: any) => {
              return {
                text: item.name + " | " + item.email,
                value: item.id,
              };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (data: any) => {
        return (
          <>
            <>{data.placedByName}</> <br /> <>{data.placedByEmail}</>
          </>
        );
      },
    },
    {
      title: "STATUS ",
      dataIndex: "status",
      // defaultFilteredValue: filterValue && [filterValue.toUpperCase()],
      defaultFilteredValue: defaultFilter,
      filters:
        filterAttributes && filterAttributes.status
          ? filterAttributes?.status.map((item: any) => {
              return {
                text: salesOrderStatusText(item),
                /*item === "LPO_SIGN_OFF"
                    ? textTransform("LPO Signed Off required")
                    : textTransform(item),*/
                value: item,
              };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (status: string) => {
        return (
          <div className="text-capitalize">
            {/*{status === "lpo_sign_off"
              ? textTransform("LPO Signed Off required")
              : textTransform(status)}*/}
            {salesOrderStatusText(status)}
          </div>
        );
      },
    },
    {
      title: "ACTION ",
      // dataIndex: "action",
      render: (a: any) => (
        <div>
          {a.status === STATUS_LPO_SIGNED && (
            <>
              <Popconfirm
                title="Are you sure to approve this order?"
                onConfirm={() => {
                  approveRejectOrder(true, a.id);
                }}
                
                okText="Yes"
                cancelText="No"
              >
                <Button shape="circle" className="success-light-button mr-1">
                  <CheckOutlined />
                </Button>
              </Popconfirm>
              {/* <Popconfirm
                title="Are you sure to reject this order?"
                onConfirm={() => {
                  approveRejectOrder(false, a.id);
                }}
                okText="Yes"
                cancelText="No"
                
              >
                <Button shape="circle" className="success-light-button">
                  <CloseOutlined />
                </Button>
              </Popconfirm> */}
              <Button shape="circle" className="success-light-button" onClick={()=>setIsModalOpen(true)}>
                  <CloseOutlined />
                </Button>
                <Modal
                   title="Are you sure to reject this order?"
                   open={isModalOpen}
                   onCancel={handleCancel}
                   footer={null}
                   okText="Yes"
                   cancelText="No"
                   className="popup"
                >
                  <Form 
                  form={form}
                  // onFinish={onFinish}
                  layout="vertical"
                  >
                    <Row  >
                      <Col xs={24}>
                                <Form.Item
                                  name="cancelText"
                                  label={"Enter the reason for cancelling"}
                                >
                                  <TextArea
                                  rows={3}
                                    placeholder="Enter the reason for cancelling"
                                      required={true} 
                                      style={{ height: 120, resize: 'none' }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                  <Form.Item>
                                  <Button onClick={()=>onFinish(a.id)}>
                                    Yes
                                  </Button>
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item>
                                  <Button onClick={handleCancel} >
                                    No
                                  </Button>
                                  </Form.Item>
                                </Col>
                              </Row>
                  </Form>

                </Modal>
            </>
          )}
          <Button
            className="ml-1 secondary-light-button"
            shape="circle"
            onClick={() => {
              viewOrderClick(a.id);
            }}
          >
            <EyeOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const viewOrderClick = (id?: number) => {
    history.push(`${SALES_ORDER_DETAILS}/${id}`, id);
  };

  const _handleSearch = (event) => {
    if (event.which === 13) {
      setSearch({ orderNumber: event.target.value });
      setPagination((prev) => {
        return { page: 1, perpage: prev.perpage };
      });
      GetOrderApprovalList(
        { page: 1, perpage: pagination.perpage },
        sort,
        filters,
        {
          orderNumber: event.target.value,
        }
      );
    }
  };

  const _handleTableChanges = async (
    pagination,
    filters,
    sorter,
    extra: { currentDataSource; action }
  ) => {
    history.push(SALES_ORDER_APPROVAL);
    if (extra.action === "paginate") {
      setPagination({
        page: pagination.current,
        perpage: pagination.pageSize,
      });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });

      setFilters(filters);
    }
  };

  const GetOrderApprovalList = async (
    pagination = {},
    sort = {},
    filters = {},
    search = {}
  ) => {
    try {
      const params = {
        ...pagination,
        ...sort,
        ...filters,
        ...search,
      };
      /*if (filterValue) {
        //@ts-ignore
        params["status"] = [filterValue];
      }*/

      /*if (defaultFilter) {
        //@ts-ignore
        params["status"] = defaultFilter;
      }*/
      const { data: data } = await API_SERVICE.fetchOrderApprovals(params);
      /*data?.data?.items.forEach((item) => {
        item.status = item.status.toLowerCase();
      });*/
      setOrderApprovalsList(data?.data?.items);
      setTotal(data?.data?.total);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      setLoadingListData(false);
    }
  };
  const getOrderApprovalAttributes = async () => {
    const {
      data: { data },
    } = await API_SERVICE.fetchOrderApprovalAttributes();
    /*let index = data.status.indexOf('LPO_SIGN_OFF');

        if (index !== -1) {
            data.status[index] = "LPO Signed Off required";
        }*/
    setFilterAttributes(data);
  };
  const approveRejectOrder = async (isApproved, orderId) => {
    const param = {
      status: isApproved ? STATUS_PROCESSING : STATUS_REJECTED,
    };
    try {
      await API_SERVICE.approveRejectOrder(orderId, param);
      notification.success({
        message: isApproved ? "Order Approved" : "Order Rejected",
        placement: "bottomRight",
      });
      GetOrderApprovalList(pagination, sort, filters, search);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      setLoadingListData(false);
    }
  };

  useEffect(() => {
    GetOrderApprovalList(pagination, sort, filters, search);
  }, [sort, filters, pagination]);

  /*useEffect(() => {
        GetOrderApprovalList();
    }, []);*/
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")!);
    if (user?.user?.roles[0]?.pivot?.roleId !== 4) {
      history.push(SALES_DASHBOARD);
    }
    getOrderApprovalAttributes();
  }, []);

  return (
    <Layout className="products-bg-color">
      <Content className="contentPadding">
        <Row
          className="align-items-center  stock-summary-container mb-2 mt-2"
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
          }}
        >
          <Col xs={24}>
            <Breadcrumb
              className="products-breadcrumb"
              style={{
                margin: "16px 0",
              }}
              separator=">"
            >
              <Breadcrumb.Item>
                <Link to={HOME}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="products-breadcrumb-active">
                Order Approval
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>

          <Col xs={24} lg={24}>
            <h1 className="page-title sales-page-title">Order Approval</h1>
          </Col>
        </Row>

        <Row>
          <Col xs={24} className="mb-3">
            <Table
              loading={loadingListData}
              scroll={{ x: true }}
              columns={columns}
              dataSource={orderApprovalsList}
              size="middle"
              pagination={{
                size: "default",
                current: pagination.page,
                pageSize: pagination.perpage,
                total: total,
                /*onChange:(page, perpage) => {
                                              setPagination({ page, perpage });
                                              getProductList({page, perpage}, sort, filters, search);
                                          }*/
              }}
              onChange={_handleTableChanges}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
