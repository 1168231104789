import { notification, Select, TreeSelect } from "antd";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./Coupons.scss";

import { ArrowLeftOutlined } from "@ant-design/icons";

import { Button, Col, Form, Input, Layout, Row } from "antd";
import { Option } from "antd/lib/mentions";
import API_SERVICE from "services/api-service";
import MilanoThumbnail from "../../../assets/MilanoThumbnail.png";
import TextArea from "antd/lib/input/TextArea";
import { useEffect } from "react";
import {
  DISCOUNT_TYPE_FIX,
  DISCOUNT_TYPE_PERCENTAGE,
} from "shared/constants/Constants";
import { COUPON } from "shared/constants/RouteConstants";
const { Header, Sider, Content } = Layout;

const NewCoupon = () => {
  const [visible, setVisible] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);

  let history = useHistory();
  const [form] = Form.useForm();
  const { id }: any = useParams();

  const handleTypeChange = (val: any) => {
    if (val == DISCOUNT_TYPE_PERCENTAGE) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  const fetchCategoriesData = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchCategories({ page: -1 });
      if (data.items !== 0) {
        setCategoriesData(
          data.items
            .filter((item: any) => item?.isActive === true)
            .sort((a: any, b: any) => {
              return a.order - b.order;
            })
        );
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  const getAllProducts = async () => {
    const params = {};

    if (categoryIds && categoryIds.length) {
      //@ts-ignore
      params["page"] = -1;
      //@ts-ignore
      params["categoryIds"] = categoryIds;
    }

    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchCategoriesProducts(params);
      if (data.items !== 0) {
        setProductsList(data.items);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "topRight",
      });
    }
  };

  function createFilterTree(data: any) {
    return data.map((item: any) => {
      return {
        title: item.name,
        key: item.id,
        value: item.id,
        children: item.children.length ? createFilterTree(item.children) : [],
      };
    });
  }

  const y = createFilterTree(categoriesData);

  const x =
    productsList.length !== 0
      ? productsList.map((item: any) => ({
          title: (
            <>
              <img
                src={item?.thumbnail?.url || MilanoThumbnail}
                className="banner-form-select-icon"
                alt="icon"
              />
              {item.name}
            </>
          ),
          searchKey: item.name,
          value: `${item.id}`,
        }))
      : [
          {
            title: "No Products in Selected Category",
            value: "null",
            checkable: false,
          },
        ];

  const allIds = y.map(({ value }) => value);
  const allProductIds = x.map(({ value }) => value);

  const _createCoupon = async (val: any) => {
    try {
      const payload = {
        offerId: id,
        code: val.title,
        description: val.description,
        type: val.type,
        amount: parseFloat(val.amount),
        maxRedemptions: parseInt(val.redemption),
        productId: productIds,
      };
      if (val.maxAmount) {
        //@ts-ignore
        payload["maxAmount"] = parseInt(val.maxAmount);
      }
      await API_SERVICE.createCoupon(payload);
      notification.success({
        message: "Coupon Created Successfully",
        placement: "bottomRight",
      });
      history.push(`${COUPON}/${id}`);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [categoryIds]);

  useEffect(() => {
    fetchCategoriesData();
  }, []);

  return (
    <>
      <Content className="add-user-main-content">
        <div className="add-user-title">CREATE NEW COUPON</div>
        <div className="add-user-icon">
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </div>
        <div className="add-user-form coupon-form">
          <Form
            form={form}
            labelCol={{
              xs: { span: 4 },
              sm: { span: 4 },
              md: { span: 8 },
              lg: { span: 8 },
              xl: { span: 8 },
            }}
            wrapperCol={{
              xs: { span: 2 },
              sm: { span: 2 },
              md: { span: 16 },
              lg: { span: 16 },
              xl: { span: 16 },
            }}
            labelAlign="left"
            layout="horizontal"
            onFinish={_createCoupon}
          >
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Enter Title",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Title"
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={0} sm={0} md={0} lg={0} xl={12}></Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Enter Description",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Enter Description"
                    style={{ resize: "none" }}
                    className="ant-input address-text"
                  />
                </Form.Item>
              </Col>
              <Col xs={0} sm={0} md={0} lg={0} xl={12}></Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <Form.Item
                  label="Select Type"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Select Type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Type"
                    allowClear
                    className="banner-form-select"
                    onChange={handleTypeChange}
                  >
                    <Option value={DISCOUNT_TYPE_PERCENTAGE}>Percentage</Option>
                    <Option value={DISCOUNT_TYPE_FIX}>Fixed</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <Form.Item
                  label="Amount (AED)"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Enter Amount",
                    },
                  ]}
                >
                  <Input
                    placeholder="Amount"
                    type="number"
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <Form.Item label="Select Category" className="coupon-tree">
                  <>
                    <TreeSelect
                      allowClear={true}
                      treeNodeFilterProp="title"
                      placeholder="Select Categories"
                      treeCheckable={true}
                      showCheckedStrategy={TreeSelect.SHOW_PARENT}
                      dropdownStyle={{ maxHeight: "300px" }}
                      onChange={(ids) => setCategoryIds(ids)}
                      value={categoryIds}
                      maxTagCount={"responsive"}
                      maxTagPlaceholder={(omittedValues) =>
                        `+ ${omittedValues.length} Categories ...`
                      }
                      treeData={[
                        {
                          title:
                            categoryIds.length > 0 ? (
                              <span
                                onClick={() => setCategoryIds([])}
                                style={{
                                  display: "inline-block",
                                  color: "#286FBE",
                                  cursor: "pointer",
                                }}
                              >
                                Unselect all
                              </span>
                            ) : (
                              <span
                                onClick={() => {
                                  setCategoryIds(allIds);
                                }}
                                style={{
                                  display: "inline-block",
                                  color: "#286FBE",
                                  cursor: "pointer",
                                }}
                              >
                                Select all
                              </span>
                            ),
                          value: "xxx",
                          disabled: true,
                          checkable: false,
                        },
                        ...y,
                      ]}
                    />
                  </>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <Form.Item label="Select Products">
                  <TreeSelect
                    treeNodeFilterProp="searchKey"
                    disabled={categoryIds.length === 0 ? true : false}
                    allowClear={true}
                    placeholder="Select Products"
                    treeCheckable={true}
                    showCheckedStrategy={TreeSelect.SHOW_CHILD}
                    dropdownStyle={{ maxHeight: "300px" }}
                    onChange={(ids) => setProductIds(ids)}
                    value={productIds}
                    maxTagCount={"responsive"}
                    maxTagPlaceholder={(omittedValues) =>
                      `+ ${omittedValues.length} Products ...`
                    }
                    treeData={[
                      {
                        title:
                          productIds.length > 0 ? (
                            <span
                              onClick={() => setProductIds([])}
                              style={{
                                display: "inline-block",
                                color: "#286FBE",
                                cursor: "pointer",
                              }}
                            >
                              Unselect all
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                setProductIds(allProductIds);
                              }}
                              style={{
                                display: "inline-block",
                                color: "#286FBE",
                                cursor: "pointer",
                              }}
                            >
                              Select all
                            </span>
                          ),
                        value: "xxx",
                        disabled: true,
                        checkable: false,
                      },
                      ...x,
                    ]}
                  />
                </Form.Item>
              </Col>

              {visible ? (
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Form.Item
                    className="coupon-input"
                    label="Max Discount (AED)"
                    name="maxAmount"
                    rules={[
                      {
                        required: true,
                        message: "Enter Max Discount",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Maximum Discount"
                      type="number"
                      className="banner-form-input"
                    />
                  </Form.Item>
                </Col>
              ) : (
                " "
              )}
              <Col xs={0} sm={0} md={0} lg={visible ? 12 : 0}></Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <Form.Item label="No. of Redemption" name="redemption">
                  <Input
                    type="number"
                    placeholder="No. Of Redemption "
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item
                  wrapperCol={
                    ({ sm: { offset: 4 } },
                    { md: { offset: 8 } },
                    { lg: { offset: 6 } },
                    { xl: { offset: 4 } })
                  }
                >
                  <Button
                    htmlType="submit"
                    className="send-notification-btn new-offer-btn"
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </>
  );
};

export default NewCoupon;
