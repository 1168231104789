import {
  DownOutlined,
  PlusOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { EyeOutlined } from "@ant-design/icons";

import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Table,
} from "antd";
import "./index.scss";
import AdminListLayout from "shared/components/layout/AdminListLayout/AdminListLayout";
import { useEffect, useState } from "react";
import globe from "../../../assets/icons/globe.png";
import { FileUpload } from "components";
import API_SERVICE from "services/api-service";
import { SearchOutlined } from "@material-ui/icons";
import { Link } from "react-router-dom";
import AccessControl from "services/AccessControl";

interface objInterface {
  labels: string;
  startRange: string;
  endRange: string;
}

function StockLabelPlanning() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [downloadSampleUrl, setDownloadSampleUrl] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({
    id: 0,
    name: "",
    skuId: "",
  });
  const [productList, setProductList] = useState([]);
  const [filterAttributes, setFilterAttributes] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: "15",
  } as any);
  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);

  const [bulkFile, setBulkFile] = useState({
    name: " ",
    size: 0,
  });
  const [labelRow, setlabelRow] = useState([]);

  const fetchData = async (
    pagination = {},
    sort = {},
    filters = {},
    search = {}
  ) => {
    try {
      const params = {
        ...pagination,
        ...sort,
        ...filters,
        ...search,
      };

      setLoading(true);
      const {
        data: { data },
      } = await API_SERVICE.fetchCategoriesProducts(params);

      if (data.items !== 0) {
        setProductList(data?.items);
        setTotal(data?.total);
      }
      setLoading(false);
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  const _handleTableChanges = async (
    pagination,
    filters,
    sorter,
    extra: { currentDataSource; action }
  ) => {
    if (extra.action === "paginate") {
      setPagination({ page: pagination.current, perpage: pagination.pageSize });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };

  //handleBulkUpload
  const handleBulkUpload = async (e: any) => {
    e.preventDefault();
    try {
      await API_SERVICE.handleBulkUpload(bulkFile);
      notification.success({
        message: "Uploaded successfully",
        placement: "bottomRight",
      });
      setBulkFile({ name: " ", size: 0 });
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };
  //Handle Add Label
  const handleAddLabel = async (values: any) => {
    const outerObj = {
      range: [],
    };
    for (let i = 0; i < labelRow.length; i++) {
      const obj: objInterface = {
        labels: values[`labels${i}`],
        startRange: values[`startRange${i}`],
        endRange: values[`endRange${i}`],
      };
      outerObj.range.push(obj);
    }
    let error;
    let arr = outerObj.range;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i]?.startRange >= arr[i]?.endRange) {
        notification.error({
          message: `Label ${
            i + 1
          } End Range value cannot be less than or equals to ${
            arr[i]?.startRange
          }`,
          placement: "bottomRight",
        });
        error = true;
        return;
      }
      if (arr[i + 1]?.startRange <= arr[i]?.endRange) {
        notification.error({
          message: `Label ${i + 2} Start Range value must be greater than ${
            arr[i]?.endRange
          }`,
          placement: "bottomRight",
        });
        error = true;
        return;
      }
    }
    if (error !== false) {
      try {
        await API_SERVICE.addStockLabel(selectedProduct.id, outerObj);

        notification.success({
          message: "Label Added Successfully ",
          placement: "bottomRight",
        });
        setlabelRow([]);
        setIsModalOpen(false);
        setSelectedProduct({
          id: 0,
          name: "",
          skuId: "",
        });
        form.resetFields();
        fetchData();
      } catch (err) {
        notification.error({
          message: API_SERVICE.handleErrors(err),
          placement: "bottomRight",
        });
      }
    }
  };

  const addTableRows = () => {
    const rowsInput = {};
    setlabelRow([...labelRow, rowsInput]);
  };

  const deleteTableRows = (index: any) => {
    const rows = [...labelRow];
    rows.splice(index, 1);
    setlabelRow(rows);
  };

  const columns = [
    {
      title: "PRODUCT TITLE",
      dataIndex: "name",
    },
    {
      title: "skuId",
      dataIndex: "skuId",
      className: "user-name",
      width: 120,
      filters:
        filterAttributes && filterAttributes.skuId
          ? filterAttributes?.skuId.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
    },
    {
      title: "cbmValue",
      dataIndex: "cbmValue",
      width: 150,
    },
    {
      title: "CATEGORY : ",
      dataIndex: "category",
      key: "categoryIds",
      // filters:
      //   filterAttributes &&
      //   filterAttributes.categoriesName &&
      //   filterAttributes.categoriesName.length
      //     ? createFilterTree(filterAttributes.categoriesName)
      //     : [],
      filters:
        filterAttributes &&
        filterAttributes.categoriesName &&
        filterAttributes.categoriesName.length
          ? filterAttributes?.categoriesName.map((item: any) => {
              return { text: item.name, value: item.id };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (data) => {
        return data?.name;
      },
    },
    {
      title: "ACTION: ",
      render: (prod: any) => (
        <AccessControl id={43}>
          <div className="actionBtns justify-content-center">
            <Button
              shape="circle"
              onClick={() => {
                setlabelRow(prod.productStockLabels);
                setSelectedProduct({
                  id: prod.id,
                  name: prod.name,
                  skuId: prod.skuId,
                });
                setIsModalOpen(true);
              }}
            >
              <EyeOutlined />
            </Button>
          </div>
        </AccessControl>
      ),
    },
  ];

  // function createFilterTree(data: any) {
  //   return data.map((item: any) => {
  //     return {
  //       text: item.name,
  //       value: item.id,
  //       children: item.children.length ? createFilterTree(item.children) : [],
  //     };
  //   });
  // }

  const downloadSample = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.downloadSample();
      if (data) {
        setDownloadSampleUrl(data.url);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  const getAllAttributes = async () => {
    const {
      data: { data },
    } = await API_SERVICE.fetchAllAttributes();
    setFilterAttributes(data);
  };
  const _handleSearch = (event) => {
    if (event.which === 13) {
      setSearch({ q: event.target.value });
      setPagination((prev) => {
        return { page: 1, perpage: prev.perpage };
      });
      fetchData({ page: 1, perpage: pagination.perpage }, sort, filters, {
        q: event.target.value,
      });
    }
  };

  useEffect(() => {
    fetchData(pagination, sort, filters, search);
  }, [sort, filters, pagination]);

  useEffect(() => {
    fetchData();
    downloadSample();
    getAllAttributes();
  }, []);

  return (
    <AccessControl id={40}>
      <>
        <Modal
          title={
            <p className="stockLabelModalTitle">
              {selectedProduct.name}
              <br />
              <span>skuId:{selectedProduct.skuId}</span>
            </p>
          }
          footer={null}
          className="add-address-modal admin-stock-modal"
          open={isModalOpen}
          onCancel={() => {
            setIsModalOpen(false);
            setSelectedProduct({
              id: 0,
              name: "",
              skuId: "",
            });
            form.resetFields();
            setlabelRow([]);
          }}
        >
          <Form form={form} layout="vertical" onFinish={handleAddLabel}>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <Col xs={24}>
                <div className="stockLabelActionContainer">
                  <Row className="header">
                    <Col xs={7}>Start Range</Col>
                    <Col xs={7}>End Range</Col>
                    <Col xs={7}>Label</Col>
                    <AccessControl id={48}>
                      <Col xs={3}>Action</Col>
                    </AccessControl>
                  </Row>

                  {labelRow.map((label, index) => {
                    return (
                      <div className="labelRowCss" key={index}>
                        <Row className="row">
                          {console.log(
                            form.setFieldsValue({
                              [Object.keys(label)[2] + index]: label.startRange,
                              [Object.keys(label)[3] + index]: label.endRange,
                              [Object.keys(label)[4] + index]: label.labels,
                            })
                          )}
                          <Col xs={7} className="p-1">
                            <Form.Item
                              name={"startRange" + index}
                              className="m-0"
                              rules={[
                                {
                                  required: true,
                                  message: "Enter Start Range",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                className="inputFields text-center"
                                maxLength={10}
                                placeholder="Enter Range"
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={7} className="p-1">
                            <Form.Item
                              name={"endRange" + index}
                              className="m-0"
                              rules={[
                                {
                                  required: true,
                                  message: "Enter End Range",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                className="inputFields text-center"
                                maxLength={10}
                                placeholder="Enter Range"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={7} className="p-1">
                            <Form.Item
                              name={"labels" + index}
                              className="m-0"
                              rules={[
                                {
                                  required: true,
                                  message: "Enter Label Name",
                                },
                              ]}
                            >
                              <Input
                                className="inputFields text-center"
                                max={10}
                                placeholder="Enter Label"
                              />
                            </Form.Item>
                          </Col>
                          <AccessControl id={48}>
                            <Col
                              xs={3}
                              className="flex justify-content-center align-items-center"
                            >
                              <Button
                                className="labelRowCssDeleteBtn"
                                onClick={() => deleteTableRows(index)}
                              >
                                <CloseIcon />
                              </Button>
                            </Col>
                          </AccessControl>
                        </Row>
                      </div>
                    );
                  })}

                  <Row>
                    <Col xs={24} className="pt-2 pb-2">
                      <Button
                        className="addLabelBtn   "
                        onClick={() => addTableRows()}
                      >
                        <AddIcon />
                        Add Label
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
              <AccessControl id={46}>
                <Col xs={24} className="flex justify-content-center mt-3">
                  <Form.Item className="stockLabelBtnContainer">
                    <Button htmlType="submit" className="theme-btn   ">
                      SAVE
                    </Button>
                  </Form.Item>
                </Col>
              </AccessControl>
            </Row>
          </Form>
        </Modal>
        <Modal
          title="Bulk Upload"
          footer={null}
          className="add-address-modal "
          open={bulkUploadModal}
          onCancel={() => setBulkUploadModal(false)}
        >
          <form onSubmit={handleBulkUpload}>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <Col xs={24} sm={24} md={16} lg={24} xl={16}>
                <a href={downloadSampleUrl} download>
                  <div className="fileUploadDesign" style={{ height: "45px" }}>
                    <div className="text-truncate">
                      <DownloadOutlined className="icon" />
                      Download Sample
                    </div>
                    <p className="text">Download</p>
                  </div>
                </a>
              </Col>
              <Col xs={24} sm={24} md={16} lg={24} xl={16}>
                <input
                  type="file"
                  id="uploadFile"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  style={{ display: "none" }}
                  onChange={(e: any) => setBulkFile(e?.target?.files[0])}
                ></input>
                <label htmlFor="uploadFile">
                  <p>Upload File</p>
                  <div className="fileUploadDesign">
                    <div>
                      <UploadOutlined className="icon" />

                      {bulkFile?.name !== " " ? (
                        <span className="text">{bulkFile?.name}</span>
                      ) : (
                        "Select File"
                      )}
                    </div>
                    <p className="text">Upload</p>
                  </div>
                </label>
              </Col>
              <Col xs={24} className="flex justify-content-center mt-3">
                <div className="stockLabelBtnContainer">
                  <Button htmlType="submit" className="theme-btn">
                    Upload
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </Modal>
        <Row
          className=" stock-summary-container mb-2 mt-2"
          align="middle"
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
          }}
        >
          <Col xs={24} lg={16} className="p-0">
            <div className="manage-products-title">STOCK LABEL PLANNING</div>
          </Col>
          <Col xs={12} lg={6} className="flex justify-content-end ">
            <Input
              onKeyPress={_handleSearch}
              className="admin-search mr-2"
              placeholder="Search..."
              prefix={<SearchOutlined />}
            />
          </Col>
          <AccessControl id={43}>
            <Col xs={12} lg={2} className="flex justify-content-end">
              <Button
                type="primary"
                className="product-btn"
                onClick={() => {
                  setBulkUploadModal(true);
                }}
              >
                <PlusOutlined />
                Bulk Upload
              </Button>
            </Col>
          </AccessControl>
        </Row>
        <Row>
          <Col xs={24} className="mb-3 mt-2">
            <Table
              scroll={{ x: true }}
              loading={loading}
              columns={columns}
              dataSource={productList}
              size="middle"
              pagination={{
                size: "default",
                current: pagination.page,
                pageSize: pagination.perpage,
                total: total,
                /*onChange:(page, perpage) => {
                            setPagination({ page, perpage });
                            getProductList({page, perpage}, sort, filters, search);
                        }*/
              }}
              onChange={_handleTableChanges}
            />
          </Col>
        </Row>
      </>
    </AccessControl>
  );
}

export default StockLabelPlanning;
