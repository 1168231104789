import { Breadcrumb, Button, Col, Layout, Row } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HOME } from "shared/constants/RouteConstants";

export const TermsAndCondition = () => {
  React.useEffect(() => {
    document.title = "Terms And Conditions";
  }, []);
  const [isArabic, setIsArabic] = useState(false);

  return (
    <>
      <Layout className="dashboard-bg-color">
        <Breadcrumb className="products-breadcrumb" separator=">">
          <Breadcrumb.Item>
            <Link to={HOME}>Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="products-breadcrumb-active">
            Terms And Conditions
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row className="pb-5 privacy-policy-container">
          <Col xs={24} className="dealer-profile-container">
            <h1 className="page-title text-center">
              {isArabic ? "الشروط والأحكام" : "Terms And Conditions"}
            </h1>
          </Col>
          <Col xs={24} className="d-flex justify-content-end">
            <Button
              onClick={() => setIsArabic(!isArabic)}
              style={{ maxWidth: "280px" }}
              className="action-button-red"
            >
              {isArabic ? "Switch to English" : "Switch to Arabic"}
            </Button>
          </Col>
          {isArabic ? (
            <Col xs={24} className="text-align-right">
              <h2>نبذة عامة</h2>
              <p className="text-align-right">
                تم تشغيل هذا الموقع من قبل دانوب لمواد البناء (ش.م.ح). وتشير
                المصطلحات "نحن" و "لنا" و "الخاص بنا" إلى دانوب هوم في جميع
                أنحاء الموقع. وتوفر دانوب لمواد البناء (ش.م.ح) هذا الموقع، بما
                في ذلك جميع المعلومات والأدوات والخدمات المتاحة من هذا الموقع لك
                كمستخدم، بشرط موافقتك على جميع الشروط والأحكام والسياسات
                والإشعارات المذكورة هنا. وتوافق عند زيارة موقعنا و / أو شراء شيء
                منا على المشاركة في "الخدمة" الخاصة بنا وتوافق على الالتزام
                بالشروط والأحكام التالية ("شروط الخدمة"، "الشروط")، بما في ذلك
                الشروط والأحكام والسياسات الإضافية المشار إليه هنا و/أو المتاحة
                من خلال الرابط التشعبي. وتنطبق شروط الخدمة هذه على جميع مستخدمي
                الموقع، بما في ذلك على سبيل المثال لا الحصر المستخدمين من
                المتصفحات و/أو البائعين و/أو العملاء و/أو التجار و/أو المساهمين
                في المحتوى. يرجى قراءة شروط الخدمة هذه بعناية قبل الدخول إلى
                موقعنا الإلكتروني أو استخدامه. وتوافق عند الدخول إلى أي جزء من
                الموقع أو استخدامه على الالتزام بشروط الخدمة هذه. وإذا كنت لا
                توافق على جميع شروط وأحكام هذه الاتفاقية، فلا يجوز لك الدخول إلى
                موقع الويب أو استخدام أي خدمات. وإذا كانت شروط الخدمة هذه بمثابة
                عرض، فإن القبول يقتصر صراحةً على شروط الخدمة هذه، كما تخضع أي
                ميزات أو أدوات جديدة تضاف إلى المتجر الحالي لشروط الخدمة. ويمكنك
                مراجعة أحدث إصدار من شروط الخدمة في أي وقت على هذه الصفحة.
                ونحتفظ بالحق في تحديث أو تغيير أو استبدال أي جزء من شروط الخدمة
                هذه عن طريق نشر التحديثات و/أو التغييرات على موقعنا. ويقع على
                عاتقك مسؤولية مراجعة هذه الصفحة بشكل دوري لمعرفة التغييرات. ويعد
                استمرار استخدامك أو الوصول إلى موقع الويب بعد نشر أي تغييرات
                بمثابة قبول لهذه التغييرات.
              </p>

              <h2>الجزء 1- شروط المتجر عبر الانترنت</h2>

              <p>
                عند الموافقة على شروط الخدمة هذه، فإنك تقر وتضمن بأنك تبلغ على
                الأقل سن الرشد في ولايتك أو إقليم إقامتك، أو أنك تبلغ سن الرشد
                في ولايتك أو إقليم إقامتك وأنك منحتنا موافقتك على السماح لأي من
                الأشخاص القصر الذين تعيلهم باستخدام هذا الموقع. ولا يجوز لك
                استخدام منتجاتنا لأي غرض غير قانوني أو غير مصرح به، كما لا يجوز
                لك عند استخدام الخدمة انتهاك أي قوانين في ولايتك القضائية (بما
                في ذلك على سبيل المثال لا الحصر قوانين حقوق الطبع والنشر). ولا
                يجوز لك أيضًا نقل أي فيروسات الدودة أو فيروسات متنقلة أو أي رمز
                ذي طبيعة مدمرة، حيث يؤدي انتهاك أو مخالفة أي من الشروط إلى
                الإنهاء الفوري لخدماتك.
              </p>

              <h2>الجزء 2 – الشروط العامة</h2>

              <p>
                حتفظ بالحق في رفض توفير الخدمة لأي شخص لأي سبب في أي وقت، وتدرك
                أن محتواك (الذي لا يشمل معلومات بطاقة الائتمان) قد يُنقل دون
                تشفير ويتضمن ذلك (أ) عمليات النقل عبر شبكات مختلفة؛ و (ب)
                التغييرات للتوافق والتكيف مع المتطلبات الفنية لتوصيل الشبكات أو
                الأجهزة. ودائمًا ما يتم تشفير معلومات بطاقة الائتمان أثناء النقل
                عبر الشبكات. وتوافق على عدم إعادة إنتاج أو تكرار أو نسخ أو بيع
                أو إعادة بيع أو استغلال أي جزء من الخدمة أو استخدام الخدمة أو
                الوصول إلى الخدمة أو أي جهة اتصال على الموقع الإلكتروني من
                خلالها يتم توفير الخدمة دون إذن كتابي صريح من جانبنا. وتم إدراج
                العناوين المستخدمة في هذه الاتفاقية لأغراض السهولة فقط ولن تقيد
                هذه الشروط أو تؤثر عليها.
              </p>

              <h2>لجزء 3 - دقة المعلومات واكتمالها وحداثتها</h2>

              <p>
                سنا مسؤولين إذا كانت المعلومات المتوفرة على هذا الموقع غير دقيقة
                أو كاملة أو حديثة. ويتم توفير المواد الموجودة على هذا الموقع
                للحصول على معلومات عامة فقط ولا ينبغي الاعتماد عليها أو
                استخدامها كأساس وحيد لاتخاذ القرارات دون استشارة مصادر المعلومات
                الأولية أو الأكثر دقة أو الأكثر اكتمالًا أو الموفرة في الوقت
                المناسب. وأي اعتماد على المواد الموجودة على هذا الموقع سيكون على
                عاتقك ومسؤوليتك. وقد يحتوي هذا الموقع على معلومات تاريخية معينة.
                وهذه المعلومات التاريخية قد لا تكون بالضرورة حديثة ويتم توفيرها
                لك لأغراض الاطلاع فقط. ونحتفظ بالحق في تعديل محتويات هذا الموقع
                في أي وقت، لكننا لسنا ملزمين بتحديث أي معلومات على موقعنا. كما
                توافق على أنه يقع على عاتقك ومسؤوليتك مراقبة التغييرات الحاصلة
                على موقعنا.
              </p>

              <h2>الجزء 4 – التعديلات على الخدمة والأسعار</h2>

              <p>
                تخضع أسعار منتجاتنا للتغيير دون تقديم إشعار بذلك، ونحتفظ بالحق
                في أي وقت في تعديل أو إيقاف الخدمة (أو أي جزء أو محتوى منها) دون
                تقديم إشعار بذلك في أي وقت، ولن نكون مسؤولين تجاهك أو تجاه أي
                طرفٍ آخرٍ عن أي تعديل أو تغيير في الأسعار أو تعليق أو وقف
                للخدمة.
              </p>

              <h2>الجزء 5 – المنتجات أو الخدمات (حسب الحالة)</h2>

              <p>
                قد تتوفر بعض المنتجات أو الخدمات حصريًا عبر الإنترنت من خلال
                موقع الويب، وقد يكون لهذه المنتجات أو الخدمات كميات محدودة وتخضع
                للإرجاع أو الاستبدال فقط وفقًا لسياسة الإرجاع لدينا. ولقد بذلنا
                قصارى جهدنا لعرض ألوان وصور منتجاتنا التي تظهر في المتجر بأكبر
                قدر ممكن من الدقة، ولا يمكننا ضمان دقة عرض شاشة جهاز حاسوبك لأي
                لون، ونحتفظ بالحق، ولكننا لسنا ملزمين، بالحد من مبيعات منتجاتنا
                أو خدماتنا لأي شخص أو منطقة جغرافية أو ولاية قضائية. كما يمكننا
                ممارسة هذا الحق على أساس كل حالة على حدة. ونحتفظ أيضًا بالحق في
                تحديد كميات أي منتجات أو خدمات نقدمها، وتخضع جميع أوصاف المنتجات
                أو أسعار المنتجات للتغيير في أي وقت دون إشعار وفقًا لتقديرنا
                الخاص. ونحتفظ بالحق في إيقاف أي منتج في أي وقت. ويعد أي عرض لأي
                منتج أو خدمة يتم تقديمها على هذا الموقع باطلاً في حال كان
                محظورًا. ولا نضمن أن جودة أي منتجات أو خدمات أو معلومات أو مواد
                أخرى تم شراؤها أو الحصول عليها من قبلك ستفي بتوقعاتك أو أن أي
                أخطاء في الخدمة سيتم تصحيحها.
              </p>

              <h2>الجزء 6 – دقة الفواتير وبيانات الحساب</h2>

              <p>
                نحتفظ بالحق في رفض أي طلب تقدمه إلينا، ويمكننا حسب تقديرنا وحدنا
                تحديد أو إلغاء الكميات المشتراة لكل شخص أو لكل أسرة أو لكل طلب،
                وقد تشمل هذه القيود الطلبات المقدمة من قبل أو تحت نفس حساب
                العميل و/أو نفس بطاقة الائتمان و/أو الطلبات التي تستخدم نفس
                عنوان الفوترة و/أو الشحن. وفي حالة قيامنا بإجراء تغيير أو إلغاء
                طلب معين، فقد نحاول إخطارك بذلك عن طريق الاتصال بك عبر البريد
                الإلكتروني و/أو عنوان إرسال الفواتير/ رقم الهاتف الذي قدمته عند
                تقديم الطلب. ونحتفظ وفقًا لتقديرنا الخاص بحق تقييد أو حظر
                الطلبات التي يبدو أنها مقدمة من التجار أو البائعين أو الموزعين.
                وتوافق على تقديم معلومات الشراء والحساب الحالية والكاملة
                والدقيقة لجميع عمليات الشراء التي تتم في متجرنا، كما توافق على
                تحديث بيانات حسابك والمعلومات الأخرى على الفور، بما في ذلك عنوان
                بريدك الإلكتروني وأرقام بطاقات الائتمان وتواريخ انتهاء الصلاحية،
                وذلك حتى نتمكن من إكمال معاملاتك والاتصال بك عند الحاجة. وللحصول
                على المزيد من التفاصيل، يرجى مراجعة سياسة الإرجاع لدينا.
              </p>

              <h2>الجزء 7 – أدوات اختيارية</h2>

              <p>
                قد نوفر لك إمكانية الوصول إلى أدوات جهات خارجية وهذه الأدوات لا
                تخضع لرقابتنا وليس لدينا أي سيطرة أو مدخلات عليها. ولهذا تقر
                وتوافق على أننا نوفر الوصول إلى هذه الأدوات "كما هي" و "حسبما هي
                متوفرة" دون أي ضمانات أو إقرارات أو شروط من أي نوع وبدون أي
                تأكيد أو تأييد لها. ولن نتحمل أي مسؤولية من أي نوع تنشأ عن أو
                تتعلق باستخدامك لأدوات اختيارية تابعة لجهات خارجية، وأي استخدام
                من جانبك للأدوات الاختيارية المقدمة من خلال الموقع يكون على
                عاتقك ومسؤوليتك وتقديرك وحدك تمامًا، ويجب عليك التأكد من أنك على
                دراية بها وأن توافق على الشروط التي يتم بموجبها توفير هذه
                الأدوات من قبل مزودها (مزوديها) من الأطراف الخارجية ذات الصلة.
                ويجوز لنا أيضًا في المستقبل تقديم خدمات و/أو ميزات جديدة من خلال
                موقع الويب (بما في ذلك، إصدار أدوات وموارد جديدة)، وتخضع هذه
                الميزات و/أو الخدمات الجديدة أيضًا لشروط الخدمة هذه.
              </p>

              <h2>الجزء 8 – روابط الأطراف الخارجية</h2>

              <p>
                قد تشتمل بعض المحتويات والمنتجات والخدمات المتوفرة عبر خدمتنا
                على مواد من أطراف خارجية، وقد توجهك روابط الأطراف الخارجية
                الموجودة على هذا الموقع إلى مواقع ويب تابعة لجهات خارجية غير
                تابعة لنا. ولسنا مسؤولين عن فحص أو تقييم المحتوى أو الدقة ولا
                نضمن ولن نتحمل أي التزام أو مسؤولية عن أي مواد أو مواقع خاصة
                بأطراف خارجية، أو عن أي مواد أو منتجات أو خدمات أخرى لدى الأطراف
                الخارجية. ولن نكون مسؤولين عن أي ضرر أو أضرار تتعلق بشراء أو
                استخدام السلع أو الخدمات أو الموارد أو المحتوى أو أي معاملات
                أخرى تتم فيما يتعلق بأي مواقع ويب تابعة لجهات خارجية. يرجى
                مراجعة سياسات وممارسات الأطراف الخارجية بعناية والتأكد من فهمك
                لها قبل الدخول في أي معاملة. كما يجب توجيه الشكاوى أو المطالبات
                أو المخاوف أو الأسئلة المتعلقة بمنتجات الأطراف الخارجية إلى هذه
                الأطراف الخارجية.
              </p>

              <h2>الجزء 9 – تعليقات وملاحظات المستخدم والطلبات الأخرى</h2>

              <p>
                إذا قمت بناءً على طلبنا بإرسال بعض الطلبات المحددة (على سبيل
                المثال إدخالات المسابقة) أو دون طلب منا، فإنك ترسل أفكارًا
                إبداعيةً أو اقتراحات أو مقترحات أو خططًا أو مواد أخرى، سواء عبر
                الإنترنت أو عبر البريد الإلكتروني أو البريد العادي أو غير ذلك.
                (ويشار إليها إجمالاً بـ "التعليقات")، فإنك توافق على أنه يجوز
                لنا، في أي وقت ودون قيود، تعديل ونسخ ونشر وتوزيع وترجمة واستخدام
                أي تعليقات ترسلها إلينا بأي وسيلة أخرى. ونحن ولسنا ملزمين (1)
                بالحفاظ على سرية أي تعليقات؛ (2) دفع تعويض عن أي تعليقات؛ أو (3)
                الرد على أي تعليقات. ويجوز لنا، ولكننا لسنا ملزمين، بمراقبة أو
                تعديل أو إزالة المحتوى الذي نحدده وفقًا لتقديرنا الخاص بأنه غير
                قانوني أو مسيء أو تهديدي أو تشهيري أو إباحي أو فاحش أو مرفوض بأي
                شكل آخر أو ينتهك الملكية الفكرية لأي طرف أو شروط الخدمة هذه.
                وتوافق على أن تعليقاتك لن تنتهك أي حق لأي طرف آخر، بما في ذلك
                حقوق الطبع والنشر أو العلامة التجارية أو الخصوصية أو الشخصية أو
                أي حقوق شخصية أو حقوق ملكية أخرى. كما توافق أيضًا على أن
                تعليقاتك لن تحتوي على مواد تشهيرية أو غير قانونية أو مسيئة أو
                فاحشة أو تحتوي على أي فيروسات حاسوبية أو برامج ضارة أخرى يمكن أن
                تؤثر بأي شكل من الأشكال على تشغيل الخدمة أو أي موقع ويب ذي صلة.
                ولا يجوز لك استخدام عنوان بريد إلكتروني مزيف أو التظاهر بأنك شخص
                آخر غيرك أو تضليلنا أو تضليل الأطراف الخارجية فيما يتعلق بأصل أي
                تعليقات. وستكون لوحدك المسؤول عن أي تعليقات تدلي بها وعن دقتها،
                ولا نتحمل أي مسؤولية ولا نتحمل أي مسؤولية عن أي تعليقات تنشرها
                أنت أو أي طرف آخر.
              </p>

              <h2>الجزء 10 – المعلومات الشخصية</h2>

              <p>
                يخضع إرسالك للمعلومات الشخصية عبر المتجر لسياسة الخصوصية لدينا.
                انقر هنا لعرض سياسة الخصوصية لدينا.
              </p>

              <h2>الجزء 11 – الأخطاء وعدم الدقة والسهو</h2>

              <p>
                قد يكون هناك من حين لآخر معلومات على موقعنا أو في الخدمة تحتوي
                على أخطاء مطبعية أو عدم دقة أو سهو قد يتعلق بأوصاف المنتج
                والأسعار والعروض الترويجية والعروض ورسوم شحن المنتج وأوقات
                العبور والتوافر، ونحتفظ بالحق في تصحيح أي أخطاء أو عدم دقة أو
                سهو، وتغيير المعلومات أو تحديثها أو إلغاء الطلبات إذا كانت أي
                معلومات في الخدمة أو على أي موقع ويب ذي صلة غير دقيقة في أي وقت
                دون تقديم إشعار مسبق بذلك (بما في ذلك بعد تقديمك للطلب) ولا
                نتعهد بتحديث أو تعديل أو توضيح المعلومات في الخدمة أو على أي
                موقع ويب ذي صلة، بما في ذلك، على سبيل المثال لا الحصر، معلومات
                الأسعار، باستثناء ما يقتضيه القانون. ولا يوجد تحديث محدد أو
                تاريخ تحديث مطبق في الخدمة أو على أي موقع ويب ذي صلة يجب أن يؤخذ
                للإشارة إلى أنه تم تعديل أو تحديث جميع المعلومات الموجودة في
                الخدمة أو على أي موقع ويب ذي صلة.
              </p>

              <h2>الجزء 12 – الاستخدامات المحظورة</h2>

              <p>
                حظر عليك- بالإضافة إلى المحظورات الأخرى المنصوص عليها في شروط
                الخدمة- استخدام الموقع أو محتواه: (أ) لأي غرض غير قانوني؛ (ب) حث
                الآخرين على أداء أو المشاركة في أي أعمال غير قانونية؛ (ج) انتهاك
                أي لوائح أو قواعد أو قوانين أو مراسيم محلية دولية أو اتحادية أو
                إقليمية أو خاصة بالدولة؛ (د) التعدي على حقوق الملكية الفكرية
                الخاصة بنا أو حقوق الملكية الفكرية الخاصة بالآخرين أو انتهاكها؛
                (هـ) المضايقة أو الإساءة أو الإهانة أو الأذى أو الإضرار أو القذف
                أو التشهير أو الاستخفاف أو الترهيب أو التمييز على أساس الجنس أو
                التوجه الجنسي أو الدين أو الإثنية أو العرق أو السن أو الأصل
                القومي أو الإعاقة؛ (و) تقديم معلومات خاطئة أو مضللة؛ (ز) تحميل
                أو نقل فيروسات أو أي نوع آخر من الشفرات الخبيثة التي سيتم
                استخدامها أو يمكن استخدامها بأي طريقة من شأنها التأثير على وظيفة
                أو تشغيل الخدمة أو أي موقع ويب ذي صلة أو مواقع ويب أخرى أو
                الإنترنت بشكل عام؛ (ح) جمع أو تتبع المعلومات الشخصية التي تخص
                الآخرين؛ (1) إرسال بريد عشوائي أو احتيالي أو إعادة التوجيه لموقع
                مزيف أو الادعاء أو التصيد أو الزحف أو الكشط؛ (ي) لأي غرض فاحش أو
                غير أخلاقي؛ أو (ك) للتدخل أو التحايل على ميزات الأمان للخدمة أو
                أي موقع ويب ذي صلة أو مواقع ويب أخرى أو الإنترنت. ونحتفظ بالحق
                في إنهاء استخدامك للخدمة أو أي موقع ويب ذي صلة لانتهاك أي من
                الاستخدامات المحظورة.
              </p>

              <h2>لجزء 13 – التنصل من الضمانات وتحديد المسؤولية</h2>

              <p>
                لا نضمن أو نقر أو نؤكد أن استخدامك لخدمتنا سيكون دون انقطاع أو
                يوفر في الوقت المناسب أو أن يكون آمنًا أو خاليًا من الأخطاء، ولا
                نضمن أن النتائج التي يمكن الحصول عليها عند استخدام الخدمة ستكون
                دقيقة أو موثوقة. وتوافق على أنه يمكننا من حين لآخر إزالة الخدمة
                لفترات زمنية غير محددة أو إلغاء الخدمة في أي وقت دون إخطارك،
                وتوافق صراحةً على أن استخدامك للخدمة أو عدم قدرتك على استخدامها
                سيكون على مسؤوليتك وحدك، وتعتبر الخدمة وجميع المنتجات والخدمات
                المقدمة لك من خلال الخدمة (باستثناء ما هو مذكور صراحة من قبلنا)
                "كما هي" و "حسبما هي متوفرة" للاستخدام من قبلك، دون أي تأكيدات
                أو ضمانات أو شروط من أي نوع، سواء كانت صريحة أو ضمنيًا، بما في
                ذلك جميع الضمانات الضمنية أو شروط القابلية للتسويق والجودة
                التجارية والملاءمة لغرض معين والمتانة والملكية وعدم الانتهاك.
                ولا يجوز بأي حال من الأحوال أن تكون دانوب لمواد البناء ش.م.ح. أو
                المديرين أو الموظفين أو الشركات التابعة أو الوكلاء أو المتعاقدين
                أو المتدربين أو الموردين أو مزودي الخدمات أو المرخصين لدينا
                مسؤولين عن أي إصابة أو خسارة أو مطالبة أو أي أضرار مباشرة أو غير
                مباشرة أو عرضية أو عقابية أو خاصة أو تبعية من أي نوع، بما في
                ذلك، على سبيل المثال لا الحصر، فوات الأرباح أو الإيرادات أو
                المدخرات أو فقدان البيانات أو تكاليف الاستبدال أو أي أضرار
                مماثلة، سواء كانت مستندة إلى العقد أو المسؤولية التقصيرية (بما
                في ذلك الإهمال) أو المسؤولية المحددة والمشددة أو غير ذلك،
                الناشئة عن استخدامك لأي خدمة أو أي منتجات تم شراؤها باستخدام
                الخدمة، أو لأي مطالبة أخرى تتعلق بأي شكل من الأشكال باستخدامك
                للخدمة أو لأي منتج، بما في ذلك، على سبيل المثال لا الحصر، أي
                أخطاء أو سهو في أي محتوى أو أي خسارة أو ضرر من أي نوع يحدث نتيجة
                لاستخدام الخدمة أو أي محتوى (أو المنتج) الذي تم نشره أو نقله أو
                إتاحته بطريقة أخرى عبر الخدمة، حتى لو تم إعلامك بإمكانية ذلك.
                ونظرًا لأن بعض الدول أو الاختصاصات القضائية لا تسمح باستثناء أو
                تحديد المسؤولية عن الأضرار التبعية أو العرضية، في مثل هذه الدول
                أو الاختصاصات القضائية، فستكون مسؤوليتنا محدودة إلى أقصى حد يسمح
                به القانون.
              </p>

              <h2>الجزء 14 – التعويض</h2>

              <p>
                توافق على تعويض شركة دانوب لمواد البناء ش.م.ح والدفاع عنها
                وإبقائها مع شركتنا الأم والشركات الفرعية والشركات التابعة
                والشركاء والمسؤولين والمديرين والوكلاء والمقاولين والمرخصين
                ومزودي الخدمات والمقاولين من الباطن والموردين والمتدربين
                والموظفين بمنأى عن أي مطالبة أو طلب، بما في ذلك أتعاب المحاماة
                المعقولة، التي يتكبدها أي طرف آخر بسبب أو الناشئة عن انتهاكك
                لشروط الخدمة هذه أو المستندات التي تتضمنها بالإشارة إليها أو
                انتهاكك لأي قانون أو حقوق طرف آخر.
              </p>

              <h2>الجزء 15- استقلالية الأحكام</h2>

              <p>
                إذا تم اتخاذ قرار بأن أي شرط من شروط الخدمة هذه غير قانوني أو
                باطل أو غير قابل للتنفيذ، فيجب أن يكون هذا الحكم قابلاً للتنفيذ
                إلى أقصى حد يسمح به القانون المعمول به، ويعتبر الجزء غير القابل
                للتنفيذ منفصلاً عن شروط الخدمة، ولن يؤثر هذا القرار على صلاحية
                وإنفاذ أي أحكام أخرى متبقية
              </p>

              <h2>الجزء 16 – الإنهاء</h2>

              <p>
                تظل التزامات ومسؤوليات الأطراف المترتبة عليهم قبل تاريخ الإنهاء
                سارية المفعول بعد إنهاء هذه الاتفاقية لجميع الأغراض، وتكون شروط
                الخدمة هذه سارية المفعول ما لم يتم إنهاؤها من قبلك أو من قبلنا،
                ويمكنك إنهاء شروط الخدمة هذه في أي وقت عن طريق إخطارنا بأنك لم
                تعد راغبًا في استخدام خدماتنا، أو عندما تتوقف عن استخدام موقعنا.
                وإذا اعتبرنا بحكمنا لوحدنا بأنك فشلت، أو نشك في أنك فشلت، في
                الامتثال لأي بند أو شرط من شروط الخدمة هذه، فيجوز لنا أيضًا
                إنهاء هذه الاتفاقية في أي وقت دون إشعار مسبق وستظل مسؤولاً عن
                جميع المبالغ المستحقة حتى تاريخ الإنهاء (شاملاً لهذا التاريخ)؛
                و/أو بالتالي يجوز لنا منعك من الوصول إلى خدماتنا (أو أي جزء
                منها).
              </p>

              <h2>الجزء 17 – مجمل الاتفاقية</h2>

              <p>
                إن عدم إقدامنا على ممارسة أو إنفاذ أي حق أو شرط من شروط الخدمة
                هذه لا يعد تنازلاً عن هذا الحق أو الحكم، وتشكل شروط الخدمة هذه
                وأي سياسات أو قواعد تشغيل منشورة من قبلنا على هذا الموقع أو فيما
                يتعلق بالخدمة كامل ومجمل الاتفاقية والتفاهم بينك وبيننا وتحكم
                استخدامك للخدمة، وتحل محل أي اتفاقيات واتصالات ومقترحات سابقة أو
                معاصرة، سواء كانت شفهية أو مكتوبة، بينك وبيننا (بما في ذلك، على
                سبيل المثال لا الحصر، أي إصدارات سابقة من شروط الخدمة). ولا يجوز
                تفسير أي غموض في تفسير شروط الخدمة هذه ضد الجهة التي صاغت
                الاتفاقية أو التفاهم.
              </p>

              <h2>الجزء 18 – القانون الحاكم</h2>

              <p>
                تخضع شروط الخدمة وأية اتفاقيات منفصلة نقدم الخدمات إليك بموجبها
                وتفسر وفقًا لقوانين إمارة دبي، الإمارات العربية المتحدة 18022.
              </p>

              <h2>الجزء 19 – تغييرات شروط الخدمة</h2>

              <p>
                مكنك مراجعة أحدث إصدار من شروط الخدمة في أي وقت على هذه الصفحة،
                ونحتفظ وفقًا لتقديرنا بالحق في تحديث أو تغيير أو استبدال أي جزء
                من شروط الخدمة هذه عن طريق نشر التحديثات والتغييرات على موقعنا.
                ويقع على عاتقك مسؤولية التحقق من موقعنا بشكل دوري لمعرفة
                التغييرات. ويعد استمرار استخدامك أو الوصول إلى موقعنا على الويب
                أو الخدمة بعد نشر أي تغييرات على شروط الخدمة هذه يشكل قبولًا
                لهذه التغييرات.
              </p>

              <h2>الجزء 20 – بيانات الاتصال</h2>

              <p>
                يجب إرسال الأسئلة المتعلقة بشروط الخدمة إلينا على
                customerservice@milanoitalysrl.com
              </p>

              <h4>ملاحظة: </h4>
              <ul>
                <li style={{ direction: "rtl" }}>
                  قد يختلف لون المنتج قليلاً بسبب مصادر الإضاءة الفوتوغرافية أو
                  إعدادات شاشتك
                </li>
                <li style={{ direction: "rtl" }}>
                  من المقبول وجود اختلاف بسيط في الأبعاد والتصميم.
                </li>
              </ul>
            </Col>
          ) : (
            <Col xs={24}>
              <h2>OVERVIEW</h2>
              <p>
                This website is operated by Danube Building Material FZCO.
                Throughout the site, the terms “we”, “us” and “our” refer to
                Danube Home. Danube Building Material FZCO offers this website,
                including all information, tools and services available from
                this site to you, the user, conditioned upon your acceptance of
                all terms, conditions, policies and notices stated here. By
                visiting our site and/ or purchasing something from us, you
                engage in our “Service” and agree to be bound by the following
                terms and conditions (“Terms of Service”, “Terms”), including
                those additional terms and conditions and policies referenced
                herein and/or available by hyperlink. These Terms of Service
                apply to all users of the site, including without limitation
                users who are browsers, vendors, customers, merchants, and/ or
                contributors of content. Please read these Terms of Service
                carefully before accessing or using our website. By accessing or
                using any part of the site, you agree to be bound by these Terms
                of Service. If you do not agree to all the terms and conditions
                of this agreement, then you may not access the website or use
                any services. If these Terms of Service are considered an offer,
                acceptance is expressly limited to these Terms of Service. Any
                new features or tools which are added to the current store shall
                also be subject to the Terms of Service. You can review the most
                current version of the Terms of Service at any time on this
                page. We reserve the right to update, change or replace any part
                of these Terms of Service by posting updates and/or changes to
                our website. It is your responsibility to check this page
                periodically for changes. Your continued use of or access to the
                website following the posting of any changes constitutes
                acceptance of those changes.
              </p>

              <h2>SECTION 1 - ONLINE STORE TERMS</h2>

              <p>
                By agreeing to these Terms of Service, you represent that you
                are at least the age of majority in your state or province of
                residence, or that you are the age of majority in your state or
                province of residence and you have given us your consent to
                allow any of your minor dependents to use this site. You may not
                use our products for any illegal or unauthorized purpose nor may
                you, in the use of the Service, violate any laws in your
                jurisdiction (including but not limited to copyright laws). You
                must not transmit any worms or viruses or any code of a
                destructive nature. A breach or violation of any of the Terms
                will result in an immediate termination of your Services.
              </p>

              <h2>SECTION 2 - GENERAL CONDITIONS</h2>

              <p>
                We reserve the right to refuse service to anyone for any reason
                at any time. You understand that your content (not including
                credit card information), may be transferred unencrypted and
                involve (a) transmissions over various networks; and (b) changes
                to conform and adapt to technical requirements of connecting
                networks or devices. Credit card information is always encrypted
                during transfer over networks. You agree not to reproduce,
                duplicate, copy, sell, resell or exploit any portion of the
                Service, use of the Service, or access to the Service or any
                contact on the website through which the service is provided,
                without express written permission by us. The headings used in
                this agreement are included for convenience only and will not
                limit or otherwise affect these Terms.
              </p>

              <h2>
                SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION
              </h2>

              <p>
                We are not responsible if information made available on this
                site is not accurate, complete or current. The material on this
                site is provided for general information only and should not be
                relied upon or used as the sole basis for making decisions
                without consulting primary, more accurate, more complete or more
                timely sources of information. Any reliance on the material on
                this site is at your own risk. This site may contain certain
                historical information. Historical information, necessarily, is
                not current and is provided for your reference only. We reserve
                the right to modify the contents of this site at any time, but
                we have no obligation to update any information on our site. You
                agree that it is your responsibility to monitor changes to our
                site.
              </p>

              <h2>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</h2>

              <p>
                Prices for our products are subject to change without notice. We
                reserve the right at any time to modify or discontinue the
                Service (or any part or content thereof) without notice at any
                time. We shall not be liable to you or to any third-party for
                any modification, price change, suspension or discontinuance of
                the Service.
              </p>

              <h2>SECTION 5 - PRODUCTS OR SERVICES (If Applicable)</h2>

              <p>
                Certain products or services may be available exclusively online
                through the website. These products or services may have limited
                quantities and are subject to return or exchange only according
                to our Return Policy. We have made every effort to display as
                accurately as possible the colors and images of our products
                that appear at the store. We cannot guarantee that your computer
                monitor's display of any color will be accurate. We reserve the
                right, but are not obligated, to limit the sales of our products
                or Services to any person, geographic region or jurisdiction. We
                may exercise this right on a case-by-case basis. We reserve the
                right to limit the quantities of any products or services that
                we offer. All descriptions of products or product pricing are
                subject to change at any time without notice, at the sole
                discretion of us. We reserve the right to discontinue any
                product at any time. Any offer for any product or service made
                on this site is void where prohibited. We do not warrant that
                the quality of any products, services, information, or other
                material purchased or obtained by you will meet your
                expectations, or that any errors in the Service will be
                corrected.
              </p>

              <h2>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</h2>

              <p>
                We reserve the right to refuse any order you place with us. We
                may, in our sole discretion, limit or cancel quantities
                purchased per person, per household or per order. These
                restrictions may include orders placed by or under the same
                customer account, the same credit card, and/or orders that use
                the same billing and/or shipping address. In the event that we
                make a change to or cancel an order, we may attempt to notify
                you by contacting the e-mail and/or billing address/phone number
                provided at the time the order was made. We reserve the right to
                limit or prohibit orders that, in our sole judgment, appear to
                be placed by dealers, resellers or distributors. You agree to
                provide current, complete and accurate purchase and account
                information for all purchases made at our store. You agree to
                promptly update your account and other information, including
                your email address and credit card numbers and expiration dates,
                so that we can complete your transactions and contact you as
                needed. For more detail, please review our Returns Policy.
              </p>

              <h2>SECTION 7 - OPTIONAL TOOLS</h2>

              <p>
                We may provide you with access to third-party tools over which
                we neither monitor nor have any control nor input. You
                acknowledge and agree that we provide access to such tools ”as
                is” and “as available” without any warranties, representations
                or conditions of any kind and without any endorsement. We shall
                have no liability whatsoever arising from or relating to your
                use of optional third-party tools. Any use by you of optional
                tools offered through the site is entirely at your own risk and
                discretion and you should ensure that you are familiar with and
                approve of the terms on which tools are provided by the relevant
                third-party provider(s).We may also, in the future, offer new
                services and/or features through the website (including, the
                release of new tools and resources). Such new features and/or
                services shall also be subject to these Terms of Service.
              </p>

              <h2>SECTION 8 - THIRD-PARTY LINKS</h2>

              <p>
                Certain content, products and services available via our Service
                may include materials from third-parties. Third-party links on
                this site may direct you to third-party websites that are not
                affiliated with us. We are not responsible for examining or
                evaluating the content or accuracy and we do not warrant and
                will not have any liability or responsibility for any
                third-party materials or websites, or for any other materials,
                products, or services of third-parties. We are not liable for
                any harm or damages related to the purchase or use of goods,
                services, resources, content, or any other transactions made in
                connection with any third-party websites. Please review
                carefully the third-party's policies and practices and make sure
                you understand them before you engage in any transaction.
                Complaints, claims, concerns, or questions regarding third-party
                products should be directed to the third-party.
              </p>

              <h2>SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h2>

              <p>
                If, at our request, you send certain specific submissions (for
                example contest entries) or without a request from us you send
                creative ideas, suggestions, proposals, plans, or other
                materials, whether online, by email, by postal mail, or
                otherwise (collectively, 'comments'), you agree that we may, at
                any time, without restriction, edit, copy, publish, distribute,
                translate and otherwise use in any medium any comments that you
                forward to us. We are and shall be under no obligation (1) to
                maintain any comments in confidence; (2) to pay compensation for
                any comments; or (3) to respond to any comments. We may, but
                have no obligation to, monitor, edit or remove content that we
                determine in our sole discretion are unlawful, offensive,
                threatening, libelous, defamatory, pornographic, obscene or
                otherwise objectionable or violates any party’s intellectual
                property or these Terms of Service. You agree that your comments
                will not violate any right of any third-party, including
                copyright, trademark, privacy, personality or other personal or
                proprietary right. You further agree that your comments will not
                contain libelous or otherwise unlawful, abusive or obscene
                material, or contain any computer virus or other malware that
                could in any way affect the operation of the Service or any
                related website. You may not use a false e-mail address, pretend
                to be someone other than yourself, or otherwise mislead us or
                third-parties as to the origin of any comments. You are solely
                responsible for any comments you make and their accuracy. We
                take no responsibility and assume no liability for any comments
                posted by you or any third-party.
              </p>

              <h2>SECTION 10 - PERSONAL INFORMATION</h2>

              <p>
                Your submission of personal information through the store is
                governed by our Privacy Policy. To view our Privacy Policy.
              </p>

              <h2>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</h2>

              <p>
                Occasionally there may be information on our site or in the
                Service that contains typographical errors, inaccuracies or
                omissions that may relate to product descriptions, pricing,
                promotions, offers, product shipping charges, transit times and
                availability. We reserve the right to correct any errors,
                inaccuracies or omissions, and to change or update information
                or cancel orders if any information in the Service or on any
                related website is inaccurate at any time without prior notice
                (including after you have submitted your order).We undertake no
                obligation to update, amend or clarify information in the
                Service or on any related website, including without limitation,
                pricing information, except as required by law. No specified
                update or refresh date applied in the Service or on any related
                website, should be taken to indicate that all information in the
                Service or on any related website has been modified or updated.
              </p>

              <h2>SECTION 12 - PROHIBITED USES</h2>

              <p>
                In addition to other prohibitions as set forth in the Terms of
                Service, you are prohibited from using the site or its content:
                (a) for any unlawful purpose; (b) to solicit others to perform
                or participate in any unlawful acts; (c) to violate any
                international, federal, provincial or state regulations, rules,
                laws, or local ordinances; (d) to infringe upon or violate our
                intellectual property rights or the intellectual property rights
                of others; (e) to harass, abuse, insult, harm, defame, slander,
                disparage, intimidate, or discriminate based on gender, sexual
                orientation, religion, ethnicity, race, age, national origin, or
                disability; (f) to submit false or misleading information; (g)
                to upload or transmit viruses or any other type of malicious
                code that will or may be used in any way that will affect the
                functionality or operation of the Service or of any related
                website, other websites, or the Internet; (h) to collect or
                track the personal information of others; (i) to spam, phish,
                pharm, pretext, spider, crawl, or scrape; (j) for any obscene or
                immoral purpose; or (k) to interfere with or circumvent the
                security features of the Service or any related website, other
                websites, or the Internet. We reserve the right to terminate
                your use of the Service or any related website for violating any
                of the prohibited uses.
              </p>

              <h2>
                SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
              </h2>

              <p>
                We do not guarantee, represent or warrant that your use of our
                service will be uninterrupted, timely, secure or error-free. We
                do not warrant that the results that may be obtained from the
                use of the service will be accurate or reliable. You agree that
                from time to time we may remove the service for indefinite
                periods of time or cancel the service at any time, without
                notice to you. You expressly agree that your use of, or
                inability to use, the service is at your sole risk. The service
                and all products and services delivered to you through the
                service are (except as expressly stated by us) provided 'as is'
                and 'as available' for your use, without any representation,
                warranties or conditions of any kind, either express or implied,
                including all implied warranties or conditions of
                merchantability, merchantable quality, fitness for a particular
                purpose, durability, title, and non-infringement. In no case
                shall Danube Building Material FZCO, our directors, officers,
                employees, affiliates, agents, contractors, interns, suppliers,
                service providers or licensors be liable for any injury, loss,
                claim, or any direct, indirect, incidental, punitive, special,
                or consequential damages of any kind, including, without
                limitation lost profits, lost revenue, lost savings, loss of
                data, replacement costs, or any similar damages, whether based
                in contract, tort (including negligence), strict liability or
                otherwise, arising from your use of any of the service or any
                products procured using the service, or for any other claim
                related in any way to your use of the service or any product,
                including, but not limited to, any errors or omissions in any
                content, or any loss or damage of any kind incurred as a result
                of the use of the service or any content (or product) posted,
                transmitted, or otherwise made available via the service, even
                if advised of their possibility. Because some states or
                jurisdictions do not allow the exclusion or the limitation of
                liability for consequential or incidental damages, in such
                states or jurisdictions, our liability shall be limited to the
                maximum extent permitted by law.
              </p>

              <h2>SECTION 14 - INDEMNIFICATION</h2>

              <p>
                You agree to indemnify, defend and hold harmless Danube Building
                Material FZCO and our parent, subsidiaries, affiliates,
                partners, officers, directors, agents, contractors, licensors,
                service providers, subcontractors, suppliers, interns and
                employees, harmless from any claim or demand, including
                reasonable attorneys’ fees, made by any third-party due to or
                arising out of your breach of these Terms of Service or the
                documents they incorporate by reference, or your violation of
                any law or the rights of a third-party.
              </p>

              <h2>SECTION 15 - SEVERABILITY</h2>

              <p>
                In the event that any provision of these Terms of Service is
                determined to be unlawful, void or unenforceable, such provision
                shall nonetheless be enforceable to the fullest extent permitted
                by applicable law, and the unenforceable portion shall be deemed
                to be severed from these Terms of Service, such determination
                shall not affect the validity and enforceability of any other
                remaining provisions.
              </p>

              <h2>SECTION 16 - TERMINATION</h2>

              <p>
                The obligations and liabilities of the parties incurred prior to
                the termination date shall survive the termination of this
                agreement for all purposes. These Terms of Service are effective
                unless and until terminated by either you or us. You may
                terminate these Terms of Service at any time by notifying us
                that you no longer wish to use our Services, or when you cease
                using our site. If in our sole judgment you fail, or we suspect
                that you have failed, to comply with any term or provision of
                these Terms of Service, we also may terminate this agreement at
                any time without notice and you will remain liable for all
                amounts due up to and including the date of termination; and/or
                accordingly may deny you access to our Services (or any part
                thereof).
              </p>

              <h2>SECTION 17 - ENTIRE AGREEMENT</h2>

              <p>
                The failure of us to exercise or enforce any right or provision
                of these Terms of Service shall not constitute a waiver of such
                right or provision.These Terms of Service and any policies or
                operating rules posted by us on this site or in respect to The
                Service constitutes the entire agreement and understanding
                between you and us and govern your use of the Service,
                superseding any prior or contemporaneous agreements,
                communications and proposals, whether oral or written, between
                you and us (including, but not limited to, any prior versions of
                the Terms of Service).Any ambiguities in the interpretation of
                these Terms of Service shall not be construed against the
                drafting party.
              </p>

              <h2>SECTION 18 - GOVERNING LAW</h2>

              <p>
                These Terms of Service and any separate agreements whereby we
                provide you Services shall be governed by and construed in
                accordance with the laws of Dubai AE 18022.
              </p>

              <h2>SECTION 19 - CHANGES TO TERMS OF SERVICE</h2>

              <p>
                You can review the most current version of the Terms of Service
                at any time at this page. We reserve the right, at our sole
                discretion, to update, change or replace any part of these Terms
                of Service by posting updates and changes to our website. It is
                your responsibility to check our website periodically for
                changes. Your continued use of or access to our website or the
                Service following the posting of any changes to these Terms of
                Service constitutes acceptance of those changes.
              </p>

              <h2>SECTION 20 - CONTACT INFORMATION</h2>

              <p>
                Questions about the Terms of Service should be sent to us at
                customerservice@milanoitalysrl.com
              </p>

              <h4>Note : </h4>
              <ul>
                <li>
                  Product color may slightly vary due to photographic lighting
                  sources or your monitor settings.
                </li>
                <li>
                  It is acceptable to have a slight mismatch in dimensions and
                  design.
                </li>
              </ul>
            </Col>
          )}
        </Row>
      </Layout>
    </>
  );
};
