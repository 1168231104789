import {
  ArrowLeftOutlined,
  DownloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import {
  Badge,
  Button,
  Col,
  Layout,
  notification,
  Pagination,
  Row,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { FileUpload } from 'components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import API_SERVICE from 'services/api-service';
import {
  consverPriceToInternationalFormat,
  textTransform,
} from 'services/util';
import {
  STATUS_CONFIRMED,
  STATUS_DELIVERED,
  STATUS_LPO_SIGNED,
  STATUS_LPO_SIGN_OFF,
  STATUS_NEW,
  STATUS_PROCESSING,
  STATUS_SHIPPED,
} from 'shared/constants/Constants';
import { dealerOrderStatusText } from 'shared/services/Utility';
import MilanoThumbnail from '../../../assets/MilanoThumbnail.png';
import './index.scss';

interface orderDetails {
  id: string;
  status: string;
  createdAt: string;
  totalAmount: string;
  totalCbm: string;
  orderNumber: string;
  deliverySlot: string;
  statusColor: {
    hex: string;
  };
  shippingAddress: {
    id: number;
    name: string;
    phone: any;
    address: string;
    building: string;
    street: string;
    area: string;
    zipcode: number;
    city: string;
    country: string;
  };
  billingAddress: {
    id: number;
    name: string;
    phone: any;
    address: string;
    building: string;
    street: string;
    area: string;
    zipcode: number;
    city: string;
    country: string;
  };
}

function OrderDetails() {
  //@ts-ignore
  const parameter = useParams();
  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState({} as orderDetails);
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    perpage: 15,
    total: 10,
  });

  const fetchSingleOrderDetails = async (page?: number, perpage?: number) => {
    try {
      setLoading(true);
      const params = {
        page: pagination.page,
        perpage: pagination.perpage,
      };

      if (page) {
        params['page'] = page;
      }
      if (perpage) {
        params['perpage'] = perpage;
      }
      const {
        data: { data },
      } = await API_SERVICE.DealerfetchOrderById(parameter.id, params);
      if (data.order) {
        setOrderDetails(data.order);
      }
      if (data.products) {
        setProductList(data.products.items);
        setPagination({
          page: data.products.page,
          perpage: data.products.perpage,
          pages: data.products.pages,
          total: data.products.total,
        });
      }

      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: 'bottomLeft',
      });
    }
  };
  const downloadGeneratedLPO = async (id: number) => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.downloadGeneratedLPO(id);
      window.open(data.url, '_blank');
    } catch (e) {
      notification.error({
        message: API_SERVICE.handleErrors(e),
        placement: 'bottomLeft',
      });
    }
  };
  const getCurrency = () => {
    const user = JSON.parse(localStorage.getItem('user')!);
    setCurrency(user?.user.dealerDetails[0]?.currencyType);
  };

  const handleUploadLPO = async (id: number, lpoId: number) => {
    try {
      const params = {
        lpoId,
      };
      await API_SERVICE.uploadSignedLPO(id, params);
      notification.success({
        message: `Uploaded Successfully`,
        placement: 'bottomLeft',
      });
      fetchSingleOrderDetails();
    } catch (e) {
      notification.error({
        message: API_SERVICE.handleErrors(e),
        placement: 'bottomLeft',
      });
    }
  };

  const deliveryStatusButtons = (status: string, obj: any) => {
    switch (status) {
      case STATUS_NEW:
        return;
      case STATUS_LPO_SIGN_OFF:
        return (
          <>
            <FileUpload
              type={'DOCUMENT'}
              showList="false"
              style={{ display: 'none' }}
              onChange={(id: any) => {
                handleUploadLPO(obj.id, id);
              }}
            >
              <Button className="theme-btn mr-2">
                <UploadOutlined />
                Upload Signed LPO
              </Button>
            </FileUpload>
            <Button
              className="theme-btn"
              onClick={() => {
                downloadGeneratedLPO(obj.id);
              }}
            >
              <DownloadOutlined />
              Download LPO
            </Button>
          </>
        );

      case STATUS_LPO_SIGNED:
      case STATUS_PROCESSING:
      case STATUS_CONFIRMED:
      case STATUS_SHIPPED:
        return (
          <>
            <Button
              className="theme-btn"
              onClick={() => {
                window.open(obj.lpo?.url);
              }}
            >
              <DownloadOutlined />
              Download Signed LPO
            </Button>
          </>
        );
      case STATUS_DELIVERED:
        return (
          <>
            <Button
              className="theme-btn mr-2"
              onClick={() => {
                window.open(obj.lpo?.url);
              }}
            >
              <DownloadOutlined />
              Download Signed LPO
            </Button>
            <Button className="theme-btn">
              <DownloadOutlined />
              Download Tax Invoice
            </Button>
            ;
          </>
        );
      default:
        return;
    }
  };

  useEffect(() => {
    fetchSingleOrderDetails();
    getCurrency();
  }, [parameter]);

  return (
    <Layout className="products-bg-color">
      <Content className="contentPadding">
        <Layout className="product-main-content-layout">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={24} className="mb-2 ">
              <span className="backBtnOrder" onClick={() => history.goBack()}>
                <ArrowLeftOutlined /> MY ORDERS
              </span>
            </Col>
            <Col xs={24} sm={24} md={24} className="mb-3">
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={16}
                  className="order-details-page-title"
                >
                  Order Details | {orderDetails?.orderNumber}
                  {/* <span className="orderTop">
                    <Badge className="orderBadge" status="success" />
                    Delivered
                  </span> */}
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={8}
                  className="order-details-buttons myOrdersBatch "
                >
                  {/* <span>{orderDetails?.status}</span> */}
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} className="order-details-topbar ">
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={6}
                  xl={4}
                  className="order-details-title order-details-top"
                >
                  {moment(orderDetails.createdAt).format('DD MMMM YYYY')}
                </Col>{' '}
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={6}
                  xl={5}
                  className="order-details-title order-details-top"
                >
                  {orderDetails.orderTotalAmount ? ' Payable Amount' : 'Total'}{' '}
                  <span>
                    {currency === 'USD' ? '$' : 'AED'}{' '}
                    {orderDetails?.totalAmount}
                  </span>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={6}
                  xl={3}
                  className="order-details-title order-details-top"
                >
                  Total CBM <span> {orderDetails?.totalCbm}</span>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={6}
                  xl={4}
                  className="order-details-title myOrdersBatch order-details-top p-0"
                >
                  <span>{orderDetails?.status}</span>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={16}
                  lg={12}
                  xl={8}
                  className="order-details-title order-details-top p-0"
                >
                  Delivery Slot{' '}
                  <span>
                    {orderDetails?.deliverySlot === 'Morning' &&
                      'Morning (8 AM - 12 PM)'}
                    {orderDetails?.deliverySlot === 'Afternoon' &&
                      'Afternoon (12 PM TO 4 PM)'}
                    {orderDetails?.deliverySlot === 'Evening' &&
                      'Evening (4 PM TO 8 PM)'}
                  </span>
                </Col>
                {orderDetails.orderTotalAmount ? (
                  <>
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      className="order-details-title order-details-top pt-1 "
                    >
                      Total Amount{' '}
                      <span>
                        {currency === 'USD' ? '$' : 'AED'}{' '}
                        {consverPriceToInternationalFormat(
                          orderDetails?.orderTotalAmount
                        )}
                      </span>
                    </Col>
                    {/* <Col
                      xs={24}
                      sm={24}
                      md={12}
                      className="order-details-title order-details-top pt-1 "
                    >
                      Discount Amount{" "}
                      <span>
                        {currency === "USD" ? "$" : "AED"}{" "}
                        {consverPriceToInternationalFormat(
                          orderDetails?.discountAmount
                        )}
                      </span>
                    </Col> */}
                  </>
                ) : null}
              </Row>
            </Col>
            <Col xs={24} className="mt-3 mb-3">
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  className="order-addres-bar"
                >
                  <div className="left">
                    <LocationOnOutlinedIcon />
                    <p>Shipping Address</p>
                  </div>
                  <div className="right">
                    <p className="name">
                      {orderDetails?.shippingAddress?.name}
                    </p>
                    <p className="address">
                      {`${orderDetails?.shippingAddress?.street},${orderDetails?.shippingAddress?.address}, ${orderDetails?.shippingAddress?.area}, ${orderDetails?.shippingAddress?.building} ${orderDetails?.shippingAddress?.city} ${orderDetails?.shippingAddress?.country} Pincode - ${orderDetails?.shippingAddress?.zipcode}`}
                    </p>
                    <p className="phone-number">
                      Phone Number : {orderDetails?.shippingAddress?.phone}
                    </p>
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  className="order-addres-bar"
                >
                  <div className="left">
                    <LocationOnOutlinedIcon />
                    <p>Billing Address</p>
                  </div>
                  <div className="right">
                    <p className="name">{orderDetails?.billingAddress?.name}</p>
                    <p className="address">
                      {`${orderDetails?.billingAddress?.street},${orderDetails?.billingAddress?.address}, ${orderDetails?.billingAddress?.area}, ${orderDetails?.billingAddress?.building} ${orderDetails?.billingAddress?.city} ${orderDetails?.billingAddress?.country} Pincode - ${orderDetails?.billingAddress?.zipcode}`}
                    </p>
                    <p className="phone-number">
                      Phone Number : {orderDetails?.billingAddress?.phone}
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} className="order-details-topbar ">
              <Row>
                <Col
                  xs={9}
                  sm={9}
                  md={9}
                  lg={12}
                  className="order-details-title"
                >
                  Items
                </Col>
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={4}
                  className="order-details-title p-0 text-center"
                >
                  Quantity
                </Col>
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={4}
                  className="order-details-title p-0 text-center"
                >
                  Item Price
                </Col>
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={4}
                  className="order-details-title p-0 text-center"
                >
                  Sub Total
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24}>
              {productList?.length !== 0 ? (
                productList?.map((product: any) => {
                  return (
                    <OrderDetailsCard product={product} key={product?.id} />
                  );
                })
              ) : (
                <p className="no-prod">No Product Added to WishList </p>
              )}
            </Col>

            <Col
              className=" mt-3"
              xs={24}
              sm={24}
              md={24}
              style={{ display: 'grid', placeItems: 'center' }}
            >
              <Pagination
                className="pagination"
                current={pagination.page}
                defaultCurrent={pagination.page}
                total={pagination.total}
                pageSize={pagination.perpage}
                responsive
                showSizeChanger={false}
                onChange={(page, size) => {
                  //@ts-ignore
                  setPagination({ page: page, perpage: size });
                  fetchSingleOrderDetails(page, size);
                  //@ts-ignore
                  pageTopRef.current.scrollIntoView();
                }}
              />
            </Col>
          </Row>
        </Layout>
      </Content>
    </Layout>
  );
}

export default OrderDetails;

function OrderDetailsCard({ product }: any) {
  const user = JSON.parse(localStorage.getItem('user')!);
  const currency = user?.user.dealerDetails[0]?.currencyType;

  return (
    <Row className="orderSingleProd">
      <Col xs={9} sm={9} md={9} lg={12}>
        <div className="orderCardContainer">
          <div className="imgContainer">
            <img
              src={
                product.product?.thumbnail
                  ? product?.product?.thumbnail?.url
                  : MilanoThumbnail
              }
              alt={product?.product?.thumbnail?.name}
            />
          </div>
          <div className="infoContainer">
            <p className="title">{product?.product?.name} </p>
            <p className="sku">SKU: {product?.product?.skuId}</p>
          </div>
        </div>
      </Col>
      <Col xs={5} sm={5} md={5} lg={4}>
        <p className="orderPrice text-center">{product?.quantity}</p>
      </Col>
      <Col xs={5} sm={5} md={5} lg={4}>
        <p className="orderPrice text-center">
          {' '}
          {currency === 'USD' ? '$' : 'AED'} {product?.price}
        </p>
      </Col>
      <Col xs={5} sm={5} md={5} lg={4}>
        <p className="orderPrice text-center">
          {' '}
          {currency === 'USD' ? '$' : 'AED'}{' '}
          {(product?.price * product?.quantity).toFixed(2)}
        </p>
      </Col>
    </Row>
  );
}
