import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { Input, notification } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { Row, Col, Modal, Button, Switch, Table } from "antd";

import { Delete, Edit } from "@material-ui/icons";
import { BANNER_ADD } from "shared/constants/RouteConstants";
import API_SERVICE from "services/api-service";
import { acceptedVideosTypes } from "shared/constants/Constants";
import AccessControl from "services/AccessControl";

const BannerAddEdit = () => {
  const [bannersList, setBannersList] = useState([]);
  let history = useHistory();

  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    perpage: 10,
    total: 1,
  });

  const getBanners = async (page?: number, perpage?: number) => {
    try {
      setLoading(true);
      const params = { ...pagination, bannerType: "HOME_BANNER" };
      if (page) {
        params["page"] = page;
      }
      if (perpage) {
        params["perpage"] = perpage;
      }
      const {
        data: { data },
      } = await API_SERVICE.getAdminBannersList(params);

      if (data.length !== 0) {
        setBannersList(data.items);
        setPagination({
          page: data.page,
          perpage: data.perpage,
          total: data.total,
          pages: data.pages,
        });
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const deleteBanner = async (id: number) => {
    try {
      await API_SERVICE.deleteHomeBanner(id);
      notification.success({
        message: "Delete Banner Successfully",
        placement: "bottomRight",
      });
      getBanners();
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  const toogleActiveInactive = async (id: number, isActive: boolean) => {
    try {
      let status = "";
      if (isActive) {
        status = "ACTIVE";
      } else {
        status = "INACTIVE";
      }
      await API_SERVICE.bannerActiveInactiveToogleCall(id, {
        status,
        bannerType: "HOME_BANNER",
      });

      getBanners(pagination.page);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const confirm = (id: number) => {
    Modal.confirm({
      title: "Are You Sure ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      onOk: () => deleteBanner(id),
      cancelText: "Cancel",
    });
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      sorter: (a: any, b: any) => b.id - a.id,
      width: 110,
    },
    {
      title: "Image ",
      dataIndex: "file",

      render: (_file: any) => {
        const ext = _file.url.slice(
          ((_file.url.lastIndexOf(".") - 1) >>> 0) + 2
        );

        return acceptedVideosTypes.includes(ext) ? (
          <>
            <video
              style={{ cursor: "auto", aspectRatio: "16 / 9" }}
              width="100%"
              height="100%"
              className="bannerTableImg"
              muted
            >
              <source src={_file.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>
        ) : (
          <a href={_file?.url} target="_blank" rel="noreferrer">
            <img
              className="bannerTableImg"
              src={_file?.url}
              alt={_file?.name}
            />
          </a>
        );
      },
    },
    {
      title: "Type ",
      dataIndex: "type",
    },

    {
      title: "Display Order ",
      dataIndex: "displayOrder",
      //   filters: [

      //   ],
      //   onFilter: (value:string, record:any) => record.dealerName.indexOf(value) === 0,
      //   filterIcon: (filtered :any ) => <DownOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    },

    {
      title: "STATUS",
      render: (d: any) => (
        <div>
          <Switch
            onChange={(e: any) => {
              toogleActiveInactive(d.id, e);
            }}
            checked={d.status === "ACTIVE" ? true : false}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
          />
        </div>
      ),
    },
    {
      title: "ACTION: ",
      //   dataIndex: "",
      render: (d: any) => (
        <div className="bannerActionButtons">
          <AccessControl id={19}>
              <Button
            shape="circle"
            onClick={() => history.push(`${BANNER_ADD}/${d?.id}`)}
          >
            <Edit />
          </Button>
          </AccessControl>
          <AccessControl id={23}>
          <Button shape="circle" onClick={() => confirm(d?.id)}>
            <Delete />
          </Button>
          </AccessControl>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getBanners();
  }, []);

  return (
    <AccessControl id={12}>
    <>
      <Row
        className="align-items-center  stock-summary-container mb-2 mt-2"
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
        }}
      >
        <Col xs={12} md={12} className="p-0">
          <div className="manage-products-title">Banner List</div>
        </Col>
        <AccessControl id={16}>
        <Col xs={12} md={12} className="flex justify-content-end">
          <Button
            className="product-btn"
            onClick={() => history.push(BANNER_ADD)}
          >
            <PlusOutlined />
            Add New Banner
          </Button>
          
        </Col>
        </AccessControl>
      </Row>

      <Row>
        <Col xs={24} className="mb-3 mt-3">
          <Table
            scroll={{ x: true }}
            loading={loading}
            pagination={{
              current: pagination.page || 1,
              defaultCurrent: pagination.page,
              pageSize: pagination.perpage,
              total: pagination.total,
              onChange: (page: number, size: number) => {
                setPagination({ page, perpage: size });
                getBanners(page, size);
              },
            }}
            columns={columns}
            dataSource={bannersList}
            size="middle"
          />
        </Col>
      </Row>
    </>
     </AccessControl>
  );
  
};

export default BannerAddEdit;
