import "./SalesProfile.scss";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Form, Input, Row, notification, Layout } from "antd";
import smartphone from "../../../assets/icons/smartphone.png";
import userPic from "../../../assets/icons/username.png";
import mailIcon from "../../../assets/icons/mail.png";
import lockIcon from "shared/assets/icons/lockIcon.png";
import { SALES_RESET_PASSWORD } from "shared/constants/RouteConstants";
import API_SERVICE from "services/api-service";
import { useEffect, useState } from "react";
import { FileUpload } from "components";
import { Content } from "antd/lib/layout/layout";
import MilanoThumbnail from "../../../assets/MilanoThumbnail.png";

export default function SalesProfile(props: any) {
  const [form] = Form.useForm();
  const { type } = useParams();
  let history = useHistory();
  const [profilePic, setProfilePic] = useState();
  const [profilePicId, setProfilePicId] = useState();
  const profileUpdate = ({ id, url }) => {
    setProfilePicId(id);
    setProfilePic(url);
  };
  const [edit, setEdit] = useState(false);
  const getUserDetails = async () => {
    console.log("ppppp", props);
    try {
      const {
        data: { data },
      } = await API_SERVICE.self();
      form.setFieldsValue({
        ["name"]: data?.name,
        ["email"]: data?.email,
        ["phone"]: data?.phone,
        ["role"]: data?.roles[0]?.name,
        ["address"]: data?.address,
      });
      setProfilePicId(data?.fileId);
      setProfilePic(data?.file?.url);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };
  const updateSalesDetails = async (values: any) => {
    const id = values.id;
    const params = {
      name: values.name,
      phone: values.phone,
      fileId: profilePicId,
    };
    try {
      await API_SERVICE.adminProfileUpdate(id, params);
      notification.success({
        message: "Updated Successfully",
        placement: "bottomRight",
      });
      getUserDetails();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    if (type) {
      setEdit(true);
    }
    getUserDetails();
  }, []);
  return (
    <>
      <Layout className="products-bg-color">
        <Content className="contentPadding">
          <div className="sales-profile-content">
            <Row
              className="sales-profile-row"
              align="top"
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            >
              <Col xs={0} sm={0} md={8} lg={8}></Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <h1 className="page-title text-center sales-profile-main-title ">
                  Profile
                </h1>
              </Col>
              <Col className="btn-col" xs={24} sm={24} md={8} lg={8}>
                <Button
                  className="sales-profile-btn"
                  onClick={() => {
                    setEdit(!edit);
                  }}
                >
                  <img src={lockIcon} alt="" />
                  Edit Profile
                </Button>
                <Button
                  className="sales-profile-btn"
                  onClick={() => {
                    setEdit(true);
                    history.push(`${SALES_RESET_PASSWORD}`);
                  }}
                >
                  <img src={lockIcon} alt="" />
                  Change Password
                </Button>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} className="sales-profile-container">
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={updateSalesDetails}
                >
                  <Row
                    className="sales-profile-form"
                    gutter={{
                      xs: 8,
                      sm: 16,
                      md: 24,
                      lg: 24,
                      xl: 24,
                    }}
                  >
                    <Col xs={24}>
                      <Form.Item
                        className="modalLabels"
                        name="profilePic"
                        style={{ textAlign: "center", margin: "20px 0px" }}
                      >
                        <img
                          src={profilePic || MilanoThumbnail}
                          alt="sales-pic"
                          className="sales-pic"
                        />
                        <FileUpload
                          showList="false"
                          type={"PROFILE_PIC"}
                          callback={profileUpdate}
                          style={{ display: "block" }}
                          onChange={(id: any) => {
                            setProfilePicId(id);
                          }}
                        >
                          {edit ? <p className="dealer-pic-text">Edit</p> : ""}
                        </FileUpload>
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        className="modalLabels"
                        label="Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Enter Name",
                          },
                        ]}
                      >
                        <Input
                          className="modalInputs"
                          prefix={<img src={userPic} alt="" />}
                          placeholder="Name"
                          disabled={!edit}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        className="modalLabels"
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Enter email",
                          },
                        ]}
                      >
                        <Input
                          className="modalInputs"
                          prefix={<img src={mailIcon} alt="" />}
                          placeholder="Email"
                          type="email"
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        className="modalLabels"
                        label="Role"
                        name="role"
                      >
                        <Input
                          className="modalInputs"
                          prefix={<img src={userPic} alt=""/>}
                          placeholder="Role"
                          disabled={true}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                      <Form.Item
                        className="modalLabels"
                        label="Mobile Number"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Enter Mobile Number",
                          },
                          {
                            min: 10,
                            message: "Phone Number must be atleast 10 digits",
                          },
                          {
                            max: 10,
                            message: "Phone Number must be atleast 10 digits",
                          },
                        ]}
                      >
                        <Input
                          className="modalInputs"
                          prefix={<img src={smartphone} alt="" />}
                          placeholder="Mobile Number"
                          disabled={!edit}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}></Col>

                    {edit ? (
                      <Col
                        xs={24}
                        style={{
                          marginTop: "40px",
                        }}
                      >
                        <Form.Item>
                          <div className="btn-center">
                            <Button
                              htmlType="submit"
                              className="theme-btn addAddressBtn save-btn"
                            >
                              Save
                            </Button>
                          </div>
                        </Form.Item>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </>
  );
}
