import {
  Col,
  DatePicker,
  MenuProps,
  notification,
  Row,
  Select,
  TreeSelect,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./CustomNotification.scss";

import { ArrowLeftOutlined } from "@ant-design/icons";
import globe from "../../../assets/icons/globe.png";

import { Button, Form, Input, Layout } from "antd";
import API_SERVICE from "services/api-service";

import TextArea from "antd/lib/input/TextArea";
import FileUpload from "components/FileUpload";
import { CloudDownload } from "@material-ui/icons";
import { CUSTOM_NOTIFICATION } from "shared/constants/RouteConstants";
import moment from "moment";
const { Header, Sider, Content } = Layout;

const NewNotification = () => {
  const { id } = useParams();

  const [selectType, setSelectType] = useState("");
  const [categoriesData, setCategoriesData] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [dealersList, setDealersList] = useState([]);
  const [dealerIds, setDealerIds] = useState([]);
  const [imageId, setImageId] = useState(null);
  const [notificationImageFile, setNotificationImageFile] = useState({
    name: "",
    url: "",
  });

  let history = useHistory();

  const [form] = Form.useForm();

  const typeSelectData = [
    {
      key: "0",
      name: "Category",
      value: "CATEGORY",
    },
    {
      key: "1",
      name: "Product",
      value: "PRODUCT",
    },
    {
      key: "2",
      name: "Custom",
      value: "CUSTOM",
    },
  ];
  const fetchCategoriesData = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchCategories({ page: -1 });
      if (data.items !== 0) {
        setCategoriesData(data.items);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
      });
    }
  };

  const fetchAllProducts = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchCategoriesProducts({ page: -1 });
      if (data.items !== 0) {
        setProductsList(data.items);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
      });
    }
  };

  const onSelectTypeChange = (value: string) => {
    switch (value) {
      case "CATEGORY":
        setSelectType(value);
        fetchCategoriesData();
        form.setFieldsValue({
          typeId: null,
        });
        return;
      case "PRODUCT":
        setSelectType(value);
        fetchAllProducts();
        form.setFieldsValue({
          typeId: null,
        });
        return;
      case "CUSTOM":
        setSelectType(value);
        return;
    }
  };

  const _handleSubmit = async (data: any) => {
    try {
      const params = {
        title: data.title,
        notification_type: data.type,
        description: data.description,
        imageId: imageId,
        dealerId: dealerIds,
      };
      if (data.typeId) {
        //@ts-ignore
        params["typeId"] = data.typeId;
      }
      if (data.customUrl) {
        //@ts-ignore
        params["customUrl"] = data.customUrl;
      }
      if (data.scheduledTime) {
        //@ts-ignore
        params["scheduledTime"] = moment(data.scheduledTime).format(
          "YYYY-MM-DD hh:mm:ss"
        );
      }

      await API_SERVICE.addCustomNotification(params);
      notification.success({
        message: "Custom Notification Added Successfully",
        placement: "bottomRight",
      });
      setImageId(null);
      form.resetFields();
      history.push(CUSTOM_NOTIFICATION);
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  const fetchSingleNotifications = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchSingleNotification(id);
      if (data) {
        setSelectType(data.type);
        onSelectTypeChange(data.type);
        if (data.customUrl) {
          form.setFieldsValue({
            customUrl: data.customUrl,
          });
        }
        if (data.scheduledTime) {
          //@ts-ignore
          form.setFieldsValue({
            scheduledTime: moment(data.scheduledTime),
          });
        }
        // if (data.typeId) {
        //   setId2(data.typeId);
        // }
        setNotificationImageFile({
          name: data.file.name,
          url: data.file.url,
        });
        setDealerIds(JSON.parse(data.dealerId || ["[]"]));
        setImageId(data.imageId);
        form.setFieldsValue({
          title: data.title,
          description: data.description,
          type: data.notificationType,
          displayOrder: data.displayOrder,
          status: data.status,
          typeId: data.typeId,
        });
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
      });
    }
  };

  const getDealerList = async () => {
    const params = {
      page: -1,
      isActive: 1,
    };
    const {
      data: { data },
    } = await API_SERVICE.fetchDealer(params);
    if (data.items) setDealersList(data.items);
  };
  const x = dealersList.map((item: any) => ({
    title: item.dealerName,
    value: item.dealerId,
  }));

  const allDealerIds = x.map(({ value }) => value);

  useEffect(() => {
    getDealerList();
    if (id) {
      fetchSingleNotifications();
    }
  }, []);

  return (
    <>
      <Content className="add-user-main-content">
        <div className="add-user-title"> SEND CUSTOM NOTIFICATION</div>
        <div className="add-user-icon">
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </div>
        <div className="add-user-form coupon-form">
          <Form
            form={form}
            labelCol={{
              xs: { span: 4 },
              sm: { span: 4 },
              md: { span: 8 },
              lg: { span: 6 },
              xl: { span: 4 },
            }}
            labelAlign="left"
            wrapperCol={{
              xs: { span: 2 },
              sm: { span: 2 },
              md: { span: 16 },
              lg: { span: 18 },
              xl: { span: 12 },
            }}
            layout="horizontal"
            disabled={id ? true : false}
            onFinish={_handleSubmit}
          >
            <Row>
              <Col xs={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Enter Title",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Title"
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Description",
                    },
                  ]}
                >
                  <TextArea
                    disabled={id ? true : false}
                    placeholder="Enter Description"
                    className="address-text"
                    style={{ resize: "none" }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item
                  label="Select Type"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Select Type",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Select Type"
                    className="banner-form-select"
                    onChange={onSelectTypeChange}
                  >
                    {typeSelectData.map((t) => (
                      <Select.Option key={t.key} value={t.value}>
                        {t.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24}>
                {selectType === "CUSTOM" ? (
                  <Form.Item
                    label="Custom Url"
                    name="customUrl"
                    rules={[
                      {
                        required: true,
                        message: "Enter Url Please",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Custom Url"
                      className="banner-form-input"
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="Select Id"
                    name="typeId"
                    rules={[
                      {
                        required: true,
                        message: "Select Id",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option?.children[1]
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      placeholder="Search to Select Id"
                      className="banner-form-select"
                      // onChange={(d) => console.log(d)}
                    >
                      {selectType === "CATEGORY"
                        ? categoriesData.map((cat: any) => {
                            return (
                              <Select.Option value={cat.id}>
                                <img
                                  src={cat?.file?.url}
                                  className="banner-form-select-icon"
                                  alt="icon"
                                />
                                {cat.name}
                              </Select.Option>
                            );
                          })
                        : productsList.map((prod: any) => {
                            return (
                              <Select.Option value={prod.id}>
                                <img
                                  src={prod?.thumbnail?.url}
                                  className="banner-form-select-icon"
                                  alt="icon"
                                />
                                {prod.name}
                              </Select.Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col xs={24}>
                <Form.Item label="Select Image" name="file1">
                  <FileUpload
                    disabled={id ? true : false}
                    type={"BANNER"}
                    onChange={(id: any) => {
                      setImageId(id);
                    }}
                  >
                    {notificationImageFile.url !== "" ? (
                      <div className="uploadedImage">
                        <img
                          src={notificationImageFile.url}
                          alt={notificationImageFile.name}
                        />
                        <Button>Edit</Button>
                      </div>
                    ) : (
                      <>
                        <div className="fileUploadDesign">
                          <div>
                            <img src={globe} alt="icon" />
                            Upload Image
                          </div>
                          <p className="text">Upload</p>
                        </div>
                        <p
                          style={{
                            color: "#A7A7A7",
                            fontSize: "14px",
                          }}
                        >
                          Upload file format should be in JPG or PNG and the
                          maximum size of file must be less then 3 MB.
                        </p>
                      </>
                    )}
                  </FileUpload>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item label="Select Dealers">
                  <TreeSelect
                    className="inputFields text-center buyxgetyselect"
                    allowClear={true}
                    placeholder="Select Dealers"
                    treeCheckable={true}
                    showCheckedStrategy={TreeSelect.SHOW_CHILD}
                    dropdownStyle={{ maxHeight: "300px" }}
                    onChange={(ids) => setDealerIds(ids)}
                    value={dealerIds}
                    maxTagCount={"responsive"}
                    maxTagPlaceholder={(omittedValues) =>
                      `+ ${omittedValues.length} Products ...`
                    }
                    treeData={[
                      {
                        title:
                          dealerIds.length > 0 ? (
                            <span
                              onClick={() => setDealerIds([])}
                              style={{
                                display: "inline-block",
                                color: "#286FBE",
                                cursor: "pointer",
                              }}
                            >
                              Unselect all
                            </span>
                          ) : (
                            <span
                              onClick={() => setDealerIds(allDealerIds)}
                              style={{
                                display: "inline-block",
                                color: "#286FBE",
                                cursor: "pointer",
                              }}
                            >
                              Select all
                            </span>
                          ),
                        value: "xxx",
                        disabled: true,
                        checkable: false,
                      },
                      ...x,
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item label="Schedule Time" name="scheduledTime">
                  <DatePicker
                    showTime
                    disabledDate={(current) => {
                      return (
                        moment().add(-1, "days") >= current ||
                        moment().add(1, "month") <= current
                      );
                    }}
                    placeholder="Schedule Time"
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  wrapperCol={
                    ({ sm: { offset: 4 } },
                    { md: { offset: 8 } },
                    { lg: { offset: 6 } },
                    { xl: { offset: 4 } })
                  }
                >
                  {!id && (
                    <Button
                      htmlType="submit"
                      className="send-notification-btn new-offer-btn"
                    >
                      Send
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </>
  );
};

export default NewNotification;
