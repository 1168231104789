import "./index.scss";
import {
  Breadcrumb,
  Col,
  Layout,
  Modal,
  notification,
  Pagination,
  Row,
  Tabs,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { HOME } from "shared/constants/RouteConstants";
import { Link } from "react-router-dom";
import WishlistCard from "components/WishlistCard/WishlistCard";
import { SearchOutlined } from "@material-ui/icons";
import { useState, useEffect, useContext, useRef } from "react";
import API_SERVICE from "services/api-service";
import CartContext from "context/Cart";
import { ExclamationCircleOutlined } from "@ant-design/icons";
export default function Wishlist(props: any) {
  const pageTopRef = useRef(null);

  const [searchInput, setSearchInput] = useState("");

  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    perpage: 10,
  });
  const [total, setTotal] = useState();
  const [currency, setCurrency] = useState();
  const [wishlistCart, setWishlistCart] = useState({
    productsWishlisted: {
      items: [],
    },
    totalCbm: 0,
    totalValue: 0,
  });

  //fetching
  const fetchWishlistData = async (
    page?: number,
    perpage?: number,
    search?: string
  ) => {
    try {
      const params = { ...pagination };
      if (search) {
        //@ts-ignore
        params["q"] = search;
        params["page"] = 1;
      }
      if (page) {
        params["page"] = page;
      }
      if (perpage) {
        params["perpage"] = perpage;
      }
      const {
        data: { data },
      } = await API_SERVICE.fetchWishlist(params);
      setWishlistCart({ ...data });
      setPagination({
        page: data.productsWishlisted.page,
        perpage: data.productsWishlisted.perpage,
        pages: data.productsWishlisted.pages,
      });
      setTotal(data.productsWishlisted.total);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };
  const WishListDeleteAll = async () => {
    try {
      await API_SERVICE.WishListDeleteAll();
      setWishlistCart({
        productsWishlisted: {
          items: [],
        },
        totalCbm: 0,
        totalValue: 0,
      });
      notification.success({
        message: "Deleted Succesfully",
        placement: "bottomLeft",
      });
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  const getCurrency = () => {
    const user = JSON.parse(localStorage.getItem("user")!);
    setCurrency(user?.user.dealerDetails[0]?.currencyType);
  };
  useEffect(() => {
    fetchWishlistData();
    getCurrency();
  }, []);
  const confirm = () => {
    Modal.confirm({
      title: "Remove all products?",
      icon: <ExclamationCircleOutlined />,
      okText: "Remove",
      onOk: () => WishListDeleteAll(),
      cancelText: "Cancel",
    });
  };
  return (
    <Layout className="products-bg-color">
      <Content className="contentPadding">
        <Breadcrumb className="products-breadcrumb" separator=">">
          <Breadcrumb.Item>
            <Link to={HOME}>Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="products-breadcrumb-active">
            Wishlist
          </Breadcrumb.Item>
        </Breadcrumb>

        <Layout className="product-main-content-layout">
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            style={{ justifyContent: "center" }}
          >
            <Col xs={24} className="dealer-profile-container">
              <h1 className="page-title text-center">Wishlist</h1>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  fetchWishlistData(1, pagination.perpage, searchInput);
                }}
              >
                <span className="wishlist-search">
                  <SearchOutlined className="icon" />
                  <input
                    placeholder="Search Products"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      e.target.value === ""
                        ? fetchWishlistData()
                        : setSearchInput(e.target.value);
                    }}
                  />
                </span>
              </form>
            </Col>
            <Col xs={24} sm={24} md={24} className="wishlist-topbar mt-3">
              <p className="total-price">
                Total{" "}
                <span>
                  {currency === "USD" ? "$" : "AED"} {wishlistCart?.totalValue}
                </span>
              </p>
              <p className="total-cbm">
                Total CBM<span>{wishlistCart.totalCbm}</span>
              </p>
              <p className="total-cbm">
                Total Items
                <span>{wishlistCart?.productsWishlisted?.items.length}</span>
              </p>
              <p className="clear-all" onClick={confirm}>
                Clear all
              </p>
            </Col>
            <Col xs={24} sm={24} md={24} style={{ minHeight: "60vh" }}>
              {wishlistCart.productsWishlisted.items.length === 0 ? (
                <p className="no-prod">No Product Added to WishList </p>
              ) : (
                <>
                  <div className="wishlistCardContainer">
                    {wishlistCart.productsWishlisted.items.map(
                      (product: any) => {
                        return (
                          <WishlistCard
                            setPagination={setPagination}
                            setTotal={setTotal}
                            pagination={pagination}
                            key={product.id}
                            wishlistCart={wishlistCart}
                            setWishlistCart={setWishlistCart}
                            product={product}
                          />
                        );
                      }
                    )}
                  </div>
                </>
              )}
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              style={{ display: "grid", placeItems: "center" }}
            >
              <Pagination
                responsive
                className="pagination"
                current={pagination.page}
                defaultCurrent={pagination.page || 1}
                total={total}
                pageSize={pagination.perpage}
                onChange={(page, size) => {
                  //@ts-ignore
                  setPagination({ page: page, perpage: size });
                  fetchWishlistData(page, size);
                  //@ts-ignore
                  pageTopRef.current.scrollIntoView();
                }}
              />
            </Col>
          </Row>
        </Layout>
      </Content>
    </Layout>
  );
}
