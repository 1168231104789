import { notification } from "antd";
import { createContext, useState } from "react";
import API_SERVICE from "services/api-service";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartData, setCartData] = useState({});
  const [cartValue, setCartValue] = useState(0);
  const [cartProducts, setCartProducts] = useState([]);

  //

  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    perpage: 10,
  });
  const [total, setTotal] = useState(10);

  const createOrder = async (obj) => {
    return await API_SERVICE.createOrder({ ...obj, cartId: cartData.id });
  };
  const fetchCartDetails = async (
    page?: number,
    perpage?: number,
    search?: string
  ) => {
    const params = { ...pagination };
    if (search) {
      params["q"] = search;
      params["page"] = 1;
    }
    if (page) {
      params["page"] = page;
    }
    if (perpage) {
      params["perpage"] = perpage;
    }
    try {
      const {
        data: { data },
      } = await API_SERVICE.getCartDetails(params);

      if (data.total !== 0) {
        setCartValue(data.products.total);
        setCartProducts(data.products.items);
        setCartData(data.cart);
        setPagination({
          page: data.products.page,
          perpage: data.products.perpage,
          pages: data.products.pages,
        });
        setTotal(data.products.total);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };

  return (
    <CartContext.Provider
      value={{
        cartValue,
        cartData,
        pagination,
        setPagination,
        total,
        setTotal,
        cartProducts,
        fetchCartDetails,
        createOrder,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
