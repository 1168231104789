import { LocalMall } from "@material-ui/icons";
import { Breadcrumb, Button, Col, Divider, Layout, Result, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NEW_ORDER, SALES_DASHBOARD } from "shared/constants/RouteConstants";

import { consverPriceToInternationalFormat } from "services/util";
import "./index.scss";
import SalesCartContext from "context/SaleCart";

export default function ThankyouPage(props: any) {
  const { setCartValues } = useContext(SalesCartContext);

  const [order, setOrder] = useState({
    shippingCharge: 0,
    totalAmount: 0,
    totalCbm: 0,
    deliverySlot: "",
    shippingAddress: {
      street: null,
      country: null,
      address: null,
      building: null,
      area: null,
      city: null,
      phone: null,
      name: null,
    },
    orderNumber: null,
  });
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("orderdata") as string);

    const {
      shippingCharge,
      totalAmount,
      totalCbm,
      shippingAddress,
      orderNumber,
      deliverySlot,
    } = data;
    setOrder({
      // @ts-ignore
      shippingCharge,
      totalAmount,
      totalCbm,
      shippingAddress,
      orderNumber,
      deliverySlot,
    });

    return () => {
      localStorage.removeItem("orderdata");
      setCartValues({
        cartId: null,
        totalAmount: null,
        totalCbm: null,
        totalItems: null,
      });
    };
  }, []);
  const prepareDeliverySlot = (t: any) => {
    if (t === "Morning") return "Morning (8AM TO 12 PM)";
    else if (t === "Afternoon") return "Afternoon (12 PM TO 4PM)";
    else return "Evening (4PM TO 8PM)";
  };
  return (
    <Layout className="products-bg-color">
      <Content className="contentPadding">
        <Breadcrumb
          className="products-breadcrumb"
          style={{
            margin: "16px 0",
          }}
          separator=">"
        >
          <Breadcrumb.Item>
            <Link to={SALES_DASHBOARD}>Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="products-breadcrumb-active">
            Checkout
          </Breadcrumb.Item>
        </Breadcrumb>

        <Layout
          className="product-main-content-layout"
          style={{ paddingTop: "0px" }}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} className="confirmation-container">
              <Result
                status="success"
                title="Thank you"
                subTitle={
                  <>
                    <p>Your order has been processed.</p>

                    <p>Your order number is {order?.orderNumber}</p>
                  </>
                }
                extra={[
                  <Link to={NEW_ORDER}>
                    <Button className="theme-btn1">
                      <LocalMall className="icon" />
                      Continue Shopping
                    </Button>
                  </Link>,
                ]}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={14}>
              <div className="summary-details">
                <h6 className="title">Order Summary</h6>
                <div className="details">
                  <p className="price">
                    <b> Price </b>
                  </p>
                  <p className="price">
                    <b>
                      AED {consverPriceToInternationalFormat(order.totalAmount)}
                    </b>
                  </p>
                </div>
                <div className="details details1"></div>
                <div className="details details1">
                  <p>Total CBM</p>
                  <p>{order.totalCbm}</p>
                </div>

                <Divider className="divider" />
                <div className="details details1 details2">
                  <p>Total Price</p>
                  <p className="totalPrice">
                    AED {consverPriceToInternationalFormat(order.totalAmount)}
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={10}>
              <div className="summary-details delivery-details">
                <h6 className="title">Deliver to</h6>
                <p className="name">{order?.shippingAddress?.name}</p>
                <p className="address">
                  {order?.shippingAddress?.building},{" "}
                  {order?.shippingAddress?.address},{" "}
                  {order?.shippingAddress?.street},
                  {order?.shippingAddress?.area}, {order?.shippingAddress?.city}
                  , {order?.shippingAddress?.country}
                </p>
                <p className="phone">
                  Phone Number: {order?.shippingAddress?.phone}
                </p>
                <p className="time">
                  {order.deliverySlot &&
                    prepareDeliverySlot(order.deliverySlot)}
                </p>
              </div>
            </Col>
          </Row>
        </Layout>
      </Content>
    </Layout>
  );
}
