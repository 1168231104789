//@ts-nocheck
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
// //@ts-ignore
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// //@ts-ignore
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import "./EditProduct.scss";
import type { MenuProps } from "antd";
import { Option } from "antd/lib/mentions";
import { useEffect } from "react";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  UploadOutlined,
  SettingOutlined,
  HomeOutlined,
  TagOutlined,
  CodeSandboxOutlined,
  UnorderedListOutlined,
  ArrowLeftOutlined,
  FileTextOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { notification } from "antd";
import {
  Checkbox,
  Layout,
  Menu,
  Row,
  Col,
  Input,
  Button,
  Form,
  Select,
} from "antd";
import { Switch } from "antd";

import AdminListLayout from "shared/components/layout/AdminListLayout/AdminListLayout";
import { width } from "@material-ui/system";
import API_SERVICE from "../../../services/api-service";
import Item from "antd/lib/list/Item";
import FileUpload from "components/FileUpload";
import { DeleteOutline } from "@material-ui/icons";
import { useWindowScroll } from "react-use";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export default function EditProduct(props: any) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  let history = useHistory();
  let selectedVisibility = [];
  const [defaultVisibility, setDefaultVisibility] = useState<
    string[] | undefined
  >();
  const visibilityList = [
    { value: "1", name: "Search" },
    { value: "2", name: "Catalogue" },
  ];
  const [data, setData] = useState({});
  const [featured, setFeatured] = useState(false);
  const [newArrival, setNewArrival] = useState(false);
  const [topSelling, setTopSelling] = useState(false);
  const [thumbnail, setThumbnail] = useState();
  const [thumbnailId, setThumbnailId] = useState();
  const [description, setDescription] = useState();
  const [descriptionId, setDescriptionId] = useState();
  const [active, setActive] = useState(false);
  const thumbnailUpdate = ({ id, url }) => {
    setThumbnail(url);
  };
  const descriptionUpdate = ({ id, url }) => {
    setDescription(url);
    setIsDisabled(false);
  };
  const [image, setImage] = useState([]);
  const [imageId, setImageId] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const imageUpdate = ({ id, url }) => {
    setImageId((current) => [...current, id]);
    setImage((current) => [...current, url]);
  };
  const [isDisabled, setIsDisabled] = useState(false);
  const [fullDescription, setFullDescription] = useState("");
  const [isFileVisible, setIsFileVisible] = useState(true);
  const fillForm = async (id: number) => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.getProductById(id);
      for (let i = 0; i < data.productImages?.length; i++) {
        imageUpdate({
          id: data.productImages[i].file.id,
          url: data.productImages[i].file.url,
        });
      }
      setProductImages(data.productImages);
      if (data.isActive === true) {
        setActive(true);
      }
      let tempVisibilityArr = [];

      if (data.isSearchable) {
        tempVisibilityArr.push("1");
      }

      if (data.isCatalogue) {
        tempVisibilityArr.push("2");
      }

      setData(data);

      if (data?.isFeatured) {
        setFeatured(true);
      }
      // if (data?.isNewArrival) {
      //   setNewArrival(true);
      // }
      if (data?.isPopular) {
        setTopSelling(true);
      }

      // setDefaultVisibility(visiblity);
      if (data?.fullDescription) {
        setFullDescription(data.fullDescription);
      }

      form.setFieldsValue({
        skuId: data?.skuId,
        productTitle: data?.name,
        brand: data?.brandId,
        division: data?.divisionId,
        price: data?.price,
        stock: data?.stock,
        dimension: data?.dimension,
        // boxQuantity: data?.boxQuantity,
        cartonQuantity: data?.cartoonQuantity,
        cbmValue: data?.cbmValue,
        origin: data?.origin,
        installationType: data?.installationType,
        category: data?.category?.category?.name,
        subCategory: data?.category?.name,
        color: data?.color,
        familyName: data?.familyName,
        warranty: data?.warranty,
        netWeight: data?.netWeight,
        grossWeight: data?.grossWeight,
        size: data?.size,
        description: data?.description,
        visibilityType: tempVisibilityArr,
      });
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };
  const onChangeActive = (e: any) => {
    setActive(e);
  };

  const updateProduct = async () => {
    try {
      setIsFileVisible(false);
      const val = await form.getFieldsValue(true);
      let isFeatured = 0;
      let isNewArrival = 0;
      let isTopSelling = 0;
      let isSearchable = 0;
      let isCatalogue = 0;
      let isActive = 0;
      if (val.visibilityType.includes("1")) {
        isSearchable = 1;
      } else {
        isSearchable = 0;
      }
      if (val.visibilityType.includes("2")) {
        isCatalogue = 1;
      } else {
        isCatalogue = 0;
      }

      featured ? (isFeatured = 1) : (isFeatured = 0);
      // newArrival ? (isNewArrival = 1) : (isNewArrival = 0);
      topSelling ? (isTopSelling = 1) : (isTopSelling = 0);
      active ? (isActive = 1) : (isActive = 0);

      const params = {
        division: val?.divisionId,
        dimension: val?.dimension,
        boxQuantity: val?.boxQuantity,
        cartoonQuantity: val?.cartonQuantity,
        cbmValue: val?.cbmValue,
        origin: val?.origin,
        installationType: val?.installationType,
        color: val?.color,
        familyName: val?.familyName,
        warranty: val?.warranty,
        netWeight: val?.netWeight,
        grossWeight: val?.grossWeight,
        size: val?.size,
        description: val?.description,
        isCatalogue: isCatalogue,
        isSearchable: isSearchable,
        isFeatured: isFeatured,
        isPopular: isTopSelling,
        // isNewArrival: isNewArrival,
        thumbnailId: thumbnailId,
        productImages: imageId,
        isActive: isActive,
        fullDescription: fullDescription,
        description_id: descriptionId,
      };

      console.log(params);
      await API_SERVICE.updateProduct(id, params);

      notification.success({
        message: "Updated Successfully",
        placement: "bottomRight",
      });
      setImageId([]);
      fillForm(id);
      setDescriptionId(null);
      setDescription(null);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const deleteProductImg = (id: number, index: number) => {
    let _productImages = productImages;
    _productImages.splice(index, 1);
    setProductImages(_productImages);
    imageId.splice(index, 1);
    updateProduct();
  };

  const handleFeature = (e: CheckboxChangeEvent) => {
    setData((prev: any) => {
      prev.isFeatured = !prev.isFeatured;
      return prev;
    });
    setFeatured(e.target.checked);
  };
  const handleNewArrival = (e: CheckboxChangeEvent) => {
    setData((prev: any) => {
      prev.isNewArrival = !prev.isNewArrival;
      return prev;
    });
    setNewArrival(e.target.checked);
  };
  const handleTopSelling = async (e: CheckboxChangeEvent) => {
    setData((prev: any) => {
      prev.isTopSelling = !prev.isTopSelling;
      return prev;
    });
    setTopSelling(e.target.checked);
  };

  useEffect(() => {
    fillForm(id);
  }, []);

  if (data) {
    return (
      <div className="edit-product-main-content">
        <div className="main-title">PRODUCTS</div>
        <div className="edit-product-icon">
          {" "}
          <ArrowLeftOutlined onClick={() => history.goBack()} />
        </div>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24, xl: 24 }} align="middle">
          <Col xs={24} sm={24} md={12} lg={15} xl={15}>
            <div className="edit-product-title">
              Edit Product <EditOutlined />
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={9}
            xl={9}
            className="editProductRight"
          >
            <div className="edit-product-title">Status</div>
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              checked={active}
              onChange={onChangeActive}
            />
            <Button
              type="primary"
              htmlType="submit"
              className="product-btn"
              form={form}
              onClick={updateProduct}
              disabled={isDisabled}
            >
              Save Product
            </Button>
          </Col>
        </Row>
        <div className="dealer-detail-title edit-product-title mb-2">
          Basic Details
        </div>
        <div className="edit-product-form">
          <Form form={form} onFinish={() => updateProduct()} layout="vertical">
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="SKU ID" name="skuId">
                  <Input
                    prefix={<FileTextOutlined />}
                    placeholder="SKU"
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Product Title" name="productTitle">
                  <Input disabled={true} prefix={<CodeSandboxOutlined />} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Brand" name="brand">
                  <Input disabled={true} prefix={<UnorderedListOutlined />} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Division" name="division">
                  <Input disabled={true} prefix={<UnorderedListOutlined />} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Price" name="price">
                  <Input
                    disabled={true}
                    prefix={<TagOutlined />}
                    placeholder="SKU"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Stock" name="stock">
                  <Input disabled={true} prefix={<CodeSandboxOutlined />} />
                </Form.Item>
              </Col>

              {/* <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Box Quantity" name="boxQuantity">
                  <Input
                    prefix={<CodeSandboxOutlined />}
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col> */}
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Carton Quantity" name="cartonQuantity">
                  <Input
                    prefix={<CodeSandboxOutlined />}
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className=" edit-product-title">Details</div>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="CBM Value" name="cbmValue">
                  <Input
                    prefix={<FileTextOutlined />}
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Country of Origin" name="origin">
                  <Input
                    prefix={<HomeOutlined />}
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Installation Type" name="installationType">
                  <Input
                    prefix={<SettingOutlined />}
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Category" name="category">
                  <Input prefix={<UnorderedListOutlined />} disabled={true} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Color" name="color">
                  <Input
                    prefix={<UnorderedListOutlined />}
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Family Name" name="familyName">
                  <Input
                    prefix={<UnorderedListOutlined />}
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Warranty" name="warranty">
                  <Input
                    prefix={<FileTextOutlined />}
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Sub Category" name="subCategory">
                  <Input prefix={<UnorderedListOutlined />} disabled={true} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Net Weight" name="netWeight">
                  <Input
                    prefix={<CodeSandboxOutlined />}
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Gross Weight" name="grossWeight">
                  <Input
                    prefix={<CodeSandboxOutlined />}
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Dimension" name="dimension">
                  <Input
                    prefix={<CodeSandboxOutlined />}
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item label="Size" name="size">
                  <Input
                    prefix={<CodeSandboxOutlined />}
                    className="banner-form-input"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={6}>
                <Form.Item onClick={() => setIsDisabled(true)}>
                  <FileUpload
                    type={"PRODUCT_DESCRIPTION"}
                    callback={descriptionUpdate}
                    style={{ display: "block" }}
                    onChange={(id: any) => {
                      setDescriptionId(id);
                    }}
                    showList={!isFileVisible}
                  >
                    <Button className="upload-form">
                      <UploadOutlined />
                      {data?.productDescription?.url
                        ? "Edit Product Description"
                        : "Upload Product Description"}
                    </Button>
                    <p className="dealer-pic-text"></p>
                  </FileUpload>
                </Form.Item>
                {data?.productDescription?.url ? (
                  <div className="upload-form-img">
                    <div
                      onClick={() =>
                        window.open(data.productDescription.url, "blank")
                      }
                      className="cursor-pointer"
                    >
                      <FileTextOutlined /> {data.productDescription.name}
                    </div>
                    <div>
                      <Button
                        shape="circle"
                        onClick={() => {
                          setDescriptionId(null);
                          data.productDescription.url = null;
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <div className=" edit-product-title">Overview</div>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item name="description">
                  <TextArea
                    rows={4}
                    className="address-text"
                    style={{ resize: "none" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className=" edit-product-title">Description</div>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <Col xs={24} sm={24} md={24} lg={24}>
                <ReactQuill
                  className="editor-quill"
                  theme="snow"
                  placeholder="Description"
                  value={fullDescription}
                  onChange={(value) => {
                    setFullDescription(value);
                  }}
                  modules={{
                    toolbar: {
                      container: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ["bold", "italic", "underline"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ align: [] }],
                        ["link", "image"],
                      ],
                    },
                  }}
                />
              </Col>
            </Row>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item>
                  <FileUpload
                    type={"PRODUCT_IMAGE"}
                    callback={thumbnailUpdate}
                    style={{ display: "block" }}
                    onChange={(id: any) => {
                      setThumbnailId(id);
                    }}
                  >
                    <Button className="upload-form">
                      <UploadOutlined />
                      Edit Thumbnail
                    </Button>
                    <p className="dealer-pic-text"></p>
                  </FileUpload>

                  {/* <Input
                    className="upload-form"
                    
                  /> */}
                </Form.Item>
                {data?.thumbnail?.url ? (
                  <div className="upload-form-img">
                    <div>
                      <img src={data.thumbnail.url} alt="" />
                    </div>
                    <div>
                      <Button
                        shape="circle"
                        onClick={() => {
                          setThumbnailId(null);
                          data.thumbnail.url = null;
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item>
                  <FileUpload
                    type={"PRODUCT_IMAGE"}
                    callback={imageUpdate}
                    style={{ display: "block" }}
                    onChange={(id: any) => {
                      // setImageId(current => [...current, id]);
                    }}
                  >
                    <Button className="upload-form">
                      <UploadOutlined />
                      Upload Images
                    </Button>
                    <p className="dealer-pic-text"></p>
                  </FileUpload>
                  {/* <Input
                    className="upload-form"
                    
                  /> */}
                </Form.Item>
                {productImages
                  ? productImages.map((item, index) => {
                      return (
                        <React.Fragment key={+new Date() + index}>
                          {item.file?.url ? (
                            <div className="upload-form-img">
                              <div>
                                <img src={item.file.url} alt="" />
                              </div>
                              <div>
                                <Button
                                  shape="circle"
                                  onClick={() => {
                                    deleteProductImg(item.id, index);
                                  }}
                                >
                                  <DeleteOutlined />
                                </Button>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      );
                    })
                  : ""}
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Form.Item
                  label={<div className=" edit-product-title">Visibility</div>}
                  name="visibilityType"
                >
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    options={visibilityList.map((item) => {
                      return {
                        value: item.value,
                        label: item.name,
                      };
                    })}
                  ></Select>
                </Form.Item>

                {/* {defaultVisibility && (
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    defaultValue={defaultVisibility}
                    onChange={onVisibilityChange}
                  >
                    {visibilityList.map((item) => {
                      return <Option value={item.value}>{item.name}</Option>;
                    })}
                  </Select>
                )} */}
              </Col>
            </Row>

            <div className=" edit-product-title">Product Type</div>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <Col xs={8} sm={8} md={6} lg={4}>
                <Checkbox onChange={handleFeature} checked={featured}>
                  Featured
                </Checkbox>
              </Col>
              {/* <Col xs={8} sm={8} md={6} lg={5}>
                <Checkbox onChange={handleNewArrival} checked={newArrival}>
                  New Arrival
                </Checkbox>
              </Col> */}
              <Col xs={8} sm={8} md={6} lg={5}>
                <Checkbox onChange={handleTopSelling} checked={topSelling}>
                  Popular
                </Checkbox>
              </Col>
            </Row>
            <Button
              type="primary"
              htmlType="submit"
              className="product-btn edit-product-btn"
              disabled={isDisabled}
            >
              Save Product
            </Button>
          </Form>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
