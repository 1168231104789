import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Button,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  TreeSelect,
  notification,
} from "antd";
import { useHistory } from "react-router-dom";
import API_SERVICE from "services/api-service";
import MilanoThumbnail from "../../../assets/MilanoThumbnail.png";
import { DownOutlined } from "@ant-design/icons";

export default function CreateBuyGetOffer({
  onClose,
  isModalOpen,
  setIsModalOpen,
  getAllOffers,
  offerId,
}: any) {
  const [form] = Form.useForm();

  const [productsList, setProductsList] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);

  const history = useHistory();

  const fetchCategoriesData = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchCategories({ page: -1 });
      if (data.items !== 0) {
        setCategoriesData(
          data.items
            .filter((item: any) => item?.isActive === true)
            .sort((a: any, b: any) => {
              return a.order - b.order;
            })
        );
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  const getAllProducts = async () => {
    const params = {};

    if (categoryIds && categoryIds.length) {
      //@ts-ignore
      params["page"] = -1;
      //@ts-ignore
      params["categoryIds"] = categoryIds;
    }

    try {
      const {
        data: { data },
      } = await API_SERVICE.fetchCategoriesProducts(params);
      console.log(data);
      if (data.items !== 0) {
        setProductsList(data.items);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  function createFilterTree(data: any) {
    return data.map((item: any) => {
      return {
        title: item.name,
        key: item.id,
        value: item.id,
        children: item.children.length ? createFilterTree(item.children) : [],
      };
    });
  }

  const y = createFilterTree(categoriesData);

  const x =
    productsList.length !== 0
      ? productsList.map((item: any) => ({
          title: (
            <>
              <img
                src={item?.thumbnail?.url || MilanoThumbnail}
                className="banner-form-select-icon"
                alt="icon"
              />
              {item.name}
            </>
          ),
          searchKey: item.name,
          value: `${item.id}`,
        }))
      : [
          {
            title: "No Products in Selected Category",
            value: "null",
            checkable: false,
          },
        ];

  const allIds = y.map(({ value }) => value);
  const allProductIds = x.map(({ value }) => value);

  //create offer
  const createOffer = async (formValues: any) => {
    const reqObj = {
      offerId,
      productId: productIds,
      buyQty: formValues.valueX,
      getQty: formValues.valueY,
    };
    try {
      await API_SERVICE.createGetBuyOffers(reqObj);
      notification.success({
        message: "Offer created successfully",
        placement: "bottomRight",
      });
      setIsModalOpen(false);
      setCategoryIds([]);
      setProductIds([]);
      form.resetFields();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    } finally {
      setIsModalOpen(false);
      getAllOffers();
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [categoryIds]);

  useEffect(() => {
    fetchCategoriesData();
  }, []);

  return (
    <Modal
      title={<p className="stockLabelModalTitle">Add Items</p>}
      footer={null}
      className=" admin-buyxgety-modal"
      open={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
        setCategoryIds([]);
        setProductIds([]);
        form.resetFields();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => createOffer(values)}
      >
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 24,
            xl: 24,
          }}
        >
          <Col xs={24}>
            <div className="stockLabelActionContainer buyXGetYContainer">
              <Row className="header">
                <Col xs={8}>Select Categories</Col>
                <Col xs={8}>Select Products</Col>
                <Col xs={4}>Value X</Col>
                <Col xs={4}>Value Y</Col>
              </Row>

              <div className="labelRowCss">
                <Row className="row">
                  <Col xs={8} className="p-1">
                    <Form.Item className="m-0 w-100 buyxgetyTree">
                      <>
                        <TreeSelect
                          className="inputFields text-center buyxgetyselect"
                          allowClear={true}
                          treeNodeFilterProp="title"
                          placeholder="Select Categories"
                          treeCheckable={true}
                          showCheckedStrategy={TreeSelect.SHOW_PARENT}
                          dropdownStyle={{ maxHeight: "300px" }}
                          onChange={(ids) => setCategoryIds(ids)}
                          value={categoryIds}
                          maxTagCount={"responsive"}
                          maxTagPlaceholder={(omittedValues) =>
                            `+ ${omittedValues.length} Categories ...`
                          }
                          treeData={[
                            {
                              title:
                                categoryIds.length > 0 ? (
                                  <span
                                    onClick={() => setCategoryIds([])}
                                    style={{
                                      display: "inline-block",
                                      color: "#286FBE",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Unselect all
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => {
                                      setCategoryIds(allIds);
                                    }}
                                    style={{
                                      display: "inline-block",
                                      color: "#286FBE",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Select all
                                  </span>
                                ),
                              value: "xxx",
                              disabled: true,
                              checkable: false,
                            },
                            ...y,
                          ]}
                        />
                      </>
                    </Form.Item>
                  </Col>

                  <Col xs={8} className="p-1">
                    <Form.Item className="m-0 w-100">
                      <TreeSelect
                        className="inputFields text-center buyxgetyselect"
                        disabled={categoryIds.length === 0 ? true : false}
                        allowClear={true}
                        treeNodeFilterProp="searchKey"
                        placeholder="Select Products"
                        treeCheckable={true}
                        showCheckedStrategy={TreeSelect.SHOW_CHILD}
                        dropdownStyle={{ maxHeight: "300px" }}
                        onChange={(ids) => setProductIds(ids)}
                        value={productIds}
                        maxTagCount={"responsive"}
                        maxTagPlaceholder={(omittedValues) =>
                          `+ ${omittedValues.length} Products ...`
                        }
                        treeData={[
                          {
                            title:
                              productIds.length > 0 ? (
                                <span
                                  onClick={() => setProductIds([])}
                                  style={{
                                    display: "inline-block",
                                    color: "#286FBE",
                                    cursor: "pointer",
                                  }}
                                >
                                  Unselect all
                                </span>
                              ) : (
                                <span
                                  onClick={() => setProductIds(allProductIds)}
                                  style={{
                                    display: "inline-block",
                                    color: "#286FBE",
                                    cursor: "pointer",
                                  }}
                                >
                                  Select all
                                </span>
                              ),
                            value: "xxx",
                            disabled: true,
                            checkable: false,
                          },
                          ...x,
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={4} className="p-1">
                    <Form.Item
                      name={"valueX"}
                      className="m-0"
                      rules={[
                        {
                          required: true,
                          message: "Enter Value X",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        className="inputFields text-center"
                        placeholder="Enter Value"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={4} className="p-1">
                    <Form.Item
                      name={"valueY"}
                      className="m-0"
                      rules={[
                        {
                          required: true,
                          message: "Enter Value Y",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        className="inputFields text-center"
                        placeholder="Enter Value"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col xs={24} className="flex justify-content-center mt-3">
            <Form.Item className="stockLabelBtnContainer">
              <Button htmlType="submit" className="theme-btn   ">
                Add
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
