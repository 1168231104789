import { Close, InboxOutlined } from "@material-ui/icons";

import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Pagination,
  Radio,
  Row,
  Select,
  notification,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { useContext, useEffect, useRef, useState } from "react";
import globe from "../../../assets/icons/globe.png";
import home from "../../../assets/icons/home.png";
import mailIcon from "../../../assets/icons/mail.png";
import mapPin from "../../../assets/icons/map-pin.png";
import map from "../../../assets/icons/map.png";
import smartphone from "../../../assets/icons/smartphone.png";
import userPic from "../../../assets/icons/username.png";
import "./index.scss";

import { Link, useHistory } from "react-router-dom";

import SalesCheckoutCard from "components/SalesCheckoutCard/SalesCheckoutCard";
import SalesCartContext from "context/SaleCart";
import API_SERVICE from "services/api-service";
import { consverPriceToInternationalFormat } from "services/util";
import {
  DISCOUNT_TYPE_FIX,
  DISCOUNT_TYPE_PERCENTAGE,
  countryList,
} from "shared/constants/Constants";
import {
  NEW_ORDER,
  SALES_PENDING_CART,
  SALES_THANKYOU,
} from "shared/constants/RouteConstants";

interface Values {
  id: number;
  name: string;
  phone: number;
  address: string;
  building: string;
  street: string;
  area: string;
  zipcode: number;
  city: string;
  country: string;
}

export default function SalesCheckout(props: any) {
  const pageTopRefCheckout = useRef(null);

  const history = useHistory();
  const {
    dealerId,
    cartValues,
    cartProducts,
    pagination,
    total,
    setCartValues,
    setDealerId,
    setPagination,
    createOrder,
    currentDiscount,
    setCurrentDiscount,
    fetchCartDetails,
    discountDiv,
    setDiscountDiv,
  } = useContext(SalesCartContext);

  const [checkDiscount, setCheckDiscount] = useState(false);
  const [edit, setEdit] = useState(false);

  const [form] = Form.useForm();
  const [discountForm] = Form.useForm();
  const [address, setAddress] = useState([]);

  const [checkOutState, setCheckOutState] = useState({
    deliverySlot: "",
    billingAddressId: null,
    shippingAddressId: null,
    sameAsShipping: false,
    cartId: cartProducts[0]?.cartId,
    status: "NEW",
    placed: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const showModal = (value: string) => {
    setIsModalOpen(true);
    setModalTitle(value);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handlePlaceOrder = async (props: any) => {
    const { billingAddressId, shippingAddressId, placed } = checkOutState;
    if (!shippingAddressId) {
      notification.error({
        message: "Please Select Shipping Address",
        placement: "bottomRight",
      });
    } else if (!billingAddressId) {
      notification.error({
        message: "Please Select Billing Address",
        placement: "bottomRight",
      });
    } else {
      // new changes

      try {
        const {
          data: { data },
        } = await createOrder({
          ...checkOutState,
        });
        setCheckOutState({
          ...checkOutState,
          placed: !placed,
        });
        localStorage.setItem("orderdata", JSON.stringify(data));
        notification.success({
          message: "Order Placed Successfully",
          placement: "bottomRight",
        });
        history.push(SALES_THANKYOU);
        setDealerId(null);
        fetchCartDetails();
      } catch (error) {
        notification.error({
          message: API_SERVICE.handleErrors(error),
          placement: "bottomRight",
        });
      }
    }
  };

  //AddingAddress
  const addAddress = async (values: Values) => {
    const params = {
      userId: dealerId,
      name: values.name,
      phone: values.phone,
      address: values.address,
      building: values.building,
      street: values.street,
      area: values.area,
      zipcode: values.zipcode,
      city: values.city,
      country: values.country,
    };

    try {
      await API_SERVICE.addAddress({ ...params });
      notification.success({
        message: "Added Address Successfully",
        placement: "bottomRight",
      });
      form.resetFields();
      fetchAddress();
      setIsModalOpen(false);
      fetchAddress();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  //fetchAddress
  const fetchAddress = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.GetSalesDealerAddress({ dealerId: dealerId });
      if (data.items.length > 0) {
        setAddress(data.items);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  //editAddress
  const editAddress = async (addr: Values) => {
    const editParams: {} = {
      id: addr.id,
      name: addr.name,
      phone: addr.phone,
      address: addr.address,
      building: addr.building,
      street: addr.street,
      area: addr.area,
      zipcode: addr.zipcode,
      city: addr.city,
      country: addr.country,
    };
    setEdit(true);
    form.setFieldsValue({ ...editParams });
  };

  //updateAddress
  const updateAddress = async (values: Values) => {
    try {
      await API_SERVICE.editAddress({ ...values });
      notification.success({
        message: "Updated Address Successfully",
        placement: "bottomRight",
      });
      fetchAddress();

      setIsModalOpen(false);
      form.resetFields();
      setEdit(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const _handleDiscount = (values: any) => {
    let total = parseFloat(cartValues.totalAmount);
    if (values.discountType === DISCOUNT_TYPE_FIX) {
      if (values.discountAmount > total) {
        notification.error({
          message: `Discount Amount must be less than ${total}`,
        });
        discountForm.resetFields();
        return;
      }
      setCurrentDiscount({
        amount: values.discountAmount,
        type: values.discountType,
      });
      setCartValues({
        ...cartValues,
        totalAmount: (total - values.discountAmount).toFixed(2),
      });
    } else {
      if (values.discountAmount > 100) {
        notification.error({
          message: `Discount Percentage must be between 0 & 100`,
        });
        discountForm.resetFields();
        return;
      }
      setCurrentDiscount({
        amount: values.discountAmount,
        type: values.discountType,
      });
      setCartValues({
        ...cartValues,
        totalAmount: (total - (values.discountAmount / 100) * total).toFixed(2),
      });
    }
    setCheckDiscount(true);
  };

  useEffect(() => {
    discountForm.resetFields();
    setCheckDiscount(false);
  }, [discountDiv]);

  useEffect(() => {
    if (dealerId) {
      fetchCartDetails();
      fetchAddress();
    } else {
      history.push(SALES_PENDING_CART);
    }

    return () => {
      setCurrentDiscount({
        amount: null,
        type: null,
      });
      setDiscountDiv(false);
      discountForm.resetFields();
    };
  }, []);

  return (
    <Layout className="cart-bg-color">
      <Content className="contentPadding">
        <Breadcrumb
          className="products-breadcrumb"
          style={{
            margin: "16px 0",
          }}
          separator=">"
        >
          <Breadcrumb.Item>
            <Link to={NEW_ORDER}>New Order</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="products-breadcrumb-active">
            Checkout
          </Breadcrumb.Item>
        </Breadcrumb>

        <Layout className="cart-main-content-layout">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24}>
              <h1 className="page-title sales-page-title">Checkout</h1>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={12}
              ref={pageTopRefCheckout}
            >
              <div className="cart-left">
                {/* <div className="search-container">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      // fetchCartDetails(searchInput);
                      fetchCartDetails(1, pagination.perpage, searchInput);
                    }}
                  >
                    <SearchOutlined className="icon" />
                    <input
                      placeholder="Search"
                      style={{ width: "100%" }}
                      // onKeyDown={handleKeyDown}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <Button htmlType="submit" hidden={true}>
                      Search
                    </Button>
                  </form>
                </div> */}
                <div className="cart-item-container">
                  {cartProducts.length !== 0
                    ? cartProducts.map((prod: any) => (
                        <SalesCheckoutCard prod={prod} key={prod.productId} />
                      ))
                    : "No Products in Cart"}
                </div>
                <div className="sales-checkout-pagination">
                  <Pagination
                    responsive
                    className="pagination"
                    current={pagination.page}
                    defaultCurrent={pagination.page || 1}
                    total={total}
                    pageSize={pagination.perpage}
                    onChange={(page, size) => {
                      setPagination({ page: page, perpage: size });
                      fetchCartDetails(page, size);
                      //@ts-ignore
                      pageTopRefCheckout.current.scrollIntoView();
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <div className="cart-right">
                <div className="coupon-container summary-container sales-summary-container ">
                  <h6 className="title">Summary</h6>

                  <div className="details details1 details2 ">
                    <p>
                      <b>Total Price ({cartValues.totalItems} items)</b>
                    </p>
                    <p className="totalPrice">
                      AED{" "}
                      {currentDiscount.amount !== null
                        ? currentDiscount.type === DISCOUNT_TYPE_PERCENTAGE
                          ? consverPriceToInternationalFormat(
                              (
                                parseFloat(cartValues.totalAmount) +
                                (parseFloat(cartValues.totalAmount) *
                                  parseFloat(currentDiscount.amount)) /
                                  (100 - parseFloat(currentDiscount.amount))
                              ).toFixed(2)
                            )
                          : consverPriceToInternationalFormat(
                              (
                                parseFloat(cartValues.totalAmount) +
                                parseFloat(currentDiscount.amount)
                              ).toFixed(2)
                            )
                        : consverPriceToInternationalFormat(
                            parseFloat(cartValues.totalAmount).toFixed(2)
                          )}
                    </p>
                  </div>
                  <Divider dashed={true} className="divider" />
                  <div className="details details1 details2">
                    <p>
                      <b>Avaiable Credit Limit </b>
                    </p>
                    <p>
                      AED{" "}
                      {consverPriceToInternationalFormat(
                        cartValues?.availableCreditLimit
                      )}
                    </p>
                  </div>

                  {discountDiv && (
                    <>
                      <div className="details details1 details2 ">
                        <p>
                          <b>Discount </b>
                        </p>
                        <p className="totalPrice">
                          AED{" "}
                          {currentDiscount.type === DISCOUNT_TYPE_PERCENTAGE
                            ? (cartValues.totalAmount *
                                currentDiscount.amount) /
                              (100 - currentDiscount.amount)
                            : currentDiscount.amount || 0}
                        </p>
                      </div>
                      <div className="details details1 details2 ">
                        <p>
                          <b>Payable Amount </b>
                        </p>
                        <p className="totalPrice">
                          AED{" "}
                          {consverPriceToInternationalFormat(
                            cartValues.totalAmount
                          )}
                        </p>
                      </div>
                    </>
                  )}

                  <Divider dashed={true} className="divider" />
                  <div className="details details1">
                    <p>Total CBM</p>
                    <p>{cartValues.totalCbm}</p>
                  </div>
                </div>

                {/* {!discountDiv && (
                  <div
                    className="coupon-container justify-content-start add-discount-container"
                    style={{ borderColor: "#C71E21" }}
                    // onClick={() => setDiscountDiv(true)}
                  >
                    <span className="icon1">
                      <InboxOutlined />
                    </span>
                    <span>Add Discount</span>
                  </div>
                )} */}

                {discountDiv && (
                  <Form form={discountForm} onFinish={_handleDiscount}>
                    <div
                      className="coupon-container justify-content-start add-discount-container"
                      style={{ borderColor: "#C71E21" }}
                    >
                      <Close
                        className="add-discount-close"
                        onClick={() => {
                          discountForm.resetFields();
                          setCurrentDiscount({
                            amount: null,
                            type: null,
                          });
                          setCheckDiscount(false);
                          setDiscountDiv(false);
                        }}
                      />
                      <span className="icon1">
                        <InboxOutlined />
                      </span>
                      <span className="add-discount-percent">
                        <Form.Item
                          name="discountType"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Type!",
                            },
                          ]}
                        >
                          <Radio.Group disabled={checkDiscount ? true : false}>
                            <Radio value={"PERCENTAGE"}>Discount (%)</Radio>
                            <Radio value={"FIXED"}>Discount (Fix)</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </span>
                      <span className="add-discount-input">
                        <Form.Item
                          name="discountAmount"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Value!",
                            },
                            {
                              min: 0,
                              message: "Enter Valid Amount / Percentage",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            disabled={checkDiscount ? true : false}
                          />
                        </Form.Item>
                      </span>
                      <Button
                        disabled={checkDiscount ? true : false}
                        htmlType="submit"
                        className="add-discount-button"
                      >
                        Apply
                      </Button>
                    </div>
                  </Form>
                )}

                <div className="coupon-container summary-container shipping-container">
                  <div className="top">
                    <h6 className="title">Shipping Address</h6>
                    <Button
                      disabled
                      className="theme-btn1"
                      onClick={() => showModal("Shipping Address")}
                      style={{ background: "#EBEBEB" }}
                    >
                      Add Address
                    </Button>

                    <Modal
                      title={modalTitle}
                      open={isModalOpen}
                      onCancel={() => {
                        handleCancel();
                        setEdit(false);
                      }}
                      footer={null}
                      className="add-address-modal"
                    >
                      <Form
                        form={form}
                        layout="vertical"
                        onFinish={edit ? updateAddress : addAddress}
                      >
                        <Row
                          gutter={{
                            xs: 8,
                            sm: 16,
                            md: 24,
                            lg: 24,
                            xl: 24,
                          }}
                        >
                          {edit ? (
                            <Col xs={24} md={12} hidden={true}>
                              <Form.Item name="id">
                                <Input
                                  placeholder="Name"
                                  required={true}
                                  hidden={true}
                                />
                              </Form.Item>
                            </Col>
                          ) : (
                            ""
                          )}
                          <Col xs={24} md={12}>
                            <Form.Item
                              className="modalLabels"
                              label="Contact Person Name"
                              name="name"
                              rules={[
                                {
                                  required: true,
                                  message: "Enter Contact Person Name",
                                },
                                {
                                  pattern: /^[A-Za-z ]+$/,
                                  message:
                                    "Contact Person Name Cannot Be a number",
                                },
                              ]}
                            >
                              <Input
                                className="modalInputs"
                                prefix={<img src={userPic} alt="" />}
                                placeholder="Name"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.Item
                              className="modalLabels"
                              label="Mobile Number"
                              name="phone"
                              tooltip="Enter Valid Phone Number"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Valid Phone Number",
                                },
                                {
                                  min: 10,
                                  message: "Phone Number must be 10 Digits.",
                                },
                                {
                                  max: 10,
                                  message: "Phone Number must be 10 Digits.",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                maxLength={10}
                                className="modalInputs"
                                prefix={<img src={smartphone} alt="" />}
                                placeholder="Mobile Number"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              className="modalLabels"
                              label="Line Address"
                              name="address"
                              rules={[
                                {
                                  required: true,
                                  message: "Enter Address",
                                },
                              ]}
                            >
                              <Input
                                className="modalInputs"
                                prefix={<img src={mapPin} alt="" />}
                                placeholder="Line Address"
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} md={12}>
                            <Form.Item
                              className="modalLabels"
                              label="Building"
                              name="building"
                              rules={[
                                {
                                  required: true,
                                  message: "Building Details Required",
                                },
                              ]}
                            >
                              <Input
                                className="modalInputs"
                                prefix={<img src={home} alt="" />}
                                placeholder="Building"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.Item
                              label="Street"
                              name="street"
                              className="modalLabels"
                              rules={[
                                {
                                  required: true,
                                  message: "Street Required",
                                },
                              ]}
                            >
                              <Input
                                className="modalInputs"
                                prefix={<img src={map} alt="" />}
                                placeholder="Street"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.Item
                              label="Area"
                              name="area"
                              className="modalLabels"
                              rules={[
                                {
                                  required: true,
                                  message: "Area Details Required",
                                },
                              ]}
                            >
                              <Input
                                className="modalInputs"
                                prefix={<img src={mapPin} alt="" />}
                                placeholder="Area"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.Item
                              className="modalLabels"
                              label="PO Box/Zip Code"
                              name="zipcode"
                              rules={[
                                {
                                  required: true,
                                  message: "Zip Code / PO Box Required",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                className="modalInputs"
                                prefix={<img src={mailIcon} alt="" />}
                                placeholder="PO Box/Zip Code"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.Item
                              label="City"
                              name="city"
                              className="modalLabels"
                              rules={[
                                {
                                  required: true,
                                  message: "City Name Required ",
                                },
                              ]}
                            >
                              <Input
                                className="modalInputs"
                                prefix={<img src={mailIcon} alt="" />}
                                placeholder="City"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12}>
                            <Form.Item
                              label="Country"
                              name="country"
                              className="modalLabels modalLabelsSelect"
                              rules={[
                                {
                                  required: true,
                                  message: "Country Name Required ",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  //@ts-ignore
                                  option?.children[1]
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                suffixIcon={
                                  <img
                                    alt=""
                                    src={globe}
                                    // className="banner-form-select-icon"
                                    // alt="icon"
                                    // style={{
                                    //   height: "21px",
                                    //   width: '21px',
                                    //   objectFit: 'contain',

                                    // }}
                                  />
                                }
                                placeholder="Select Country"
                                className="banner-form-select"
                              >
                                {countryList.map((country) => {
                                  return (
                                    <Select.Option
                                      value={country}
                                      className="modalInputs"
                                    >
                                      <img
                                        src={globe}
                                        className="banner-form-select-icon"
                                        alt="icon"
                                        style={{
                                          height: "21px",
                                          width: "21px",
                                          objectFit: "contain",
                                        }}
                                      />
                                      {country}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            style={{
                              textAlign: "center",
                              marginTop: "40px",
                            }}
                          >
                            <Form.Item>
                              {edit ? (
                                <Button
                                  disabled
                                  htmlType="submit"
                                  className="theme-btn addAddressBtn"
                                >
                                  Update Address
                                </Button>
                              ) : (
                                <Button
                                  disabled
                                  htmlType="submit"
                                  className="theme-btn addAddressBtn"
                                >
                                  Add Address
                                </Button>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Modal>
                  </div>

                  <div
                    style={{
                      maxHeight: "270px",
                      overflowY: "auto",
                      width: "100%",
                    }}
                  >
                    {address.map((addr: any) => {
                      return (
                        <div className="address-row" key={`${addr.id}SA`}>
                          <input
                            disabled
                            type="radio"
                            // value="CSS"
                            name="shipping-address"
                            id={`${addr.id}SA`}
                            onChange={() =>
                              setCheckOutState({
                                ...checkOutState,
                                shippingAddressId: addr.id,
                              })
                            }
                          />

                          <label htmlFor={`${addr.id}SA`}>
                            <p className="name">
                              {addr.name}
                              <span>
                                <Button
                                  disabled
                                  className="theme-btn1"
                                  onClick={() => {
                                    showModal("Shipping Address");
                                    editAddress(addr);
                                  }}
                                >
                                  Edit
                                </Button>
                              </span>
                            </p>
                            <p className="address">
                              {addr.building},{addr.address},{addr.street},{" "}
                              {addr.area},{addr.city}, {addr.country}
                            </p>
                            <p className="phone">Phone Number: {addr.phone}</p>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="coupon-container summary-container billing-container">
                  <div className="top">
                    <h6 className="title">Billing Address</h6>
                    <Button
                      disabled
                      className="theme-btn1"
                      onClick={() => showModal("Billing Address")}
                    >
                      Add Address
                    </Button>
                  </div>
                  <div
                    style={{
                      maxHeight: "270px",
                      overflowY: "auto",
                      width: "100%",
                    }}
                  >
                    <div className="address-row">
                      <input
                        disabled
                        type="radio"
                        // value="address1"
                        name="billing-address"
                        id="sameasshippingadddr"
                        onChange={() =>
                          setCheckOutState({
                            ...checkOutState,
                            sameAsShipping: !checkOutState.sameAsShipping,
                            billingAddressId: checkOutState.shippingAddressId,
                          })
                        }
                      />
                      <label htmlFor="sameasshippingadddr">
                        <p className="name">Same as Shipping Address</p>
                      </label>
                    </div>

                    {address.map((addr: any) => {
                      return (
                        <div className="address-row" key={`${addr.id}BA`}>
                          <input
                            disabled
                            type="radio"
                            value="CSS"
                            id={`${addr.id}BA`}
                            name="billing-address"
                            onChange={() =>
                              setCheckOutState({
                                ...checkOutState,
                                billingAddressId: addr.id,
                              })
                            }
                          />
                          <label htmlFor={`${addr.id}BA`}>
                            <p className="name">
                              {addr.name}
                              <span>
                                <Button
                                  disabled
                                  className="theme-btn1"
                                  onClick={() => {
                                    showModal("Billing Address");
                                    editAddress(addr);
                                  }}
                                >
                                  Edit
                                </Button>
                              </span>
                            </p>
                            <p className="address">
                              {addr.building},{addr.address},{addr.street},{" "}
                              {addr.area},{addr.city}, {addr.country}
                            </p>
                            <p className="phone">Phone Number: {addr.phone}</p>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="coupon-container summary-container">
                  <h6 className="title">Preffered Time Slot</h6>
                  <div className="address-row">
                    <input
                      disabled
                      type="radio"
                      value="morning"
                      id="morning"
                      name="timeslot"
                      onChange={(e) => {
                        setCheckOutState({
                          ...checkOutState,
                          deliverySlot: "Morning",
                        });
                      }}
                    />
                    <label htmlFor="morning">
                      <p className="name">Morning (8AM TO 12 PM)</p>
                    </label>
                  </div>
                  <div className="address-row">
                    <input
                      disabled
                      type="radio"
                      value="afternoon"
                      id="afternoon"
                      onChange={(e) =>
                        setCheckOutState({
                          ...checkOutState,
                          deliverySlot: "Afternoon",
                        })
                      }
                      name="timeslot"
                    />
                    <label htmlFor="afternoon">
                      <p className="name">Afternoon (12 PM TO 4PM)</p>
                    </label>
                  </div>
                  <div className="address-row">
                    <input
                      disabled
                      type="radio"
                      id="evening"
                      onChange={(e) =>
                        setCheckOutState({
                          ...checkOutState,
                          deliverySlot: "Evening",
                        })
                      }
                      value="evening"
                      name="timeslot"
                    />
                    <label htmlFor="evening">
                      <p className="name">Evening ( 4PM TO 8PM)</p>
                    </label>
                  </div>
                </div>

                {/* <Button
                  // onClick={() => history.push(SALES_THANKYOU)}
                  className="theme-btn"
                  onClick={handlePlaceOrder}
                >
                  Place Order
                </Button> */}
              </div>
            </Col>
          </Row>
        </Layout>
      </Content>
    </Layout>
  );
}
