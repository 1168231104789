import * as React from "react";
import { ReactElement } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  SALES_DASHBOARD,
  SALES_ORDER_APPROVAL,
  SALES_CHECKOUT,
  SALES_DEALER_LIST,
  SALES_MANAGE_ORDERS,
  SALES_ORDER_DETAILS,
  SALES_THANKYOU,
  NEW_ORDER,
  SALES_PROFILE,
  SALES_RESET_PASSWORD,
  SALES_MAN_DEALER_LIST,
  SALES_PENDING_CART,
  SALES_PRODUCT_DETAIL,
  SALES_NOTIFICATION,
} from "shared/constants/RouteConstants";
import Page404 from "./Page404/Page404";
import SalesDashboard from "./Sales/SalesDashboard/SalesDashboard";
import SalesLayout from "shared/components/layout/SalesLayout";
import OrderApproval from "./Sales/OrderApproval/OrderApproval";
import SalesCheckout from "./Sales/SalesCheckout/SalesCheckout";
import ThankyouPage from "./Sales/ThankyouPage/ThankyouPage";
import ManageOrder from "./Sales/ManageOrder/ManageOrder";
import OrderDetails from "./Sales/OrderDetails/OrderDetails";
import DealerList from "./Sales/ManageDealer/DealerList";
import SalesProfile from "./Sales/SalesProfile/SalesProfile";
import NewOrder from "./Sales/NewOrder/NewOrder";
import SalesResetPassword from "./Sales/SalesProfile/SalesResetPassword";
import DealerListSalesMan from "./Sales/ManageDealer/DealerListSalesMan";
import SalesDealer from "./Sales/ManageDealer/SalesDealer";
import PendingCart from "./Sales/PendingCart/PendingCart";
import SalesProductDetail from "./Sales/SalesProductDetail/SalesProductDetail";
import SalesNotifications from "./Sales/SalesNotification/SalesNotification";

type Props = {};

const SalesRoutes: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const history = useHistory();

  const selectedMenu = pathname;

  function handleMenuClick(route: string) {
    if (route !== pathname) {
      history.push(route);
    }
  }

  const AppLayout = SalesLayout;

  React.useEffect(() => {
    document.title = "Milano Sales";
  }, []);

  return (
    <AppLayout selectedMenu={selectedMenu} onMenuClick={handleMenuClick}>
      <Switch>
        <Route exact path={SALES_DASHBOARD} component={SalesDashboard} />
        <Route exact path={SALES_ORDER_APPROVAL} component={OrderApproval} />
        <Route
          exact
          path={SALES_ORDER_APPROVAL + "/:filterValue"}
          component={OrderApproval}
        />

        <Route exact path={SALES_DEALER_LIST} component={SalesDealer} />
        <Route exact path={SALES_MANAGE_ORDERS} component={ManageOrder} />

        <Route
          exact
          path={SALES_ORDER_DETAILS + "/:id"}
          component={OrderDetails}
        />

        <Route exact path={SALES_CHECKOUT} component={SalesCheckout} />
        <Route exact path={SALES_THANKYOU} component={ThankyouPage} />

        <Route exact path={SALES_PROFILE} component={SalesProfile} />
        <Route exact path={SALES_PROFILE + "/:type"} component={SalesProfile} />
        <Route
          exact
          path={SALES_RESET_PASSWORD}
          component={SalesResetPassword}
        />
        {/* <Route exact path={NEW_ORDER} component={NewOrder} /> */}
        <Route exact path={SALES_PENDING_CART} component={PendingCart} />
        <Route
          exact
          path={SALES_PRODUCT_DETAIL + "/:id"}
          component={SalesProductDetail}
        />
        <Route exact path={SALES_NOTIFICATION} component={SalesNotifications} />
        <Route path="*" component={Page404} />
      </Switch>
    </AppLayout>
  );
};

export default SalesRoutes;
