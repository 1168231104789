import React, { useState } from "react";

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  notification,
  Space,
  Checkbox,
} from "antd";
import { CloseOutlined, ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FORGOT_PASSWORD } from "../../../shared/constants/RouteConstants";
import API_SERVICE from "client/src/services/api-service";
import lockIcon from "../../assets/icons/lockIcon.png";
import username from "../../assets/icons/username.png";
import eye from "../../assets/icons/eye.png";
// import ForgotPass from "../../view/forgotPass/forgotPass.tsx";

interface Props {
  onLogin: (email: string, password: string) => void;
  loading: boolean;
}

export default function LoginForm({ onLogin, loading }: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [recoveringPassword, setRecoveringPassword] = useState(false);

  function handleSubmit(values: any) {
    if (onLogin) {
      const { email, password } = values;
      onLogin(email, password);
    }
  }

  // const forgotPassword = () => {
  // //    <ForgotPass/>
  //     // <
  //     // setIsModalVisible(true);
  // };

  // const handleCancel = () => {
  //     setIsModalVisible(false);
  // };

  return (
    <>
      <Form
        className={"login-form"}
        form={form}
        layout="vertical"
        fields={[]}
        initialValues={{
          email: "",
          password: "",
        }}
        onFinish={handleSubmit}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 20 }}
        requiredMark={false}
      >
        <Form.Item
          className={"mb-3"}
          label={"Email / Dealer Code"}
          name="email"
          rules={[{ required: true, message: "Email / Dealer Code required" }]}
        >
          <Input
            autoComplete={"off"}
            size="large"
            placeholder="username/email"
            className="login-input"
            prefix={<img src={username} />}
          />
        </Form.Item>
        <Form.Item
          className={"mb-3"}
          label={"Password"}
          name="password"
          rules={[{ required: true, message: "Password required" }]}
        >
          <Input.Password
            autoComplete={"off"}
            size="large"
            placeholder="password"
            prefix={<img src={lockIcon} />}
            type="password"
            className="login-input"
            suffix={<img src={eye} />}
          />
          {/* <Input
                    
                        autoComplete={'off'}
                        size="large"
                        type='password'
                        placeholder="password"
                        prefix={<img src={lockIcon}/>}
                        suffix={<img src={eye}/>}
                    
                    /> */}
        </Form.Item>

        <Form.Item className="mb-3">
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={loading}
            className="theme-button primary reset-btn"
          >
            Sign in
          </Button>
        </Form.Item>

        <Row>
          <Col xs={12} lg={8} xl={15} xxl={11}>
            <div className="form-text">
              <small>
                <Checkbox onChange={onChange}>Remember Me</Checkbox>
              </small>
            </div>
          </Col>
          <Col xs={12} lg={6} xl={5} xxl={5} className="forgot-pass p-0 m-0">
            <small>
              <a
                className="text-primary"
                onClick={() => {
                  history.push(FORGOT_PASSWORD);
                }}
              >
                Forgot Password?
              </a>
            </small>
          </Col>
        </Row>
      </Form>

      {/* <Modal title="Forgot password? Recover Now."
                   footer={null}
                   visible={isModalVisible}
                   onCancel={handleCancel}
                   destroyOnClose={true}
            >
                <Form
                    className={'forgot-password-form'}
                    fields={[]}
                    onFinish={async (value: string) => {
                        try {
                            setRecoveringPassword(true);
                            // @ts-ignore
                            await API_SERVICE.forgotPassword(value.f_email);
                            notification.success({
                                message: 'An email with new password has been sent to you!',
                                 placement: "bottomRight"
                            });
                            handleCancel();
                        } catch (e) {
                            notification.error({
                                message: t(API_SERVICE.handleErrors(e)),
                                 placement: "bottomRight"
                            });
                        } finally {
                            setRecoveringPassword(false);
                        }
                    }}
                    requiredMark={false}
                >
                    <Form.Item
                        style={{flex: 1}}
                        label={'Email ID'}
                        name='f_email'
                        rules={[
                            { required: true, message: 'Email required' },
                        ]}
                    >
                        <Input autoComplete={'off'}
                        />
                    </Form.Item>

                    <div className="text-right">
                        <Space>
                            <Button
                                type='primary'
                                ghost
                                htmlType='button'
                                icon={<CloseOutlined />}
                                loading={loading}
                                className='theme-button primary full-width'
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>

                            <Button
                                type='primary'
                                htmlType='submit'
                                icon={<ReloadOutlined />}
                                loading={recoveringPassword}
                                className='theme-button primary full-width '
                            >
                                Recover
                            </Button>
                        </Space>
                    </div>
                </Form>
            </Modal> */}
    </>
  );
}
