import { PercentageOutlined } from "@ant-design/icons";
import { notification, Space } from "antd";
import NotificationContext from "context/Notification";
import moment from "moment";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import API_SERVICE from "services/api-service";
import { ADMIN_ORDER_DETAILS } from "shared/constants/RouteConstants";
import MilanoThumbnail from "../../assets/MilanoThumbnail.png";

interface Notification {
  notification: {
    id: number;
    data: string;
    isRead: boolean;
    sourceModel: string;
    title: string;
    sourceId: number;
    createdAt: Date;
  };
}

const AdminNotificationCardMain = (props: Notification) => {
  const { _fetchUnreadCount, fetchNotifications } =
    useContext(NotificationContext);
  const history = useHistory();
  const data = JSON.parse(props.notification.data);

  // const _createUrl = (type: string, obj: any) => {
  //   if (type === "CATEGORY") {
  //     history.push(`${PRODUCT_MAIN}/${obj.typeId}/${obj.type_details.name}`);
  //   }
  //   if (type === "PRODUCT") {
  //     history.push(
  //       `${PRODUCT_DETAILS_PAGE}/${obj.typeId}/${obj.type_details.category.name}`
  //     );
  //   }
  //   if (type === "CUSTOM") {
  //     window.open(obj.customUrl, "_blank");
  //   }
  // };

  const _statusChange = async (id: number) => {
    try {
      await API_SERVICE.statusChangeNotification(id);
      _fetchUnreadCount();
      fetchNotifications();
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  const _handleClick = (id: number) => {
    history.push(`${ADMIN_ORDER_DETAILS}/${id}`);
    _statusChange(props.notification.id);
  };

  return (
    <div className="notification-detail-card">
      <div className="image">
        <img
          onClick={() => _handleClick(data.id)}
          src={
            (data.hasOwnProperty("file") && data.file.url) || MilanoThumbnail
          }
          alt={props.notification.title}
        />
        {data.hasOwnProperty("description") && (
          <span className="icon">
            <PercentageOutlined />
          </span>
        )}
      </div>
      <div className="details">
        <h2 className="title" onClick={() => _handleClick(data.id)}>
          {props.notification.title}
        </h2>
        <p className="info">
          {data.hasOwnProperty("description") && data.description}
        </p>
        <span className="time-tag">
          {moment
            .utc(props.notification.createdAt)
            .local()
            .startOf("seconds")
            .fromNow()}
        </span>
      </div>
    </div>
  );
};

export default AdminNotificationCardMain;
