import "./AdminProfile.scss";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Radio,
  Row,
  Upload,
  notification,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import globe from "../../../assets/icons/globe.png";
import smartphone from "../../../assets/icons/smartphone.png";
import userPic from "../../../assets/icons/username.png";
import mailIcon from "../../../assets/icons/mail.png";
import uploadIcon from "shared/assets/images/upload.png";
import hashIcon from "shared/assets/images/hash.png";
import calenderIcon from "shared/assets/images/calendar.png";
import dollarIcon from "shared/assets/images/dollar-sign.png";
import { Link } from "react-router-dom";
import { HOME } from "shared/constants/RouteConstants";
import API_SERVICE from "services/api-service";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CloudDownload, Delete } from "@material-ui/icons";
import moment from "moment";
import { FileUpload } from "components";
import AdminListLayout from "shared/components/layout/AdminListLayout/AdminListLayout";
import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { RESET_ADMIN_PASSWORD } from "shared/constants/RouteConstants";

export default function AdminProfile(props: any) {
  const [form] = Form.useForm();
  const [id, setId] = useState();
  const [profilePic, setProfilePic] = useState();
  const [profilePicId, setProfilePicId] = useState();
  let history = useHistory();
  const profileUpdate = ({ id, url }) => {
    setProfilePicId(id);
    setProfilePic(url);
    console.log(url);
  };
  const getUserDetails = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.self();
      console.log("dddddd", data);
      setId(data.id);
      console.log("id", id);
      // form.resetFields();
      form.setFieldsValue({
        ["name"]: data?.name,
        ["email"]: data?.email,
        ["phone"]: data?.phone,
        ["role"]: data?.roles[0]?.name,
        ["address"]: data?.address,
      });
      setProfilePicId(data?.fileId);
      setProfilePic(data?.file?.url);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const updateAdminDetails = async (values: any) => {
    console.log("xxxxxxxxxxxxxxx", profilePicId);
    const params = {
      name: values.name,
      phone: values.phone,
      address: values.address,
      fileId: profilePicId,
    };
    try {
      await API_SERVICE.adminProfileUpdate(id, params);
      notification.success({
        message: "Updated Successfully",
        placement: "bottomRight",
      });
      getUserDetails();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      <div className="admin-profile-content">
        <Row
          className="admin-profile-main-title-row"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
          align="middle"
        >
          <Col xs={12} sm={12} md={20} lg={21}>
            <div className="admin-profile-main-title">EDIT PROFILE</div>
          </Col>
        </Row>
        <Row className="admin-profile-main-title-row-2">
          <div className="add-dealer-icon"
          >
            {" "}
            <ArrowLeftOutlined onClick={() => history.goBack()} />
          </div>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} className="admin-profile-container">
            <h1 className="page-title text-center">Admin Profile</h1>

            <Form form={form} layout="vertical" onFinish={updateAdminDetails}>
              <Row
                className="admin-profile-form"
                gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 24,
                  xl: 24,
                }}
              >
                <Col xs={24}>
                  <Form.Item
                    className="modalLabels"
                    name="profilePic"
                    style={{ textAlign: "center", margin: "20px 0px" }}
                  >
                    <img
                      src={profilePic}
                      alt="admin-pic"
                      className="admin-pic"
                    />
                    <FileUpload
                      type={"PROFILE_PIC"}
                      callback={profileUpdate}
                      style={{ display: "block" }}
                      onChange={(id: any) => {
                        setProfilePicId(id);
                      }}
                    >
                      <p className="dealer-pic-text">Edit</p>
                    </FileUpload>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item
                    className="modalLabels"
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Enter Name",
                      },
                    ]}
                  >
                    <Input
                      className="modalInputs"
                      prefix={<img src={userPic} />}
                      placeholder="Name"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    className="modalLabels"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Enter email",
                      },
                    ]}
                  >
                    <Input
                      className="modalInputs"
                      prefix={<img src={mailIcon} />}
                      placeholder="Email"
                      type="email"
                      disabled={true}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item
                    className="modalLabels"
                    label="Mobile Number"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Enter Mobile Number",
                      },
                      // {
                      //   pattern: /^[6-9]{1}[0-9]{9}$/,
                      //   message: "Please Enter Valid Phone Number",
                      // },
                    ]}
                  >
                    <Input
                      className="modalInputs"
                      prefix={<img src={smartphone} />}
                      placeholder="Mobile Number"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item className="modalLabels" label="Role" name="role">
                    <Input
                      className="modalInputs"
                      prefix={<img src={globe} />}
                      placeholder="Role"
                      disabled={true}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  <Form.Item
                    className="modalLabels"
                    label="Address"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Enter Address",
                      },
                    ]}
                  >
                    <Input
                      className="modalInputs"
                      prefix={<img src={globe} />}
                      placeholder="Address"
                    />
                  </Form.Item>
                </Col>
                <Col md={12}></Col>
                {/* <Col
                    xs={24} md={12}
                    style={{
                      marginTop: "40px",
                    }}
                  >
                     <Form.Item>
                   
                    </Form.Item>
                  </Col> */}
                <Col
                  xs={24}
                  style={{
                    marginTop: "40px",
                  }}
                >
                  <Form.Item>
                    <div className="btn-center">
                      <Button
                        htmlType="submit"
                        className="theme-btn addAddressBtn"
                      >
                        Save Changes
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Link to={RESET_ADMIN_PASSWORD} className="resetPasswordLink">
                    Change Password
                  </Link>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}
