import React, { useState } from "react";

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Modal,
  notification,
  Space,
  Checkbox,
} from "antd";
import { CloseOutlined, ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FORGOT_PASSWORD } from "../../../shared/constants/RouteConstants";
import API_SERVICE from "client/src/services/api-service";
import username from "../../assets/icons/username.png";

interface Props {
  OnforgotPassword: (email: string) => void;
  loading: boolean;
}

export default function ForgotPasswordForm({
  OnforgotPassword,
  loading,
}: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();
  // const onChange = (e) => {
  //     console.log(`checked = ${e.target.checked}`);
  //   };
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [recoveringPassword, setRecoveringPassword] = useState(false);

  function handleSubmit(values: any) {
    if (OnforgotPassword) {
      const { email } = values;
      OnforgotPassword(email);
    }
  }

  const forgotPassword = () => {
    // <
    // setIsModalVisible(true);
  };

  // const handleCancel = () => {
  //     setIsModalVisible(false);
  // };

  return (
    <>
      <Form
        className={"forgot-pass-form"}
        form={form}
        layout="vertical"
        fields={[]}
        initialValues={{
          email: "",
        }}
        onFinish={handleSubmit}
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 20 }}
        requiredMark={false}
      >
        <Form.Item
          className={"mb-3"}
          label={"Enter your registered email or username"}
          name="email"
          rules={[{ required: true, message: "Email required" }]}
        >
          <Input
            autoComplete={"off"}
            size="large"
            placeholder="username/email"
            className="forgot-input"
            prefix={<img src={username} />}
          />
        </Form.Item>

        <Form.Item className="mb-3">
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={loading}
            className="theme-button primary reset-btn"
          >
            Reset Password
          </Button>
        </Form.Item>
      </Form>

      {/* <Modal title="Forgot password? Recover Now."
                   footer={null}
                   visible={isModalVisible}
                   onCancel={handleCancel}
                   destroyOnClose={true}
            >
                <Form
                    className={'forgot-password-form'}
                    fields={[]}
                    onFinish={async (value: string) => {
                        try {
                            setRecoveringPassword(true);
                            // @ts-ignore
                            await API_SERVICE.forgotPassword(value.f_email);
                            notification.success({
                                message: 'An email with new password has been sent to you!',
                                 placement: "bottomRight"
                            });
                            handleCancel();
                        } catch (e) {
                            notification.error({
                                message: t(API_SERVICE.handleErrors(e)),
                                 placement: "bottomRight"
                            });
                        } finally {
                            setRecoveringPassword(false);
                        }
                    }}
                    requiredMark={false}
                >
                    <Form.Item
                        style={{flex: 1}}
                        label={'Email ID'}
                        name='f_email'
                        rules={[
                            { required: true, message: 'Email required' },
                        ]}
                    >
                        <Input autoComplete={'off'}
                        />
                    </Form.Item>

                    <div className="text-right">
                        <Space>
                            <Button
                                type='primary'
                                ghost
                                htmlType='button'
                                icon={<CloseOutlined />}
                                loading={loading}
                                className='theme-button primary full-width'
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>

                            <Button
                                type='primary'
                                htmlType='submit'
                                icon={<ReloadOutlined />}
                                loading={recoveringPassword}
                                className='theme-button primary full-width '
                            >
                                Recover
                            </Button>
                        </Space>
                    </div>
                </Form>
            </Modal> */}
    </>
  );
}
