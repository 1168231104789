import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./ManageProducts.scss";
import { MenuProps, Modal } from "antd";
import { EDIT_PRODUCT } from "shared/constants/RouteConstants";
import {
  SearchOutlined,
  DownOutlined,
  EditOutlined,
  DownloadOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Row, Col, Input, Button, Table, Pagination, notification } from "antd";
import AdminListLayout from "shared/components/layout/AdminListLayout/AdminListLayout";
import API_SERVICE from "../../../services/api-service";
import { Done } from "@material-ui/icons";
import AccessControl from "services/AccessControl";

const ManageProducts = () => {
  let history = useHistory();

  const { Search } = Input;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bulkUploadModal, setBulkUploadModal] = useState(false);

  const [downloadSampleUrl, setDownloadSampleUrl] = useState("");
  const [downloadSampleUrlImage, setDownloadSampleUrlImage] = useState("");
  const [bulkFile, setBulkFile] = useState({
    name: " ",
    size: 0,
  });
  const [bulkImageZipFile, setBulkImageZipFile] = useState({
    name: " ",
    size: 0,
  });

  const [modalType, setModalType] = useState("");
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [filterAttributes, setFilterAttributes] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: "15",
  } as any);
  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);

  const columns = [
    {
      title: "S.N.",
      key: "serialNumber",
      render: (a: any, v: any, index: number) => (
        <div>{(pagination.page - 1) * pagination.perpage + index + 1}</div>
      ),
    },
    {
      title: "PRODUCT NAME",
      key: "name",
      sorter: (a: any, b: any) => {},
      render: (data: any) => (
        <Row align="middle" wrap={false}>
          <Col>
            <img
              className="photo mr-2"
              src={data?.thumbnail?.url || "https://random.imagecdn.app/50/50"}
            />
          </Col>
          <Col>
            <div className="detailCard-title productdetailCard-title">
              {data?.name || "-"}
              <p>SKU #{data?.skuId}</p>
            </div>
          </Col>
        </Row>
      ),
    },
    {
      title: "PRICE",
      dataIndex: "price",
      render: (data: any) => <div>AED {data}</div>,
    },

    {
      title: "QUANTITY",
      dataIndex: "stock",
    },
    {
      title: "CBM ",
      dataIndex: "cbmValue",
    },
    {
      title: "STOCK LABEL ",
      render: (data: any) => <div>{data.label?.labels}</div>,
    },
    {
      title: "STATUS : ",
      dataIndex: "isActive",
      filters: [
        {
          text: "Active",
          value: 1,
        },
        {
          text: "Inactive",
          value: 0,
        },
      ],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (data: any) => <div>{data ? "Active" : "Inactive"}</div>,
    },
    {
      title: "VISIBILITY ",
      key: "visibility",
      render: (data: any) => {
        const str = [];
        if (data.isCatalogue) {
          str.push("Catalogue");
        }
        if (data.isSearchable) {
          str.push("Search");
        }
        return <div style={{ whiteSpace: "noWrap" }}>{str.join(", ")}</div>;
      },
    },
    {
      title: "COLOR : ",
      dataIndex: "color",
      filters:
        filterAttributes && filterAttributes.colors
          ? filterAttributes?.colors.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
      render: (data: any) => (
        <div className="productcolor-div" style={{ backgroundColor: data }} />
      ),
    },
    {
      title: "SIZE : ",
      dataIndex: "size",
    },
    {
      title: "WEIGHT : ",
      dataIndex: "netWeight",
    },
    {
      title: "CATEGORY : ",
      dataIndex: "category",
      filters:
        filterAttributes &&
        filterAttributes.categoriesName &&
        filterAttributes.categoriesName.length
          ? filterAttributes?.categoriesName.map((item: any) => {
              return { text: item.name, value: item.id };
            })
          : [],
      /*filters:
        filterAttributes && filterAttributes.categories && filterAttributes.categories.length
          ? filterAttributes?.categories.map((item: any) => {
              return { text: item.name, value: item.value, children: item.children };
            })
          : [],*/
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      // filterMode: "tree",
      // filterSearch: (input: any, record: any) => record.name.includes(input),
      filterSearch: true,
      render: (data) => {
        return data?.name;
      },
    },
    {
      title: "FAMILY NAME: ",
      dataIndex: "familyName",
      filters:
        filterAttributes && filterAttributes.familyNames
          ? filterAttributes?.familyNames.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
    },
    {
      title: "ACTION",
      key: "action",
      render: (data: any) => (
        <AccessControl id={29}>
          <div className="d-flex no-wrap">
            <Button
              shape="circle"
              className="edit-action-btn"
              onClick={() => {
                editProduct(data.id);
              }}
            >
              <EditOutlined />
            </Button>
            {/* <Button
            shape="circle"
            className="delete-action-btn"
            onClick={() => {
              deleteProduct(data.id);
            }}
          >
            <DeleteOutlined />
          </Button> */}
          </div>
        </AccessControl>
      ),
    },
  ];

  const editProduct = async (id: number) => {
    history.push(`${EDIT_PRODUCT}/${id}`, id);
  };

  function createFilterTree(data: any) {
    return data.map((item: any) => {
      return {
        text: item.name,
        value: item.id,
        children: item.children.length ? createFilterTree(item.children) : [],
      };
    });
  }

  const deleteProduct = async (id: number) => {
    try {
      await API_SERVICE.deleteProduct(id);
      notification.success({ message: "Product Successfully deleted" });
      if (productList.length === 1) {
        getProductList(
          { page: pagination.page - 1, perpage: pagination.perpage },
          sort,
          filters,
          search
        );
        setPagination((prev) => {
          return { page: prev.page - 1, perpage: prev.perpage };
        });
      } else {
        getProductList(pagination, sort, filters, search);
      }
    } catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  const getProductList = async (
    pagination = {},
    sort = {},
    filters = {},
    search = {}
  ) => {
    const params = {
      ...pagination,
      ...sort,
      ...filters,
      ...search,
    };
    params.categoryIds = params.category;
    delete params.category;
    const {
      data: { data },
    } = await API_SERVICE.getAllProductsList(params);
    setProductList(data?.items);
    /*setPagination({
            page: data?.page || 1,
            perpage: data?.perpage || 10,
        });*/
    setTotal(data?.total);
  };

  const getAllAttributes = async () => {
    const {
      data: { data },
    } = await API_SERVICE.fetchAllAttributes();
    setFilterAttributes(data);
  };

  const _handleTableChanges = async (
    pagination,
    filters,
    sorter,
    extra: { currentDataSource; action }
  ) => {
    if (extra.action === "paginate") {
      setPagination({ page: pagination.current, perpage: pagination.pageSize });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };

  const _handleSearch = (event) => {
    if (event.which === 13) {
      setSearch({ q: event.target.value });
      setPagination((prev) => {
        return { page: 1, perpage: prev.perpage };
      });
      getProductList({ page: 1, perpage: pagination.perpage }, sort, filters, {
        q: event.target.value,
      });
    }
  };

  const downloadSample = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.downloadManageProductSample();
      if (data) {
        setDownloadSampleUrl(data.url);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };
  const downloadSampleImage = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.downloadManageProductSampleImage();
      if (data) {
        setDownloadSampleUrlImage(data.url);
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };
  const downloadTableData = async () => {
    try {
      const {
        data: { data },
      } = await API_SERVICE.downloadManageProductTableData();
      if (data) {
        window.open(data.url, "_blank");
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  //handleBulkUpload
  const handleBulkUpload = async (e: any) => {
    e.preventDefault();
    try {
      await API_SERVICE.handleManageProductBulkUpload(bulkFile);
      notification.success({
        message: "Uploaded successfully",
        placement: "bottomRight",
      });
      setBulkFile({ name: " ", size: 0 });
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };
  const handleBulkUploadImage = async (e: any) => {
    e.preventDefault();
    try {
      console.log(bulkFile);
      console.log(bulkImageZipFile);

      await API_SERVICE.handleManageProductBulkUploadImage(
        bulkFile,
        bulkImageZipFile
      );
      notification.success({
        message: "Uploaded successfully",
        placement: "bottomRight",
      });
      setBulkFile({ name: " ", size: 0 });
      setBulkImageZipFile({ name: " ", size: 0 });
      setBulkUploadModal(false);
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    getProductList(pagination, sort, filters, search);
  }, [sort, filters, pagination]);

  useEffect(() => {
    getAllAttributes();
    downloadSample();
    downloadSampleImage();
  }, []);

  return (
    <AccessControl id={26}>
      <>
        <Modal
          title="Bulk Update"
          footer={null}
          className="add-address-modal "
          open={bulkUploadModal}
          onCancel={() => setBulkUploadModal(false)}
        >
          <form
            onSubmit={
              modalType === "bulkUpload"
                ? handleBulkUpload
                : handleBulkUploadImage
            }
          >
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 24,
                xl: 24,
              }}
            >
              <AccessControl id={32}>
                <Col xs={24} sm={24} md={16} lg={24} xl={16}>
                  <a
                    href={
                      modalType === "bulkUpload"
                        ? downloadSampleUrl
                        : downloadSampleUrlImage
                    }
                    download
                  >
                    <div
                      className="fileUploadDesign"
                      style={{ height: "45px" }}
                    >
                      <div className="text-truncate">
                        <DownloadOutlined className="icon" />
                        Download Sample
                      </div>
                      <p className="text">Download</p>
                    </div>
                  </a>
                </Col>
              </AccessControl>
              <AccessControl id={31}>
                <Col xs={24} sm={24} md={16} lg={24} xl={16}>
                  {modalType === "bulkUpload" ? (
                    <>
                      <input
                        type="file"
                        id="uploadFile"
                        accept={
                          ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        }
                        style={{ display: "none" }}
                        onChange={(e: any) => setBulkFile(e?.target?.files[0])}
                      ></input>
                      <label htmlFor="uploadFile">
                        <p>Upload File</p>
                        <div className="fileUploadDesign">
                          <div>
                            <UploadOutlined className="icon" />

                            {bulkFile?.name !== " " ? (
                              <span className="text">{bulkFile?.name}</span>
                            ) : (
                              "Select File"
                            )}
                          </div>
                          <p className="text">Upload</p>
                        </div>
                      </label>
                    </>
                  ) : (
                    <>
                      <input
                        type="file"
                        id="uploadFile"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        style={{ display: "none" }}
                        onChange={(e: any) => setBulkFile(e?.target?.files[0])}
                      ></input>
                      <label htmlFor="uploadFile">
                        <p>Upload File</p>
                        <div className="fileUploadDesign">
                          <div>
                            <UploadOutlined className="icon" />

                            {bulkFile?.name !== " " ? (
                              <span className="text">{bulkFile?.name}</span>
                            ) : (
                              "Select File"
                            )}
                          </div>
                          <p className="text">Upload</p>
                        </div>
                      </label>
                      <input
                        type="file"
                        id="uploadZipFile"
                        accept=".zip"
                        style={{ display: "none" }}
                        onChange={(e: any) =>
                          setBulkImageZipFile(e?.target?.files[0])
                        }
                      ></input>
                      <label htmlFor="uploadZipFile">
                        <p>Upload Zip Folder</p>
                        <div className="fileUploadDesign">
                          <div>
                            <UploadOutlined className="icon" />

                            {bulkImageZipFile?.name !== " " ? (
                              <span className="text">
                                {bulkImageZipFile?.name}
                              </span>
                            ) : (
                              "Select File"
                            )}
                          </div>
                          <p className="text">Upload</p>
                        </div>
                      </label>
                    </>
                  )}
                </Col>

                <Col xs={24} className="flex justify-content-center mt-3">
                  <div className="stockLabelBtnContainer">
                    <Button htmlType="submit" className="theme-btn">
                      Upload
                    </Button>
                  </div>
                </Col>
              </AccessControl>
            </Row>
          </form>
        </Modal>
        <Row
          className="align-items-center  stock-summary-container mb-3 mt-2"
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
          }}
        >
          <Col xs={24} sm={24} md={10} lg={6} xl={7} xxl={9} className="p-0">
            <div className="manage-products-title">Manage Products</div>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={10}
            lg={7}
            xl={6}
            xxl={6}
            className="flex justify-content-end align-items-center "
          >
            <Input
              onKeyPress={_handleSearch}
              className="admin-search mr-2"
              placeholder="Search..."
              prefix={<SearchOutlined />}
            />
          </Col>
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={5}
            xl={3}
            xxl={3}
            className="flex justify-content-end"
          >
            <Button
              type="primary"
              className="product-btn"
              onClick={() => {
                setModalType("bulkUpload");
                setBulkUploadModal(true);
              }}
            >
              <UploadOutlined />
              Bulk Update
            </Button>
          </Col>
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={5}
            xl={4}
            xxl={3}
            className="flex justify-content-end"
          >
            <Button
              type="primary"
              className="product-btn"
              onClick={() => {
                setModalType("bulkUploadImages");
                setBulkUploadModal(true);
              }}
            >
              <UploadOutlined />
              Bulk Update Images
            </Button>
          </Col>
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={4}
            xl={4}
            xxl={3}
            className="flex justify-content-end"
          >
            <Button
              type="primary"
              className="product-btn"
              onClick={downloadTableData}
            >
              <DownloadOutlined />
              Download Products
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={24} className="mb-3">
            <Table
              scroll={{ x: true }}
              loading={loading}
              columns={columns}
              dataSource={productList}
              size="middle"
              pagination={{
                size: "default",
                current: pagination.page,
                pageSize: pagination.perpage,
                total: total,
                /*onChange:(page, perpage) => {
                            setPagination({ page, perpage });
                            getProductList({page, perpage}, sort, filters, search);
                        }*/
              }}
              onChange={_handleTableChanges}
            />
            {/*<div className="d-flex justify-content-end mt-3">
                    <Pagination
                        current={pagination.page}
                        pageSize={pagination.perpage}
                        pageSizeOptions={[10, 20, 50]}
                        showSizeChanger
                        showQuickJumper
                        total={total}
                        onChange={(page, perpage) => {
                            setPagination({ page, perpage });
                            getProductList({page, perpage});
                        }}
                    />
                </div>*/}
          </Col>
        </Row>
      </>
    </AccessControl>
  );
};

export default ManageProducts;
