import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./UserManagement.scss";
import { AppstoreOutlined, MailOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  SearchOutlined,
  BellOutlined,
  DownOutlined,
  ShoppingCartOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import logoTransparent from "shared/assets/logoTransparent.png";
import API_SERVICE from "client/src/services/api-service";
import { useEffect } from "react";
// import { ADD_USER } from '../../../../../shared/constants/RouteConstants';
import { notification } from "antd";
import {
  Layout,
  Menu,
  Row,
  Col,
  Input,
  Badge,
  Dropdown,
  Space,
  Divider,
  Button,
  Table,
  Switch,
} from "antd";
import { ADD_ROLE, ADD_USER } from "shared/constants/RouteConstants";
import AdminListLayout from "shared/components/layout/AdminListLayout/AdminListLayout";
import AccessControl from "services/AccessControl";

const { Header, Sider, Content } = Layout;

// const { RangePicker } = DatePicker;
const menu = (
  <Menu
    items={[
      {
        label: <a href="https://www.antgroup.com">1st menu item</a>,
        key: "0",
      },
      {
        label: <a href="https://www.aliyun.com">2nd menu item</a>,
        key: "1",
      },
      {
        type: "divider",
      },
      {
        label: "3rd menu item",
        key: "3",
      },
    ]}
  />
);
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps["items"] = [
  getItem("Dashboard", "1", <AppstoreOutlined />),
  getItem("Catalogue", "sub1", <MailOutlined />, [
    getItem("Products", "g1", <ShoppingCartOutlined />),
    getItem("Categories", "g2", <ShoppingCartOutlined />),
  ]),
  getItem("Orders", "3", <ShoppingCartOutlined />),
  getItem("Dealers", "4", <AppstoreOutlined />),
  getItem("Stock Labels", "5", <AppstoreOutlined />),
  getItem("Users & Roles", "6", <AppstoreOutlined />),
];

interface Props {
  location: any;
}

const UserManagement = () => {
  const [filterAttributes, setFilterAttributes] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: "15",
  } as any);
  const [total, setTotal] = useState(10);
  const [filters, setFilters] = useState({} as any);
  const [sort, setSort] = useState({} as any);
  const [search, setSearch] = useState({} as any);

  let history = useHistory();

  const addUserClick = () => {
    history.push(ADD_USER);
  };
  const editUserClick = (id: number) => {
    history.push(`${ADD_USER}/${id}`);
  };
  const addRoleClick = () => {
    history.push(ADD_ROLE);
  };

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setFilteredData] = useState([
    {
      key: "1",
      serialNumber: 1,
      userName: "Amit Shah",
      role: "Sales man",
      contactDetails: "+91-3423123423 amit@aldanube.com",
    },
    {
      key: "2",
      serialNumber: 2,
      userName: "Amit Shah",
      role: "Sales man",
      contactDetails: "+91-3423123423 amit@aldanube.com",
    },
  ]);

  const deleteUserFromID = async (id: any) => {
    try {
      const res = await API_SERVICE.deleteUserFromAdmin(id);

      fetchALlUsers();
      notification.success({
        message: "User Deleted Successfully",
      });
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const fetchALlUsers = async (
    pagination = {},
    sort = {},
    filters = {},
    search = {}
  ) => {
    try {
      const params = {
        ...pagination,
        ...sort,
        ...filters,
        ...search,
      };
      setLoading(true);

      const {
        data: { data },
      } = await API_SERVICE.getAdminAllUsers(params);

      if (data.items !== 0) {
        setFilteredData(data?.items);
        setTotal(data?.total);
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const toogleActiveInactive = async (id: number, isActive: boolean) => {
    try {
      await API_SERVICE.adminActiveInactiveToogleCall(id, { isActive });
      notification.success({
        message: isActive ? "Activated" : "Deactivated",
        placement: "bottomRight",
      });
      fetchALlUsers();
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const getAllAttributes = async () => {
    const {
      data: { data },
    } = await API_SERVICE.GetUserAttributes();
    setFilterAttributes(data);
  };

  const _handleTableChanges = async (
    pagination,
    filters,
    sorter,
    extra: { currentDataSource; action }
  ) => {
    if (extra.action === "paginate") {
      setPagination({
        page: pagination.current,
        perpage: pagination.pageSize,
      });
    }
    if (extra.action === "sort") {
      if (sorter.order) {
        setSort({
          orderBy: sorter.columnKey,
          order: sorter.order === "ascend" ? "asc" : "desc",
        });
      } else {
        sort && sort.order && setSort({});
      }
    }
    if (extra.action === "filter") {
      setPagination({ page: 1, perpage: pagination.pageSize });
      setFilters(filters);
    }
  };

  const _handleSearch = (event) => {
    if (event.which === 13) {
      setSearch({ q: event.target.value });
      setPagination((prev) => {
        return { page: 1, perpage: prev.perpage };
      });
      fetchALlUsers({ page: 1, perpage: pagination.perpage }, sort, filters, {
        q: event.target.value,
      });
    }
  };

  useEffect(() => {
    fetchALlUsers(pagination, sort, filters, search);
  }, [sort, filters, pagination]);

  useEffect(() => {
    // fetchALlUsers();
    getAllAttributes();
  }, []);

  const columns = [
    // "id": 1,
    // "name": "admin",
    // "contact": "9876543210",
    // "contactDetails": "admin@admin.com",
    // "address": "delhi",
    // "status": 1,
    // "roleName": "Super Admin"

    {
      title: "S.N.",
      dataIndex: "key",
      // sorter: (a: any, b: any) => a.serialNumber - b.serialNumber,
      render: (a: any, v: any, index: number) => (
        <div>{(pagination.page - 1) * pagination.perpage + index + 1}</div>
      ),
    },
    {
      title: "NAME",
      dataIndex: "name",
      className: "user-name",
    },
    {
      title: "ROLE",
      dataIndex: "roleName",
      key: "roles",
      filters:
        filterAttributes && filterAttributes.roles
          ? filterAttributes?.roles.map((item: any) => {
              return { text: item, value: item };
            })
          : [],
      filterIcon: (filtered: any) => (
        <DownOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterSearch: true,
    },

    {
      title: "CONTACT DETAILS",
      // dataIndex: "contactDetails",
      render: (d: any) => {
        return (
          <>
            <div>{d["contact"]}</div>
            <div>{d["contactDetails"]}</div>
          </>
        );
      },
    },

    {
      title: "ACTION: ",
      render: (d: any) => (
        <div>
          <Switch
            onChange={(e: any) => {
              toogleActiveInactive(d.id, e);
            }}
            checked={d.status}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
          />
          <Divider type="vertical" />
          <AccessControl id={54}>
            <Button shape="circle" className="action-btn">
              <EditOutlined onClick={() => editUserClick(d.id)} />
            </Button>
          </AccessControl>
          <AccessControl id={57}>
            <Button shape="circle" className="action-btn">
              <DeleteOutlined onClick={() => deleteUserFromID(d.id)} />
            </Button>
          </AccessControl>
        </div>
      ),
    },
  ];

  return (
    <AccessControl id={49}>
      <>
        <Content className="user-management-main-content">
          <Row
            className=" stock-summary-container mb-2 mt-2"
            align="middle"
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
            }}
          >
            <Col xs={24} sm={24} md={13} lg={13}>
              <div className="user-management-title">
                USER & ROLE MANAGEMENT
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={13}
              lg={6}
              className="flex justify-content-end"
            >
              <Input
                onKeyPress={_handleSearch}
                className="admin-search"
                placeholder="Search..."
                prefix={<SearchOutlined />}
              />
            </Col>
            <AccessControl id={52}>
              <Col>
                <Button
                  onClick={addUserClick}
                  type="primary"
                  className="product-btn"
                >
                  <PlusOutlined />
                  Add User
                </Button>
              </Col>
            </AccessControl>
            <AccessControl id={53}>
              <Col>
                <Button
                  onClick={addRoleClick}
                  type="primary"
                  className="product-btn"
                >
                  <PlusOutlined />
                  Add Role
                </Button>
              </Col>
            </AccessControl>
          </Row>
          <Row>
            <Col xs={24} className="mb-3">
              <Table
                loading={loading}
                className="dealerListTable"
                columns={columns}
                dataSource={filteredData}
                size="middle"
                scroll={{ x: true }}
                pagination={{
                  size: "default",
                  current: pagination.page,
                  pageSize: pagination.perpage,
                  total: total,
                  /*onChange:(page, perpage) => {
                            setPagination({ page, perpage });
                            getProductList({page, perpage}, sort, filters, search);
                        }*/
                }}
                onChange={_handleTableChanges}
              />
            </Col>
          </Row>
        </Content>
      </>
    </AccessControl>
  );
};

export default UserManagement;
