import React, { Suspense, useContext, useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
const { Search } = Input;

import {
  BellOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  DropboxOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import PersonIcon from "@material-ui/icons/Person";
import {
  Badge,
  Button,
  Col,
  Dropdown,
  Input,
  Layout,
  Row,
  Typography,
  Space,
  Drawer,
  Menu,
} from "antd";
import { getUser } from "client/src/services/local-storage";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-use";
import logo from "shared/assets/logo.jpg";
import LoaderOverlay from "shared/components/LoaderOverlay";
import {
  SALES_DASHBOARD,
  SALES_DEALER_LIST,
  SALES_MANAGE_ORDERS,
  SALES_NOTIFICATION,
  SALES_ORDER_APPROVAL,
  SALES_PROFILE,
} from "shared/constants/RouteConstants";
import useWindowDimensions from "shared/hooks/WindowsDimension";
import "shared/style/fonts/icons/style.scss";
import { AuthConsumer, logout } from "../../contexts/AuthContext";
import "./Layout.scss";
const { Header, Footer, Sider, Content } = Layout;

import API_SERVICE from "client/src/services/api-service";
import NotificationContext from "context/Notification";
const { Text } = Typography;
const { SubMenu } = Menu;
// import { DirectionsBoatOutlined } from "@material-ui/icons";
// import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

// const onSearch = (value) => console.log(value);

interface LayoutProps {
  selectedMenu?: string | null;
  onMenuClick?: (route: string) => void;
  children: any;
  hasHeader?: boolean;
  logout?: boolean;
  hasLink?: boolean;
  linkText?: string;
  linkUrl?: string;
}
// asdf
import type { MenuProps } from "antd";
import SalesNotificationCardModal from "components/NotificationCardModal/SalesNotificationCardModal";
export default function SalesLayout({
  selectedMenu,
  onMenuClick,
  children,
  hasLink,
  linkText,
  linkUrl,
}: LayoutProps) {
  const [openCart, setOpenCart] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const windowDimensions = useWindowDimensions();
  const user = getUser();
  let searchTimer: any = undefined;
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const roleId = user.user.roles[0].pivot.roleId;

  const { totalNoti, _fetchUnreadCount, fetchNotifications } =
    useContext(NotificationContext);

  const items = [
    {
      key: "1",
      label: (
        <Button className="header-btn header-dropdown-btn">
          <PersonIcon />
          <p className="text">Profile Details</p>
        </Button>
      ),
      onClick: () => history.push(SALES_PROFILE),
    },

    {
      key: "4",
      label: (
        <Button className="header-btn header-dropdown-btn">
          <LogoutOutlined />
          <p className="text">Logout</p>
        </Button>
      ),
      onClick: () => logout(),
    },
  ];

  const fetchFilteredProducts = async (query: any) => {
    if (query) {
      const params = {
        q: query,
      };
      const {
        data: {
          data: { items },
        },
      } = await API_SERVICE.fetchCategoriesProducts(params);
      setFilteredProducts(items);
    } else {
      setFilteredProducts([]);
    }
  };

  type MenuItem = Required<MenuProps>["items"][number];
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const mobileMenuItems: MenuProps["items"] = [
    getItem("Dashboard", SALES_DASHBOARD, <DashboardOutlinedIcon />),
    getItem("Dealer List", SALES_DEALER_LIST, <HomeOutlined />),
    getItem(
      "Manager Order",
      roleId === 4 ? SALES_ORDER_APPROVAL : SALES_MANAGE_ORDERS,
      <HomeOutlined />
    ),
    getItem(
      "Notifications",
      SALES_NOTIFICATION,
      <Badge count={totalNoti}>
        <BellOutlined />
      </Badge>
    ),
    getItem("My Account", "myAccount", <PersonIcon />, [
      getItem("Profile Details", SALES_PROFILE, <PersonIcon />),
      getItem("Log Out", "logout", <LogoutOutlined />),
    ]),
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "logout") {
      logout();
    } else {
      history.push(e.key);
      setOpen(false);
    }
  };

  useEffect(() => {
    _fetchUnreadCount();
    fetchNotifications();
  }, []);

  return (
    <Layout
      hasSider={false}
      style={{ minHeight: "100vh" }}
      className={`desktop-layout`}
    >
      <Header className="salesHeader">
        <Row align="middle" justify="space-between" className="mb-2 mt-3 ">
          <Col xs={16} md={8} lg={2} xl={4} className="header-left">
            <div className="d-flex align-items-center">
              <div className="custom-nav-link header-logo-wrapper">
                <Link to={SALES_DASHBOARD}>
                  <img src={logo} className="logo" />
                </Link>
              </div>
            </div>
          </Col>
          {windowDimensions.width < 1025 && (
            <Col xs={8} className="flex justify-content-end align-items-end">
              <Space>
                <Button className="header-btn" onClick={showDrawer}>
                  <MenuFoldOutlined />
                </Button>
              </Space>
            </Col>
          )}
          {windowDimensions.width > 1024 ? (
            <Col
              className="desktop-menu gutter-row flex justify-content-end align-items-center  "
              xs={24}
              sm={24}
              md={24}
              lg={22}
              xl={20}
            >
              <AuthConsumer>
                {({ isAuth, user }) =>
                  isAuth ? (
                    <>
                      <Link to={SALES_DASHBOARD}>
                        <Button className="header-btn no-fix-width">
                          <DashboardOutlinedIcon />
                          <p className="text">Dashboard</p>
                        </Button>
                      </Link>

                      <Link to={SALES_DEALER_LIST}>
                        <Button className="header-btn no-fix-width">
                          <UsergroupAddOutlined />
                          <p className="text">Dealer List</p>
                        </Button>
                      </Link>
                      <Link
                        to={
                          roleId === 4
                            ? SALES_ORDER_APPROVAL
                            : SALES_MANAGE_ORDERS
                        }
                      >
                        <Button className="header-btn no-fix-width">
                          <DropboxOutlined />
                          <p className="text">Manage Orders</p>
                        </Button>
                      </Link>
                      <Dropdown
                        // trigger={["click"]}
                        dropdownRender={(menu) => (
                          <SalesNotificationCardModal />
                        )}
                      >
                        <Button className="header-btn">
                          <Badge count={totalNoti}>
                            <BellOutlined />
                          </Badge>
                        </Button>
                      </Dropdown>
                      <Dropdown menu={{ items }}>
                        <Button className="header-btn no-fix-width">
                          <PersonIcon />
                          <p className="text">
                            My Account <br /> <span>{user.name}</span>{" "}
                          </p>
                        </Button>
                      </Dropdown>
                    </>
                  ) : (
                    <Redirect to="/" />
                  )
                }
              </AuthConsumer>
            </Col>
          ) : (
            <>
              <Drawer
                placement={"left"}
                closable={false}
                onClose={onClose}
                width={windowDimensions.width < 500 ? "85%" : "35%"}
                open={open}
              >
                <Menu
                  onClick={onClick}
                  defaultSelectedKeys={["1"]}
                  mode="inline"
                  items={mobileMenuItems}
                />
              </Drawer>
            </>
          )}
        </Row>
      </Header>

      <Content className={"content-layout"}>
        <Suspense fallback={<LoaderOverlay size="large" loading={true} />}>
          {children}
        </Suspense>
      </Content>
    </Layout>
  );
}
