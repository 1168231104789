//
import {
  ArrowRightOutlined,
  CalendarOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Layout,
  Modal,
  notification,
  Row,
  Spin,
} from "antd";
import dayjs from "dayjs";
import AccessControl from "../../../client/src/services/AccessControl";
import API_SERVICE from "client/src/services/api-service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { consverPriceToInternationalFormat } from "services/util";
import Donut from "../../../client/src/components/Donut/Donut.js";
import DonutSmall from "../../../client/src/components/Donut/DonutSmall.js";
import dollar from "../../assets/icons/dollar-sign.png";
import arrow from "../../assets/icons/insight-box-arrow.png";
import failArrow from "../../assets/images/fail-arrow.png";
import MilanoThumbnail from "../../../client/src/assets/MilanoThumbnail.png";
import successArrow from "../../assets/images/success-arrow.png";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import {
  DEALER_LIST,
  MANAGE_ORDER,
  MANAGE_PRODUCTS,
} from "../../constants/RouteConstants";
import "./AdminDashboard.scss";
const { Header, Sider, Content } = Layout;

const { RangePicker } = DatePicker;
interface Props {
  location: any;
}

export default function AdminDashboard({ location }: Props) {
  const [totalRevenue, setTotalRevenue] = useState();
  const [totalOrders, setTotalOrders] = useState();
  const [dealersCount, setDealersCount] = useState();
  const [totalProducts, setTotalProucts] = useState();
  const [avgOrderValue, setAvgOrderValue] = useState();
  const [orderChartStatus, setOrderChartStatus] = useState({});
  const [loading, setLoading] = useState(false);
  const [topPerformingProducts, setTopPerformingProducts] = useState([]);
  const [topPerformingDealers, setTopPerformingDealers] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [topPerformingRange, setTopPerformingRange] = useState({
    month: moment(new Date()).format("MMMM"),
    year: moment(new Date()).format("YYYY"),
  });
  const [topPerformingDealerRange, setTopPerformingDealerRange] = useState({
    month: moment(new Date()).format("MMMM"),
    year: moment(new Date()).format("YYYY"),
  });
  const [reveueRange, setRevenueRange] = useState({
    year: moment(new Date()).format("YYYY"),
  });

  let history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    GetDashboardOrderChartInfo();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const GetTotalRevenue = async () => {
    try {
      setLoading(true);
      const { data: data } = await API_SERVICE.getTotalRevenue();
      if (data) {
        setTotalRevenue(data);
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      GetTotalOrder();
    }
  };
  const GetTotalOrder = async () => {
    try {
      setLoading(true);
      const { data: data } = await API_SERVICE.getTotalOrders();
      if (data) {
        setTotalOrders(data);
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      GetDealersCount();
    }
  };

  const GetDealersCount = async () => {
    try {
      setLoading(true);
      const { data: data } = await API_SERVICE.getDealersCount();
      if (data) {
        setDealersCount(data);
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      GetTotalProducts();
    }
  };
  const GetTotalProducts = async () => {
    try {
      setLoading(true);
      const { data: data } = await API_SERVICE.getTotalProducts();
      if (data) {
        setTotalProucts(data);
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    } finally {
      GetAvgOrderValue();
    }
  };
  const GetAvgOrderValue = async () => {
    try {
      setLoading(true);
      const { data: data } = await API_SERVICE.getAvgOrderValue();
      if (data) {
        setAvgOrderValue(data);
      }
      setLoading(false);
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const GetTopPerformingProducts = async () => {
    try {
      const params = {
        month: topPerformingRange.month,
        year: topPerformingRange.year,
      };
      const { data: data } = await API_SERVICE.GetTopPerformingProducts(params);
      if (data) {
        setTopPerformingProducts(data.data);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };
  const GetTopPerformingDealers = async () => {
    try {
      const params = {
        month: topPerformingDealerRange.month,
        year: topPerformingDealerRange.year,
      };
      const { data: data } = await API_SERVICE.GetTopPerformingDealers(params);
      if (data) {
        setTopPerformingDealers(data.data);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const GetDashboardOrderChartInfo = async () => {
    try {
      const { data: data } = await API_SERVICE.GetDashboardOrderChartInfo();
      if (data) {
        setOrderChartStatus(data);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  const GetRevenueDetails = async () => {
    try {
      const params = {
        year: reveueRange.year,
      };
      const { data: data } = await API_SERVICE.GetRevenueDetails(params);
      if (data) {
        setRevenueData(data.data);
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    GetTotalRevenue();
    GetDashboardOrderChartInfo();
  }, []);

  useEffect(() => {
    GetTopPerformingProducts();
  }, [topPerformingRange]);

  useEffect(() => {
    GetTopPerformingDealers();
  }, [topPerformingDealerRange]);

  useEffect(() => {
    GetRevenueDetails();
  }, [reveueRange]);

  return (
    <>
      <Content>
        <Modal
          title="Total Order Breakdown by Status"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          className="total-order-modal"
        >
          <Donut
            orderChartStatus={orderChartStatus}
            totalOrders={totalOrders}
          />
        </Modal>
        <div className="admin-dashboard-main-content">
          <Col xs={16} lg={21}>
            <div className="dashboard-page-title">DASHBOARD</div>
          </Col>
          <div className="dashboard-admin-row mb-2">
            <AccessControl id={1}>
              <div className="dashboard-box">
                <div className="dashboard-box-title">Total Order Value</div>
                <div className="dashboard-box-content">
                  AED{" "}
                  {loading ? (
                    <Spin />
                  ) : (
                    consverPriceToInternationalFormat(totalRevenue?.data)
                  )}
                </div>
                {/* <div>
                  <p className="dashboard-p">
                    <span className="dashboard-success">1.3% </span>{" "}
                    <img src={successArrow} alt="" />
                    than last year
                  </p>
                </div> */}
              </div>
            </AccessControl>
            <AccessControl id={2}>
              <div className="dashboard-box dashboard-order">
                <div
                  className="dashboard-box-title"
                  onClick={() => history.push(MANAGE_ORDER)}
                >
                  Total Orders
                </div>
                <div className="dashboard-box-content">
                  {loading ? <Spin /> : totalOrders?.data}
                </div>
                {/* <div>
                  <p className="dashboard-p">
                    <span className="dashboard-fail">1.3% </span>{" "}
                    <img src={failArrow} alt="" /> than last year
                  </p>
                </div> */}
                <ArrowRightOutlined className="arrow" onClick={showModal} />
                <div className="donut">
                  <DonutSmall
                    orderChartStatus={orderChartStatus}
                    totalOrders={totalOrders}
                  />
                </div>
              </div>
            </AccessControl>
            <AccessControl id={3}>
              <div
                className="dashboard-box"
                onClick={() => history.push(DEALER_LIST)}
              >
                <div className="dashboard-box-title">Dealers Registered</div>
                <div className="dashboard-box-content">
                  {loading ? <Spin /> : dealersCount?.data}
                </div>
                <div>
                  <p className="dashboard-p">
                    <span className="dashboard-success">1.3% </span>{" "}
                    <img src={successArrow} alt="" /> than last year
                  </p>
                </div>
              </div>
            </AccessControl>
            <AccessControl id={4}>
              <div
                className="dashboard-box"
                onClick={() => history.push(MANAGE_PRODUCTS)}
              >
                <div className="dashboard-box-title">Total Products</div>
                <div className="dashboard-box-content">
                  {loading ? (
                    <Spin />
                  ) : (
                    consverPriceToInternationalFormat(totalProducts?.data)
                  )}
                </div>
                {/* <div>
                  <p className="dashboard-p">
                    <span className="dashboard-success">1.3% </span>{" "}
                    <img src={successArrow} alt="" /> than last year
                  </p>
                </div> */}
              </div>
            </AccessControl>
            <AccessControl id={5}>
              <div className="dashboard-box">
                <div className="dashboard-box-title">Average Order Value</div>
                <div className="dashboard-box-content">
                  AED{" "}
                  {loading ? (
                    <Spin />
                  ) : (
                    consverPriceToInternationalFormat(avgOrderValue?.data)
                  )}
                </div>
                {/* <div>
                  <p className="dashboard-p">
                    <span className="dashboard-success">1.3% </span>{" "}
                    <img src={successArrow} alt="" /> than last year
                  </p>
                </div> */}
              </div>
            </AccessControl>
          </div>

          <Row
            // style={{ margin: 0 }}
            gutter={{ xs: 8, sm: 16 }}
            // justify="space-between"
          >
            <AccessControl id={6}>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={7}
              >
                <div className="insight-box">
                  <div className="insight-box-header">
                    <div className="insight-box-title">
                      <img className="insight-img" src={arrow} alt="" />
                      Top performing Products
                      <AccessControl id={9}>
                        <DatePicker
                          disabledDate={(current) => {
                            return (
                              current &&
                              current > dayjs().startOf("month").add(1, "month")
                            );
                          }}
                          allowClear={false}
                          defaultValue={moment(new Date(), "MMM YYYY")}
                          picker="month"
                          onChange={(value) => {
                            setTopPerformingRange({
                              month: moment(value).format("MMMM"),
                              year: moment(value).format("YYYY"),
                            });
                          }}
                          className="admin-datepicker"
                          suffixIcon={<CalendarOutlined />}
                        />
                      </AccessControl>
                    </div>
                  </div>
                  <Divider className="divide" />
                  <div className="insight-content-box">
                    {topPerformingProducts.length !== 0
                      ? topPerformingProducts.slice(0, 5).map((prod: any) => {
                          return <DetailCard product={prod} />;
                        })
                      : "No Data Available"}
                  </div>

                  <Divider className="divide admin-divide" />
                  <Button
                    type="text"
                    className="insight-button"
                    onClick={() => history.push(MANAGE_PRODUCTS)}
                  >
                    More Insights <RightOutlined />
                  </Button>
                </div>
              </Col>
            </AccessControl>
            <AccessControl id={7}>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={10}
                xl={9}
                xxl={7}
              >
                <div className="insight-box">
                  <div className="insight-box-header">
                    <div className="insight-box-title">
                      <img className="insight-img" src={arrow} alt="" />
                      Top performing Dealers
                      <AccessControl id={10}>
                        <DatePicker
                          disabledDate={(current) => {
                            return (
                              current &&
                              current > dayjs().startOf("month").add(1, "month")
                            );
                          }}
                          allowClear={false}
                          defaultValue={moment(new Date(), "MMM YYYY")}
                          picker="month"
                          onChange={(value) => {
                            setTopPerformingDealerRange({
                              month: moment(value).format("MMMM"),
                              year: moment(value).format("YYYY"),
                            });
                          }}
                          className="admin-datepicker"
                          suffixIcon={<CalendarOutlined />}
                        />
                      </AccessControl>
                    </div>
                  </div>
                  <Divider className="divide" />
                  <div>
                    <div className="insight-content-box">
                      {topPerformingDealers.length !== 0
                        ? topPerformingDealers
                            .slice(0, 5)
                            .map((dealer: any) => {
                              return <TopPerformingCard dealer={dealer} />;
                            })
                        : " No Data Available"}
                    </div>
                  </div>
                  <Divider className="divide" />
                  <Button
                    type="text"
                    className="insight-button"
                    onClick={() => history.push(DEALER_LIST)}
                  >
                    More Insights <RightOutlined />
                  </Button>
                </div>
              </Col>
            </AccessControl>
            <AccessControl id={8}>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={16}
                xl={16}
                xxl={10}
              >
                <div className="insight-box">
                  <div className="insight-box-header">
                    <div className="insight-box-title d-flex">
                      <ShoppingCartOutlinedIcon className="insight-img" />
                      Order Value
                      <AccessControl id={11}>
                        <DatePicker
                          allowClear={false}
                          defaultValue={moment(new Date(), "MMM YYYY")}
                          picker="year"
                          onChange={(value) => {
                            setRevenueRange({
                              year: moment(value).format("YYYY"),
                            });
                          }}
                          className="admin-datepicker"
                          suffixIcon={<CalendarOutlined />}
                        />
                      </AccessControl>
                    </div>
                  </div>
                  <Divider className="divide" />
                  <Row
                    // align="middle"
                    justify="space-between"
                    className="pb-4"
                    gutter={{ xs: 8, sm: 16, md: 24 }}
                  >
                    <Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
                      <Row
                        align="middle"
                        justify="space-between"
                        gutter={{ xs: 8, sm: 16, md: 24 }}
                      >
                        <Col>Month</Col>
                        <Col>Order Value</Col>
                        <Col>Orders</Col>
                        <Divider className="divide" />

                        {revenueData.length !== 0 && revenueData.length > 6 ? (
                          revenueData.slice(0, 6).map((rev: any) => {
                            return (
                              <>
                                <Col
                                  style={{ padding: 0 }}
                                  className="gutter-row"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                >
                                  <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                                    <Col
                                      className="gutter-row"
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                    >
                                      <p className="insight-box-month">
                                        {rev.months}
                                      </p>
                                    </Col>
                                    <Col
                                      className="gutter-row"
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                    >
                                      <p className="insight-box-span">
                                        AED{" "}
                                        {consverPriceToInternationalFormat(
                                          rev.revenue
                                        )}
                                      </p>
                                    </Col>
                                    <Col
                                      className="gutter-row"
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                    >
                                      <p className="insight-box-span">
                                        {rev.orders}
                                      </p>
                                    </Col>
                                  </Row>
                                </Col>
                                <Divider />
                              </>
                            );
                          })
                        ) : (
                          <p className="text-center " style={{ width: "100%" }}>
                            {" "}
                            No Data Available
                          </p>
                        )}
                      </Row>
                    </Col>
                    <Col className="gutter-row" xs={24} sm={24} md={12} lg={12}>
                      <Row
                        align="middle"
                        justify="space-between"
                        gutter={{ xs: 8, sm: 16, md: 24 }}
                      >
                        <Col>Month</Col>
                        <Col>Order Value</Col>
                        <Col>Orders</Col>
                        <Divider className="divide" />
                        {revenueData.length !== 0 ? (
                          revenueData.slice(6).map((rev: any) => {
                            return (
                              <>
                                <Col
                                  style={{ padding: 0 }}
                                  className="gutter-row"
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={24}
                                >
                                  <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                                    <Col
                                      className="gutter-row"
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                    >
                                      <p className="insight-box-month">
                                        {rev.months}
                                      </p>
                                    </Col>
                                    <Col
                                      className="gutter-row"
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                    >
                                      <p className="insight-box-span">
                                        AED{" "}
                                        {consverPriceToInternationalFormat(
                                          rev.revenue
                                        )}
                                      </p>
                                    </Col>
                                    <Col
                                      className="gutter-row"
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                    >
                                      <p className="insight-box-span">
                                        {rev.orders}
                                      </p>
                                    </Col>
                                  </Row>
                                </Col>
                                <Divider />
                              </>
                            );
                          })
                        ) : (
                          <p className="text-center " style={{ width: "100%" }}>
                            {" "}
                            No Data Available
                          </p>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </AccessControl>
          </Row>
        </div>
      </Content>
    </>
  );
}
function DetailCard(props: any) {
  return (
    <Row
      className="detail-card"
      style={{ margin: 0 }}
      align="middle"
      justify="space-between"
    >
      <Col xs={17} sm={17} lg={18}>
        <Row align="middle">
          <Col xs={6}>
            <img
              className="photo insight-photo"
              src={props.product?.url || MilanoThumbnail}
            />
          </Col>
          <Col xs={17}>
            <div className="detailCard-title">
              {props.product?.name}
              <p>SKU #{props.product?.skuId}</p>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={7} sm={7} lg={6}>
        <div className="detailCard-number">{props.product?.count} Nos</div>
      </Col>
    </Row>
  );
}

function TopPerformingCard(props: any) {
  return (
    <Row
      className="detail-card"
      style={{ margin: 0 }}
      align="middle"
      justify="space-between"
    >
      <Col xs={17}>
        <Row align="middle">
          <Col xs={7}>
            <img
              className="photo top-performing"
              src={props.dealer?.url || MilanoThumbnail}
            />
          </Col>
          <Col xs={17}>
            <div className="detailCard-title">
              {props.dealer?.name}
              <p>{props.dealer?.customerId}</p>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={7}>
        <div className="detailCard-number flex">
          AED {consverPriceToInternationalFormat(props.dealer?.revenue)}{" "}
        </div>
      </Col>
    </Row>
  );
}
