import React, { CSSProperties, useEffect, useRef, useState } from "react";
import voucherIcon from "shared/assets/images/voucher.png";
import useWindowDimensions from "shared/hooks/WindowsDimension";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  CATEGORIES,
  PRODUCT_DETAILS_PAGE,
} from "shared/constants/RouteConstants";
import {
  Row,
  Col,
  Button,
  Space,
  Input,
  Carousel,
  Card,
  Layout,
  Checkbox,
  Divider,
  Slider,
  Breadcrumb,
  Menu,
  Dropdown,
  notification,
  Pagination,
  Select,
} from "antd";
import API_SERVICE from "services/api-service";
import AddToCartBtn from "components/AddToCartBtn";
import { InboxOutlined } from "@material-ui/icons";
import "./ProductPage.scss";
import PropTypes from "prop-types";
import {
  ArrowRightOutlined,
  HeartOutlined,
  HeartFilled,
  DownSquareOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { consverPriceToInternationalFormat } from "services/util";
import MilanoThumbnail from "../../../assets/MilanoThumbnail.png";

const { Search } = Input;
const { Content } = Layout;

export default function ProductPage(props: any) {
  const pageTopRef = useRef();

  const [pagination, setPagination] = useState({
    page: 1,
    pages: 1,
    perpage: 12,
    total: 1,
  });

  const [currentMinValue, setCurrentMinValue] = useState<number>();
  const [currentMaxValue, setCurrentMaxValue] = useState<number>();
  const windowDimensions = useWindowDimensions();
  const [visible, setVisible] = useState(false);
  const showVisible = () => {
    /*if (visible) setVisible(false);
    else setVisible(true);*/
    setVisible((prev: boolean) => {
      return !prev;
    });
  };
  const [productsList, setProductsList] = useState([]);
  const [subCate, setSubCate] = useState<any[]>([]);
  const [sortKey, setSortKey] = useState<number>();

  const [familyNames, setFamilyNames] = useState<string[]>([]);
  const [colors, setColors] = useState<any[]>([]);
  const [sizes, setSizes] = useState<any[]>([]);
  const [orderBy, setOrderBy] = useState({ order: "asc", orderBy: "name" });
  const [currency, setCurrency] = useState();
  const { categoryId, popularProducts, categoryName, selectedFamily }: any =
    useParams();

  const [filters, setFilters] = useState({
    prices: [
      {
        priceMin: null,
        priceMax: null,
      },
    ],
    subcategories: [
      {
        id: 2,
        name: "sub_cat",
        parentId: 1,
        fileId: 2,
        isActive: true,
        order: 1,
        deletedAt: null,
        createdAt: null,
        updatedAt: null,
      },
    ],
    colors: [],
    familyNames: [],
    dimensions: [],
  });
  const fetchCategoryProducts = async (page?: number, perpage?: number) => {
    try {
      let params = {
        categoryId,
        familyName: familyNames,
        Color: colors,
        dimension: sizes,
        order: orderBy.order,
        orderBy: orderBy.orderBy,
        page: page || 1,
        perpage: perpage,
      };
      if (currentMaxValue && currentMinValue)
        params = { ...params, min: [currentMinValue], max: [currentMaxValue] };
      if (subCate && subCate.length) {
        //@ts-ignore
        params["categoryIds"] = subCate;
        delete params.categoryId;
      }

      const {
        data: { data },
      } = await API_SERVICE.getAllProducts(params);

      if (data !== 0) {
        setProductsList(data.items);
        setPagination({
          page: data.page,
          perpage: data.perpage,
          total: data.total,
          pages: data.pages,
        });
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };

  const handleSubCate = (checked: boolean, subId: number) => {
    if (checked === true) {
      setSubCate((prev) => {
        return [...prev, subId];
      });
    } else {
      let filterArr = subCate.filter(function (value) {
        return value != subId;
      });
      setSubCate(filterArr);
    }
  };

  const handleFamilyNames = (checked: boolean, fm: string) => {
    if (checked === true) {
      setFamilyNames([fm, ...familyNames]);
    } else {
      let filterArr = familyNames.filter(function (name) {
        return name != fm;
      });
      setFamilyNames(filterArr);
    }
  };

  const handleColors = (checked: boolean, c: any) => {
    if (checked === true) {
      setColors([c, ...colors]);
    } else {
      let filterArr = colors.filter(function (color) {
        return color != c;
      });
      setColors(filterArr);
    }
  };

  const handleSizes = (checked: boolean, d: any) => {
    if (checked === true) {
      setSizes([d, ...sizes]);
    } else {
      let filterArr = sizes.filter(function (size) {
        return size != d;
      });
      setSizes(filterArr);
    }
  };

  const fetchAttributes = async () => {
    try {
      const params = {};
      if (categoryId !== "PopularFamily") {
        //@ts-ignore
        params["categoryId"] = categoryId;
      }
      if (props?.location?.state === "featured") {
        const {
          data: { data },
        } = await API_SERVICE.fetchFeaturedAttributes(params);
        const filterIsActive = (values: []) => {
          return values.filter((item: any) => item.isActive === true);
        };

        if (data) {
          setFilters({
            prices: data.prices,
            subcategories: data.categories
              ? filterIsActive(data.categories)
              : filterIsActive(data.subcategories),
            colors: data.colors,
            familyNames: data.familyNames,
            dimensions: data.dimensions,
          });
          setCurrentMaxValue(data.prices[0].priceMax);
          setCurrentMinValue(data.prices[0].priceMin);
        }
      } else if (
        categoryId == undefined &&
        props?.location?.state != "featured"
      ) {
        const {
          data: { data },
        } = await API_SERVICE.fetchPopularAttributes(params);
        const filterIsActive = (values: []) => {
          return values.filter((item: any) => item.isActive === true);
        };

        if (data) {
          setFilters({
            prices: data.prices,
            subcategories: data.categories
              ? filterIsActive(data.categories)
              : filterIsActive(data.subcategories),
            colors: data.colors,
            familyNames: data.familyNames,
            dimensions: data.dimensions,
          });
          setCurrentMaxValue(data.prices[0].priceMax);
          setCurrentMinValue(data.prices[0].priceMin);
        }
      } else {
        const {
          data: { data },
        } = await API_SERVICE.fetchAttributes(params);

        const filterIsActive = (values: []) => {
          return values.filter((item: any) => item.isActive === true);
        };

        if (data) {
          setFilters({
            prices: data.prices,
            subcategories: data.categories
              ? filterIsActive(data.categories)
              : filterIsActive(data.subcategories),
            colors: data.colors,
            familyNames: data.familyNames,
            dimensions: data.dimensions,
          });
          setCurrentMaxValue(data.prices[0].priceMax);
          setCurrentMinValue(data.prices[0].priceMin);
        }
      }
    } catch (err) {
      notification.error({
        message: API_SERVICE.handleErrors(err),
        placement: "bottomLeft",
      });
    }
  };

  const resetFilters = () => {
    setSubCate([]);
    setSizes([]);
    setColors([]);
    setFamilyNames([]);
    setCurrentMinValue(filters?.prices[0]?.priceMin);
    setCurrentMaxValue(filters?.prices[0]?.priceMax);

    //@ts-ignore
    pageTopRef.current.scrollIntoView();

    // put your code for other filters here
  };

  const fetchPopularProducts = async (page?: number, perpage?: number) => {
    try {
      let params = {
        categoryIds: subCate,
        familyName: familyNames,
        Color: colors,
        dimension: sizes,
        order: orderBy.order,
        orderBy: orderBy.orderBy,
        page: page,
        perpage: perpage,
      };
      if (currentMaxValue && currentMinValue)
        params = { ...params, min: [currentMinValue], max: [currentMaxValue] };
      const {
        data: { data },
      } = await API_SERVICE.fetchPopularProducts(params);
      if (data.items) {
        setProductsList(data.items);
        setPagination({
          page: data.page,
          perpage: data.perpage,
          total: data.total,
          pages: data.pages,
        });
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };
  const fetchFeatured = async (page?: number, perpage?: number) => {
    try {
      let params = {
        categoryIds: subCate,
        familyName: familyNames,
        Color: colors,
        dimension: sizes,

        order: orderBy.order,
        orderBy: orderBy.orderBy,
        page: page,
        perpage: perpage,
      };
      if (currentMaxValue && currentMinValue)
        params = { ...params, min: [currentMinValue], max: [currentMaxValue] };

      const {
        data: { data },
      } = await API_SERVICE.fetchFeatureProducts(params);

      if (data.items) {
        setProductsList(data.items);
        setPagination({
          page: data.page,
          perpage: data.perpage,
          total: data.total,
          pages: data.pages,
        });
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };
  const fetchPopularFamily = async (page?: number, perpage?: number) => {
    try {
      console.log(categoryName);
      let params = {
        // categoryId: categoryName,
        categoryId: subCate,
        familyName: categoryName,
        Color: colors,
        dimension: sizes,
        order: orderBy.order,
        orderBy: orderBy.orderBy,
        page: page,
        perpage: perpage,
      };
      if (currentMaxValue && currentMinValue)
        params = { ...params, min: [currentMinValue], max: [currentMaxValue] };
      const {
        data: { data },
      } = await API_SERVICE.popularFamilyProducts(params);
      if (data.items) {
        setProductsList(data.items);
        setPagination({
          page: data.page,
          perpage: data.perpage,
          total: data.total,
          pages: data.pages,
        });
      }
    } catch (error) {
      notification.error({
        message: API_SERVICE.handleErrors(error),
        placement: "bottomLeft",
      });
    }
  };
  const getCurrency = () => {
    const user = JSON.parse(localStorage.getItem("user")!);
    setCurrency(user?.user.dealerDetails[0]?.currencyType);
  };
  useEffect(() => {
    windowDimensions.width < 800 && showVisible();
    fetchAttributes();
    getCurrency();
  }, []);

  useEffect(() => {
    if (props?.location?.state === "featured") {
      fetchFeatured();
    } else if (categoryId === undefined) {
      fetchPopularProducts();
    } else if (props?.match?.params?.categoryId === "PopularFamily") {
      fetchPopularFamily();
    } else if (categoryId !== undefined && categoryId !== "PopularFamily") {
      fetchCategoryProducts();
    }
  }, [orderBy, colors, sizes, familyNames, subCate]);

  return (
    <Layout className="product-main-content contentPadding pb-7">
      <Row ref={pageTopRef}>
        <Col xs={16} sm={16} md={16} lg={24}>
          <Breadcrumb className="products-breadcrumb" separator=">">
            <Breadcrumb.Item>
              <Link to={CATEGORIES}>All Categories</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="products-breadcrumb-active">
              {popularProducts}
              {categoryName}
              {!popularProducts && !categoryName && "Featured Products"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col xs={8} sm={8} md={8} lg={0}>
          <Row
            align="middle"
            className="filter m-0"
            gutter={{ xs: 8, sm: 16, md: 24 }}
          >
            <Col
              className="gutter-row flex align-items-center justify-content-end"
              xs={24}
            >
              <div className="filter-btn-div">
                <Button
                  className="filter-btn"
                  size="small"
                  icon={<ArrowRightOutlined />}
                  onClick={showVisible}
                ></Button>
                <p className="m-0">Filters</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="m-0" gutter={{ xs: 8, sm: 16, md: 24 }}>
        <Col className="gutter-row p-0" xs={24} sm={24} md={8} lg={6}>
          <div className={visible ? "product-sider-collapsed" : "sider-main"}>
            <div className="sider-div">
              <div className="sider-category">
                {filters.subcategories[0] !== null && (
                  <>
                    <div className="sider-title-container">
                      <div className="sider-title">
                        {categoryName
                          ? categoryId !== "PopularFamily"
                            ? categoryName
                            : "Categories"
                          : "Categories"}
                      </div>
                      <div>
                        <Button className="reset-btn" onClick={resetFilters}>
                          Reset Filter
                        </Button>
                      </div>
                    </div>

                    <div className="sider-checkbox">
                      {filters?.subcategories?.map((sub: any) => {
                        return (
                          <Row
                            key={sub.id}
                            className="sider-row"
                            align="middle"
                            justify="space-between"
                          >
                            <Col>
                              <p className="sider-txt">{sub.name}</p>
                              {/* <span className="sider-number">(123)</span></p>{" "} */}
                            </Col>
                            <Col>
                              <Checkbox
                                checked={
                                  subCate?.includes(sub?.id) ? true : false
                                }
                                onChange={(e) =>
                                  handleSubCate(e.target.checked, sub?.id)
                                }
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
              {filters.prices[0].priceMin !== null &&
                filters.prices[0].priceMax !== null && (
                  <>
                    {categoryId !== "PopularFamily" && (
                      <Divider className="divider" orientation="center" />
                    )}
                    <div className="sider-price">
                      <div className="sider-title-container">
                        <div className="sider-title">Filter by Price</div>
                        <div>
                          <Button
                            className="reset-btn"
                            onClick={() => {
                              if (categoryId === "PopularFamily") {
                                fetchPopularFamily();
                              } else if (props?.location?.state === "featured")
                                fetchFeatured();
                              else if (
                                categoryId !== undefined &&
                                categoryId !== "PopularFamily"
                              ) {
                                fetchCategoryProducts();
                              } else if (categoryId === undefined) {
                                fetchPopularProducts();
                              }

                              //@ts-ignore
                              pageTopRef.current.scrollIntoView();
                            }}
                          >
                            Apply
                          </Button>
                        </div>
                      </div>

                      <div className="sider-checkbox pt-0 pb-0 overflow">
                        <Slider
                          className="slide "
                          range
                          //@ts-ignore
                          defaultValue={[
                            filters.prices[0].priceMin,
                            filters.prices[0].priceMax,
                          ]}
                          value={[currentMinValue, currentMaxValue]}
                          step={0.01}
                          min={filters.prices[0].priceMin}
                          max={filters.prices[0].priceMax}
                          onChange={([minValue, maxValue]) => {
                            setCurrentMinValue(minValue);
                            setCurrentMaxValue(maxValue);
                          }}
                        />
                        <p className="sider-txt">
                          Price
                          <span className="sider-title">
                            {currency === "USD" ? "$ " : "AED "}
                            {currentMinValue}&nbsp;-&nbsp;
                            {currency === "USD" ? "$ " : "AED "}
                            {currentMaxValue}
                          </span>
                        </p>
                      </div>
                    </div>
                  </>
                )}

              {filters.dimensions[0] !== null &&
                filters.dimensions.length !== 0 && (
                  <>
                    <Divider className="divider" orientation="center" />
                    <div className="sider-category">
                      <div className="sider-title">Size</div>
                      <div className="sider-checkbox">
                        {filters.dimensions.map((d) => {
                          return (
                            <Row
                              className="sider-row"
                              align="middle"
                              justify="space-between"
                            >
                              <Col>
                                <p className="sider-txt">{d}</p>
                              </Col>
                              <Col>
                                <Checkbox
                                  checked={sizes.includes(d) ? true : false}
                                  onChange={(e) =>
                                    handleSizes(e.target.checked, d)
                                  }
                                ></Checkbox>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    </div>{" "}
                  </>
                )}
              {filters.colors[0] !== null && filters.colors.length !== 0 && (
                <>
                  <Divider className="divider" orientation="center" />
                  <div className="sider-color">
                    <div className="sider-title">Color</div>
                    <div className="sider-checkbox">
                      {filters.colors.map((c) => {
                        return (
                          <Row
                            className="sider-row"
                            align="middle"
                            justify="space-between"
                          >
                            <Col>
                              <p className="sider-txt">{c}</p>
                            </Col>
                            <Col>
                              <Checkbox
                                checked={colors.includes(c) ? true : false}
                                onChange={(e) =>
                                  handleColors(e.target.checked, c)
                                }
                              ></Checkbox>
                            </Col>
                          </Row>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
              {categoryId !== "PopularFamily" &&
                filters.familyNames[0] !== null &&
                filters.familyNames.length !== 0 && (
                  <>
                    <Divider className="divider" orientation="center" />
                    <div className="sider-category">
                      <div className="sider-title">Family Name</div>
                      <div className="sider-checkbox">
                        {filters.familyNames.map((fm) => {
                          return (
                            <Row
                              className="sider-row"
                              align="middle"
                              justify="space-between"
                            >
                              <Col>
                                <p className="sider-txt">
                                  {fm}
                                  {/* <span className="sider-number">(123)</span> */}
                                </p>
                              </Col>
                              <Col>
                                <Checkbox
                                  checked={
                                    familyNames.includes(fm) ? true : false
                                  }
                                  onChange={(e) =>
                                    handleFamilyNames(e.target.checked, fm)
                                  }
                                ></Checkbox>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </Col>
        <Col
          style={{ padding: 0 }}
          className="gutter-row"
          xs={24}
          sm={24}
          md={16}
          lg={18}
        >
          <Content className="content product-main-content">
            <Row
              className="view"
              style={{ margin: 0 }}
              align="middle"
              justify="space-between"
            >
              <Col xs={24} sm={24} md={12}>
                <p className="view-page">
                  Viewing{" "}
                  <span className="view-page-number">
                    {" "}
                    {productsList.length}{" "}
                  </span>
                  of
                  <span className="view-page-number"> {pagination.total} </span>
                  Products
                </p>
              </Col>
              <Col xs={24} sm={24} md={12} className="filterDropdownContainer">
                <div className="filter-dropdown">
                  <Space>
                    <p className="m-0">
                      Sort by :
                      <span className="dropdown-title">
                        <Select
                          className="sortDropdown"
                          defaultValue={JSON.stringify({
                            order: "asc",
                            orderBy: "name",
                          })}
                          onChange={(v: any) => {
                            const newValue = JSON.parse(v);
                            setOrderBy({
                              order: newValue.order,
                              orderBy: newValue.orderBy,
                            });
                          }}
                          options={[
                            {
                              value: JSON.stringify({
                                order: "asc",
                                orderBy: "name",
                              }),
                              label: "Ascending (Name)",
                            },
                            {
                              value: JSON.stringify({
                                order: "desc",
                                orderBy: "name",
                              }),
                              label: "Descending (Name)",
                            },
                            {
                              value: JSON.stringify({
                                order: "asc",
                                orderBy: "price",
                              }),
                              label: "Ascending (Price)",
                            },
                            {
                              value: JSON.stringify({
                                order: "desc",
                                orderBy: "price",
                              }),
                              label: "Descending (Price)",
                            },
                          ]}
                        />
                      </span>
                    </p>
                  </Space>
                </div>
              </Col>
            </Row>
            <Row className="flex products-display m-0">
              {productsList.length !== 0 ? (
                productsList.map((product: any) => {
                  return (
                    <ProductCard
                      key={product.id}
                      categoryName={categoryName}
                      product={product}
                      callback={fetchCategoryProducts}
                    />
                  );
                })
              ) : (
                <Col xs={24} className="flex justify-content-center">
                  <h2>"No Products Available"</h2>
                </Col>
              )}

              <Col
                xs={24}
                sm={24}
                md={23}
                lg={23}
                style={{ display: "grid", placeItems: "flex-end" }}
              >
                <Pagination
                  responsive
                  className="pagination"
                  current={pagination.page}
                  defaultCurrent={pagination.page || 1}
                  total={pagination.total}
                  pageSize={pagination.perpage}
                  onChange={(page, size) => {
                    //@ts-ignore
                    setPagination({ page: page, perpage: size });

                    if (categoryId === undefined) {
                      fetchPopularProducts(page, size);
                    }
                    if (categoryId === "PopularFamily") {
                      fetchPopularFamily(page, size);
                    }

                    if (
                      categoryId !== undefined &&
                      categoryId !== "PopularFamily"
                    ) {
                      fetchCategoryProducts(page, size);
                    }

                    //@ts-ignore
                    pageTopRef.current.scrollIntoView();
                  }}
                />
              </Col>
            </Row>
          </Content>
        </Col>
      </Row>
    </Layout>
  );
}

function ProductCard(props: any) {
  const history = useHistory();
  const catName = props.product?.category?.parentCategory?.name;
  const user = JSON.parse(localStorage.getItem("user")!);
  const currency = user?.user.dealerDetails[0]?.currencyType;
  const truncate = (title: string) => {
    return title.length > 40 ? title.substr(0, 40) + "..." : title;
  };

  const addToWIshlistHandle = async (
    productId: number,
    isWishlisted: boolean,
    isHandleMoveCart?: boolean
  ) => {
    if (isWishlisted) {
      try {
        await API_SERVICE.WishListDelete(productId);

        if (isHandleMoveCart) {
          notification.success({
            message: "Removed From Wishlist",
            placement: "bottomLeft",
          });
          props.callback();
        }
      } catch (error) {
        notification.error({
          message: "Something Went Wrong",
          placement: "bottomLeft",
        });
      }
    } else {
      try {
        await API_SERVICE.addToWishList(productId);
        notification.success({
          message: "Added to Wishlist",
          placement: "bottomLeft",
        });
        props.callback();
      } catch (err) {
        notification.error({
          message: API_SERVICE.handleErrors(err),
          placement: "bottomLeft",
        });
      }
    }
  };
  return (
    // <>{console.log("ppppppppppppp", props)}</>
    <div className="product-card" style={{ cursor: "pointer" }}>
      <div className="offer-line">
        {/* {props.product?.buyGetOffers &&
          props.product?.buyGetOffers.map((item: any) => {
            return (
              <p key={item.id} className="product-detail-offer-text">
                Buy {item.buyQty} Get {item.getQty} free
              </p>
            );
          })} */}
        {props.product?.buyGetOffers && props.product?.buyGetOffers[0] && (
          <p className="product-detail-offer-text">
            Buy {props.product?.buyGetOffers[0]?.buyQty} Get{" "}
            {props.product?.buyGetOffers[0]?.getQty} free
          </p>
        )}
      </div>
      <div
        onClick={() =>
          history.push(
            PRODUCT_DETAILS_PAGE + "/" + props?.product?.id + "/" + catName
          )
        }
      >
        <div className="product-card-image">
          <img
            src={
              props?.product?.thumbnail !== null
                ? props?.product?.thumbnail?.url
                : MilanoThumbnail
            }
            alt={props?.product?.thumbnail?.name}
          />
        </div>
        <div className="product-details">
          <h3 className="title ">{truncate(props?.product?.name)} </h3>
          <p className="price">
            {currency === "USD" ? "$" : "AED"}{" "}
            {consverPriceToInternationalFormat(props?.product?.price)}{" "}
            {parseInt(props?.product?.cartoonQuantity) > 1 && (
              <span> per piece</span>
            )}
            <span className="product-stock">
              {props?.product?.label?.labels}
            </span>
          </p>

          <div className="inventory-info">
            <div>
              <p className="product-sku">SKU {props?.product?.skuId}</p>
            </div>
            <div>
              {parseInt(props?.product?.cartoonQuantity) > 1 && (
                <p className="theme-p">
                  (Set Of {props?.product?.cartoonQuantity})
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="AddToCartBtnContainer">
        <AddToCartBtn
          name=""
          productId={props?.product.id}
          quantity={parseInt(props.product.cartoonQuantity)}
          orderCoupon={null}
        />
        <Button
          className="wishlistBtn"
          onClick={() => {
            addToWIshlistHandle(
              props.product.id,
              props.product.isWishlisted,
              true
            );
          }}
        >
          {props.product.isWishlisted ? <HeartFilled /> : <HeartOutlined />}
        </Button>
      </div>
    </div>
  );
}
function fetchCategoryProducts(): void {
  throw new Error("Function not implemented.");
}
